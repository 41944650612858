import axios from 'axios';
import React from 'react';
import {Image, TouchableOpacity, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {AppText} from '../../components';
import {Colors, Images} from '../../constants';
import AuthActions from '../../redux/reduxsauce/authRedux';
import {strings} from '../../i18n';
import styles from './styles';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {goBack} from '../../utils';

const DeleteConfirmation = (props) => {
  const {config, auth} = useSelector((state) => state);

  const dispatch = useDispatch();

  const onDeleteAccount = async () => {
    dispatch(CommonActions.setLoading(true));
    await axios
      .get(`/user/delete/account/${props.route?.params?.uidAndToken}`)
      .then((response) => {
        dispatch(CommonActions.setLoading(false));
        dispatch(AuthActions.logout());
        axios.defaults.headers.common['Authorization'] = '';
        delete axios.defaults.headers.common['Authorization'];
        goBack(props.navigation);
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content:
              response?.data?.message || strings('deleteConfirmation.success'),
          }),
        );
      })
      .catch((error) => {
        dispatch(CommonActions.setLoading(false));
        console.log(error);
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.message,
          }),
        );
      });
  };
  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <Image style={styles.logoImage} source={Images.logo} />
        <AppText style={styles.question}>
          {strings('deleteConfirmation.askDelete') +
            ' ' +
            config?.businessName +
            '?'}
        </AppText>
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            onPress={onDeleteAccount}
            style={[
              styles.btn,
              {
                borderColor: Colors.wallet_card_red,
                backgroundColor: Colors.wallet_card_red,
                opacity: 0.8,
              },
            ]}
          >
            <AppText style={{fontFamily: 'Roboto-Bold', color: Colors.white}}>
              {strings('deleteConfirmation.button.confirm')}
            </AppText>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => goBack(props.navigation)}
            style={styles.btn}
          >
            <AppText style={{fontFamily: 'Roboto-Bold', color: Colors.black}}>
              {strings('deleteConfirmation.button.cancel')}
            </AppText>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};
export default DeleteConfirmation;
