import React, {Component} from 'react';
import {Image, Text, View, TouchableOpacity} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {AppText, Icon, MainContainer, MainHeader} from '../../components';
import {Images} from '../../constants';
import {strings} from '../../i18n';
import {getParam, goBack} from '../../utils';
import styles from './styles';

class ChooseOrderTypeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reorderItems: getParam(props.route, 'reorderItems', null),
    };
  }

  componentDidMount() {
    // this.props.getOrderSettings();
  }

  onDelivery = () => {
    // this.props.navigation.replace('DeliveryOrder', { reorderItems: this.state.reorderItems });
    // this.props.navigation.replace('SelectDeliveryLocationOutlet', { reorderItems: this.state.reorderItems });
    this.props.navigation.replace('ChooseDeliveryAddress', {
      reorderItems: this.state.reorderItems,
    });
  };

  renderImage() {
    return (
      <View>
        <Image source={Images.order} style={styles.image} />
        <Text style={styles.text}>
          {strings('ordering.chooseOrderTypeScreen.desc')}
        </Text>
      </View>
    );
  }

  renderOptions() {
    // if (this.props.order.orderingSettings == null) return;
    return (
      <View>
        {/* {this.props.order.orderingSettings.canPickUp  && ( */}
        {true && (
          <TouchableOpacity
            style={styles.button}
            // onPress={this.onPickUp}
            onPress={() =>
              // this.props.navigation.replace('pickup-order-config', {
              //   orderType: 'pick-up',
              // })
              this.props.navigation.replace('products', {
                orderType: 'pickUp',
              })
            }
          >
            <Text style={styles.buttonText}>
              {strings('ordering.chooseOrderTypeScreen.pickup')}
            </Text>
          </TouchableOpacity>
        )}
        {/* {this.props.order.orderingSettings.canDineIn && ( */}
        {true && (
          <TouchableOpacity
            style={styles.button}
            onPress={() =>
              this.props.navigation.replace('scan-table-qr', {
                reorderItems: this.state.reorderItems,
              })
            }
          >
            <Text style={styles.buttonText}>
              {strings('ordering.chooseOrderTypeScreen.dinein')}
            </Text>
          </TouchableOpacity>
        )}
        {/* {(this.props.order.orderingSettings.canDeliver || this.props.user.isTester) && ( */}
        {true && (
          <TouchableOpacity
            style={styles.button}
            onPress={() =>
              this.props.navigation.replace('products', {
                orderType: 'delivery',
              })
            }
          >
            <Text style={styles.buttonText}>
              {strings('ordering.chooseOrderTypeScreen.delivery')}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    );
  }

  render() {
    return (
      <View style={styles.container}>
        <TouchableOpacity
          style={styles.header}
          onPress={() => goBack(this.props.navigation)}
        >
          <Icon name="back" color="white" size={20} />
          <AppText style={styles.title}>
            {strings('ordering.chooseOrderTypeScreen.title')}
          </AppText>
        </TouchableOpacity>
        <View style={styles.content}>
          {this.renderImage()}
          {this.renderOptions()}
        </View>
      </View>
    );
  }
}

export default connect(
  state => ({
    order: state.order,
    user: state.auth.user,
  }),
  dispatch => bindActionCreators({}, dispatch),
)(ChooseOrderTypeScreen);
