import React, {useEffect, useState} from 'react';
import {
  Keyboard,
  Platform,
  TextInput,
  View,
  FlatList,
  TouchableOpacity,
} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {
  AppText,
  LongButton,
  MainContainer,
  MainHeader,
  Icon,
  DropDownInput,
} from '../../../components';
import {Colors} from '../../../constants';
import {strings} from '../../../i18n';
import {goBack, setValue} from '../../../utils';
import styles from './styles';
import {connectActionSheet} from '@expo/react-native-action-sheet';
import axios from 'axios';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {useDispatch, useSelector} from 'react-redux';
import {useIsFocused} from '@react-navigation/native';

const AddNewTable = (props) => {
  const item = props?.route?.params?.item;
  const [tableDetail, setTableDetail] = useState({
    number: item?.number ? JSON.stringify(item?.number) : '',
    capacity: item?.capacity ? JSON.stringify(item?.capacity) : '',
    room: props?.route?.params?.roomItem?.id
      ? props?.route?.params?.roomItem?.id
      : '',
  });
  const dispatch = useDispatch();

  const onSave = async () => {
    Keyboard.dismiss();
    dispatch(CommonActions.setLoading(true));
    if (tableDetail.number == '') {
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('addNewTable.alert.number'),
        }),
      );
      return;
    }
    if (tableDetail.capacity == '') {
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('addNewTable.alert.capacity'),
        }),
      );
      return;
    }
    if (tableDetail.room == '') {
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('addNewTable.alert.room'),
        }),
      );
      return;
    }

    let body = new FormData();
    body.append('number', tableDetail?.number);
    body.append('capacity', tableDetail?.capacity);
    body.append('room', tableDetail?.room);

    const header = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    console.log('body of edit table', body);
    if (item?.id) {
      await axios
        .patch('/restaurant/table/update/' + item?.id, body, header)
        .then((response) => {
          console.log('response of update table', response);
          goBack(props.navigation);
          dispatch(CommonActions.setLoading(false));
        })
        .catch((error) => {
          console.log('error update floor --', error?.response);
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: error?.response?.message,
            }),
          );
          dispatch(CommonActions.setLoading(false));
        });
    } else {
      await axios
        .post('/restaurant/table/create', body, header)
        .then((response) => {
          console.log('response of create table', response);
          goBack(props.navigation);
          getFloorList();
          dispatch(CommonActions.setLoading(false));
        })
        .catch((error) => {
          console.log('error create floor', error.response);
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: error?.response?.message,
            }),
          );
          dispatch(CommonActions.setLoading(false));
        });
    }
  };

  return (
    <View style={styles.container}>
      <MainHeader
        title={
          props?.route?.params?.item?.id
            ? strings('addNewTable.editTable')
            : strings('addNewTable.createTable')
        }
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer>
        <KeyboardAwareScrollView>
          <View style={styles.textInputContainer}>
            <AppText style={styles.name} required>
              {strings('addNewTable.title.tableNumber')}
            </AppText>
            <TextInput
              keyboardType="numeric"
              underlineColorAndroid="transparent"
              autoCapitalize="none"
              autoCorrect={false}
              placeholder={strings('addNewTable.placeHolder.tableNumber')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={styles.textInput}
              value={tableDetail?.number}
              onChangeText={(text) =>
                setTableDetail({
                  ...tableDetail,
                  number: text,
                })
              }
            />
          </View>

          <View style={styles.textInputContainer}>
            <AppText style={styles.name} required>
              {strings('addNewTable.title.capacity')}
            </AppText>
            <TextInput
              keyboardType="numeric"
              underlineColorAndroid="transparent"
              autoCapitalize="none"
              autoCorrect={false}
              placeholder={strings('addNewTable.placeHolder.description')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={styles.textInput}
              value={tableDetail?.capacity}
              onChangeText={(text) =>
                setTableDetail({
                  ...tableDetail,
                  capacity: text,
                })
              }
            />
          </View>

          <View style={styles.textInputContainer}>
            <AppText style={styles.name} required>
              {strings('addNewTable.title.room')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCapitalize="none"
              autoCorrect={false}
              editable={false}
              returnKeyType="done"
              style={styles.textInput}
              value={props?.route?.params?.roomItem?.name}
            />
          </View>

          {Platform.OS == 'web' && (
            <LongButton
              onPress={() => onSave()}
              loading={false}
              text={
                item?.id
                  ? strings('addNewGallery.edit')
                  : strings('editProductCategory.button.save')
              }
              style={{
                marginTop: setValue(20),
                marginBottom: setValue(45),
              }}
            />
          )}
        </KeyboardAwareScrollView>
      </MainContainer>

      {Platform.OS !== 'web' && (
        <LongButton
          onPress={() => onSave()}
          loading={false}
          text={
            item?.id
              ? strings('addNewGallery.edit')
              : strings('editProductCategory.button.save')
          }
          style={{borderRadius: 0, width: '100%'}}
        />
      )}
    </View>
  );
};
const ConnectedAddNewTable = connectActionSheet(AddNewTable);

export default ConnectedAddNewTable;
