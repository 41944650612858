import {Platform, StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  safeArea: {
    flex: 1,
    paddingTop: Platform.OS === 'android' ? 10 : 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    alignItems: 'center',
    maxWidth: 400,
    width: '100%',
  },
  headerIcon: {
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginRight: setXAxisValue(20),
  },
  iconCross: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.white_gray,
    borderRadius: setXAxisValue(40) / 2,
    width: setXAxisValue(40),
    height: setXAxisValue(40),
  },
  imageAvatar: {
    borderRadius: 8,
    width: setXAxisValue(100),
    height: setXAxisValue(100),
    marginVertical: setYAxisValue(30),
  },
  name: {
    fontSize: setXAxisValue(18),
    marginTop: setYAxisValue(20),
    textAlign:"center"
  },
  phone: {
    fontSize: setXAxisValue(18),
    marginTop: setYAxisValue(5),
    textAlign:"center"

  },
  button: {
    marginTop: setYAxisValue(30),
    alignSelf: 'center',
    borderColor: Colors.theme_color,
    paddingHorizontal: setXAxisValue(50),
    paddingVertical: setYAxisValue(15),
    borderWidth: 1,
    borderRadius: setXAxisValue(50),
  },
  buttonText: {
    color: Colors.theme_color,
  },
});
