import {Platform, StyleSheet} from 'react-native';
import Constants from 'expo-constants';

import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

const wrapper = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 8,
  padding: setValue(5),
};

export default StyleSheet.create({
  onboardingContainer: {
    flex: 1,
  },
  safeView: {
    backgroundColor: Colors.theme_color,
    paddingBottom: setYAxisValue(10),
    paddingTop:
      Platform.OS === 'web' ? setYAxisValue(10) : Constants.statusBarHeight,
  },
  title: {
    fontSize: setXAxisValue(20),
    marginHorizontal: setXAxisValue(8),
    color: Colors.primary_color,
    paddingRight: setXAxisValue(10),
    paddingLeft: setXAxisValue(8),
  },
  profileStrengthContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    borderTopRightRadius: setValue(90) / 2,
    borderBottomRightRadius: setValue(90) / 2,
    marginTop: 15,
  },
  imageContainer: {
    width: 200,
    aspectRatio: 1 / 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
    backgroundColor: Colors.white,
  },
  image: {
    width: 200,
    aspectRatio: 1 / 1,
    borderRadius: 5,
  },
  cameraIcon: {
    backgroundColor: Colors.white,
    padding: 5,
    paddingHorizontal: setValue(7),
    borderRadius: setValue(5),
    position: 'absolute',
    alignSelf: 'center',
    top: '40%',
  },
  logoRequirements: {
    marginTop: 5,
    marginBottom: 20,
    fontSize: 10,
  },
  textInputContainer: {
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 5,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
    flex: 1,
  },
  inputLabel: {
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    fontFamily: 'Roboto-Bold',
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  textInput: {
    height: setValue(50),
    padding: 0,
    flex: 1,
    paddingHorizontal: setXAxisValue(10),
  },
  textInputWeb: {
    height: setValue(50),
    padding: setValue(5),
    paddingHorizontal: setXAxisValue(10),
  },
  multiLineTextInput: {
    padding: setValue(10),
    textAlignVertical: 'top',
    minHeight: 100,
  },
  buttonContainer: {
    backgroundColor: Colors.white,
    paddingVertical: setYAxisValue(25),
  },
  buttonContainerWeb: {
    backgroundColor: Colors.white,
    marginVertical: setValue(50),
  },
  buttonButton: {
    width: '100%',
    backgroundColor: Colors.theme_color,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    bottom: 0,
    position: 'absolute',
    paddingVertical: setYAxisValue(15),
  },
  buttonButtonWeb: {
    width: '100%',
    backgroundColor: Colors.theme_color,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 5,
    bottom: 0,
    position: 'absolute',
    paddingVertical: setYAxisValue(15),
    ...wrapper,
  },
  buttonText: {
    fontSize: setXAxisValue(14),
    color: Colors.white,
  },

  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: setValue(30),
  },
  signUpImage: {
    width: setValue(200),
    height: setValue(200),
    borderRadius: setValue(8),
  },
  textMessage: {
    textAlign: 'center',
    fontSize: setXAxisValue(25),
  },
  textDes: {
    marginTop: setValue(10),
    fontSize: setXAxisValue(15),
    textAlign: 'center',
    color: Colors.wallet_card_red,
  },
  refreshButton: {
    backgroundColor: Colors.theme_color,
    borderRadius: 5,
    height: setYAxisValue(45),
    width: setXAxisValue(120),
    alignItems: 'center',
    justifyContent: 'center',
  },
});
