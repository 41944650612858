import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
  borderRadius: 5,
  backgroundColor: Colors.white,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  textInputContainer: {
    height: setValue(60),
  },
  textInput: {
    height: setValue(50),
    paddingHorizontal: setXAxisValue(15),
    marginBottom: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    //
    ...shadow,
  },
  inputRightSide: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    right: setValue(15),
    alignSelf: 'center',
    top: setValue(12),
    elevation: 10,
  },
  itemContainer: {
    flex: 1,
    padding: setValue(10),
    marginTop: setYAxisValue(10),
    ...shadow,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: setValue(5),
  },
  orderUId: {
    fontSize: setXAxisValue(15),
  },
  orderPrice: {
    fontFamily: 'Roboto-Bold',
    fontSize: setXAxisValue(18),
    color: Colors.black60,
  },
  bottomContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemContainer: {
    flex: 1,
    padding: setValue(10),
    marginTop: setYAxisValue(10),
    ...shadow,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: setValue(5),
  },
  orderUId: {
    fontSize: setXAxisValue(15),
  },
  orderPrice: {
    fontFamily: 'Roboto-Bold',
    fontSize: setXAxisValue(16),
    color: Colors.black60,
  },
  bottomContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
