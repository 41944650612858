import axios from 'axios';
import get from 'lodash.get';
import moment from 'moment';
import React, {Component} from 'react';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Linking,
  Platform,
  SafeAreaView,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import {connect} from 'react-redux';
import {
  AppText,
  DeliveryPartnerModal,
  Icon,
  MainContainer,
  MainHeader,
  Modal,
  QRCode,
} from '../../components';
import {strings} from '../../i18n';
import {
  cashFormat,
  getParam,
  goBack,
  screenHeight,
  screenWidth,
  setValue,
  setXAxisValue,
} from '../../utils';
import styles from './styles';
import {Colors, Layout} from '../../constants';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {bindActionCreators} from 'redux';
import * as Location from 'expo-location';
import MapView from 'react-native-maps';
import MapViewDirections from 'react-native-maps-directions';
import * as Print from 'expo-print';
import Constants from 'expo-constants';

const GOOGLE_API_KEY = 'AIzaSyAW-2c6qBkLKg78SLFGqqj_xqKkJCCR_OA';
class OfflineOrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: getParam(props.route, 'id', null),
      orderProductDetail: [],
      serviceCharge: 0,
      showModal: false,
      loading: false,
      status: '',
      showShippingModal: false,
      statusMessage: '',
      errorMessage: '',
      basicOrderDetail: null,
      orderStatusHistory: [],
      isLocationLoading: false,
      locationPermission: null,
      deliveryPartner: null,
      selectedPrinter: null,
      declineCancellation: false,
      selfTrackingId: '',
      coordinates: [
        {
          latitude: null,
          longitude: null,
        },
        {
          latitude: null,
          longitude: null,
        },
      ],
    };
    this.currencySymbol = get(props?.config, 'currencySymbol', '₹');
  }

  print = async () => {
    const {basicOrderDetail} = this.state;

    const getOrderList = () => {
      let newdata = ``;
      let data = this.state.orderProductDetail;
      for (let i = 0; i < data?.length; i++) {
        newdata += `<tr>
        <td style="text-transform: capitalize;width: 40%;">${
          data[i]?.product.name
        } ${
          // (data[i]?.selected_variants
          // ? ' (' +
          //   data[i]?.selected_variants?.map((o) => o.value).join(', ') +
          //   ')'
          // : '')
          ''
        }</td>
        <td style="text-transform: capitalize;text-align: center;width: 20%;">${parseInt(
          data[i]?.qty,
        )}</td>
        <td style="text-transform: capitalize;text-align: center;width: 20%;">${
          this.currencySymbol
        }${
          data[i]?.final_price
            ? parseInt(data[i]?.final_price)
            : parseInt(data[i]?.product.price)
        }</td>
        <td style="text-transform: capitalize;text-align: right;width: 20%;">${
          this.currencySymbol
        }${
          data[i]?.final_price
            ? parseInt(data[i]?.final_price) * parseInt(data[i]?.qty)
            : parseInt(data[i]?.product.price) * parseInt(data[i]?.qty)
        }</td>
    </tr>`;
      }
      return newdata;
    };
    const renderBussinessLocation = () => {
      const {config} = this.props;
      return config?.businessLocation?.street &&
        config?.businessLocation?.area &&
        config?.businessLocation?.city
        ? `${
            config?.businessLocation?.street
              ? config?.businessLocation?.street
              : ''
          }${
            config?.businessLocation?.area
              ? ', ' + config?.businessLocation?.area
              : ''
          }${
            config?.businessLocation?.city
              ? ', ' + config?.businessLocation?.city
              : ''
          }${
            config?.businessLocation?.state
              ? config?.businessLocation?.state
              : ''
          }${
            config?.businessLocation?.country
              ? ', ' + config?.businessLocation?.country
              : ''
          } ${
            config?.businessLocation?.pincode
              ? '- ' + config?.businessLocation?.pincode
              : ''
          }`
        : '-';
    };
    const html = `<html>
    <head>
        <style>
            @page {
                size: 58mm 105mm;
            }
            *{
              box-sizing: border-box;
              font-size: 1mm;
              font-weight: 400;
            }
            .mainDiv {
                width: 100%;
                height: 100%;
                border-width: 1px solid #000;
            }
            .title_container{ 
                border-bottom: 1px dotted black;
                text-align: center;
                padding: 5px;
            }
            tr{width: 100%;}
        </style>
    </head>
    <body>
        <div class="mainDiv">
            <div class="title_container">
                <div style=" font-size: 110%;text-transform: uppercase;padding-bottom: 5px; font-weight: 600;">${
                  this.props.config?.businessName
                }</div>
                <div style="text-transform: capitalize;">${renderBussinessLocation()}  </div>
            </div>
            <table style="width: 100%;border-bottom: 1px dotted black;">
                <!-- <tr>
                    <td style="text-transform: capitalize;">Invoice #</td>
                    <td style="text-transform: uppercase;text-align: right;">inv-0000</td>
                </tr> -->
                <tr>
                    <td style="text-transform: capitalize;">order ID</td>
                    <td style="text-transform: uppercase;text-align: right;">ORD00${
                      this.state.basicOrderDetail?.id
                    }</td>
                </tr>
               ${
                 this.state.basicOrderDetail?.type == 'dinein'
                   ? `<tr>
                <td style="text-transform: capitalize;">table no</td>
                <td style="text-transform: uppercase;text-align: right;">${this.state.basicOrderDetail?.table_number}</td>
            </tr>`
                   : ''
               }
                <tr>
                    <td style="text-transform: capitalize;">Date</td>
                    <td style="text-transform: uppercase;text-align: right;">${
                      this.state.basicOrderDetail?.created_at &&
                      moment(this.state.basicOrderDetail?.created_at).format(
                        'DD MMM YYYY hh:mm A',
                      )
                    }</td>
                </tr>
                <tr>
                    <td style="text-transform: capitalize;">name</td>
                    <td style="text-transform: uppercase;text-align: right;">${
                      this.state.basicOrderDetail?.user?.name
                    }</td>
                </tr>
                <tr>
                    <td style="text-transform: capitalize;">phone</td>
                    <td style="text-transform: uppercase;text-align: right;">${
                      this.state.basicOrderDetail?.user?.phone
                    }</td>
                </tr>
            </table>
            <table style="width: 100%;border-bottom: 1px dotted black;">
                <tr>
                    <td style="text-transform: capitalize;width: 40%;font-weight: 500;">Products</td>
                    <td style="text-transform: capitalize;text-align: center;width: 20%;font-weight: 500;">qty</td>
                    <td style="text-transform: capitalize;text-align: center;width: 20%;font-weight: 500;">rate</td>
                    <td style="text-transform: capitalize;text-align: right;width: 20%;font-weight: 500;">amount</td>
                </tr>
                ${getOrderList()}
            </table>
            ${
              this.state.basicOrderDetail?.promotion
                ? `<div style=" border-bottom: 1px dotted black; display: flex;flex-direction: row;justify-content: space-between">
            <div style="margin: 3px;width: 65%">
                <div>Discount : ${
                  this.state.basicOrderDetail?.promotion?.coupon_code
                }</div>
                <div>
                (${
                  parseFloat(basicOrderDetail?.promotion?.percentage_of) +
                  '% up to ' +
                  this.currencySymbol +
                  '' +
                  parseFloat(basicOrderDetail?.promotion?.discount_upto_price)
                }
                  )</div>
            </div>
            <div style="margin: 3px; ">
                - ${this.currencySymbol}${parseFloat(
                    basicOrderDetail?.order_total - basicOrderDetail?.total,
                  ).toFixed(2)}
            </div>
        </div>
        `
                : ``
            }
           ${
             parseFloat(this.state.basicOrderDetail?.delivery_charge) > 0
               ? `<div>
                 <div style="margin: 5px 0; display: flex;flex-direction: row;justify-content: flex-end;">
                   <div style="width: 50%; text-transform: capitalize; text-align: right;">
                     <div>delivery charge</div>
                   </div>
                   <div style="width: 30%; text-transform: capitalize; text-align: right;">
                     <div>
                       ${this.currencySymbol}
                       ${parseFloat(
                         this.state.basicOrderDetail?.delivery_charge,
                       ).toFixed(2)}
                       /-
                     </div>
                   </div>
                 </div>
               </div>`
               : ''
           }
            <div style="margin: 5px 0; display: flex;flex-direction: row;justify-content: flex-end;">
                <div style="width: 30%; text-transform: capitalize; text-align: right;">
                    <div>total</div>
                </div>
                <div style="width: 30%; text-transform: capitalize; text-align: right;">
                    <div>${this.currencySymbol}${parseFloat(
      this.state.basicOrderDetail?.total,
    ).toFixed(2)}/-</div>
                    <!-- <div style="">${this.currencySymbol}${parseFloat(
      this.state.basicOrderDetail?.total,
    )}/-</div>
                    <div>₹ 0.00/-</div>
                    <div style="">${this.currencySymbol}${parseFloat(
      this.state.basicOrderDetail?.total,
    )}/-</div> -->
                </div>
            </div>
            <div style="width: 100%;margin-top: 15px; text-transform: capitalize;">bill inclusive of tax</div>
            <div style="display: flex;flex-direction: row;">
                <div style="width: 100%;text-align: center;align-items: center;justify-content: right;">
                    <div style="height: 10px;"></div>
                    <div style="text-align: right;text-transform: capitalize;">For ${
                      this.props.config?.businessName
                    }</div>
                    <div style="text-align: right;text-transform: capitalize;">(signatory)</div>
                    <br/>
                    <br/>
                    <hr/>
                </div>
            </div>
        </div>
    </body>
</html>`;
    if (Platform.OS == 'web') {
      let printWin = window.open('', '', 'toolbar=0,scrollbars=0,status=0');
      printWin.document.write(html);
      printWin.document.close();
      printWin.focus();
      printWin.print();
      printWin.close();
      return;
    }
    await Print.printAsync({
      html: html,
      // width: 58,
      // height: 100,
      printerUrl: this.state.selectedPrinter?.url, // iOS only
    });
  };

  componentDidMount() {
    this.props.setLoading(true);
    this.unsubscribe = this.props.navigation.addListener('focus', () => {
      this.fetchOrderDetail();
    });
  }

  componentWillUnmount() {
    this.props.setLoading(false);
    this.unsubscribe && this.unsubscribe();
  }

  fetchOrderDetail = async () => {
    const {coordinates} = this.state;
    this.props.setLoading(true);
    await axios
      .get(`/invoice/get/details/${this.state?.orderId}`)
      .then((response) => {
        console.log('response of invoice Detail----', response);
        // this.setState({status: (response?.data?.status).toUpperCase()});
        this.setState({orderProductDetail: response?.data?.products});
        this.setState({basicOrderDetail: response?.data});
        console.log('basicOrderDetail====', this.state.basicOrderDetail);

        this.props.setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        this.props.setLoading(false);
        this.props.openAlert(error.response.message);
      });
  };

  downloadShiprocketInvoice = (orderID) => {
    axios
      .post('/delivery/get/invoice/', {
        order: orderID,
      })
      .then((response) => {
        console.log('response get invoice', response);
        Linking.openURL(response?.data?.invoice_url);
      })
      .catch((error) => {
        console.log('error get invoice', error);
        this.props.openAlert(
          error?.response?.message || 'Something went wrong.',
        );
      });
  };

  downloadOrderInvoice = async () => {
    this.props.setLoading(true);
    await axios
      .get('/order/invoice/' + this.state.basicOrderDetail?.id)
      .then(async (response) => {
        console.log('response ------>', response);
        this.props.setLoading(false);
        if (Platform.OS == 'web') {
          let printWin = window.open('', '', 'toolbar=0,scrollbars=0,status=0');
          printWin.document.write(response.data);
          printWin.document.close();
          printWin.focus();
          printWin.print();
          printWin.close();
          return;
        }
        await Print.printAsync({
          html: response.data,
          // width: 58,
          // height: 100,
          printerUrl: this.state.selectedPrinter?.url, // iOS only
        });
      })
      .catch((error) => {
        console.log('error-->', error?.response);
        this.props.openAlert(
          error?.response?.message || 'Something went wrong.',
        );
        this.props.setLoading(false);
      });
  };

  renderOrderItem = ({item, index}) => {
    console.log('item===========', item);
    return (
      <View key={index} style={styles.orderDetailItem}>
        <View
          style={{width: '50%', flexDirection: 'row', alignItems: 'flex-start'}}
        >
          <AppText
            style={[styles.itemName]}
            onPress={() =>
              this.props.navigation.navigate('productDetail', {
                slug: item?.product?.name
                  .toLowerCase()
                  .replace(/ /g, '-')
                  .replace(/[^\w-]+/g, ''),
                id: item?.product?.id,
              })
            }
          >
            {item?.product?.name + ' '}
            {item?.selected_variants?.length > 0 && (
              <AppText style={styles.productVarientsText}>
                ({item?.selected_variants?.map((o) => o.value).join(', ')})
              </AppText>
            )}
          </AppText>
        </View>
        <View style={styles.itemDetail2Container}>
          <AppText style={styles.itemQuantityText}>
            {parseInt(item?.qty)}
          </AppText>
          <AppText style={styles.priceText}>
            {this.currencySymbol + ' ' + item?.final_price
              ? item?.final_price
              : item?.product?.price}
          </AppText>
        </View>
      </View>
    );
  };
  renderOrder = () => {
    const {item} = this.props.route.params;
    const {basicOrderDetail} = this.state;

    return (
      <View style={styles.infoList}>
        <AppText style={styles.info}>
          {strings('ordering.orderHistoryDetailScreen.orderItems')}
          {/* order item */}
        </AppText>

        <View
          style={{
            height: 1,
            backgroundColor: 'gray',
            width: '100%',
          }}
        />
        <View style={styles.orderDetailContainer}>
          <View style={styles.orderDetailItem}>
            <View style={{width: '50%', justifyContent: 'center'}}>
              <AppText
                style={[styles.itemName, {fontFamily: 'Roboto-Bold'}]}
                numberOfLines={1}
              >
                {strings('ordering.orderStatusPending.products')}
              </AppText>
            </View>
            <View style={styles.itemDetail2Container}>
              <AppText
                style={[styles.itemQuantityText, {fontFamily: 'Roboto-Bold'}]}
              >
                {strings('ordering.orderStatusPending.qty')}
              </AppText>
              <AppText style={[styles.priceText, {fontFamily: 'Roboto-Bold'}]}>
                {strings('ordering.orderStatusPending.price')}
              </AppText>
            </View>
          </View>
          <View>
            {this.state.orderProductDetail?.length !== 0 &&
              this.state.orderProductDetail?.map(
                (item, index) => this.renderOrderItem({item, index}),
                console.log('item====', item),
              )}
          </View>
          <View
            style={{
              marginHorizontal: -10,
              borderTopWidth: 1,
              borderColor: '#ccc',
              paddingHorizontal: 10,
              marginTop: 10,
            }}
          >
            {basicOrderDetail?.type == 'delivery' && (
              <View style={[styles.orderDetailItem, {marginTop: 10}]}>
                <AppText style={styles.subTotalItem1}>
                  {strings('ordering.orderStatusPending.deliveryCharge')}
                </AppText>
                <AppText style={styles.subTotalItem2}>
                  {this.currencySymbol +
                    ' ' +
                    basicOrderDetail?.delivery_charge}
                </AppText>
              </View>
            )}
            <View style={[styles.orderDetailItem]}>
              <AppText style={styles.subTotalItem1}>
                {strings('ordering.orderStatusPending.total')}
              </AppText>
              <AppText style={styles.subTotalItem2}>
                {this.currencySymbol + ' ' + basicOrderDetail?.total}
              </AppText>
            </View>
          </View>

          {false && this.state.order?.serviceCharge !== 0 && (
            <View style={styles.itemDetail2Container}>
              <AppText style={styles.netTotalItem1}>
                {strings('ordering.checkOutorder?.serviceTax')}
              </AppText>
              <AppText style={styles.netTotalItem2}>
                {this.currencySymbol +
                  ' ' +
                  cashFormat(this.state.serviceCharge)}
              </AppText>
            </View>
          )}
        </View>
      </View>
    );
  };

  renderOrderInfo = () => {
    const {basicOrderDetail} = this.state;
    console.log('basicOrderDetail?.status', basicOrderDetail?.status);

    return (
      <View style={styles.infoList}>
        <AppText style={styles.info}>
          {strings('ordering.orderStatusPending.detail')}
          {/* Detail */}
        </AppText>
        <View
          style={{
            height: 1,
            backgroundColor: 'gray',
            width: '100%',
          }}
        />
        <View style={styles.orderDetailContainer}>
          <View style={styles.orderDetailItem}>
            <AppText style={styles.infoItemText1}>
              {strings('ordering.orderHistoryDetailScreen.orderNo')}
            </AppText>
            <AppText>{'ORD00' + basicOrderDetail?.id}</AppText>
          </View>
          <View style={[styles.orderDetailItem, {marginVertical: 5}]}>
            <AppText style={styles.infoItemText1}>
              {strings('ordering.orderHistoryDetailScreen.orderDate')}
            </AppText>
            <AppText>
              {basicOrderDetail?.created_at &&
                moment(basicOrderDetail?.created_at).format(
                  'DD MMMM YYYY hh:mm A',
                )}
            </AppText>
          </View>
          {basicOrderDetail?.type === 'pickup' && (
            <View style={[styles.orderDetailItem, {marginVertical: 5}]}>
              <AppText style={styles.infoItemText1}>
                {strings('ordering.orderHistoryDetailScreen.pickupTime')}
              </AppText>
              <AppText>
                {basicOrderDetail?.pickup_time &&
                  moment(basicOrderDetail?.pickup_time).format(
                    'DD MMMM YYYY hh:mm A',
                  )}
              </AppText>
            </View>
          )}

          <View style={[styles.orderDetailItem, {marginTop: 5}]}>
            <AppText style={styles.infoItemText1}>
              {strings('ordering.orderStatusPending.paymentMethode')}
            </AppText>
            <AppText>{basicOrderDetail?.payment}</AppText>
          </View>
          {(basicOrderDetail?.status === 'declined' ||
            basicOrderDetail?.status === 'cancelled') &&
            basicOrderDetail?.payment_refund_key?.length > 0 &&
            ['merchant', 'manager'].includes(this.props?.user?.type) && (
              // basicOrderDetail?.payment_method == 'razorpay' &&
              <View style={[styles.orderDetailItem, {marginTop: 5}]}>
                <AppText style={styles.infoItemText1}>
                  {strings('ordering.orderStatusPending.refundKey')}
                </AppText>
                <AppText style={{width: '60%', textAlign: 'right'}}>
                  {basicOrderDetail?.payment_refund_key}
                </AppText>
              </View>
            )}
          {(basicOrderDetail?.status == 'declined' ||
            basicOrderDetail?.status === 'cancelled') &&
            basicOrderDetail?.status_message?.length > 0 && (
              <View style={[styles.orderDetailItem, {marginTop: 5}]}>
                <AppText style={styles.infoItemText1}>
                  {strings('ordering.orderStatusPending.message')}
                </AppText>
                <AppText
                  style={{width: '60%', textAlign: 'right'}}
                  numberOfLines={2}
                >
                  {basicOrderDetail?.status_message}
                </AppText>
              </View>
            )}
          {basicOrderDetail?.type == 'dinein' && (
            <View style={[styles.orderDetailItem, {marginTop: 5}]}>
              <AppText style={styles.infoItemText1}>
                {strings('ordering.orderHistoryDetailScreen.tableNo')}
              </AppText>
              <AppText>{basicOrderDetail?.table_number}</AppText>
            </View>
          )}
        </View>
      </View>
    );
  };

  renderCustomerInfo = () => {
    const {basicOrderDetail} = this.state;

    return (
      <View style={styles.infoList}>
        {/* Heading */}
        <AppText style={styles.info}>
          {strings('ordering.orderStatusPending.customerDetails')}
        </AppText>
        {/* border Line */}
        <View
          style={{
            height: 1,
            backgroundColor: 'gray',
            width: '100%',
          }}
        />

        {/*  customer Info */}
        <View style={styles.orderDetailContainer}>
          <View style={[styles.orderDetailItem, {marginTop: 0}]}>
            <AppText style={styles.infoItemText1}>
              {strings('ordering.orderStatusPending.name')}
            </AppText>
            <AppText>
              {basicOrderDetail?.user?.name
                ? basicOrderDetail?.user?.name
                : basicOrderDetail?.customer?.name}
            </AppText>
          </View>
          <View style={[styles.orderDetailItem, {marginTop: 5}]}>
            <AppText style={styles.infoItemText1}>
              {strings('ordering.orderStatusPending.mobile')}
            </AppText>
            <AppText>
              {basicOrderDetail?.user?.phone
                ? basicOrderDetail?.user?.phone
                : basicOrderDetail?.customer?.phone}
            </AppText>
          </View>
        </View>
      </View>
    );
  };

  render = () => {
    // const {item} = this.props.route.params;
    const {user, config, loading} = this.props;
    const {basicOrderDetail} = this.state;
    return (
      <View style={styles.container}>
        <MainHeader
          title={'Order Detail'}
          leftIcon="back"
          leftPress={() => goBack(this.props.navigation)}
          rightIcon={'print'}
          rightPress={() => {
            // Linking.openURL(
            //   axios.defaults.baseURL +
            //     '/invoice/' +
            //     this.state.basicOrderDetail?.id,
            // );
            this.props.navigation.navigate('offline-order-print-preview', {
              id: basicOrderDetail.id,
            });
          }}
        />

        {!loading && (
          <MainContainer>
            <View style={{marginBottom: 50}}>
              <View style={styles.container2}>{this.renderOrderInfo()}</View>

              <View style={styles.container2}>{this.renderCustomerInfo()}</View>
              <View style={styles.container2}>{this.renderOrder()}</View>
            </View>
          </MainContainer>
        )}
      </View>
    );
  };
}
export default connect(
  (state) => ({
    user: state.auth.user,
    loading: state.common.loading,
    config: state.config,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setLoading: CommonActions.setLoading,
        openAlert: (content) =>
          CommonActions.setAlert({visible: true, content: content}),
      },
      dispatch,
    ),
)(OfflineOrderDetail);
