import {Platform, StyleSheet} from 'react-native';
import {Colors, Layout} from '../../constants';
import {screenWidth, setValue, setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  itemTitle: {
    fontSize: setValue(15),
    fontFamily: 'Roboto-Bold',
  },
  textDiscountPrice: {
    textDecorationLine: 'line-through',
    fontSize: 12,
    color: Colors.black40,
  },
  itemDescription: {
    color: Colors.black40,
  },
  itemConatiner: {
    marginBottom: setValue(10),
    flexDirection: 'row',
    padding: setValue(8),
    marginHorizontal: 2,
    backgroundColor: 'white',
    borderRadius: setValue(5),
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 3,
  },
  rightSideView: {
    flex: 1,
  },
  leftSideView: {
    padding: 5,
    width: 80,
    height: 80,
  },
  image: {
    height: undefined,
    width: '100%',
    flex: 1,
    borderRadius: 5,
    resizeMode: 'cover',
    aspectRatio: 1,
  },
  radioContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    maxWidth: 50,
  },
  radio: {
    textAlign: 'center',
    textAlignVertical: 'center',
  },
});
