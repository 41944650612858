import React, { Component } from 'react';
import { TouchableOpacity, View } from 'react-native';

import { AppText, Icon, MainContainer, MainHeader } from '../../components';
import { Colors } from '../../constants';
import { strings } from '../../i18n';
import { goBack } from '../../utils';
import styles from './styles';

class SupportScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { navigation } = this.props;
    return (
      <View style={styles.container}>
        <MainHeader title={strings('supportScreen.support')} leftIcon="back" leftPress={() => goBack(navigation)} />
        <MainContainer useView>
          <View style={styles.body}>
            <TouchableOpacity style={styles.supportView} onPress={() => navigation.navigate('faq')}>
              <AppText style={styles.title}>{strings('supportScreen.faq')}</AppText>
            </TouchableOpacity>
            <View style={styles.descriptionContainer}>
              <AppText style={styles.descriptionTitle}>{strings('supportScreen.cannotFindAnswer')}</AppText>
              <View style={styles.infoContainer}>
                <Icon name="info" size={20} color={Colors.color30} />
                <AppText style={styles.infoText}>{strings('supportScreen.freeToContact')}</AppText>
              </View>
            </View>
            <TouchableOpacity style={styles.supportView} onPress={() => navigation.navigate('contact-us')}>
              <AppText style={styles.title}>{strings('supportScreen.contactUs')}</AppText>
            </TouchableOpacity>
          </View>
        </MainContainer>
      </View>
    );
  }
}

export default SupportScreen;
