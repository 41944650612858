import Constants from 'expo-constants';

const {extra} = Constants.manifest;

const {themeColor} = extra;
const {primaryColor} = extra;
const {secondaryColor} = extra;

export default {
  black: '#000',
  black20: '#131313',
  black40: '#585858',
  black60: '#313131',
  border: '#ccc',
  primary_color: primaryColor == '' || !primaryColor ? '#ffffff' : primaryColor,
  secondary_color:
    secondaryColor == '' || !secondaryColor ? '#d3d3d3' : secondaryColor,
  color30: '#B4B9B9',
  color50: '#f8f8f8',
  dark_yellow: '#D7AD01',
  facebook: '#3b5998',
  member_QR_code_BGColor: '#FBAE21',
  overlay: 'rgba(0,0,0,0.7)',
  tab_icon_default: '#ccc',
  tab_icon_selected: '#111',
  theme_color: themeColor == '' || !themeColor ? '#ffb800' : themeColor,
  green: '#008000',
  wallet_card_orange: '#FCA218',
  wallet_card_red: '#E64026',
  white: '#fff',
  white_cream: '#F7F7F7',
  white_gray: '#F3F3F3',
};
