import React from 'react';
import {
  Animated,
  PanResponder,
  TouchableWithoutFeedback,
  View,
  TouchableOpacity,
} from 'react-native';
import {Layout} from '../../constants';
import styles from './styles';
import {Icon} from '../index';
import {SwipeRow as DefaultSwipe} from 'react-native-swipe-list-view';

export default function SwipeRow(props) {
  return (
    <DefaultSwipe
      disableRightSwipe
      disableLeftSwipe={props.disableSwipe}
      rightOpenValue={props.rightOpenValue ? props.rightOpenValue : -70}
    >
      <View style={styles.hiddenButton}>
        {props.editable ? (
          <TouchableOpacity
            style={styles.editButton}
            onPress={props?.onPressEdit}
          >
            <Icon name="edit" color="white" size={18} />
          </TouchableOpacity>
        ) : null}
        <TouchableOpacity
          style={styles.deleteButton}
          onPress={() => props?.onPressDelete(props?.item)}
        >
          <Icon name="dustBinSmaller" color="white" />
        </TouchableOpacity>
      </View>
      {props.children}
    </DefaultSwipe>
  );
}
