import React, { Component } from 'react';
import { Image, SafeAreaView, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Icon } from '..';
import { Colors } from '../../constants';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import AppText from '../AppText';
import styles from './styles';

class AlertFullScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  leftPress = () => {
    const { alertFullScreen, closeAlert } = this.props;
    if (alertFullScreen.leftPress) {
      closeAlert();
      alertFullScreen.leftPress();
    } else {
      closeAlert();
    }
  };

  rightPress = () => {
    const { alertFullScreen, closeAlert } = this.props;
    if (alertFullScreen.rightPress) {
      closeAlert();
      alertFullScreen.rightPress();
    } else {
      closeAlert();
    }
  };

  render() {
    const { alertFullScreen } = this.props;

    return alertFullScreen && alertFullScreen.content && alertFullScreen.visible ? (
      <View style={styles.container}>
        <View style={styles.overlay}>
          <View style={styles.alertContainer}>
            {alertFullScreen.image ? <Image style={styles.images} resizeMode="cover" source={alertFullScreen.image} /> : null}
            {alertFullScreen.content ? <AppText style={styles.content}>{alertFullScreen.content}</AppText> : null}
            {alertFullScreen.content2 ? <AppText style={styles.content2}>{alertFullScreen.content2}</AppText> : null}
          </View>
        </View>
        <SafeAreaView style={styles.crossIcon}>
          <TouchableOpacity onPress={this.rightPress}>
            <Icon name="cross" size={22} color={Colors.black20} />
          </TouchableOpacity>
        </SafeAreaView>
      </View>
    ) : null;
  }
}

export default connect(
  state => ({
    alertFullScreen: state.common.alertFullScreen
  }),
  dispatch =>
    bindActionCreators(
      {
        closeAlert: () => CommonActions.setAlertFullScreen({ visible: false })
      },
      dispatch
    )
)(AlertFullScreen);
