import {ActionSheetProvider} from '@expo/react-native-action-sheet';
import React, {createRef} from 'react';
import {Platform, StyleSheet, View} from 'react-native';
import {StatusBar} from 'expo-status-bar';
import {useDispatch, useSelector} from 'react-redux';
import {Alert, DrawerMenu, Loading, NetworkStatus} from '../components';
import Toast from '../components/Toast';
import AlertFullScreen from '../components/AlertFullScreen';
import AlertWebView from '../components/AlertWebView';
import {Colors, Layout} from '../constants';
import {AppNavigator, AuthNavigator} from './routes';
import CommonActions from '../redux/reduxsauce/commonRedux';
import ConfigAction from '../redux/reduxsauce/configRedux';
import _ from 'lodash';
import Constants from 'expo-constants';
import {useNavigationContainerRef} from '@react-navigation/native';
import {useEffect} from 'react';
// import * as Segment from 'expo-analytics-segment';
import ConnectToWebSocket from '../components/WebSocket';

const styles = StyleSheet.create({
  wrapper: {
    width: Layout.window.width,
    height: Layout.window.height,
    flex: 1,
    alignSelf: 'center',
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
  },
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
});
const App = (props) => {
  const navRef = useNavigationContainerRef();
  const config = useSelector((state) => state.config);
  const isLogin = useSelector((state) => state.auth?.user);
  const isMenuOpen = useSelector((state) => state.common.isMenuShow);
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(CommonActions.setLoading(false));
    if (!_.isEmpty(config)) {
      let ENV = Constants.manifest.extra.ENV;
      if (ENV === 'dev') {
        ENV = 'development';
      }
      if (ENV === undefined) {
        ENV = 'local';
      }
    }
  }, []);

  return (
    <View style={styles.wrapper}>
      <ActionSheetProvider>
        {/* <SideMenu
          disableGestures
          openMenuOffset={
            Platform.OS == 'web' && screenWidth > 1023
              ? Layout.window.width / 10
              : Layout.window.width / 3
          }
          menu={<DrawerMenu navigator={navRef} />}
          isOpen={isMenuOpen}
          onChange={(isOpen) => {
            dispatch(CommonActions.setMenu(isOpen));
          }}
        > */}
        <View style={styles.container}>
          <StatusBar style={'light'} />
          {Platform.OS === 'ios' && <StatusBar style={'light'} />}
          {isLogin && isLogin.business ? (
            <AppNavigator
              navRef={(nav) => {
                navRef.current = nav;
                window.navigation = nav;
              }}
            />
          ) : (
            <AuthNavigator
              navRef={(nav) => {
                navRef.current = nav;
                window.navigation = nav;
              }}
            />
          )}
          <Alert />
          <AlertFullScreen />
          <AlertWebView />
          <Loading />
          <Toast />
          {/* <ConnectToWebSocket/> */}
          {Platform.OS !== 'web' ? <NetworkStatus /> : null}
        </View>
        {/* </SideMenu> */}
      </ActionSheetProvider>
    </View>
  );
};

export default App;
