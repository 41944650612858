import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  tooltip: {
    padding: setValue(10),
    textAlign: 'center',
  },
  container: {
    flex: 1,
  },
  textInput: {
    height: setValue(50),
    padding: 0,
    flex: 1,
    paddingHorizontal: setXAxisValue(5),
    marginBottom: setYAxisValue(10),
    borderRadius: 5,
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
  },
  textInputContainer: {
    height: setValue(60),
  },
  sendButton: {
    marginVertical: setYAxisValue(25),
  },
  textInputMultiLine: {
    height: setValue(200),
    padding: 0,
    flex: 1,
    paddingHorizontal: setXAxisValue(5),
    marginBottom: setYAxisValue(10),
    borderRadius: 5,
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
  },
  textInputMultiLineContainer: {
    height: setValue(200),
  },
  addIcon: {
    width: setValue(32),
    height: setValue(32),
    borderRadius: setValue(32) / 2,
    backgroundColor: Colors.theme_color,
    justifyContent: 'center',
    alignItems: 'center',
  },
  attachPhotoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: setValue(150),
    marginVertical: setYAxisValue(15),
    backgroundColor: Colors.white_gray,
    paddingVertical: setXAxisValue(20),
  },
  attachText: {
    fontSize: setXAxisValue(14),
    color: Colors.color30,
    // fontFamily: ''
  },
  imageFeedback: {
    height: '100%',
    width: '100%',
    backgroundColor: '#cecece',
  },
  imageContainer: {
    flex: 1,
  },
  imageList: {flex: 1, flexDirection: 'row'},
  crossIcon: {
    position: 'absolute',
    top: setYAxisValue(5),
    right: setXAxisValue(5),
    width: setValue(28),
    height: setValue(28),
    borderRadius: setValue(28) / 2,
    backgroundColor: Colors.theme_color,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
