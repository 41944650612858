import React, {useState} from 'react';
import {Image, View} from 'react-native';
import {useDispatch} from 'react-redux';
import {AppText, MainContainer, MainHeader} from '../../../components';
import {strings} from '../../../i18n';
import {goBack, screenHeight} from '../../../utils';
import styles from './styles';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import axios from 'axios';

const GalleryDetail = (props) => {
  const {item} = props?.route?.params;
  const dispatch = useDispatch();

  const deleteBlog = async (id) => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios.delete('/business/gallery/delete/' + item?.id).then((response) => {
        console.log('response --> ', response);
        dispatch(CommonActions.setLoading(false));
        goBack(props.navigation);
      });
    } catch (error) {
      dispatch(CommonActions.setLoading(false));
      console.log('message', error?.response);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };

  return (
    <View style={{flex: 1}}>
      <MainHeader
        title={item?.title} // after we add in language json file
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
        rightOtherIcon={'edit'}
        rightIcon={'dustBinSmaller'}
        rightOtherPress={() =>
          props.navigation.navigate('edit-gallery', {
            item: item,
            edit: true,
          })
        }
        rightPress={() =>
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: strings('galleryList.askDelete'),
              rightButton: strings('settingScreen.confirm'),
              rightPress: async () => {
                deleteBlog(item?.id);
              },
              leftButton: strings('settingScreen.cancel'),
              leftPress: null,
            }),
          )
        }
      />
      <MainContainer>
        <View style={{minHeight: screenHeight / 2}}>
          <View style={styles.blogItem}>
            {item?.image && (
              <Image
                source={{uri: item?.image}}
                style={[styles.blogImage, {aspectRatio: 30 / 17}]}
              />
            )}
            {item?.title && (
              <AppText style={styles.blogTitle}>{item?.title}</AppText>
            )}
            {item?.description && (
              <AppText style={styles.blogDesc}>{item?.description}</AppText>
            )}
          </View>
        </View>
      </MainContainer>
    </View>
  );
};

export default GalleryDetail;
