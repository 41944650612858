import DateTimePicker from '@react-native-community/datetimepicker';
import moment from 'moment';
import React, {Component} from 'react';
import CommonActions from './../../redux/reduxsauce/commonRedux';
import {
  Platform,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {Colors} from '../../constants';
import {strings} from '../../i18n';
import AppText from '../AppText';
import Icon from '../Icon';
import Modal from '../Modal';
import styles from './styles';

const minYear = moment().add(-100, 'years').year();
class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: String(props.maxYear || moment().year()),
      yearValue: String(props.maxYear || moment().year()),
      dateObject: {
        dd: props.pickupDate ? moment(props.pickupDate).format('DD') : '',
        mm: props.pickupDate ? moment(props.pickupDate).format('MM') : '',
        yy: props.pickupDate ? moment(props.pickupDate).format('YYYY') : '',
      },
    };
    this.maxDate =
      props.maxDate ||
      (props.maxYear ? new Date(Number(props.maxYear - 1), 12, 31) : null);
    this.minDate = props.minDate || new Date(Number(minYear), 1, 1);
    const currentDate = moment();
    const formattedMaxDate = moment(this.maxDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
    const newFormattedDate = currentDate.isAfter(formattedMaxDate)
      ? formattedMaxDate
      : currentDate;
    this.dateData = {
      dateString: newFormattedDate.format('YYYY-MM-DD'),
      day: newFormattedDate.date(),
      month: newFormattedDate.month() + 1,
      timestamp: newFormattedDate.unix(),
      year: newFormattedDate.year(),
    };
    this.dateObject = {dd: '', mm: '', yy: ''};
  }
  componentWillUpdate() {
    this.props.navigation?.addListener('focus', () => {
      this.setState({...this.state, dateObject: {dd: '', mm: '', yy: ''}});
      this.dateObject = {dd: '', mm: '', yy: ''};
    });
  }
  renderDatePickerWeb = () => {
    const {year, yearValue, dateObject} = this.state;
    const {maxYear} = this.props;
    return (
      <View style={styles.calendarContainer}>
        <View style={{flexDirection: 'row'}}>
          <TextInput
            placeholder="YYYY"
            placeholderTextColor={Colors.placeholderTextColor}
            style={{maxWidth: 40}}
            value={dateObject.yy}
            onChangeText={(text) => {
              this.setState({
                ...this.state,
                dateObject: {...dateObject, yy: text},
              });
              if (this.props.onSetDataForWeb) {
                this.props.onSetDataForWeb(
                  text + '-' + dateObject.mm + '-' + dateObject.dd,
                );
              } else {
                this.props.onSetDataForClass(
                  text + '-' + dateObject.mm + '-' + dateObject.dd,
                );
              }
            }}
            maxLength={4}
          />
          <AppText style={{fontSize: 20, paddingHorizontal: 10}}>-</AppText>
          <TextInput
            placeholder="MM"
            style={{maxWidth: 30}}
            placeholderTextColor={Colors.placeholderTextColor}
            value={dateObject.mm}
            onChangeText={(text) => {
              if (parseInt(text) > 12) {
                this.props.openAlert('Enter valid month');
                return;
              }
              this.setState({
                ...this.state,
                dateObject: {...dateObject, mm: text},
              });
              if (this.props.onSetDataForWeb) {
                this.props.onSetDataForWeb(
                  dateObject.yy + '-' + text + '-' + dateObject.dd,
                );
              } else {
                this.props.onSetDataForClass(
                  dateObject.yy + '-' + text + '-' + dateObject.dd,
                );
              }
            }}
            maxLength={2}
          />
          <AppText style={{fontSize: 20, paddingHorizontal: 10}}>-</AppText>
          <TextInput
            keyboardType="numeric"
            style={{maxWidth: 30}}
            placeholder="DD"
            placeholderTextColor={Colors.placeholderTextColor}
            value={dateObject.dd}
            onChangeText={(text) => {
              if (parseInt(text) > 31) {
                this.props.openAlert('Enter valid day');
                return;
              }
              this.setState({
                ...this.state,
                dateObject: {...dateObject, dd: text},
              });
              if (this.props.onSetDataForWeb) {
                this.props.onSetDataForWeb(
                  dateObject.yy + '-' + dateObject.mm + '-' + text,
                );
              } else {
                this.props.onSetDataForClass(
                  dateObject.yy + '-' + dateObject.mm + '-' + text,
                );
              }
            }}
            maxLength={2}
          />
        </View>
        <TouchableOpacity
          onPress={() => {
            this.setState({
              ...this.state,
              dateObject: {dd: '', mm: '', yy: ''},
            });
            this.props.onCalenderPress();
          }}
        >
          <Icon
            name={
              (dateObject.yy || dateObject.mm || dateObject.dd) != ''
                ? 'cross'
                : 'calendarSmall'
            }
            size={20}
            color={Colors.black20}
          />
        </TouchableOpacity>
      </View>
      // <Modal {...this.props} transparent onRequestClose={this.props.onClose}>
      //   <TouchableWithoutFeedback onPress={this.props.onClose}>
      //     <View style={styles.overlay} onStartShouldSetResponder={() => true}>
      //       <View style={styles.calendarContainer}>
      //         <TextInput
      //           underlineColorAndroid="transparent"
      //           autoCapitalize="none"
      //           autoCorrect={false}
      //           placeholder="YYYY"
      //           placeholderTextColor={Colors.color30}
      //           style={styles.textInput}
      //           returnKeyType="done"
      //           keyboardType="number-pad"
      //           defaultValue={year}
      //           value={yearValue}
      //           onChangeText={(text) => this.setState({yearValue: text})}
      //           maxLength={4}
      //           clearTextOnFocus
      //           onSubmitEditing={(e) => {
      //             const yearSubmit =
      //               Number(e.nativeEvent.text) > Number(maxYear)
      //                 ? String(maxYear)
      //                 : e.nativeEvent.text;
      //             this.setState({
      //               year: yearSubmit,
      //               yearValue: yearSubmit,
      //             });
      //           }}
      //         />
      //         {/* {(
      //           <Calendar
      //             minDate={this.props.minDate || `${minYear}-01-01`}
      //             maxDate={
      //               this.props.maxDate || (maxYear ? `${maxYear}-12-31` : '')
      //             }
      //             current={
      //               year !== String(moment().year())
      //                 ? moment(Number(year) + 1, 'YYYY').toString()
      //                 : moment(new Date()).format('D MMMM YYYY')
      //             }
      //             onDayPress={this.props.onDayPress}
      //             renderArrow={(direction) => (
      //               <Icon
      //                 name={direction === 'left' ? 'arrowLeft' : 'arrowRight'}
      //               />
      //             )}
      //           />
      //         )} */}
      //       </View>
      //     </View>
      //   </TouchableWithoutFeedback>
      // </Modal>
    );
  };

  renderMobileDatePicker = () => {
    const {dateObject} = this.state;
    const {onDayPress, onCancelPress} = this.props;

    return (
      <View style={styles.calendarContainer}>
        {this.props.title || <AppText>{'No date'}</AppText>}
        <TouchableOpacity
          style={{marginHorizontal: 7}}
          onPress={() => {
            this.dateObject = {dd: '', mm: '', yy: ''};
            this.props.onCalenderPress();
          }}
        >
          <Icon
            name={
              (this.dateObject.yy ||
                this.dateObject.mm ||
                this.dateObject.dd) != ''
                ? 'cross'
                : this.props.showCross
                ? 'cross'
                : 'calendarSmall'
            }
            size={20}
            color={Colors.black20}
          />
        </TouchableOpacity>
        <Modal
          {...this.props}
          visible={this.props.visible}
          transparent
          onRequestClose={this.props.onClose}
        >
          <TouchableWithoutFeedback onPress={this.props.onClose}>
            <View
              style={styles.overlayMobile}
              onStartShouldSetResponder={() => true}
            >
              {Platform.OS === 'ios' ? (
                <View style={styles.buttons}>
                  <TouchableOpacity
                    onPress={() => onDayPress && onDayPress(this.dateData)}
                  >
                    <AppText style={styles.button}>
                      {strings('common.ok')}
                    </AppText>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => onCancelPress && onCancelPress()}
                  >
                    <AppText style={styles.button}>
                      {strings('common.cancel')}
                    </AppText>
                  </TouchableOpacity>
                </View>
              ) : null}
              <View style={styles.datePickerBground}>
                <DateTimePicker
                  value={new Date()}
                  mode="date"
                  is24Hour
                  display={Platform.select({
                    ios: 'spinner',
                    android: 'default',
                  })}
                  onChange={(event, selectedDate) => {
                    if (selectedDate) {
                      const formattedDate = moment(
                        selectedDate,
                        'YYYY-MM-DDTHH:mm:ss.SSSZ',
                      );
                      const dateData = {
                        dateString: formattedDate.format('YYYY-MM-DD'),
                        day: formattedDate.date(),
                        month: formattedDate.month() + 1,
                        timestamp: formattedDate.unix(),
                        year: formattedDate.year(),
                      };
                      if (
                        (this.dateObject.dd ||
                          this.dateObject.mm ||
                          this.dateObject.yy) != ''
                      ) {
                        this.dateObject = {
                          dd: '',
                          mm: '',
                          yy: '',
                        };
                      } else {
                        this.dateObject = {
                          dd: dateData.day,
                          mm: dateData.month,
                          yy: dateData.year,
                        };
                      }

                      if (Platform.OS === 'android') {
                        onDayPress && onDayPress(dateData);
                      } else {
                        this.dateData = dateData;
                      }
                    }
                  }}
                  style={styles.datePicker}
                  maximumDate={this.maxDate}
                  minimumDate={this.minDate}
                />
              </View>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      </View>
    );
  };

  render() {
    return Platform.select({
      web: this.renderDatePickerWeb(),
      default: this.renderMobileDatePicker(),
    });
  }
}

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      openAlert: (content) => CommonActions.setAlert({visible: true, content}),
    },
    dispatch,
  ),
)(DatePicker);
