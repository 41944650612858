import {connectActionSheet} from '@expo/react-native-action-sheet';
import React, {Component} from 'react';
import {Image, Platform, View} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {AppText, MainContainer, MainHeader} from '../../../components';
import {Images} from '../../../constants';
import {strings} from '../../../i18n';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {capitalizeFirstLetter, goBack} from '../../../utils';
import styles from './styles';

class ProfileScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
    };
  }

  renderProfileStrength = () => (
    <View style={styles.profileStrengthContainer}>
      <View style={styles.profileStrengthBody}>
        <AppText style={styles.profileStrengthText}>
          {strings('profileScreen.profileStrength')}
        </AppText>
        <AppText style={styles.percentText}>
          {this.props.profile?.profilePercentage}%
        </AppText>
      </View>
      <Image
        source={
          this.props?.profile?.imageURI
            ? Images.profile
            : require('../../../../assets/images/General/no-img-found.jpg')
        }
        style={styles.image}
      />
    </View>
  );
  renderBasicInfo = () => {
    console.log('profile', this.props);
    return (
      <View style={styles.basicInfoContainer}>
        <View style={styles.infoBody}>
          <View style={styles.infoView}>
            <AppText style={styles.infoTitle}>
              {strings('profileScreen.nickName')}
            </AppText>
            <AppText style={styles.infoText}>
              {this.props.profile?.nickname}
            </AppText>
          </View>
          <View style={styles.infoView}>
            <AppText style={styles.infoTitle}>
              {strings('profileScreen.firstName')}
            </AppText>
            <AppText style={styles.infoText}>
              {this.props.profile?.first_name}
            </AppText>
          </View>
          <View style={styles.infoView}>
            <AppText style={styles.infoTitle}>
              {strings('profileScreen.lastName')}
            </AppText>
            <AppText style={styles.infoText}>
              {this.props.profile?.last_name}
            </AppText>
          </View>
          <View style={styles.infoView}>
            <AppText style={styles.infoTitle}>
              {strings('profileScreen.gender')}
            </AppText>
            {this.props.profile.gender ? (
              <AppText style={styles.infoText}>
                {capitalizeFirstLetter(this.props.profile?.gender)}
              </AppText>
            ) : null}
          </View>
          <View style={styles.infoView}>
            <AppText style={styles.infoTitle}>
              {strings('profileScreen.dob')}
            </AppText>
            <AppText style={styles.infoText}>
              {this.props.profile?.dateOfBirth}
            </AppText>
          </View>
          <View style={styles.infoView}>
            <AppText style={styles.infoTitle}>
              {strings('profileScreen.phone')}
            </AppText>
            <AppText style={styles.infoText}>
              {this.props.profile?.phone}
            </AppText>
          </View>
          <View style={styles.infoView}>
            <AppText style={styles.infoTitle}>
              {strings('profileScreen.email')}
            </AppText>
            <AppText style={styles.infoText}>
              {this.props.profile?.email}
            </AppText>
          </View>
        </View>
      </View>
    );
  };

  renderAddress = () => (
    <View>
      <View style={styles.headerAddress}>
        <AppText style={styles.addressHeader}>
          {strings('profileScreen.listAddress')}
        </AppText>
      </View>
    </View>
  );

  render() {
    const {navigation} = this.props;
    return (
      <View style={styles.container}>
        <MainHeader
          title={strings('profileScreen.profileSettings')}
          leftIcon="back"
          leftPress={() => goBack(navigation)}
          rightIcon="edit"
          rightPress={() => navigation.navigate('edit-profile')}
        />
        <MainContainer>{this.renderBasicInfo()}</MainContainer>
      </View>
    );
  }
}

const ConnectedProfileScreen = connectActionSheet(ProfileScreen);

export default connect(
  (state) => ({
    profile: state.auth.user,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setLoading: CommonActions.setLoading,
      },
      dispatch,
    ),
)(ConnectedProfileScreen);
