import React, {Component} from 'react';
import {
  findNodeHandle,
  FlatList,
  Platform,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  UIManager,
  View,
} from 'react-native';

import {Colors} from '../../constants';
import {screenHeight} from '../../utils';
import AppText from '../AppText';
import Icon from '../Icon';
import Modal from '../Modal';
import styles from './styles';

class DropDownInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      top: 0,
    };
  }

  setModalVisible = (visible) => {
    this.setState({visible});
  };

  onPressItem = (item) => {
    this.setModalVisible(false);
    this.props.onChangeText(item);
  };

  onPress = () => {
    this.props.data.length > 0 && this.setModalVisible(true);
  };

  render() {
    const {
      inputStyle,
      inputContainerStyle,
      titleStyle,
      iconStyle,
      pickerStyle,
      title,
      customView,
      iconColor,
      customBackgroundStyle,
    } = this.props;
    return (
      <View
        style={styles.inputView}
        ref={(ref) => {
          this.containerRef = ref;
        }}
        onLayout={() => {
          if (this.containerRef) {
            const handle = findNodeHandle(this.containerRef);
            UIManager.measureInWindow(handle, (x, y) => {
              if (this.state.top < y && y) {
                this.setState({top: y});
              }
            });
          }
        }}
      >
        {customView ? (
          customView(this.onPress)
        ) : (
          <TouchableOpacity
            onPress={this.onPress}
            style={[styles.textInputContainer, inputStyle]}
            disabled={this.props.disabled}
          >
            {title && (
              <AppText style={titleStyle || styles.title}>{title}</AppText>
            )}
            <TextInput
              style={
                this.props?.customBackgroundStyle
                  ? customBackgroundStyle
                  : styles.input
              }
              underlineColorAndroid="transparent"
              autoCorrect={false}
              autoCapitalize="none"
              pointerEvents="none"
              editable={false}
              placeholderTextColor={Colors.color30}
              value={
                typeof this.props.data[0] == 'object'
                  ? this.props.data.find((o) => o.value == this.props?.value)
                      ?.label
                  : this.props.value
              }
              placeholder={this.props.placeholder}
            />
            {!this.props.disabled && (
              <View style={[styles.downIcon, iconStyle]}>
                <Icon
                  size={18}
                  name="back_down"
                  color={iconColor ? iconColor : Colors.black60}
                />
              </View>
            )}
          </TouchableOpacity>
        )}
        {/* <Modal visible={this.state.visible} transparent onRequestClose={() => this.setModalVisible(false)}>
          <TouchableWithoutFeedback onPress={() => this.setModalVisible(false)}>
            <View style={styles.overlay} onStartShouldSetResponder={() => true}>
              <View style={[styles.picker, { top: this.state.top }, pickerStyle]} onStartShouldSetResponder={() => true}>
                <FlatList
                  data={this.props.data}
                  style={styles.scroll}
                  showsVerticalScrollIndicator={false}
                  renderItem={({ item }) => (
                    <TouchableOpacity style={styles.itemView} onPress={() => this.onPressItem(item)}>
                      <AppText style={styles.item} numberOfLines={1}>
                        {item}
                      </AppText>
                    </TouchableOpacity>
                  )}
                  keyExtractor={(item, index) => String(index)}
                  contentContainerStyle={styles.scrollContainer}
                  overScrollMode="never"
                />
              </View>
            </View>
          </TouchableWithoutFeedback>
        </Modal> */}
        {this.props.data.length > 0 && (
          <Modal
            visible={this.state.visible}
            transparent
            onRequestClose={() => this.setModalVisible(false)}
          >
            <TouchableWithoutFeedback
              onPress={() => this.setModalVisible(false)}
            >
              <View
                style={
                  Platform.OS == 'web' ? styles.overlayWeb : styles.overlay
                }
                onStartShouldSetResponder={() => true}
              >
                <View
                  style={
                    Platform.OS == 'web'
                      ? {
                          marginTop: screenHeight / 4.5,
                          maxWidth: 400,
                          minWidth: 400,
                          height:
                            this.props.data.length == 1
                              ? '25%'
                              : this.props.data.length < 5
                              ? this.props.data.length * 125
                              : this.props.data.length == 5
                              ? this.props.data.length * 80
                              : '70%',
                        }
                      : {
                          position: 'absolute',
                          bottom: 10,
                          left: 0,
                          right: 0,
                          height:
                            this.props.data.length == 1
                              ? '25%'
                              : this.props.data.length == 2
                              ? this.props.data.length * 120
                              : this.props.data.length == 3
                              ? this.props.data.length * 100
                              : this.props.data.length == 4
                              ? this.props.data.length * 90
                              : this.props.data.length == 5
                              ? this.props.data.length * 80
                              : '75%',
                        }
                  }
                >
                  <View
                    style={
                      Platform.OS == 'web'
                        ? {
                            // display: 'flex',
                            backgroundColor: Colors.white,
                            padding: 15,
                            marginHorizontal: 10,
                            borderRadius: 10,
                            height:
                              this.props.data.length < 5
                                ? this.props.data.length * 80
                                : this.props.data.length == 5
                                ? this.props.data.length * 65
                                : '75%',
                          }
                        : {
                            backgroundColor: Colors.white,
                            padding: 15,
                            marginHorizontal: 10,
                            borderRadius: 10,
                            height: '30%',
                            flex: 1,
                          }
                    }
                  >
                    <FlatList
                      data={this.props.data}
                      style={styles.scroll}
                      showsVerticalScrollIndicator={false}
                      renderItem={({item}) => {
                        return typeof item == 'object' ? (
                          <TouchableOpacity
                            style={styles.itemView}
                            onPress={() => this.onPressItem(item.value)}
                          >
                            <AppText style={styles.item} numberOfLines={1}>
                              {item.label}
                            </AppText>
                          </TouchableOpacity>
                        ) : (
                          <TouchableOpacity
                            style={styles.itemView}
                            onPress={() => this.onPressItem(item)}
                          >
                            <AppText style={styles.item} numberOfLines={1}>
                              {item}
                            </AppText>
                          </TouchableOpacity>
                        );
                      }}
                      keyExtractor={(item, index) => String(index)}
                      contentContainerStyle={styles.scrollContainer}
                      overScrollMode="never"
                    />
                  </View>
                  <TouchableOpacity
                    activeOpacity={1}
                    style={{
                      backgroundColor: Colors.white,
                      padding: 15,
                      marginHorizontal: 10,
                      borderRadius: 10,
                      marginTop: 10,
                      marginBottom: 30,
                    }}
                    onPress={() => this.setModalVisible(false)}
                  >
                    <AppText
                      style={{textAlign: 'center', fontFamily: 'Roboto-Bold'}}
                    >
                      Cancel
                    </AppText>
                  </TouchableOpacity>
                </View>
              </View>
            </TouchableWithoutFeedback>
          </Modal>
        )}
      </View>
    );
  }
}

DropDownInput.defaultProps = {
  value: '',
  placeholder: '',
  data: [],
  onChangeText: () => {},
};

export default DropDownInput;
