import React, {useEffect, useState} from 'react';
import {
  TextInput,
  View,
  Platform,
  TouchableOpacity,
} from 'react-native';
import styles from './styles';
import {Colors} from '../../constants';
import AppText from '../AppText';
import {Icon} from '../../components';
import DropDownInput from '../DropDownInput';
import {strings} from '../../i18n';

export default function SpecificationItem(props) {
  const {index, item} = props;

  return (
    <View key={index} style={styles.container}>
      <View style={styles.textInputContainer}>
        <AppText style={styles.inputLabel}>{'Label'}</AppText>
        <TextInput
          underlineColorAndroid="transparent"
          // autoCapitalize="words"
          autoCorrect={false}
          placeholder={'label'}
          placeholderTextColor={Colors.color30}
          returnKeyType="done"
          style={
            Platform.OS == 'web'
              ? styles.textInputWeb
              : styles.multiLineTextInput
          }
          defaultValue={item.label}
          onChangeText={(text) => props.onSpecificationKeyChange(text, index)}
        />
      </View>

      <View style={styles.textInputContainer}>
        <AppText style={styles.inputLabel}>{'Type'}</AppText>
        {/* <Picker
          mode="dropdown"
          style={styles.typePicker}
          onValueChange={itemValue => {
            props.onSpecificationTypeChange(itemValue, index);
          }}
          selectedValue={item.type}
        >
          <Picker.Item label="Select Type" value="text" />
          <Picker.Item label="Text" value="text" />
          <Picker.Item label="Long Text" value="longText" />
          <Picker.Item label="List" value="array" />
        </Picker> */}
        <DropDownInput
          data={[
            // {value: 'text', label: 'Select Type'},
            {value: 'text', label: 'Text'},
            {value: 'longText', label: 'Long Text'},
            {value: 'array', label: 'List'},
          ]}
          value={item?.type}
          inputStyle={{
            paddingHorizontal: 10,
            marginVertical: Platform.OS == 'web' ? 5 : 7,
          }}
          onChangeText={(itemValue) => {
            props.onSpecificationTypeChange(itemValue, index);
          }}
        />
      </View>

      {item.type == 'text' ? (
        <View style={styles.textInputContainer}>
          <AppText style={styles.inputLabel}>{'Value'}</AppText>
          <TextInput
            underlineColorAndroid="transparent"
            // autoCapitalize="words"
            autoCorrect={false}
            placeholder={'label'}
            placeholderTextColor={Colors.color30}
            returnKeyType="done"
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            defaultValue={item.value}
            onChangeText={(text) =>
              props.onSpecificationValueChange(text, index)
            }
          />
        </View>
      ) : (
        item.type == 'longText' && (
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>{'Value'}</AppText>
            <TextInput
              underlineColorAndroid="transparent"
              // autoCapitalize="words"
              autoCorrect={false}
              placeholder={'label'}
              multiline={true}
              numberOfLines={3}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web'
                  ? styles.textInputWeb
                  : styles.multiLineTextInput
              }
              defaultValue={item.value}
              onChangeText={(text) =>
                props.onSpecificationValueChange(text, index)
              }
            />
          </View>
        )
      )}
      {item.type == 'array' && (
        <>
          {item.value.map((o, i) => (
            <View style={styles.textInputContainer} key={i}>
              <AppText style={styles.inputLabel}>{'Point ' + (i + 1)}</AppText>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <View style={{width: '85%', justifyContent: 'center'}}>
                  <TextInput
                    underlineColorAndroid="transparent"
                    // autoCapitalize="words"
                    autoCorrect={false}
                    placeholder={'Value'}
                    placeholderTextColor={Colors.color30}
                    returnKeyType="done"
                    style={
                      Platform.OS == 'web'
                        ? styles.textInputWeb
                        : styles.pointsInput
                    }
                    value={o}
                    onChangeText={(text) =>
                      props.SpecificationPointsChange(text, i, index)
                    }
                  />
                </View>
                <View
                  style={{
                    width: '10%',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <TouchableOpacity
                    style={styles.removePoint}
                    onPress={() => props.RemoveValueField(index)}
                  >
                    <Icon name="deleteItem" color={Colors.black20} />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          ))}
          <View style={styles.textInputContainer}>
            {/* <TouchableOpacity
              style={styles.addRomoveButton}
              onPress={() => props.RemoveValueField(index)}
            >
              <AppText style={styles.addRomoveButtonText}>Remove</AppText>
            </TouchableOpacity> */}
            <TouchableOpacity onPress={() => props.AddValueField(index)}>
              <AppText style={styles.addRomoveButtonText}>
                <Icon name={'add'} size={15} color={Colors.black60} />
              </AppText>
            </TouchableOpacity>
          </View>
        </>
      )}
    </View>
  );
}
