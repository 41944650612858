import Constants from 'expo-constants';
import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
  },
  title: {
    color: 'white',
    fontSize: setXAxisValue(20),
    marginLeft: setXAxisValue(10),
  },
  description: {
    alignSelf: 'center',
    backgroundColor: 'black',
    paddingHorizontal: setXAxisValue(20),
    paddingVertical: setYAxisValue(10),
    marginBottom: setYAxisValue(10),
    borderRadius: setValue(5),
    overflow: 'hidden',
    color: 'white',
  },
  descriptionTestOutlet: {
    alignSelf: 'center',
    backgroundColor: Colors.theme_color,
    paddingHorizontal: setXAxisValue(20),
    paddingVertical: setYAxisValue(10),
    marginBottom: setYAxisValue(15),
    borderRadius: setValue(5),
    overflow: 'hidden',
    color: 'white',
  },
  content: {
    flex: 1,
    justifyContent: 'space-between',
    paddingTop: Constants.statusBarHeight,
  },
  header: {
    flexDirection: 'row',
    paddingHorizontal: setXAxisValue(20),
    paddingVertical: setYAxisValue(10),
    // backgroundColor: 'black',
    alignItems: 'center',
  },
  qrcode: StyleSheet.absoluteFillObject,
  qrcodeContentTransparent: {
    backgroundColor: 'transparent',
  },
  qrcodeContentDarkOverlay: {
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  flashMode: {
    alignSelf: 'center',
    backgroundColor: Colors.theme_color,
    paddingHorizontal: setXAxisValue(20),
    paddingVertical: setYAxisValue(10),
    marginBottom: setYAxisValue(30),
    borderRadius: setValue(5),
    overflow: 'hidden',
    color: 'white',
  },
});
