import {Platform, StyleSheet} from 'react-native';

import {Colors, Layout} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black60,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 5,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  itemName: {
    marginLeft: setXAxisValue(30),
    color: Colors.primary_color,
    marginBottom: setYAxisValue(10),
  },
  image: {
    width: '100%',
    height: undefined,
    aspectRatio: 3 / 2,
    borderRadius: setValue(10),
    backgroundColor: 'white',
  },
  imageVarinatsText:{
    fontSize: 15,
    height: 20,
    alignSelf: 'center',
    position:"absolute",
    backgroundColor:Colors.white,
    left:0, bottom:0, right:0,
    borderBottomLeftRadius:10,
    borderBottomRightRadius:10,
    paddingHorizontal:15 
  },
  summaryContainer: {
    marginBottom: setYAxisValue(10),
  },
  quantityContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  productTypeIcon: {
    width: setValue(20),
    height: setValue(20),
    marginTop: setYAxisValue(5),
    marginHorizontal: setXAxisValue(3),
  },
  updateQuantityIconContainer: {
    padding: setValue(10),
  },
  itemQuantityText: {
    fontSize: setXAxisValue(28),
    marginHorizontal: setXAxisValue(10),
    maxWidth: setValue(50),
    textAlign: 'center',
  },
  sectionContainer: {
    flexDirection: 'row',
    backgroundColor: 'white',
    borderRadius: setValue(10),
    marginTop: setYAxisValue(15),
    paddingVertical: setYAxisValue(15),
    paddingHorizontal: setXAxisValue(15),
    elevation: 3,
    ...shadow,
    // marginBottom: 20,
  },
  otherSectionContainer: {
    backgroundColor: Colors.white,
    borderRadius: setValue(10),
    marginTop: setYAxisValue(15),
    paddingVertical: setYAxisValue(15),
    paddingHorizontal: setXAxisValue(15),
    ...shadow,
  },
  summaryDescContainer: {
    flex: 1,
    marginTop: 15,
    backgroundColor: Colors.white,
    padding: 15,
    borderRadius: 10,
    ...shadow,
  },
  summaryDivider: {
    backgroundColor: 'gray',
    width: 1,
    marginHorizontal: setXAxisValue(5),
  },
  summaryPrice: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  summaryPriceSign: {
    fontSize: setXAxisValue(20),
    marginEnd: setXAxisValue(5),
  },
  summaryPriceText: {
    fontSize: setXAxisValue(20),
    fontFamily: 'Roboto-Bold',
    // marginBottom: setValue(5),
  },
  discountText: {
    paddingTop: setValue(3),
    color: Colors.color30,
    fontSize: setValue(14),
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  quantityTitle: {
    flex: 1,
    alignSelf: 'center',
    marginLeft: setXAxisValue(5),
  },
  optionsList: {
    flexGrow: 0,
    width: Layout.window.width,
  },
  optionGroupTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  optionGroupTitle: {
    // marginTop: 5,
    fontSize: setXAxisValue(16),
    color: '#CCCCCC',
  },
  optionGroupDivider: {
    height: 1.5,
    backgroundColor: 'rgba(0,0,0,0.2)',
    flex: 1,
    marginStart: setXAxisValue(10),
  },
  optionGroupDesc: {
    fontSize: setXAxisValue(11),
    color: 'red',
    marginTop: setYAxisValue(5),
    marginBottom: setYAxisValue(10),
  },
  optionItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  optionItemCheckBoxEmpty: {
    width: setValue(24),
    height: setValue(24),
  },
  optionItemText: {
    flex: 1,
    color: '#4D4D4D',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: setXAxisValue(5),
  },
  optionItemSoldOut: {
    color: 'red',
    fontSize: setXAxisValue(9),
  },
  optionItemPrice: {
    color: '#B1B1B1',
  },
  specialRequestText: {
    padding: 5,
    borderWidth: 1,
    borderColor: '#CCCCCC',
    borderRadius: 5,
    height: '100%',
    width: '75%',
    marginRight: setValue(10),
    paddingVertical: 9,
  },
  specificationContainer: {
    flexDirection: 'row',
    paddingVertical: 5,
    width: '100%',
  },
  specificationKeys: {
    fontFamily: 'Roboto-Bold',
    width: '35%',
  },
  specificationValues: {
    width: '65%',
  },
  variants: {
    padding: 5,
    paddingHorizontal: 12,
    borderWidth: 2,
    borderColor: '#ccc',
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
    marginRight: 8,
  },
  selectedVariants: {
    padding: 5,
    paddingHorizontal: 12,
    borderWidth: 2,
    backgroundColor: Colors.theme_color,
    borderRadius: 8,
    borderColor: Colors.theme_color,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
    marginRight: 8,
  },
  addQuantityButton: {
    borderWidth: 2,
    height: '100%',
    borderRadius: 5,
    borderColor: Colors.theme_color,
    backgroundColor: Colors.theme_color,
    flex: 1,
    padding: 5,
    paddingVertical: 9,
    justifyContent: 'center',
    alignItems: 'center',
  },
  addCategoryButtonWeb: {
    backgroundColor: Colors.theme_color,
    // marginTop: setValue(50),
    paddingVertical: 5,
    shadowColor: Colors.black,
    borderRadius: 5,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 5,
  },
  addToCartText: {
    textAlign: 'center',
    padding: 10,
    color: Colors.white,
  },
  pointStyle: {
    borderRadius: 100,
    borderWidth: setValue(3),
    borderColor: 'black',
    alignSelf: 'center',
  },
  shareButton: {
    marginVertical: 10,
    borderRadius: 30,
    // borderColor: Colors.black60,
    backgroundColor: Colors.theme_color,
    position: 'absolute',
    bottom: 0,
    padding: 15,
    right: 10,
    ...shadow,
  },
  shareText: {
    color: Colors.theme_color,
  },
});
