import React, {useState, useEffect} from 'react';
import {
  View,
  FlatList,
  Platform,
  Image,
  TouchableWithoutFeedback,
} from 'react-native';
import {
  MainHeader,
  MainContainer,
  AddressItem,
  LongButton,
  AppText,
} from '../../../components';
import {goBack, setValue} from '../../../utils';
import styles from './styles';
import {strings} from '../../../i18n';
import {SwipeRow} from '../../../components';
import NoRecordFound from '../../../components/NoRecordFound';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {useIsFocused} from '@react-navigation/native';

export default function BlogList(props) {
  const [blogList, setBlogList] = useState([]);

  const config = useSelector((state) => state?.config);
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const getBlogsList = async () => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios.get('/blog/list/' + config.businessId).then((response) => {
        console.log('response --> ', response);
        setBlogList(response?.data);
        dispatch(CommonActions.setLoading(false));
      });
    } catch (error) {
      dispatch(CommonActions.setLoading(false));
      console.log('message', error?.response?.message);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };

  const deleteBlog = async (id) => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios.delete('/blog/delete/' + id).then((response) => {
        console.log('response --> ', response);
        getBlogsList();
      });
    } catch (error) {
      dispatch(CommonActions.setLoading(false));
      console.log('message', error?.response?.message);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };

  useEffect(() => {
    isFocused && getBlogsList();
  }, [isFocused]);

  return (
    <View style={Platform.OS == 'web' ? styles.webContainer : styles.container}>
      <MainHeader
        title={strings('blogList.title')} // after we add in language json file
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
        rightIcon={'setting'}
        rightPress={() => {
          props.navigation.navigate('blog-category-list');
        }}
      />
      <MainContainer useView={Platform.OS === 'web' ? false : true}>
        {blogList ? (
          <FlatList
            data={blogList}
            showsVerticalScrollIndicator={false}
            keyExtractor={(item, index) => index}
            renderItem={({item, index}) => (
              <SwipeRow
                key={index}
                editable={true}
                leftOpenValue={70}
                rightOpenValue={-100}
                onPressEdit={() =>
                  props.navigation.navigate('edit-blog', {
                    item: item,
                    edit: true,
                  })
                }
                onPressDelete={() =>
                  dispatch(
                    CommonActions.setAlert({
                      visible: true,
                      content: strings('blogList.askDelete'),
                      rightButton: strings('settingScreen.confirm'),
                      rightPress: async () => {
                        deleteBlog(item?.id);
                      },
                      leftButton: strings('settingScreen.cancel'),
                      leftPress: null,
                    }),
                  )
                }
              >
                <BLogItem
                  item={item}
                  onPress={() => {
                    props.navigation.navigate('blog-detail', {item: item});
                  }}
                />
              </SwipeRow>
            )}
          />
        ) : (
          <NoRecordFound
            title={strings('blogList.noRecord.title')}
            message={strings('blogList.noRecord.message')}
          />
        )}
        {/* if platform is web then edit address button with flat list  */}
        {Platform.OS == 'web' && (
          <LongButton
            text={strings('blogList.addAddress')}
            style={{marginTop: setValue(10), marginBottom: setValue(45)}}
            onPress={() => props.navigation.navigate('edit-blog')}
          />
        )}
      </MainContainer>

      {/* if Platform os other then web then button is end of flex */}
      {Platform.OS != 'web' && (
        <LongButton
          text={strings('blogList.addAddress')}
          style={{
            width: '100%',
            borderRadius: 0,
          }}
          onPress={() => props.navigation.navigate('edit-blog')}
        />
      )}
    </View>
  );
}

const BLogItem = ({item, onPress}) => {
  const [aspectRatio, setAspectRatio] = useState(1 / 1);
  return (
    <TouchableWithoutFeedback onPress={() => onPress()}>
      <View style={styles.blogItem}>
        <Image
          source={
            item?.image
              ? {uri: item?.image}
              : require('../../../../assets/images/General/no-img-found.jpg')
          }
          style={[styles.blogImage, {aspectRatio: 30 / 17}]}
        />
        {item?.title !== null && item?.title !== '' && (
          <AppText style={styles.blogTitle} numberOfLines={2}>
            {item?.title}
          </AppText>
        )}
        {item?.description !== null && item?.description !== '' && (
          <AppText style={styles.blogDesc} numberOfLines={3}>
            {item?.description}
          </AppText>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};
