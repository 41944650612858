import {get} from 'lodash';
import moment from 'moment';
import React from 'react';
import {View, TouchableOpacity} from 'react-native';
import {useSelector} from 'react-redux';
import {strings} from '../../i18n';
import AppText from '../AppText';
import styles from './styles';

export default function CoinItem(props) {
  const {item} = props;

  const config = useSelector((state) => state.config);
  let currencySymbol = get(config, 'currencySymbol', '₹');

  return (
    <View>
      <View style={styles.container}>
        <View style={styles.topContainer}>
          <AppText style={styles.orderUId}>{'TRD00' + item?.id}</AppText>
          <AppText style={styles.orderUId}>
            {moment(item.created_at).format('DD MMM YYYY hh:mm A')}
          </AppText>
        </View>
        <View style={styles.bottomContainer}>
          <AppText
            style={styles.orderPrice}
          >{`${currencySymbol} ${item.coin}`}</AppText>
          <AppText
            style={
              item?.type === 'credit'
                ? [styles.orderPrice, {color: '#056eb0'}]
                : styles.orderPrice
            }
          >
            {item?.type == 'credit'
              ? strings('coinTransactionList.label.credit')
              : strings('coinTransactionList.label.debit')}
          </AppText>
        </View>
        <View style={styles.topContainer}>
          <AppText style={styles.orderUId}>{item?.remark}</AppText>
        </View>
      </View>
    </View>
  );
}
