// @flow

import {StyleSheet} from 'react-native';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';
import {Colors} from '../../constants';

const absoluteStretch = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
};

export default StyleSheet.create({
  container: {
    ...absoluteStretch,
    justifyContent: 'center',
  },
  menu: {
    ...absoluteStretch,
  },
  hiddenButton: {
    justifyContent: 'flex-end',
    paddingVertical: setValue(25),
    paddingHorizontal: setValue(10),
    flexDirection: 'row',
  },
  frontView: {
    flex: 1,
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundColor: 'transparent',
    overflow: 'hidden',
  },
  overlay: {
    ...absoluteStretch,
    backgroundColor: Colors.overlay,
  },
  editButton: {
    marginRight: 5,
    // padding: setValue(8),
    alignItems: 'center',
    justifyContent: 'center',
    width: setValue(32),
    height: setValue(32),
    backgroundColor: 'rgba(0,0,0,0.4)',
    borderRadius: 100,
  },
  deleteButton: {
    marginRight: 5,
    // padding: setValue(9),
    alignItems: 'center',
    justifyContent: 'center',
    width: setValue(32),
    height: setValue(32),
    backgroundColor: Colors.wallet_card_red,
    borderRadius: 100,
  },
});
