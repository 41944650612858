import React, {useState, useEffect} from 'react';
import {
  View,
  Text,
  TextInput,
  ScrollView,
  FlatList,
  Platform,
  TouchableOpacity,
} from 'react-native';
import {
  MainHeader,
  MainContainer,
  AddressItem,
  AppText,
  Icon,
  LongButton,
} from '../../../components';
import {goBack, setValue} from '../../../utils';
import styles from './styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {strings} from '../../../i18n';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {
  addressList,
  deleteAddress,
} from '../../../redux/reduxsauceActions/authActions';
import {SwipeRow} from '../../../components';
import NoRecordFound from '../../../components/NoRecordFound';
function AddressScreen(props) {
  useEffect(() => {
    props.addressList();
  }, []);

  return (
    <View style={Platform.OS == 'web' ? styles.webContainer : styles.container}>
      <MainHeader
        title={strings('addressList.title')} // after we add in language json file
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer useView={Platform.OS === 'web' ? false : true}>
        {props.address?.length > 0 || !props.address ? (
          <FlatList
            data={props.address}
            showsVerticalScrollIndicator={false}
            keyExtractor={(item, index) => index}
            renderItem={({item, index}) => (
              <SwipeRow
                editable={true}
                leftOpenValue={70}
                rightOpenValue={-100}
                onPressEdit={() =>
                  props.navigation.navigate('edit-address', {
                    item: item,
                    edit: true,
                  })
                }
                onPressDelete={() =>
                  props.openAlert(
                    strings('addressList.askDelete'),
                    strings('settingScreen.confirm'),
                    async () => {
                      props.deleteAddress(item.id);
                    },
                    strings('settingScreen.cancel'),
                    null,
                  )
                }
              >
                <AddressItem item={item} key={index} />
              </SwipeRow>
            )}
          />
        ) : (
          <NoRecordFound
            title={strings('addressList.noRecord.title')}
            message={strings('addressList.noRecord.message')}
          />
        )}
        {/* if platform is web then edit address button with flat list  */}
        {Platform.OS == 'web' && (
          <LongButton
            text={strings('addressList.addAddress')}
            style={{marginTop: setValue(10), marginBottom: setValue(45)}}
            onPress={() => props.navigation.navigate('edit-address')}
          />
        )}
      </MainContainer>

      {/* if Platform os other then web then button is end of flex */}
      {Platform.OS != 'web' && (
        <LongButton
          text={strings('addressList.addAddress')}
          style={{
            width: '100%',
            borderRadius: 0,
          }}
          onPress={() => props.navigation.navigate('edit-address')}
        />
      )}
    </View>
  );
}

export default connect(
  (state) => {
    const {user: profile, address} = state.auth;
    return {
      appConfig: state.config,
      profile,
      address,
    };
  },
  (dispatch) =>
    bindActionCreators(
      {
        addressList,
        deleteAddress,
        openAlert: (content, leftButton, leftPress, rightButton, rightPress) =>
          CommonActions.setAlert({
            visible: true,
            content,
            leftButton,
            leftPress,
            rightButton,
            rightPress,
          }),
      },
      dispatch,
    ),
)(AddressScreen);
