import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {Platform, Text, TouchableOpacity, View, FlatList} from 'react-native';
import {
  AppText,
  CheckBox,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
  TimePicker,
} from '../../components';
import {goBack, setValue} from '../../utils';
import styles from './styles';
import DateTimePicker from '@react-native-community/datetimepicker';
import {useDispatch, useSelector} from 'react-redux';
import CommonAction from '../../redux/reduxsauce/commonRedux';
import ConfigActions from '../../redux/reduxsauce/configRedux';
import axios from 'axios';
import {Colors} from '../../constants';
import {getAppConfig} from '../../redux/reduxsauceActions/configActions';
import {object} from 'prop-types';
import {strings} from '../../i18n';

export default function ShopTimingSetting(props) {
  // states
  const [isTimeVisible, setIsTimeVisible] = useState(false);

  const [dayWithTime, setDayWithTime] = useState({
    monday: {
      isOpen: false,
      // day: 'Monday',
      open_time: null,
      close_time: null,
    },
    tuesday: {
      isOpen: false,
      // day: 'Tuesday',
      open_time: null,
      close_time: null,
    },
    wednesday: {
      isOpen: false,
      // day: 'Wednesday',
      open_time: null,
      close_time: null,
    },
    thursday: {
      isOpen: false,
      // day: 'Thursday',
      open_time: null,
      close_time: null,
    },
    friday: {
      isOpen: false,
      // day: 'Friday',
      open_time: null,
      close_time: null,
    },
    saturday: {
      isOpen: false,
      // day: 'Saturday',
      open_time: null,
      close_time: null,
    },
    sunday: {
      isOpen: false,
      // day: 'Sunday',
      open_time: null,
      close_time: null,
    },
  });
  const [timeType, setTimeType] = useState('open_time');
  const [open_time, setOpen_time] = useState();
  const [close_time, setClose_time] = useState();
  const [index, setIndex] = useState('');

  // redux store`s state
  const config = useSelector((state) => state.config);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('config.pickup_time', config?.activeTime);
    // config?.activeTime && setDayWithTime(config?.activeTime);
    if (config?.activeTime && !Array.isArray(config?.activeTime)) {
      let mainObject = {};
      Object.keys(config?.activeTime).map((o) => {
        let keyArray = Object.keys(dayWithTime);

        if (keyArray.includes(o)) {
          let object = {
            ...dayWithTime[o],
            isOpen: config?.activeTime[o]?.isOpen == false ? false : true,
            close_time: !isNaN(Date.parse(config?.activeTime[o]?.close_time))
              ? new Date(config?.activeTime[o]?.close_time)
              : null,
            open_time: !isNaN(Date.parse(config?.activeTime[o]?.open_time))
              ? new Date(config?.activeTime[o]?.open_time)
              : null,
          };
          mainObject = {
            ...mainObject,
            [o]: object,
          };
        }
      });
      setDayWithTime({
        ...dayWithTime,
        ...mainObject,
      });
    }
  }, []);
  console.log('timeType', timeType, isTimeVisible);
  // function onSave
  const onSaveShopTime = () => {
    let array = Object.keys(dayWithTime).filter((o) => dayWithTime[o].isOpen);
    let index = 0;
    for (let i = 0; i < array.length; i++) {
      let key = array[i];
      if (
        dayWithTime[key].open_time == null ||
        dayWithTime[key].close_time == null
      ) {
        dispatch(
          CommonAction.setAlert({
            visible: true,
            content:
              key + ` ${strings('shopTimeing.alert.openCloseTimeRequired')}`,
          }),
        );
        return false;
      } else {
        index += 1;
      }
    }

    if (index == array.length) {
      console.log('calling');
      callApi();
    }
  };

  const callApi = () => {
    dispatch(CommonAction.setLoading(true));
    axios
      .patch(`/business/update/business-setting/${config?.businessId}`, {
        active_time: dayWithTime,
      })
      .then((response) => {
        console.log('Shop time added success ->', response);
        dispatch(CommonAction.setLoading(false));
        dispatch(getAppConfig());
        dispatch(
          CommonAction.setAlert({
            visible: true,
            content: strings('shopTimeing.alert.success'),
          }),
        );
        goBack(navigation);
      })
      .catch((error) => {
        dispatch(CommonAction.setLoading(false));
        console.log('error', error.response);
        dispatch(
          CommonAction.setAlert({
            visible: true,
            content: error?.response?.message || 'Something went wrong :(',
          }),
        );
      });
  };

  let KeyArray = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  return (
    <View
      style={
        Platform.OS === 'web'
          ? {overflow: 'auto', height: '100vh'}
          : styles.container
      }
    >
      <MainHeader
        title={
          config.type == 'retail'
            ? strings('shopTimeing.title.shopTime')
            : strings('shopTimeing.title.restaurantTime')
        }
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer>
        {/* <FlatList
          data={dayWithTime}
          keyExtractor={(item, index) => String(index)}
          showsVerticalScrollIndicator={false}
          renderItem={({item, index}) => ( */}
        {/* )}
        /> */}

        {KeyArray.map((key) => (
          <View style={styles.timeContainer} key={key}>
            <View style={{marginVertical: 6, marginHorizontal: 1}}>
              <View
                style={[
                  styles.textInputContainer,
                  {
                    flex: 1,
                    flexDirection: 'row',
                    backgroundColor: Colors.white,
                    padding: 10,
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: 'Roboto-Bold',
                    paddingVertical: 5,
                    textAlignVertical: 'center',
                    textTransform: 'capitalize',
                  }}
                >
                  {key}
                </Text>
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                  }}
                >
                  <CheckBox
                    checked={dayWithTime[key]?.isOpen}
                    onChange={(checked) => {
                      setDayWithTime({
                        ...dayWithTime,
                        [key]: {
                          ...dayWithTime[key],
                          isOpen: checked,
                        },
                      });
                    }}
                  />
                </View>
              </View>
              {dayWithTime[key]?.isOpen && (
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <TouchableOpacity
                    style={[{marginRight: 5}, styles.textInputContainer]}
                    onPress={() => {
                      setIndex(key);
                      setIsTimeVisible(true);
                      setTimeType('open_time');
                    }}
                  >
                    <AppText style={styles.inputLabel}>
                      {strings('shopTimeing.label.openTime')}
                    </AppText>
                    <View style={styles.calendarContainer}>
                      <AppText style={styles.date}>
                        {dayWithTime[key]?.open_time
                          ? moment(
                              new Date(dayWithTime[key]?.open_time),
                            ).format('hh:mm A')
                          : null}
                      </AppText>
                      <TouchableOpacity
                        onPress={() => {
                          setIndex(key);
                          setIsTimeVisible(true);
                          setTimeType('open_time');
                        }}
                      >
                        <Icon name="clock" size={18} color="gray" />
                      </TouchableOpacity>
                    </View>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={[{marginLeft: 5}, styles.textInputContainer]}
                    onPress={() => {
                      console.log('index', index);
                      setIndex(key);
                      setIsTimeVisible(true);
                      setTimeType('close_time');
                    }}
                  >
                    <AppText style={styles.inputLabel}>
                      {strings('shopTimeing.label.closeTime')}
                    </AppText>
                    <View style={styles.calendarContainer}>
                      <AppText style={styles.date}>
                        {dayWithTime[key]?.close_time
                          ? moment(
                              new Date(dayWithTime[key]?.close_time),
                            ).format('hh:mm A')
                          : null}
                      </AppText>
                      <TouchableOpacity
                        onPress={() => {
                          console.log('index', index);
                          setIndex(key);
                          setIsTimeVisible(true);
                          setTimeType('close_time');
                        }}
                      >
                        <Icon name="clock" size={18} color="gray" />
                      </TouchableOpacity>
                    </View>
                  </TouchableOpacity>
                </View>
              )}
            </View>
          </View>
        ))}
        {Platform.OS == 'web' && isTimeVisible && timeType && (
          <TimePicker
            visible={isTimeVisible}
            onClose={() => setIsTimeVisible(false)}
            onTimeChanged={(timeValue) => {
              console.log('call', timeValue);
              const event = new Date();
              event.setHours(timeValue.selectedHour, timeValue.selectedMinute);
              console.log('event', event);
              console.log('event', event.toISOString());
              setIsTimeVisible(false);
              setTimeType('');

              if (timeType == 'open_time') {
                let key = index;
                setDayWithTime({
                  ...dayWithTime,
                  [key]: {
                    ...dayWithTime[key],
                    open_time: event.toISOString(),
                  },
                });
              } else if (timeType == 'close_time') {
                let key = index;
                console.log('key', key);
                setDayWithTime({
                  ...dayWithTime,
                  [key]: {
                    ...dayWithTime[key],
                    close_time: event.toISOString(),
                  },
                });
              }
            }}
            initValue={''}
            okBtnText={'Ok'}
          />
        )}

        {isTimeVisible && timeType && Platform.OS !== 'web' && (
          <DateTimePicker
            mode="time"
            value={new Date()}
            display={Platform.OS == 'ios' ? 'spinner' : 'default'}
            is24Hour={false}
            onTouchCancel={() => {
              setIsTimeVisible(false);
            }}
            onChange={(event, time) => {
              console.log('time');
              if (event.type == 'dismissed') {
                setIsTimeVisible(false);
                setTimeType('');
              } else {
                setIsTimeVisible(false);
                setTimeType('');
                if (timeType == 'open_time') {
                  let key = index;
                  setDayWithTime({
                    ...dayWithTime,
                    [key]: {
                      ...dayWithTime[key],
                      open_time: time,
                    },
                  });
                } else if (timeType == 'close_time') {
                  let key = index;
                  console.log('key', key);
                  setDayWithTime({
                    ...dayWithTime,
                    [key]: {
                      ...dayWithTime[key],
                      close_time: time,
                    },
                  });
                }
              }
            }}
          />
        )}
        {Platform.OS == 'web' && (
          <LongButton
            text={strings('shopTimeing.button.save')}
            style={{marginTop: setValue(10), marginBottom: setValue(45)}}
            onPress={() => onSaveShopTime()}
          />
        )}
      </MainContainer>

      {Platform.OS !== 'web' && (
        <LongButton
          text={strings('shopTimeing.button.save')}
          style={{
            width: '100%',
            borderRadius: 0,
          }}
          onPress={() => onSaveShopTime()}
        />
      )}
    </View>
  );
}
