import React from 'react';
import {View, Image, Platform} from 'react-native';
import {setValue} from '../../utils';
import NotFoundImage from '../../../assets/images/General/not-found.png';
import {useSelector} from 'react-redux';
import styles from './styles';
import {AppText} from '..';

const NoRecordFound = (props) => {
  const loading = useSelector((state) => state.common.loading);
  const {hideImage, title, message} = props;
  if (loading) {
    return null;
  }
  return (
    <View style={styles.container}>
      {!hideImage && (
        <View style={styles.imageContainer}>
          {Platform.OS == 'web' ? (
            <Image
              style={{
                height: setValue(201),
                width: setValue(200),
                marginHorizontal: 5,
              }}
              source={NotFoundImage}
            />
          ) : (
            <Image
              source={NotFoundImage}
              height={setValue(200)}
              width={setValue(170)}
            />
          )}
        </View>
      )}
      <View style={styles.title}>
        <AppText style={styles.titleText}>{title}</AppText>
      </View>
      <View style={styles.borderStyle}></View>
      {message && (
        <View style={styles.message}>
          <AppText style={styles.messageText}>{message}</AppText>
        </View>
      )}
    </View>
  );
};

export default NoRecordFound;
