import {get} from 'lodash';
import React from 'react';
import {View, TouchableOpacity, Image, Text, ToastAndroid} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {AppText} from '../../components';
import {strings} from '../../i18n';
import {dateFormat, goBack, setValue} from '../../utils';
import styles from './styles';

const PromotionItem = (props) => {
  const {navigation, fetching, item} = props;

  const config = useSelector((state) => state.config);
  let currencySymbol = get(config, 'currencySymbol', '₹');

  const dispatch = useDispatch();

  return (
    <View style={styles.itemContainer}>
      <Image
        source={
          item?.image
            ? {uri: `${item.image}`}
            : require('../../../assets/images/General/no-img-found.jpg')
        }
        style={styles.promotionImage}
      />
      <View style={{padding: setValue(10)}}>
        <View style={{paddingRight: 20}}>
          <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
            <AppText style={styles.title}>{item.name}</AppText>
          </View>
        </View>
        <AppText style={styles.desc}>{item.description}</AppText>
        <TouchableOpacity
          style={styles.couponCodeContainer}
          onPress={() => {
            props.pressApply();
          }}
        >
          <Text style={styles.couponCodeText}>{item.coupon_code}</Text>
          <Text style={styles.copyText}>{'Apply'}</Text>
        </TouchableOpacity>
        <View style={styles.promotionOffer}>
          <Text style={styles.offerText}>
            {parseFloat(item.percentage_of) + '% OFF '}
            {parseFloat(item?.discount_upto_price) > 0.0 &&
              'UP TO ' + currencySymbol + ' ' + item?.discount_upto_price}
          </Text>
        </View>
      </View>
    </View>
    // config.currencySymbol
    // <TouchableOpacity
    //   {...props}
    //   style={[styles.itemContainer]}
    //   onPress={() => {
    //     // if (!fetching) {
    //     // this.props.getPromotionDetail(item?.Id, navigation);
    //     navigation.navigate('promotion-detail', {
    //       item,
    //     });
    //     // }
    //   }}
    // >
    //   <Image
    //     source={{
    //       uri: `${item?.image}`,
    //     }}
    //     resizeMode="cover"
    //     style={styles.promotionImage}
    //   />
    //   <View style={{flexDirection: 'row'}}>
    //     <AppText style={styles.title}>{item?.name}</AppText>
    //     {item?.isNew ||
    //       (true && (
    //         <Image
    //           style={styles.iconNewPromotion}
    //           source={require('../../../assets/images/General/newPromo.png')}
    //         />
    //       ))}
    //   </View>
    //   <AppText style={styles.description}>{item?.description}</AppText>
    //   <View style={styles.infoBar}>
    //     {false && item && (
    //       <View style={styles.moneyContainer}>
    //         <View style={styles.discountView}>
    //           <AppText style={styles.currencySymbolText}>
    //             {currencySymbol}
    //           </AppText>
    //           <AppText style={styles.discountText}>
    //             {currencySymbol}
    //             {item?.price}
    //           </AppText>
    //         </View>
    //         <AppText style={styles.currentPriceText}>{'100.00'}</AppText>
    //       </View>
    //     )}
    //   </View>
    // </TouchableOpacity>
  );
};

export default PromotionItem;
