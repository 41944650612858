import {bindActionCreators} from 'redux';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import axios from 'axios';
import Constants from 'expo-constants';
import React, {Component} from 'react';
import {
  ActivityIndicator,
  TextInput,
  TouchableOpacity,
  Linking,
  Platform,
  View,
} from 'react-native';
import {connect} from 'react-redux';

import {
  AppText,
  AutoHeightWebview as WebView,
  LongButton,
  MainContainer,
  MainHeader,
  SegmentControl,
} from '../../components';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import {goBack} from '../../utils';
import styles from './styles';

class RefundsPolicyScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      loading: false,
      shippingPolicyDetail: '',
      defaultPolicy: null,
    };
    this.webViewRef = null;
  }

  componentDidMount() {
    this.props.setLoading(true);
    this.getPolicy();
  }

  getPolicy = () => {
    axios
      .get('/business/policy-and-page/get')
      .then((response) => {
        console.log('response -->', response.data.message);
        this.setState({
          defaultPolicy: response.data.message,
        });
        this.props.setLoading(false);
      })
      .catch((error) => {
        console.log('error==', error?.response);
        this.props.setLoading(false);
      });
  };

  async onUpdate() {
    const {tab, refundsPolicyDetail} = this.state;
    this.props.setLoading(true);
    let body = {cancellations_refunds_policy: refundsPolicyDetail};
    await axios
      .patch(
        axios.defaults.baseURL +
          '/business/policy-and-page/update/' +
          this.state.defaultPolicy?.id,
        body,
      )
      .then((response) => {
        this.setState({shippingPolicyDetail: ''});
        this.getPolicy();
        console.log('response ------->', response);
      })
      .catch((error) => {
        this.props.openAlert(error?.response?.message);
        this.props.setLoading(false);
        this.getPolicy();
        console.log('error ----->', error?.response);
      });
  }

  render() {
    const {navigation, config} = this.props;
    return (
      <View style={styles.container}>
        <MainHeader
          title={strings('refundPolicyScreen.title')}
          leftIcon="back"
          leftPress={() => goBack(navigation)}
        />
        <MainContainer useView showFullWidth={true}>
          <View style={styles.body}>
            {['merchant', 'manager'].includes(this.props?.user?.type) && (
              <View>
                <View style={styles.textInputContainer}>
                  <TextInput
                    value={this.state.refundsPolicyDetail}
                    placeholder={strings('refundPolicyScreen.placeholder')}
                    multiline={true}
                    onChangeText={(text) =>
                      this.setState({refundsPolicyDetail: text})
                    }
                    numberOfLines={5}
                    style={styles.textInput}
                  />
                </View>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL('https://whitelabelapp.in/merchant-tools/');
                  }}
                  style={styles.createTermsAndPolicyButton}
                >
                  <AppText style={{color: Colors.facebook}}>
                    {strings('refundPolicyScreen.clickHere')}
                  </AppText>
                </TouchableOpacity>
                <View style={styles.buttonContainer}>
                  <LongButton
                    text={strings('refundPolicyScreen.update')}
                    onPress={() => this.onUpdate()}
                  />
                </View>
              </View>
            )}
            <View style={styles.webViewContainer}>
              <WebView
                originWhitelist={['*']}
                // source={{
                //   uri: `${axios.defaults.baseURL}/business/cancellations-refunds-policy/${config.businessId}`,
                // }}
                source={{
                  html: this?.state.defaultPolicy?.cancellations_refunds_policy,
                }}
                style={styles.webView}
                // onLoadStart={() => this.setState({loading: true})}
                // onLoadEnd={() => this.setState({loading: false})}
                androidHardwareAccelerationDisabled
                // startInLoadingState
                ref={(ref) => (this.webViewRef = ref)}
              />
              {/* {Platform.OS != 'web' && this.state.loading && (
                <View
                  style={{
                    position: 'absolute',
                    zIndex: 9999,
                    top: '45%',
                    alignSelf: 'center',
                  }}
                >
                  <ActivityIndicator size="large" color={Colors.theme_color} />
                </View>
              )} */}
            </View>
          </View>
        </MainContainer>
      </View>
    );
  }
}

export default connect(
  (state) => ({
    config: state.config,
    user: state.auth.user,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setLoading: CommonActions.setLoading,
        openAlert: (content, leftButton, leftPress, rightButton, rightPress) =>
          CommonActions.setAlert({
            visible: true,
            content,
            leftButton,
            leftPress,
            rightButton,
            rightPress,
          }),
      },
      dispatch,
    ),
)(RefundsPolicyScreen);

// export default RefundsPolicyScreen;
