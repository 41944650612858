import {View, Text, Platform, TouchableOpacity} from 'react-native';
import React, {useState} from 'react';
import {
  AppText,
  DropDownInput,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../components';
import {goBack} from '../../utils';
import styles from './styles';
import {strings} from '../../i18n';
import {Colors} from '../../constants';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CommonAction from '../../redux/reduxsauce/commonRedux';
import ConfigActions from '../../redux/reduxsauce/configRedux';
import axios from 'axios';

const CurrencySetting = (props) => {
  const config = useSelector((state) => state.config);

  let currencyCode = [{id: 1, name: 'INR'}];
  if (config?.ENV == 'local' || config?.ENV == 'development') {
    currencyCode.push({id: 2, name: 'USD'});
  }
  let currencySymbol = [{id: 1, name: '₹'}];
  if (config?.ENV == 'local' || config?.ENV == 'development') {
    currencySymbol.push({id: 2, name: '$'});
  }

  const dispatch = useDispatch();
  const [currencyData, setCurrencyData] = useState({
    currencyCode: '',
    currencySymbol: '',
  });

  useEffect(() => {
    const setting = config;
    setCurrencyData({
      currencyCode: setting?.currencyCode || 'INR',
      currencySymbol: setting?.currencySymbol || '₹',
    });
  }, []);

  const onSaveConfiguration = async () => {
    console.log('currencyData', currencyData);
    try {
      const response = await axios.patch(
        `/business/update/setting/${config?.businessId}`,
        {
          setting: {
            ...config,
            ...currencyData,
          },
        },
      );
      console.log('response', response);
      const configData = {
        ...config,
        ...currencyData,
      };
      dispatch(ConfigActions.setAppConfiguration(configData));
      dispatch(CommonAction.setLoading(false));
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: strings('settingScreen.success'),
        }),
      );
      // Platform.OS != 'web' && Restart();
      goBack(props.navigation);
    } catch (error) {
      console.log('error', error);
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: error?.response.message,
        }),
      );
      dispatch(CommonAction.setLoading(false));
    }
  };
  return (
    <View
      style={Platform.OS === 'web' ? styles.webContainer : styles.container}
    >
      <MainHeader
        title={strings('settingScreen.currencySetting.title')}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer useView>
        <View style={{marginTop: 10}}>
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('settingScreen.currencySetting.code')}
            </AppText>

            {/* <Picker
              mode="dropdown"
              style={styles.typePicker}
              onValueChange={(itemValue) => {
                console.log('item value', itemValue);
                setCurrencyData({...currencyData, currencyCode: itemValue});
              }}
              selectedValue={currencyCode}
            >
              {currencyCode.length > 0 &&
                currencyCode.map((o, index) => (
                  <Picker.Item key={index} label={o?.name} value={o?.name} />
                ))}
            </Picker> */}
            <DropDownInput
              inputStyle={{
                paddingHorizontal: 10,
                marginVertical: 5,
              }}
              data={currencyCode && currencyCode.map((o) => o?.name)}
              value={currencyData.currencyCode}
              onChangeText={(text) => {
                setCurrencyData({...currencyData, currencyCode: text});
              }}
            />
          </View>
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('settingScreen.currencySetting.symbol')}
            </AppText>
            {/* <Picker
              mode="dropdown"
              style={styles.typePicker}
              onValueChange={(itemValue) => {
                console.log('item value', itemValue);
                setCurrencyData({...currencyData, currencySymbol: itemValue});
              }}
              selectedValue={currencySymbol}
            >
              {currencySymbol.length > 0 &&
                currencySymbol.map((o, index) => (
                  <Picker.Item key={index} label={o?.name} value={o?.name} />
                ))}
            </Picker> */}
            <DropDownInput
              inputStyle={{
                paddingHorizontal: 10,
                marginVertical: 5,
              }}
              data={currencySymbol && currencySymbol.map((o) => o?.name)}
              value={currencyData.currencySymbol}
              onChangeText={(text) => {
                setCurrencyData({...currencyData, currencySymbol: text});
              }}
            />
          </View>
        </View>
        {Platform.OS == 'web' && (
          <LongButton
            text={strings('settingScreen.currencySetting.save')}
            onPress={onSaveConfiguration}
            style={{marginTop: 20, marginBottom: 50}}
          />
        )}
      </MainContainer>
      <View>
        {Platform.OS != 'web' && (
          <LongButton
            text={strings('settingScreen.currencySetting.save')}
            onPress={onSaveConfiguration}
            style={{borderRadius: 0}}
          />
        )}
      </View>
    </View>
  );
};

export default CurrencySetting;
