import React, { Component } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';

import { MainContainer, MainHeader, WebView } from '../../components';
import { strings } from '../../i18n';
import { goBack } from '../../utils';
import styles from './styles';


class FAQScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { navigation } = this.props;
    const  config = useSelector(state => state.config)
    return (
      <View style={styles.container}>
        <MainHeader title={strings('faqScreen.faq')} leftIcon="back" leftPress={() => goBack(navigation)} />
        <MainContainer useView>
          <View style={styles.body}>
            <View style={styles.webViewContainer}>
              <WebView originWhitelist={['*']} source={{ uri: config.faqLink }} style={styles.webView} startInLoadingState />
            </View>
          </View>
        </MainContainer>
      </View>
    );
  }
}

export default FAQScreen;
