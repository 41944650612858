import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {View, Platform} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import CommonAction from '../../redux/reduxsauce/commonRedux';
import {
  AppConfigCheckbox,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../components';
import {strings} from '../../i18n';
import {goBack, setValue} from '../../utils';
import styles from './styles';
import {getAppConfig} from '../../redux/reduxsauceActions/configActions';

const SubscriptionSetting = (props) => {
  const config = useSelector((state) => state.config);
  const dispatch = useDispatch();

  const [subscriptionData, setSubscriptionData] = useState({
    autoAccept: false,
    cancelFromUser: false,
    // enable_subscription: false,
  });

  useEffect(() => {
    const setting = config;
    setSubscriptionData({
      ...subscriptionData,
      autoAccept: setting?.subscription?.autoAccept,
      cancelFromUser: setting?.subscription?.cancelFromUser || false,
      // enable_subscription: setting?.subscription?.enable_subscription || false,
    });
  }, []);

  const saveOrderConfigData = async () => {
    dispatch(CommonAction.setLoading(true));

    try {
      const response = await axios.patch(
        `/business/update/business-setting/${config?.businessId}`,
        {
          subscription: {
            ...config?.subscription,
            ...subscriptionData,
          },
        },
      );

      console.log('response  subscription ', response);
      dispatch(getAppConfig());
      dispatch(CommonAction.setLoading(false));
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: strings('subscriptionSetting.alert.success'),
        }),
      );
      goBack(props.navigation);
    } catch (error) {
      dispatch(CommonAction.setLoading(false));
      console.log('error', error.response);
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: error?.response?.message || 'Something went wrong :(',
        }),
      );
    }
  };

  return (
    <View
      style={Platform.OS === 'web' ? styles.webContainer : styles.container}
    >
      <MainHeader
        title={strings('subscriptionSetting.title')}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer>
        <View style={styles.orderSettingContainer}>
          <AppConfigCheckbox
            icon={'foodForkDrink'}
            title={strings('subscriptionSetting.autoAccept')}
            desc={strings('subscriptionSetting.autoAcceptDesc')}
            checked={subscriptionData?.autoAccept}
            onChangeCheck={(checked) =>
              setSubscriptionData({
                ...subscriptionData,
                autoAccept: checked,
              })
            }
          />

          <AppConfigCheckbox
            icon={'foodForkDrink'}
            title={strings('subscriptionSetting.enableCancel')}
            desc={strings('subscriptionSetting.enableCancelDesc')}
            checked={subscriptionData?.cancelFromUser}
            onChangeCheck={(checked) =>
              setSubscriptionData({
                ...subscriptionData,
                cancelFromUser: checked,
              })
            }
          />

          {/* <AppConfigCheckbox
            icon={'foodForkDrink'}
            title={'Enable Subscription'}
            desc={'Allow users to subscribe products'}
            checked={subscriptionData?.enable_subscription}
            onChangeCheck={(checked) =>
              setSubscriptionData({
                ...subscriptionData,
                enable_subscription: checked,
              })
            }
          /> */}
        </View>

        {Platform.OS == 'web' && (
          <LongButton
            text={strings('subscriptionSetting.btn')}
            style={{marginTop: setValue(10), marginBottom: setValue(45)}}
            onPress={saveOrderConfigData}
          />
        )}
      </MainContainer>
      {Platform.OS !== 'web' && (
        <LongButton
          text={strings('subscriptionSetting.btn')}
          style={{
            width: '100%',
            borderRadius: 0,
          }}
          onPress={saveOrderConfigData}
        />
      )}
    </View>
  );
};

export default SubscriptionSetting;
