import React, {useState, useEffect} from 'react';
import {Text, View} from 'react-native';
import LiveActions from '../../redux/reduxsauce/liveRedux';
import {useDispatch, useSelector} from 'react-redux';

const ConnectToWebSocket = () => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  const connectToSocket = () => {
    var ws = new WebSocket(
      `ws://192.168.1.15:8000/live/merchant/data/${auth.user?.business}`,
      null,
      {
        headers: {
          Authorization: `Token ${auth.user?.token}`,
        },
      },
    );

    ws.onopen = () => {
      // connection opened
      console.log('WebSocket connected=========');
      dispatch(LiveActions.setConnected(true));
    };

    ws.onmessage = (e) => {
      // a message was received
      let data = e.data;
      console.log('WebSocket data===', data, typeof data);
      // let data1 = JSON.parse(data)
      // console.log("WebSocket data parsed===>>>",data1);
      dispatch(LiveActions.setLiveData(data));
    };

    ws.onerror = (e) => {
      // an error occurred
      console.log('WebSocket error===', e.message);
      dispatch(LiveActions.setConnected(false));
    };

    ws.onclose = (e) => {
      // connection closed
      console.log('WebSocket close===', e.code, e.reason);
      dispatch(LiveActions.setConnected(false));
    };
  };

  useEffect(() => {
    connectToSocket();
  }, []);
};

export default ConnectToWebSocket;
