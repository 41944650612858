import React, {useState, useEffect} from 'react';
import {
  View,
  FlatList,
  Platform,
  Image,
  TouchableWithoutFeedback,
} from 'react-native';
import {
  MainHeader,
  MainContainer,
  AddressItem,
  LongButton,
  AppText,
  SwipeRow,
} from '../../../../components';
import {goBack, setValue} from '../../../../utils';
import styles from './styles';
import {strings} from '../../../../i18n';
import NoRecordFound from '../../../../components/NoRecordFound';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import CommonActions from '../../../../redux/reduxsauce/commonRedux';
import {useIsFocused} from '@react-navigation/native';

export default function ExperienceList(props) {
  const [accommodationList, setAccommodationList] = useState([]);

  const config = useSelector((state) => state?.config);
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const getExperienceList = async () => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios.get('/hospitality/Experiences/list').then((response) => {
        console.log('accommodation response --> ', response);
        setAccommodationList(response?.data);
        dispatch(CommonActions.setLoading(false));
      });
    } catch (error) {
      dispatch(CommonActions.setLoading(false));
      console.log('message', error?.response?.message);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };

  const deleteExperience = async (id) => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios
        .delete('/hospitality/Experiences/deleted/' + id)
        .then((response) => {
          console.log('response of delete experience --> ', response);
          getExperienceList();
        });
    } catch (error) {
      dispatch(CommonActions.setLoading(false));
      console.log('error', error?.response?.message);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };

  useEffect(() => {
    isFocused && getExperienceList();
  }, [isFocused]);

  return (
    <View style={Platform.OS == 'web' ? styles.webContainer : styles.container}>
      <MainHeader
        title={strings('experiences.title')}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer useView={Platform.OS === 'web' ? false : true}>
        {accommodationList ? (
          <FlatList
            data={accommodationList}
            showsVerticalScrollIndicator={false}
            keyExtractor={(item, index) => index}
            renderItem={({item, index}) => (
              <SwipeRow
                key={index}
                editable={true}
                leftOpenValue={70}
                rightOpenValue={-100}
                onPressEdit={() =>
                  props.navigation.navigate('add-new-experience', {
                    item: item,
                    edit: true,
                  })
                }
                onPressDelete={() =>
                  dispatch(
                    CommonActions.setAlert({
                      visible: true,
                      content: strings('blogList.askDelete'),
                      rightButton: strings('settingScreen.confirm'),
                      rightPress: async () => {
                        deleteExperience(item?.id);
                      },
                      leftButton: strings('settingScreen.cancel'),
                      leftPress: null,
                    }),
                  )
                }
              >
                <BLogItem item={item} />
              </SwipeRow>
            )}
          />
        ) : (
          <NoRecordFound
            title={strings('blogList.noRecord.title')}
            message={strings('blogList.noRecord.message')}
          />
        )}
        {/* if platform is web then edit address button with flat list  */}
        {Platform.OS == 'web' && (
          <LongButton
            text={strings('experiences.addExperience')}
            style={{marginTop: setValue(10), marginBottom: setValue(45)}}
            onPress={() => props.navigation.navigate('add-new-experience')}
          />
        )}
      </MainContainer>

      {/* if Platform os other then web then button is end of flex */}
      {Platform.OS != 'web' && (
        <LongButton
          text={strings('experiences.addExperience')}
          style={{
            width: '100%',
            borderRadius: 0,
          }}
          onPress={() => props.navigation.navigate('add-new-experience')}
        />
      )}
    </View>
  );
}

const BLogItem = ({item, onPress}) => {
  return (
    <TouchableWithoutFeedback>
      <View style={styles.blogItem}>
        <Image
          source={
            item?.image
              ? {uri: item?.image}
              : require('../../../../../assets/images/General/no-img-found.jpg')
          }
          style={[styles.blogImage, {aspectRatio: 30 / 17}]}
        />
        {item?.title !== null && item?.title !== '' && (
          <AppText style={styles.blogTitle} numberOfLines={2}>
            {item?.name}
          </AppText>
        )}
        {item?.description !== null && item?.description !== '' && (
          <AppText style={styles.blogDesc} numberOfLines={3}>
            {item?.description}
          </AppText>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};
