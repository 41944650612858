import {StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {screenWidth, setValue, setXAxisValue, setYAxisValue} from '../../utils';
import Constants from 'expo-constants';

const textColor = Constants.manifest.extra.dark ? Colors.white : Colors.black20;
const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 5,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  addNewSliderBtn: {
    backgroundColor: Colors.theme_color,
    marginVertical: setValue(15),
    borderRadius: 5,
    borderWidth: Constants.manifest.extra.dark ? 1 : 0,
    borderColor: textColor,
  },
  addNewSliderBtnLightTheme: {
    backgroundColor: Colors.white,
    marginVertical: setValue(15),
    borderRadius: 5,
    borderWidth: 2,
    borderColor: Colors.theme_color,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 5,
  },
  btnText: {
    textAlign: 'center',
    // flex: 1,
    justifyContent: 'center',
    padding: setValue(15),
  },
  overlay: {
    backgroundColor: Colors.overlay,
    flex: 1,
    justifyContent: 'center',
  },
  modalBody: {
    backgroundColor: Colors.white,
    padding: setValue(10),
  },
  label: {
    fontFamily: 'Roboto-Bold',
    paddingVertical: setValue(10),
  },
  clientImage: {
    width: '90%',
    aspectRatio: 4 / 2,
    alignSelf: 'center',
  },
  saveSliderBtn: {
    backgroundColor: Colors.theme_color,
  },
  saveBtnText: {
    padding: setValue(15),
    textAlign: 'center',
    color: Colors.white,
    fontFamily: 'Roboto-Bold',
  },
  loading: {
    padding: 15,
  },
});
