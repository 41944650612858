import {MaterialCommunityIcons} from '@expo/vector-icons';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {
  View,
  FlatList,
  ActivityIndicator,
  Text,
  TextInput,
  TouchableOpacity,
  Platform,
} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';
import {
  Icon,
  SegmentControl,
  MainContainer,
  MainHeader,
  NoRecordFound,
} from '../../components';
import {CustomerItem} from '../../components';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import {goBack} from '../../utils';
import styles from './styles';
import CommonActions from '../../redux/reduxsauce/commonRedux';

const Customer = (props) => {
  const [selectListType, setSelectListType] = useState([
    'Customers',
    'Staff Members',
  ]);
  const [loading, setLoading] = useState(true);
  const [listType, setListType] = useState('Customers');
  const [listData, setListData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [total, setTotal] = useState(0);
  const config = useSelector((state) => state.config);

  useEffect(() => {
    listType == 'Customers'
      ? fetchListData('client, member', 0) 
      : fetchListData('merchant, manager, deliveryboy, serviceboy, operator, developer', 0);
  }, [searchText, listType]);

  const dispatch = useDispatch();

  const openAlert = (content) => {
    dispatch(
      CommonActions.setAlert({
        visible: true,
        content,
      }),
    );
  };

  let cancelToken = axios.CancelToken.source();

  const fetchListData = (type, offset = listData.length) => {
    try {
      axios
        .get(
          `/user/business/list?limit=10&offset=${offset}&search=${searchText}&type=${type}`,
          {cancelToken: cancelToken.token},
        )
        .then((response) => {
          console.log('response', response);
          setTotal(response.data?.count);
          offset == 0
            ? setListData(response.data?.results)
            : setListData(listData.concat(response.data?.results));
          setLoading(false);
        })
        .catch((error) => {
          console.log('error-->', error);
        });
    } catch (error) {
      setLoading(false);
      openAlert(error?.response?.message || 'Something Went wrong!');
    }
  };

  // useEffect(() => {
  //   console.log('customer --', listType);
  //   props.navigation.addListener('focus', () => {
  //     listType == 'Customers'
  //       ? fetchListData('customer', 0)
  //       : fetchListData('staff', 0);
  //   });
  // }, [props.navigation]);

  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('homeScreen.customers')}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer useView>
        <View style={styles.searchBarContainer}>
          <TextInput
            underlineColorAndroid="transparent"
            placeholder={strings('locationScreen.search')}
            placeholderTextColor="gray"
            autoCorrect={false}
            style={styles.searchBarText}
            enablesReturnKeyAutomatically
            returnKeyType="search"
            onChangeText={(searchText) => {
              setSearchText(searchText);
            }}
            defaultValue={searchText}
            onFocus={() => {}}
          />
          <View style={styles.searchBarIcon}>
            <Icon name="search" size={18} color="gray" />
          </View>
        </View>

        <SegmentControl
          values={selectListType}
          selectedIndex={selectListType.findIndex((o) => o == listType)}
          onChange={(index) => {
            if (typeof cancelToken != typeof undefined) {
              cancelToken.cancel('Operation canceled due to new request.');
            }
            setLoading(true);
            setListData([]);
            setListType(selectListType[index]);
          }}
        />

        <View
          style={
            Platform.OS == 'web'
              ? styles.listContainerWeb
              : styles.listContainer
          }
        >
          {loading ? (
            <View style={styles.loadingView}>
              <ActivityIndicator color={Colors.theme_color} size={40} />
            </View>
          ) : listData?.length == 0 ? (
            <View
              style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}
            >
              <NoRecordFound
                hideImage
                title={listType}
                message={'No ' + listType.toLowerCase() + ' found'}
              />
            </View>
          ) : (
            <FlatList
              removeClippedSubviews={false}
              data={listData}
              style={{marginTop: 10}}
              keyExtractor={(item, index) => String(index)}
              renderItem={({item}) => {
                return (
                  <CustomerItem
                    item={item}
                    listType={listType}
                    onPressCustomer={() =>
                      props.navigation.navigate('customer-detail', {
                        customerID: item.id,
                        memberType: listType,
                      })
                    }
                  />
                );
              }}
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              onEndReached={() =>
                listData?.length > 9 &&
                fetchListData(listType == 'Customers' ? 'client, member' : 'merchant, manager, deliveryboy, serviceboy, operator, developer')
              }
              ListFooterComponent={() =>
                listData?.length > 9 && (
                  <View style={{paddingTop: 15, paddingBottom: 20, height:50}}>
                    {listData?.length > 9 && listData?.length !== total && (
                      <ActivityIndicator color={Colors.theme_color} />
                    )}
                  </View>
                )
              }
            />
          )}
        </View>
        {/* qr code Scanner */}
        {Platform.OS != 'web' && (
          <TouchableOpacity
            style={styles.qrCodeScanner}
            onPress={() => props.navigation.navigate('scan-customers-qr')}
          >
            <MaterialCommunityIcons
              name="qrcode-scan"
              size={30}
              color={Colors.white}
            />
          </TouchableOpacity>
        )}
      </MainContainer>
    </View>
  );
};

export default Customer;
