import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  container: {
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
    borderRadius: 5,
    marginBottom: 10,
    paddingBottom: 5,
    backgroundColor: Colors.white,
  },
  titleText: {
    color: Colors.black60,
    marginLeft: 15,
    fontSize: 15,
  },
  inputLabel: {
    fontFamily: 'Roboto-Bold',
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(15),
    color: Colors.black60,
    marginTop: setYAxisValue(5),
  },
  listContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginHorizontal: 15,
    marginTop: 5,
  },
  orderPlus: {
    width: 40,
    elevation: 5,
    color: Colors.theme_color,
    backgroundColor: Colors.white,
    borderColor: Colors.theme_color,
    borderWidth: 1,
    borderRadius: 5,
    padding: 6.2,
    marginRight: 5,
    marginBottom: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  orderList: {
    padding: 5,
    marginRight: 5,
    marginBottom: 5,
    color: Colors.theme_color,
    backgroundColor: Colors.white,
    borderColor: Colors.theme_color,
    borderWidth: 1,
    borderRadius: 5,
    elevation: 5,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
