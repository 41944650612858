import AsyncStorage from '@react-native-async-storage/async-storage';
import {CommonActions as RNCommonActions} from '@react-navigation/native';
import Constants from 'expo-constants';
import React, {Component} from 'react';
import {Image, SafeAreaView, Text, TouchableOpacity, View} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {AppText, Icon, Loading, ProgressiveImage} from '../../components';
import {Colors, Images} from '../../constants';
import {strings} from '../../i18n';
import Swiper from '../../components/Swiper';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {updateSplashList} from '../../redux/reduxsauceActions/configActions';
import styles from './styles';

class IntroductionScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this.setState({loading: true});
    this.props.toggleMenu(false);
    this._isMounted = true;
    this.skipIntro();
    this.setState({loading: false});
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  skipIntro = async () => {
    if (this._isMounted) {
      const skipIntro = await AsyncStorage.getItem('skipIntro');
      if (skipIntro === 'yes') {
        this.navigateToHome();
      }
    }
  };

  renderPlaceholderImage = (image) => {
    return (
      <>
        {/* <Image
          style={styles.image}
          source={{uri: image && image}}
          resizeMode="contain"
        /> */}
        <ProgressiveImage
          style={styles.image}
          thumbnailSource={{uri: image && image}}
          source={{uri: image && image}}
          resizeMode="cover"
        />
      </>
    );
  };

  navigateToHome = () => {
    const resetAction = RNCommonActions.reset({
      index: 0,
      routes: [{name: 'login'}],
    });
    this.props.navigation.dispatch(resetAction);
  };

  onSkip = () => {
    AsyncStorage.setItem('skipIntro', 'yes');
    this.navigateToHome();
  };

  renderPagination = (index, total, context) => {
    if (total <= 1) {
      return null;
    }
    const dots = [];
    const ActiveDot = <View style={styles.activeDot} />;
    const Dot = <View style={styles.normalDot} />;
    for (let i = 0; i < total; i++) {
      dots.push(React.cloneElement(i === index ? ActiveDot : Dot, {key: i}));
    }
    return (
      <SafeAreaView>
        <View pointerEvents="none" style={styles.dotContainer}>
          {dots}
        </View>
        <View style={styles.paginationContainer}>
          <TouchableOpacity
            onPress={() => {
              if (index === 0) {
                this.onSkip();
              } else {
                context.scrollTo(index - 1);
              }
            }}
          >
            <AppText style={styles.skipText}>
              {index === 0 ? strings('common.skip') : strings('common.back')}
            </AppText>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              const nextIndex = index + 1;
              if (nextIndex < total) {
                context.scrollTo(nextIndex);
              } else {
                this.onSkip();
              }
            }}
          >
            <AppText style={styles.nextText}>
              {index === total - 1
                ? strings('common.letExplore')
                : strings('common.next')}
            </AppText>
          </TouchableOpacity>
        </View>
      </SafeAreaView>
    );
  };

  render() {
    const {loading} = this.state;
    const {config} = this.props;
    let array = config?.splash?.length > 0 ? [...config?.splash] : [];
    if (['merchant', 'manager'].includes(this.props?.user?.type)) {
      array = [...array, {static: true}];
    }
    return (
      <View style={styles.mainOnboardContainer}>
        {loading ? (
          <Loading loading />
        ) : (
          <Swiper
            showsButtons={false}
            showsPagination
            loop={false}
            renderPagination={this.renderPagination}
          >
            {array?.length > 0 &&
              array.map((o, index) => {
                if (o.static) {
                  return (
                    <View
                      style={{flex: 1, justifyContent: 'center'}}
                      key={index}
                    >
                      <TouchableOpacity
                        style={styles.addSplashBtn}
                        onPress={() => {
                          this.props.navigation.navigate(
                            'introduction-setting',
                          );
                        }}
                      >
                        <AppText
                          style={{
                            textAlign: 'center',
                            fontFamily: 'Roboto-Bold',
                          }}
                        >
                          <Icon name="add" color={Colors.black60} size={20} />
                        </AppText>
                      </TouchableOpacity>
                    </View>
                  );
                }
                return (
                  <View style={styles.sliderContainer} key={index}>
                    {['merchant', 'manager'].includes(
                      this.props?.user?.type,
                    ) && (
                      <View style={styles.btnContainer}>
                        <TouchableOpacity
                          style={styles.deleteBtn}
                          onPress={() => {
                            let newArray = [...config?.splash];
                            newArray = newArray.filter((o, i) => i != index);
                            console.log('newArray', newArray);
                            this.props.updateSplashList(newArray);
                          }}
                        >
                          <AppText style={styles.deleteIcon}>
                            <Icon name="dustBinSmaller" color={Colors.white} />
                          </AppText>
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={styles.editBtn}
                          onPress={() => {
                            this.props.navigation.navigate(
                              'introduction-setting',
                              {edit: true, item: o, index: index},
                            );
                          }}
                        >
                          <AppText style={styles.deleteIcon}>
                            <Icon
                              name="edit"
                              color={Colors.black60}
                              size={17}
                            />
                          </AppText>
                        </TouchableOpacity>
                      </View>
                    )}
                    <View style={styles.imageContainer}>
                      {this.renderPlaceholderImage(o.image)}
                    </View>
                    <View style={styles.sliderTextContainer}>
                      <AppText style={styles.headerOnboard}>{o.header}</AppText>
                      <AppText style={styles.descriptionOnboard}>
                        {o.description}
                      </AppText>
                    </View>
                  </View>
                );
              })}
          </Swiper>
        )}
      </View>
    );
  }
}

export default connect(
  (state) => ({
    user: state.auth.user,
    config: state.config,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updateSplashList: updateSplashList,
        toggleMenu: CommonActions.setMenu,
      },
      dispatch,
    ),
)(IntroductionScreen);
