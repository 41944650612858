import {Platform, StyleSheet} from 'react-native';
import {Colors} from '../../../../constants';
import {setXAxisValue, setYAxisValue, setValue} from '../../../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  webContainer: {
    overflow: 'scroll',
    height: '100vh',
  },
  blogItem: {
    backgroundColor: Colors.white,
    borderRadius: 5,
    ...shadow,
    padding: setValue(10),
    marginBottom: setYAxisValue(10),
    marginHorizontal: 0,
  },
  blogImage: {
    width: '100%',
    height: undefined,
    borderRadius: 5,
    overflow: 'hidden',
    marginBottom: 3,
  },
  blogTitle: {
    fontSize: setXAxisValue(14),
    fontFamily: 'Roboto-Bold',
    textAlign: 'left',
  },
  blogDesc: {
    marginTop: 3,
    fontFamily: 'Roboto-Regular',
  },
});
