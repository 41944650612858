import React, {useEffect, useState} from 'react';
import {Keyboard, Platform, TextInput, View} from 'react-native';
import {AppText, LongButton, MainContainer, MainHeader} from '../../components';
import {Colors} from '../../constants';
import {goBack} from '../../utils';
import styles from './styles';
import {connectActionSheet} from '@expo/react-native-action-sheet';
import {connect, useDispatch} from 'react-redux';
import {strings} from '../../i18n';
import {bindActionCreators} from 'redux';
import axios from 'axios';
import CommonActions from '../../redux/reduxsauce/commonRedux';

const EditVariant = (props) => {
  const dispatch = useDispatch();
  
  const [variantDetail, setVariantDetail] = useState({
    name: props?.route?.params?.item?.name || '',
    variant_type: props?.route?.params?.variant_type,
  });

  const onSave = async () => {
    Keyboard.dismiss();

    const {user, config, categories} = props;

    if (variantDetail.name == '') {
      props.setLoading(false);
      props.setAlert({
        visible: true,
        content: 'please add name of variant',
      });
      return;
    }

    let body = new FormData();
    body.append('name', variantDetail?.name);
    body.append('variant_type', variantDetail?.variant_type);

    const header = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    console.log('body', body);
    if (props?.route?.params?.item?.id) {
      await axios
        .patch(
          '/products/variant/update/' + props?.route?.params?.item?.id,
          body,
          header,
        )
        .then((response) => {
          goBack(navigation);
        })
        .catch((error) => {
          props.setAlert({
            visible: true,
            content: error?.response?.message,
          });
        });
    } else {
      await axios
        .post('products/variant/create', body, header)
        .then((response) => {
          console.log('response', response);
          goBack(navigation);
        })
        .catch((error) => {
          console.log('error', error.response);
          props.setAlert({
            visible: true,
            content: error?.response?.message,
          });
        });
    }
  };

  return (
    <View style={styles.container}>
      <MainHeader
        title={props?.route?.params?.item?.id ? 'Edit Variant' : 'Add Variant'}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer>
        <View>
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel} required>
              {'Name'}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              // autoCapitalize="words"
              autoCorrect={false}
              placeholder={strings('editProductCategory.placeholder.name')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
              value={variantDetail?.name}
              onChangeText={(text) =>
                setVariantDetail({...variantDetail, name: text})
              }
            />
          </View>
        </View>
        {Platform.OS == 'web' && (
          <LongButton
            text={'Save'}
            onPress={onSave}
            style={{marginTop: 20, marginBottom: 50}}
          />
        )}
      </MainContainer>
      {Platform.OS !== 'web' && (
        <LongButton
          text={'Save'}
          style={{borderRadius: 0, width: '100%'}}
          onPress={onSave}
        />
      )}
    </View>
  );
};

const ConnectedEditVariant = connectActionSheet(EditVariant);

export default connect(
  (state) => ({
    categories: state.products?.categories,
    user: state.auth?.user,
    config: state.config,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setLoading: CommonActions.setLoading,
        setAlert: CommonActions.setAlert,
      },
      dispatch,
    ),
)(ConnectedEditVariant);
