import {connectActionSheet} from '@expo/react-native-action-sheet';
import React, {useEffect, useState} from 'react';
import {
  ActivityIndicator,
  Image,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import {Icon} from '../';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import {setValue, setYAxisValue} from '../../utils';
import * as ImagePicker from 'expo-image-picker';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import CommonAction from '../../redux/reduxsauce/commonRedux';

const styles = StyleSheet.create({
  imageContainer: {
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 5,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
    padding: 5,
  },
});

function ClientAppImage(props) {
  const [loading, setLoading] = useState(false);
  const {image, index, type} = props;

  const config = useSelector((state) => state.config);
  const dispatch = useDispatch();

  const openActionSheet = () => {
    const options = [
      strings('actionSheet.takePhoto'),
      strings('actionSheet.choosePhoto'),
      strings('actionSheet.removePhoto'),
      strings('actionSheet.cancel'),
    ];
    const cancelButtonIndex = 3;

    props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) openCamera(type, index);
        if (buttonIndex === 1) openImagePicker(type, index);
        if (buttonIndex === 2) removePhoto(type, index);
      },
    );
  };

  const removePhoto = () => {
    dispatch(
      CommonAction.setAlert({
        visible: true,
        content: 'Are you sure you want to delete this image?',
        leftButton: strings('common.yes'),
        leftPress: props.ChangeClientAppImage(''),
        rightButton: strings('common.no'),
        rightPress: null,
      }),
    );
    props.ChangeClientAppImage('');
  };

  const dataURLtoFile = (dataurl) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], 'image1.jpg', {type: mime});
  };

  const openCamera = async () => {
    await ImagePicker.requestCameraPermissionsAsync();

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect:
        type == 'splash'
          ? [1, 1]
          : type == 'sharingCard'
          ? [4, 2]
          : type == 'orderCard'
          ? [4, 1.8]
          : type == 'bannerCard' && [4, 1],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      let blob = await (await fetch(result.uri)).blob();
      let body = new FormData();
      Platform.OS == 'web'
        ? body.append('image', dataURLtoFile(result?.uri))
        : body.append('image', {
            uri: result.uri,
            type: blob.type,
            name: blob.data.name,
          });
      body.append('business', config.businessId);
      setLoading(true);
      axios
        .post('/lookup/images/create', body)
        .then((response) => {
          console.log('response upload image', response);
          setLoading(false);
          props.ChangeClientAppImage(response.data?.image);
        })
        .catch((error) => {
          console.log('error upload image', error);
          setLoading(false);
          dispatch(
            CommonAction.setAlert({
              visible: true,
              content: error?.response?.message || 'Something want wrong',
            }),
          );
        });
    }
    if (result.cancelled) {
    }
  };
  useEffect(()=>{
    props.setLoading(loading)
  },[loading])

  const openImagePicker = async () => {
    await ImagePicker.requestMediaLibraryPermissionsAsync();

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect:
        type == 'splash'
          ? [1, 1]
          : type == 'sharingCard'
          ? [4, 2]
          : type == 'orderCard'
          ? [4, 1.8]
          : type == 'bannerCard' && [10, 4],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      let blob = await (await fetch(result.uri)).blob();
      let body = new FormData();
      Platform.OS == 'web'
        ? body.append('image', dataURLtoFile(result?.uri))
        : body.append('image', {
            uri: result.uri,
            type: blob.type,
            name: blob.data.name,
          });
      body.append('business', config.businessId);
      setLoading(true);
      axios
        .post('/lookup/images/create', body)
        .then((response) => {
          console.log('response upload image', response);
          setLoading(false);
          props.ChangeClientAppImage(response.data?.image);
        })
        .catch((error) => {
          console.log('error upload image', error);
          setLoading(false);
          dispatch(
            CommonAction.setAlert({
              visible: true,
              content: error?.response?.message || 'Something want wrong',
            }),
          );
        });
    }
    if (result.cancelled) {
    }
  };
  if (loading) {
    return (
      <View style={[styles.imageContainer]}>
        <View style={[props.styles, {justifyContent: 'center'}]}>
          <ActivityIndicator color="#ffb800" size="small" />
        </View>
      </View>
    );
  }
  return (
    <TouchableOpacity
      style={styles.imageContainer}
      onPress={() => openActionSheet('sharingCard')}
    >
      <Image style={props.styles} source={{uri: image}} />
      <View
        style={{
          position: 'absolute',
          alignSelf: 'center',
          top: type == 'splash' ? '50%' : '40%',
          backgroundColor: Colors.white,
          padding: 5,
          paddingHorizontal: setValue(7),
          borderRadius: setValue(5),
        }}
      >
        <Icon name="camera" color={Colors.black20} />
      </View>
    </TouchableOpacity>
  );
}

const ConnectedClientAppImage = connectActionSheet(ClientAppImage);
export default ConnectedClientAppImage;
