import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
  Platform,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {
  AppText,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../../components';
import TopProductItem from '../../../components/TopProductItem';
import CommonAction from '../../../redux/reduxsauce/commonRedux';
import {updateTopProductList} from '../../../redux/reduxsauceActions/configActions';
import {strings} from '../../../i18n';
import {goBack, screenWidth, setValue} from '../../../utils';
import styles from './styles';
import {Colors} from '../../../constants';

const SelectTopProduct = (props) => {
  const config = useSelector((state) => state.config);
  const dispatch = useDispatch();
  const [productList, setProductList] = useState([]);
  const [selectedTopProducts, setSelectedTopProducts] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  const homePage = config?.appHomePage;

  useEffect(() => {
    setSelectedTopProducts(
      (homePage?.topProducts?.length > 0 && homePage?.topProducts) || [],
    );
    fetchProductList();
  }, []);

  useEffect(() => {
    fetchProductList();
  }, [searchText]);

  const fetchProductList = async () => {
    setLoading(true);
    let url = '/v2/product/list/'+config?.businessId;
    if (searchText) {
      url += `?search=${searchText}`;
    }
    try {
      const response = await axios.get(url);
      let newArray = [];
      response?.data.map((o) => {
        if (
          homePage?.topProducts?.length > 0 &&
          homePage?.topProducts?.some((x) => x == o?.id)
        ) {
          o = {
            ...o,
            checked: true,
          };
          newArray = [...newArray, o];
        } else {
          o = {
            ...o,
            checked: false,
          };
          newArray = [...newArray, o];
        }
      });
      setProductList(newArray);
      setLoading(false);
    } catch (error) {
      console.log('error', error?.response);
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: error?.response?.message || 'Something went wrong :(',
        }),
      );
      setLoading(false);
    }
  };

  const onSaveTopProducts = () => {
    console.log('selectedTopProducts', selectedTopProducts);
    dispatch(updateTopProductList(selectedTopProducts));
    goBack(props.navigation);
  };

  const onClearText = () => {
    setLoading(true);
    setSearchText('');
  };
  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('TopSelectedProductScreen.topSelectedProduct')}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer
        useView
        showFullWidth={Platform.OS == 'web' ? true : false}
      >
        <View style={styles.searchBarContainer}>
          <TextInput
            underlineColorAndroid="transparent"
            placeholder={strings('ordering.placeOrderScreen.searchHint')}
            placeholderTextColor="gray"
            autoCorrect={false}
            style={styles.searchBarText}
            enablesReturnKeyAutomatically
            returnKeyType="search"
            onChangeText={(text) => {
              setSearchText(text);
            }}
            defaultValue={searchText}
          />
          <View style={styles.searchBarIcon}>
            {searchText.length === 0 ? (
              <Icon name="search" size={18} color="gray" />
            ) : (
              <TouchableOpacity onPress={onClearText}>
                <Icon name="cross" size={18} color="gray" />
              </TouchableOpacity>
            )}
          </View>
        </View>
        {loading ? (
          <View style={{justifyContent: 'center', flex: 1}}>
            <ActivityIndicator color={Colors.theme_color} size={'large'} />
          </View>
        ) : (
          <View>
            <FlatList
              data={productList}
              style={{width: '100%'}}
              keyExtractor={(item, index) => index}
              showsVerticalScrollIndicator={false}
              contentContainerStyle={
                Platform.OS == 'web'
                  ? {
                      width: '100%',
                      alignSelf: 'flex-start',
                      marginHorizontal: -3,
                    }
                  : {width: '100%', paddingBottom: 20}
              }
              numColumns={
                Platform.OS == 'web' && screenWidth > 576
                  ? parseInt(screenWidth / 180)
                  : 2
              }
              renderItem={({item}) => (
                <TopProductItem
                  item={item}
                  grid={true}
                  isCheckBoxVisible={true}
                  checkBoxSelectOnly={true}
                  setChecked={() => {
                    let array = [];
                    productList?.length &&
                      productList?.find((o) => {
                        if (o.id == item.id) {
                          o = {
                            ...o,
                            checked: !o.checked,
                          };
                          console.log('o', o);
                          if (o.checked) {
                            let topProducts = [
                              ...selectedTopProducts,
                              o?.id?.toString(),
                            ];
                            console.log('topProducts', topProducts);
                            setSelectedTopProducts(topProducts);
                          } else {
                            let topProducts = [...selectedTopProducts];
                            topProducts =
                              topProducts?.length &&
                              topProducts?.filter((item) => item != o.id);
                            console.log('topProducts', topProducts);
                            setSelectedTopProducts(topProducts);
                          }
                        }
                        array = [...array, o];
                      });
                    setProductList(array);
                  }}
                />
                // </View>
              )}
              ListFooterComponent={() => (
                <View style={{marginBottom: setValue(100)}}>
                  {Platform.OS == 'web' && (
                    <LongButton
                      text={'Save'}
                      style={{
                        // maxWidth: 340,
                        marginLeft: 10,
                        alignSelf: 'center',
                        marginTop: setValue(20),
                        marginBottom: setValue(45),
                      }}
                      onPress={onSaveTopProducts}
                    />
                  )}
                </View>
              )}
            />
          </View>
        )}
      </MainContainer>
      {Platform.OS !== 'web' && (
        <LongButton
          text={'Save'}
          style={{
            width: '100%',
            borderRadius: 0,
          }}
          onPress={onSaveTopProducts}
        />
      )}
    </View>
  );
};

export default SelectTopProduct;
