import React from 'react';
import {View} from 'react-native';
import {AppText} from '../../components';
import styles from './styles';

const FranchiseItem = ({item, key}) => {
  return (
    <View style={styles.addressCard} key={key}>
      <View style={styles.addressContainer}>
        <AppText style={{fontWeight: 'bold'}}>
          {item?.name}
        </AppText>
        <AppText style={{opacity: 0.7}}  numberOfLines={1}>
          {item?.street + ', ' + item?.area + ','}
        </AppText>
        <AppText  numberOfLines={1} style={{opacity: 0.7}}>
          {item?.city +
            ', ' +
            item?.state +
            ', ' +
            item?.country +
            ' - ' +
            item?.pincode}
        </AppText>
      </View>
    </View>
  );
};

export default FranchiseItem;
