import Moment from 'moment';
import React, {Component} from 'react';
import {
  Keyboard,
  Platform,
  SafeAreaView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
  AppText,
  DatePicker,
  DropDownInput,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
  TimePicker,
} from '../../components';
import {strings} from '../../i18n';
import CommonActions from '../../redux/reduxsauce/commonRedux';
// import {
//   getOrderSettingsByMode,
//   orderCheckIn,
//   updatePickupInformation,
// } from '../../redux/reduxsauceActions/orderActions';
import {getParam, goBack} from '../../utils';
import styles from './styles';

class SelectPickUpOrderConfig extends Component {
  constructor(props) {
    super(props);
    const isFromCheckoutScreen = getParam(
      this.props.route,
      'isFromCheckoutScreen',
      false,
    );

    this.state = {
      mobileNo: isFromCheckoutScreen
        ? this.props.order.orderPickDetail.pickUpContact
        : this.props.auth.profile?.mobileNo,
      isDateVisible: false,
      isTimeVisible: false,
      pickupDate: isFromCheckoutScreen
        ? new Date(
            `${
              this.props.order.orderPickDetail.pickupDate
            }${this.getTimeZone()}`,
          )
        : new Date(),
      pickupTime: isFromCheckoutScreen
        ? Moment(
            new Date(
              `${
                this.props.order.orderPickDetail.pickupDate
              }${this.getTimeZone()}`,
            ),
          )
        : Moment(new Date()),
      outletName: isFromCheckoutScreen ? this.props.order.outletName : '',
      outletID: isFromCheckoutScreen ? this.props.order.outletId : '',
      pickUpLocation: isFromCheckoutScreen
        ? this.props.order.orderPickDetail.pickUpPoint
        : '',
      chosenOutletIndex: -1,
      maxDate: new Date(),
      specialInput: isFromCheckoutScreen
        ? this.props.order.orderPickDetail.pickUpRemarks
        : '',
      hourList: [
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
      ],
      minuteList: [
        '00',
        '05',
        '10',
        '15',
        '20',
        '25',
        '30',
        '35',
        '40',
        '45',
        '50',
        '55',
      ],
      reorderItems: getParam(this.props.route, 'reorderItems', null),
      isFromCheckoutScreen,
    };
  }

  getTimeZone() {
    const offset = new Date().getTimezoneOffset();
    const o = Math.abs(offset);
    return `${
      (offset < 0 ? '+' : '-') + `00${Math.floor(o / 60)}`.slice(-2)
    }:${`00${o % 60}`.slice(-2)}`;
  }

  onPlaceOrder = () => {
    if (this.state.outletName.length === 0) {
      this.props.openAlert(strings('ordering.pickUpOrderScreen.warningOutlet'));
      return;
    }
    if (this.state.pickUpLocation.length === 0) {
      this.props.openAlert(
        strings('ordering.pickUpOrderScreen.warningLocation'),
      );
      return;
    }
    if (this.state.pickupDate.length === 0) {
      this.props.openAlert(strings('ordering.pickUpOrderScreen.warningDate'));
      return;
    }
    if (this.state.pickupTime.length === 0) {
      this.props.openAlert(strings('ordering.pickUpOrderScreen.warningTime'));
      return;
    }
    this.props.orderCheckIn(
      this.state.outletID,
      this.state.outletName,
      this.props.order.modeSetting[this.state.chosenOutletIndex].pickupSetting
        .pickUpTableNo,
      'PICKUP',
      this.props.navigation,
      this.state.reorderItems,
      {
        pickUpPoint: this.state.pickUpLocation,
        pickUpContact: this.state.mobileNo,
        pickUpRemarks: this.state.specialInput,
        pickupDate: `${Moment(this.state.pickupDate).format(
          'YYYY-MM-DD',
        )}T${this.state.pickupTime.format('HH:mm')}`,
      },
      this.state.specialInput,
    );
  };

  componentDidMount = () => {
    console.log(
      'ordering pick up :: ',
      this.props.route.params.isFromCheckoutScreen,
    );
    // this.props.getOrderSettingsByMode('PICKUP');
  };

  renderDateTime = () => {
    const date =
      this.state.pickupDate.length === 0
        ? strings('ordering.pickUpOrderScreen.setDate')
        : Moment(this.state.pickupDate).format('D MMMM YYYY');

    const time =
      this.state.pickupTime.format('HH:mm').length === 0
        ? strings('ordering.pickUpOrderScreen.setTime')
        : this.state.pickupTime.format('HH:mm');
    let filteredHour = this.state.hourList;
    if (date === Moment(new Date()).format('D MMMM YYYY')) {
      const currentHour = new Date().getHours();
      filteredHour = this.state.hourList.filter((hour) => hour >= currentHour);
    }

    return (
      <View>
        <Text style={[styles.textTitle, {marginTop: 20}]}>
          {strings('ordering.pickUpOrderScreen.pickupTimeLabel')}
        </Text>
        <View style={{flexDirection: 'row'}}>
          <TouchableOpacity
            style={{flexDirection: 'row', alignItems: 'center'}}
            onPress={() =>
              this.setState({isDateVisible: !this.state.isDateVisible})
            }
          >
            <Icon name="calendar" size={18} color="gray" />
            <Text style={[styles.textTitle, {marginLeft: 10}]}>{date}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: 30,
            }}
            onPress={() =>
              this.setState({isTimeVisible: !this.state.isTimeVisible})
            }
          >
            <Icon name="clock" size={18} color="gray" />
            <Text style={[styles.textTitle, {marginLeft: 10}]}>{time}</Text>
          </TouchableOpacity>
        </View>
        <DatePicker
          visible={this.state.isDateVisible}
          onClose={() => this.setState({isDateVisible: false})}
          minDate={new Date()}
          maxDate={this.state.maxDate}
          onDayPress={(data) =>
            this.setState({
              isDateVisible: false,
              pickupDate: data.dateString,
              pickupTime: this.state.pickupTime,
            })
          }
        />
        <TimePicker
          visible={this.state.isTimeVisible}
          onClose={() => this.setState({isTimeVisible: false})}
          hourList={filteredHour}
          minuteList={this.state.minuteList}
          onTimeChanged={(timeValue) => {
            this.setState({
              isTimeVisible: false,
              pickupTime: Moment(new Date(this.state.pickupTime)).set({
                hour: timeValue.selectedHour,
                minute: timeValue.selectedMinute,
              }),
            });
          }}
          initValue={this.state.pickupTime.format('HH:mm')}
        />
      </View>
    );
  };

  onChangeOutlet = (text) => {
    this.state.chosenOutletIndex = this.props.order.modeSetting
      .map((item) => item.outletName)
      .indexOf(text);
    const maxDate = new Date();
    if (
      typeof this.props.order.modeSetting[this.state.chosenOutletIndex]
        .pickupSetting.advancedPickup !== 'undefined'
    )
      maxDate.setDate(
        maxDate.getDate() +
          this.props.order.modeSetting[this.state.chosenOutletIndex]
            .pickupSetting.advancedPickup,
      );

    const leadTime =
      this.props.order.modeSetting[this.state.chosenOutletIndex].pickupSetting
        .pickupLeadTime;

    this.setState({
      outletName: text,
      pickUpLocation: '',
      pickupTime: Moment(new Date()).add(leadTime, 'minutes'),
      maxDate,
    });
  };

  onChangeInformation = () => {
    this.props.updatePickupInformation(
      `${Moment(this.state.pickupDate).format(
        'YYYY-MM-DD',
      )}T${this.state.pickupTime.format('HH:mm')}`,
      this.state.pickUpLocation,
      this.state.mobileNo,
      this.state.specialInput,
      this.props.navigation,
    );
  };

  renderContent = () => {
    if (this.props.order.modeSetting !== null) {
      // this.state.chosenOutletIndex = this.props.order.modeSetting
      //   .map(item => item.outletName)
      //   .indexOf(this.state.outletName);
      const pickUpLocations =
        this.state.chosenOutletIndex !== -1
          ? this.props.order.modeSetting[this.state.chosenOutletIndex]
              .pickupSetting.pickUpPoint
          : [];
      // this.state.outletID =
      //   this.state.chosenOutletIndex !== -1
      //     ? this.props.order.modeSetting[this.state.chosenOutletIndex].outletId
      //     : '';
      // this.state.outletName =
      //   this.state.chosenOutletIndex !== -1
      //     ? this.props.order.modeSetting[this.state.chosenOutletIndex]
      //         .outletName
      //     : '';
      return (
        <View style={styles.mainContent}>
          {this.props.order.modeSetting && !this.state.isFromCheckoutScreen ? (
            <View style={styles.textInputContainer}>
              <AppText style={styles.title}>
                {strings('ordering.pickUpOrderScreen.pickupOutlet')}
              </AppText>
              <DropDownInput
                data={this.props.order.modeSetting.map(
                  (item) => item.outletName,
                )}
                value={this.state.outletName}
                onChangeText={this.onChangeOutlet}
              />
            </View>
          ) : (
            <View style={styles.textInputContainer}>
              <AppText style={styles.title}>
                {strings('ordering.pickUpOrderScreen.pickupOutlet')}
              </AppText>
              <DropDownInput
                value={this.state.outletName}
                data={pickUpLocations}
                onChangeText={(text) => this.setState({pickUpLocation: text})}
                disabled
              />
            </View>
          )}
          <View style={styles.textInputContainer}>
            <AppText style={styles.title}>
              {strings('ordering.pickUpOrderScreen.pickupLocation')}
            </AppText>
            <DropDownInput
              data={pickUpLocations}
              value={this.state.pickUpLocation}
              onChangeText={(text) => this.setState({pickUpLocation: text})}
            />
          </View>
          <Text style={[styles.customerInfo, {marginTop: 15}]}>
            {this.props.auth.profile?.nickname}
          </Text>
          <TextInput
            editable={false}
            selectTextOnFocus={false}
            style={styles.mobileNoInput}
            value={this.state.mobileNo}
            onChangeText={(mobileNo) => this.setState({mobileNo})}
          />
          {this.props.route.params.isFromCheckoutScreen &&
            this.renderDateTime()}
          <Text style={styles.textTitle}>
            {strings('ordering.pickUpOrderScreen.pickupSpecial')}
          </Text>
          <TextInput
            style={styles.specialInput}
            multiline
            numberOfLines={Platform.OS === 'ios' ? null : 5}
            minHeight={Platform.OS === 'ios' ? 20 * 5 : null}
            value={this.state.specialInput}
            returnKeyType="done"
            blurOnSubmit
            onSubmitEditing={Keyboard.dismiss}
            maxLength={200}
            onChangeText={(text) => this.setState({specialInput: text})}
          />
          <View style={{flexDirection: 'row'}}>
            <Icon
              name="infoCircle"
              style={{marginVertical: 10, marginEnd: 5}}
            />
            <Text style={styles.textTitle}>
              {strings('ordering.pickUpOrderScreen.instruction')}
            </Text>
          </View>
        </View>
      );
    }
    return null;
  };

  renderButtons = () => {
    return (
      <SafeAreaView>
        <View style={{flexDirection: 'row', justifyContent: 'center'}}>
          <LongButton
            text={strings('ordering.pickUpOrderScreen.cancelButton')}
            style={styles.buttonLeft}
            onPress={() => goBack(this.props.navigation)}
          />
          <LongButton
            text={strings('ordering.pickUpOrderScreen.placeOrderButton')}
            style={styles.buttonRight}
            onPress={
              this.state.isFromCheckoutScreen
                ? this.onChangeInformation
                : this.onPlaceOrder
            }
          />
        </View>
      </SafeAreaView>
    );
  };

  render() {
    return (
      <View style={styles.container}>
        <MainHeader
          title={strings('ordering.pickUpOrderScreen.title')}
          leftIcon="back"
          leftPress={() => goBack(this.props.navigation)}
        />
        <MainContainer useView>
          <KeyboardAwareScrollView>
            {this.renderContent()}
          </KeyboardAwareScrollView>
        </MainContainer>
        {this.renderButtons()}
      </View>
    );
  }
}

export default connect(
  (state) => ({
    order: state.order,
    auth: state.auth,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        // getOrderSettingsByMode,
        // orderCheckIn,
        // updatePickupInformation,
        openAlert: (content) =>
          CommonActions.setAlert({visible: true, title: '', content}),
      },
      dispatch,
    ),
)(SelectPickUpOrderConfig);
