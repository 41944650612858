import {StyleSheet} from 'react-native';
import {Colors} from '../../../../constants';
import {
  setValue,
  setXAxisValue,
  setYAxisValue,
} from '../../../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
};
export default StyleSheet.create({
  container: {
    flex: 1,
  },

  profileStrengthContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    borderTopRightRadius: setValue(90) / 2,
    borderBottomRightRadius: setValue(90) / 2,
    marginBottom: setYAxisValue(20),
    marginTop: 15,
  },
  imageContainer: {
    width: 300,
    aspectRatio: 30 / 17,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
    backgroundColor: Colors.white,
  },
  image: {
    width: 300,
    aspectRatio: 30 / 17,
    borderRadius: 5,
  },
  cameraIcon: {
    backgroundColor: Colors.white,
    padding: 5,
    paddingHorizontal: setValue(7),
    borderRadius: setValue(5),
    position: 'absolute',
    alignSelf: 'center',
    top: '40%',
  },
  textInputContainer: {
    minHeight: 70,
    backgroundColor: Colors.white,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
    ...shadow,
  },
  textInput: {
    height: setValue(50),
    padding: 5,
    flex: 1,
    paddingHorizontal: setXAxisValue(10),
  },
  multiLineTextInput: {
    padding: setValue(10),
    textAlignVertical: 'top',
  },
  title: {
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  iconDropdown: {
    top: setYAxisValue(25),
  },
  pickerStyle: {
    marginTop: setValue(70),
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: setYAxisValue(10),
    marginBottom: setYAxisValue(30),
    marginHorizontal: 1,
  },
  settingView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: setXAxisValue(15),
    paddingHorizontal: setXAxisValue(10),
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
    backgroundColor: Colors.white,
    ...shadow,
  },
  actionTitle: {
    fontSize: setXAxisValue(14),
    color: Colors.black60,
  },
});
