import {StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
  borderRadius: 5,
  backgroundColor: Colors.white,
};

export default StyleSheet.create({
  overlay: {
    backgroundColor: Colors.overlay,
    flex: 1,
    justifyContent: 'center',
  },
  mainContainer: {
    backgroundColor: Colors.white,
    padding: setValue(10),
    marginHorizontal: setValue(15),
    borderRadius: 5,
    maxWidth: setValue(360),
    width: '100%',

    alignSelf: 'center',
  },
  loadingContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 100,
  },
  headerTitle: {
    fontFamily: 'Roboto-Bold',
    fontSize: setValue(18),
    marginBottom: setYAxisValue(15),
    textAlign: 'center',
  },
  checkboxFullContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginBottom: 7,
  },
  checkBoxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkboxText: {
    marginLeft: 10,
    fontFamily: 'Roboto-Regular',
  },
  itemContainer: {
    borderWidth: 1,
    borderColor: Colors.border,
    flex: 1,
    padding: 5,
    ...shadow,
    paddingHorizontal: 10,
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  CompanyName: {
    fontFamily: 'Roboto-Bold',
    fontSize: setValue(15),
    marginBottom: 3,
  },
  textInputContainer: {
    backgroundColor: Colors.white,
    marginBottom: setYAxisValue(10),
    // ...shadow,
  },
  inputLabel: {
    // marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(14),
    fontFamily: 'Roboto-Bold',
    color: Colors.black20,
    marginBottom: setYAxisValue(5),
  },
  textInput: {
    height: setValue(40),
    padding: 0,
    borderWidth: 1,
    borderColor: Colors.border,
    borderRadius: 5,
    // flex: 1,
    paddingHorizontal: setXAxisValue(10),
  },
  textInputWeb: {
    height: setValue(50),
    padding: setValue(8),
    borderWidth: 1,
    borderColor: Colors.border,
    borderRadius: 5,
    width:"100%",
    paddingHorizontal: setXAxisValue(10),
  },
  scanButtonContainer: {
    width: '20%',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  scanButton: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: Colors.theme_color,
    borderRadius: 5,
    elevation: 5,
    height: setValue(40),
  },
  scanBarcodeContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectBox: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: Colors.border,
    borderRadius: setValue(5),
    padding: 10,
    paddingHorizontal: 15,
    marginBottom: setValue(5),
  },
  selfCheckbox: {
    flexDirection: 'row',
    // paddingBottom: setValue(10),
  },
});
