import {Platform, StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {screenWidth, setValue, setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  webContainer: {
    overflow: 'visible',
    height: '100vh',
  },
  orderSettingContainer: {
    flex: 1,
  },
});
