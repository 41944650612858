import {Camera} from 'expo-camera';
import React, {Component} from 'react';
import {TouchableOpacity, Vibration, View} from 'react-native';
import BarcodeMask from 'react-native-barcode-mask';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {AppText, Icon} from '../../components';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {goBack} from '../../utils';
import styles from './styles';
import Constants from 'expo-constants';
import {BarCodeScanner} from 'expo-barcode-scanner';

class ScanDeliveryPartner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scanned: false,
      flashModeOn: false,
      cameraAvailable: false,
    };
  }

  componentDidMount() {
    this.checkCameraPermission();
  }

  checkCameraPermission = async () => {
    const {status} = await Camera.requestCameraPermissionsAsync();
    if (status === 'granted') {
      this.setState({cameraAvailable: true});
    }
  };

  showErrorAlert = () => {
    this.props.openAlert(
      strings('ordering.checkInOrderScreen.qrCodeUnknown'),
      strings('ordering.checkInOrderScreen.tryAgain'),
      () => this.setState({scanned: false}),
      strings('ordering.checkInOrderScreen.cancel'),
      () => goBack(this.props.navigation),
    );
  };

  handleBarCodeScanned = async ({data}) => {
    console.log('datas ---->', data);
    Vibration.vibrate();
    try {
      if (data) {
        this.setState({scanned: true}),
          this.props.route.params?.scanBarcode({trackingId: data});
        this.props.navigation.goBack();
      }
    } catch ({message}) {
      this.showErrorAlert();
    }
  };

  render() {
    return (
      <View style={styles.container}>
        {this.state.cameraAvailable ? (
          <Camera
            onBarCodeScanned={
              this.state.scanned ? null : this.handleBarCodeScanned
            }
            // barCodeScannerSettings={{
            //   barCodeTypes: [BarCodeScanner.Constants.BarCodeType.code128],
            // }}
            style={styles.qrcode}
            type={Camera.Constants.Type.back}
            flashMode={
              this.state.flashModeOn
                ? Camera.Constants.FlashMode.torch
                : Camera.Constants.FlashMode.off
            }
          >
            <BarcodeMask
              edgeColor={Colors.green}
              animatedLineColor={Colors.wallet_card_red}
              outerMaskOpacity={0}
              lineAnimationDuration={1200}
            />
            <View
              style={[
                styles.content,
                this.state.scanned
                  ? styles.qrcodeContentDarkOverlay
                  : styles.qrcodeContentTransparent,
              ]}
            >
              <View style={styles.header}>
                <TouchableOpacity
                  style={{flexDirection: 'row', alignItems: 'center'}}
                  onPress={() => goBack(this.props.navigation)}
                >
                  <Icon name="back" color="white" size={20} />
                  <AppText style={styles.title}>
                    {'Scan Delivery Partner'}
                  </AppText>
                </TouchableOpacity>
              </View>
              <View>
                <TouchableOpacity
                  onPress={() =>
                    this.setState((prev) => ({flashModeOn: !prev.flashModeOn}))
                  }
                >
                  <AppText style={styles.flashMode}>{`${strings(
                    'ordering.checkInOrderScreen.flashMode',
                  )}: ${this.state.flashModeOn ? 'off' : 'on'}`}</AppText>
                </TouchableOpacity>
              </View>
            </View>
          </Camera>
        ) : null}
      </View>
    );
  }
}

export default connect(
  (state) => ({
    user: state.auth.user,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        openAlert: (content, leftButton, leftPress, rightButton, rightPress) =>
          CommonActions.setAlert({
            visible: true,
            content,
            leftButton,
            leftPress,
            rightButton,
            rightPress,
          }),
      },
      dispatch,
    ),
)(ScanDeliveryPartner);
