import React from 'react';
import {View, TouchableOpacity, Text} from 'react-native';
import {Colors} from '../../constants';
import {ProgressiveImage} from '..';
import styles from './styles';
import Icon4 from '@expo/vector-icons/FontAwesome';
import {useSelector} from 'react-redux';

export default function HomeBannerCard(props) {
  const {item} = props;
  const auth = useSelector((state) => state.auth);
  return (
    <View style={styles.container}>
      <TouchableOpacity
        // onPress={props?.onPress}
        style={styles.imageContainer}
      >
        <ProgressiveImage
          thumbnailSource={{uri: item?.image}}
          source={{uri: item?.image}}
          style={styles.image}
          resizeMode="cover"
        />
      </TouchableOpacity>
    </View>
  );
}
