import React, {useState} from 'react';
import {TextInput, View, TouchableOpacity, Platform} from 'react-native';

import {AppText, LongButton, MainContainer, MainHeader} from '../../components';
import {
  goBack,
  isDateValidBelow,
  isEmail,
  isPhoneNumberValid,
} from '../../utils';
import styles from './styles';
import {useDispatch, useSelector} from 'react-redux';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {strings} from '../../i18n';
import axios from 'axios';
import {getAppConfig} from '../../redux/reduxsauceActions/configActions';

const ContactUs = (props) => {
  const dispatch = useDispatch();
  const {config, auth} = useSelector((state) => state);
  const {navigation, route} = props;
  const [bussinessName, setBussinessName] = useState(
    config?.contactUs?.bussinessName || '',
  );
  const [email, setEmail] = useState(config?.contactUs?.email || '');
  const [mobile, setMebile] = useState(config?.contactUs?.mobile || '');
  const [address, setAddress] = useState(config?.contactUs?.address || '');

  const onSave = async () => {
    if (!bussinessName || bussinessName == '') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('contactUs.alert.bussinessName'),
        }),
      );
      return;
    }
    if (mobile == '' && email == '') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('contactUs.alert.mobileAndEmail'),
        }),
      );
      return;
    }
    if (email != '' && !isEmail(email)) {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('contactUs.alert.email'),
        }),
      );
      return;
    }
    let body = {
      bussinessName,
    };
    if (email != '') {
      body = {...body, email};
    }
    if (mobile != '') {
      body = {...body, mobile};
    }
    if (address != '') {
      body = {...body, address};
    }
    dispatch(CommonActions.setLoading(true));
    await axios
      .patch('/business/update/business-setting/' + config.businessId, {
        contact_us: body,
      })
      .then((response) => {
        dispatch(getAppConfig());
        dispatch(CommonActions.setLoading(false));
        goBack(navigation);
      })
      .catch((error) => {
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.message,
          }),
        );
        dispatch(CommonActions.setLoading(false));
      });
  };

  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('contactUs.title')}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer showsVerticalScrollIndicator={false}>
        {!['merchant', 'manager'].includes(auth?.user?.type) ? (
          <View>
            <View style={styles.contactDetailContainer}>
              <AppText style={styles.contactUsTitle}>
                {config?.contactUs?.bussinessName || '-'}
              </AppText>
              <View style={styles.contactDetail}>
                <AppText style={styles.contactUsTitleText}>
                  {strings('contactUs.label.contact')}
                </AppText>
                <AppText style={styles.contactUsText}>
                  {config?.contactUs?.mobile || '-'}
                </AppText>
              </View>
              <View style={styles.contactDetail}>
                <AppText style={styles.contactUsTitleText}>
                  {strings('contactUs.label.email')}
                </AppText>
                <AppText style={styles.contactUsText}>
                  {config?.contactUs?.email || '-'}
                </AppText>
              </View>
              {config?.contactUs?.address && (
                <View style={{padding: 5}}>
                  <AppText style={styles.contactUsTitleText}>
                    {strings('contactUs.label.address')}
                  </AppText>
                  <AppText style={styles.contactUsText}>
                    {config?.contactUs?.address || '-'}
                  </AppText>
                </View>
              )}
            </View>
          </View>
        ) : (
          <View>
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>
                {strings('contactUs.label.bussinessName')}
              </AppText>
              <TextInput
                style={styles.textInput}
                placeholder={strings('contactUs.placehoder.bussinessName')}
                onChangeText={(text) => setBussinessName(text)}
                value={bussinessName}
              />
            </View>
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>
                {strings('contactUs.label.email')}
              </AppText>
              <TextInput
                style={styles.textInput}
                placeholder={strings('contactUs.placehoder.email')}
                onChangeText={(text) => setEmail(text)}
                value={email}
              />
            </View>
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>
                {strings('contactUs.label.contact')}
              </AppText>
              <TextInput
                style={styles.textInput}
                placeholder={strings('contactUs.placehoder.contact')}
                keyboardType="numeric"
                onChangeText={(text) => setMebile(text)}
                value={mobile}
              />
            </View>
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>
                {strings('contactUs.label.address')}
              </AppText>
              <TextInput
                multiline={true}
                numberOfLines={3}
                style={{paddingVertical: 8, textAlignVertical: 'top'}}
                placeholder={strings('contactUs.placehoder.address')}
                onChangeText={(text) => setAddress(text)}
                value={address}
              />
            </View>
            {Platform.OS == 'web' && (
              <LongButton
                style={{marginTop: 20}}
                text={strings('contactUs.button.title')}
                onPress={onSave}
              />
            )}
          </View>
        )}
      </MainContainer>
      {['merchant', 'manager'].includes(auth?.user?.type) &&
        Platform.OS != 'web' && (
          <LongButton
            style={{width:"100%", borderRadius:0}}
            text={strings('contactUs.button.title')}
            onPress={onSave}
          />
        )}
    </View>
  );
};
export default ContactUs;
