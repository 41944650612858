import {StyleSheet} from 'react-native';

import {Colors} from '../../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
  borderRadius: 8,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  textInputContainer: {
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 5,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
  },
  inputLabel: {
    fontFamily: 'Roboto-Bold',
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  iconStyle: {
    height: setValue(50),
  },
  webIconStyle: {
    height: setValue(60),
  },
  textInput: {
    height: setValue(50),
    color: Colors.black20,
    paddingHorizontal: setXAxisValue(10),
  },
  textInputWeb: {
    height: setValue(50),
    padding: setValue(10),
    flex: 1,
    paddingHorizontal: setXAxisValue(10),
  },
  typePicker: {
    height: setValue(50),
    padding: 0,
    // flex: 1,
    paddingHorizontal: setXAxisValue(10),
  },
  saveBtnContainer: {
    backgroundColor: Colors.theme_color,
  },
  saveBtnText: {
    textAlign: 'center',
    fontFamily: 'Roboto-Bold',
    color: Colors.white,
    padding: 15,
  },
});
