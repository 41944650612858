import React, {useEffect, useState} from 'react';
import {Platform, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {AppText, MainContainer, MainHeader} from '../../../components';
import {Colors} from '../../../constants';
import {strings} from '../../../i18n';
import {goBack} from '../../../utils';
import styles from './styles';

export default function EditInformation(props) {
  return (
    <View style={styles.container}>
      <MainHeader
        title={'Business Information'}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer>
        {/* components  */}
        <View>
        <View style={styles.textInputContainer}>
          <Text style={styles.inputLabel}>
            {"firstName"}
          </Text>
          <TextInput
            underlineColorAndroid="transparent"
            // autoCapitalize="words"
            autoCorrect={false}
            placeholder={"first name"}
            placeholderTextColor={Colors.color30}
            returnKeyType="done"
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={name}
            onChangeText={text => this.setState({name: text})}
          />
        </View>
        </View>


        {Platform.OS == 'web' && (
          <View style={styles.buttonContainerWeb}>
            <TouchableOpacity
              style={
                Platform.OS === 'web'
                  ? styles.buttonButtonWeb
                  : styles.buttonButton
              }
              // onPress={this.onSave}
            >
              <AppText style={styles.buttonText}>
                {strings('editProfileScreen.save')}
              </AppText>
            </TouchableOpacity>
          </View>
        )}
      </MainContainer>
      {Platform.OS !== 'web' && (
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            style={
              Platform.OS === 'web'
                ? styles.buttonButtonWeb
                : styles.buttonButton
            }
            // onPress={this.onSave}
          >
            <AppText style={styles.buttonText}>
              {strings('editProfileScreen.save')}
            </AppText>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
}
