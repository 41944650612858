import axios from 'axios';
import get from 'lodash.get';
import React, {Component} from 'react';
import {
  FlatList,
  TextInput,
  TouchableOpacity,
  View,
  ActivityIndicator,
  Platform,
} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
  AppText,
  DatePicker,
  Icon,
  MainContainer,
  MainHeader,
  NoRecordFound,
  OrderItem,
  SegmentControl,
  MerchantOrderItem,
} from '../../components';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import {cashFormat, getParam, goBack, isDateValid, setValue} from '../../utils';
import styles from './styles';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {AntDesign} from '@expo/vector-icons';

let cancelToken = axios.CancelToken.source();
class MerchantOrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      searchText: '',
      searchDate: '',
      isDateVisible: false,
      isFromWallet: getParam(props.route, 'isFromWallet', false),
      refreshingActive: false,
      refreshingCompleted: false,
      loadMore: false,
      orderList: [],
      orderType: '',
      type: '',
      limit: 10,
      offset: 0,
      loading: false,
      totalCount: 0,
      selectOrderType: [],
    };
    this.currencySymbol = get(props.appConfig, 'currencySymbol', '₹');
    this.pageActive = 1;
    this.pageCompleted = 1;
    this.onEndReachedCalledDuringMomentum = {
      active: true,
      completed: true,
    };
  }

  componentDidMount = () => {
    const {navigation} = this.props;

    let selectOrderType = [];
    if (this.props.appConfig?.order?.pickup) {
      selectOrderType = [...selectOrderType, 'PickUp'];
    }
    if (this.props.appConfig?.order?.delivery) {
      selectOrderType = [...selectOrderType, 'Delivery'];
    }
    if (
      this.props.appConfig?.type == 'restaurants' &&
      this.props.appConfig?.order?.dinein
    ) {
      selectOrderType = [...selectOrderType, 'Dine-In'];
    }

    this.setState({selectOrderType: selectOrderType}, () => {
      this.setState(
        {
          type:
            selectOrderType[0]?.toLowerCase() == 'dine-in'
              ? 'dinein'
              : selectOrderType[0]?.toLowerCase(),
          orderType: selectOrderType[0],
          limit: 10,
          offset: 0,
          searchText: '',
          orderList: [],
          searchDate: '',
          searchDate: this.state?.searchDate?.dateString
            ? this.state?.searchDate?.dateString
            : '',
        },
        () => {
          console.log('calling');
          this.fetchOrderList();
        },
      );
    });
    navigation.addListener('focus', () => {
      this.handleRefresh();
    });
  };

  fetchOrderList = (text) => {
    const {limit, offset, searchText, orderList, searchDate, type} = this.state;
    const {appConfig, user} = this.props;

    let url = '/order/merchant/list?limit=' + limit;
    if (user?.type == 'deliveryboy') {
      url += '&type=delivery';
    } else {
      url += '&type=' + type;
    }
    if (offset > 0) {
      url += '&offset=' + offset;
    }
    if (searchText) {
      url += '&search=' + searchText;
    }
    let date = text ? text : searchDate?.dateString;

    if (date) {
      url += '&created_at=' + date;
    }

    if (cancelToken) {
      cancelToken.cancel();
      cancelToken = axios.CancelToken.source();
    }

    // console.log('url-->', url);
    this.setState({loading: true});
    try {
      axios
        .get(url, {
          cancelToken: cancelToken.token,
        })
        .then((response) => {
          // console.log('response-------------->>>>>>>>>', response);
          offset > 0
            ? this.setState({
                orderList: orderList.concat(response?.data?.results),
                loading: false,
                totalCount: response?.data?.count,
              })
            : this.setState({
                orderList: response?.data?.results,
                loading: false,
                totalCount: response?.data?.count,
              });
        })
        .catch((error) => {
          // console.log('error-->', error);
          // this.props.onpenAlert(error?.response?.message);
          this.setState({loading: false});
        });
    } catch (error) {
      this.setState({loading: false});
      this.props.onpenAlert(error?.response?.message);
      console.log('error---->', error);
    }
  };

  handleRefresh = () => {
    if (this.state.type && this.state.orderType) {
      this.setState(
        {
          limit: 10,
          offset: 0,
          searchText: '',
          orderList: [],
          searchDate: '',
        },
        () => this.fetchOrderList(),
      );
    }
    // if (status === 'active') {
    //   this.pageActive = 1;
    // } else {
    //   this.pageCompleted = 1;
    // }
    // this.setState({
    //   refreshingActive: status === 'active',
    //   refreshingCompleted: status === 'completed',
    // });
    // await this.props.getPreviousOrders(
    //   1,
    //   this.state.searchText,
    //   this.state.searchDate,
    //   status,
    // );
    // this.setState({refreshingActive: false, refreshingCompleted: false});
  };

  handleLoadMore = (status) => {
    const {offset, orderList} = this.state;
    console.log('aa->');
    this.setState(
      {
        offset: orderList.length,
      },
      () => this.fetchOrderList(),
    );
  };

  // onCheck = debounce(async () => {
  //   this.props.getPreviousOrders(
  //     1,
  //     this.state.searchText,
  //     this.state.searchDate,
  //     'active',
  //   );
  //   this.props.getPreviousOrders(
  //     1,
  //     this.state.searchText,
  //     this.state.searchDate,
  //     'completed',
  //   );
  // }, 700);

  // onReorderAll = item => {
  //   // console.log('checking order id :: ', item.previousCompletedOrders.id);
  //   // alert(this.props.order && this.props.order.id);
  //   const reorderItems = item.orderItems.filter(e => !e.fromVoucherProfileId);

  //   if (this.state.isFromWallet) {
  //     this.props.navigation.navigate('ChooseOrderType', {
  //       reorderItems,
  //     });
  //   } else if (item.id) {
  //     this.props.addItemsToOrder(
  //       reorderItems,
  //       this.props.navigation,
  //       strings('ordering.orderHistoryScreen.reorderSuccessful'),
  //     );
  //   } else {
  //     this.props.orderCheckIn(
  //       reorderItems,
  //       this.props.navigation,
  //       strings('ordering.orderHistoryScreen.reorderSuccessful'),
  //     );
  //   }
  // };

  onSeeOrderDetail = (item) => {
    this.props.navigation.navigate('order-status', {
      // statusId: item.statusId,
      // orderId: item.orderRefNo,
      orderId: item.id, // TODO_CHANGE
    });
  };
  onSeeOrderHistory = (item) => {
    this.props.navigation.navigate('order-history-detail', {
      id: item.id,
    });
  };

  renderCurrentOrders = () => {
    const {orderList, limit, totalCount} = this.state;
    return (
      <View style={{paddingTop: 10, flex: 1}}>
        <FlatList
          data={orderList}
          renderItem={({item}) => (
            <MerchantOrderItem item={item} navigation={this.props.navigation} />
          )}
          refreshing={this.state.refreshingActive}
          onRefresh={() => this.handleRefresh('active')}
          keyboardShouldPersistTaps="always"
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          keyExtractor={(item) => item.id}
          onEndReached={() => {
            if (orderList.length < 7) {
              return;
            }
            orderList.length > limit - 1 &&
              totalCount != orderList.length &&
              this.handleLoadMore('active');
          }}
          onEndReachedThreshold={0.5}
          onMomentumScrollBegin={() => {
            this.onEndReachedCalledDuringMomentum.active = false;
          }}
          ListFooterComponent={() =>
            orderList?.length !== totalCount ? (
              <View style={{padding: Platform.OS == 'web' ? 30 : 20}}>
                <ActivityIndicator size="large" color="#000" />
              </View>
            ) : null
          }
        />
      </View>
    );
  };

  renderItemInOrder = ({item}) => {
    let customizeText = item.productOptions
      ? item.productOptions.length > 0
        ? item.productOptions[0].items.map(
            (e) => (customizeText = `${e.name.trim()}. `),
          )
        : ''
      : '';

    return (
      <View style={{marginBottom: 5, flexDirection: 'row'}}>
        <View style={{flex: 1}}>
          <AppText
            style={{color: item.fromVoucherProfileId ? 'green' : 'black'}}
          >
            {item.outletProduct.name}
          </AppText>
          {customizeText.length > 0 && (
            <AppText style={{fontSize: 12, color: 'gray'}}>
              {customizeText}
            </AppText>
          )}
          {item.fromVoucherProfileId && (
            <AppText style={styles.vcRedeemed}>{`${strings(
              'ordering.myDiningCart.voucherRedeemed',
            )}`}</AppText>
          )}
        </View>
        <AppText style={{}}>{item.orderQty}</AppText>
      </View>
    );
  };

  render() {
    const {
      tab,
      isDateVisible,
      searchDate,
      orderList,
      offset,
      loading,
      orderType,
      selectOrderType,
    } = this.state;
    const {user} = this.props;

    return (
      <View style={styles.container}>
        <MainHeader
          title={strings('ordering.orderHistoryScreen.title')}
          leftIcon="back"
          leftPress={() => goBack(this.props.navigation)}
        />
        <MainContainer useView>
          <View style={styles.textInputContainer}>
            <TextInput
              ref={(input) => {
                this.passwordInput = input;
              }}
              underlineColorAndroid="transparent"
              autoCapitalize="none"
              autoCorrect={false}
              placeholder={strings('transactionHistoryScreen.search')}
              placeholderTextColor={Colors.color30}
              style={styles.textInput}
              returnKeyType="done"
              value={this.state.searchText}
              onChangeText={(text) => {
                this.setState({searchText: text, offset: 0}, () =>
                  this.fetchOrderList(),
                );
              }}
            />

            {this.state.searchText.length === 0 ? (
              <TouchableOpacity style={styles.inputRightSide}>
                <Icon name="search" size={22} color={Colors.black20} />
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                style={styles.inputRightSide}
                onPress={() => this.handleRefresh()}
              >
                <Icon name="cross" size={22} color="gray" />
              </TouchableOpacity>
            )}
          </View>

          <View style={styles.filterByDateContainer}>
            <AppText style={styles.titleFilter}>
              {strings('transactionHistoryScreen.filterByDate')}
            </AppText>
            <DatePicker
              title={
                <AppText style={styles.date}>
                  {(searchDate && searchDate.dateString) ||
                    strings('transactionHistoryScreen.noFilter')}
                </AppText>
              }
              visible={isDateVisible}
              onClose={() =>
                this.setState({isDateVisible: false, searchDate: ''})
              }
              onDayPress={(date) => {
                this.setState({
                  isDateVisible: false,
                  offset: 0,
                  searchDate: date,
                });
                this.fetchOrderList();
              }}
              onCancelPress={() => {
                this.setState({isDateVisible: false, searchDate: ''});
              }}
              onSetDataForClass={(text) => {
                this.setState({
                  offset: 0,
                  searchDate: text,
                });
                if (isDateValid(text)) {
                  console.log('text ------------>', text);
                  this.fetchOrderList(text != '--' && text);
                }
              }}
              onCalenderPress={() => {
                if (searchDate) {
                  this.setState({isDateVisible: false, searchDate: ''}, () =>
                    this.fetchOrderList(),
                  );
                  // this.onCheck();
                } else {
                  this.setState({isDateVisible: true, searchDate});
                }
              }}
              navigation={this.props.navigation}
            />
          </View>

          {user?.type != 'deliveryboy' && (
            <SegmentControl
              values={selectOrderType}
              selectedIndex={selectOrderType.findIndex((o) => o == orderType)}
              onChange={(index) => {
                if (cancelToken) {
                  console.log('calling-->', cancelToken);
                  cancelToken.cancel(
                    'This request is cancel due to new request',
                  );
                  cancelToken = axios.CancelToken.source();
                }
                this.setState({
                  orderType: selectOrderType[index],
                });
                if (selectOrderType[index] == 'PickUp') {
                  this.setState(
                    {
                      limit: 10,
                      offset: 0,
                      orderList: [],
                      type: 'pickup',
                    },
                    () => this.fetchOrderList(),
                  );
                } else if (selectOrderType[index] == 'Dine-In') {
                  this.setState(
                    {
                      limit: 10,
                      offset: 0,
                      orderList: [],
                      type: 'dinein',
                    },
                    () => this.fetchOrderList(),
                  );
                } else {
                  this.setState(
                    {
                      limit: 10,
                      offset: 0,
                      orderList: [],
                      type: 'delivery',
                    },
                    () => this.fetchOrderList(),
                  );
                }
              }}
            />
          )}
          {offset == 0 && loading ? (
            <View
              style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}
            >
              <ActivityIndicator size="large" color={Colors.theme_color} />
            </View>
          ) : (
            <View useView style={{flex: 1}}>
              {orderList?.length == 0 ? (
                <NoRecordFound
                  title="Orders"
                  message="We'll notify you when there is something now!"
                  hideImage={true}
                />
              ) : (
                this.renderCurrentOrders()
              )}
            </View>
          )}

          {/* qr code Scanner */}
          {Platform.OS != 'web' && (
            <TouchableOpacity
              style={styles.qrCodeScanner}
              onPress={() => this.props.navigation.navigate('scan-order-qr')}
            >
              <AntDesign name="qrcode" size={30} color={Colors.white} />
            </TouchableOpacity>
          )}
        </MainContainer>
      </View>
    );
  }
}

export default connect(
  (state) => ({
    appConfig: state.config,
    user: state.auth.user,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setLoading: CommonActions.setLoading,
        onpenAlert: (content) =>
          CommonActions.setAlert({visible: true, content: content}),
      },
      dispatch,
    ),
)(MerchantOrderList);
