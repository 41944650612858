import Constants from 'expo-constants';
import {StyleSheet, Platform} from 'react-native';

import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: setXAxisValue(15),
    height:setValue(35),
  },
  menuIconViewLeft: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuIconViewRight: {
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: Colors.white,
    width: setValue(32),
    height: setValue(32),
    borderRadius: setValue(32) / 2,
    marginLeft: setXAxisValue(10),
  },
  WebMenuItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: setValue(5),
    height: setValue(32),
    paddingHorizontal: setValue(8),
    marginLeft: setXAxisValue(15),
  },
  tableDetail: {
    alignItems: 'center',
    justifyContent: 'center',
    height: setValue(30),
    alignSelf: 'center',
    backgroundColor: Colors.white,
    borderRadius: setValue(15),
    paddingHorizontal: setValue(8),
    marginLeft: setXAxisValue(10),
  },
  tableDetailText: {
    fontSize: setXAxisValue(12),
    fontWeight: '700',
  },
  title: {
    flex: 1,
    fontSize: setXAxisValue(20),
    marginHorizontal: setXAxisValue(8),
    color: Colors.primary_color,
    paddingRight: setXAxisValue(10),
    paddingLeft: setXAxisValue(8),
  },
  safeView: {
    backgroundColor: Colors.theme_color,
    paddingBottom: setYAxisValue(10),
    paddingTop:
      Platform.OS === 'web' ? setYAxisValue(10) : Constants.statusBarHeight,
  },
  rightContainer: {
    flexDirection: 'row',
  },
  badgeContainer: {
    position: 'absolute',
    right: setXAxisValue(-10),
    top: setYAxisValue(-10),
    width: setValue(22),
    height: setValue(22),
    borderRadius: setValue(22),
    backgroundColor: Colors.wallet_card_red,
    alignItems: 'center',
    justifyContent: 'center',
  },
  cartBadgeContainer:{
    position: 'absolute',
    right: setXAxisValue(-5),
    top: setYAxisValue(-3),
    width: setValue(18),
    height: setValue(18),
    borderRadius: setValue(22),
    backgroundColor: Colors.wallet_card_red,
    alignItems: 'center',
    justifyContent: 'center',
  },
  badge: {
    color: Colors.white,
    textAlign: 'center',
    fontSize: setXAxisValue(10),
  },
});
