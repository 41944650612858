import {StyleSheet} from 'react-native';

import {Colors} from '../../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../../utils';

const wrapper = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 8,
  padding: setValue(5),
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  profileStrengthContainer: {
    // ...wrapper,
    flexDirection: 'row',
    // justifyContent: 'space-between',
    justifyContent: 'center',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    borderTopRightRadius: setValue(90) / 2,
    borderBottomRightRadius: setValue(90) / 2,
    marginBottom: setYAxisValue(20),
    marginTop: 15,
  },
  imageContainer: {
    width: setValue(100),
    height: setValue(100),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
    backgroundColor: Colors.white,
  },
  image: {
    width: setValue(100),
    height: setValue(100),
    borderRadius: 5,
  },
  textInputContainer: {
    minHeight: 70,
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 5,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
  },
  multiLineTextInput: {
    padding: setValue(10),
    textAlignVertical: 'top',
  },

  inputLabel: {
    // color: Colors.black,
    // fontSize: setValue(17),
    // paddingBottom: setYAxisValue(7),
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    fontFamily: 'Roboto-Bold',
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  textInput: {
    height: setValue(50),
    padding: 0,
    flex: 1,
    paddingHorizontal: setXAxisValue(10),
  },
  textInputWeb: {
    height: setValue(50),
    padding: setValue(10),
    flex: 1,
    paddingHorizontal: setXAxisValue(10),
  },
  cameraIcon: {
    backgroundColor: Colors.white,
    padding: 5,
    paddingHorizontal: setValue(7),
    borderRadius: setValue(5),
    position: 'absolute',
    alignSelf: 'center',
    top: '38%',
  },
  subCategoryConatiner: {
    paddingTop: 8,
  },
  subCatogoryTitle: {
    // textAlign:"center",
    fontFamily: 'Roboto-Bold',
    marginVertical: 5,
    marginBottom: 10,
    fontSize: 16,
  },
  staticItemContainer: {
    backgroundColor: Colors.white,
    marginVertical: 7,
    marginHorizontal: 3,
    borderRadius: 5,
    ...wrapper,
  },
  iconContainer: {
    textAlign: 'center',
    padding: 10,
  },
  iconStyle: {
    color: Colors.black60,
  },
});
