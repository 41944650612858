import {StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {setValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 5,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  title: {
    fontSize: setValue(18),
    marginBottom: setYAxisValue(15),
    fontFamily: 'Roboto-Bold',
    color: Colors.black60,
  },
  topSliderContainer: {
    height: setValue(180),
  },
  topSliderContainerWeb: {
    height: setValue(180),
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
  },
  topSliderItem: {
    height: '100%',
    borderRadius: 5,
  },
  topSliderStaticItem: {
    height: '100%',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    backgroundColor: Colors.white,
    marginHorizontal: 1,
  },
  topSliderImage: {
    aspectRatio: 4 / 2,
    width: '100%',
    height: undefined,
    borderRadius: 5,
  },
  topSliderImageWeb: {
    display: 'flex',
    height: '100%',
    width: '100%',
    borderRadius: 5,
  },
  productContainer: {
    borderRadius: 5,
    marginLeft: -5,
  },
  addTopProductItemButton: {
    flex: 1,
    width: setValue(200),
    borderRadius: 5,
    padding: 0,
    backgroundColor: Colors.white,
    marginHorizontal: 7,
    marginBottom: 4,
    justifyContent: 'center',
    alignItems: 'center',
    ...shadow,
  },
  addTopProductItemButtonWeb: {
    flex: 1,
    width: setValue(200),
    borderRadius: 5,
    backgroundColor: Colors.white,
    marginHorizontal: 7,
    marginBottom: 4,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 5,
  },
  addTopProductItemButtonText: {
    flex: 1,
    height: '100%',
    display: 'flex',
    borderWidth: 2,
    textAlign: 'center',
    textAlignVertical: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  addTopProductItemButtonTextWeb: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    textAlign: 'center',
  },
  addBannerCardBtn: {
    backgroundColor: Colors.white,
    borderRadius: 5,
    elevation: 5,
    width: '100%',
    height: 130,
    justifyContent: 'center',
    alignItems: 'center',
  },
  addBannerCardBtnWeb: {
    backgroundColor: Colors.white,
    borderRadius: 5,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 5,
    width: '100%',
    height: 92,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
