import React, {useEffect, useState} from 'react';
import {
  Keyboard,
  Platform,
  TextInput,
  View,
  FlatList,
  TouchableOpacity,
} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {
  AppText,
  LongButton,
  MainContainer,
  MainHeader,
  Icon,
  NoRecordFound,
  CategoryItem,
} from '../../../components';
import {Colors} from '../../../constants';
import {strings} from '../../../i18n';
import {goBack, setValue} from '../../../utils';
import styles from './styles';
import {connectActionSheet} from '@expo/react-native-action-sheet';
import axios from 'axios';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {useDispatch, useSelector} from 'react-redux';
import {useIsFocused} from '@react-navigation/native';

const AddNewFloor = (props) => {
  const item = props?.route?.params?.item;
  const [roomList, setRoomList] = useState([]);
  const [floorDetail, setFloorDetail] = useState({
    name: item?.name ? item?.name : '',
    description: item?.description ? item?.description : '',
  });
  const dispatch = useDispatch();
  const config = useSelector((state) => state?.config);
  const auth = useSelector((state) => state?.auth);
  const isFocused = useIsFocused();

  const onSave = async () => {
    Keyboard.dismiss();
    dispatch(CommonActions.setLoading(true));

    if (floorDetail.name == '') {
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('addNewFloor.alert.name'),
        }),
      );
      return;
    }
    if (floorDetail.description == '') {
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('addNewFloor.alert.description'),
        }),
      );
      return;
    }

    let body = new FormData();
    body.append('name', floorDetail?.name.trim());
    floorDetail?.description.trim().length > 0 &&
      body.append('description', floorDetail?.description.trim());

    const header = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    if (item?.id) {
      await axios
        .patch('/restaurant/floor/update/' + item?.id, body, header)
        .then((response) => {
          console.log('response update floor', response);
          dispatch(CommonActions.setLoading(false));
          props.navigation.navigate('floors');
        })
        .catch((error) => {
          console.log('error update floor --', error?.response);
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: error?.response?.message,
            }),
          );
          dispatch(CommonActions.setLoading(false));
        });
    } else {
      await axios
        .post('/restaurant/floor/create', body, header)
        .then((response) => {
          console.log('response create floor', response);
          dispatch(CommonActions.setLoading(false));
          props.navigation.navigate('floors');
        })
        .catch((error) => {
          console.log('error create floor', error.response);
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: error?.response?.message,
            }),
          );
          dispatch(CommonActions.setLoading(false));
        });
    }
  };

  const getRoomList = async () => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios
        .get('/restaurant/room/list/' + config.businessId)
        .then((response) => {
          console.log('response --> roomList', response);
          let array = [];
          if (['merchant', 'manager'].includes(auth.user.type)) {
            array = [{static: true}, ...response.data];
          } else {
            array = [...response.data];
          }
          setRoomList(array);
          dispatch(CommonActions.setLoading(false));
        });
    } catch (error) {
      dispatch(CommonActions.setLoading(false));
      console.log('message roomList', error?.response);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };

  useEffect(() => {
    isFocused && getRoomList();
  }, [isFocused]);

  const deleteRoom = async (id) => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios.delete('/restaurant/room/delete/' + id).then((response) => {
        console.log('response of delete room--> ', response);
        dispatch(CommonActions.setLoading(false));
        getRoomList();
      });
    } catch (error) {
      dispatch(CommonActions.setLoading(false));
      console.log('message', error?.response?.message);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };

  return (
    <View style={styles.container}>
      <MainHeader
        title={
          item?.id
            ? strings('addNewFloor.editFloor')
            : strings('addNewFloor.createFloor')
        }
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer>
        <KeyboardAwareScrollView>
          <>
            <View style={styles.textInputContainer}>
              <AppText style={styles.name} required>
                {strings('addNewFloor.title.floorName')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={strings('addNewFloor.placeHolder.name')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={styles.textInput}
                value={floorDetail?.name}
                onChangeText={(text) =>
                  setFloorDetail({
                    ...floorDetail,
                    name: text,
                  })
                }
              />
            </View>

            <View style={styles.textInputContainer}>
              <AppText style={styles.name} required>
                {strings('addNewFloor.title.description')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={strings('addNewFloor.placeHolder.description')}
                placeholderTextColor={Colors.color30}
                numberOfLines={3}
                multiline={true}
                returnKeyType="done"
                style={styles.multiLineTextInput}
                value={floorDetail?.description}
                onChangeText={(text) =>
                  setFloorDetail({
                    ...floorDetail,
                    description: text,
                  })
                }
              />
            </View>

            {item?.id && (
              <View style={styles.subCategoryConatiner}>
                <AppText style={styles.subCatogoryTitle}>
                  {strings('addNewFloor.rooms')}
                </AppText>
                {roomList.length > 0 ? (
                  <>
                    <FlatList
                      data={roomList}
                      showsVerticalScrollIndicator={false}
                      keyExtractor={(item, index) => index}
                      renderItem={({item, index, isActive}) => {
                        if (item.static) {
                          return (
                            <TouchableOpacity
                              style={styles.staticItemContainer}
                              onPress={() =>
                                props.navigation.navigate('edit-room', {
                                  floorItem: props?.route?.params?.item,
                                })
                              }
                            >
                              <AppText style={styles.iconContainer}>
                                <Icon
                                  name="add"
                                  size={18}
                                  style={styles.iconStyle}
                                />
                              </AppText>
                            </TouchableOpacity>
                          );
                        }
                        return (
                          <>
                            {item?.floor === props?.route?.params?.item?.id && (
                              <RoomItemContainer
                                key={index}
                                item={item}
                                onEditPress={() =>
                                  props.navigation.navigate('edit-room', {
                                    edit: true,
                                    item: item,
                                    floorItem: props?.route?.params?.item,
                                  })
                                }
                                onDeletePress={() =>
                                  dispatch(
                                    CommonActions.setAlert({
                                      visible: true,
                                      content: strings('addNewRoom.askDelete'),
                                      rightButton: strings(
                                        'settingScreen.confirm',
                                      ),
                                      rightPress: () => {
                                        deleteRoom(item?.id);
                                      },
                                      leftButton: strings(
                                        'settingScreen.cancel',
                                      ),
                                      leftPress: null,
                                    }),
                                  )
                                }
                              />
                            )}
                          </>
                        );
                      }}
                      ListFooterComponent={() => (
                        <View style={{height: 100}}></View>
                      )}
                    />
                  </>
                ) : (
                  <NoRecordFound
                    title={strings('addNewFloor.noRecord.title')}
                    message={strings('addNewFloor.noRecord.message')}
                  />
                )}
              </View>
            )}
          </>
          {Platform.OS == 'web' && (
            <LongButton
              onPress={() => onSave()}
              loading={false}
              text={
                item?.id
                  ? strings('addNewGallery.edit')
                  : strings('editProductCategory.button.save')
              }
              style={{
                marginTop: setValue(20),
                marginBottom: setValue(45),
              }}
            />
          )}
        </KeyboardAwareScrollView>
      </MainContainer>

      {Platform.OS !== 'web' && (
        <LongButton
          onPress={() => onSave()}
          loading={false}
          text={
            item?.id
              ? strings('addNewGallery.edit')
              : strings('editProductCategory.button.save')
          }
          style={{borderRadius: 0, width: '100%'}}
        />
      )}
    </View>
  );
};

const RoomItemContainer = (props) => {
  return (
    <TouchableOpacity style={styles.itemContainer} key={props.item?.id}>
      <View style={styles.roomDetailContainer}>
        {props.item?.name !== null && props.item?.name !== '' && (
          <AppText style={styles.roomName} numberOfLines={2}>
            {props.item?.name}
          </AppText>
        )}
        {props.item?.description !== null && props.item?.description !== '' && (
          <AppText style={styles.roomDesc} numberOfLines={3}>
            {props.item?.description}
          </AppText>
        )}
      </View>
      <View style={styles.buttonContainer}>
        <TouchableOpacity onPress={props.onEditPress} style={styles.button}>
          <Icon name="edit" size={setValue(18)} color="#fff" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.button} onPress={props.onDeletePress}>
          <Icon name="dustBinSmaller" size={setValue(18)} color="#fff" />
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  );
};

const ConnectedAddNewFloor = connectActionSheet(AddNewFloor);

export default ConnectedAddNewFloor;
