import {connectActionSheet} from '@expo/react-native-action-sheet';
import React, {Component} from 'react';
import {FlatList, Image, Linking, TouchableOpacity, View} from 'react-native';
import MapView from 'react-native-maps';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {AppText, Icon, MainContainer, MainHeader} from '../../components';
import {strings} from '../../i18n';
import styles from './styles';
import * as Location from 'expo-location';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import axios from 'axios';
import Constants from 'expo-constants';

// const LATITUDE = 3.166;
// const LONGITUDE = 101.6527;
const LATITUDE_DELTA = 0.0922;
const LONGITUDE_DELTA = 0.0421;

class LocationMapScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLocation: {
        latitude: '',
        longitude: '',
        latitudeDelta: '',
        longitudeDelta: '',
      },
      locationPermission: false,
      franchiseList: [],
    };
  }

  componentDidMount() {
    this.getCurrentLocation();
    this.getFranchiseList();
    this.unsubscribe = this.props.navigation.addListener('focus', () => {
      // this.handleRefresh();
    });
  }

  getFranchiseList = async () => {
    this.props.setLoading(true);
    await axios
      .get('/business/franchise/list/' + this.props.config?.businessId)
      .then((response) => {
        console.log('response->', response);
        this.setState({
          franchiseList: response.data,
        });
        this.props.setLoading(false);
      })
      .catch((error) => {
        this.props.setLoading(false);
        console.log('error  ->', error?.response);
      });
  };

  getCurrentLocation = async () => {
    // const currentLocation = await getLocation();
    let {status} = await Location.requestForegroundPermissionsAsync();
    if (status !== 'granted') {
      // setErrorMsg('Permission to access location was denied');
      this.props.openAlert('Permission to access location was denied');
      return;
    }
    let location = await Location.getCurrentPositionAsync({});

    this.setState({
      locationPermission: true,
      currentLocation: {
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
        latitudeDelta: LATITUDE_DELTA,
        longitudeDelta: LONGITUDE_DELTA,
      },
    });
  };

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  // handleRefresh = async () => {
  //   await this.props.getNearByOrderingByPage(1);
  // };

  onViewableItemsChanged = ({viewableItems}) => {
    if (viewableItems.length) {
      const currentOutletLatLng = {
        latitude: viewableItems[0].item.outlet.latitude,
        longitude: viewableItems[0].item.outlet.longitude,
        latitudeDelta: LATITUDE_DELTA,
        longitudeDelta: LONGITUDE_DELTA,
      };
      this.mapView.animateToRegion(currentOutletLatLng);
    }
  };

  openActionSheet = (detail) => {
    const options = ['Waze', 'Google Maps', strings('actionSheet.cancel')];
    const cancelButtonIndex = 3;

    this.props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        let url = '';
        switch (buttonIndex) {
          case 0:
            url = `https://waze.com/ul?q=${detail.name}&ll=${detail.latitude},${detail.longitude}&z=10&navigate=yes`;
            break;
          case 1:
            url = `https://www.google.com/maps/search/?api=1&query=${detail.latitude},${detail.longitude}`;
            break;
          default:
            return;
        }
        Linking.openURL(url);
      },
    );
  };

  fetchDistanceBetweenPoints = (lat1, lng1, lat2, lng2) => {
    // Pass Latitude & Longitude of both points as a parameter

    let apiKey = Constants.manifest.android?.config?.googleMaps?.apiKey;
    var urlToFetchDistance =
      'https://maps.googleapis.com/maps/api/distancematrix/json?units=metric&origins=' +
      lat1 +
      ',' +
      lng1 +
      '&destinations=' +
      lat2 +
      '%2C' +
      lng2 +
      '&key=' +
      apiKey;
    console.log('url->', urlToFetchDistance);

    // fetch(urlToFetchDistance)
    //   .then((res) => {
    //     return res.json();
    //   })

    axios
      .get(urlToFetchDistance)
      .then((res) => {
        // var distanceString = res.rows[0].elements[0].distance.text;
        console.log('respobnse->', res);

        // Do your stuff here
      })
      .catch((error) => {
        console.log('Problem occurred', error);
      });
  };

  render() {
    // const { navigation, locationOrders } = this.props;
    const {franchiseList, currentLocation} = this.state;
    return (
      <View style={styles.container} key="location">
        <MainHeader
          title={'Location Map'}
          // rightIcon="listView" rightPress={() => navigation.navigate('Location')}
        />
        <MainContainer useView showFullWidth={true}>
          {this.state.locationPermission && (
            <MapView
              ref={(ref) => (this.mapView = ref)}
              provider={MapView.PROVIDER_GOOGLE}
              showsUserLocation={true}
              style={styles.mapStyle}
              zoomEnabled={true}
              zoomControlEnabled={true}
              zoomTapEnabled={true}
              initialRegion={this.state.currentLocation}
            >
              {franchiseList?.map((o, index) => (
                <MapView.Marker
                  key={index}
                  coordinate={{
                    latitude: parseFloat(o.latitude),
                    longitude: parseFloat(o.longitude),
                  }}
                  title={o.name}
                />
              ))}
            </MapView>
          )}

          <View style={styles.flatList}>
            <FlatList
              removeClippedSubviews={false}
              data={franchiseList}
              renderItem={({item}) => {
                console.log('item->', item);

                this.fetchDistanceBetweenPoints(
                  parseFloat(currentLocation.latitude),
                  parseFloat(currentLocation.longitude),
                  parseFloat(item?.latitude),
                  parseFloat(item?.longitude),
                );
                return (
                  <View style={styles.itemContainer}>
                    <View style={styles.itemBody}>
                      <AppText style={styles.title}>{item?.name}</AppText>
                      <View style={styles.descContainer}>
                        <AppText style={styles.desc} numberOfLines={2}>
                          {/* {item?.name+" Company name"} */}
                          {/* {this.props.config?.businessName} */}
                          {item?.street +
                            ', ' +
                            item?.area +
                            ', ' +
                            item?.city +
                            ', ' +
                            item?.state +
                            ', ' +
                            item?.country +
                            ' - ' +
                            item?.pincode}
                        </AppText>
                      </View>
                      <View style={styles.distanceContainer}>
                        <View style={styles.distance}>
                          <AppText style={styles.kmText}>
                            {Math.round(25.22)} Km
                          </AppText>
                        </View>
                        <TouchableOpacity
                          style={styles.directionContainer}
                          onPress={() => this.openActionSheet(item)}
                        >
                          <Icon name="location_arrow" size={22} />
                        </TouchableOpacity>
                      </View>
                    </View>
                    {/* <View style={styles.outLetImageContainer}>
                      <TouchableOpacity
                        // onPress={() =>
                        //   navigation.navigate('LocationDetail', {
                        //     data: item?.outlet?.id,
                        //   })
                        // }
                      >
                        <Image
                          source={{
                            // uri: `/${item?.outlet?.imageURI}`
                            uri: `${this.props.config?.logo}`
                          }}
                          resizeMode="contain"
                          style={styles.outLetImage}
                        />
                      </TouchableOpacity>
                    </View> */}
                  </View>
                );
              }}
              horizontal
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              keyExtractor={(item) => item.id}
              keyboardShouldPersistTaps="always"
              onEndReachedThreshold={0.5}
              viewabilityConfig={{
                itemVisiblePercentThreshold: 100,
              }}
              // onViewableItemsChanged={this.onViewableItemsChanged}
            />
          </View>
        </MainContainer>
      </View>
    );
  }
}

const ConnectedLocationMapScreen = connectActionSheet(LocationMapScreen);

export default connect(
  (state) => ({
    config: state.config,
    // locationOrders: state.location.locationOrders,
    // fetching: state.location.fetching || false
  }),
  (dispatch) =>
    bindActionCreators(
      {
        // getNearByOrderingByPage
        openAlert: (content) =>
          CommonActions.setAlert({visible: true, content}),
        setLoading: (value) => CommonActions.setLoading(value),
      },
      dispatch,
    ),
)(ConnectedLocationMapScreen);
