import Constants from 'expo-constants';
import {StyleSheet} from 'react-native';

import {Colors, Layout} from '../../../constants';
import {
  screenWidth,
  setValue,
  setXAxisValue,
  setYAxisValue,
} from '../../../utils';

const wrapper = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 8,
  padding: setValue(5),
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
});
