import React from 'react';
import {ActivityIndicator, TouchableOpacity} from 'react-native';
import {Colors} from '../../constants';

import AppText from '../AppText';
import styles from './styles';

export default (props) => (
  <TouchableOpacity
    style={[
      styles.container,
      props.disabled ? styles.disabledContainer : {},
      props.style,
    ]}
    onPress={props.onPress}
    disabled={props.disabled}
  >
    {props.loading ? (
      <ActivityIndicator color={Colors.white} size={'small'} />
    ) : (
      <AppText style={[styles.text, props.textStyle]}>{props.text}</AppText>
    )}
  </TouchableOpacity>
);
