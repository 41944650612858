import {
  AntDesign,
  createIconSet,
  Entypo,
  FontAwesome,
  FontAwesome5,
  Fontisto,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  Octicons,
} from '@expo/vector-icons';
import Icon4 from '@expo/vector-icons/FontAwesome';
import React from 'react';

const bike = <MaterialIcons name="delivery-dining" size={25} />;
const bigBike = <MaterialIcons name="delivery-dining" size={35} />;
const boxOpen = <FontAwesome5 name="box-open" size={24} />;
const car = <MaterialCommunityIcons name="car-side" size={25} />;
const calendar = <Icon4 name="calendar" size={25} />;
const email = <MaterialCommunityIcons name="email" size={24} />;
const clock = <FontAwesome5 name="clock" size={25} />;
const calendarSmall = <Icon4 name="calendar" size={20} />;
const copy = <AntDesign name="copy1" size={24} />;
const facebook = <Icon4 name="facebook-square" size={35} />;
const minus = <AntDesign name="minus" size={18} />;
const camera = <AntDesign name="camera" size={22} />;
const video = <AntDesign name="video" size={22} />;
const clip = <AntDesign name="paperclip" size={22} />;
const plus = <Icon4 name="plus" size={18} />;
const dustBin = <Icon4 name="trash" size={25} />;
const dustBinSmaller = <Icon4 name="trash" size={18} />;
const login = <Icon4 name="sign-in" size={35} />;
const history = <Icon4 name="history" size={30} />;
const gift = <Icon4 name="gift" size={30} />;
const send = <Ionicons name="send" size={20} />;
const link = <Icon4 name="link" size={30} />;
const cart = <Icon4 name="shopping-cart" size={25} />;
const arrowRight = <Icon4 name="arrow-right" size={20} />;
const arrowLeft = <Icon4 name="arrow-left" size={20} />;
const arrowRightBig = <Icon4 name="arrow-right" size={40} />;
const gridView = <Icon4 name="th-large" size={20} />;
const userPlus = <Icon4 name="user-plus" size={18} />;
const contactUs = <MaterialIcons name="contacts" size={30} />;
const angleDown = <Icon4 name="angle-down" size={25} />;
const angleUp = <Icon4 name="angle-up" size={25} />;
const vertical3Dots = <Icon4 name="ellipsis-v" size={25} />;
const check = <Icon4 name="check-square-o" size={25} />;
const checkAnt = <AntDesign name="check" size={24} color="green" />;
const unCheck = <Icon4 name="square-o" size={25} />;
const infoCircle = <Icon4 name="info-circle" size={16} color="gray" />;
const info = <Entypo name="info" size={18} />;
const filter = <Ionicons name="md-options" size={18} color="gray" />;
const pickUp = <Fontisto name="shopping-bag-1" size={24} />;
const product = <MaterialCommunityIcons name="store" size={28} />;
const foodForkDrink = (
  <MaterialCommunityIcons name="food-fork-drink" size={24} color="black" />
);
const orderMinus = <AntDesign name="minuscircleo" size={16} />;
const orderPlus = <AntDesign name="pluscircleo" size={16} />;
const appleIcon = <AntDesign name="apple1" size={20} />;
const close = <AntDesign name="close" size={20} />;
const pushpino = <AntDesign name="pushpino" size={20} />;
const pushpin = <AntDesign name="pushpin" size={20} />;
const tag = <AntDesign name="tag" size={20} />;
const storeCreditPay = <Entypo name="credit-card" size={24} />;
const emoji = <Entypo name="emoji-happy" size={24} />;
const profile = <FontAwesome5 name="user-alt" size={22} />;
const print = <Entypo name="print" size={20} color="black" />;
const deleteItem = <MaterialIcons name="delete" size={24} />;
const delivery = <MaterialCommunityIcons name="truck-delivery" size={24} />;
const shipping = <MaterialCommunityIcons name="truck-fast" size={24} />;
const shop = <Entypo name="shop" size={60} />;
const map = <FontAwesome5 name="map-marker-alt" size={24} color="black" />;
const category = <MaterialIcons name="category" size={24} />;
const dinein = <MaterialCommunityIcons name="room-service" size={24} />;
const search = <Ionicons name="ios-search" size={24} color="black" />;
const verified = <Octicons name={'verified'} size={22} />;
const unverified = <Octicons name={'unverified'} size={22} />;
const filledStar = <FontAwesome name="star" size={24} color="black" />;
const cartoff = <MaterialCommunityIcons name="cart-off" size={24} />;
const listalt = <FontAwesome5 name="list-alt" size={26} />;
const fileinvoice = <FontAwesome5 name="file-invoice" size={24} />;
const bell = <FontAwesome name="bell" size={22} />;
const coins = <FontAwesome5 name="coins" size={22} />;
const blog = <FontAwesome5 name="blog" size={24} />;
const post = <MaterialCommunityIcons name="post" size={24} />;
const gallery = <FontAwesome name="photo" size={24} />;
const subscription = <MaterialIcons name="subscriptions" size={24} />;
const table = <MaterialCommunityIcons name="table-furniture" size={24} />;
const hospitality = <FontAwesome5 name="hotel" size={24} />;
const donation = <FontAwesome5 name="donate" size={24} />;

const glyphMap = {
  appleIcon,
  cartoff,
  listalt,
  coins,
  bell,
  fileinvoice,
  orderMinus,
  orderPlus,
  checkAnt,
  hot_pizza: 0x61,
  wallet: 0x62,
  profile,
  account: profile,
  burger: 0x63,
  edit: 0x64,
  listView: 0x65,
  forward: 0x66,
  refresh: 0x67,
  back: 0x68,
  back_down: 0x69,
  notification_bell: 0x6a,
  dislike: 0x6b,
  menu: 0x6c,
  setting: 0x6d,
  search: search,
  memberDetail: 0x6f,
  location: 0x70,
  vegetable: 0x71,
  favorite: 0x72,
  fishBall: 0x73,
  bell: 0x74,
  notifications: 0x74,
  addToFavorite: 0x75,
  'revealPwd-no': 0x76,
  share: 0x77,
  hotdog: 0x78,
  star: 0x79,
  'star-fill': 0x7a,
  contactSupport: 0x41,
  terms: 0x42,
  award: 0x43,
  forward_up: 0x44,
  questionMark: 0x45,
  qrCode: 0x46,
  cheerGlass: 0x47,
  home: 0x48,
  promotion: 0x49,
  add: 0x4a,
  revealPwd: 0x4b,
  friends: 0x4c,
  phone: 0x4d,
  migoreng: 0x4e,
  foodMenu: 0x4f,
  fingerPrint: 0x50,
  order: 0x51,
  threeDots: 0x52,
  message: 0x53,
  tick: 0x54,
  'member-tick': 0x55,
  'left-arrow': 0x56,
  'love-msg': 0x57,
  cross: 0x58,
  chicken: 0x59,
  'change-setting': 0x5a,
  filter,
  location_arrow: 0x30,
  // info: 0x31,
  info: info,
  login,
  minus,
  dustBin,
  dustBinSmaller,
  history,
  gift,
  link,
  cart,
  products: product,
  arrowRight,
  arrowRightBig,
  gridView,
  userPlus,
  angleDown,
  angleUp,
  vertical3Dots,
  plus,
  facebook,
  email,
  pickUp,
  product,
  calendar,
  arrowLeft,
  calendarSmall,
  check,
  unCheck,
  foodForkDrink,
  clock,
  infoCircle,
  close,
  pushpino,
  pushpin,
  print,
  tag,
  copy,
  storeCreditPay,
  car,
  bike,
  bigBike,
  delivery,
  boxOpen,
  send,
  emoji,
  camera,
  video,
  clip,
  deleteItem,
  shop,
  map,
  shipping,
  category,
  dinein,
  verified,
  unverified,
  filledStar,
  contactUs,
  blog,
  post,
  gallery,
  subscription,
  table,
  hospitality,
  donation,
};

const expoAssetId = require('../../../assets/fonts/wl-assets.ttf');

const Icon = createIconSet(glyphMap, 'untitled-font-1', expoAssetId);

export default Icon;
