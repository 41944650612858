import {Ionicons} from '@expo/vector-icons';
import {
  View,
  Linking,
  Image,
  TouchableOpacity,
  Text,
  StyleSheet,
} from 'react-native';
import React from 'react';
import {Colors, Images} from '../../constants';
import {setValue} from '../../utils';
import {Icon} from '../../components';

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.theme_color,
    justifyContent: 'space-between',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  messageText: {
    color: Colors.color30,
    fontSize: setValue(16),
    margin: 10,
    textAlign: 'center',
  },
  titleText: {
    textAlign: 'center',
    margin: 10,
    color: Colors.primary_color,
    fontSize: setValue(25),
  },
  button: {
    margin: setValue(20),
    backgroundColor: Colors.primary_color,
    padding: 10,
    width: '40%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderRadius: setValue(5),
  },
  buttonText: {
    textAlign: 'center',
    color: Colors.theme_color,
  },
  logoContainer: {
    padding: setValue(40),
  },
  logo: {
    width: '100%',
    aspectRatio: 1,
  },
  linkURL: {
    textDecorationLine: 'underline',
    fontSize: setValue(16),
    color: Colors.facebook,
  },
});

const ThankYou = (props) => {
  return (
    <View style={styles.mainContainer}>
      <View style={styles.container}>
        <View style={styles.logoContainer}>
          <Image source={Images.logo} style={styles.logo} />
        </View>
        <Text style={styles.titleText}>Thank you ! </Text>
        <Text style={styles.messageText}>
          {
            'Thank you for create account.\n We will review and get back to you as soon as possible. for more detail '
          }
          <TouchableOpacity
            onPress={() =>
              Linking.openURL('mailto:admin@spyhunteritsolution.in')
            }
          >
            <Text style={styles.linkURL}>admin@spyhunteritsolution.in</Text>
          </TouchableOpacity>
        </Text>
        <TouchableOpacity
          onPress={() => props.navigation.goBack()}
          style={styles.button}
        >
          <Ionicons
            name="arrow-back-outline"
            size={22}
            color={Colors.theme_color}
          />
          <Text style={styles.buttonText}>{' Go Back'}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};
export default ThankYou;
