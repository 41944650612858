import {
  View,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
} from 'react-native';
import React from 'react';
import {
  AppText,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
  SliderItem,
} from '../../components';
import {goBack, setValue} from '../../utils';
import styles from './styles';
import {useSelector} from 'react-redux';
import {useState} from 'react';
import {Colors} from '../../constants';
import {useEffect} from 'react';
import {connectActionSheet} from '@expo/react-native-action-sheet';
import {strings} from '../../i18n';
import * as ImagePicker from 'expo-image-picker';
import {useDispatch} from 'react-redux';
import CommonAction from '../../redux/reduxsauce/commonRedux';
import Constants from 'expo-constants';
import axios from 'axios';
import { getAppConfig } from '../../redux/reduxsauceActions/configActions';

const HomeSlider = (props) => {
  const config = useSelector((state) => state?.config);
  const user = useSelector((state) => state.auth?.user);
  const dispatch = useDispatch();
  const [sliderData, setSliderData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSliderData(
      (config?.appHomePage?.slider?.length > 0 &&
        config?.appHomePage?.slider) ||
        [],
    );
  }, []);

  const type = 'splash';
  const iconColor = Constants.manifest?.extra?.dark
    ? Colors.white
    : Colors.black60;

  const dataURLtoFile = (dataurl) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], 'image1.jpg', {type: mime});
  };

  const openActionSheet = () => {
    const options = [
      strings('actionSheet.takePhoto'),
      strings('actionSheet.choosePhoto'),
      strings('actionSheet.cancel'),
    ];
    const cancelButtonIndex = 2;

    props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) openCamera(type, 0);
        if (buttonIndex === 1) openImagePicker(type, 1);
      },
    );
  };

  const openCamera = async () => {
    await ImagePicker.requestCameraPermissionsAsync();

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: Platform.OS == 'web' ? [3, 1] : [4, 2],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      let blob = await (await fetch(result.uri)).blob();
      let body = new FormData();
      Platform.OS == 'web'
        ? body.append('image', dataURLtoFile(result?.uri))
        : body.append('image', {
            uri: result.uri,
            type: blob.type,
            name: blob.data.name,
          });
      body.append('business', config.businessId);
      setLoading(true);
      axios
        .post('/lookup/images/create', body, {
          'Content-Type': 'multipart/form-data',
        })
        .then((response) => {
          console.log('response upload image', response);
          let array = [...sliderData, {image: response.data.image}];
          setSliderData(array);
          setLoading(false);
        })
        .catch((error) => {
          console.log('error upload image', error);
          setLoading(false);
          dispatch(
            CommonAction.setAlert({
              visible: true,
              content: error?.response?.message || 'Something want wrong',
            }),
          );
        });
    }
    if (result.cancelled) {
    }
  };

  const openImagePicker = async () => {
    await ImagePicker.requestMediaLibraryPermissionsAsync();

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: Platform.OS == 'web' ? [3, 1] : [4, 2],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      let blob = await (await fetch(result.uri)).blob();
      let body = new FormData();
      Platform.OS == 'web'
        ? body.append('image', dataURLtoFile(result?.uri))
        : body.append('image', {
            uri: result.uri,
            type: blob.type,
            name: blob.data.name,
          });
      body.append('business', config.businessId);
      setLoading(true);
      axios
        .post('/lookup/images/create', body, {
          'Content-Type': 'multipart/form-data',
        })
        .then((response) => {
          console.log('response upload image', response);
          let array = [...sliderData, {image: response.data.image}];
          setSliderData(array);
          setLoading(false);
        })
        .catch((error) => {
          console.log('error upload image', error);
          setLoading(false);
          dispatch(
            CommonAction.setAlert({
              visible: true,
              content: error?.response?.message || 'Something want wrong',
            }),
          );
        });
    }
    if (result.cancelled) {
    }
  };

  const saveConfigData = async () => {
    try {
      const response = await axios.patch(
        `/business/update/business-setting/${config['businessId']}`,
        {
          app_home_page: {
            ...config.appHomePage,
            slider: sliderData,
          },
        },
      );
      dispatch(getAppConfig());
      console.log('response', response);
      props?.navigation?.goBack();
    } catch (error) {
      console.log('error', error);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error.response.message || 'Something went wrong :(',
        }),
      );
    }
  };

  const removeItem = (itemIndex) => {
    let array = [...sliderData];
    array = array.filter((o, index) => index !== itemIndex);
    setSliderData(array);
  };

  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('HomeSliderScreen.sliderConfiguaration')}
        leftIcon="back"
        leftPress={() => props?.navigation?.goBack()}
      />
      <MainContainer useView>
        <FlatList
          data={sliderData?.length > 0 ? sliderData : []}
          keyExtractor={(item, index) => index}
          showsVerticalScrollIndicator={false}
          renderItem={({item, index}) => {
            return (
              <>
                <SliderItem
                  item={item}
                  index={index}
                  onDeleteItem={() => removeItem(index)}
                />
              </>
            );
          }}
          ListFooterComponent={() => {
            if (['merchant', 'manager'].includes(user.type)) {
              return (
                <TouchableOpacity
                  disabled={loading}
                  style={
                    Constants.manifest.extra.dark
                      ? styles.addNewSliderBtn
                      : styles.addNewSliderBtnLightTheme
                  }
                  onPress={() => {
                    openActionSheet();
                  }}
                >
                  {loading ? (
                    <View style={styles.loading}>
                      <ActivityIndicator color={iconColor} size={'small'} />
                    </View>
                  ) : (
                    <AppText style={styles.btnText}>
                      <Icon name="add" color={iconColor} size={20} />
                    </AppText>
                  )}
                </TouchableOpacity>
              );
            } else {
              return <View></View>;
            }
          }}
        />
        {Platform.OS == 'web' && (
          <LongButton
            disabled={loading}
            text={strings('orderSetting.button.save')}
            style={{marginTop: setValue(20), marginBottom: setValue(45)}}
            onPress={saveConfigData}
          />
        )}
      </MainContainer>

      {Platform.OS != 'web' && (
        <LongButton
          disabled={loading}
          text={strings('orderSetting.button.save')}
          style={{
            width: '100%',
            borderRadius: 0,
          }}
          onPress={saveConfigData}
        />
      )}
    </View>
  );
};
const ConnectedClientAppImage = connectActionSheet(HomeSlider);
export default ConnectedClientAppImage;
