import React, {useEffect, useState} from 'react';
import {
  Image,
  Keyboard,
  Platform,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {
  AppText,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../../components';
import {Colors} from '../../../constants';
import {strings} from '../../../i18n';
import {goBack, setValue} from '../../../utils';
import styles from './styles';
import * as ImagePicker from 'expo-image-picker';
import {connectActionSheet} from '@expo/react-native-action-sheet';
import axios from 'axios';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {useDispatch} from 'react-redux';

const EditBlogCategory = (props) => {
  const item = props?.route?.params?.item;
  const [blogCategoryDetail, setBlogCategoryDetail] = useState({
    title: item?.name ? item?.name : '',
    description: item?.seo_description ? item?.seo_description : '',
    image: item?.image ? {uri: item?.image} : null,
    slug: item?.slug ? item?.slug : '',
  });
  const dispatch = useDispatch();

  const openActionSheet = () => {
    const options = [
      strings('actionSheet.takePhoto'),
      strings('actionSheet.choosePhoto'),
      strings('actionSheet.removePhoto'),
      strings('actionSheet.cancel'),
    ];
    const cancelButtonIndex = 3;

    props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) openCamera();
        if (buttonIndex === 1) openImagePicker();
        if (buttonIndex === 2) removePhoto();
      },
    );
  };

  const removePhoto = () => {
    // props.removeProfilePicture();
    setBlogCategoryDetail({
      ...blogCategoryDetail,
      image: null,
    });
  };

  const openCamera = async () => {
    await ImagePicker.requestCameraPermissionsAsync();

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: [30, 17],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      setBlogCategoryDetail({
        ...blogCategoryDetail,
        image: result,
      });
    }

    if (!result.cancelled) {
      // changeProfilePicture(result);
    }
  };

  const openImagePicker = async () => {
    await ImagePicker.requestMediaLibraryPermissionsAsync();

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: [30, 17],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      setBlogCategoryDetail({
        ...blogCategoryDetail,
        image: result,
      });
    }

    if (!result.cancelled) {
    }
  };

  const dataURLtoFile = (dataurl) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], 'image1.jpg', {type: mime});
  };

  const onSave = async () => {
    Keyboard.dismiss();
    dispatch(CommonActions.setLoading(true));
    if (blogCategoryDetail.title.trim() == '') {
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('addNewBlog.alert.title'),
        }),
      );
      return;
    }
    if (blogCategoryDetail.slug.trim() == '') {
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('addNewBlog.alert.slug'),
        }),
      );
      return;
    }

    let body = new FormData();
    body.append('name', blogCategoryDetail?.title.trim());
    blogCategoryDetail?.description.trim().length > 0 &&
      body.append('seo_description', blogCategoryDetail?.description.trim());
    blogCategoryDetail?.slug.trim().length > 0 &&
      body.append('slug', blogCategoryDetail?.slug.trim());
    if (
      blogCategoryDetail?.image?.uri &&
      blogCategoryDetail?.image?.uri?.slice(0, 4) != 'http'
    ) {
      if (Platform.OS == 'web') {
        body.append('image', dataURLtoFile(blogCategoryDetail?.image?.uri));
      } else {
        let blob = await (await fetch(blogCategoryDetail?.image?.uri)).blob();
        body.append('image', {
          uri: blogCategoryDetail?.image?.uri,
          type: blob.type,
          name: blob.data.name,
        });
      }
    } else if (!blogCategoryDetail?.image?.uri) {
      body.append('image', '');
    }

    const header = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    console.log('body', body);
    if (item?.id) {
      dispatch(CommonActions.setLoading(true));
      await axios
        .patch('/blog/category/update/' + item?.id, body, header)
        .then((response) => {
          console.log('response update blog', response);
          dispatch(CommonActions.setLoading(false));
          props.navigation.navigate('blog-category-list');
        })
        .catch((error) => {
          console.log('error update blog --', error?.response);
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: error?.response?.message,
            }),
          );
          dispatch(CommonActions.setLoading(false));
        });
    } else {
      dispatch(CommonActions.setLoading(true));
      await axios
        .post('/blog/category/create', body, header)
        .then((response) => {
          console.log('response create blog category----', response);
          dispatch(CommonActions.setLoading(false));
          props.navigation.navigate('blog-category-list');
        })
        .catch((error) => {
          console.log('error create blog category----', error.response);
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: error?.response?.message,
            }),
          );
          dispatch(CommonActions.setLoading(false));
        });
    }
  };

  return (
    <View style={styles.container}>
      <MainHeader
        title={
          props?.route?.params?.item?.id
            ? strings('addNewBlogCategory.editNewBlogCategory')
            : strings('addNewBlogCategory.addNewBlogCategory')
        }
        // title={'Edit Blog Category'}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer>
        <KeyboardAwareScrollView>
          <>
            <View style={styles.profileStrengthContainer}>
              <TouchableOpacity
                style={styles.imageContainer}
                onPress={openActionSheet}
              >
                {!blogCategoryDetail?.image ? (
                  <Icon name="add" size={16} color={Colors.black60} />
                ) : (
                  <View>
                    <Image
                      source={{uri: blogCategoryDetail?.image?.uri}}
                      style={styles.image}
                    />
                    <TouchableOpacity
                      style={styles.cameraIcon}
                      onPress={openActionSheet}
                    >
                      <Icon name="camera" color={Colors.black20} />
                    </TouchableOpacity>
                  </View>
                )}
              </TouchableOpacity>
            </View>

            <View style={styles.textInputContainer}>
              <AppText style={styles.title} required>
                {strings('addNewBlogCategory.title.title')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={strings('addNewBlogCategory.placeHolder.title')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={styles.textInput}
                value={blogCategoryDetail?.title}
                onChangeText={(text) =>
                  setBlogCategoryDetail({
                    ...blogCategoryDetail,
                    title: text,
                  })
                }
              />
            </View>
            <View style={styles.textInputContainer}>
              <AppText style={styles.title}>
                {strings('addNewBlogCategory.title.description')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={strings(
                  'addNewBlogCategory.placeHolder.description',
                )}
                placeholderTextColor={Colors.color30}
                numberOfLines={3}
                multiline={true}
                returnKeyType="done"
                style={styles.multiLineTextInput}
                value={blogCategoryDetail?.description}
                onChangeText={(text) => {
                  setBlogCategoryDetail({
                    ...blogCategoryDetail,
                    description: text,
                  });
                }}
              />
            </View>
            <View style={styles.textInputContainer}>
              <AppText style={styles.title} required>
                {strings('addNewBlogCategory.title.slug')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={strings('addNewBlogCategory.placeHolder.slug')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={styles.textInput}
                value={blogCategoryDetail?.slug}
                onChangeText={(text) =>
                  setBlogCategoryDetail({
                    ...blogCategoryDetail,
                    slug: text,
                  })
                }
              />
            </View>
          </>

          {Platform.OS == 'web' && (
            <LongButton
              onPress={() => onSave()}
              loading={false}
              text={
                item?.id
                  ? strings('addNewGallery.edit')
                  : strings('editProductCategory.button.save')
              }
              style={{
                marginTop: setValue(20),
                marginBottom: setValue(45),
              }}
            />
          )}
        </KeyboardAwareScrollView>
      </MainContainer>

      {Platform.OS !== 'web' && (
        <LongButton
          onPress={() => onSave()}
          loading={false}
          text={
            item?.id
              ? strings('addNewGallery.edit')
              : strings('editProductCategory.button.save')
          }
          style={{borderRadius: 0, width: '100%'}}
        />
      )}
    </View>
  );
};
const ConnectedEditBlogCategory = connectActionSheet(EditBlogCategory);

export default ConnectedEditBlogCategory;
