import Constants from 'expo-constants';
import {Platform, StyleSheet} from 'react-native';

import {screenWidth, setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.9)',
  },
  title: {
    color: 'white',
    fontSize: setXAxisValue(20),
    marginLeft: setXAxisValue(10),
  },
  header: {
    flexDirection: 'row',
    paddingHorizontal: setXAxisValue(15),
    paddingVertical: Constants.statusBarHeight + setYAxisValue(10),
    alignItems: 'center',
  },
  content: {
    alignItems: 'center',
    alignSelf: 'center',
    maxWidth: Platform.OS == 'web' ? 400 : '100%',
  },
  image: {
    borderRadius: setXAxisValue(20),
    width: setXAxisValue(160),
    height: setXAxisValue(160),
    marginVertical: setYAxisValue(30),
    alignSelf: 'center',
  },
  text: {
    color: 'white',
    fontSize: setXAxisValue(16),
    fontWeight: 'bold',
    marginBottom: setYAxisValue(50),
  },
  button: {
    borderRadius: setXAxisValue(5),
    marginTop: setYAxisValue(20),
    width:
      Platform.OS == 'web'
        ? 400 - setXAxisValue(50) * 2
        : screenWidth - setXAxisValue(50) * 2,
    paddingVertical: setYAxisValue(20),
    alignSelf: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: setXAxisValue(16),
  },
});
