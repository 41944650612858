import React from 'react';
import {View, Text, TouchableOpacity, Platform} from 'react-native';
import {AppText, Icon} from '../../components';
import {strings} from '../../i18n';
import styles from './styles';
import Moment from 'moment';
import {Fontisto, MaterialCommunityIcons} from '@expo/vector-icons';
import {Colors} from '../../constants';
import {get} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import CommonActions from '../../redux/reduxsauce/commonRedux';

const textColor = Colors.white;

const OrderItem = (props) => {
  const item = props.item;

  const config = useSelector((state) => state.config);
  const user = useSelector((state) => state?.auth?.user);
  let currencySymbol = get(config, 'currencySymbol', '₹');

  const dispatch = useDispatch();

  return (
    <TouchableOpacity
      onPress={() => props.onSeeOrderHistory(item)}
      style={styles[props.tab]}
    >
      {/* <FlatList
        style={styles.orderItemList}
        contentContainerStyle={{flexGrow: 0}}
        data={item.orderItems}
        renderItem={this.renderItemInOrder}
        keyExtractor={i => i.id}
      /> */}
      <View style={styles.orderPrice}>
        <AppText style={styles.orderId}>{'ORD00' + item.id}</AppText>
        {item.type === 'dinein' && (
          <AppText style={styles.orderTypeText}>
            <MaterialCommunityIcons
              name="food-fork-drink"
              size={18}
              color={textColor}
            />
            {' ' + strings('ordering.orderHistoryScreen.dineIn')}
          </AppText>
        )}
        {item.type === 'pickup' && (
          <AppText style={styles.orderTypeText}>
            <Fontisto name="shopping-bag-1" size={18} color={textColor} />
            {' ' + strings('ordering.orderHistoryScreen.pickUp')}
          </AppText>
        )}
        {item.type === 'delivery' && (
          <AppText style={styles.orderTypeText}>
            <MaterialCommunityIcons
              name="truck-delivery"
              size={18}
              color={textColor}
            />
            {' ' + strings('ordering.orderHistoryScreen.delivery')}
          </AppText>
        )}
      </View>
      <View style={styles.orderPrice}>
        <AppText style={styles.orderDate}>
          {Moment(item.created_at).format('D MMM YYYY hh:mm A')}
        </AppText>
        {/* <AppText style={styles.orderTypeText}>{item.statusId}</AppText> */}
      </View>
      <AppText
        style={styles.orderPrice}
      >{`${currencySymbol} ${item.total}`}</AppText>
      {props.tab === 0 ? (
        item?.status == 'unpaid' ? (
          <TouchableOpacity
            style={styles.reOderButtonCurrentStatusTab}
            onPress={() => {
              props.navigation.navigate('order-history-detail', {id: item.id})
              // if (Platform.OS == 'web') {
              //   console.log('calling--->');
              //   const options = {
              //     key: config?.paymentKey,
              //     currency: 'INR',
              //     amount: item?.total * 100,
              //     name: config?.businessName,
              //     description: config?.businessDescription,
              //     image: config?.logo,
              //     order_id: item?.payment_key,
              //     handler: (response) => {
              //       // alert(response.razorpay_payment_id);
              //       // alert(response.razorpay_order_id);
              //       // alert(response.razorpay_signature);
              //       dispatch(CommonActions.setLoading(true));
              //       axios
              //         .post('/order/pay/razorpay/success/' + item.id, {
              //           razorpay_payment_id: response.razorpay_payment_id,
              //           razorpay_order_id: response.razorpay_order_id,
              //           razorpay_signature: response.razorpay_signature,
              //           order_id: item.id,
              //         })
              //         .then((res) => {
              //           console.log('response- razorpay ->', res);
              //           dispatch(CommonActions.setLoading(false));
              //           props.navigation.replace('order-history-detail', {
              //             id: item.id,
              //           });
              //         })
              //         .catch((error) => {
              //           console.log('error- razorpay-> ', error?.response);
              //           dispatch(CommonActions.setLoading(false));
              //           alert(
              //             'Something went wrong. Your order is not updated.',
              //           );
              //         });
              //     },
              //     prefill: {
              //       name: user?.first_name + ' ' + user?.last_name,
              //       email: user?.email,
              //       contact: user?.phone,
              //     },
              //     theme: {
              //       color: Colors.theme_color,
              //     },
              //   };

              //   const paymentObject = new window.Razorpay(options);
              //   paymentObject.open();
              // } else {
              //   props.navigation.navigate('payment', {id: item.id});
              // }
            }}
          >
            <AppText style={styles.reOrderButtonText}>
              {/* {strings('ordering.orderHistoryScreen.payNow')} */}
              UNPAID
            </AppText>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            style={styles.reOderButtonCurrentStatusTab}
            onPress={() => props.onSeeOrderDetail(item)}
          >
            <AppText style={styles.reOrderButtonText}>
              {/* {strings('ordering.orderHistoryScreen.checkStatus')} */}
              {item?.status && item?.status.toUpperCase()}
            </AppText>
          </TouchableOpacity>
        )
      ) : (
        <View style={styles.orderContainer}>
          <View style={styles.reOderButton}>
            <TouchableOpacity
              onPress={() =>
                props.navigation.navigate('order-history-detail', {id: item.id})
              }
            >
              <AppText style={styles.reOrderButtonText}>
                {strings('ordering.orderHistoryDetailScreen.button')}
              </AppText>
            </TouchableOpacity>
          </View>
          {/* {item.statusId !== 'CANCELLED' && (
          <View style={styles.reOderButton}>
            <TouchableOpacity onPress={() => this.onReorderAll(item)}>
              <AppText style={styles.reOrderButtonText}>{strings('ordering.orderHistoryScreen.button')}</AppText>
            </TouchableOpacity>
          </View>
          )} */}
        </View>
      )}
    </TouchableOpacity>
  );
};
export default OrderItem;
