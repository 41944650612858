import NetInfo from '@react-native-community/netinfo';
import React, {Component} from 'react';
import {Image, SafeAreaView, Text, TouchableOpacity, View} from 'react-native';
import {connect} from 'react-redux';

import {Colors, Images} from '../../constants';
import {strings} from '../../i18n';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import AppText from '../AppText';
import Icon from '../Icon';
import Modal from '../Modal';
import styles from './styles';

class NetworkStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
    this.unsubscribe = null;
  }

  componentDidMount() {
    this.unsubscribe = NetInfo.addEventListener(this._handleConnectionChange);
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  _handleConnectionChange = status => {
    if (!status.isConnected) {
      this.setState({visible: true});
    } else {
      this.setState({visible: false});
    }
    this.props.dispatch(
      CommonActions.setConnected({status: !this.state.visible}),
    );
  };

  render() {
    const {visible} = this.state;
    return (
      <Modal
        visible={visible}
        onClose={() => this.setState({visible: false})}
        transparent
        onRequestClose={() => this.setState({visible: false})}
      >
        <View style={styles.overlay}>
          <View style={styles.container}>
            <View>
              <Image source={Images.noInternet} />
              <View style={styles.noWifi}>
                <Image source={Images.noWifi} />
              </View>
            </View>
            <AppText style={styles.title}>{strings('network.title')}</AppText>
            <AppText style={styles.description}>
              {strings('network.description')}
            </AppText>
            <TouchableOpacity onPress={() => this.setState({visible: false})} style = {styles.closeButton}>
              <Text style={styles.closeButtonText}>Close</Text>
            </TouchableOpacity>
          </View>
          {/* <SafeAreaView style={styles.crossIcon}>
            <TouchableOpacity onPress={() => this.setState({ visible: false })}>
              <Icon name="cross" size={22} color={Colors.white} />
            </TouchableOpacity>
          </SafeAreaView> */}
        </View>
      </Modal>
    );
  }
}

export default connect(state => ({
  isConnected: state.common.isConnected,
}))(NetworkStatus);
