import React, {useEffect, useState} from 'react';
import {
  ActivityIndicator,
  FlatList,
  Platform,
  View,
  TouchableOpacity,
} from 'react-native';
import {useDispatch} from 'react-redux';
import {
  AppText,
  MainContainer,
  MainHeader,
  NoRecordFound,
  LongButton,
  Icon,
} from '../../../../components';
import {Colors} from '../../../../constants';
import {dateTimeFormat, goBack, setValue} from '../../../../utils';
import styles from './styles';
import CommonActions from '../../../../redux/reduxsauce/commonRedux';
import axios from 'axios';
import {useIsFocused} from '@react-navigation/native';
import Icon4 from '@expo/vector-icons/FontAwesome';

const RateCodeList = (props) => {
  const isFocused = useIsFocused();
  const dispatch = useDispatch();

  const [rateCodeList, setRateCodeList] = useState([]);

  const getRateCodeList = async () => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios.get('/hospitality/rate_code/list').then((response) => {
        console.log('rate_code list response --> ', response);
        setRateCodeList(response?.data);
        dispatch(CommonActions.setLoading(false));
      });
    } catch (error) {
      dispatch(CommonActions.setLoading(false));
      console.log('rate_code list error', error?.response?.message);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };

  const deleteRateCode = async (id) => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios
        .delete('/hospitality/rate_code/deleted/' + id)
        .then((response) => {
          console.log('response of delete rate code----- ', response);
          dispatch(CommonActions.setLoading(false));
          getRateCodeList();
        });
    } catch (error) {
      console.log('error delete rate code----- ', error?.response);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
      dispatch(CommonActions.setLoading(false));
    }
  };

  console.log('rateCodeList--->>', rateCodeList);
  useEffect(() => {
    isFocused && getRateCodeList();
  }, [isFocused]);

  return (
    <View style={styles.container}>
      <MainHeader
        title={'Rate Code '}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer useView>
        {false && loading ? (
          <View
            style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}
          >
            <ActivityIndicator size="large" color={Colors.theme_color} />
          </View>
        ) : (
          <View useView style={{flex: 1}}>
            {false ? (
              <NoRecordFound
                title="Transactions"
                message="We'll notify you when there is something now!"
                hideImage={true}
              />
            ) : (
              <View
                style={{
                  paddingTop: 10,
                  flex: 1,
                }}
              >
                <FlatList
                  data={rateCodeList}
                  showsVerticalScrollIndicator={false}
                  keyExtractor={(item) => item.id}
                  renderItem={({item, index, isActive}) => (
                    <TouchableOpacity
                      disabled={isActive}
                      style={styles.itemContainer}
                      key={item?.id}
                    >
                      <View style={styles.categoryDetailContainer}>
                        <AppText style={styles.categoryName} numberOfLines={2}>
                          {item?.name}
                        </AppText>
                        <View style={styles.buttonContainer}>
                          <TouchableOpacity
                            onPress={() =>
                              props.navigation.navigate('edit-rate-code', {
                                item: item,
                              })
                            }
                            style={styles.button}
                          >
                            <Icon
                              name="edit"
                              size={setValue(18)}
                              color="#fff"
                            />
                          </TouchableOpacity>
                          <TouchableOpacity
                            style={styles.button}
                            onPress={() => deleteRateCode(item.id)}
                          >
                            <Icon4
                              name="trash"
                              size={setValue(18)}
                              color="#fff"
                            />
                          </TouchableOpacity>
                        </View>
                      </View>
                    </TouchableOpacity>
                  )}
                />
              </View>
            )}
          </View>
        )}
        {Platform.OS == 'web' && (
          <LongButton
            onPress={() => props.navigation.navigate('edit-rate-code')}
            loading={false}
            text={'Add Rate Code'}
            style={{
              marginTop: setValue(20),
              marginBottom: setValue(45),
            }}
          />
        )}
      </MainContainer>
      {Platform.OS !== 'web' && (
        <LongButton
          onPress={() => props.navigation.navigate('edit-rate-code')}
          loading={false}
          text={'Add Rate Code'}
          style={{borderRadius: 0, width: '100%'}}
        />
      )}
    </View>
  );
};

export default RateCodeList;
