import {StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {setYAxisValue, setValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
};

export default StyleSheet.create({
  addressCard: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: Colors.white,
    padding: setValue(10),
    marginBottom: setYAxisValue(10),
    marginHorizontal: 0,
    borderRadius: 5,
    ...shadow,
  },
  addressContainer: {
    // margin: setValue(5),
    flex: 1,
  },
});
