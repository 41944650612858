import {View, Text, TouchableOpacity, Platform} from 'react-native';
import React from 'react';
import styles from './styles';
import Icon4 from '@expo/vector-icons/FontAwesome';
import {AppText} from '..';
import {Colors} from '../../constants';

const HomeSettingCard = (props) => {
  return (
    <TouchableOpacity
      style={
        Platform.OS === 'web'
          ? styles.homeSettingCardContainerWeb
          : styles.homeSettingCardContainer
      }
      onPress={props.onPress}
    >
      <View style={{padding: 15, justifyContent: 'center'}}>
        <Icon4 name="th-large" size={25} color={Colors.black60} />
      </View>
      <View style={{paddingVertical: 15}}>
        <AppText numberOfLines={1} style={styles.homeSettingCardTitle}>
          {props?.title || 'Title'}
        </AppText>
        <AppText style={{color: Colors.color30}} numberOfLines={1}>
          {props?.description || 'Description'}
        </AppText>
      </View>
    </TouchableOpacity>
  );
};

export default HomeSettingCard;
