import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styles from './styles';
import CommonAction from '../../redux/reduxsauce/commonRedux';
import axios from 'axios';
import {goBack, screenHeight, setValue} from '../../utils';
import {AppText, MainContainer, MainHeader} from '../../components';
import {Linking, TouchableOpacity, View} from 'react-native';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import {useIsFocused} from '@react-navigation/native';

const NimbusDelivery = (props) => {
  const orderId = props?.route?.params?.orderId;

  const isFocused = useIsFocused();
  const dispatch = useDispatch();

  const [shippingDetail, setShippingDetail] = useState(null);
  const [orderDetail, setOrderDetail] = useState(null);

  const trackOrderNow = () => {
    axios
      .get('/delivery/order/track/' + orderId)
      .then((response) => {
        console.log('nimbus order tracking', response);

        if (response?.data?.success === true) {
          console.log('shipment detail', shippingDetail);
          setShippingDetail({...shippingDetail, ...response?.data?.data?.data});
        } else {
          dispatch(
            CommonAction.setAlert({
              visible: true,
              content: response?.data?.message || 'Something want wrong!',
            }),
          );
        }
        dispatch(CommonAction.setLoading(false));
      })
      .catch((error) => {
        console.log('nimbus order tracking error', error?.response);
        dispatch(CommonAction.setLoading(false));
      });
  };

  const getOrderDetail = () => {
    dispatch(CommonAction.setLoading(true));

    axios
      .get('/order/detail/' + orderId)
      .then(async (response) => {
        console.log('order Detail --', response);
        if (response?.data?.data?.delivery_information) {
          setShippingDetail({
            ...shippingDetail,
            ...response?.data?.data?.delivery_information,
          });
          setOrderDetail(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log('error-->', error?.response);
        dispatch(CommonAction.setLoading(false));
      });
  };

  useEffect(() => {
    orderId && getOrderDetail();
  }, [isFocused, orderId]);

  useEffect(() => {
    shippingDetail?.awb_number && trackOrderNow();
  }, [orderDetail]);

  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('nimbusDetailScreen.pageTitle')}
        leftIcon={'back'}
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer>
        <View style={{minHeight: screenHeight / 2}}>
          {/* detail */}
          <View style={styles.orderDetailCard}>
            <View>
              <AppText style={styles.cardHeader}>
                {strings('nimbusDetailScreen.static.title')}
              </AppText>
            </View>
            <View style={styles.detailRow}>
              <View style={{maxWidth: '35%'}} numberOfLine={2}>
                <AppText
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: setValue(15),
                  }}
                >
                  {strings('nimbusDetailScreen.static.orderId')}
                </AppText>
              </View>
              <View style={{maxWidth: '60%'}} numberOfLine={2}>
                <AppText
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: setValue(15),
                  }}
                >
                  {shippingDetail?.order_id}
                </AppText>
              </View>
            </View>

            <View style={styles.detailRow}>
              <View style={{maxWidth: '35%'}} numberOfLine={2}>
                <AppText
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: setValue(15),
                  }}
                >
                  {strings('nimbusDetailScreen.static.shipmentId')}
                </AppText>
              </View>
              <View style={{maxWidth: '60%'}} numberOfLine={2}>
                <AppText
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: setValue(15),
                  }}
                >
                  {shippingDetail?.shipment_id}
                </AppText>
              </View>
            </View>
            <View style={styles.detailRow}>
              <View style={{maxWidth: '35%'}} numberOfLine={2}>
                <AppText
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: setValue(15),
                  }}
                >
                  {strings('nimbusDetailScreen.static.awb')}
                </AppText>
              </View>
              <View style={{maxWidth: '60%'}} numberOfLine={2}>
                <AppText
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: setValue(15),
                  }}
                >
                  {shippingDetail?.awb_number}
                </AppText>
              </View>
            </View>
            <View style={styles.detailRow}>
              <View style={{maxWidth: '35%'}} numberOfLine={2}>
                <AppText
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: setValue(15),
                  }}
                >
                  {strings('nimbusDetailScreen.static.courier')}
                </AppText>
              </View>
              <View style={{maxWidth: '60%'}} numberOfLine={2}>
                <AppText
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: setValue(15),
                    textTransform: 'capitalize',
                  }}
                >
                  {shippingDetail?.courier_name}
                </AppText>
              </View>
            </View>
            <View style={styles.detailRow}>
              <View style={{maxWidth: '35%'}} numberOfLine={2}>
                <AppText
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: setValue(15),
                    textTransform: 'capitalize',
                  }}
                >
                  {strings('nimbusDetailScreen.static.status')}
                </AppText>
              </View>
              <View style={{maxWidth: '60%'}} numberOfLine={2}>
                <AppText
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: setValue(15),
                    textTransform: 'capitalize',
                  }}
                >
                  {shippingDetail?.status}
                </AppText>
              </View>
            </View>
            <View style={styles.detailRow}>
              <View style={{maxWidth: '35%'}} numberOfLine={2}>
                <AppText
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: setValue(15),
                  }}
                >
                  {strings('nimbusDetailScreen.static.paymentType')}
                </AppText>
              </View>
              <View style={{maxWidth: '60%'}} numberOfLine={2}>
                <AppText
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: setValue(15),
                    textTransform: 'capitalize',
                  }}
                >
                  {shippingDetail?.payment_type}
                </AppText>
              </View>
            </View>
          </View>

          {shippingDetail?.status == 'cancelled' && (
            <TouchableOpacity
              style={[styles.loginButton, {marginTop: 20, marginBottom: 0}]}
              onPress={() => {
                props.navigation.replace('nimbus-order-create', {
                  orderId: orderDetail?.id,
                  partnerId: orderDetail?.delivery_partner_config,
                });
              }}
            >
              <AppText
                style={{
                  color: Colors.wallet_card_red,
                  fontFamily: 'Roboto-Regular',
                  textAlign: 'center',
                }}
              >
                {strings('nimbusDetailScreen.button.reOrder')}
              </AppText>
            </TouchableOpacity>
          )}

          {shippingDetail?.label && (
            <View style={{marginVertical: setValue(20)}}>
              <TouchableOpacity
                style={styles.downloadButton}
                onPress={() => Linking.openURL(shippingDetail?.label)}
              >
                <AppText style={styles.btnText}>
                  {strings('nimbusDetailScreen.button.download')}
                </AppText>
              </TouchableOpacity>
            </View>
          )}

          {/* history */}
          {shippingDetail?.history?.length > 0 && (
            <View style={styles.bottomHistoryDetail}>
              <AppText style={styles.historyHeader}>
                {strings('nimbusDetailScreen.static.history')}
              </AppText>

              {shippingDetail?.history.length > 0 &&
                shippingDetail?.history.map((o, index) => {
                  return (
                    <View
                      key={index}
                      style={[
                        styles.orderDetailCard,
                        {marginBottom: setValue(10)},
                      ]}
                    >
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'flex-start',
                          marginBottom: 5,
                        }}
                      >
                        <AppText
                          style={{
                            fontFamily: 'Roboto-Regular',
                            fontWeight: '700',
                            textTransform: 'capitalize',
                            width: '48%',
                          }}
                        >
                          {o.location}
                        </AppText>
                        <AppText
                          style={{
                            fontFamily: 'Roboto-Regular',
                            textTransform: 'capitalize',
                            textAlign: 'right',
                            width: '48%',
                          }}
                        >
                          {o.event_time}
                        </AppText>
                      </View>
                      <View>
                        <AppText
                          style={{
                            fontFamily: 'Roboto-Regular',
                            textTransform: 'capitalize',
                          }}
                        >
                          {o.message}
                        </AppText>
                      </View>
                    </View>
                  );
                })}
            </View>
          )}
        </View>
      </MainContainer>
    </View>
  );
};

export default NimbusDelivery;
