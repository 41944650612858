import {Platform, StyleSheet} from 'react-native';
import {Colors} from '../../../constants';
import {screenHeight, setValue} from '../../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 8,
};

export default StyleSheet.create({
  container:
    Platform.OS == 'web'
      ? {
          overflow: 'scroll',
          height: '100vh',
        }
      : {
          flex: 1,
        },
  mainContainer: {
    flex: Platform.OS != 'web' ? 1 : null,
  },
  addCategoryButtonWeb: {
    backgroundColor: Colors.theme_color,
    marginTop: setValue(50),
    paddingVertical: 5,
    shadowColor: Colors.black,
    borderRadius: 5,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 5,
  },
  addCategoryText: {
    textAlign: 'center',
    padding: 10,
    color: Colors.white,
  },
});
