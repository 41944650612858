import React, {useState, useEffect} from 'react';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {
  Platform,
  Text,
  View,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
} from 'react-native';
import {
  LongButton,
  MainContainer,
  MainHeader,
  NoRecordFound,
  AppText,
  Icon,
} from '../../components';
import {goBack} from '../../utils';
import styles from './styles';
import {strings} from '../../i18n';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import Icon4 from '@expo/vector-icons/FontAwesome';
import {setValue} from '../../utils';
import {Colors} from '../../constants';
import {useIsFocused} from '@react-navigation/native';

const VariantTypes = (props) => {
  const config = useSelector((state) => state?.config);
  const common = useSelector((state) => state?.common);
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const [variantTypesList, setVariantTypesList] = useState([]);

  const getVariantTypesList = () => {
    dispatch(CommonActions.setLoading(true));
    axios
      .get('/products/variant-type/list/' + config?.businessId)
      .then((response) => {
        console.log('response of setVariant Types List===', response);
        setVariantTypesList(response?.data);
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        console.log('error in Variant Types list===', error);
        dispatch(CommonActions.setLoading(false));
      });
  };

  useEffect(() => {
    isFocused && getVariantTypesList();
  }, [isFocused]);

  const deleteVariantTypes = async (id) => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios
        .delete('/products/variant-type/delete/' + id)
        .then((response) => {
          console.log('response --> ', response);
          getVariantTypesList();
          dispatch(CommonActions.setLoading(false));
        });
    } catch (error) {
      dispatch(CommonActions.setLoading(false));
      console.log('message', error?.response?.message);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };

  return (
    <View style={styles.container}>
      <MainHeader
        title={'Variants Types'}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer useView>
        <View style={styles.mainContainer}>
          {common?.loading ? (
            <View
              style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}
            >
              <ActivityIndicator size="large" color={Colors.theme_color} />
            </View>
          ) : variantTypesList.length != 0 ? (
            <FlatList
              data={variantTypesList}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item) => item.id}
              renderItem={({item, index, isActive}) => (
                <TouchableOpacity
                  disabled={isActive}
                  style={styles.itemContainer}
                  key={item?.id}
                >
                  <View style={styles.categoryDetailContainer}>
                    <AppText style={styles.categoryName} numberOfLines={2}>
                      {item?.type}
                    </AppText>
                    {item?.description ? (
                      <AppText
                        numberOfLines={1}
                        style={styles.categoryDescripation}
                      >
                        {item?.description}
                      </AppText>
                    ) : null}
                    <View style={styles.buttonContainer}>
                      <TouchableOpacity
                        onPress={() =>
                          props.navigation.navigate('edit-variant-types', {
                            item: item,
                            edit: true,
                          })
                        }
                        style={styles.button}
                      >
                        <Icon name="edit" size={setValue(18)} color="#fff" />
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={styles.button}
                        onPress={() => deleteVariantTypes(item?.id)}
                      >
                        <Icon4 name="trash" size={setValue(18)} color="#fff" />
                      </TouchableOpacity>
                    </View>
                  </View>
                </TouchableOpacity>
              )}
            />
          ) : (
            <NoRecordFound
              title={strings('ManageCategories.norecord.title')}
              message={strings('ManageCategories.norecord.message')}
            />
          )}
        </View>
        {Platform.OS == 'web' && (
          <TouchableOpacity
            style={styles.addCategoryButtonWeb}
            onPress={() => props.navigation.navigate('edit-variant-types')}
          >
            <Text style={styles.addCategoryText}>
              {'Create New Variant Types'}
            </Text>
          </TouchableOpacity>
        )}
      </MainContainer>
      {Platform.OS != 'web' && (
        <LongButton
          text={'Create New Variant Types'}
          style={{marginTop: 10, borderRadius: 0}}
          onPress={() => props.navigation.navigate('edit-variant-types')}
        />
      )}
    </View>
  );
};
export default VariantTypes;
