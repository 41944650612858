import React, {useEffect, useState} from 'react';
import {Switch, TouchableOpacity, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {
  AppConfigCheckbox,
  AppText,
  CheckBox,
  MainContainer,
  MainHeader,
} from '../../components';
import {Colors} from '../../constants';
import {goBack, setValue} from '../../utils';
import styles from './styles';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import axios from 'axios';
import {updateUserData} from '../../redux/reduxsauceActions/authActions';
import {strings} from '../../i18n';

export default function NotificationSetting(props) {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const onChangeSetting = (type, value) => {
    dispatch(CommonActions.setLoading(true));

    axios
      .put('/user/setting/update/' + user?.setting?.id, {
        order_update_notification:
          type == 'order_Update'
            ? value
            : user?.setting?.order_update_notification,
        promotion_notification:
          type == 'promotion' ? value : user?.setting?.promotion_notification,
      })
      .then((response) => {
        console.log('response -->  ', response);
        dispatch(CommonActions.setLoading(false));
        dispatch(
          updateUserData({
            ...user?.setting,
            order_update_notification:
              type == 'order_Update'
                ? value
                : user?.setting?.order_update_notification,
            promotion_notification:
              type == 'promotion'
                ? value
                : user?.setting?.promotion_notification,
          }),
        );
      })
      .catch((error) => {
        console.log('error->', error.response);
        dispatch(CommonActions.setLoading(false));
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error.response.message || 'Something want wrong',
          }),
        );
      });
  };

  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('notificationSettings.title')}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />

      <MainContainer useView>
        <View style={styles.mainContainer}>
          <AppConfigCheckbox
            title={strings('notificationSettings.orderUpdate')}
            desc={strings('notificationSettings.orderUpdateDescription')}
            checked={user?.setting?.order_update_notification}
            onChangeCheck={(checked) => {
              onChangeSetting('order_Update', checked);
            }}
          />

          <AppConfigCheckbox
            title={strings('notificationSettings.offer')}
            desc={strings('notificationSettings.offerDescrption')}
            checked={user?.setting?.promotion_notification}
            onChangeCheck={(checked) => {
              onChangeSetting('promotion', checked);
            }}
          />
        </View>
      </MainContainer>
    </View>
  );
}
