import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  orderContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  reOderButton: {
    backgroundColor: Colors.black,
    position: 'relative',
    marginLeft: 4,
    right: setXAxisValue(10),
    bottom: setYAxisValue(15),
    borderRadius: setValue(20),
  },
  reOderButtonCurrentStatusTab: {
    backgroundColor: Colors.black,
    position: 'absolute',
    marginLeft: 4,
    right: setXAxisValue(10),
    bottom: setYAxisValue(15),
    borderRadius: setValue(20),
  },
  reOrderButtonText: {
    color: Colors.primary_color,
    marginHorizontal: setXAxisValue(10),
    marginVertical: setYAxisValue(5),
  },
  0: {
    backgroundColor: Colors.theme_color,
    padding: setValue(3),
    marginBottom: setYAxisValue(10),
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 3,
    borderRadius: 8,
  },
  1: {
    backgroundColor: Colors.secondary_color,
    padding: setValue(3),
    marginBottom: setYAxisValue(10),
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 3,
    borderRadius: 8,
  },
  orderId:{
    color: Colors.white,
    fontSize: setXAxisValue(14),
  },

  orderDate: {
    color: Colors.white,
    opacity:0.7,
    fontSize: setXAxisValue(14),
  },
  orderTypeText: {
    color: Colors.white,
    fontSize: setXAxisValue(14),
    right: setXAxisValue(10),
    marginLeft: setXAxisValue(10),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  orderPrice: {
    color: Colors.white,
    marginStart: setXAxisValue(8),
    marginBottom: setYAxisValue(8),
    fontSize: setXAxisValue(22),
    //
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
