import React from 'react';
import {Image, View, TouchableOpacity} from 'react-native';
import {Colors} from '../../constants';
import {Icon, AppText} from '../../components';
import Icon4 from '@expo/vector-icons/FontAwesome';
import {setValue} from '../../utils';
import styles from './styles';

const CategoryItem = (props) => {
  const iconColor = Colors.white;
  const {item, isActive} = props;
  return (
    <TouchableOpacity
      // onLongPress={drag}
      disabled={isActive}
      style={styles.itemContainer}
      key={item?.id}
    >
      <View style={styles.categoryContainer}>
        {/* <View style={styles.categoryImages}> */}
        <Image
          style={styles.image}
          source={
            item?.photo
              ? {uri: item?.photo}
              : require('../../../assets/images/General/no-img-found.jpg')
          }
          resizeMode="cover"
        />
        {/* </View> */}
      </View>
      <View style={styles.categoryDetailContainer}>
        <AppText style={styles.categoryName} numberOfLines={2}>
          {item?.name}
        </AppText>
        {item?.description ? (
          <AppText numberOfLines={1} style={styles.categoryDescripation}>
            {item?.description}
          </AppText>
        ) : null}
        <View style={styles.buttonContainer}>
          <TouchableOpacity onPress={props.onEditPress} style={styles.button}>
            <Icon name="edit" size={setValue(18)} color="#fff" />
          </TouchableOpacity>
          <TouchableOpacity style={styles.button} onPress={props.onDeletePress}>
            <Icon4 name="trash" size={setValue(18)} color="#fff" />
          </TouchableOpacity>
        </View>
      </View>
    </TouchableOpacity>
  );
};
export default CategoryItem;
