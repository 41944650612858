import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {
  FlatList,
  TouchableOpacity,
  View,
  Text,
  Image,
  Platform,
} from 'react-native';
import {
  AppText,
  MainHeader,
  LongButton,
  Icon,
  MainContainer,
} from '../../components';
import {goBack, setValue} from '../../utils';
import {useDispatch, useSelector} from 'react-redux';
import Icon4 from '@expo/vector-icons/FontAwesome';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {useIsFocused} from '@react-navigation/native';

import styles from './styles';
import {strings} from '../../i18n';

const ManageServicesCategoryScreen = (props) => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const config = useSelector((state) => state?.config);

  const [serviceCategoryList, setServiceCategoryList] = useState([]);

  const getServiceCategoryList = () => {
    dispatch(CommonActions.setLoading(true));
    axios
      .get('/services/services-category/list/' + config?.businessId)
      .then((response) => {
        console.log('response of services-category list===', response);
        setServiceCategoryList(response?.data);
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        dispatch(CommonActions.setLoading(trfalseue));
        console.log('error in response of services-category list===', error);
      });
  };

  const deleteBlogCategory = async (id) => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios
        .delete('/services/services-category/delete/' + id)
        .then((response) => {
          console.log('response of delete category----- ', response);
          dispatch(CommonActions.setLoading(false));
          props.navigation.navigate('manage-services-category');
          getServiceCategoryList();
        });
    } catch (error) {
      console.log('error -------------->', error?.response);
      dispatch(openAlert(error?.response?.message));
      dispatch(CommonActions.setLoading(false));
    }
  };

  useEffect(() => {
    isFocused && getServiceCategoryList();
  }, [isFocused]);

  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('manageServiceCategory.title')}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer useView>
        {serviceCategoryList != 0 && (
          <FlatList
            data={serviceCategoryList}
            keyExtractor={(item, index) => index}
            renderItem={({item, index}) => {
              return (
                <>
                  <TouchableOpacity style={styles.itemContainer} key={item?.id}>
                    <View style={styles.categoryContainer}>
                      <Image
                        style={styles.image}
                        source={
                          item?.photo
                            ? {uri: item?.photo}
                            : require('../../../assets/images/General/no-img-found.jpg')
                        }
                        resizeMode="cover"
                      />
                    </View>
                    <View style={styles.categoryDetailContainer}>
                      <AppText style={styles.categoryName} numberOfLines={2}>
                        {item?.name}
                      </AppText>
                      <View style={styles.buttonContainer}>
                        <TouchableOpacity
                          onPress={() => {
                            props.navigation.navigate(
                              'edit-services-category',
                              {
                                item: item,
                              },
                            );
                          }}
                          style={styles.button}
                        >
                          <Icon name="edit" size={setValue(18)} color="#fff" />
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={styles.button}
                          onPress={() =>
                            dispatch(
                              CommonActions.setAlert({
                                visible: true,
                                content: strings('blogCategoryList.askDelete'),
                                rightButton: strings(
                                  'addNewBlogCategory.confirm',
                                ),
                                rightPress: async () => {
                                  deleteBlogCategory(item?.id);
                                },
                                leftButton: strings(
                                  'addNewBlogCategory.cancel',
                                ),
                                leftPress: null,
                              }),
                            )
                          }
                        >
                          <Icon4
                            name="trash"
                            size={setValue(18)}
                            color="#fff"
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                  </TouchableOpacity>
                </>
              );
            }}
          />
        )}
        {Platform.OS == 'web' && (
          <LongButton
            onPress={() => props.navigation.navigate('edit-services-category')}
            loading={false}
            text={strings('manageServiceCategory.button.createNewCategory')}
            style={{
              marginTop: setValue(20),
              marginBottom: setValue(45),
            }}
          />
        )}
      </MainContainer>
      {Platform.OS != 'web' && (
        <LongButton
          text={strings('manageServiceCategory.button.createNewCategory')}
          style={{marginTop: 10, borderRadius: 0}}
          onPress={() => props.navigation.navigate('edit-services-category')}
        />
      )}
    </View>
  );
};
export default ManageServicesCategoryScreen;
