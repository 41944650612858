import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import AppText from '../AppText';
import styles from './styles';

export default props => (
  <TouchableOpacity style={styles.wrapper} onPress={props.onPress}>
    <View style={[styles.container, props.style]}>
      <AppText style={[styles.text, props.textStyle]}>{props.text}</AppText>
    </View>
  </TouchableOpacity>
);
