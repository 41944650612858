import {StyleSheet} from 'react-native';
import { Colors } from '../../constants';
import { setValue, setXAxisValue, setYAxisValue } from '../../utils';

export default StyleSheet.create({
  container: {
    flex:1
  },
  textInput: {
    height: setValue(50),
    padding: 0,
    // flex: 1,
    paddingHorizontal: setXAxisValue(10),
    marginBottom: setYAxisValue(10),
    borderRadius: 5,
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
  },
  textInputContainer: {
    height: setValue(60),
  },
  inputRightSide: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    right: setValue(10),
    alignSelf: 'center',
    top: setValue(15),
    elevation: 10,
  },
});
