import axios from 'axios';
import get from 'lodash.get';
import moment from 'moment';
import {goBack} from '../../utils';
import {Platform} from 'react-native';

import {strings} from '../../i18n';
import AuthActions from '../reduxsauce/authRedux';
import CommonActions from '../reduxsauce/commonRedux';
import Constants from 'expo-constants';

const ENV = Constants.manifest.extra.ENV;

export const newRegisterAccount =
  (
    {
      data,
      email,
      password,
      phone,
      first_name,
      last_name,
      business_name,
      description,
      category,
      street,
      area,
      city,
      pincode,
      state,
      country,
    },
    navigation,
  ) =>
  async (dispatch, getState) => {
    dispatch(CommonActions.setLoading(true));
    try {
      const response = await axios
        .post('/v2/user/merchant/register', {
          email,
          phone,
          password,
          first_name,
          last_name,
          // business: getState().config.businessId,
          type: 'merchant',
          business_name,
          description,
          category,
          street,
          area,
          city,
          pincode,
          state,
          country,
        })
        .then((response) => response.data);
      console.log('new Registration :: ', response);
      if (!response.is_active) {
        navigation.navigate('verify-otp', {user: response});
      } else {
        dispatch(AuthActions.setUser(response));
      }
    } catch (e) {
      console.log(e.response);
      // console.log(typeof e.response.data == 'object');
      // console.log(e.response.data[Object.keys(e.response.data)[0]]);

      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: e.response.message,
        }),
      );
    }

    dispatch(CommonActions.setLoading(false));
  };

export const emailPassWOrdLogin =
  (data, navigation) => async (dispatch, getState) => {
    const {config, common} = getState();
    dispatch(CommonActions.setLoading(true));
    // try {
    //   const response =
    axios.defaults.headers.common['Authorization'] = '';
    delete axios.defaults.headers.common['Authorization'];
    let body = {
      username: data?.username,
      password: data?.password,
      type: data?.type,
    };
    if (data?.businessId) {
      body = {
        ...body,
        business_id: data?.businessId,
      };
    }
    await axios
      .post('/user/merchant/login', body)
      .then((response) => {
        console.log('EmailPasswordLogin', response.data);
        // if (['local', 'development'].includes(ENV)) {
        //   if (!response.data.approved) {
        //     dispatch(CommonActions.setLoading(false));
        //     return navigation.navigate('pending-approvement');
        //   }
        // }

        if (response.data.is_active) {
          dispatch(CommonActions.setLoading(false));
          if (!response.data.business) {
            navigation.replace('create-business', {user: response.data});
            return;
          }
          dispatch(AuthActions.setUser(response.data));
          if (common.loginFrom) {
            navigation.replace(common.loginFrom);
            dispatch(CommonActions.setLoginFrom(null));
            return;
          }
          navigation.replace('home');
        } else {
          dispatch(CommonActions.setLoading(false));
          navigation.navigate('verify-otp', {
            user: {
              ...response?.data,
              email: username,
              business: getState().config?.businessId,
            },
          });
        }
      })
      // navigation.navigate('Home');
      // }
      .catch((e) => {
        console.log('error->', e);
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: e.response.message,
          }),
        );
        dispatch(CommonActions.setLoading(false));
      });
  };

export const verifyOTP = (data, navigation) => async (dispatch, getState) => {
  const {config, common} = getState();
  dispatch(CommonActions.setLoading(true));
  console.log('data', data);
  const body = {
    otp: data.otp,
    user: data.id,
  };
  try {
    const response = await axios.post('/user/verify-otp', body);
    console.log('response', response);
    dispatch(CommonActions.setLoading(false));
    if (!response.data.business) {
      navigation.replace('create-business', {user: response.data});
      return;
    }
    dispatch(AuthActions.setUser(response.data));
    if (common.loginFrom) {
      navigation.replace(common.loginFrom);
      dispatch(CommonActions.setLoginFrom(null));
      return;
    }

    navigation.replace('home');
  } catch (error) {
    console.log('error', error);
    dispatch(
      CommonActions.setAlert({
        visible: true,
        content: error.response.message || 'Invalid OTP try again.',
      }),
    );
    dispatch(CommonActions.setLoading(false));
  }
};

export const verifyCode = (data, navigation) => async (dispatch) => {
  dispatch(CommonActions.setLoading(true));
  try {
    // const response = await verifyCodeApi(data);
    // if (response.memberId) {
    //   dispatch(AuthActions.setUser(response));
    //   navigation.navigate('SetNewPin', {
    //     user: response,
    //     onDone: async () => {
    //       await dispatch(setLoginAndProfile(response));
    //       navigation.navigate('Home');
    //     },
    //   });
    // } else {
    //   dispatch(
    //     CommonActions.setAlert({
    //       visible: true,
    //       content: strings('message.failToVerify'),
    //     }),
    //   );
    // }
  } catch ({message}) {
    dispatch(CommonActions.setAlert({visible: true, content: message}));
  }
  dispatch(CommonActions.setLoading(false));
};

export const logout = (navigation, token) => async (dispatch, getState) => {
  const {config} = getState;

  dispatch(CommonActions.setLoading(true));
  let url = '/user/logout';
  if (token) {
    url += '?token_from=admin&type=expo&token=' + token;
  }
  await axios
    .get(url)
    .then(async (response) => {
      console.log('response', response);
      axios.defaults.headers.common['Authorization'] = '';
      delete axios.defaults.headers.common['Authorization'];
      await dispatch(AuthActions.logout());
      await dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('message.logoutSuccess'),
        }),
      );
      // !config?.theme?.isLoginRequired &&
      //   (Platform.OS == 'web'
      //     ? await window.location.replace('/')
      //     : await goBack(navigation));

      dispatch(CommonActions.setLoading(false));
    })
    .catch((error) => {
      console.log('error->', error);
      dispatch(CommonActions.setLoading(false));
      CommonActions.setAlert({
        visible: true,
        content: error?.response?.message || 'Something went wrong.',
      });
    });
};

export const resendVerificationCode = (phone) => async (dispatch) => {
  dispatch(CommonActions.setLoading(true));
  try {
    // const response = await resendVerificationCodeApi(phone);
    // if (response) {
    //   dispatch(
    //     CommonActions.setAlert({
    //       visible: true,
    //       content: strings('message.resendSuccess'),
    //     }),
    //   );
    // }
  } catch ({message}) {
    dispatch(CommonActions.setAlert({visible: true, content: message}));
  }
  dispatch(CommonActions.setLoading(false));
};

export const createPickupLocation = (data) => async (dispatch, getState) => {};

export const updateAddress = () => async (dispatch, getState) => {};

export const addressList = () => async (dispatch) => {
  dispatch(CommonActions.setLoading(true));
  axios
    .get('/user/address/list')
    .then((response) => {
      dispatch(CommonActions.setLoading(false));
      console.log('response addressList -->', response.data);
      dispatch(AuthActions.setAddress(response.data));
    })
    .catch((error) => {
      console.log('address List', error);
      dispatch(CommonActions.setLoading(false));
    });
};

export const deleteAddress = (id) => async (dispatch, getState) => {
  dispatch(CommonActions.setLoading(true));
  axios
    .delete('/user/address/delete/' + id)
    .then((response) => {
      dispatch(CommonActions.setLoading(false));
      console.log('response addressList -->', response.data);
      dispatch(addressList());
    })
    .catch((error) => {
      console.log('address List', error);
      dispatch(CommonActions.setLoading(false));
    });
};
export const pickupLocationList = () => async (dispatch) => {
  dispatch(CommonActions.setLoading(true));
  axios
    .get('/delivery/pickup/location/list')
    .then((response) => {
      dispatch(CommonActions.setLoading(false));
      console.log('response PickupLocationList -------------->', response.data);
      dispatch(AuthActions.setPickupLocation(response.data));
    })
    .catch((error) => {
      console.log('PickupLocation List', error);
      dispatch(CommonActions.setLoading(false));
    });
};

export const deletePickupLocation = (id) => async (dispatch, getState) => {
  dispatch(CommonActions.setLoading(true));
  axios
    .delete('/delivery/pickup/location/delete/' + id)
    .then((response) => {
      dispatch(CommonActions.setLoading(false));
      console.log('response PickupLocationList -->', response.data);
      dispatch(pickupLocationList());
    })
    .catch((error) => {
      console.log('PickupLocation List', error);
      dispatch(CommonActions.setLoading(false));
    });
};

export const updateUserData = (data) => async (dispatch, getState) => {
  const {auth} = getState();
  dispatch(AuthActions.setUser({...auth?.user, setting: data}));
};
