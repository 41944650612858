import React from 'react';
import {Platform} from 'react-native';
import {useSelector} from 'react-redux';
import GradientView from './GradientView';
import OvalView from './OvalView';

function MainContainer(props) {
  const config = useSelector((state) => state.config);
  const Layout =
    Platform.OS == 'web'
      ? OvalView
      : config?.theme?.screenBackground == 'gradient' || true
      ? GradientView
      : OvalView;

  return <Layout {...props} />;
}

export default MainContainer;
