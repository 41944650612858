import React from 'react';
import {Text, View, TouchableOpacity} from 'react-native';
import {Colors} from '../../constants';
import {Icon, AppText} from '../../components';
import {setValue} from '../../utils';
import styles from './styles';

const TableItem = (props) => {
  const iconColor = Colors.white;
  const {item, isActive} = props;
  return (
    <TouchableOpacity
      disabled={isActive}
      style={styles.itemContainer}
      key={item?.id}
    >
      <View style={styles.categoryDetailContainer}>
        <AppText style={styles.tableItemTitle}>
          {'Table Number : '}
          {item?.number}
        </AppText>
        {item?.capacity ? (
          <AppText style={styles.tableName}>
            {'Capacity : '}
            {item?.capacity}
          </AppText>
        ) : null}
      </View>
      <View style={styles.buttonContainer}>
        <TouchableOpacity onPress={props.onEditPress} style={styles.button}>
          <Icon name="edit" size={setValue(18)} color="#fff" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.button} onPress={props.onDeletePress}>
          <Icon name="dustBinSmaller" size={setValue(18)} color="#fff" />
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  );
};
export default TableItem;
