import React, { Component } from 'react';
import { Image, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { strings } from '../../i18n';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import AppText from '../AppText';
import styles from './styles';

class Alert extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  leftPress = () => {
    const { alert, closeAlert } = this.props;
    if (alert.leftPress) {
      closeAlert();
      alert.leftPress();
    } else {
      closeAlert();
    }
  };

  rightPress = () => {
    const { alert, closeAlert } = this.props;
    if (alert.rightPress) {
      closeAlert();
      alert.rightPress();
    } else {
      closeAlert();
    }
  };

  render() {
    const { alert, closeAlert } = this.props;

    return (alert.title || alert.content) && alert.visible ? (
      <View style={styles.container}>
        <View style={styles.overlay}>
          <View style={styles.alertContainer}>
            {alert.title ? (
              <View style={styles.information}>
                <AppText style={styles.title}>{alert.title}</AppText>
              </View>
            ) : null}
            {alert.image ? <Image style={styles.images} source={alert.image} /> : null}
            {alert.content ? <AppText style={styles.content}>{alert.content}</AppText> : null}
            {alert.leftButton && alert.rightButton ? (
              <View style={styles.button2Container}>
                <TouchableOpacity onPress={this.leftPress} style={styles.button2}>
                  <AppText style={styles.okText}>{alert.leftButton}</AppText>
                </TouchableOpacity>
                <TouchableOpacity onPress={this.rightPress} style={styles.button2}>
                  <AppText style={styles.okText}>{alert.rightButton}</AppText>
                </TouchableOpacity>
              </View>
            ) : alert.rightButton ? (
              <TouchableOpacity onPress={this.rightPress} style={styles.button}>
                <AppText style={styles.okText}>{alert.rightButton}</AppText>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity onPress={closeAlert} style={styles.button}>
                <AppText style={styles.okText}>{strings('common.ok')}</AppText>
              </TouchableOpacity>
            )}
          </View>
        </View>
      </View>
    ) : null;
  }
}

export default connect(
  state => ({
    alert: state.common.alert
  }),
  dispatch =>
    bindActionCreators(
      {
        closeAlert: () => CommonActions.setAlert({ visible: false })
      },
      dispatch
    )
)(Alert);
