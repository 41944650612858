import React, {useEffect, useState} from 'react';
import {
  ActivityIndicator,
  Image,
  Platform,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as ImagePicker from 'expo-image-picker';
import {
  AppText,
  ClientAppImage,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../../components';
import {Colors} from '../../../constants';
import {strings} from '../../../i18n';
import {goBack, setValue} from '../../../utils';
import styles from './styles';
// import ConfigActions from '../../redux/reduxsauce/configRedux';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import axios from 'axios';
import {connectActionSheet} from '@expo/react-native-action-sheet';

const BusinessDetail = (props) => {
  const config = useSelector((state) => state.config);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [businessDetails, setBusinessDetails] = useState({
    id: '',
    name: '',
    details: '',
    logo: '',
    app_type: '',
    street: '',
    area: '',
    city: '',
    state: '',
    pincode: '',
    country: '',
  });

  useEffect(() => {
    fetchBusinessDetail();
  }, []);

  const fetchBusinessDetail = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/business/details/${config?.businessId}`,
      );
      console.log('response payment detail', response);
      const {data} = response;
      setBusinessDetails({
        id: data?.id.toString(),
        name: data?.name,
        details: data?.description,
        logo: data?.logo,
        app_type: data?.app_type,
        street: data?.street,
        area: data?.area,
        city: data?.city,
        state: data?.state,
        pincode: data?.pincode,
        country: data?.country,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error', error);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };

  const dataURLtoFile = (dataurl) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], 'image1.jpg', {type: mime});
  };

  const onSaveData = async () => {
    if (!businessDetails?.name || businessDetails?.name == '') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('bussinessDetail.alert.name'),
        }),
      );
    } else if (!businessDetails?.details || businessDetails?.details == '') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('bussinessDetail.alert.description'),
        }),
      );
    } else if (!businessDetails?.street || businessDetails?.street == '') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('bussinessDetail.alert.street'),
        }),
      );
    } else if (!businessDetails?.area || businessDetails?.area == '') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('bussinessDetail.alert.area'),
        }),
      );
    } else if (!businessDetails?.city || businessDetails?.city == '') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('bussinessDetail.alert.city'),
        }),
      );
    } else if (!businessDetails?.state || businessDetails?.state == '') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('bussinessDetail.alert.state'),
        }),
      );
    } else if (!businessDetails?.pincode || businessDetails?.pincode == '') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('bussinessDetail.alert.pincode'),
        }),
      );
    } else if (!businessDetails?.country || businessDetails?.country == '') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('bussinessDetail.alert.country'),
        }),
      );
    } else {
      setLoading(true);
      try {
        let body = new FormData();
        body.append('name', businessDetails?.name);
        body.append('description', businessDetails?.details);
        body.append('app_type', businessDetails?.app_type);
        body.append('street', businessDetails?.street);
        body.append('area', businessDetails?.area);
        body.append('city', businessDetails?.city);
        body.append('state', businessDetails?.state);
        body.append('pincode', businessDetails?.pincode);
        body.append('country', businessDetails?.country);

        if (
          businessDetails?.logo &&
          businessDetails?.logo?.slice(0, 4) != 'http'
        ) {
          if (Platform.OS == 'web') {
            body.append('logo', dataURLtoFile(businessDetails?.logo));
          } else {
            let blob = await (await fetch(businessDetails?.logo)).blob();
            body.append('logo', {
              uri: businessDetails?.logo,
              type: blob.type,
              name: blob.data.name,
            });
          }
        }
        console.log('body', body);

        const response = await axios.patch(
          `/business/update/${config?.businessId}`,
          body,
        );
        console.log('response', response);
        setLoading(false);
        goBack(props.navigation);
      } catch (error) {
        setLoading(false);
        console.log('error', error?.response);
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.message || 'Something went wrong :(',
          }),
        );
      }
    }
  };

  const openActionSheet = () => {
    const options = [
      strings('actionSheet.takePhoto'),
      strings('actionSheet.choosePhoto'),
      strings('actionSheet.removePhoto'),
      strings('actionSheet.cancel'),
    ];
    const cancelButtonIndex = 3;

    props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) openCamera();
        if (buttonIndex === 1) openImagePicker();
        if (buttonIndex === 2) removePhoto();
      },
    );
  };

  const removePhoto = () => {
    // props.removeProfilePicture();
    setBusinessDetails({...businessDetails, logo: ''});
  };

  const openCamera = async () => {
    await ImagePicker.requestCameraPermissionsAsync();

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      // setImage(result);
      setBusinessDetails({...businessDetails, logo: result?.uri});
    }

    if (!result.cancelled) {
      // changeProfilePicture(result);
    }
  };

  const openImagePicker = async () => {
    await ImagePicker.requestMediaLibraryPermissionsAsync();

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      setBusinessDetails({...businessDetails, logo: result?.uri});
    }

    if (!result.cancelled) {
      // changeProfilePicture(result);
    }
  };

  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('bussinessDetail.title')}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />

      {loading ? (
        <View style={{flex: 1, justifyContent: 'center'}}>
          <ActivityIndicator color={Colors.theme_color} size={'small'} />
        </View>
      ) : (
        <>
          <MainContainer>
            <View style={styles.profileStrengthContainer}>
              <TouchableOpacity
                style={styles.imageContainer}
                onPress={openActionSheet}
              >
                {!businessDetails?.logo ? (
                  <Icon name="add" size={16} color={Colors.black60} />
                ) : (
                  <View>
                    <Image
                      source={
                        businessDetails?.logo
                          ? {uri: businessDetails?.logo}
                          : require('../../../../assets/images/General/no-img-found.jpg')
                      }
                      style={styles.image}
                    />
                    <TouchableOpacity
                      style={styles.cameraIcon}
                      onPress={openActionSheet}
                    >
                      <Icon name="camera" color={Colors.black20} />
                    </TouchableOpacity>
                  </View>
                )}
              </TouchableOpacity>
            </View>
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>
                {strings('bussinessDetail.label.id')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCorrect={false}
                editable={false}
                placeholder={strings('bussinessDetail.placeholder.id')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={
                  Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
                }
                value={businessDetails?.id}
                onChangeText={(text) => {
                  setBusinessDetails({
                    ...businessDetails,
                    id: text,
                  });
                }}
              />
            </View>

            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel} required>
                {strings('bussinessDetail.label.name')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCorrect={false}
                placeholder={strings('bussinessDetail.placeholder.name')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={
                  Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
                }
                value={businessDetails?.name}
                onChangeText={(text) => {
                  setBusinessDetails({
                    ...businessDetails,
                    name: text,
                  });
                }}
              />
            </View>
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel} required>
                {strings('bussinessDetail.label.description')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                numberOfLines={3}
                multiline={true}
                autoCorrect={false}
                placeholder={strings('bussinessDetail.placeholder.description')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={
                  Platform.OS == 'web'
                    ? styles.textInputWeb
                    : [styles.textAreaInput]
                }
                value={businessDetails?.details}
                onChangeText={(text) => {
                  setBusinessDetails({
                    ...businessDetails,
                    details: text,
                  });
                }}
              />
            </View>
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>
                {strings('bussinessDetail.label.type')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCorrect={false}
                editable={false}
                placeholder={strings('bussinessDetail.placeholder.type')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={
                  Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
                }
                value={businessDetails?.app_type}
                onChangeText={(text) => {
                  setBusinessDetails({
                    ...businessDetails,
                    app_type: text,
                  });
                }}
              />
            </View>

            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel} required>
                {strings('bussinessDetail.label.street')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCorrect={false}
                placeholder={strings('bussinessDetail.placeholder.street')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={
                  Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
                }
                value={businessDetails?.street}
                onChangeText={(text) => {
                  setBusinessDetails({
                    ...businessDetails,
                    street: text,
                  });
                }}
              />
            </View>
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel} required>
                {strings('bussinessDetail.label.area')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCorrect={false}
                placeholder={strings('bussinessDetail.placeholder.area')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={
                  Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
                }
                value={businessDetails?.area}
                onChangeText={(text) => {
                  setBusinessDetails({
                    ...businessDetails,
                    area: text,
                  });
                }}
              />
            </View>

            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel} required>
                {strings('bussinessDetail.label.city')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCorrect={false}
                placeholder={strings('bussinessDetail.placeholder.city')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={
                  Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
                }
                value={businessDetails?.city}
                onChangeText={(text) => {
                  setBusinessDetails({
                    ...businessDetails,
                    city: text,
                  });
                }}
              />
            </View>

            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel} required>
                {strings('bussinessDetail.label.state')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCorrect={false}
                placeholder={strings('bussinessDetail.placeholder.state')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={
                  Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
                }
                value={businessDetails?.state}
                onChangeText={(text) => {
                  setBusinessDetails({
                    ...businessDetails,
                    state: text,
                  });
                }}
              />
            </View>

            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel} required>
                {strings('bussinessDetail.label.pincode')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCorrect={false}
                placeholder={strings('bussinessDetail.placeholder.pincode')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={
                  Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
                }
                value={businessDetails?.pincode}
                onChangeText={(text) => {
                  setBusinessDetails({
                    ...businessDetails,
                    pincode: text,
                  });
                }}
              />
            </View>
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel} required>
                {strings('bussinessDetail.label.country')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCorrect={false}
                placeholder={strings('bussinessDetail.label.country')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={
                  Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
                }
                value={businessDetails?.country}
                onChangeText={(text) => {
                  setBusinessDetails({
                    ...businessDetails,
                    country: text,
                  });
                }}
              />
            </View>
            {Platform.OS == 'web' && (
              <LongButton
                disabled={loading}
                text={strings('bussinessDetail.button.save')}
                style={{
                  width: '100%',
                  fontFamily: 'Roboto-Bold',
                  borderRadius: 5,
                  marginBottom: setValue(15),
                }}
                onPress={onSaveData}
              />
            )}
          </MainContainer>
          {Platform.OS != 'web' && (
            <LongButton
              disabled={loading}
              text={strings('bussinessDetail.button.save')}
              style={{
                width: '100%',
                fontFamily: 'Roboto-Bold',
                borderRadius: 0,
              }}
              onPress={onSaveData}
            />
          )}
        </>
      )}
    </View>
  );
};

const ConnectedClientAppImage = connectActionSheet(BusinessDetail);
export default ConnectedClientAppImage;
