import AsyncStorage from '@react-native-async-storage/async-storage';
// import base64 from 'base-64';
import Constants from 'expo-constants';
import * as ImageManipulator from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';
import {Camera} from 'expo-camera';
import get from 'lodash.get';
import React, {Component} from 'react';
import {
  Image,
  ScrollView,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
  AppText,
  DropDownInput,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../components';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import PhoneInput from '../../lib/react-native-phone-input';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {
  goBack,
  isEmail,
  isPhoneNumberValid,
  setValue,
  setXAxisValue,
} from '../../utils';
import styles from './styles';

class ContactUsScreen extends Component {
  constructor(props) {
    super(props);
    const {profile} = props;
    this.state = {
      feedBack: '',
      images: [],
      imageBytes: [],
      name: get(profile, 'nickname', ''),
      email: get(profile, 'email', ''),
      phone: get(profile, 'mobileNo', ''),
      message: '',
      tooltipVisible: false,
    };
  }

  componentDidMount() {
    // this.getSupportCategory();
    if (this.phoneInput && this.state.phone === '') {
      this.setState({
        phone: `+${this.phoneInput.getCountryCode()}`,
      });
    }
    // this.handleCheckGuideAvailable();
  }

  // handleCheckGuideAvailable = async () => {
  //   const guideScreen = await AsyncStorage.getItem('guideScreen');
  //   if (guideScreen !== 'no') {
  //     this.setState({tooltipVisible: true});
  //   }
  // };

  onSendFeedBack = () => {
    const {feedBack, name, email, phone, message, imageBytes} = this.state;
    const {openAlert, navigation} = this.props;
    const currentVersion = Constants.manifest.version.toString();

    if (name.trim() === '') {
      return openAlert(strings('message.nameEmpty'));
    }
    if (email.trim() === '') {
      return openAlert(strings('message.emailEmpty'));
    }
    if (!isEmail(email)) {
      return openAlert(strings('message.emailInvalid'));
    }
    if (phone.trim() === '') {
      return openAlert(strings('message.phoneEmpty'));
    }
    if (!isPhoneNumberValid(phone.trim())) {
      return openAlert(strings('message.phoneInvalid'));
    }
    if (message.trim() === '') {
      return openAlert(strings('message.messageEmpty'));
    }
    // return this.props.sendContactSupport(
    //   {
    //     name,
    //     email,
    //     mobileNo: phone,
    //     category: feedBack,
    //     message,
    //     images: imageBytes,
    //     version: currentVersion,
    //   },
    //   navigation,
    // );
  };

  checkAndOpenImagePicker = async () => {
    if (Constants.platform.ios) {
      const {status} = await Camera.requestCameraPermissionsAsync();
      if (status === 'granted') {
        this.openImagePicker();
      }
    } else {
      this.openImagePicker();
    }
  };

  openImagePicker = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      // aspect: [4, 3],
      quality: 1,
      base64: true,
      compress: 0.5,
    });

    if (!result.cancelled) {
      this._compressImage(result);
    }
  };

  _compressImage = async (pickerResult) => {
    const manipResult = await ImageManipulator.manipulateAsync(
      pickerResult.uri,
      [{resize: {width: 200, height: 200}}],
      {compress: 0.5, format: 'jpeg', base64: true},
    );
  };

  removeImage = (index) => {
    const {images, imageBytes} = this.state;
    images.splice(index, 1);
    imageBytes.splice(index, 1);
    this.setState({images, imageBytes});
  };

  renderAttachImage = () => {
    const {images} = this.state;
    return (
      <View style={styles.imageList}>
        {images.map((item, index) => (
          <View
            key={item}
            style={[
              styles.imageFeedback,
              {width: `${100 / images.length}%`},
              {marginRight: index === 3 ? 0 : setXAxisValue(5)},
            ]}
          >
            <Image
              source={
                item
                  ? {uri: item}
                  : require('../../../assets/images/General/no-img-found.jpg')
              }
              style={styles.imageFeedback}
              resizeMode="contain"
            />
            <TouchableOpacity
              style={styles.crossIcon}
              onPress={() => this.removeImage(index)}
            >
              <Icon name="cross" color={Colors.white} size={15} />
            </TouchableOpacity>
          </View>
        ))}
      </View>
    );
  };

  render() {
    const {navigation, countryCode} = this.props;
    const {feedBack, images, name, email, phone, message, supportCategory} =
      this.state;
    return (
      <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
        <MainHeader
          title={strings('contactUsScreen.feedBack')}
          leftIcon="back"
          leftPress={() => goBack(navigation)}
        />
        <MainContainer useView>
          <View style={styles.textInputContainer}>
            <TextInput
              underlineColorAndroid="transparent"
              // autoCapitalize="words"
              autoCorrect={false}
              placeholder={strings('contactUsScreen.placeHolder.name')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={styles.textInput}
              value={name}
              onChangeText={(text) => this.setState({name: text})}
            />
          </View>
          <View style={styles.textInputContainer}>
            <TextInput
              ref={(input) => {
                this.emailInput = input;
              }}
              underlineColorAndroid="transparent"
              autoCapitalize="none"
              autoCorrect={false}
              placeholder={strings('contactUsScreen.placeHolder.email')}
              placeholderTextColor={Colors.color30}
              keyboardType="email-address"
              returnKeyType="done"
              style={styles.textInput}
              value={email}
              onChangeText={(text) => this.setState({email: text})}
            />
          </View>
          <View style={styles.textInputContainer}>
            <PhoneInput
              ref={(input) => {
                this.phoneInput = input;
              }}
              initialCountry={countryCode}
              style={styles.textInput}
              value={phone}
              onSelectCountry={() => {
                this.setState({
                  phone: `+${this.phoneInput.getCountryCode()}`,
                });
              }}
              inputStyle={{
                height: setValue(50),
                justifyContent: 'center',
              }}
              textProps={{
                underlineColorAndroid: 'transparent',
                autoCapitalize: 'none',
                autoCorrect: false,
                placeholder: strings('contactUsScreen.placeHolder.phone'),
                placeholderTextColor: Colors.color30,
                keyboardType: 'phone-pad',
                returnKeyType: 'next',
                value: phone,
                onChangeText: (text) => this.setState({phone: text}),
              }}
            />
          </View>
          <DropDownInput
            data={supportCategory}
            value={feedBack}
            onChangeText={(text) => this.setState({feedBack: text})}
          />
          <View style={styles.textInputMultiLineContainer}>
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              placeholder={strings('contactUsScreen.placeHolder.message')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              multiline
              numberOfLines={10}
              value={message}
              onChangeText={(text) => this.setState({message: text})}
              style={styles.textInputMultiLine}
            />
          </View>
          {/* <Tooltip
            isVisible={this.state.tooltipVisible}
            content={
              <AppText style={styles.tooltip}>
                {strings('guide.attachImages')}
              </AppText>
            }
            placement="top"
            onClose={() => this.setState({tooltipVisible: false})}
          > */}
          <View style={styles.attachPhotoContainer}>
            <View style={styles.imageContainer}>
              {images.length === 0 ? (
                <AppText style={styles.attachText}>
                  {strings('contactUsScreen.attachPhoto')}
                </AppText>
              ) : (
                this.renderAttachImage()
              )}
            </View>
            {images.length < 3 && (
              <TouchableOpacity
                style={styles.addIcon}
                onPress={this.checkAndOpenImagePicker}
              >
                <Icon name="plus" color={Colors.white} />
              </TouchableOpacity>
            )}
          </View>
          {/* </Tooltip> */}
          <LongButton
            text={strings('contactUsScreen.send')}
            style={styles.sendButton}
            onPress={this.onSendFeedBack}
          />
        </MainContainer>
      </ScrollView>
    );
  }
}

export default connect(
  (state) => ({
    profile: state.auth.profile,
    countryCode: state.common.countryCode,
    appConfig: state.config,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        openAlert: (content) =>
          CommonActions.setAlert({visible: true, content}),
        setLoading: CommonActions.setLoading,
      },
      dispatch,
    ),
)(ContactUsScreen);
