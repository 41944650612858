import {StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {setYAxisValue, setValue, setXAxisValue, screenWidth} from '../../utils';

let TotalWidth =
  screenWidth > 1023 ? 992 : screenWidth - 2 * setXAxisValue(15) - 22;
const shadow = {
  shadowColor: Colors.black60,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
};

export default StyleSheet.create({
  topProductItem: {
    width: setValue(200),
    borderRadius: 5,
    padding: 0,
    backgroundColor: Colors.white,
    marginHorizontal: 7,
    marginBottom: 4,
    ...shadow,
  },
  productImage: {
    height: undefined,
    width: '100%',
    aspectRatio: 3 / 2,
    // height: setValue(100),
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  productImageWeb: {
    aspectRatio: 3 / 2,
    width: setValue('100%'),
    height: undefined,
    // height: setValue(135),
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  topProductItemName: {
    color: Colors.black60,
  },
  topProductItemDesc: {
    color: '#bbb',
  },
  topProductItemPrice: {
    fontFamily: 'Roboto-Bold',
    color: Colors.black60,
    fontSize: setValue(15),
  },
  deleteIconContainer: {
    position: 'absolute',
    backgroundColor: Colors.white,
    zIndex: 999,
    right: 5,
    top: 5,
    height: setValue(25),
    width: setValue(25),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 25,
    ...shadow,
  },
  checkBoxContainer: {
    position: 'absolute',
    backgroundColor: Colors.white,
    zIndex: 999,
    right: 5,
    top: 5,
    height: setValue(25),
    width: setValue(25),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
    ...shadow,
  },
  gridItem:
    screenWidth > 576
      ? {
          marginVertical: setValue(7.5),
          marginHorizontal: setValue(3),
          backgroundColor: 'white',
          borderRadius: setValue(10),
          marginLeft: 5,
          marginRight: 1,
          width:
            180 +
            180 *
              ((TotalWidth / 180 - Math.floor(TotalWidth / 180)) /
                Math.floor(TotalWidth / 180)) -
            Math.floor(TotalWidth / 180),
          ...shadow,
        }
      : {
          marginVertical: setValue(3),
          marginHorizontal: 2,
          width: '49.2%',
          borderRadius: 5,
          backgroundColor: Colors.white,
          ...shadow,
        },
  discountText: {
    marginLeft: 6,
    paddingTop: setValue(3),
    color: Colors.color30,
    fontSize: setValue(12),
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  AddToCart: {
    position: 'absolute',
    right: setXAxisValue(15),
    top: setYAxisValue(-30),
    padding: setValue(8),
    borderRadius: setValue(30),
    backgroundColor: Colors.theme_color,
  },
  outOfStock: {
    zIndex: 9999,
    backgroundColor: Colors.wallet_card_red,
    position: 'absolute',
    alignSelf: 'center',
    paddingHorizontal: 10,
    borderWidth: 2,
    borderRadius: setValue(5),
    borderColor: Colors.wallet_card_red,
  },
  FoodType: {
    padding: setValue(3),
    borderWidth: 1.5,
    position: 'absolute',
    right: setXAxisValue(5),
    top: setYAxisValue(5),
    borderRadius: 3,
    zIndex: 1000,
    backgroundColor: Colors.white_gray,
  },
});
