import {get} from 'lodash';
import React, {useEffect, useState} from 'react';
import {
  View,
  TextInput,
  FlatList,
  ActivityIndicator,
  TouchableOpacity,
  Platform,
} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';
import {
  AppText,
  MainContainer,
  MainHeader,
  ProductCategoryItem,
  Icon,
  NoRecordFound,
  ProductItemGird,
} from '../../components';
import styles from './styles';
import {strings} from '../../i18n';
import {
  goBack,
  screenWidth,
  setValue,
  setYAxisValue,
  setXAxisValue,
} from '../../utils';
import {
  getServices,
  getServicesCategories,
} from '../../redux/reduxsauceActions/servicesActions';
import {Colors} from '../../constants';
import {useIsFocused} from '@react-navigation/native';
import axios from 'axios';
import ProgressiveImage from '../../components/ProgressiveImage';
import {Fontisto, Feather} from '@expo/vector-icons';
import CommonActions from '../../redux/reduxsauce/commonRedux';

const ServicesListScreen = (props) => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const {serviceSearchLoading} = useSelector((state) => state.services);
  const auth = useSelector((state) => state?.auth);
  let currencySymbol = get(config, 'currencySymbol', '₹');

  const [selectedItem, setSelectedItem] = useState(null);
  const [searchText, setSearchText] = useState('');
  const {common, config} = useSelector((state) => state);
  const [serviceList, setServiceList] = useState([]);
  const [serviceCategoryList, setServiceCategoryList] = useState([]);
  const [imageHeight, setImageHeight] = useState(0);
  const [changeView, setChangeView] = useState('grid-view');

  const renderSearchBar = () => {
    let timer;
    return (
      <View style={styles.searchBarContainer}>
        <TextInput
          underlineColorAndroid="transparent"
          placeholder={strings('ordering.placeOrderScreen.searchHint')}
          placeholderTextColor="gray"
          autoCorrect={false}
          style={styles.searchBarText}
          enablesReturnKeyAutomatically
          returnKeyType="search"
          onChangeText={(text) => {
            dispatch(getServices(text, selectedItem ? selectedItem : false));
            clearTimeout(timer);
            timer = setTimeout(() => {}, 600);
          }}
          defaultValue={searchText}
        />
        <View style={styles.searchBarIcon}>
          {searchText.length === 0 ? (
            <Icon name="search" size={18} color="gray" />
          ) : (
            <TouchableOpacity onPress={this.onClearText}>
              <Icon name="cross" size={18} color="gray" />
            </TouchableOpacity>
          )}
        </View>
      </View>
    );
  };

  const getServicesList = async () => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios.get('/services/merchant/list').then((response) => {
        console.log('accommodation response --> ', response);
        setServiceList(response?.data);
        dispatch(CommonActions.setLoading(false));
      });
    } catch (error) {
      dispatch(CommonActions.setLoading(false));
      console.log('message', error?.response?.message);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };

  const getServiceCategoryList = async () => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios
        .get('/services/services-category/list/' + config?.businessId)
        .then((response) => {
          console.log('services category response --> ', response);
          setServiceCategoryList(response?.data);
          dispatch(CommonActions.setLoading(false));
        });
    } catch (error) {
      dispatch(CommonActions.setLoading(false));
      console.log('message', error?.response?.message);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };

  useEffect(() => {
    isFocused && getServicesList();
    isFocused && getServiceCategoryList();
  }, [isFocused]);

  const renderProductList = () => {
    let TotalWidth =
      screenWidth > 1023 ? 992 : screenWidth - 2 * setXAxisValue(15) - 22;

    let addProduct = {
      id: '0',
      name: 'Add Service',
      icon: 'add',
      type: 'merchant',
    };

    let service = ['merchant', 'manager'].includes(auth.user.type)
      ? [addProduct, ...serviceList]
      : [...serviceList];

    if (service.length === 0) {
      return (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <NoRecordFound title="Product" hideImage={true} />
        </View>
      );
    } else {
      return (
        <FlatList
          key="grid"
          style={{width: '100%'}}
          contentContainerStyle={
            Platform.OS == 'web'
              ? {width: '100%', alignSelf: 'flex-start', marginHorizontal: -3}
              : {width: '100%', paddingBottom: 20}
          }
          data={service}
          showsVerticalScrollIndicator={false}
          numColumns={screenWidth > 576 ? parseInt(TotalWidth / 180) : 2}
          renderItem={({item, index}) => {
            if (item?.type == 'merchant') {
              return (
                <TouchableOpacity
                  style={styles.productItemGridView}
                  onPress={() => navigation.navigate('edit-services')}
                >
                  <View style={[styles.addProductContainer]}>
                    <Icon name={item?.icon} size={16} color={Colors.black60} />
                    <AppText style={styles.addProductTitle}>
                      {item.name}
                    </AppText>
                  </View>
                </TouchableOpacity>
              );
            } else {
              return (
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate('services-details', {item: item})
                  }
                  style={
                    index % 2 == 0
                      ? [styles.productContainer, {marginRight: setValue(3)}]
                      : [styles.productContainer, {marginLeft: setValue(3)}]
                  }
                  keyExtractor={item.id}
                >
                  <ProgressiveImage
                    thumbnailSource={
                      item?.images?.length > 0
                        ? {uri: item?.images[0]?.photo}
                        : require('../../../assets/images/General/no-img-found.jpg')
                    }
                    source={
                      item?.images?.length > 0
                        ? {uri: item?.images[0]?.photo}
                        : require('../../../assets/images/General/no-img-found.jpg')
                    }
                    style={[
                      styles.productImage,
                      {
                        aspectRatio: 4 / 3,
                        opacity:
                          !item.always_available_stock &&
                          parseFloat(item?.stock_qty) == 0
                            ? 0.5
                            : 1,
                      },
                    ]}
                    onLayout={(event) => {
                      const {height} = event.nativeEvent.layout;
                      setImageHeight(height);
                    }}
                    resizeMode="cover"
                  />
                  <AppText style={styles.productName} numberOfLines={1}>
                    {item.name}
                  </AppText>
                  <View style={{flexDirection: 'row', paddingHorizontal: 10}}>
                    {config.product?.hidePrice ? (
                      <AppText></AppText>
                    ) : (
                      <AppText style={styles.productPrice}>
                        {`${currencySymbol} ${item.price} ${' '}`}
                        {item.discount && item.discount != 0 ? (
                          <AppText style={styles.discountText}>
                            {`${currencySymbol} ${
                              parseInt(item.price) +
                              (parseInt(item.price) * parseInt(item.discount)) /
                                100
                            }`}
                          </AppText>
                        ) : null}
                      </AppText>
                    )}
                  </View>
                  <TouchableOpacity
                    onPress={() =>
                      navigation.navigate('edit-services', {item: item})
                    }
                    style={[
                      styles.productAddButton,
                      {top: imageHeight ? imageHeight - 18 : 0},
                    ]}
                  >
                    <Feather name="edit" size={16} color={Colors.white} />
                  </TouchableOpacity>
                  {config.type == 'restaurants' ? (
                    item.food_type == 'veg' ? (
                      <View
                        style={[styles.FoodType, {borderColor: Colors.green}]}
                      >
                        <Fontisto
                          name="record"
                          size={10}
                          color={Colors.green}
                        />
                      </View>
                    ) : (
                      item.food_type == 'non-veg' && (
                        <View style={[styles.FoodType, {borderColor: 'red'}]}>
                          <Fontisto
                            name="record"
                            size={10}
                            color={Colors.wallet_card_red}
                          />
                        </View>
                      )
                    )
                  ) : null}
                </TouchableOpacity>
              );
            }
          }}
          keyExtractor={(item, index) => index}
        />
      );
    }
  };

  const renderCategoryList = () => {
    
    let addCategory = {
      name: strings('ProductListScreen.addCatggory'),
      icon: 'add',
      type: 'merchant',
      onClickChange: () => {
        this.props.navigation.navigate('manage-category');
      },
    };
    let showAllItem = {
      id: null,
      name: 'Show All',
      description: '',
      photo: require('../../../assets/images/General/show-all.png'),
      deleted: false,
      business: null,
      type: 'all',
    };

    let serviceCategory = [addCategory, showAllItem, ...serviceCategoryList];
    return (
      <View style={{flexDirection: 'row'}}>
        <FlatList
          // ref="categoryList"
          contentContainerStyle={{flexGrow: 0}}
          horizontal
          scrollEnabled={true}
          keyExtractor={(item, index) => index}
          data={serviceCategory}
          renderItem={({item, index}) => {
            if (item?.type == 'merchant') {
              return (
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate('manage-services-category')
                  }
                >
                  <View style={styles.categoryButtonIconContainer}>
                    <View style={styles.categoryImages}>
                      <Icon
                        name={item?.icon}
                        size={16}
                        color={Colors.black60}
                      />
                    </View>
                  </View>
                  <AppText
                    style={[styles.categoryButtonText, {color: Colors.black60}]}
                    numberOfLines={2}
                  >
                    {item.name}
                  </AppText>
                </TouchableOpacity>
              );
            }

            return (
              <View>
                <ProductCategoryItem
                  item={item}
                  index={index}
                  OnPressCategory={() => {
                    console.log('service category pressed');
                  }}
                  // selectedItem={this.state.selectedItem}
                />
              </View>
            );
          }}
          showsHorizontalScrollIndicator={false}
          extraData={selectedItem}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <MainHeader
        leftIcon={'back'}
        leftPress={() => goBack(props.navigation)}
        title={strings('services.title')}
      />
      <MainContainer useView showFullWidth={true}>
        {renderSearchBar()}
        <View style={{flex: 1}}>
          {renderCategoryList()}
          {serviceSearchLoading ? (
            <View style={styles.serachContainer}>
              <ActivityIndicator size={45} color={Colors.theme_color} />
            </View>
          ) : serviceList.length == 0 ? (
            <View style={{flex: 1}}>
              <NoRecordFound
                title={strings('services.button.title')}
                message={strings('services.button.message')}
              />
            </View>
          ) : (
            renderProductList()
          )}
        </View>
      </MainContainer>
      {/* )} */}
    </View>
  );
};

export default ServicesListScreen;
