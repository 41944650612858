import {useIsFocused} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {FlatList, Platform, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {
  AppText,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
  SwipeRow,
} from '../../../components';
import {strings} from '../../../i18n';
import {goBack} from '../../../utils';
import CommonAction from '../../../redux/reduxsauce/commonRedux';
import axios from 'axios';
import styles from './styles';
import {Colors} from '../../../constants';

const PaymentList = (props) => {
  const isFocused = useIsFocused();
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config);

  const [methodList, setMethodList] = useState([]);

  const getPaymentMethodList = () => {
    dispatch(CommonAction.setLoading(true));

    axios
      .get('/business/payment-methods/list')
      .then((response) => {
        console.log(' payment methods list --', response.data);
        setMethodList(response?.data || []);
        dispatch(CommonAction.setLoading(false));
      })
      .catch((error) => {
        console.log('error ==>', error?.response);
        dispatch(CommonAction.setLoading(false));
      });
  };

  const activePaymentMethod = (id) => {
    dispatch(CommonAction.setLoading(true));

    axios
      .patch('/business/payment-methods/active/' + id, {
        is_active: true,
      })
      .then((response) => {
        console.log(' payment methods update --', response.data);
        getPaymentMethodList();
      })
      .catch((error) => {
        console.log('error ==>', error?.response);
        dispatch(CommonAction.setLoading(false));
      });
  };

  const deletePaymentMethod = (id) => {
    axios
      .delete('/business/payment-methods/delete/' + id)
      .then((response) => {
        console.log(' payment methods delete --', response.data);
        getPaymentMethodList();
      })
      .catch((error) => {
        console.log('error ==>', error?.response);
        dispatch(CommonAction.setLoading(false));
      });
  };

  useEffect(() => {
    isFocused && getPaymentMethodList();
  }, [isFocused]);

  const renderItem = ({item, index}) => {
    return (
      <SwipeRow
        key={index.toString()}
        editable={item?.deleted || item?.is_active ? false : true}
        disableSwipe={item?.deleted || item?.is_active ? true : false}
        leftOpenValue={70}
        rightOpenValue={-100}
        onPressEdit={() => {
          dispatch(
            CommonAction.setAlert({
              visible: true,
              content: 'Are you sure, you want to active this method?',
              rightButton: 'Yes',
              leftButton: 'No',
              leftPress: null,
              rightPress: () => activePaymentMethod(item.id),
            }),
          );
        }}
        onPressDelete={() => {
          dispatch(
            CommonAction.setAlert({
              visible: true,
              content: 'Are you sure, you want to delete this method?',
              rightButton: 'Yes',
              leftButton: 'No',
              leftPress: null,
              rightPress: () => deletePaymentMethod(item.id),
            }),
          );
        }}
      >
        <View
          style={[
            styles.methodItem,
            {backgroundColor: item?.deleted ? Colors.border : Colors.white},
          ]}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 3,
            }}
          >
            <AppText style={styles.methodType}>{item?.label}</AppText>
            {/* <Icon
              color={item?.is_active ? Colors.green : Colors.dark_yellow}
              name={item?.is_active ? 'verified' : 'unverified'}
            /> */}
            <AppText
              style={[
                styles.methodDetail,
                {color: item?.is_active ? Colors.green : Colors.dark_yellow},
              ]}
            >
              {item?.is_active ? 'Active' : 'InActive'}
            </AppText>
          </View>
          <AppText
            style={styles.methodDetail}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            <AppText style={{color: Colors.black60}}>{'Type : '}</AppText>
            {item.type}
          </AppText>
          <AppText
            style={styles.methodDetail}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            <AppText style={{color: Colors.black60}}>{'Key : '}</AppText>
            {item.payment_key}
          </AppText>
          <AppText
            style={styles.methodDetail}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            <AppText style={{color: Colors.black60}}>{'Secret Key : '}</AppText>
            {item.payment_key}
          </AppText>
        </View>
      </SwipeRow>
    );
  };

  return (
    <View style={{flex: 1}}>
      <MainHeader
        title={strings('settingScreen.paymentSetting')}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer useView={Platform.OS == 'web' ? false : true}>
        <FlatList
          data={methodList}
          contentContainerStyle={{flexGrow: 1}}
          initialNumToRender={10}
          maxToRenderPerBatch={5}
          nestedScrollEnabled={true}
          removeClippedSubviews={true}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          keyExtractor={(item, index) => index.toString()}
          renderItem={renderItem}
          ItemSeparatorComponent={() => <View style={{height: 10}} />}
          ListFooterComponent={() => (
            <View style={{height: Platform.OS == 'web' ? 50 : 100}} />
          )}
        />

        {Platform.OS == 'web' && (
          <LongButton
            text={'Add New Payment Method'}
            style={{width: '100%'}}
            onPress={() => {
              props.navigation.navigate('add-payment-configuration');
            }}
          />
        )}
      </MainContainer>
      {Platform.OS !== 'web' && (
        <LongButton
          text={'Add New Payment Method'}
          style={{width: '100%', borderRadius: 0}}
          onPress={() => {
            props.navigation.navigate('add-payment-configuration');
          }}
        />
      )}
    </View>
  );
};

export default PaymentList;
