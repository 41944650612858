import React, {Component} from 'react';
import {
  ScrollView,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import {strings} from '../../i18n';

import Modal from '../Modal';
import styles from './styles';

class TimePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedHour:
        this.props.initValue.length > 0
          ? this.props.initValue.substring(0, 2)
          : '',
      selectedMinute:
        this.props.initValue.length > 0
          ? this.props.initValue.substring(3, 5)
          : '',
      minHour: props.minHour,
      minMinute: props.minMinute,
      time: props.time,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      selectedHour:
        nextProps.initValue.length > 0
          ? nextProps.initValue.substring(0, 2)
          : '',
      selectedMinute:
        nextProps.initValue.length > 0
          ? nextProps.initValue.substring(3, 5)
          : '',
      minHour: nextProps.minHour,
      minMinute: nextProps.minMinute,
      time: nextProps.time,
    });
  }

  renderHourList = () => {
    let hours = [
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
    ];
    return (
      <ScrollView
        contentContainerStyle={{flexDirection: 'row', flexWrap: 'wrap'}}
      >
        {hours.map((hour, index) => (
          <TouchableOpacity
            key={hour}
            onPress={() => {
              this.setState({selectedHour: hour});
            }}
            style={
              this.state.selectedHour === hour
                ? styles.selectedHourContainer
                : styles.hourContainer
            }
          >
            <Text
              style={[
                this.state.selectedHour === hour
                  ? styles.selectedHourText
                  : styles.hourText,
                hour <= this.state.minHour &&
                  hour < this.state.selectedHour && {opacity: 0.5},
              ]}
            >
              {hour}
            </Text>
          </TouchableOpacity>
        ))}
      </ScrollView>
    );
  };

  renderMinuteList = () => {
    let minuts = [
      '00',
      '05',
      '10',
      '15',
      '20',
      '25',
      '30',
      '35',
      '40',
      '45',
      '50',
      '55',
    ];
    return (
      <ScrollView
        contentContainerStyle={{flexDirection: 'row', flexWrap: 'wrap'}}
      >
        {minuts.map((minute) => (
          <TouchableOpacity
            key={minute}
            onPress={() => {
              this.setState({selectedMinute: minute});
            }}
            style={
              this.state.selectedMinute === minute
                ? styles.selectedHourContainer
                : styles.hourContainer
            }
          >
            <Text
              style={[
                this.state.selectedMinute === minute
                  ? styles.selectedHourText
                  : styles.hourText,
                this.state.minMinute > minute &&
                  this.state.selectedHour <= this.state.minHour && {
                    opacity: 0.5,
                  },
              ]}
            >
              {minute}
            </Text>
          </TouchableOpacity>
        ))}
      </ScrollView>
    );
  };

  render() {
    return (
      <Modal {...this.props} transparent onRequestClose={this.props.onClose}>
        <View style={styles.overlay} onStartShouldSetResponder={() => true}>
          <View style={styles.container}>
            <View style={{alignItems: 'center'}}>
              <View style={{flexDirection: 'row'}}>
                <Text style={{fontSize: 17, margin: 16, marginBottom: 0}}>
                  {"Select time"}
                  {/* {strings('ordering.pickUpOrderScreen.theEarliestPickupTime')}: */}
                </Text>
                <Text
                  style={{
                    fontSize: 17,
                    marginLeft: -10,
                    margin: 16,
                    fontWeight: 'bold',
                    marginBottom: 0,
                  }}
                >
                  {this.state.time}
                </Text>
              </View>
            </View>
            <Text style={styles.textTitle}>{strings('common.hour')}</Text>
            {this.renderHourList()}
            <Text style={styles.textTitle}>{strings('common.minute')}</Text>
            {this.renderMinuteList()}
            {/* <View style={{alignItems:'center'}}>
              <View style={{ flexDirection: 'row', paddingBottom:10}}>
                  <Text style={{ fontSize: 15, margin: 16, font: '', marginBottom: 0 }}>Chosen Pickup Time: </Text>
                  <Text style={{ fontSize: 15, marginLeft: -10, margin: 16, font: '', fontWeight: 'bold', marginBottom: 0 }}>{this.state.selectedHour} : {this.state.selectedMinute}</Text>
              </View>
            </View> */}
            <View style={styles.buttonsContainer}>
              <TouchableOpacity
                onPress={this.props.onClose}
                style={styles.button2}
              >
                <Text style={styles.button}>{strings('common.cancel')}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.button2}
                onPress={() =>
                  this.props.onTimeChanged({
                    selectedHour: this.state.selectedHour,
                    selectedMinute: this.state.selectedMinute,
                  })
                }
              >
                <Text style={styles.button}>
                  {this.props.okBtnText
                    ? this.props.okBtnText
                    : strings('ordering.pickUpOrderScreen.selectAndPay')}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    );
  }
}

export default TimePicker;
