import React, {useEffect} from 'react';
import {TouchableOpacity, Image, View} from 'react-native';
import styles from './styles';
import AppText from '../AppText';
import ProgressiveImage from '../ProgressiveImage';

export default function ProductCategoryItem(props) {
  const {item, selectedItem} = props;

  return (
    <TouchableOpacity
      onPress={() => {
        props.OnPressCategory();
      }}
      key={item.id}
    >
      <View
        style={[
          styles.categoryButtonIconContainer,
          selectedItem === item.id ? styles.selected : null,
        ]}
      >
        <View style={styles.categoryImages}>
          {/* <Image
            style={styles.image}
            source={item.type == 'all' ? item.photo : {uri: item.photo}}
            resizeMode="cover"
          /> */}
          <ProgressiveImage
            thumbnailSource={
              item.type == 'all'
                ? item.photo
                : item.photo
                ? {uri: item.photo}
                : require('../../../assets/images/General/no-img-found.jpg')
            }
            source={
              item.type == 'all'
                ? item.photo
                : item.photo
                ? {uri: item.photo}
                : require('../../../assets/images/General/no-img-found.jpg')
            }
            style={styles.image}
            resizeMode="cover"
          />
        </View>
      </View>
      <AppText style={styles.categoryButtonText} numberOfLines={2}>
        {item.name}
      </AppText>
    </TouchableOpacity>
  );
}
