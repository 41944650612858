import React, {useEffect, useState} from 'react';
import {
  FlatList,
  Image,
  Keyboard,
  Platform,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import {
  AppText,
  CategoryItem,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../../components';
import {Colors} from '../../../constants';
import {goBack} from '../../../utils';
import styles from './styles';
import {connectActionSheet} from '@expo/react-native-action-sheet';
import {connect, useDispatch} from 'react-redux';
import {strings} from '../../../i18n';
import * as ImagePicker from 'expo-image-picker';
import {bindActionCreators} from 'redux';
import axios from 'axios';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import ProductActions from '../../../redux/reduxsauce/productRedux';
import {useSelector} from 'react-redux';

const EditSubCategory = (props) => {
  const config = useSelector((state) => state.config);
  const dispatch = useDispatch();
  const [image, setImage] = useState(
    props?.route?.params?.photo ? {uri: props?.route?.params?.photo} : null,
  );
  const [categoryDetail, setCategoryDetail] = useState({
    name: props?.route?.params?.name || '',
    des: props?.route?.params?.description || '',
  });

  const openActionSheet = () => {
    const options = [
      strings('actionSheet.takePhoto'),
      strings('actionSheet.choosePhoto'),
      strings('actionSheet.removePhoto'),
      strings('actionSheet.cancel'),
    ];
    const cancelButtonIndex = 3;

    props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) openCamera();
        if (buttonIndex === 1) openImagePicker();
        if (buttonIndex === 2) removePhoto();
      },
    );
  };

  const removePhoto = () => {
    // props.removeProfilePicture();
    setImage(null);
  };

  const openCamera = async () => {
    await ImagePicker.requestCameraPermissionsAsync();

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      setImage(result);
    }

    if (!result.cancelled) {
      // changeProfilePicture(result);
    }
  };

  const openImagePicker = async () => {
    await ImagePicker.requestMediaLibraryPermissionsAsync();

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      setImage(result);
    }

    if (!result.cancelled) {
      // changeProfilePicture(result);
    }
  };

  const dataURLtoFile = (dataurl) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], 'image1.jpg', {type: mime});
  };

  const onSave = async () => {
    props.setLoading(true);
    Keyboard.dismiss();

    const {user, config, categories} = props;

    if (categoryDetail.name == '') {
      props.setLoading(false);
      props.setAlert({
        visible: true,
        content: 'Category name required!',
      });
      return;
    }

    let body = new FormData();
    body.append('name', categoryDetail?.name);
    body.append('description', categoryDetail?.des);
    body.append('business', config.businessId);
    body.append('category', props.route?.params?.category);

    if (image?.uri && image?.uri?.slice(0, 4) != 'http') {
      if (Platform.OS == 'web') {
        body.append('photo', dataURLtoFile(image?.uri));
      } else {
        let blob = await (await fetch(image?.uri)).blob();
        body.append('photo', {
          uri: image?.uri,
          type: blob.type,
          name: blob.data.name,
        });
      }
    } else if (!image?.uri) {
      body.append('photo', '');
    }

    const header = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    console.log('body', body);
    if (props.route?.params?.edit) {
      await axios
        .put(
          '/products/product-sub-category/update/' + props.route?.params?.id,
          body,
          header,
        )
        .then((response) => {
          setCategoryDetail({
            ...categoryDetail,
            name: '',
            des: '',
          });
          setImage(null);
          props.setLoading(false);
          goBack(navigation);
        })
        .catch((error) => {
          props.setAlert({
            visible: true,
            content: error?.response?.message,
          });
          props.setLoading(false);
        });
    } else {
      await axios
        .post('/products/product-sub-category/create', body, header)
        .then((response) => {
          console.log('response', response);
          setCategoryDetail({
            ...categoryDetail,
            name: '',
            des: '',
          });
          setImage(null);
          props.setLoading(false);
          goBack(navigation);
        })
        .catch((error) => {
          console.log('error', error.response);
          props.setAlert({
            visible: true,
            content: error?.response?.message,
          });
          props.setLoading(false);
        });
    }
  };

  return (
    <View style={styles.container}>
      <MainHeader
        // title={strings('EditProductScreen.profileEditing')}
        title={
          props?.route?.params?.edit ? 'Edit Sub Category' : 'Add Sub Category'
        }
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer useView>
        <View style={styles.profileStrengthContainer}>
          <TouchableOpacity
            style={styles.imageContainer}
            onPress={openActionSheet}
          >
            {!image ? (
              <Icon name="add" size={16} color={Colors.black60} />
            ) : (
              <View>
                <Image source={{uri: image.uri}} style={styles.image} />
                <TouchableOpacity
                  style={styles.cameraIcon}
                  onPress={openActionSheet}
                >
                  <Icon name="camera" color={Colors.black20} />
                </TouchableOpacity>
              </View>
            )}
          </TouchableOpacity>
        </View>

        <View>
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel} required>
              {'Name'}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              // autoCapitalize="words"
              autoCorrect={false}
              placeholder={'Like FastFood, Electronics .. '}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
              value={categoryDetail.name}
              onChangeText={(text) =>
                setCategoryDetail({...categoryDetail, name: text})
              }
            />
          </View>
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>{'Description'}</AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              multiline={true}
              numberOfLines={3}
              placeholder={'Description'}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={styles.multiLineTextInput}
              value={categoryDetail.des}
              onChangeText={(text) =>
                setCategoryDetail({...categoryDetail, des: text})
              }
            />
          </View>
        </View>
        {Platform.OS == 'web' && (
          <LongButton
            text={'Save'}
            style={{
              marginTop: 20,
              marginBottom: 50,
            }}
            onPress={onSave}
          />
        )}
      </MainContainer>

      {Platform.OS !== 'web' && (
        <LongButton
          text={'Save'}
          style={{
            borderRadius: 0,
            width: '100%',
          }}
          onPress={onSave}
        />
      )}
    </View>
  );
};

const ConnectedEditSubCategory = connectActionSheet(EditSubCategory);

export default connect(
  (state) => ({
    categories: state.products?.categories,
    user: state.auth?.user,
    config: state.config,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setLoading: CommonActions.setLoading,
        setAlert: CommonActions.setAlert,
        setCategories: ProductActions.getCategories,
      },
      dispatch,
    ),
)(ConnectedEditSubCategory);
