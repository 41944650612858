import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {View, TextInput, TouchableOpacity, Platform} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {AppText, LongButton, MainContainer, MainHeader} from '../../components';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import {goBack, setValue} from '../../utils';
import styles from './styles';
import CommonAction from '../../redux/reduxsauce/commonRedux';
import {getAppConfig} from '../../redux/reduxsauceActions/configActions';

const DonationConfiguaration = (props) => {
  const config = useSelector((state) => state.config);
  const dispatch = useDispatch();

  const [donationData, setDonationData] = useState({
    donation_reward_coin_multiplier: config?.donation
      ?.donation_reward_coin_multiplier
      ? JSON.stringify(config?.donation?.donation_reward_coin_multiplier)
      : null,
  });

  const saveOrderConfigData = async () => {
    dispatch(CommonAction.setLoading(true));

    try {
      const response = await axios.patch(
        `/business/update/business-setting/${config?.businessId}`,
        {
          donation: {
            donation_reward_coin_multiplier: Number(
              donationData?.donation_reward_coin_multiplier,
            ),
          },
        },
      );
      console.log('response  donoation configuration ', response);
      dispatch(getAppConfig());
      dispatch(CommonAction.setLoading(false));
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: strings('subscriptionSetting.alert.success'),
        }),
      );
      goBack(props.navigation);
    } catch (error) {
      dispatch(CommonAction.setLoading(false));
      console.log('error', error);
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: error?.response?.message || 'Something went wrong :(',
        }),
      );
    }
  };
  return (
    <View style={styles.container}>
      <MainHeader
        title={'Donation Configuration'}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer>
        <View style={styles.textInputContainer}>
          <AppText style={styles.inputLabel} required>
            {'Donation reward coin multiplier'}
          </AppText>
          <TextInput
            underlineColorAndroid="transparent"
            autoCorrect={false}
            keyboardType="numeric"
            placeholder={'Enter donation reward coin multiplier'}
            placeholderTextColor={Colors.color30}
            returnKeyType="done"
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={donationData?.donation_reward_coin_multiplier}
            onChangeText={(text) =>
              setDonationData({
                ...donationData,
                donation_reward_coin_multiplier: text,
              })
            }
          />
        </View>

        {Platform.OS == 'web' && (
          <LongButton
            text={'Save Configuration'}
            style={{marginTop: setValue(10), marginBottom: setValue(45)}}
            onPress={saveOrderConfigData}
          />
        )}
      </MainContainer>
      {Platform.OS !== 'web' && (
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            style={styles.buttonButton}
            onPress={saveOrderConfigData}
          >
            <AppText style={styles.buttonText}>{'Save Configuration'}</AppText>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

export default DonationConfiguaration;
