import React, {useState} from 'react';
import {TextInput, TouchableOpacity, View} from 'react-native';
import {Colors} from '../../constants';
import AppText from '../AppText';
import Modal from '../Modal';
import styles from './styles';

export default function DeliveryRistInputModal(props) {
  const {deliveryRistType, isVisible} = props;

  const [deliveryRistTypeValue, setDeliveryRistTypeValue] = useState('');

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isVisible}
      onRequestClose={() => {
        setDeliveryRistTypeValue('');
        props.onClose();
      }}
    >
      <View style={styles.container}>
        <View style={styles.topContainer}>
          <View>
            <TextInput
              style={styles.textInput}
              placeholder={`Enter ${deliveryRistType}`}
              keyboardType={deliveryRistType == 'pincode' ? 'numeric' : 'text'}
              underlineColorAndroid="transparent"
              autoCapitalize="none"
              value={deliveryRistTypeValue}
              onChangeText={(e) => setDeliveryRistTypeValue(e)}
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              marginTop: 15,
              marginBottom: 5,
            }}
          >
            <TouchableOpacity
              style={[
                styles.touchable,
                {
                  marginRight: 2,
                  borderColor: Colors.theme_color,
                  borderWidth: 2,
                  backgroundColor: Colors.white,
                },
              ]}
              onPress={() => {
                setDeliveryRistTypeValue('');
                props.onClose();
              }}
            >
              <AppText style={styles.buttonText}>Cancal</AppText>
            </TouchableOpacity>

            <TouchableOpacity
              style={[
                styles.touchable,
                {marginLeft: 2, backgroundColor: Colors.theme_color},
              ]}
              onPress={() => {
                props.onSave(deliveryRistTypeValue);
                setDeliveryRistTypeValue('');
              }}
            >
              <AppText style={[styles.buttonText, {color: Colors.white}]}>
                Add
              </AppText>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
}
