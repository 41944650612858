import {
  View,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
} from 'react-native';
import React, {useEffect, useState} from 'react';
import {
  AppText,
  DatePicker,
  Icon,
  MainContainer,
  MainHeader,
  MerchantOrderItem,
  NoRecordFound,
} from '../../../components';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {goBack, isDateValid, setValue} from '../../../utils';
import axios from 'axios';
import {useDispatch} from 'react-redux';
import styles from './styles';
import {Colors} from '../../../constants';
import {strings} from '../../../i18n';
import {useIsFocused} from '@react-navigation/native';

const OfflineOrderList = (props) => {
  const [offlineOrderList, setOfflineOrderList] = useState([]);
  const [searchDate, setSearchDate] = useState('');
  const [offset, setOffSet] = useState(0);
  const [limit, setLimit] = useState(10);
  const [refreshingActive, setRefreshingActive] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [isDateVisible, setIsDateVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum,
  ] = useState({
    active: true,
    completed: true,
  });

  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      setOffSet(0);
      dispatch(CommonActions.setLoading(true));
      fetchInvoiceList();
    }
  }, [isFocused]);

  useEffect(() => {
    if (searchDate?.dateString) {
      dispatch(CommonActions.setLoading(true));
      fetchInvoiceList(0);
    } else if (isDateValid(searchDate) || searchDate == '') {
      dispatch(CommonActions.setLoading(true));
      fetchInvoiceList(searchDate, 0);
    }
  }, [searchDate]);

  const renderFooterLoading = () => {
    return offlineOrderList.length == totalCount ? null : (
      <View
        style={{
          padding: Platform.OS == 'web' ? 30 : 20,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ActivityIndicator color={Colors.theme_color} size="large" />
      </View>
    );
  };

  const fetchInvoiceList = async (text, offset = offset) => {
    let url = '/invoice/list?limit=' + limit;

    url += '&offset=' + (offset || 0);
    url += '&type=offline';

    let date = text ? text : searchDate?.dateString;

    console.log('date', date);
    if (date) {
      let getDate = searchDate?.dateString ? searchDate?.dateString : date;
      url += '&created_at=' + getDate;
    }
    setLoading(true);
    try {
      const response = await axios.get(url);
      console.log('response of invoice list----->', response);
      if (offset > 0) {
        setOfflineOrderList(offlineOrderList.concat(response?.data?.results));
        setTotalCount(response?.data?.count);
        dispatch(CommonActions.setLoading(false));
        setLoading(false);
      } else {
        setOfflineOrderList(response?.data?.results);
        setTotalCount(response?.data?.count);
        dispatch(CommonActions.setLoading(false));
        setLoading(false);
      }
    } catch (error) {
      dispatch(CommonActions.setLoading(false));
      setLoading(false);
      console.log('error', error);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message || 'Something went wrong :(',
        }),
      );
    }
  };

  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('homeScreen.offlineOrder')}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
        rightIcon={'setting'}
        rightPress={() => {
          props.navigation.navigate('offline-order-settings');
        }}
      />
      <MainContainer useView>
        <View style={{flex: 1, paddingTop: 10}}>
          <View style={styles.filterByDateContainer}>
            <AppText style={styles.titleFilter}>
              {strings('transactionHistoryScreen.filterByDate')}
            </AppText>
            <DatePicker
              title={
                <AppText style={styles.date}>
                  {(searchDate && searchDate?.dateString) ||
                    strings('transactionHistoryScreen.noFilter')}
                </AppText>
              }
              visible={isDateVisible}
              onClose={() => {
                setIsDateVisible(false);
                setSearchDate('');
              }}
              onDayPress={(date) => {
                setIsDateVisible(false);
                setOffSet(0);
                setSearchDate(date);
                // fetchInvoiceList(date?.dateString);
              }}
              onCancelPress={() => {
                setIsDateVisible(false);
                setSearchDate('');
              }}
              onSetDataForClass={(text) => {
                setOffSet(0);
                if (isDateValid(text)) {
                  setSearchDate(text);
                  // fetchInvoiceList(text != '--' && text);
                }
              }}
              onCalenderPress={() => {
                if (searchDate || searchDate != '') {
                  setIsDateVisible(false);
                  setSearchDate('');
                  // fetchInvoiceList(searchDate.dateString);
                } else {
                  setIsDateVisible(true);
                  setSearchDate('');
                }
              }}
              navigation={props.navigation}
            />
          </View>

          <TouchableOpacity
            style={{
              position: 'absolute',
              right: 0,
              bottom: 20,
              backgroundColor: Colors.theme_color,
              borderRadius: 50,
              zIndex: 1111,
            }}
            onPress={() => props.navigation.navigate('add-offline-order')}
          >
            <View
              style={{
                color: Colors.white,
                width: setValue(60),
                height: setValue(60),
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icon name="add" size={30} color={Colors.white} />
            </View>
          </TouchableOpacity>
          <View style={{flex: 1}}>
            {offlineOrderList?.length == 0 ? (
              <NoRecordFound title={'Invoices'} message={'No record found'} />
            ) : (
              <View style={{flex: 1}}>
                <FlatList
                  data={offlineOrderList}
                  refreshing={refreshingActive}
                  style={{paddingBottom: 20}}
                  onRefresh={() => {
                    setOffSet(0);
                  }}
                  keyExtractor={(item, index) => index}
                  showsVerticalScrollIndicator={false}
                  renderItem={({item, index}) => {
                    return (
                      <MerchantOrderItem
                        item={item}
                        from="invoice"
                        navigation={props.navigation}
                      />
                    );
                  }}
                  onEndReached={() => {
                    console.log('on-end-reach -- ', offlineOrderList.length);
                    if (offlineOrderList.length < 7) {
                      return;
                    }
                    offlineOrderList.length > limit - 1 &&
                      totalCount != offlineOrderList.length &&
                      fetchInvoiceList(searchDate, offlineOrderList.length);
                  }}
                  onEndReachedThreshold={0.5}
                  onMomentumScrollBegin={() => {
                    setOnEndReachedCalledDuringMomentum({
                      ...onEndReachedCalledDuringMomentum,
                      active: false,
                    });
                  }}
                  ListFooterComponent={renderFooterLoading}
                />
              </View>
            )}
          </View>
        </View>
      </MainContainer>
    </View>
  );
};

export default OfflineOrderList;
