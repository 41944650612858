import React from 'react';
import { View } from 'react-native';
import { MainContainer, MainHeader } from '../../../components';
import { goBack } from '../../../utils';
import styles from "./styles";

export default function BusinessOverview() {
  return (
    <View style={styles.container}>
      <MainHeader
        title={'Business Overview'}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer></MainContainer>
    </View>
  );
}
