import axios from 'axios';
import debounce from 'lodash.debounce';
import get from 'lodash.get';
import Moment from 'moment';
import React, {Component} from 'react';
import {
  FlatList,
  TextInput,
  TouchableOpacity,
  View,
  ActivityIndicator,
  Platform,
} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
  AppText,
  DatePicker,
  Icon,
  MainContainer,
  MainHeader,
  NoRecordFound,
  OrderItem,
  SegmentControl,
} from '../../components';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import {cashFormat, getParam, goBack, isDateValid} from '../../utils';
import styles from './styles';
import CommonActions from '../../redux/reduxsauce/commonRedux';

class OrderHistoryScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      searchText: '',
      searchDate: '',
      isDateVisible: false,
      isFromWallet: getParam(props.route, 'isFromWallet', false),
      refreshingActive: false,
      refreshingCompleted: false,
      loadMore: false,
      orderList: [],
      orderType: 'PickUp',
      type: '',
      limit: 10,
      offset: 0,
      loading: false,
      totalCount: 0,
    };

    this.currencySymbol = get(props.appConfig, 'currencySymbol', '₹');
    this.pageActive = 1;
    this.pageCompleted = 1;
    this.onEndReachedCalledDuringMomentum = {
      active: true,
      completed: true,
    };
  }

  componentDidMount = () => {
    // this.handleRefresh('active');
    // this.handleRefresh('completed');
    // this.interval = setInterval(() => this.fetchUpdatedOrderStatus(), 2000);
    this.setState(
      {
        limit: 10,
        offset: 0,
        searchText: '',
        orderList: [],
        searchDate: '',
        searchDate: this.state?.searchDate?.dateString
          ? this.state?.searchDate?.dateString
          : '',
      },
      () => this.fetchOrderList(),
    );

    const {navigation} = this.props;
    navigation.addListener('focus', () => {
      this.handleRefresh();
    });
  };

  fetchOrderList = (text) => {
    const {limit, offset, searchText, orderList, searchDate, type} = this.state;

    let url = '/order/list?limit=' + limit;
    if (offset > 0) {
      url += '&offset=' + offset;
    }
    if (searchText) {
      url += '&search=' + searchText;
    }
    let date = text ? text : searchDate.dateString;
    if (date) {
      url += '&created_at=' + date;
    }

    this.setState({loading: true});
    axios
      .get(url)
      .then((response) => {
        console.log('response->', response);
        offset > 0
          ? this.setState({
              orderList: orderList.concat(response.data.results),
              loading: false,
              totalCount: response.data.count,
            })
          : this.setState({
              orderList: response.data.results,
              loading: false,
              totalCount: response.data.count,
            });
      })
      .catch((error) => {
        this.props.openAlert(
          error?.response?.message || 'Something went worng :(',
        );
        this.setState({loading: false});
      });
  };

  handleRefresh = () => {
    this.setState(
      {
        limit: 10,
        offset: 0,
        searchText: '',
        orderList: [],
        searchDate: '',
      },
      () => this.fetchOrderList(),
    );
    // if (status === 'active') {
    //   this.pageActive = 1;
    // } else {
    //   this.pageCompleted = 1;
    // }
    // this.setState({
    //   refreshingActive: status === 'active',
    //   refreshingCompleted: status === 'completed',
    // });
    // await this.props.getPreviousOrders(
    //   1,
    //   this.state.searchText,
    //   this.state.searchDate,
    //   status,
    // );
    // this.setState({refreshingActive: false, refreshingCompleted: false});
  };

  handleLoadMore = (status) => {
    const {fetching} = this.props;
    const {offset, orderList} = this.state;
    this.setState(
      {
        offset: orderList.length,
      },
      () => this.fetchOrderList(),
    );
  };

  // onCheck = debounce(async () => {
  //   this.props.getPreviousOrders(
  //     1,
  //     this.state.searchText,
  //     this.state.searchDate,
  //     'active',
  //   );
  //   this.props.getPreviousOrders(
  //     1,
  //     this.state.searchText,
  //     this.state.searchDate,
  //     'completed',
  //   );
  // }, 700);

  // onReorderAll = item => {
  //   // console.log('checking order id :: ', item.previousCompletedOrders.id);
  //   // alert(this.props.order && this.props.order.id);
  //   const reorderItems = item.orderItems.filter(e => !e.fromVoucherProfileId);

  //   if (this.state.isFromWallet) {
  //     this.props.navigation.navigate('ChooseOrderType', {
  //       reorderItems,
  //     });
  //   } else if (item.id) {
  //     this.props.addItemsToOrder(
  //       reorderItems,
  //       this.props.navigation,
  //       strings('ordering.orderHistoryScreen.reorderSuccessful'),
  //     );
  //   } else {
  //     this.props.orderCheckIn(
  //       reorderItems,
  //       this.props.navigation,
  //       strings('ordering.orderHistoryScreen.reorderSuccessful'),
  //     );
  //   }
  // };

  onSeeOrderDetail = (item) => {
    // this.props.navigation.navigate('order-status', {
    //   // statusId: item.statusId,
    //   // orderId: item.orderRefNo,
    //   orderId: item.id, // TODO_CHANGE
    // });
    this.props.navigation.navigate('order-history-detail', {
      id: item.id,
    });
  };
  onSeeOrderHistory = (item) => {
    this.props.navigation.navigate('order-history-detail', {
      id: item.id,
    });
  };

  renderCurrentOrders = () => {
    const {orderList, limit, totalCount} = this.state;
    // const {previousActiveOrders} = this.props;
    // console.log('previousActiveOrders: ', previousActiveOrders);
    return (
      <View style={{flex: 1, paddingTop: 10}}>
        <FlatList
          data={orderList}
          renderItem={({item}) => (
            <OrderItem
              item={item}
              tab={this.state.tab}
              navigation={this.props.navigation}
              onSeeOrderHistory={this.onSeeOrderHistory}
              onSeeOrderDetail={this.onSeeOrderDetail}
            />
          )}
          refreshing={this.state.refreshingActive}
          onRefresh={() => this.handleRefresh('active')}
          keyboardShouldPersistTaps="always"
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          keyExtractor={(item, index) => index}
          onEndReached={() => {
            orderList.length > limit - 1 &&
              totalCount != orderList.length &&
              this.handleLoadMore('active');
          }}
          onEndReachedThreshold={0.5}
          onMomentumScrollBegin={() => {
            this.onEndReachedCalledDuringMomentum.active = false;
          }}
          ListFooterComponent={() => (
            <View style={{paddingBottom: 35}}>
              {/* {this.state.loadMore && this.state.loadMore === 'active' && ( */}
              {this.state.loading && (
                <ActivityIndicator size="large" color="#000" />
              )}
            </View>
          )}
        />
      </View>
    );
  };

  // renderPreviousOrders = () => {
  //   const {previousCompletedOrders} = this.props;
  //   return (
  //     <View style={{flex: 1, paddingTop: 10}}>
  //       <FlatList
  //         data={previousCompletedOrders}
  //         renderItem={this.renderOrderItem}
  //         refreshing={this.state.refreshingCompleted}
  //         onRefresh={() => this.handleRefresh('completed')}
  //         keyboardShouldPersistTaps="always"
  //         showsVerticalScrollIndicator={false}
  //         showsHorizontalScrollIndicator={false}
  //         onEndReached={() => this.handleLoadMore('completed')}
  //         onEndReachedThreshold={0.5}
  //         onMomentumScrollBegin={() => {
  //           this.onEndReachedCalledDuringMomentum.completed = false;
  //         }}
  //         ListFooterComponent={() => (
  //           <View style={{paddingBottom: 35}}>
  //             {this.state.loadMore && this.state.loadMore === 'completed' && (
  //               <ActivityIndicator color="#000" />
  //             )}
  //           </View>
  //         )}
  //       />
  //     </View>
  //   );
  // };

  renderItemInOrder = ({item}) => {
    let customizeText = item.productOptions
      ? item.productOptions.length > 0
        ? item.productOptions[0].items.map(
            (e) => (customizeText = `${e.name.trim()}. `),
          )
        : ''
      : '';

    return (
      <View style={{marginBottom: 5, flexDirection: 'row'}}>
        <View style={{flex: 1}}>
          <AppText
            style={{color: item.fromVoucherProfileId ? 'green' : 'black'}}
          >
            {item.outletProduct.name}
          </AppText>
          {customizeText.length > 0 && (
            <AppText style={{fontSize: 12, color: 'gray'}}>
              {customizeText}
            </AppText>
          )}
          {item.fromVoucherProfileId && (
            <AppText style={styles.vcRedeemed}>{`${strings(
              'ordering.myDiningCart.voucherRedeemed',
            )}`}</AppText>
          )}
        </View>
        <AppText style={{}}>{item.orderQty}</AppText>
      </View>
    );
  };
  renderDatepicker() {
    const {isDateVisible, searchDate} = this.state;
    return (
      <View style={styles.filterByDateContainer}>
        <AppText style={styles.titleFilter}>
          {strings('transactionHistoryScreen.filterByDate')}
        </AppText>
        <DatePicker
          navigation={this.props.navigation}
          title={
            <AppText style={styles.date}>
              {(searchDate && searchDate.dateString) ||
                strings('transactionHistoryScreen.noFilter')}
            </AppText>
          }
          visible={isDateVisible}
          onClose={() => this.setState({isDateVisible: false, searchDate: ''})}
          onDayPress={(date) => {
            this.setState({
              searchDate: date,
              isDateVisible: false,
            });
            this.fetchOrderList();
          }}
          onCancelPress={() => {
            this.setState({isDateVisible: false, searchDate: ''});
            // this.onCheck();
          }}
          onSetDataForClass={(text) => {
            this.setState({
              isDateVisible: false,
              searchDate: text,
            });
            if (isDateValid(text)) {
              this.fetchOrderList(text != '--' && text);
            }
          }}
          onCalenderPress={() => {
            if (searchDate) {
              this.setState({isDateVisible: false, searchDate: ''}, () =>
                this.fetchOrderList(),
              );
              // this.onCheck();
            } else {
              this.setState({isDateVisible: true, searchDate});
            }
          }}
        />
      </View>
    );
  }
  render() {
    const {isDateVisible, searchDate, orderList, offset, loading} = this.state;
    const {user} = this.props;
    const config = this.props.appConfig;
    return (
      <View style={styles.container}>
        <MainHeader
          title={strings('ordering.orderHistoryScreen.title')}
          leftIcon={
            this.props?.navigation.getState().type == 'tab' ? null : 'back'
          }
          leftPress={
            this.props?.navigation.getState().type == 'tab'
              ? null
              : () => goBack(this.props.navigation)
          }
        />
        <MainContainer useView>
          <View style={styles.textInputContainer}>
            <TextInput
              ref={(input) => {
                this.passwordInput = input;
              }}
              underlineColorAndroid="transparent"
              autoCapitalize="none"
              autoCorrect={false}
              placeholder={strings('transactionHistoryScreen.search')}
              placeholderTextColor={Colors.color30}
              style={styles.textInput}
              returnKeyType="done"
              value={this.state.searchText}
              onChangeText={(text) => {
                this.setState({searchText: text, offset: 0}, () =>
                  this.fetchOrderList(),
                );
              }}
            />

            {this.state.searchText.length === 0 ? (
              <TouchableOpacity style={styles.inputRightSide}>
                <Icon name="search" size={22} color={Colors.black20} />
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                style={styles.inputRightSide}
                onPress={() => this.setState({searchText: ''})}
              >
                <Icon name="cross" size={22} color="gray" />
              </TouchableOpacity>
            )}
          </View>
          {this.renderDatepicker()}
          {offset == 0 && loading ? (
            <View
              style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}
            >
              <ActivityIndicator size="large" color="#000" />
            </View>
          ) : (
            <View useView style={{flex: 1}}>
              {orderList?.length == 0 ? (
                <NoRecordFound
                
                  title={strings('ordering.orderHistoryScreen.norecord.title')}
                  message={strings('ordering.orderHistoryScreen.norecord.message')}
                  hideImage={true}
                />
              ) : (
                this.renderCurrentOrders()
              )}
            </View>
          )}
        </MainContainer>
      </View>
    );
  }
}

export default connect(
  (state) => ({
    previousActiveOrders: state.order.previousActiveOrders,
    previousCompletedOrders: state.order.previousCompletedOrders,
    appConfig: state.config,
    fetching: state.order.fetching || false,
    loading: state.common.loading,
    user: state.auth.user,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        openAlert: (content) =>
          CommonActions.setAlert({visible: true, content}),
        setLoading: CommonActions.setLoading,
      },
      dispatch,
    ),
)(OrderHistoryScreen);
