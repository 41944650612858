import {StyleSheet} from 'react-native';

import {Colors, Layout} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  forgetPasswordContainer: {
    shadowColor: Colors.black,
    alignItems: 'center',
    marginBottom: setYAxisValue(40),
    marginTop: setYAxisValue(40),
  },
  textInputContainer: {
    width: '100%',
    height: setValue(60),
  },
  textInput: {
    height: setValue(50),
    padding: 0,
    // flex: 1,
    paddingHorizontal: setXAxisValue(5),
    marginBottom: setYAxisValue(10),
    borderRadius: 5,
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    width: '100%',
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
  },
  inputRightSide: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    right: setValue(5),
    alignSelf: 'center',
    top: setValue(10),
    elevation: 10,
  },
  RadioGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'center',
    alignItems: 'center',
    marginVertical: setYAxisValue(20),
  },
  selectionCard: {
    flexDirection: 'row',
    borderWidth: 2,
    paddingHorizontal: setXAxisValue(15),
    marginHorizontal: 5,
    padding: setValue(10),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    borderColor: '#ccc',
    backgroundColor: Colors.white,
  },
  selectedSelectionCard: {
    flexDirection: 'row',
    borderWidth: 2,
    marginHorizontal: 5,
    paddingHorizontal: setXAxisValue(15),
    padding: setValue(10),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    borderColor: Colors.theme_color,
    backgroundColor: Colors.theme_color,
  },
  selectionText: {
    color: Colors.black20,
    fontSize: setValue(15),
    marginLeft: setValue(10),
  },
  selectedSelectionText: {
    color: Colors.white,
    fontSize: setValue(15),
    marginLeft: setValue(10),
  },
});
