import React, {Component} from 'react';
import {Text} from 'react-native';

class AppText extends Component {
  componentDidMount() {}

  render() {
    const {children, style, required = false, ...rest} = this.props;

    return (
      <Text
        style={[
          // { fontFamily: 'Roboto-Regular' },
          style,
        ]}
        {...rest}
      >
        {children}
        {required && <Text style={{color: '#cc0000'}}> {'*'}</Text>}
      </Text>
    );
  }
}

export default AppText;
