import { Platform, StyleSheet } from 'react-native';

import { setXAxisValue, setYAxisValue } from '../../utils';

export default StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between'
  },
  crossIcon: {
    position: 'absolute',
    top: Platform.select({ web: setYAxisValue(10), default: 30 }),
    right: setXAxisValue(10)
  }
});
