import {Platform, StyleSheet} from 'react-native';

import {Colors, Layout} from '../../constants';
import {
  screenHeight,
  screenWidth,
  setValue,
  setXAxisValue,
  setYAxisValue,
} from '../../utils';

let TotalWidth =
  screenWidth > 1023 ? 992 : screenWidth - 2 * setXAxisValue(15) - 22;

const shadow = {
  shadowColor: Colors.black60,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 5,
};
export default StyleSheet.create({
  container: {
    flex: 1,
  },
  outletName: {
    color: Colors.primary_color,
    fontSize: setXAxisValue(12),
    marginLeft: setXAxisValue(30),
  },
  searchBarContainer: {
    flexDirection: 'row',
    // marginTop: setYAxisValue(20),
    height: setValue(50),
    backgroundColor: 'white',
    borderRadius: setValue(8),
    marginBottom: setYAxisValue(15),
    ...shadow,
  },
  searchBarText: {
    color: 'black',
    marginHorizontal: setXAxisValue(20),
    flex: 1,
  },
  searchBarIcon: {
    marginRight: setXAxisValue(10),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },

  emtyProductText: {
    textAlign: 'center',
    marginTop: setYAxisValue(30),
  },
  cartContainer: {
    position: 'absolute',
    backgroundColor: Colors.theme_color,
    flexDirection: 'row',
    flex: 1,
    bottom: 0,
    paddingHorizontal: setXAxisValue(20),
    paddingVertical: setYAxisValue(10),
  },
  cartInfoContainer: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    alignContent: 'center',
  },
  cartText: {
    color: Colors.primary_color,
    fontSize: setXAxisValue(20),
  },
  cartQuantity: {
    color: Colors.primary_color,

    fontSize: setXAxisValue(20),
  },
  cartIcon: {
    padding: setValue(10),
  },
  categoryButtonIconContainer:
    Platform.OS == 'web'
      ? {
          backgroundColor: Colors.white,
          borderRadius: setValue(10),
          width: setValue(60),
          height: setValue(60),
          marginRight: setXAxisValue(5),
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: 3,
          ...shadow,
        }
      : {
          backgroundColor: Colors.white,
          borderRadius: setValue(10),
          width: setValue(60),
          height: setValue(60),
          marginRight: setXAxisValue(5),
          alignItems: 'center',
          justifyContent: 'center',
        },
  categoryButtonIconDarkContainer: {
    backgroundColor: Colors.white,
    borderRadius: setValue(15),
    width: setValue(50),
    height: setValue(30),
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    borderWidth: 1,
    borderColor: Colors.theme_color,
  },

  categoryImages: {
    width: setValue(50),
    justifyContent: 'center',
    height: setValue(50),
    alignItems: 'center',
  },
  categoryButtonText: {
    fontSize: setXAxisValue(12),
    textAlign: 'center',
    marginVertical: setYAxisValue(5),
    marginBottom: setYAxisValue(8),
    // marginLeft: setYAxisValue(8),
    width: 60,
  },
  categoryImage: {
    width: setValue(60),
    height: setValue(60),
  },
  addProductBtn: {
    marginVertical: setValue(10),
    borderWidth: 1.5,
    backgroundColor: '#FFF',
    borderColor: Colors.theme_color,
    color: Colors.theme_color,
    paddingVertical: 5,
    borderRadius: 5,
    margin: 0,
    elevation: 5,
  },
  addProductBtnText: {
    textAlign: 'center',
    padding: 8,
    color: Colors.theme_color,
    fontFamily: 'Roboto-Bold',
  },
  productItemGridView:
    screenWidth > 576
      ? {
          marginVertical: setValue(8),
          marginHorizontal: setValue(3),
          backgroundColor: 'white',
          borderRadius: setValue(10),
          marginLeft: 5,
          // width: 220,
          width:
            180 +
            180 *
              ((TotalWidth / 180 - Math.floor(TotalWidth / 180)) /
                Math.floor(TotalWidth / 180)) -
            Math.floor(TotalWidth / 180),
          ...shadow,
        }
      : {
          marginVertical: setValue(3),
          marginRight: setValue(3),
          // flex: 0.498,
          width: '48%',
          marginLeft: 5,
          padding: setValue(3),
          backgroundColor: 'white',
          borderRadius: setValue(10),
          ...shadow,
        },
  addProductContainer: {
    paddingVertical: setValue(8),
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    borderWidth: 0,
  },
  addProductTitle: {
    marginTop: 10,
    fontSize: setXAxisValue(15),
    fontWeight: 'bold',
    color: Colors.black60,
    lineHeight: setXAxisValue(15),
  },
  productItemListView: {
    marginVertical: setValue(3),
    flex: 1,
    flexDirection: 'row',
    padding: setValue(8),
    backgroundColor: 'white',
    borderRadius: setValue(5),
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 3,
  },
  fabBtn: {
    backgroundColor: Colors.theme_color,
    borderRadius: 40,
    position: 'absolute',
    bottom: 10,
    right: 10,
    ...shadow,
  },
  fabBtnWeb: {
    backgroundColor: Colors.theme_color,
    borderRadius: 40,
    position: 'absolute',
    bottom: 30,
    right: 30,
    ...shadow,
  },
  fabTextIcon: {
    padding: 12,
    justifyContent: 'center',
  },
  textInputContainer: {
    height: 70,
    backgroundColor: Colors.white,
    // borderWidth:2,
    borderRadius: 5,
    // marginHorizontal:1,
    marginBottom: setYAxisValue(10),
    ...shadow,
  },
  title: {
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
});
