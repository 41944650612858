import {Platform, StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {
  screenHeight,
  screenWidth,
  setValue,
  setXAxisValue,
  setYAxisValue,
} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 8,
  borderRadius: 5,
  backgroundColor: Colors.white,
};
export default StyleSheet.create({
  container: {
    flex: 1,
  },
  container2: {
    marginVertical: setYAxisValue(15),
    marginHorizontal: setXAxisValue(5),
    ...shadow,
  },
  orderedItemsText: {
    fontSize: setXAxisValue(20),
    paddingVertical: setYAxisValue(5),
    color: 'red',
  },
  orderList: {
    backgroundColor: 'white',
    borderRadius: 5,
    paddingTop: 10,
    marginTop: 10,
    minHeight: 200,
    marginBottom: setXAxisValue(20),
  },
  itemDetailContainer: {
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'row',
    paddingHorizontal: setXAxisValue(20),
    paddingVertical: setYAxisValue(5),
  },
  itemDetailContainerRedeemedVC: {
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'row',
    paddingStart: setXAxisValue(15),
  },
  itemName: {
    fontSize: setValue(14),
    // fontFamily: ''
  },
  productVarientsText: {
    fontSize: 12,
    textAlignVertical: 'bottom',
    color: 'gray',
  },
  itemCustomize: {
    fontSize: setValue(10),
    color: 'gray',
  },
  itemDetail2Container: {
    width: '50%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems:"flex-start",
  },
  itemQuantityText: {
    textAlign: 'center',
    width: '35%',
    paddingHorizontal: 2,
  },
  priceText: {
    width: '65%',
    textAlign: 'right',
    paddingHorizontal: 2,
  },

  whitePriceSign: {
    fontSize: setValue(10),
    color: 'white',
  },
  themeColorPriceSign: {
    fontSize: setValue(10),
    color: Colors.primary_color,
  },
  priceSign: {
    fontSize: setValue(10),
  },

  PRODUCT_COUPONRedeemedName: {
    color: 'red',
    fontSize: setValue(14),
    width: '70%',
    paddingTop: 5,
  },
  PRODUCT_VOUCHERRedeemedName: {
    color: 'orange',
    fontSize: setValue(14),
    width: '70%',
    paddingTop: 5,
  },
  PRODUCT_COUPONvcRedeemed: {
    backgroundColor: 'red',
    color: Colors.white_gray,
    fontSize: setValue(11),
    width: '30%',
    borderRadius: 5,
    padding: 2,
    paddingStart: setXAxisValue(10),
    justifyContent: 'center',
  },
  PRODUCT_VOUCHERvcRedeemed: {
    backgroundColor: 'orange',
    color: Colors.white_gray,
    fontSize: setValue(11),
    width: '30%',
    borderRadius: 5,
    padding: 2,
    paddingStart: setXAxisValue(10),
    justifyContent: 'center',
  },
  subTotalContainer: {
    backgroundColor: 'white',
    borderRadius: setValue(5),
    paddingHorizontal: setXAxisValue(10),
    paddingVertical: setYAxisValue(15),
    marginVertical: setYAxisValue(15),
    alignItems: 'center',
    justifyContent: 'center',
  },
  totalContainer: {
    flexDirection: 'row',
    backgroundColor: 'black',
    borderRadius: setValue(5),
    paddingHorizontal: setXAxisValue(10),
    paddingVertical: setYAxisValue(15),
    marginTop: setYAxisValue(15),
    alignItems: 'center',
    justifyContent: 'center',
  },
  subTotalItem: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.7)',
    borderRadius: setXAxisValue(5),
    paddingHorizontal: setXAxisValue(15),
    marginTop: setValue(10),
  },
  VCRedeemedInOrder: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    backgroundColor: Colors.theme_color,
    borderRadius: setXAxisValue(5),
    paddingHorizontal: setXAxisValue(15),
    marginTop: 7,
  },
  VCRedeemedInOrderTitle: {
    flex: 1,
    paddingVertical: setYAxisValue(10),
    fontSize: setValue(12),
    color: Colors.primary_color,
    // fontFamily: ''
  },
  VCRedeemedInOrderPrice: {
    //
    fontSize: setValue(14),
    color: Colors.primary_color,
  },
  subTotalItem1: {
    fontSize: setValue(14),
    fontFamily: 'Roboto-Bold',
  },
  subTotalItem2: {
    fontSize: setValue(14),
    fontFamily: 'Roboto-Bold',
  },
  netTotalItem1: {
    flex: 1,
    paddingVertical: setYAxisValue(10),
    fontSize: setValue(12),
    // fontFamily: ''
  },
  netTotalItem: {
    flex: 1,
    paddingVertical: setYAxisValue(10),
    fontSize: setValue(14),
    // fontFamily: ''
  },

  totalItem1: {
    flex: 1,
    color: 'white',
    fontSize: setValue(13),
    //
    marginStart: setXAxisValue(5),
  },
  totalItem2: {
    color: 'white',
    fontSize: setValue(18),
    //
    marginEnd: setYAxisValue(5),
  },
  netTotalItem2: {
    // fontFamily: ''
  },
  noteText: {
    color: '#ac2a1c',
    //
    fontSize: setYAxisValue(12),
  },
  netTotalContainer: {
    paddingHorizontal: setXAxisValue(10),
  },
  redeemVCContainer: {
    marginTop: setYAxisValue(25),
  },
  vcItemContainer: {
    marginRight: setXAxisValue(5),
    padding: setValue(3),
    backgroundColor: 'white',
    borderRadius: setValue(5),
  },
  vcItemImage: {
    aspectRatio: 3 / 2,
    borderRadius: setValue(5),
    borderColor: 'rgba(0, 0, 0, 0.1)',
    borderWidth: 1,
  },
  vcItemName: {
    marginTop: setYAxisValue(5),
    marginBottom: setYAxisValue(5),
    marginHorizontal: setXAxisValue(10),
    fontSize: setXAxisValue(13),
    //
    width: screenWidth * 0.6,
    flex: 1,
  },
  quantityContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
  },
  quantityVCContainerPopup: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: setValue(5),
  },
  exceedAmountPopup: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: setValue(5),
    marginBottom: 100,
    flexWrap: 'wrap',
    backgroundColor: Colors.white,
    flexDirection: 'row',
  },
  exceedAmountTotalPopup: {
    alignItems: 'flex-start',
    backgroundColor: 'white',
    borderRadius: setValue(5),
    marginBottom: 100,
    flexWrap: 'wrap',
    backgroundColor: Colors.white,
  },
  vcItemImagePopup: {
    aspectRatio: 3 / 2,
    width: screenWidth * 0.6,
    borderRadius: setValue(5),
  },
  vcItemNamePopop: {
    marginVertical: setYAxisValue(5),
    paddingHorizontal: setXAxisValue(10),
    fontSize: setXAxisValue(13),
    //
    width: screenWidth * 0.6,
  },
  quantityContainerPopup: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: setValue(5),
    width: screenWidth * 0.6,
  },
  buttonVCquantityPopup: {
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: 20,
    borderColor: Colors.theme_color,
    marginVertical: setValue(10),
    paddingHorizontal: setValue(3),
    marginHorizontal: setValue(3),
    paddingVertical: setValue(5),
  },
  buttonVCquantityPopupCancel: {
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: 20,
    borderColor: 'gray',
    marginVertical: setValue(10),
    marginHorizontal: setValue(3),
    paddingHorizontal: setValue(3),
    paddingVertical: setValue(5),
  },
  buttonTextVCQuantityPopup: {
    marginHorizontal: setXAxisValue(20),
    color: Colors.theme_color,
    //
    fontSize: setXAxisValue(16),
  },
  buttonTextVCQuantityPopupCancel: {
    marginHorizontal: setXAxisValue(20),
    color: 'gray',
    //
    fontSize: setXAxisValue(16),
  },
  updateQuantityIconContainer: {
    padding: setValue(10),
  },
  updatePayIconContainer: {
    marginLeft: setXAxisValue(10),
  },
  vcItemQuantityText: {
    //
    flex: 1,
  },
  confirmButton: {
    marginVertical: setYAxisValue(15),
    marginHorizontal: setXAxisValue(15),
  },
  redeemText: {
    //
    color: 'gray',
  },
  redeemList: {
    flexGrow: 0,
    marginTop: 15,
  },
  itemQuantityContainer: {
    marginHorizontal: 10,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemVCUsed: {
    fontSize: 15,
  },
  itemVCLeft: {
    fontSize: 12,
    color: 'gray',
  },
  overlay: {
    flex: 1,
    backgroundColor: Colors.overlay,
    alignItems: 'center',
    justifyContent: 'center',
  },
  overlayExceedAmount: {
    opacity: 0.95,
    flex: 1,
    backgroundColor: Colors.overlay,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 100,
    flexWrap: 'wrap',
  },
  informationContainer: {
    ...shadow,
    justifyContent: 'center',
    alignSelf: 'center',
    borderRadius: 5,
    width: '85%',
    marginBottom: setYAxisValue(40),
    padding: setValue(2),
  },
  information: {
    backgroundColor: Colors.theme_color,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: setYAxisValue(10),
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  informationText: {
    fontSize: setXAxisValue(14),
    color: Colors.white,
    // fontFamily: ''
  },
  paymentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: setXAxisValue(10),
    paddingVertical: setYAxisValue(5),
  },
  paymentText: {
    fontSize: setXAxisValue(12),
    textAlign: 'center',
    color: Colors.black40,
    lineHeight: setYAxisValue(20),
    paddingHorizontal: setXAxisValue(20),
  },
  paymentImage: {
    height: setXAxisValue(40),
    width: setXAxisValue(40),
  },
  flatList: {
    marginVertical: setXAxisValue(10),
  },
  webViewPaymentContainer: {
    ...shadow,
    borderRadius: 5,
    width: '90%',
    height: '80%',
    padding: setValue(2),
  },
  payFromWalletContainer: {
    marginVertical: setYAxisValue(15),
    backgroundColor: Colors.white,
    borderRadius: setValue(5),
    flexDirection: 'row',
    padding: setValue(15),
    alignSelf: 'baseline',
  },
  payFromWalletItem: {
    flex: 0.5,
    backgroundColor: Colors.white_gray,
    borderRadius: setValue(5),
    padding: setValue(10),
  },
  payFromWalletItemLabel: {
    fontSize: setXAxisValue(12),
  },
  payFromWalletItemValue: {
    // fontFamily: ''
  },
  payFromWalletItemImage: {
    width: 30,
    height: 32,
    marginBottom: setYAxisValue(10),
    resizeMode: 'contain',
  },
  payFromWalletItemInput: {
    borderRadius: setValue(5),
    borderColor: 'gray',
    borderWidth: 1,
    padding: setValue(3),
    fontSize: 12,
    flex: 1,
  },
  textInput: {
    fontSize: setXAxisValue(20),
    color: Colors.black40,
    paddingRight: setXAxisValue(10),
  },
  info: {
    fontSize: setValue(16),
    marginHorizontal: setXAxisValue(10),
    marginVertical: setYAxisValue(10),
  },
  infoItemStyle: {
    flexDirection: 'row',
    marginHorizontal: setXAxisValue(10),
    marginVertical: setYAxisValue(10),
    justifyContent: 'space-between',
  },
  orderDetailContainer: {
    marginHorizontal: setXAxisValue(10),
    marginVertical: setYAxisValue(10),
  },
  orderDetailItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    // alignItems: 'center',
    marginVertical: setYAxisValue(5),
  },
  infoItemText1: {
    marginRight: 5,
    width: '35%',
  },
  infoList: {
    backgroundColor: 'white',
    borderRadius: 5,
  },
  actionButtonContainer: {
    marginVertical: setYAxisValue(0),
    top: -10,
    marginHorizontal: setXAxisValue(5),
    justifyContent: 'space-between',
    flex: 1,
  },
  actionButton: {
    paddingVertical: setYAxisValue(10),
    marginTop: 10,
    paddingHorizontal: setXAxisValue(15),
    justifyContent: 'center',
    alignItems: 'center',
    // borderWidth:2
  },
  deniedButton: {
    flex: 1,
    ...shadow,
  },
  acceptButton: {
    flex: 1,
    ...shadow,
    backgroundColor: Colors.theme_color,
  },
  declineBtn: {
    ...shadow,
    backgroundColor: Colors.theme_color,
  },
  infoText: {
    borderWidth: 1.5,
    borderColor: Colors.white_gray,
    borderRadius: 5,
    padding: 10,
    minHeight: 100,
    textAlignVertical: 'top',
  },
  modalTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    padding: 5,
    fontSize: setValue(18),
  },
  modalContainer: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    flex: 1,
    justifyContent: 'center',
  },
  modalContent: {
    padding: 20,
    margin: 15,
    alignContent: 'center',
    borderRadius: 5,
    alignSelf: 'center',
    maxWidth: 360,
    width: '100%',
    ...shadow,
  },
  mainMapContainer: {
    backgroundColor: Colors.white,
    marginTop: setYAxisValue(10),
  },
  map: {
    height: screenHeight / 2,
  },
  orderStatusStepConatiner: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 400,
    alignSelf: 'center',
    marginTop: Platform.OS == 'web' ? 15 : 0,
  },
  LocationRequestButton: {
    ...shadow,
    backgroundColor: Colors.theme_color,
    borderRadius: 5,
    marginTop: setValue(20),
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  separator: {
    height: setXAxisValue(2),
    width: '90%',
    position: 'absolute',
    backgroundColor: '#4D4D4D',
    top: setXAxisValue(25),
    left: setXAxisValue(5),
  },
  doneStep: {
    width: setXAxisValue(50),
    height: setXAxisValue(50),
    backgroundColor: '#8CC63F',
    borderRadius: setXAxisValue(10),
    justifyContent: 'center',
    alignItems: 'center',
  },
  undoneStep: {
    width: setXAxisValue(50),
    height: setXAxisValue(50),
    backgroundColor: '#4D4D4D',
    borderRadius: setXAxisValue(10),
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepIconForPaid: {
    width: setXAxisValue(40),
    height: setXAxisValue(40),
  },
  textStep:
    Platform.OS == 'web'
      ? {
          textAlign: 'center',
          color: Colors.black60,
          fontSize: setXAxisValue(10),
          marginTop: setYAxisValue(10),
        }
      : {
          textAlign: 'center',
          color: Colors.black60,
          fontSize: setXAxisValue(10),
          marginTop: setYAxisValue(10),
        },
  stepIcon: {
    width: setXAxisValue(24),
    height: setXAxisValue(24),
  },
  stepIconForPaid: {
    width: setXAxisValue(40),
    height: setXAxisValue(40),
  },
  selectPrinterBtn: {
    backgroundColor: Colors.white,
    borderRadius: 5,
    marginVertical: 10,
    ...shadow,
  },
  selectPrinterBtnText: {
    textAlign: 'center',
    padding: 10,
    fontFamily: 'Roboto-Bold',
  },
  orderStatusHistoryContainer: {
    backgroundColor: Colors.white,
    borderRadius: 5,
    elevation: 5,
    marginTop: 10,
    marginHorizontal: 5,
    shadowOffset: {
      height: 4,
      width: 0,
    },
    shadowColor: Colors.black60,
    shadowRadius: 5,
    shadowOpacity: 0.4,
  },
  orderHistoryTitle: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginTop: 5,
  },
  basicBorder: {
    borderWidth: 0.5,
    marginVertical: 3,
    borderColor: Colors.border,
  },
  orderStatusHistoryItem: {
    flex: 1,
    paddingHorizontal: 10,
    paddingVertical: 5,
    fontSize: 15,
  },
  orderStatus: {fontFamily: 'Roboto-Bold'},
  paymentBtn: {
    marginHorizontal: 5,
    backgroundColor: Colors.white,
    borderRadius: 5,
    ...shadow,
  },
  paymentBtnText: {
    textAlign: 'center',
    padding: 15,
    fontFamily: 'Roboto-Bold',
  },
});
