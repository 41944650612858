import * as Location from 'expo-location';

export const getLocation = async () => {
  const { status } = await  Location.requestForegroundPermissionsAsync();;

  let currentLocation = {};
  if (status === 'granted') {
    currentLocation = await Location.getCurrentPositionAsync({});
  } else {
    currentLocation = {
      coords: {
        latitude: 3.1666,
        longitude: 101.6532
      }
    };
  }

  const location = {
    latitude: currentLocation.coords.latitude,
    longitude: currentLocation.coords.longitude
  };

  return location;
};

export const getReverseGeocode = async () => {
  const { status } = await  Location.requestForegroundPermissionsAsync();

  let currentLocation = {};
  let location = {};
  if (status !== 'granted' || typeof status !== 'undefined') {
    currentLocation = await Location.getCurrentPositionAsync({
      enableHighAccuracy: true
    });
    location = await Location.reverseGeocodeAsync({
      latitude: currentLocation.coords.latitude,
      longitude: currentLocation.coords.longitude
    });
  } else {
    currentLocation = {
      coords: {
        latitude: 3.1666,
        longitude: 101.6532
      }
    };
    location = {
      isoCountryCode: ['MY']
    };
  }

  return location[0].isoCountryCode.toLowerCase();
};
