import React, { Component } from 'react';
import { Keyboard, KeyboardAvoidingView, Platform, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AppText, LongButton, MainContainer, MainHeader } from '../../../components';
import { Colors } from '../../../constants';
import { strings } from '../../../i18n';
import PhoneInput from '../../../lib/react-native-phone-input';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import { goBack, isPhoneNumberValid, setValue, setYAxisValue } from '../../../utils';
import styles from './styles';

class UpdatePhoneScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: ''
    };
  }

  componentDidMount() {
    if (this.phoneInput) {
      this.setState({
        phone: `+${this.phoneInput.getCountryCode()}`
      });
    }
  }

  onNext = () => {
    const { phone } = this.state;
    const { openAlert, navigation } = this.props;
    Keyboard.dismiss();
    if (!phone || !(phone && isPhoneNumberValid(phone))) {
      return openAlert(strings('registerScreen.validate.phone'));
    }
  };

  render() {
    const { navigation, countryCode } = this.props;
    const { phone } = this.state;

    return (
      <View style={styles.container}>
        <MainHeader title={strings('updatePhoneScreen.updatePhone')} leftIcon="back" leftPress={() => goBack(navigation)} />
        <MainContainer useView>
          <KeyboardAvoidingView style={styles.container} behavior={Platform.OS === 'ios' ? 'padding' : 'height'} keyboardVerticalOffset={setYAxisValue(60)}>
            <View>
              <AppText style={styles.title}>{strings('updatePhoneScreen.enterPhone')}</AppText>
              <View>
                <View style={styles.textInputContainer}>
                  <PhoneInput
                    ref={input => {
                      this.phoneInput = input;
                    }}
                    initialCountry={countryCode}
                    style={styles.textInput}
                    value={phone}
                    onSelectCountry={() => {
                      this.setState({
                        phone: `+${this.phoneInput.getCountryCode()}`
                      });
                    }}
                    inputStyle={{
                      height: setValue(50),
                      justifyContent: 'center'
                    }}
                    textProps={{
                      underlineColorAndroid: 'transparent',
                      autoCapitalize: 'none',
                      autoCorrect: false,
                      placeholder: strings('registerScreen.placeHolder.phone'),
                      placeholderTextColor: Colors.color30,
                      keyboardType: 'phone-pad',
                      returnKeyType: 'done',
                      value: phone,
                      onChangeText: text => this.setState({ phone: text })
                    }}
                  />
                </View>
              </View>
            </View>
            <LongButton text={strings('updatePhoneScreen.next')} style={styles.button} onPress={this.onNext} />
          </KeyboardAvoidingView>
        </MainContainer>
      </View>
    );
  }
}

export default connect(
  state => ({
    countryCode: state.common.countryCode
  }),
  dispatch =>
    bindActionCreators(
      {
        openAlert: content => CommonActions.setAlert({ visible: true, content })
      },
      dispatch
    )
)(UpdatePhoneScreen);
