import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {
  FlatList,
  TouchableOpacity,
  View,
  Text,
  Image,
  Platform,
} from 'react-native';
import {
  AppText,
  MainHeader,
  LongButton,
  Icon,
  MainContainer,
} from '../../../components';
import {goBack, setValue} from '../../../utils';
import {useDispatch, useSelector} from 'react-redux';
import Icon4 from '@expo/vector-icons/FontAwesome';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {useIsFocused} from '@react-navigation/native';

import styles from './styles';
import {strings} from '../../../i18n';

const BlogCategoryList = (props) => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const config = useSelector((state) => state?.config);

  const [blogCategoryList, setBlogCategoryList] = useState([]);

  const getBlogCategoryList = () => {
    dispatch(CommonActions.setLoading(true));
    axios
      .get('/blog/category/list/' + config?.businessId)
      .then((response) => {
        // console.log('response of category list===', response);
        setBlogCategoryList(response?.data);
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        // dispatch(CommonActions.setLoading(true));
        console.log('error in response of category list===', error);
      });
  };

  const deleteBlogCategory = async (id) => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios.delete('/blog/category/delete/' + id).then((response) => {
        console.log('response of delete category----- ', response);
        dispatch(CommonActions.setLoading(false));
        props.navigation.navigate('blog-category-list');
        getBlogCategoryList();
      });
    } catch (error) {
      console.log('error -------------->', error?.response);
      dispatch(openAlert(error?.response?.message));
      dispatch(CommonActions.setLoading(false));
    }
  };

  useEffect(() => {
    isFocused && getBlogCategoryList();
  }, [isFocused]);

  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('blogCategoryList.title')}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer useView>
        {blogCategoryList != 0 && (
          <FlatList
            data={blogCategoryList}
            keyExtractor={(item, index) => index}
            renderItem={({item, index}) => {
              return (
                <>
                  <TouchableOpacity style={styles.itemContainer} key={item?.id}>
                    <View style={styles.categoryContainer}>
                      <Image
                        style={styles.image}
                        source={
                          item?.image
                            ? {uri: item?.image}
                            : require('../../../../assets/images/General/no-img-found.jpg')
                        }
                        resizeMode="cover"
                      />
                    </View>
                    <View style={styles.categoryDetailContainer}>
                      <AppText style={styles.categoryName} numberOfLines={2}>
                        {item?.name}
                      </AppText>
                      <View style={styles.buttonContainer}>
                        <TouchableOpacity
                          onPress={() => {
                            props.navigation.navigate('edit-blog-category', {
                              item: item,
                            });
                          }}
                          style={styles.button}
                        >
                          <Icon name="edit" size={setValue(18)} color="#fff" />
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={styles.button}
                          onPress={() =>
                            dispatch(
                              CommonActions.setAlert({
                                visible: true,
                                content: strings('blogCategoryList.askDelete'),
                                rightButton: strings(
                                  'addNewBlogCategory.confirm',
                                ),
                                rightPress: async () => {
                                  deleteBlogCategory(item?.id);
                                },
                                leftButton: strings(
                                  'addNewBlogCategory.cancel',
                                ),
                                leftPress: null,
                              }),
                            )
                          }
                        >
                          <Icon4
                            name="trash"
                            size={setValue(18)}
                            color="#fff"
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                  </TouchableOpacity>
                </>
              );
            }}
          />
        )}
        {Platform.OS == 'web' && (
          <LongButton
            onPress={() => props.navigation.navigate('edit-blog-category')}
            loading={false}
            text={strings('blogCategoryList.addCategory')}
            style={{
              marginTop: setValue(20),
              marginBottom: setValue(45),
            }}
          />
        )}
      </MainContainer>
      {Platform.OS != 'web' && (
        <LongButton
          text={strings('blogCategoryList.addCategory')}
          style={{marginTop: 10, borderRadius: 0}}
          onPress={() => props.navigation.navigate('edit-blog-category')}
        />
      )}
    </View>
  );
};
export default BlogCategoryList;
