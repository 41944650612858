import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {
  View,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
  Platform,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {
  AppConfigCheckbox,
  AppText,
  DropDownInput,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../../components';
import {Colors} from '../../../constants';
import {strings} from '../../../i18n';
import {goBack, setValue} from '../../../utils';
import styles from './styles';
import ConfigActions from '../../../redux/reduxsauce/configRedux';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import Constants from 'expo-constants';

const PaymentSetting = (props) => {
  const config = useSelector((state) => state.config);

  let paymentMethods = [
    {label: 'Razorpay', value: 'razorpay'},
    {label: 'Cashfree', value: 'cashfree'},
    {label: 'Stripe', value: 'stripe'},
    {label: 'Paytm', value: 'paytm'},
    {label: 'UPI Gateway', value: 'upi'},
  ];

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [paymentConfigData, setPaymentConfigData] = useState({
    type: 'razorpay',
    key: '',
    secret_key: '',
    label: '',
    is_active: true,
  });

  // useEffect(() => {
  //   fetchBusinessDetail();
  // }, []);

  // const fetchBusinessDetail = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.get(
  //       `/business/details/${config?.businessId}`,
  //     );
  //     console.log('response payment detail', response);
  //     const {data} = response;
  //     setPaymentConfigData({
  //       type: data?.payment_method?.type,
  //       key: data?.payment_method?.key,
  //       secret_key: data?.payment_method?.secret_key,
  //       label:data?.payment_method?.label,

  //     });
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.log('error', error);
  //     dispatch(
  //       CommonActions.setAlert({
  //         visible: true,
  //         content: error?.response?.message,
  //       }),
  //     );
  //   }
  // };

  const savePaymentData = async () => {
    console.log('paymentConfigData', paymentConfigData);
    setLoading(true);
    try {
      if (!paymentConfigData.key || paymentConfigData.key == '') {
        setLoading(false);
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: strings('paymentSetting.alert.key'),
          }),
        );
        return;
      } else if (
        (!paymentConfigData.secret_key || paymentConfigData.secret_key == '') &&
        paymentConfigData?.type !== 'upi'
      ) {
        setLoading(false);
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: strings('paymentSetting.alert.secret'),
          }),
        );
        return;
      } else if (!paymentConfigData.label || paymentConfigData.label == '') {
        setLoading(false);
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: 'Payment method label is required',
          }),
        );
        return;
      } else {
        console.log('paymentConfigData', paymentConfigData);
        const response = await axios.post(`/business/payment-methods/create`, {
          type: paymentConfigData?.type,
          payment_key: paymentConfigData?.key,
          payment_secret_key: paymentConfigData?.secret_key,
          label: paymentConfigData?.label,
          is_active: paymentConfigData?.is_active,
        });

        console.log('response-->', response);
        setLoading(false);
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: strings('paymentSetting.alert.success'),
          }),
        );
        goBack(props.navigation);
      }
    } catch (error) {
      setLoading(false);
      console.log('error', error?.response);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message || 'Something went wrong :(',
        }),
      );
    }
  };

  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('settingScreen.paymentSetting')}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      {loading ? (
        <View style={{flex: 1, justifyContent: 'center'}}>
          <ActivityIndicator color={Colors.theme_color} size={'small'} />
        </View>
      ) : (
        <>
          <MainContainer>
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>
                {strings('paymentSetting.label.payMethod')}
              </AppText>
              <DropDownInput
                inputStyle={{
                  marginVertical: 10,
                  marginHorizontal: 10,
                }}
                data={paymentMethods}
                value={paymentConfigData.type}
                onChangeText={(text) => {
                  setPaymentConfigData({
                    ...paymentConfigData,
                    type: text.toLowerCase(),
                    key: '',
                    secret_key: '',
                  });
                }}
              />
            </View>

            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel} required>
                {'Label'}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCorrect={false}
                placeholder={'Enter method label'}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                value={paymentConfigData?.label}
                onChangeText={(text) =>
                  setPaymentConfigData({...paymentConfigData, label: text})
                }
                style={
                  Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
                }
              />
            </View>

            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel} required>
                {strings('paymentSetting.label.key')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCorrect={false}
                placeholder={strings('paymentSetting.placeholder.key')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                value={paymentConfigData?.key}
                onChangeText={(text) =>
                  setPaymentConfigData({...paymentConfigData, key: text})
                }
                style={
                  Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
                }
              />
            </View>

            {paymentConfigData.type !== 'upi' && (
              <View style={styles.textInputContainer}>
                <AppText style={styles.inputLabel} required>
                  {strings('paymentSetting.label.secretkey')}
                </AppText>
                <TextInput
                  underlineColorAndroid="transparent"
                  autoCorrect={false}
                  placeholder={strings('paymentSetting.placeholder.secretkey')}
                  placeholderTextColor={Colors.color30}
                  returnKeyType="done"
                  value={paymentConfigData?.secret_key}
                  onChangeText={(text) =>
                    setPaymentConfigData({
                      ...paymentConfigData,
                      secret_key: text,
                    })
                  }
                  style={
                    Platform.OS == 'web'
                      ? styles.textInputWeb
                      : styles.textInput
                  }
                />
              </View>
            )}

            <AppConfigCheckbox
              desc={'Active Now'}
              checked={paymentConfigData.is_active}
              onChangeCheck={(checked) => {
                setPaymentConfigData({
                  ...paymentConfigData,
                  is_active: checked,
                });
              }}
              style={{alignItems: 'center'}}
              descStyle={{
                color: Colors.black,
                fontSize: setValue(15),
              }}
            />

            {Platform.OS == 'web' && (
              <LongButton
                disabled={loading}
                text={strings('paymentSetting.button.save')}
                style={{
                  width: '100%',
                  fontFamily: 'Roboto-Bold',
                  borderRadius: 5,
                  marginBottom: setValue(15),
                }}
                onPress={savePaymentData}
              />
            )}
          </MainContainer>
          {Platform.OS != 'web' && (
            <TouchableOpacity
              disabled={loading}
              style={styles.saveBtnContainer}
              onPress={savePaymentData}
            >
              <AppText style={styles.saveBtnText}>
                {strings('paymentSetting.button.save')}
              </AppText>
            </TouchableOpacity>
          )}
        </>
      )}
    </View>
  );
};

export default PaymentSetting;
