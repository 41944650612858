import {parsePhoneNumberFromString} from 'libphonenumber-js';
import moment from 'moment';

export const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

export const isDateAboveAge = (date, age) => {
  const currentAge = moment().year() - moment(date, 'YYYY/M/D').year();
  return currentAge >= age;
};

export const isPhoneNumberValid = (number) => {
  const phoneNumber = parsePhoneNumberFromString(number);
  if (phoneNumber) return phoneNumber.isValid();
  return false;
};

export const isDateValidAbove = (date) => {
  return moment(date).diff(moment().format('YYYY-MM-DD')) <= 0;
};

export const isDateValidBelow = (date) => {
  return moment(date).diff(moment().format('YYYY-MM-DD')) >= 0;
};

export const isDateValid = (date) =>
  date?.split('-')[0] &&
  date?.split('-')[1] &&
  date?.split('-')[2] &&
  date?.split('-')[0].length >= 4 &&
  date?.split('-')[1].length >= 1 &&
  date?.split('-')[2].length >= 1 &&
  moment(date, 'YYYY-M-D').isValid();
