import {StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 5,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  imageLogo: {
    width: (setValue(200) * 4) / 4,
    height: (setValue(200) * 2) / 4,
    borderRadius: setValue(10),
    marginBottom: setValue(20),
    alignSelf: 'center',
  },
  textInputContainer: {
    backgroundColor: Colors.white,
    ...shadow,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
  },
  inputLabel: {
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    fontFamily: 'Roboto-Bold',
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  textInput: {
    height: setValue(50),
    padding: 0,
    paddingHorizontal: setXAxisValue(10),
  },
  textInputWeb: {
    height: setValue(50),
    padding: setValue(5),
    paddingHorizontal: setXAxisValue(10),
  },
  orderDetailCard: {
    ...shadow,
    backgroundColor: Colors.white,
    padding: setValue(10),
    borderRadius: 5,
  },
  detailRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: setValue(5),
  },
  downloadButton: {
    backgroundColor: Colors.theme_color,
    borderRadius: setValue(5),
    height: 45,
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnText: {
    fontFamily: 'Roboto-Bold',
    color: Colors.white,
  },
  cardHeader: {
    fontSize: setValue(16),
    marginBottom: setYAxisValue(10),
    borderBottomWidth: 1,
    marginHorizontal: setValue(-10),
    padding: setValue(10),
    paddingTop: 5,
  },
  historyHeader: {
    fontFamily: 'Roboto-Bold',
    fontSize: setValue(16),
    marginBottom: setValue(8),
  },
  loginButton: {
    backgroundColor: Colors.white,
    padding: 10,
    paddingVertical: 15,
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    ...shadow,
  },
  inputRightSide: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    right: setValue(10),
    alignSelf: 'center',
    top: setValue(15),
    elevation: 10,
  },

  // modal styles
  overlay: {
    backgroundColor: Colors.overlay,
    flex: 1,
    justifyContent: 'center',
  },
  mainContainer: {
    backgroundColor: Colors.white,
    padding: setValue(10),
    marginHorizontal: setValue(15),
    borderRadius: 5,
    maxWidth: setValue(360),
    width: '100%',

    alignSelf: 'center',
  },
});
