import {StyleSheet} from 'react-native';
import {Colors} from '../../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 5,
};
export default StyleSheet.create({
  container: {
    flex: 1,
  },
  filterByDateContainer: {
    borderRadius: 5,
    backgroundColor: Colors.white,
    padding: setValue(15),
    ...shadow,
    marginBottom: setYAxisValue(15),
  },
  calendarContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputRightSide: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    right: setValue(15),
    alignSelf: 'center',
    top: setValue(12),
    ...shadow,
  },
  date:{
    paddingTop:5
  },
});
