import {Platform, StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {screenWidth, setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 5,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  webContainer: {
    overflow: 'visible',
    height: '100vh',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  TabBarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    marginBottom: setValue(10),
    backgroundColor: Colors.white,
    borderRadius: 5,
    ...shadow,
  },
  TabBarHeader: {
    fontWeight: 'bold',
    color: Colors.black20,
  },
  TabBarDescText: {
    color: Colors.black40,
    fontSize: 13,
  },
  BackgroundPickerContainer: {
    padding: 10,
    marginBottom: setValue(10),
    backgroundColor: Colors.white,
    borderRadius: 5,
    ...shadow,
  },
  BackgroundPicker:
    Platform.OS == 'web'
      ? {
          height: 35,
          paddingHorizontal: 0,
          marginVertical: 0,
          marginHorizontal: -10,
          borderWidth: 2,
          borderColor: '#000',
        }
      : {
          // height: 30,
          paddingHorizontal: 0,
          marginHorizontal: -10,
          borderColor: '#000',
        },

  textInputContainer: {
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 5,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
  },
  inputLabel: {
    fontFamily: 'Roboto-Bold',
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(15),
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  textInput: {
    height: setValue(40),
    color: Colors.black20,
    paddingHorizontal: setXAxisValue(10),
  },
  textInputWeb: {
    height: setValue(50),
    padding: setValue(10),
    paddingHorizontal: setXAxisValue(10),
  },
  inputContainer: {
    // marginTop: setYAxisValue(10),
  },
  backgroundLayoutCard: {
    flex: 1,
    backgroundColor: Colors.white,
    elevation: 5,
    shadowColor: Colors.black60,
    shadowOpacity: 0.3,
    borderRadius: 5,
    shadowOffset: {
      height: 4,
      width: 0,
    },
  },
  settingView: {
    ...shadow,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: setYAxisValue(20),
    paddingHorizontal: setXAxisValue(10),
    borderRadius: 8,
    alignItems: 'center',
    marginBottom: setYAxisValue(10),
    backgroundColor: Colors.white,
  },
  title: {
    fontSize: setXAxisValue(16),
    color: Colors.black60,
    fontWeight: '600',
  },
});
