import React from 'react';
import {Image, Platform, ScrollView, View} from 'react-native';

import {Colors, Images} from '../../../constants';
import styles from './styles';
import {LinearGradient} from 'expo-linear-gradient';

export default props => {
  const MainView = props.useView || props.useViewOval ? View : ScrollView;
  const showFullWidth = props.showFullWidth ? true : false;
  return (
    <LinearGradient
      colors={[Colors.theme_color, Colors.white]}
      style={{flex: 1}}
      start={[0,0]}
      end={[0,0.7]}
    >
      <MainView
        style={[
          styles.container,
          // props.useView && styles.containerUseView,
          // props.useViewOval && {zIndex: -999},
        ]}
        {...props}
        keyboardShouldPersistTaps="always"
        showsVerticalScrollIndicator={false}
        ref={props.viewRef}
      >
        {Platform.OS !== 'web' && (
          <View
            style={[
              props.useView && styles.viewOval,
              props.isWallet
                ? styles.topOvalWallet
                : props.isCheckoutOrdering
                ? styles.checkoutOrderingOvalWallet
                : styles.topOval,
            ]}
          />
        )}
        {props.showLogo && (
          <Image style={styles.logoImage} source={Images.logo} />
        )}
        {/* <View style={{alignItems:"center"}}> */}
        <View
          style={[
            styles.childrenContainer,
            props.useView && {paddingBottom: 0},
            showFullWidth && {maxWidth: 1024},
          ]}
        >
          {props.children}
        </View>
        {/* </View> */}
      </MainView>
    </LinearGradient>
  );
};
