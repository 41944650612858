import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {screenWidth, setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
  backgroundColor: Colors.white,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  webContainer: {
    overflow: 'visible',
    height: '100vh',
  },
  TabBarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    marginVertical: setValue(15),
    backgroundColor: Colors.white,
    borderRadius: 5,
    ...shadow,
  },
  TabBarHeader: {
    fontFamily: 'Roboto-Bold',
    fontSize: 15,
  },
  TabBarDescText: {
    color: Colors.black40,
    fontSize: 13,
  },
  imageContainer: {
    ...shadow,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
    flex: 1,
  },
  text: {
    marginLeft: setYAxisValue(15),
    fontSize: setXAxisValue(15),
    paddingTop: 10,
    fontFamily: 'Roboto-Bold',
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  settingView: {
    ...shadow,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: setYAxisValue(20),
    paddingHorizontal: setXAxisValue(10),
    borderRadius: 8,
    alignItems: 'center',
    marginBottom: setYAxisValue(10),
    backgroundColor: Colors.white,
  },
});
