import React, {useState, useEffect} from 'react';
import {View, FlatList, Platform, TouchableWithoutFeedback} from 'react-native';
import {
  MainHeader,
  MainContainer,
  LongButton,
  AppText,
} from '../../../components';
import {goBack, setValue} from '../../../utils';
import styles from './styles';
import {strings} from '../../../i18n';
import {SwipeRow} from '../../../components';
import NoRecordFound from '../../../components/NoRecordFound';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {useIsFocused} from '@react-navigation/native';

export default function FloorList(props) {
  const [floorList, setFloorList] = useState([]);

  const config = useSelector((state) => state?.config);
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const getFloorList = async () => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios
        .get('/restaurant/floor/list/' + config.businessId)
        .then((response) => {
          console.log('response --> FloorList', response);
          setFloorList(response?.data);
          dispatch(CommonActions.setLoading(false));
        });
    } catch (error) {
      dispatch(CommonActions.setLoading(false));
      console.log('message FloorList', error?.response?.message);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };

  const deleteFloor = async (id) => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios.delete('/restaurant/floor/delete/' + id).then((response) => {
        getFloorList();
        console.log('response --> ', response);
      });
    } catch (error) {
      dispatch(CommonActions.setLoading(false));
      console.log('message', error?.response?.message);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };

  useEffect(() => {
    isFocused && getFloorList();
  }, [isFocused]);

  return (
    <View style={Platform.OS == 'web' ? styles.webContainer : styles.container}>
      <MainHeader
        title={strings('floorList.floorList')}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer useView={Platform.OS === 'web' ? false : true}>
        {floorList ? (
          <FlatList
            data={floorList}
            showsVerticalScrollIndicator={false}
            keyExtractor={(item, index) => index}
            renderItem={({item, index}) => (
              <SwipeRow
                key={index}
                editable={true}
                leftOpenValue={70}
                rightOpenValue={-100}
                onPressEdit={() =>
                  props.navigation.navigate('edit-floor', {
                    item: item,
                    edit: true,
                  })
                }
                onPressDelete={() =>
                  dispatch(
                    CommonActions.setAlert({
                      visible: true,
                      content: strings('floorList.askDelete'),
                      rightButton: strings('settingScreen.confirm'),
                      rightPress: () => {
                        deleteFloor(item?.id);
                      },
                      leftButton: strings('settingScreen.cancel'),
                      leftPress: null,
                    }),
                  )
                }
              >
                <FloorItemContainer item={item} />
              </SwipeRow>
            )}
          />
        ) : (
          <NoRecordFound
            title={strings('floorList.noRecord.title')}
            message={strings('floorList.noRecord.message')}
          />
        )}
        {/* if platform is web then edit address button with flat list  */}
        {Platform.OS == 'web' && (
          <LongButton
            text={strings('floorList.addNewFloor')}
            style={{marginTop: setValue(10), marginBottom: setValue(45)}}
            onPress={() =>
              props.navigation.navigate('edit-floor', {item: floorList})
            }
          />
        )}
      </MainContainer>

      {/* if Platform os other then web then button is end of flex */}
      {Platform.OS != 'web' && (
        <LongButton
          text={strings('floorList.addNewFloor')}
          style={{
            width: '100%',
            borderRadius: 0,
          }}
          onPress={() =>
            props.navigation.navigate('edit-floor', {item: floorList})
          }
        />
      )}
    </View>
  );
}

const FloorItemContainer = ({item}) => {
  const [aspectRatio, setAspectRatio] = useState(1 / 1);
  return (
    <TouchableWithoutFeedback >
      <View style={styles.floorItem}>
        {item?.name !== null && item?.name !== '' && (
          <AppText style={styles.floorName} numberOfLines={2}>
            {item?.name}
          </AppText>
        )}
        {item?.description !== null && item?.description !== '' && (
          <AppText style={styles.floorDesc} numberOfLines={3}>
            {item?.description}
          </AppText>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};
