import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  overlay: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.9)',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 10,
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    width: '100%',
    borderRadius: setValue(15),
    borderTopLeftRadius: setXAxisValue(15),
    borderTopRightRadius: setXAxisValue(15),
    borderWidth: setXAxisValue(2),
    margin: 10,
    borderColor: Colors.white_cream,
    padding: setValue(10),
  },
  title: {
    fontSize: setXAxisValue(22),
    textAlign: 'center',
    fontFamily: 'Roboto-Bold',
    lineHeight: setXAxisValue(24),
    marginBottom: setYAxisValue(10),
    color: Colors.black60,
  },
  description: {
    fontSize: setXAxisValue(15),
    lineHeight: setXAxisValue(20),
    marginTop: setYAxisValue(10),
    marginBottom: setYAxisValue(15),
    color: Colors.black20,
    textAlign: 'center',
    fontFamily: 'Roboto-Regular',
  },
  image: {
    width: setXAxisValue(130),
    height: setXAxisValue(130),
    marginBottom: setValue(15),
    borderRadius: setValue(5),
  },
  UpdateButton: {
    marginTop: setYAxisValue(10),
    // marginBottom: setYAxisValue(30),
    paddingHorizontal: setXAxisValue(20),
    paddingVertical: setXAxisValue(10),
    backgroundColor: Colors.theme_color,
    borderRadius: 5,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 3,
  },
  updateText: {
    color: Colors.white,
    fontFamily: 'Roboto-Bold',
    fontSize: setValue(16),
  },
});
