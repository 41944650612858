import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
  borderRadius: 5,
  backgroundColor: Colors.white,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  itemContainer: {
    flex: 1,
    padding: setValue(10),
    marginTop: setYAxisValue(10),
    ...shadow,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: setValue(5),
  },
  orderUId: {
    fontSize: setXAxisValue(15),
  },
  orderPrice: {
    fontFamily: 'Roboto-Bold',
    fontSize: setXAxisValue(18),
    color: Colors.black60,
  },
  bottomContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
