import {Platform, StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {screenWidth, setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 5,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  inputLabel: {
    fontSize: setXAxisValue(12),
    fontFamily: 'Roboto-Bold',
    color: Colors.black20,
  },
  textInput: {
    paddingVertical: 8,
  },
  contactDetailContainer: {
    paddingVertical: setValue(10),
    borderRadius: 5,
    ...shadow,
    paddingHorizontal: setValue(10),
    marginBottom: setYAxisValue(15),
    backgroundColor: Colors.white,
  },
  textInputContainer: {
    paddingVertical: setValue(8),
    borderRadius: 5,
    ...shadow,
    paddingHorizontal: setValue(10),
    marginBottom: setYAxisValue(15),
    backgroundColor: Colors.white,
  },
  contactUsTitleText: {
    color: Colors.black,
    fontFamily: 'Roboto-Bold',
  },
  contactDetail: {
    flexDirection: 'row',
    padding: 5,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contactUsText: {
    color: Colors.black40,
    marginVertical: 5,
  },
  contactUsTitle: {
    textAlign: 'center',
    fontSize: 20,
    padding: 15,
    fontFamily: 'Roboto-Bold',
  },
});
