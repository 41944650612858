import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
  Platform,
  TouchableWithoutFeedback,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {
  AppText,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
  Modal,
  NoRecordFound,
  SelectProductVeriantsModal,
} from '../../../components';
import TopProductItem from '../../../components/TopProductItem';
import CommonAction from '../../../redux/reduxsauce/commonRedux';
import {strings} from '../../../i18n';
import {goBack, screenWidth, setValue, setXAxisValue} from '../../../utils';
import styles from './styles';
import {Colors} from '../../../constants';

const SelectProductFromList = (props) => {
  const config = useSelector((state) => state?.config);
  const dispatch = useDispatch();
  const [productList, setProductList] = useState([]);
  const [selectedProductsList, setSelectedProductsList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [varientProduct, setVarientProduct] = useState(null);

  useEffect(() => {
    fetchProductList();
  }, []);

  useEffect(() => {
    fetchProductList();
  }, [searchText]);

  const fetchProductList = async () => {
    setLoading(true);
    try {
      let url = '/v2/product/list/' + config?.businessId;
      if (searchText) {
        url += '?search=' + searchText;
      }
      const response = await axios.get(url);
      setProductList(response.data);
      setLoading(false);
    } catch (error) {
      console.log('error', error.response);
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: error?.response?.message || 'Something went wrong :(',
        }),
      );
      setLoading(false);
    }
  };

  const onSaveProducts = () => {
    console.log('selectedProductsList', selectedProductsList);
    props?.route?.params?.selectedProducts({
      products: selectedProductsList,
    });
    goBack(props.navigation);
  };

  const onClearText = () => {
    setLoading(true);
    setSearchText('');
  };

  let TotalWidth =
    screenWidth > 1023 ? 992 : screenWidth - 2 * setXAxisValue(15) - 22;
  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('selectProductFromList.title')}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer
        useView
        showFullWidth={Platform.OS == 'web' ? true : false}
      >
        <SelectProductVeriantsModal
          isVisible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          item={varientProduct}
          addToProduct={(item) => {
            console.log('oooo', item);
            setSelectedProductsList([...selectedProductsList, item]);
            setIsModalVisible(false);
          }}
        />
        <View style={styles.searchBarContainer}>
          <TextInput
            underlineColorAndroid="transparent"
            placeholder={strings('ordering.placeOrderScreen.searchHint')}
            placeholderTextColor="gray"
            autoCorrect={false}
            style={styles.searchBarText}
            enablesReturnKeyAutomatically
            returnKeyType="search"
            onChangeText={(text) => {
              setSearchText(text);
            }}
            defaultValue={searchText}
          />
          <View style={styles.searchBarIcon}>
            {searchText.length === 0 ? (
              <Icon name="search" size={18} color="gray" />
            ) : (
              <TouchableOpacity onPress={onClearText}>
                <Icon name="cross" size={18} color="gray" />
              </TouchableOpacity>
            )}
          </View>
        </View>
        {loading ? (
          <View style={{justifyContent: 'center', flex: 1}}>
            <ActivityIndicator color={Colors.theme_color} size={'large'} />
          </View>
        ) : productList.length == 0 ? (
          <NoRecordFound
            title={strings('ProductListScreen.products')}
            message={strings('selectProductFromList.message')}
          />
        ) : (
          <View>
            <FlatList
              data={productList}
              style={{width: '100%', marginBottom: 100}}
              keyExtractor={(item, index) => index}
              showsVerticalScrollIndicator={false}
              contentContainerStyle={
                Platform.OS == 'web'
                  ? {
                      width: '100%',
                      alignSelf: 'flex-start',
                      marginHorizontal: -3,
                    }
                  : {width: '100%', paddingBottom: 20}
              }
              numColumns={screenWidth > 576 ? parseInt(TotalWidth / 180) : 2}
              renderItem={({item}) => (
                <TopProductItem
                  item={item}
                  grid={true}
                  isCheckBoxVisible={true}
                  setChecked={() => {
                    let array = [];
                    productList?.length &&
                      productList?.find((o) => {
                        if (o.id == item.id) {
                          o = {
                            ...o,
                            checked: !o.checked,
                          };
                          if (o.checked) {
                            let selectedProduct = [...selectedProductsList, o];
                            console.log('o-----', o);
                            if (o?.variants?.length > 0) {
                              setIsModalVisible(true);
                              setVarientProduct(o);
                            } else {
                              setSelectedProductsList(selectedProduct);
                            }
                          } else {
                            let selectedProduct = [...selectedProductsList];
                            selectedProduct =
                              selectedProduct?.length &&
                              selectedProduct?.filter(
                                (product) => product.id != o.id,
                              );
                            setSelectedProductsList(selectedProduct);
                          }
                        }
                        array = [...array, o];
                      });
                    setProductList(array);
                  }}
                />
              )}
            />
          </View>
        )}
        {Platform.OS == 'web' && (
          <LongButton
            text={strings('addNewAddressScreen.add')}
            style={{
              alignSelf: 'center',
              maxWidth: 'inherit',
              position: 'fixed',
              bottom: 10,
            }}
            onPress={onSaveProducts}
          />
        )}
      </MainContainer>

      {Platform.OS !== 'web' && (
        <LongButton
          text={strings('addNewAddressScreen.add')}
          style={{
            width: '100%',
            borderRadius: 0,
          }}
          onPress={onSaveProducts}
        />
      )}
    </View>
  );
};

export default SelectProductFromList;
