// import * as AppleAuthentication from 'expo-apple-authentication';
import Constants from 'expo-constants';
import React, {Component} from 'react';
import {
  BackHandler,
  Keyboard,
  Linking,
  Platform,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {MaterialCommunityIcons} from '@expo/vector-icons';
import {
  AppText,
  DropDownInput,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../components';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import PhoneInput from '../../lib/react-native-phone-input';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {emailPassWOrdLogin} from '../../redux/reduxsauceActions/authActions';
import {
  isPhoneNumberValid,
  setValue,
  isEmail,
  setYAxisValue,
} from '../../utils';
import styles from './styles';
import {FontAwesome5} from '@expo/vector-icons';
import {Helmet} from 'react-helmet';

class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSecure: true,
      phone: '+91',
      password: '',
      email: '',
      selectedRadio: 1,
      role: 'admin',
      businessId: '',
    };
  }
  // componentDidMount() {
  //   this.backHandler = BackHandler.addEventListener(
  //     'hardwareBackPress',
  //     () => true,
  //   );
  // }

  // componentWillUnmount() {
  //   this.backHandler.remove();
  // }
  onSignIn = () => {
    const {phone, password, email, selectedRadio, role, businessId} =
      this.state;
    const {openAlert, navigation} = this.props;

    Keyboard.dismiss();
    if (selectedRadio == 0) {
      if (!email || !(email && isEmail(email))) {
        return openAlert(strings('registerScreen.validate.email'));
      }
    } else {
      if (!phone || !(phone && isPhoneNumberValid(phone))) {
        return openAlert(strings('registerScreen.validate.phone'));
      }
    }
    if (!password || (password && password.length < 6)) {
      return openAlert(strings('registerScreen.validate.password'));
    }
    if (selectedRadio == 0) {
      return this.props.emailPassWOrdLogin(
        {
          username: email,
          password: password,
          type: role,
          businessId: businessId,
        },
        navigation,
      );
    } else {
      return this.props.emailPassWOrdLogin(
        {
          username: phone,
          password: password,
          type: role,
          businessId: businessId,
        },
        navigation,
      );
    }
    // return this.props.loginWithPassword(phone, password, fcm_token, navigation);
  };

  render() {
    const {navigation, countryCode} = this.props;
    const {isSecure, phone, password, email, selectedRadio, role, businessId} =
      this.state;
    return (
      <View style={styles.container}>
        <Helmet>
          <meta
            name="description"
            content="Login to Whitelabel app after signing up for a free trial and manage your workflows easily."
          />
        </Helmet>
        {/* <MainHeader title={''} /> */}
        <MainContainer showLogo>
          <View>
            <View style={styles.RadioGroup}>
              <TouchableOpacity
                style={
                  selectedRadio == 0
                    ? styles.selectedSelectionCard
                    : styles.selectionCard
                }
                onPress={() =>
                  this.setState({selectedRadio: 0, phone: '+91', password: ''})
                }
              >
                <MaterialCommunityIcons
                  name="email"
                  size={20}
                  color={selectedRadio == 0 ? Colors.white : Colors.black20}
                />
                <Text
                  style={
                    selectedRadio == 0
                      ? styles.selectedSelectionText
                      : styles.selectionText
                  }
                >
                  Email
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={
                  selectedRadio == 1
                    ? styles.selectedSelectionCard
                    : styles.selectionCard
                }
                onPress={() =>
                  this.setState({selectedRadio: 1, password: '', email: ''})
                }
              >
                <Icon
                  name="phone"
                  size={16}
                  color={selectedRadio == 1 ? Colors.white : Colors.black20}
                />
                <Text
                  style={
                    selectedRadio == 1
                      ? styles.selectedSelectionText
                      : styles.selectionText
                  }
                >
                  Phone
                </Text>
              </TouchableOpacity>
            </View>

            {/* <View style={[styles.RadioGroup, {marginTop: setYAxisValue(0)}]}>
              <TouchableOpacity
                style={
                  role == 'admin'
                    ? styles.selectedSelectionCard
                    : styles.selectionCard
                }
                onPress={() => this.setState({role: 'admin', businessId: null})}
              >
                <Text
                  style={
                    role == 'admin'
                      ? styles.selectedSelectionText
                      : styles.selectionText
                  }
                >
                  Admin
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={
                  role == 'manager'
                    ? styles.selectedSelectionCard
                    : styles.selectionCard
                }
                onPress={() =>
                  this.setState({role: 'manager', businessId: null})
                }
              >
                <Text
                  style={
                    role == 'manager'
                      ? styles.selectedSelectionText
                      : styles.selectionText
                  }
                >
                  Manager
                </Text>
              </TouchableOpacity>
            </View> */}
            <DropDownInput
              data={[
                {
                  label: 'Admin',
                  value: 'admin',
                },
                {
                  label: 'Manager',
                  value: 'manager',
                },
                {
                  label: 'Deliveryboy',
                  value: 'deliveryboy',
                },
              ]}
              value={this.state.role}
              inputStyle={styles.textInput}
              onChangeText={(text) => {
                this.setState({role: text, businessId: ''});
              }}
            />
            {selectedRadio == 1 ? (
              <PhoneInput
                ref={(input) => {
                  this.phoneInput = input;
                }}
                initialCountry={countryCode}
                style={styles.textInput}
                value={phone}
                onSelectCountry={() => {
                  this.setState({
                    phone: `+${this.phoneInput.getCountryCode()}`,
                  });
                }}
                inputStyle={{
                  height: setValue(50),
                  justifyContent: 'center',
                }}
                textProps={{
                  underlineColorAndroid: 'transparent',
                  autoCapitalize: 'none',
                  autoCorrect: false,
                  placeholder: strings('registerScreen.placeHolder.phone'),
                  placeholderTextColor: Colors.color30,
                  keyboardType: 'phone-pad',
                  returnKeyType: 'done',
                  value: phone,
                  onChangeText: (text) => this.setState({phone: text}),
                  onSubmitEditing: () => this.passwordInput?.focus(),
                }}
              />
            ) : (
              <View style={styles.textInputContainer}>
                <TextInput
                  ref={(input) => {
                    this.passwordInput = input;
                  }}
                  underlineColorAndroid="transparent"
                  autoCapitalize="none"
                  autoCorrect={false}
                  placeholder={strings('loginScreen.placeHolder.email')}
                  placeholderTextColor={Colors.color30}
                  secureTextEntry={false}
                  style={styles.textInput}
                  returnKeyType="done"
                  value={email}
                  onChangeText={(text) => this.setState({email: text})}
                />
                <TouchableOpacity style={styles.inputRightSide}>
                  <Icon name="email" size={20} color={Colors.color30} />
                </TouchableOpacity>
              </View>
            )}

            {role != 'admin' && (
              <View style={styles.textInputContainer}>
                <TextInput
                  underlineColorAndroid="transparent"
                  autoCapitalize="none"
                  autoCorrect={false}
                  keyboardType={'number-pad'}
                  placeholder={'Enter Your business ID'}
                  placeholderTextColor={Colors.color30}
                  style={styles.textInput}
                  returnKeyType="done"
                  value={businessId}
                  onChangeText={(text) => this.setState({businessId: text})}
                />
              </View>
            )}

            <View style={styles.textInputContainer}>
              <TextInput
                ref={(input) => {
                  this.passwordInput = input;
                }}
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={strings('loginScreen.placeHolder.password')}
                placeholderTextColor={Colors.color30}
                secureTextEntry={isSecure}
                style={styles.textInput}
                returnKeyType="done"
                value={password}
                onChangeText={(text) => this.setState({password: text})}
              />
              <TouchableOpacity
                onPress={() =>
                  this.setState((prev) => ({isSecure: !prev.isSecure}))
                }
                style={styles.inputRightSide}
              >
                <Icon
                  name={isSecure ? 'revealPwd' : 'revealPwd-no'}
                  size={20}
                  color={Colors.color30}
                />
              </TouchableOpacity>
            </View>
            <LongButton
              text={strings('loginScreen.login')}
              onPress={this.onSignIn}
            />
            <TouchableOpacity
              onPress={() => navigation.navigate('forgot-password')}
            >
              <AppText style={styles.forgetPwText}>
                {strings('loginScreen.forgotPassword')}
              </AppText>
            </TouchableOpacity>
            {/* {Platform.OS === 'web' ? (
              <FacebookLogin
                appId={Constants.manifest.facebookAppId.toString()}
                autoLoad={false}
                fields="name,email,picture,gender,picture.type(large)"
                callback={this.loginWithFbOnWeb}
                render={renderProps => <LongButton text={strings('loginScreen.loginViaFB')} style={styles.fbButton} onPress={renderProps.onClick} />}
              />
            ) : (
              <LongButton text={strings('loginScreen.loginViaFB')} style={styles.fbButton} onPress={this.loginWithFbOnApp} />
            )} */}
            {/* {Platform.OS === 'ios' && (
              <View style={{ alignItems: 'center', marginTop: 10 }}>
                <AppleAuthentication.AppleAuthenticationButton
                  buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
                  buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
                  cornerRadius={5}
                  style={styles.appleButton}
                  onPress={this.loginWithAppleId}
                />
              </View>
            )} */}
            <View style={styles.dividerContainer}>
              <View style={styles.divider} />
              <AppText style={styles.orText}>
                {strings('loginScreen.or')}
              </AppText>
              <View style={styles.divider} />
            </View>
            <LongButton
              text={strings('loginScreen.register')}
              onPress={() => {
                navigation.navigate('register');
              }}
            />

            {/* <LongButton
              text={'Merchant login'}
              style={{marginTop: 10}}
              onPress={() =>
                this.props.emailPassWOrdLogin('+919724720202', 'test1123')
              }
            /> */}
          </View>
        </MainContainer>
      </View>
    );
  }
}

export default connect(
  (state) => ({
    appConfig: state.config,
    countryCode: state.common.countryCode,
    isConnected: state.common.isConnected,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        // loginApple,
        // loginFB,
        openAlert: (content) =>
          CommonActions.setAlert({visible: true, content}),
        // loginWithPassword,
        emailPassWOrdLogin,
        setLoading: CommonActions.setLoading,
      },
      dispatch,
    ),
)(LoginScreen);
