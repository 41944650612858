import Constants from 'expo-constants';
import React, {Component} from 'react';
import {Image, Linking, Platform, TouchableOpacity, View} from 'react-native';

import {Colors, Images} from '../../constants';
import {strings} from '../../i18n';
import AppText from '../AppText';
import Icon from '../Icon';
import Modal from '../Modal';
import styles from './styles';
import {connect} from 'react-redux';

class ForceUpdate extends Component {
  onClose = () => {
    this.props.onClose && this.props.onClose();
  };

  render() {
    const {visible, config, item} = this.props;
    console.log('Constants', Constants.manifest?.android?.package);

    return (
      <Modal
        visible={visible}
        onClose={this.onClose}
        transparent
        onRequestClose={this.onClose}
      >
        <View style={styles.overlay}>
          <View style={styles.container}>
            <Image
              source={Images.logo}
              resizeMode="contain"
              style={styles.image}
            />
            <AppText style={styles.title}>
              {strings('common.forceUpdateTitle')+" "+item?.latestVersion}
            </AppText>
            <AppText style={styles.description}>
              {strings('common.forceUpdateDesc')}
            </AppText>
            <TouchableOpacity
              onPress={() =>
                Linking.openURL(
                  Platform.select({
                    ios:
                      'https://apps.apple.com/us/app/' +
                      Constants?.manifest?.ios?.bundleIdentifier,
                    android: item?.storeUrl,
                  }),
                )
              }
              style={styles.UpdateButton}
            >
              <AppText style={styles.updateText}>Update Now</AppText>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    );
  }
}

export default connect((state) => ({
  config: state.config,
}))(ForceUpdate);
