import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  container: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    flex: 1,
    justifyContent: 'center',
  },
  topContainer: {
    padding: 20,
    margin: 15,
    alignContent: 'center',
    borderRadius: 5,
    alignSelf: 'center',
    maxWidth: 360,
    width: '100%',
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
    borderRadius: 5,
    backgroundColor: Colors.white,
  },
  textInput: {
    borderWidth: 1.5,
    borderColor: Colors.white_gray,
    borderRadius: 5,
    padding: 8,
    minHeight: 8,
    textAlignVertical: 'top',
    fontSize: 17,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  touchContainer: {flexDirection: 'row', marginTop: 15, marginBottom: 5},
  touchable: {
    paddingVertical: setYAxisValue(10),
    borderColor: Colors.theme_color,
    borderWidth: 2,
    paddingHorizontal: setXAxisValue(15),
    justifyContent: 'center',
    borderRadius: 8,
    alignItems: 'center',
    flex: 1,
  },
  buttonText: {fontFamily: 'Roboto-Bold', color: Colors.theme_color},
});
