import React, {useState, useEffect} from 'react';
import {Platform, Image, TouchableOpacity, View} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {get} from 'lodash';
import Swiper from '../../components/Swiper';
import {
  AppText,
  LongButton,
  MainContainer,
  MainHeader,
  ProgressiveImage,
  ZoomImage,
} from '../../components';
import {strings} from '../../i18n';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {cashFormat, goBack, setValue} from '../../utils';
import styles from './styles';
import axios from 'axios';
import {getMenu} from '../../redux/reduxsauceActions/menuActions';
import {Colors} from '../../constants';

const MenuDetailScreen = (props) => {
  const {user, navigation, route, openAlert, appConfig, setLoading} = props;

  const [item, setItem] = useState(route.params?.item || null);
  const [visibleImage, setVisibleImage] = useState(false);
  let currencySymbol = get(appConfig, 'currencySymbol', '₹');
  const getMenuDetail = async () => {
    props.setLoading(true);

    await axios
      .get('/products/details/' + item?.id)
      .then((response) => {
        setItem(response?.data);
        props.setLoading(false);
      })
      .catch((error) => {
        console.log('error->', error.response);
        props.setLoading(false);
      });
  };
  useEffect(() => {
    props.navigation.addListener('focus', () => {
      getMenuDetail();
    });
  }, [props.navigation]);

  return (
    <View
      style={
        Platform.OS == 'web'
          ? {overflow: 'visible', height: '100vh'}
          : styles.container
      }
    >
      <MainHeader
        title={`${item?.name}`}
        leftIcon="back"
        rightIcon={['merchant', 'manager'].includes(user?.type) && 'edit'}
        rightPress={() =>
          navigation.navigate('edit-menu', {
            item,
          })
        }
        leftPress={() => goBack(navigation)}
        rightOtherIcon={
          user?.id &&
          ['merchant', 'manager'].includes(user?.type) &&
          'dustBinSmaller'
        }
        rightOtherPress={() => {
          openAlert(
            strings('menuDetailScreen.delete'),
            strings('common.yes'),
            async () => {
              setLoading(true);
              await axios
                .delete('/products/delete/' + item.id)
                .then((response) => {
                  props.getMenu();
                  goBack(navigation);
                  setLoading(false);
                })
                .catch((error) => {
                  openAlert(error?.response?.message);
                  setLoading(false);
                });
            },
            strings('common.no'),
            null,
          );
        }}
      />
      <MainContainer>
        <KeyboardAwareScrollView>
          <ZoomImage
            imageUrls={
              item?.images?.length > 0 &&
              item?.images?.map((o) => {
                return (o = {url: o.photo});
              })
            }
            visible={visibleImage}
            handleClose={() => {
              setVisibleImage(false);
            }}
          />

          <View style={{aspectRatio: 3 / 2}}>
            <TouchableOpacity
              onPress={() => {
                setVisibleImage(true);
              }}
            >
              <View style={{aspectRatio: 3 / 2}}>
                {item.images?.length > 0 ? (
                  <Swiper
                    autoplay={true}
                    activeDotColor={Colors.theme_color}
                    autoplayTimeout={7}
                  >
                    {item.images?.map((o, index) => (
                      <TouchableOpacity
                        onPress={() => {
                          setVisibleImage(true);
                        }}
                        key={index}
                      >
                        <ProgressiveImage
                          thumbnailSource={{uri: o.photo}}
                          source={{uri: o.photo}}
                          style={styles.image}
                          resizeMode="cover"
                          key={index}
                        />
                      </TouchableOpacity>
                    ))}
                  </Swiper>
                ) : (
                  <Image
                    source={require('../../../assets/images/General/no-img-found.jpg')}
                    style={styles.image}
                  />
                )}
              </View>
            </TouchableOpacity>
          </View>

          <View style={[styles.sectionContainer, {flexDirection: 'column'}]}>
            <View style={{flexDirection: 'row'}}>
              <AppText style={styles.summaryPriceText}>
                {currencySymbol +
                  ' ' +
                  cashFormat(parseInt(item.price)) +
                  ' /-'}
              </AppText>
              {item?.discount != 0 && (
                <AppText style={styles.textDiscountPrice}>
                  {currencySymbol +
                    ' ' +
                    cashFormat(
                      (parseInt(item?.price) * parseInt(item?.discount)) / 100 +
                        parseInt(item?.price),
                    ) +
                    ' /-'}
                </AppText>
              )}
            </View>
            <View style={styles.summaryDescContainer}>
              <AppText style={styles.descriptionText}>
                {item?.description
                  ? item?.description
                  : strings('menuDetailScreen.description')}
              </AppText>
            </View>
          </View>
          {item?.details?.length && (
            <View style={styles.otherSectionContainer}>
              {(item?.details).map((o, index) => (
                <View
                  style={[
                    styles.specificationContainer,
                    o.type == 'array' && {flexDirection: 'column'},
                  ]}
                  key={index}
                >
                  <AppText style={styles.specificationKeys}>{o.label}</AppText>

                  {(o.type == 'text' || o.type == 'longText') && (
                    <AppText style={styles.specificationValues}>
                      {o.value}
                    </AppText>
                  )}

                  {o.type == 'array' && (
                    <View
                      style={[
                        styles.specificationValues,
                        {padding: setValue(5)},
                      ]}
                    >
                      {o.value &&
                        o.value.map((item, index) => (
                          <View style={{flexDirection: 'row'}}>
                            <View style={styles.pointStyle}></View>
                            <AppText
                              style={{marginLeft: setValue(10)}}
                              key={index}
                            >
                              {item}
                            </AppText>
                          </View>
                        ))}
                    </View>
                  )}
                </View>
              ))}
            </View>
          )}
        </KeyboardAwareScrollView>
      </MainContainer>
    </View>
  );
};

export default connect(
  (state) => ({
    appConfig: state.config,
    user: state.auth.user,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getMenu,
        setLoading: CommonActions.setLoading,
        openAlert: (content, leftButton, leftPress, rightButton, rightPress) =>
          CommonActions.setAlert({
            visible: true,
            content,
            leftButton,
            leftPress,
            rightButton,
            rightPress,
          }),
      },
      dispatch,
    ),
)(MenuDetailScreen);
