import React, {useState} from 'react';
import {
  View,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  Platform,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {AppText, CheckBox, Icon} from '..';
import {setValue} from '../../utils';
import styles from './styles';
import Icon4 from '@expo/vector-icons/FontAwesome';
import {Colors} from '../../constants';
import {get} from 'lodash';
import ProgressiveImage from '../ProgressiveImage';
import {MaterialCommunityIcons, Fontisto} from '@expo/vector-icons';
import axios from 'axios';
import CommonActions from '.././../redux/reduxsauce/commonRedux';

const TopProductItem = (props) => {
  const config = useSelector((state) => state.config);
  let currencySymbol = get(config, 'currencySymbol', '₹');

  let ratio = config?.product?.aspectRatio
    ? config?.product?.aspectRatio[0] / config?.product?.aspectRatio[1]
    : 4 / 3;

  const {item} = props;
  const Element = props.useTouch ? TouchableOpacity : View;
  const [loading, setLoading] = useState(false);
  const [imageHeight, setImageHeight] = useState(0);

  const dispatch = useDispatch();
  const iconColor = Colors.white;

  const getSupportChat = async (product) => {
    setLoading(true);
    try {
      const response = await axios.get(`/support/get/${product?.id}/product`);
      console.log('response--', response);
      setLoading(false);
      props.navigation.navigate('live-support', {
        photo: null,
        chatId: response?.data?.data.id,
        id: response?.data?.data?.product?.id,
      });
    } catch (error) {
      setLoading(false);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message || 'Something want worng :(',
        }),
      );
      console.log('error', error);
    }
  };
  return (
    <Element
      style={props.grid ? styles.gridItem : styles.topProductItem}
      onPress={() => {
        props.onPress(item);
      }}
    >
      {props.isButtonVisible && (
        <TouchableOpacity
          onPress={() => {
            props.removeTopSelectedProduct(item.id);
          }}
          style={styles.deleteIconContainer}
        >
          <Icon4 name="trash" size={17} color="red" />
        </TouchableOpacity>
      )}
      {props.isCheckBoxVisible && (
        <View style={styles.checkBoxContainer}>
          <CheckBox
            checked={item.checked}
            onChange={(checked) => {
              props.setChecked(item.id);
            }}
            disabled={
              props?.checkBoxSelectOnly
                ? false
                : !item.always_available_stock &&
                  parseFloat(item?.stock_qty) == 0
            }
          />
        </View>
      )}
      <View>
        {!item.always_available_stock && parseFloat(item?.stock_qty) == 0 && (
          <View
            style={[
              styles.outOfStock,
              {top: imageHeight ? imageHeight / 2.2 : 0, alignSelf: 'center'},
            ]}
          >
            <Text
              style={{
                color: Colors.white,
                padding: setValue(3),
                fontWeight: 'bold',
                fontSize: setValue(12),
              }}
            >
              {'OUT OF STOCK'}
            </Text>
          </View>
        )}
        <ProgressiveImage
          thumbnailSource={
            item?.images[0]?.photo
              ? {uri: item?.images[0]?.photo}
              : require('../../../assets/images/General/no-img-found.jpg')
          }
          source={
            item?.images[0]?.photo
              ? {uri: item?.images[0]?.photo}
              : require('../../../assets/images/General/no-img-found.jpg')
          }
          style={[
            Platform.OS == 'web' ? styles.productImageWeb : styles.productImage,
            {
              aspectRatio: ratio,
              opacity:
                !item.always_available_stock && parseFloat(item?.stock_qty) == 0
                  ? 0.5
                  : 1,
            },
          ]}
          onLayout={(event) => {
            const {height} = event.nativeEvent.layout;
            setImageHeight(height);
          }}
          resizeMode="cover"
        />
      </View>
      <View
        style={{
          paddingHorizontal: setValue(10),
          marginTop: setValue(15),
          paddingBottom: setValue(10),
        }}
      >
        <AppText style={styles.topProductItemName} numberOfLines={1}>
          {item.name}
        </AppText>

        {/* <AppText numberOfLines={1} style={styles.topProductItemDesc}>
          {item.description}
        </AppText> */}
        <View style={{flexDirection: 'row', marginTop: 3}}>
          <AppText
            style={
              item.discount
                ? [styles.topProductItemPrice, {paddingBottom: 3}]
                : [styles.topProductItemPrice]
            }
          >
            {currencySymbol}
            {' ' + item.price}
          </AppText>
          {item.discount && item.discount != 0 && (
            <AppText style={styles.discountText}>
              {`${currencySymbol} ${(
                parseInt(item.price) +
                (parseInt(item.price) * parseInt(item.discount)) / 100
              ).toFixed(2)}`}
            </AppText>
          )}
        </View>
        {config.product?.hidePrice &&
        (item.always_available_stock || parseFloat(item?.stock_qty) > 0) ? (
          <TouchableOpacity
            onPress={() => getSupportChat(item)}
            style={[styles.AddToCart]}
          >
            {loading ? (
              <ActivityIndicator color={iconColor} size={'small'} />
            ) : (
              <MaterialCommunityIcons
                name="email"
                size={20}
                color={iconColor}
              />
            )}
          </TouchableOpacity>
        ) : (
          props?.isAddToCart &&
          (item.always_available_stock || parseFloat(item?.stock_qty) > 0) && (
            <TouchableOpacity
              style={styles.AddToCart}
              onPress={() => {
                // props?.onAddToCart();
              }}
            >
              <Icon name="add" size={15} color={iconColor} />
            </TouchableOpacity>
          )
        )}
      </View>
      {config.type == 'restaurants' &&
        !props.isCheckBoxVisible &&
        (item.food_type == 'veg' ? (
          <View style={[styles.FoodType, {borderColor: Colors.green}]}>
            <Fontisto name="record" size={10} color={Colors.green} />
          </View>
        ) : (
          item.food_type == 'non-veg' && (
            <View style={[styles.FoodType, {borderColor: 'red'}]}>
              <Fontisto
                name="record"
                size={10}
                color={Colors.wallet_card_red}
              />
            </View>
          )
        ))}
    </Element>
  );
};

export default TopProductItem;
