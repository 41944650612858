import {StyleSheet} from 'react-native';

import {Colors} from '../../../constants';
import {setXAxisValue, setYAxisValue} from '../../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 8,
  backgroundColor: Colors.white,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },

  title: {
    fontSize: setXAxisValue(16),
    color: Colors.black60,

    paddingVertical: setYAxisValue(10),
  },
  date: {
    fontSize: setXAxisValue(14),
    color: Colors.black60,
    paddingVertical: setYAxisValue(10),
    fontStyle: 'italic',
  },
  description: {
    fontSize: setXAxisValue(16),
    color: Colors.black40,
    paddingVertical: setYAxisValue(5),
  },
  notificationContainer: {
    ...shadow,
    backgroundColor: Colors.white,
    paddingHorizontal: setXAxisValue(10),
    borderRadius: 10,
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    width: '100%',
    backgroundColor: Colors.theme_color,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    paddingVertical: setYAxisValue(10),
    color: Colors.white,
    marginBottom: setYAxisValue(10),
  },
  okText: {
    fontSize: setXAxisValue(14),

    color: Colors.white,
    backgroundColor: Colors.theme_color,
  },
});
