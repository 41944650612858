import {StyleSheet} from 'react-native';
import {Colors} from '../../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
};
const wrapper = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 8,
  padding: setValue(5),
};
export default StyleSheet.create({
  container: {
    flex: 1,
  },
  textInputContainer: {
    minHeight: 70,
    backgroundColor: Colors.white,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
    ...shadow,
  },
  textInput: {
    height: setValue(50),
    padding: 5,
    flex: 1,
    paddingHorizontal: setXAxisValue(10),
  },
  multiLineTextInput: {
    padding: setValue(10),
    textAlignVertical: 'top',
  },
  name: {
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  iconDropdown: {
    top: setYAxisValue(25),
  },
  pickerStyle: {
    marginTop: setValue(70),
  },
  buttonContainer: {
    justifyContent: 'center',
    flexDirection: 'row',
    paddingLeft: setValue(5),
    alignItems: 'center',
  },
  subCategoryConatiner: {
    flex: 1,
    paddingTop: 8,
  },
  subCatogoryTitle: {
    // textAlign:"center",
    fontFamily: 'Roboto-Bold',
    marginVertical: 5,
    marginBottom: 10,
    fontSize: 16,
  },
  staticItemContainer: {
    backgroundColor: Colors.white,
    marginVertical: 7,
    marginHorizontal: 3,
    borderRadius: 5,
    ...wrapper,
  },
  iconContainer: {
    textAlign: 'center',
    padding: 10,
  },
  iconStyle: {
    color: Colors.black60,
  },
  itemContainer: {
    padding: 5,
    marginTop: 6,
    marginBottom: 6,
    marginLeft: 3,
    marginRight: 5,
    marginVertical: 5,
    marginHorizontal: 3,
    flexDirection: 'row',
    backgroundColor: Colors.white,
    borderRadius: 5,
    ...shadow,
  },
  roomDetailContainer: {
    justifyContent: 'center',
    flex: 1,
  },
  roomName: {
    color: Colors.black60,
    fontSize: setValue(16),
    marginLeft: setValue(8),
    fontFamily: 'Roboto-Bold',
  },
  roomDesc: {
    color: Colors.black60,
    fontSize: setValue(14),
    marginLeft: setValue(8),
  },
  button: {
    width: setValue(33),
    height: setValue(33),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.theme_color,
    marginVertical: setValue(5),
    marginHorizontal: setValue(5),
    borderRadius: setValue(40),
  },
});
