import {StyleSheet} from 'react-native';

import {Colors, Layout} from '../../constants';
import {setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 8,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  body: {
    marginTop: Layout.window.width / 2,
  },
  supportView: {
    ...shadow,
    backgroundColor: Colors.theme_color,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: setYAxisValue(20),
    paddingHorizontal: setXAxisValue(10),
    borderRadius: 8,
    alignItems: 'center',
  },
  title: {
    fontSize: setXAxisValue(14),
    color: Colors.white,
  },
  descriptionContainer: {
    marginVertical: setYAxisValue(20),
  },
  descriptionTitle: {
    fontSize: setXAxisValue(13),
    color: Colors.black60,
  },
  infoContainer: {
    flexDirection: 'row',
    marginTop: setYAxisValue(10),
    alignItems: 'center',
  },
  infoText: {
    fontSize: setXAxisValue(13),
    color: Colors.black60,
    marginLeft: setXAxisValue(5),
  },
});
