import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import {AppText} from '..';
import styles from './styles';

const CustomerItem = (props) => {
  //   console.log('props', props);
  const {item, listType} = props;
  return (
    <TouchableOpacity
      style={styles.customerCard}
      onPress={props.onPressCustomer}
    >
      <View style={styles.imageContainer}>
        <Image
          source={
            item?.photo
              ? {uri: item?.photo}
              : require('../../../assets/images/General/profile.png')
          }
          style={styles.image}
        />
      </View>
      <View style={styles.customerDetailContainer}>
        <View style={{flex: 1, alignSelf: 'baseline', flexDirection: 'row'}}>
          {item?.first_name ? (
            <AppText style={styles.customerName}>
              {item?.first_name?.charAt(0).toUpperCase() +
                item?.first_name?.slice(1)}{' '}
              {item?.last_name?.charAt(0).toUpperCase() +
                item?.last_name?.slice(1)}{' '}
            </AppText>
          ) : (
            <AppText style={styles.customerName}>
              {item?.name?.charAt(0).toUpperCase() + item?.name?.slice(1)}
            </AppText>
          )}
          {listType == 'Staff Members' && (
            <Text style={{fontSize: 12, marginTop: 4, fontWeight: 'bold'}}>
              ({item.type})
            </Text>
          )}
        </View>
        <AppText style={styles.customerEmail}>{item?.email}</AppText>
        <AppText style={styles.customerPhone}>{item?.phone}</AppText>
      </View>
    </TouchableOpacity>
  );
};

export default CustomerItem;
