import {Platform, StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {
  screenHeight,
  screenWidth,
  setValue,
  setXAxisValue,
  setYAxisValue,
} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
  backgroundColor: Colors.white,
};

export default StyleSheet.create({
  container:
  Platform.OS !="web" ?{
    flex: 1,
  }:{
    overflow: 'scroll',
    height: '100vh',
  },
  body: {
    marginTop: setYAxisValue(20),
    marginBottom: 10,
    minHeight: screenHeight / 2,
  },
  settingView: {
    ...shadow,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: setYAxisValue(20),
    paddingHorizontal: setXAxisValue(10),
    borderRadius: 8,
    alignItems: 'center',
    marginBottom: setYAxisValue(10),
  },
  settingSwitchView: {
    ...shadow,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: setYAxisValue(15),
    paddingHorizontal: setXAxisValue(10),
    borderRadius: 8,
    alignItems: 'center',
    marginBottom: setYAxisValue(10),
  },
  title: {
    fontSize: setXAxisValue(14),
    color: Colors.black60,
  },
  title2: {
    fontSize: setXAxisValue(14),
    color: Colors.black40,
  },
  tickView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tick: {
    marginRight: setXAxisValue(50),
  },
  logoutView: {
    ...shadow,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: setYAxisValue(20),
    paddingHorizontal: setXAxisValue(10),
    borderRadius: 8,
    alignItems: 'center',
    marginTop: setYAxisValue(30),
  },
  overlay: {
    flex: 1,
    backgroundColor: Colors.overlay,
    alignItems: 'center',
    justifyContent: 'center',
  },
  informationContainer: {
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
    backgroundColor: Colors.white,
    justifyContent: 'center',
    alignSelf: 'center',
    borderRadius: 8,
    width: screenWidth * 0.85,
    marginBottom: setYAxisValue(40),
    padding: setValue(2),
  },
  envItem: {
    fontSize: 16,
    paddingVertical: setYAxisValue(22),
    paddingHorizontal: setXAxisValue(16),
    backgroundColor: Colors.white,
  },
  divider: {
    height: 1,
    backgroundColor: '#cacaca',
  },
});
