import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {screenWidth, setValue, setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  itemContainer: {
    marginBottom: 10,
    flexDirection: 'row',
    backgroundColor: Colors.white,
    borderRadius: 5,
    padding: 10,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
  },
  itemIcon: {
    justifyContent: 'center',
    marginRight:10
  },
  itemDetail: {
    flex: 1,
    paddingRight:10
  },
  itemCheckBox: {
    justifyContent: 'center',
  },
  itemTitle: {
    fontWeight: 'bold',
    color: Colors.black20,
  },
  itemDesc: {
    color: Colors.black40,
  },
});
