import React, {Component} from 'react';
import {SafeAreaView, TouchableOpacity, View} from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';
import {Colors} from '../../constants';
import Icon from '../Icon';
import Modal from '../Modal';
import styles from './styles';

class ZoomImage extends Component {
  constructor(props) {
    super(props);
    this.state = {visible: false};
  }

  setVisible = (visible) => this.setState({visible});

  render() {
    const {visible} = this.props;
    console.log('visible', visible);
    return (
      <Modal
        visible={visible}
        transparent
        onRequestClose={() => this.props.handleClose()}
      >
        <View style={styles.container}>
          <ImageViewer
            imageUrls={this.props.imageUrls || []}
            style={{width: '100%', height: 500}}
          />
        </View>
        <SafeAreaView style={styles.crossIcon}>
          <TouchableOpacity
            onPress={() => {
              this.props.handleClose();
              // this.setVisible(false);
            }}
          >
            <Icon name="cross" size={22} color={Colors.white} />
          </TouchableOpacity>
        </SafeAreaView>
      </Modal>
    );
  }
}

export default ZoomImage;
