import {StyleSheet} from 'react-native';
import {Colors} from '../../constants';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 8,
  backgroundColor: Colors.white,
};

export default StyleSheet.create({
  cardContainer: {
    padding: 10,
    marginBottom: 15,
    borderRadius:8,
    ...shadow,
  },
  row:{
    flexDirection:"row",
    justifyContent:"space-between",
    alignItems:"center",
  },
  payNowBtn: {
    alignSelf: 'center',
    paddingHorizontal: 10,
    paddingVertical: 5,
    // backgroundColor: Colors.theme_color,
    borderRadius:10
  },
  payNowText: {
    // color: Colors.white,
    color:Colors.theme_color,
    fontFamily:"Roboto-Bold",
    fontSize: 16,
  },
});
