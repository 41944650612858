import {Platform, StyleSheet} from 'react-native';

import {Colors, Layout} from '../../constants';
import {setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 8,
  backgroundColor: Colors.white,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  mapStyle: {
    width: Platform.OS == 'web' ? '100%' : Layout.window.width,
    ...StyleSheet.absoluteFillObject,
  },
  outLetImage: {
    width: setXAxisValue(160),
    height: setYAxisValue(100),
    borderRadius: 8,
  },
  outLetImageContainer: {
    position: 'absolute',
    alignSelf: 'center',
    borderRadius: 8,
    ...shadow,
  },
  itemContainer: {
    marginRight: setXAxisValue(15),
    width: setXAxisValue(180),
    height: setYAxisValue(300),
  },
  itemBody: {
    ...shadow,
    // alignItems: 'center',
    borderRadius: 8,
    marginTop: setYAxisValue(45),
  },
  title: {
    fontSize: setXAxisValue(13),
    fontFamily: 'Roboto-Bold',
    paddingHorizontal: setYAxisValue(10),
    paddingTop: setYAxisValue(10),
  },
  descContainer: {
    // borderTopWidth: StyleSheet.hairlineWidth,
    borderBottomWidth: StyleSheet.hairlineWidth,
    width: '100%',
    paddingVertical: setYAxisValue(5),
  },
  desc: {
    fontSize: setXAxisValue(10),
    fontFamily: 'Roboto-Bold',
    color: Colors.color30,
    paddingTop: setYAxisValue(5),
    padding: setXAxisValue(10),
  },
  distanceContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  distance: {
    width: '70%',
    paddingLeft: setXAxisValue(10),
    borderRightWidth: StyleSheet.hairlineWidth,
    paddingVertical: setYAxisValue(15),
  },
  distanceText: {
    fontSize: setXAxisValue(10),
    fontFamily: 'Roboto-Bold',
    color: Colors.color30,
  },
  kmText: {
    fontSize: setXAxisValue(10),
    fontFamily: 'Roboto-Bold',
    color: Colors.black20,
  },
  directionContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '30%',
  },
  flatList: {
    position: 'absolute',
    top: setYAxisValue(20),
    left: setXAxisValue(20),
    backgroundColor: 'transparent',
  },
});
