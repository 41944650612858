import get from 'lodash.get';
import moment from 'moment';
import React, {Component} from 'react';
import {
  ActivityIndicator,
  Keyboard,
  TextInput,
  TouchableOpacity,
  View,
  Platform,
} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Picker} from '@react-native-picker/picker';
import {
  AppText,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
  DropDownInput,
} from '../../components';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import PhoneInput from '../../lib/react-native-phone-input';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {newRegisterAccount} from '../../redux/reduxsauceActions/authActions';
import {
  getParam,
  goBack,
  isEmail,
  isPhoneNumberValid,
  setValue,
} from '../../utils';
import styles from './styles';
import {Helmet} from 'react-helmet';

const businessTypeArray = [
  {
    value: 'Kirana Store, FMCG and Grocery',
    label: 'Kirana Store, FMCG & Grocery',
  },
  {
    value: 'Restaurants and Hotels',
    label: 'Restaurants & Hotels',
  },
  {
    value: 'Fashion Apparels, Shoes and Accessories',
    label: 'Fashion Apparels, Shoes & Accessories',
  },
  {value: 'Fruits and Vegetables', label: 'Fruits & Vegetables'},
  {
    value: 'Mobiles, Computers and Other Accessories',
    label: 'Mobiles, Computers & Other Accessories',
  },
  {
    value: 'Books and Stationery Products',
    label: 'Books & Stationery Products',
  },
  {value: 'Beauty and Cosmetics', label: 'Beauty & Cosmetics'},
  {
    value: 'Home Decoration and Electronic Appliances',
    label: 'Home Decoration & Electronic Appliances',
  },
  {
    value: 'Pharmacy and Medical Care',
    label: 'Pharmacy & Medical Care',
  },
  {value: 'Bakery and Cake Shops', label: 'Bakery & Cake Shops'},
  {
    value: 'Fresh Chicken, Fish, and Meat',
    label: 'Fresh Chicken, Fish, & Meat',
  },
  {
    value: 'Local and Online Services',
    label: 'Local & Online Services',
  },
  {
    value: 'Jewellery, Gold, and Gems',
    label: 'Jewellery, Gold, & Gems',
  },
  {
    value: 'Insurance and Financial Services',
    label: 'Insurance & Financial Services',
  },
  {value: 'Paan Shop', label: 'Paan Shop'},
  {
    value: 'Gym and Sports Equipment',
    label: 'Gym & Sports Equipment',
  },
  {
    value: 'Educational Institutions, Schools, and Teachers',
    label: 'Educational Institutions, Schools, & Teachers',
  },
  {
    value: 'Hardware and Construction Tools',
    label: 'Hardware & Construction Tools',
  },
  {
    value: 'Transportation, Taxi, Travel, and Tourism',
    label: 'Transportation, Taxi, Travel, & Tourism',
  },
  {
    value: 'Car, Bike, Tractor and Vehicle Accessories',
    label: 'Car, Bike, Tractor & Vehicle Accessories',
  },
];

class RegisterScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSecure: true,
      gender: '',
      // getParam(props.route, 'gender', '')
      //   ? strings(
      //       `registerScreen.gender.${getParam(props.route, 'gender', '')}`,
      //     )
      //   : '',
      isDateVisible: false,
      date: {
        day: '01',
        month: '01',
        year: '1999',
      },
      phone: '',
      first_name: getParam(props.route, 'name', ''),
      last_name: getParam(props.route, 'name', ''),
      password: '',
      email: getParam(props.route, 'email', ''),
      isCheckingPhone: false,
      referralId: '',
      checked: false,
      category: '',
      business_name: '',
      description: '',
      street: '',
      area: '',
      city: '',
      state: '',
      pincode: 0,
      country: '',
    };
  }

  componentDidMount() {
    console.log('props---->>', this.props);
    this.setupPhoneInput();
  }

  setupPhoneInput = () => {
    this.setState({
      phone: `+${this.phoneInput.getCountryCode()}`,
    });
  };

  onRegister = () => {
    const {
      phone,
      first_name,
      last_name,
      email,
      date,
      password,
      gender,
      referralId,
      category,
      business_name,
      description,
      street,
      area,
      city,
      state,
      pincode,
      country,
    } = this.state;
    const {openAlert, navigation, appConfig} = this.props;
    const minAge = get(appConfig, 'memberMinAge', 18);

    Keyboard.dismiss();

    if (
      !phone ||
      !(phone && isPhoneNumberValid(phone)) ||
      !first_name ||
      !last_name ||
      !email ||
      !isEmail(email.trim()) ||
      !password ||
      (password && password.length < 6) ||
      !business_name ||
      !description ||
      !category ||
      !street ||
      !area ||
      !city ||
      !state ||
      !pincode ||
      !country
    ) {
      return openAlert(
        ((!phone || !(phone && isPhoneNumberValid(phone))) &&
          strings('registerScreen.validate.phone')) ||
          (!first_name && strings('registerScreen.validate.first_name')) ||
          (!last_name && strings('registerScreen.validate.last_name')) ||
          ((!email || !isEmail(email.trim())) &&
            strings('registerScreen.validate.email')) ||
          ((!password || (password && password.length < 6)) &&
            strings('registerScreen.validate.password')) ||
          (!business_name && 'Please enter business name') ||
          (!description && 'Please describe your business') ||
          (!category && 'Please select category of your business') ||
          ((!street || street == '') && 'Please enter street') ||
          ((!area || area == '') && 'Please enter area') ||
          ((!city || city == '') && 'Please enter city') ||
          ((!pincode || pincode == '') && 'Please enter pincode') ||
          ((!state || state == '') && 'Please enter state') ||
          ((!country || country == '') && 'Please enter country'),
      );
    }

    // let birthdate = Platform.OS == 'web' ? date : date?.dateString;
    // if (!birthdate || !isDateValid(`${birthdate}`)) {
    //   return openAlert(strings('registerScreen.validate.date'));
    // }
    // if (
    //   !isDateAboveAge(
    //     `${birthdate.split('-')[0]}/${birthdate.split('-')[1]}/${
    //       birthdate.split('-')[2]
    //     }`,
    //     minAge,
    //   )
    // ) {
    //   return openAlert(strings('registerScreen.validate.age', {age: minAge}));
    // }
    return this.props.newRegisterAccount(
      {
        password,
        phone,
        first_name,
        last_name,
        email,
        gender: gender.toLowerCase(),
        dob: `${String(date.year).padStart(2, '0')}-${String(
          date.month,
        ).padStart(2, '0')}-${String(date.day).padStart(2, '0')}`,
        referralId,
        business_name,
        description,
        category,
        street,
        area,
        city,
        pincode,
        state,
        country
      },
      navigation,
    );
  };

  render() {
    const {navigation, appConfig, countryCode} = this.props;
    const {
      isSecure,
      isDateVisible,
      isCheckingPhone,
      checked,
      phone,
      first_name,
      last_name,
      email,
      date,
      password,
      gender,
      referralId,
      category,
      business_name,
      description,
      street,
      area,
      city,
      state,
      pincode,
      country,
    } = this.state;

    const minAge = get(appConfig, 'memberMinAge', 18);
    const maxYear = moment().year() - Number(minAge);

    return (
      <View style={styles.container}>
        <Helmet>
          <meta
            name="description"
            content="Sign Up to Whitelabel app after signing up for a free trial and manage your workflows easily."
          />
        </Helmet>
        <MainHeader
          title={strings('registerScreen.registration')}
          leftIcon="back"
          leftPress={() => goBack(navigation)}
        />
        <MainContainer>
          <View style={styles.textInputContainer}>
            <PhoneInput
              ref={(input) => {
                this.phoneInput = input;
              }}
              initialCountry={countryCode}
              style={styles.textInput}
              value={phone}
              onSelectCountry={() => {
                this.setState({
                  phone: `+${this.phoneInput.getCountryCode()}`,
                });
              }}
              inputStyle={{
                height: setValue(50),
                justifyContent: 'center',
              }}
              textProps={{
                underlineColorAndroid: 'transparent',
                autoCapitalize: 'none',
                autoCorrect: false,
                placeholder: strings('registerScreen.placeHolder.phone'),
                placeholderTextColor: Colors.color30,
                keyboardType: 'phone-pad',
                returnKeyType: 'done',
                value: phone,
                onChangeText: (text) => this.setState({phone: text}),
                onSubmitEditing: () => this.firstName.focus(),
                // onBlur: () => this.checkingPhoneExist()
              }}
            />
            {isCheckingPhone && (
              <View style={styles.calendarIcon}>
                <ActivityIndicator color={Colors.color30} size="small" />
              </View>
            )}
          </View>
          <View style={styles.textInputContainer}>
            <TextInput
              ref={(input) => {
                this.firstName = input;
              }}
              underlineColorAndroid="transparent"
              autoCapitalize="words"
              autoCorrect={false}
              placeholder={strings('registerScreen.placeHolder.first_name')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={styles.textInput}
              value={first_name}
              onChangeText={(text) => this.setState({first_name: text})}
              onSubmitEditing={() => this.lastName.focus()}
            />
          </View>
          <View style={styles.textInputContainer}>
            <TextInput
              ref={(input) => {
                this.lastName = input;
              }}
              underlineColorAndroid="transparent"
              autoCapitalize="words"
              autoCorrect={false}
              placeholder={strings('registerScreen.placeHolder.last_name')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={styles.textInput}
              value={last_name}
              onChangeText={(text) => this.setState({last_name: text})}
              onSubmitEditing={() => this.emailInput.focus()}
            />
          </View>
          <View style={styles.textInputContainer}>
            <TextInput
              ref={(input) => {
                this.emailInput = input;
              }}
              underlineColorAndroid="transparent"
              autoCapitalize="none"
              autoCorrect={false}
              placeholder={strings('registerScreen.placeHolder.email')}
              placeholderTextColor={Colors.color30}
              keyboardType="email-address"
              returnKeyType="done"
              style={styles.textInput}
              value={email}
              onChangeText={(text) => this.setState({email: text})}
              onSubmitEditing={() => this.passwordInput?.focus()}
            />
          </View>

          <View style={styles.textInputContainer}>
            <TextInput
              ref={(input) => {
                this.passwordInput = input;
              }}
              underlineColorAndroid="transparent"
              autoCapitalize="none"
              autoCorrect={false}
              placeholder={strings('registerScreen.placeHolder.password')}
              placeholderTextColor={Colors.color30}
              secureTextEntry={isSecure}
              style={styles.textInput}
              returnKeyType="done"
              value={password}
              onChangeText={(text) => this.setState({password: text})}
            />
            <TouchableOpacity
              onPress={() =>
                this.setState((prev) => ({isSecure: !prev.isSecure}))
              }
              style={styles.inputRightSide}
            >
              <Icon
                name={isSecure ? 'revealPwd' : 'revealPwd-no'}
                size={22}
                color={Colors.color30}
              />
            </TouchableOpacity>
          </View>

          <View style={{paddingTop: 15, paddingBottom: 10}}>
            <AppText>{'Business Details'}</AppText>
          </View>

          <View style={styles.textInputContainer}>
            <TextInput
              ref={(input) => {
                this.lastName = input;
              }}
              underlineColorAndroid="transparent"
              autoCapitalize="words"
              autoCorrect={false}
              placeholder={'Business Name'}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={styles.textInput}
              value={business_name}
              onChangeText={(text) => this.setState({business_name: text})}
            />
          </View>

          <View
            style={[
              styles.textInputContainer,
              Platform.OS == 'web' && {minHeight: 80},
            ]}
          >
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              multiline={true}
              numberOfLines={3}
              placeholder={strings('editProductScreen.placeholder.description')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web'
                  ? [styles.textInputWeb]
                  : styles.multiLineTextInput
              }
              value={description}
              onChangeText={(text) => this.setState({description: text})}
            />
          </View>

          <View style={styles.textInputContainer}>
            <DropDownInput
              data={businessTypeArray}
              value={category}
              placeholder={'Please select bussiness category'}
              inputStyle={[
                {
                  paddingHorizontal: 10,
                  marginVertical: 10,
                },
              ]}
              onChangeText={(text) => {
                this.setState({category: text});
              }}
            />
          </View>

          <View style={styles.textInputContainer}>
            <TextInput
              ref={(input) => {
                this.lastName = input;
              }}
              underlineColorAndroid="transparent"
              autoCapitalize="words"
              autoCorrect={false}
              placeholder={'Street'}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={styles.textInput}
              value={street}
              onChangeText={(text) => this.setState({street: text})}
            />
          </View>

          <View style={styles.textInputContainer}>
            <TextInput
              ref={(input) => {
                this.lastName = input;
              }}
              underlineColorAndroid="transparent"
              autoCapitalize="words"
              autoCorrect={false}
              placeholder={'Area'}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={styles.textInput}
              value={area}
              onChangeText={(text) => this.setState({area: text})}
            />
          </View>

          <View style={styles.textInputContainer}>
            <TextInput
              ref={(input) => {
                this.lastName = input;
              }}
              underlineColorAndroid="transparent"
              autoCapitalize="words"
              autoCorrect={false}
              placeholder={'City'}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={styles.textInput}
              value={city}
              onChangeText={(text) => this.setState({city: text})}
            />
          </View>

          <View style={styles.textInputContainer}>
            <TextInput
              ref={(input) => {
                this.lastName = input;
              }}
              underlineColorAndroid="transparent"
              autoCapitalize="words"
              autoCorrect={false}
              placeholder={'Pincode'}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={styles.textInput}
              value={pincode}
              onChangeText={(text) => this.setState({pincode: text})}
            />
          </View>

          <View style={styles.textInputContainer}>
            <TextInput
              ref={(input) => {
                this.lastName = input;
              }}
              underlineColorAndroid="transparent"
              autoCapitalize="words"
              autoCorrect={false}
              placeholder={'State'}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={styles.textInput}
              value={state}
              onChangeText={(text) => this.setState({state: text})}
            />
          </View>

          <View style={styles.textInputContainer}>
            <TextInput
              ref={(input) => {
                this.lastName = input;
              }}
              underlineColorAndroid="transparent"
              autoCapitalize="words"
              autoCorrect={false}
              placeholder={'Country'}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={styles.textInput}
              value={country}
              onChangeText={(text) => this.setState({country: text})}
            />
          </View>

          <LongButton
            text={strings('registerScreen.register')}
            style={styles.registerButton}
            onPress={this.onRegister}
          />
          <View style={styles.descContainer}>
            <AppText style={styles.agreeText}>
              {strings('registerScreen.agree')}
            </AppText>
            <TouchableOpacity
              onPress={() => navigation.navigate('term-policy')}
            >
              <AppText style={styles.termAndConText}>
                {strings('registerScreen.termsAndConditions')}
              </AppText>
            </TouchableOpacity>
          </View>
        </MainContainer>
      </View>
    );
  }
}

export default connect(
  (state) => ({
    appConfig: state.config,
    countryCode: state.common.countryCode,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        openAlert: (content) =>
          CommonActions.setAlert({visible: true, content}),
        // registerAccount,
        newRegisterAccount,
        // checkPhoneExist,
        setLoading: CommonActions.setLoading,
      },
      dispatch,
    ),
)(RegisterScreen);
