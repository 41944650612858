import * as Notifications from 'expo-notifications';

import {store} from '../redux';
import CommonActions from '../redux/reduxsauce/commonRedux';

export const notificationHandler = async (msg, navigation) => {
  const auth = store.getState().auth;

  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: false,
      shouldPlaySound: false,
      shouldSetBadge: true,
    }),
  });
  try {
    const {action} = msg.request.content.data;
    const {body} = msg.request.content;

    let payload = msg.request.content.data;
    switch (action.toUpperCase()) {
      case 'ORDER':
        console.log('payload', payload);
        switch (payload.status.toUpperCase()) {
          case 'PAID':
          case 'ACCEPTED':
          case 'PREPARING':
          case 'READY':
          case 'SHIPPING':
          case 'COMPLETED':
          case 'DECLINED':
          case 'CANCELLED':
            if (auth.user.type == 'merchant' || auth.user.type == 'manager') {
              navigation.popToTop();
              navigation.navigate('order-history-detail', {
                id: payload.orderId,
              });
            }
            break;
          default:
            window.store.dispatch(
              CommonActions.setAlert({
                visible: true,
                content: body,
                title: msg.request.content.title,
              }),
            );
            break;
        }
        break;
      case 'SUBSCRIPTION':
        console.log('payload', payload);
        switch (payload.status.toUpperCase()) {
          case 'PAID':
          case 'ACCEPTED':
          case 'PREPARING':
          case 'READY':
          case 'SHIPPING':
          case 'COMPLETED':
          case 'DECLINED':
          case 'CANCELLED':
            navigation.popToTop();
            navigation.navigate('subscription-detail', {
              id: payload.orderId,
            });
            break;
          default:
            window.store.dispatch(
              CommonActions.setAlert({
                visible: true,
                content: body,
                title: msg.request.content.title,
              }),
            );
            break;
        }
        break;

      case 'PROMOTION':
      default:
        window.store.dispatch(
          CommonActions.setAlert({
            visible: true,
            content: body,
            title: msg.request.content.title,
          }),
        );
    }
  } catch (e) {
    console.warn(`Data parse error : ${e}\n${JSON.stringify(msg)}`);
  }
};
