import {Platform, StyleSheet} from 'react-native';
import {Colors} from '../../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../../utils';
const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 5,
};
export default StyleSheet.create({
  container: {
    flex: 1,
  },
  textInputContainer: {
    backgroundColor: Colors.white,
    flexDirection: 'row',
    borderRadius: 5,
    paddingHorizontal: setXAxisValue(15),
    marginHorizontal: 5,
    marginBottom: setYAxisValue(10),
    ...shadow
  },
  textInput:
    Platform.OS == 'web'
      ? {
          width: '100%',
          height: setValue(45)
        }
      : {
          height: setValue(45),
          fontSize: setXAxisValue(14),
        },
  inputRightSide: {
    position: 'absolute',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    right: setValue(15),
  },
});
