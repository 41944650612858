import {useIsFocused} from '@react-navigation/native';
import axios from 'axios';
import React, {useState, useEffect, useContext} from 'react';
import {useDispatch} from 'react-redux';
import StripeProvider, {StripeContext} from './StripeProvider';
import CommonAction from '../../redux/reduxsauce/commonRedux';
import {FlatList, Text, TouchableOpacity, View} from 'react-native';
import styles from './styles';
import {MainHeader, MainContainer} from '../../components';
import {strings} from '../../i18n';
import {dateTimeFormat, goBack} from '../../utils';
import {Colors} from '../../constants';
import moment from 'moment';

const BusinessInvoiceList = (props) => {
  const context = useContext(StripeContext);
  const isFocused = useIsFocused();
  const dispatch = useDispatch();

  const [invoiceList, setInvoiceList] = useState([]);
  useEffect(() => {
    getInvoiceList();
  }, [isFocused]);

  const getInvoiceList = async () => {
    dispatch(CommonAction.setLoading(true));
    await axios
      .get('/business/payout/invoice/list')
      .then((response) => {
        console.log('rep ---', response.data);
        setInvoiceList(response?.data);
        dispatch(CommonAction.setLoading(false));
      })
      .catch((error) => {
        console.log('error --', error);
        dispatch(CommonAction.setLoading(false));
      });
  };

  const renderItem = ({item, index}) => {
    return (
      <View style={styles.cardContainer}>
        <View style={styles.row}>
          <Text>{'INV00' + item.id}</Text>
          <Text>{moment(item.invoice_month, 'YYYY-MM-DD').format("MMM YYYY")}</Text>
        </View>
        <View style={styles.row}>
          <Text>
            {'Due Date : '}
            <Text
              style={{
                color:
                  item?.status == 'unpaid'
                    ? Colors.wallet_card_red
                    : Colors.black20,
              }}
            >
              {item.due_date}
            </Text>
          </Text>
        </View>
        <View style={styles.row}>
          <Text>
            {'Amount : ' + '$ ' + item.amount}
          </Text>
        </View>
        {item.status == 'unpaid' && (
          <TouchableOpacity style={styles.payNowBtn} onPress={async()=>{
            console.log('---------------------stripe');
            const paymentStatus = await context.sendPaymentByStripe(
              item,
              'home',
              props.navigation,
            );
            console.log('paymentStatus', paymentStatus);

            if (paymentStatus) {
              props.navigation.replace('home',);
            }
          }}>
            <Text style={styles.payNowText}>Pay Now</Text>
          </TouchableOpacity>
        )}
      </View>
    );
  };

  return (
    <StripeProvider style={{flex: 1}}>
      <MainHeader
        title={'Invoice List'}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer useView>
        <FlatList
          showsVerticalScrollIndicator={false}
          initialNumToRender={10}
          maxToRenderPerBatch={8}
          windowSize={8}
          removeClippedSubviews={true}
          data={invoiceList}
          keyExtractor={(item, index) => index.toString()}
          renderItem={renderItem}
          ListFooterComponent={() => <View style={{height: 100}} />}
        />
      </MainContainer>
    </StripeProvider>
  );
};

export default BusinessInvoiceList;
