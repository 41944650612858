import {get} from 'lodash';
import moment from 'moment';
import React from 'react';
import {View, TouchableOpacity} from 'react-native';
import {useSelector} from 'react-redux';
import AppText from '../AppText';
import styles from './styles';

export default function MerchantOrderItem(props) {
  const {item} = props;

  const config = useSelector((state) => state.config);
  let currencySymbol = get(config, 'currencySymbol', '₹');

  return (
    <TouchableOpacity
      onPress={() =>
        props?.from == 'invoice'
          ? props.navigation.navigate('offline-order-detail', {
              id: item.id,
            })
          : props.navigation.navigate('order-history-detail', {
              id: item.id,
            })
      }
    >
      <View style={styles.container}>
        <View style={styles.topContainer}>
          <AppText style={styles.orderUId}>{'ORD00' + item.id}</AppText>
          <AppText>
            <AppText style={styles.orderUId}>
              {moment(item.created_at).format('DD MMM YYYY hh:mm A')}
            </AppText>
          </AppText>
        </View>
        <View style={styles.bottomContainer}>
          <AppText
            style={styles.orderPrice}
          >{`${currencySymbol} ${item.total}`}</AppText>
          {props?.from == 'invoice' ? null : props.from == 'report' ? (
            <AppText
              style={
                item?.type === 'pickup'
                  ? [styles.orderPrice, {color: '#056eb0'}]
                  : item?.type == 'delivery'
                  ? [styles.orderPrice, {color: '#008c96'}]
                  : item?.type == 'dinein'
                  ? [styles.orderPrice, {color: '#f69c00'}]
                  : styles.orderPrice
              }
            >
              {item?.type.toUpperCase()}
            </AppText>
          ) : (
            <AppText
              style={
                item?.status === 'paid'
                  ? [styles.orderPrice, {color: '#056eb0'}]
                  : item?.status === 'accepted'
                  ? [styles.orderPrice, {color: '#008c96'}]
                  : item?.status === 'ready' || item?.status === 'shipping'
                  ? [styles.orderPrice, {color: '#f69c00'}]
                  : item?.status === 'completed'
                  ? [styles.orderPrice, {color: '#05a912'}]
                  : item?.cash_on_delivery == true
                  ? styles.orderPrice
                  : styles.orderPrice
              }
            >
              {item?.status == 'declined'
                ? item?.status?.toUpperCase()
                : item?.cash_on_delivery == true
                ? item?.status == 'unpaid'
                  ? 'COD'
                  : item?.status?.toUpperCase() + ' (COD)'
                : item?.status?.toUpperCase()}
            </AppText>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
}
