import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {View, Platform} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import CommonAction from '../../../redux/reduxsauce/commonRedux';
import ConfigActions from '../../../redux/reduxsauce/configRedux';
import {
  AppConfigCheckbox,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../../components';
import {goBack, screenHeight, setValue} from '../../../utils';
import styles from './styles';
import {strings} from '../../../i18n';

const ServiceSetting = (props) => {
  const [showServiceCategory, setShowServiceCategory] = useState(true);

  const config = useSelector((state) => state?.config);
  const dispatch = useDispatch();

  useEffect(() => {
    const setting = config;
    setShowServiceCategory(setting?.serviceConfiguration?.showCategory);
  }, []);

  const saveServiceConfig = async () => {
    dispatch(CommonAction.setLoading(true));
    try {
      const response = await axios.patch(
        `/business/update/setting/${config?.businessId}`,
        {
          setting: {
            ...config?.setting,
            serviceConfiguration: {
              showCategory: showServiceCategory,
            },
          },
        },
      );
      console.log('response', response);
      const configData = {
        ...config,
        serviceConfiguration: {
          showCategory: showServiceCategory,
        },
      };
      dispatch(ConfigActions.setAppConfiguration(configData));
      dispatch(CommonAction.setLoading(false));
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: strings('serviceSetting.alert.success'),
        }),
      );
      goBack(props.navigation);
    } catch (error) {
      dispatch(CommonAction.setLoading(false));
      console.log('error', error.response);
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };

  return (
    <View
      style={Platform.OS === 'web' ? styles.webContainer : styles.container}
    >
      <MainHeader
        title={strings('serviceSetting.title')}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer>
        <View style={Platform.OS != 'web' ? {minHeight: screenHeight / 2} : {}}>
          <AppConfigCheckbox
            icon={'category'}
            title={strings('serviceSetting.serviceCategory')}
            desc={strings('serviceSetting.descripation')}
            checked={showServiceCategory}
            onChangeCheck={(checked) => {
              setShowServiceCategory(checked);
            }}
          />
        </View>
        {Platform.OS == 'web' && (
          <LongButton
            text={strings('serviceSetting.button.save')}
            style={{marginTop: setValue(10), marginBottom: setValue(45)}}
            onPress={saveServiceConfig}
          />
        )}
      </MainContainer>
      {Platform.OS !== 'web' && (
        <LongButton
          text={strings('serviceSetting.button.save')}
          style={{
            width: '100%',
            borderRadius: 0,
          }}
          onPress={saveServiceConfig}
        />
      )}
    </View>
  );
};

export default ServiceSetting;
