import { useIsFocused } from '@react-navigation/native';
import axios from 'axios';
import {Camera} from 'expo-camera';
import React, {Component, useEffect, useState} from 'react';
import {
  TouchableOpacity,
  Vibration,
  View,
  TouchableWithoutFeedback,
} from 'react-native';
import BarcodeMask from 'react-native-barcode-mask';
import { useDispatch} from 'react-redux';

import {AppText, Icon} from '../../components';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {goBack} from '../../utils';
import styles from './styles';

export default function ScanCustomersQr(props) {
  const [cameraAvailable, setCameraAvailable] = useState(false);
  const [flashModeOn, setFlashModeOn] = useState(false);
  const [scanned, setScanned] = useState(false);
  const dispatch = useDispatch();
  const isFocused = useIsFocused()

  const handleBarCodeScanned = ({data}) => {
    const toNavigate = props.route.params?.navigate
      ? props.route.params?.navigate
      : 'customer-detail';
    console.log('data ---->', data);
    Vibration.vibrate();
    setScanned(true);
    if (parseInt(data)) {
      props.navigation.replace(toNavigate, {
        customerID: parseInt(data),
      });
    } else {
      goBack(props.navigation);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('common.invalidQR'),
        }),
      );
    }
  };

  useEffect(() => {
    checkCameraPermission();
  }, []);

  const checkCameraPermission = async () => {
    const {status} = await Camera.requestCameraPermissionsAsync();
    console.log('status: ', status);
    if (status === 'granted') {
      setCameraAvailable(true);
    } else {
      goBack(props.navigation);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('common.permision'),
        }),
      );
    }
  };

  useEffect(()=>{
    setScanned(false)
  },[isFocused])

  return (
    <View style={styles.container}>
      {cameraAvailable ? (
        <Camera
          onBarCodeScanned={scanned ? null : handleBarCodeScanned}
          style={styles.qrcode}
          type={Camera.Constants.Type.back}
          flashMode={
            flashModeOn
              ? Camera.Constants.FlashMode.torch
              : Camera.Constants.FlashMode.off
          }
        >
          <BarcodeMask
            edgeColor={Colors.green}
            animatedLineColor={Colors.wallet_card_red}
            outerMaskOpacity={0}
            lineAnimationDuration={1200}
          />
          <View
            style={[
              styles.content,
              scanned
                ? styles.qrcodeContentDarkOverlay
                : styles.qrcodeContentTransparent,
            ]}
          >
            <View style={styles.header}>
              <TouchableOpacity
                style={{flexDirection: 'row', alignItems: 'center'}}
                onPress={() => goBack(props.navigation)}
              >
                <Icon name="back" color="white" size={20} />
                <AppText style={styles.title}>{'Customer Qr Scanner'}</AppText>
              </TouchableOpacity>
            </View>
            <View>
              <TouchableOpacity onPress={() => setFlashModeOn(!flashModeOn)}>
                <AppText style={styles.flashMode}>{`${strings(
                  'ordering.checkInOrderScreen.flashMode',
                )}: ${flashModeOn ? 'off' : 'on'}`}</AppText>
              </TouchableOpacity>
            </View>
          </View>
        </Camera>
      ) : null}
    </View>
  );
}
