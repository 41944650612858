import * as Brightness from 'expo-brightness';
import React, {Component} from 'react';
import {
  Image,
  Platform,
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native';
import {connect} from 'react-redux';

import {AppText, LongButton, Icon, QRCode} from '../../components';
import {Images, Layout} from '../../constants';
import {goBack, setValue} from '../../utils';
import styles from './styles';

class MyQRScreen extends Component {
  currentBrightness = 0;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.handleCheckBrightness();
  }

  componentWillUnmount() {
    if (Platform.OS !== 'web') {
      Brightness.setSystemBrightnessAsync(this.currentBrightness);
    }
  }

  handleCheckBrightness = async () => {
    if (Platform.OS !== 'web') {
      const brightness = await Brightness.getBrightnessAsync();
      this.currentBrightness = brightness;
      const {status} = await Brightness.requestPermissionsAsync();
      if (status === 'granted') {
        Brightness.setSystemBrightnessAsync(1);
      }
    }
  };

  render() {
    const {navigation, profile, user} = this.props;
    return (
      <SafeAreaView style={styles.safeArea}>
        <ScrollView>
          <View style={styles.headerIcon}>
            {/* <TouchableOpacity style={styles.iconCross} onPress={() => goBack(navigation)}>
              <Icon name="cross" size={15} color="black" />
            </TouchableOpacity> */}
          </View>
          <View style={styles.container}>
            <View style={{marginVertical:30}}>
              <Image source={Images.logo} style={styles.imageAvatar} />
            </View>
            <QRCode
              size={Platform.OS == 'web' ? 350 : Layout.window.width * 0.8}
              value={user.id.toString()}
            />
            <AppText style={styles.name}>
              {user?.first_name + ' ' + user?.last_name}
            </AppText>
            <AppText style={styles.phone}>{user?.phone}</AppText>
          </View>
          <View style={{marginTop: setValue(20)}}>
            {Platform.OS == 'web' && (
              <LongButton text={'Close'} onPress={() => goBack(navigation)} />
            )}
          </View>
        </ScrollView>
        {Platform.OS != 'web' && (
          <LongButton
            text={'Close'}
            style={{borderRadius: 0}}
            onPress={() => goBack(navigation)}
          />
        )}
      </SafeAreaView>
    );
  }
}

export default connect((state) => ({
  user: state.auth?.user,
}))(MyQRScreen);
