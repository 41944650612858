import React, { Component } from 'react';
import { View } from 'react-native';
import QRCodeRN from 'react-native-qrcode-svg';

import { Images } from '../../constants';

class QRCode extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { value, logoSize, size } = this.props;
    return (
      <View style={this.props.style}>
        <QRCodeRN value={value} size={size} />
      </View>
    );
  }
}

QRCode.defaultProps = {
  value: 'random value',
  size: 128,
  logoSize: 30
};

export default QRCode;
