import i18n from 'i18n-js';
import * as Localization from 'expo-localization';
import hindi from './lang/hindi';
import en from './lang/en';
import ml from './lang/ml';

i18n.fallbacks = true;

i18n.translations = {
  en,
  hindi,
  ml,
};

i18n.locale = Localization.locale;

export const strings = (name, params = {}) => i18n.t(name, params);

export const switchLanguage = (lang, component) => {
  i18n.locale = lang;
  component.forceUpdate();
};

export default i18n;
