import {StyleSheet} from 'react-native';
import {Colors} from '../../constants';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
};

export default StyleSheet.create({
  partnerItem: {
    backgroundColor: Colors.white,
    borderRadius: 5,
    marginBottom: 10,
    padding: 10,
    ...shadow,
  },
  partnerType: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    marginBottom: 2,
    textTransform: 'capitalize',
  },
  partnerPass:{
    color:Colors.color30
  }
});
