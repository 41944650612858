import Constants from 'expo-constants';
import React, {Component} from 'react';
import {
  ActivityIndicator,
  Keyboard,
  Platform,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
  AppText,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../components';
import {Colors} from '../../constants';
import {goBack} from '../../utils';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {emailPassWOrdLogin} from '../../redux/reduxsauceActions/authActions';
import styles from './styles';
import axios from 'axios';
import {strings} from '../../i18n';

class ChangePasswordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSecureOldPassord: true,
      isSecureNewPassord: true,
      isSecureConfirmPassord: true,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      loading: false,
    };
  }

  onChangePassword = async () => {
    const {oldPassword, newPassword, confirmPassword} = this.state;
    const {openAlert, navigation} = this.props;

    let response = undefined;

    Keyboard.dismiss();
    this.setState({loading: true});
    if (!oldPassword || oldPassword.length < 6) {
      this.setState({
        loading: false,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
      openAlert(strings('changePasswordScreen.alert.oldPasswordLength'));
    } else if (!newPassword || newPassword.length < 6) {
      this.setState({
        loading: false,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
      openAlert(strings('changePasswordScreen.alert.newPasswordLength'));
    } else if (!confirmPassword || confirmPassword.length < 6) {
      this.setState({
        loading: false,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
      openAlert(strings('changePasswordScreen.alert.confirmPasswordLength'));
    } else if (confirmPassword === newPassword) {
      await axios
        .put(`/user/change-password`, {
          old_password: oldPassword,
          new_password: newPassword,
          confirm_password: confirmPassword,
        })
        .then((response) => {
          if (response?.data?.message) {
            this.setState({
              loading: false,
              oldPassword: '',
              newPassword: '',
              confirmPassword: '',
            });
            openAlert(response?.data?.message);
            goBack(navigation);
          } else if (response?.data?.non_field_errors) {
            this.setState({
              loading: false,
              oldPassword: '',
              newPassword: '',
              confirmPassword: '',
            });
            openAlert(response?.data?.non_field_errors);
          } else {
            this.setState({
              loading: false,
              oldPassword: '',
              newPassword: '',
              confirmPassword: '',
            });
            openAlert('Something went worng!');
          }
        })
        .catch((error) => {
          if (error.response?.data?.non_field_errors) {
            openAlert(error.response?.data?.non_field_errors);
          } else {
            openAlert(error?.response?.message);
          }
          this.setState({
            loading: false,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
          });
        });
    } else {
      this.setState({
        loading: false,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
      openAlert(strings('changePasswordScreen.alert.notMatch'));
    }
    this.setState({
      loading: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
  };

  render() {
    const {navigation} = this.props;
    const {
      loading,
      isSecureOldPassord,
      isSecureConfirmPassord,
      isSecureNewPassord,
      oldPassword,
      confirmPassword,
      newPassword,
    } = this.state;
    return (
      <View style={styles.container}>
        <MainHeader
          title={strings('changePasswordScreen.title')} // after we add in language json file
          leftIcon="back"
          leftPress={() => goBack(navigation)}
        />
        <MainContainer showLogo>
          <View>
            <View style={styles.textInputContainer}>
              <TextInput
                ref={(input) => {
                  this.passwordInput = input;
                }}
                editable={!loading}
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={strings(
                  'changePasswordScreen.placeholder.oldPassword',
                )}
                placeholderTextColor={Colors.color30}
                secureTextEntry={isSecureOldPassord}
                style={styles.textInput}
                returnKeyType="done"
                value={oldPassword}
                onChangeText={(text) => this.setState({oldPassword: text})}
              />
              <TouchableOpacity
                onPress={() =>
                  this.setState((prev) => ({
                    isSecureOldPassord: !prev.isSecureOldPassord,
                  }))
                }
                style={styles.inputRightSide}
              >
                <Icon
                  name={isSecureOldPassord ? 'revealPwd' : 'revealPwd-no'}
                  size={30}
                  color={Colors.color30}
                />
              </TouchableOpacity>
            </View>
            <View style={styles.textInputContainer}>
              <TextInput
                editable={!loading}
                ref={(input) => {
                  this.passwordInput = input;
                }}
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={strings(
                  'changePasswordScreen.placeholder.newPassword',
                )}
                placeholderTextColor={Colors.color30}
                secureTextEntry={isSecureNewPassord}
                style={styles.textInput}
                returnKeyType="done"
                value={newPassword}
                onChangeText={(text) => this.setState({newPassword: text})}
              />
              <TouchableOpacity
                onPress={() =>
                  this.setState((prev) => ({
                    isSecureNewPassord: !prev.isSecureNewPassord,
                  }))
                }
                style={styles.inputRightSide}
              >
                <Icon
                  name={isSecureNewPassord ? 'revealPwd' : 'revealPwd-no'}
                  size={30}
                  color={Colors.color30}
                />
              </TouchableOpacity>
            </View>
            <View style={styles.textInputContainer}>
              <TextInput
                editable={!loading}
                ref={(input) => {
                  this.passwordInput = input;
                }}
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={strings(
                  'changePasswordScreen.placeholder.confirmPassword',
                )}
                placeholderTextColor={Colors.color30}
                secureTextEntry={isSecureConfirmPassord}
                style={styles.textInput}
                returnKeyType="done"
                value={confirmPassword}
                onChangeText={(text) => this.setState({confirmPassword: text})}
              />
            </View>
            <LongButton
              disabled={loading}
              text={
                loading ? (
                  <ActivityIndicator color={'#fff'} />
                ) : (
                  strings('changePasswordScreen.changePassword')
                )
              }
              onPress={this.onChangePassword}
            />
          </View>
        </MainContainer>
      </View>
    );
  }
}

export default connect(
  (state) => ({
    user: state.auth.user,
    isConnected: state.common.isConnected,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        openAlert: (content) =>
          CommonActions.setAlert({visible: true, content}),
        emailPassWOrdLogin,
        setLoading: CommonActions.setLoading,
      },
      dispatch,
    ),
)(ChangePasswordScreen);
