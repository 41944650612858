import {connectActionSheet} from '@expo/react-native-action-sheet';
import * as ImagePicker from 'expo-image-picker';
import React, {useState, useEffect} from 'react';
import {
  Image,
  Keyboard,
  Platform,
  TextInput,
  TouchableOpacity,
  View,
  ScrollView,
  ActivityIndicator,
} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  AppText,
  Icon,
  MainContainer,
  MainHeader,
  AppConfigCheckbox,
  SpecificationItem,
  CheckBox,
  DropDownInput,
  TimePicker,
  LongButton,
} from '../../../components';
import {Colors} from '../../../constants';
import {strings} from '../../../i18n';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {goBack, setValue, setXAxisValue} from '../../../utils';
import styles from './styles';
import {
  getServices,
  onCreateServices,
  onUpdateServices,
} from '../../../redux/reduxsauceActions/servicesActions';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {useIsFocused} from '@react-navigation/native';

const EditServicesScreen = (props) => {
  const item = props?.route?.params?.item;
  const {categories} = props;

  const isFocused = useIsFocused();
  const dispatch = useDispatch();
  const config = useSelector((state) => state?.config);

  const [selectedTime, setSelectedTime] = useState({});
  const [servicesData, setServicesData] = useState({
    is_active: item?.is_active ? item?.is_active : false,
    name: item?.name ? item?.name : '',
    description: item?.description ? item?.description : '',
    price: item?.price ? parseInt(item?.price).toString() : '0',
    discount: item?.discount ? parseInt(item?.discount).toString() : '0',
    tax: item?.tax ? parseInt(item?.tax).toString() : '0',
    required_time: item?.required_time ? item?.required_time : '',
    type: item?.type ? item?.type : '',
    category: item?.category ? item?.category : '',
  });
  const [images, setImages] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageLoadingIndex, setImageLoadingIndex] = useState(null);
  const [isTimeVisible, setIsTimeVisible] = useState(false);
  const [serviceCategoryList, setServiceCategoryList] = useState([]);

  console.log('selectedTime--->>', selectedTime);

  const getServiceCategoryList = () => {
    dispatch(CommonActions.setLoading(true));
    axios
      .get('/services/services-category/list/' + config?.businessId)
      .then((response) => {
        console.log('response of services-category list===', response);
        setServiceCategoryList(response?.data);
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        dispatch(CommonActions.setLoading(trfalseue));
        console.log('error in response of services-category list===', error);
      });
  };

  const onSave = async () => {
    Keyboard.dismiss();
    if (servicesData.name == '') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('editServices.required.name'),
        }),
      );
      return;
    }

    if (servicesData.price == '' || servicesData.price == 0) {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('editServices.required.price'),
        }),
      );
      return;
    }
    if (servicesData.required_time == '') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('editServices.required.requiredTime'),
        }),
      );
      return;
    }
    if (servicesData.type == '') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('editServices.required.type'),
        }),
      );
      return;
    }
    if (servicesData.category == '') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('editServices.required.category'),
        }),
      );
      return;
    }

    dispatch(CommonActions.setLoading(true));
    let body = new FormData();
    body.append('business', config.businessId);
    body.append('name', servicesData.name);
    servicesData.description &&
      body.append('description', servicesData.description);
    body.append('discount', parseInt(servicesData.discount) || 0);
    body.append('tax', parseInt(servicesData.tax) || 0);
    body.append('category', servicesData?.category);
    body.append('price', servicesData.price ? parseInt(servicesData.price) : 0);
    body.append('type', servicesData.type);
    body.append('required_time', servicesData.required_time);
    body.append('is_active', servicesData.is_active);

    const header = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    if (item?.id) {
      await axios
        .patch('/services/update/' + item?.id, body, header)
        .then((response) => {
          console.log('response update blog', response);
          dispatch(CommonActions.setLoading(false));
          props.navigation.replace('services-list');
        })
        .catch((error) => {
          console.log('error update blog --', error?.response);
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: error?.response?.message,
            }),
          );
          dispatch(CommonActions.setLoading(false));
        });
    } else {
      await axios
        .post('/services/create', body, header)
        .then((response) => {
          console.log('response of create Services', response);
          dispatch(CommonActions.setLoading(false));
          props.navigation.replace('services-list');
          saveNewAccommodationImages(response?.data?.id);
        })
        .catch((error) => {
          console.log('error create Services', error.response);
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: error?.response?.message,
            }),
          );
          dispatch(CommonActions.setLoading(false));
        });
    }
  };

  useEffect(() => {
    isFocused && getServiceCategoryList();
  }, [isFocused]);

  const dataURLtoFile = (dataurl) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], 'image1.jpg', {type: mime});
  };

  const saveNewAccommodationImages = (id) => {
    dispatch(CommonActions.setLoading(true));

    Promise.all(
      images.map(async (o, index) => {
        console.log('image map o===>>', o);
        let blob = await (await fetch(o.photo)).blob();
        let body = new FormData();
        body.append('service', id);
        Platform.OS == 'web'
          ? body.append('photo', dataURLtoFile(o.photo))
          : body.append('photo', {
              uri: o.photo,
              type: blob.type,
              name: blob.data.name,
            });

        console.log('body', body);

        return axios.post('/services/services-media/create', body);
      }),
    )
      .then((response) => {
        console.log('response of accommodation media create-->', response);
        dispatch(CommonActions.setLoading(false));
        goBack(navigation);
      })
      .catch((error) => {
        console.log('error of accommodation media create-->', error);
        props.openAlert(error?.response?.message);
        dispatch(CommonActions.setLoading(false));
      });
  };

  const UpdateOldProductImages = async (id, url) => {
    let serviceId = props.route?.params?.item?.id;
    let blob = await (await fetch(url)).blob();
    if (id) {
      let array = [...images];
      let index = array.findIndex((o) => o.id == id);

      if (index >= 0) {
        setImageLoading(true);
        setImageLoadingIndex(index);
      }

      let body = new FormData();
      // body.append('service', serviceId);
      Platform.OS == 'web'
        ? body.append('photo', dataURLtoFile(url))
        : body.append('photo', {
            uri: url,
            type: blob.type,
            name: blob.data.name,
          });

      console.log('body-->', body);

      axios
        .patch('/services/services-media/update/' + id, body)
        .then((response) => {
          console.log(response.data);
          array[index] = {
            ...array[index],
            ...response.data,
          };
          setImages(array);
          setImageLoading(false);
          setImageLoadingIndex(null);
        })
        .catch((error) => {
          console.log('error -->', error?.response);
          props.openAlert(error?.response?.message);
          props.setLoading(false);
          setImageLoading(false);
          setImageLoadingIndex(null);
        });
    } else {
      let body = new FormData();
      body.append('service', serviceId);

      Platform.OS == 'web'
        ? body.append('photo', dataURLtoFile(url))
        : body.append('photo', {
            uri: url,
            type: blob.type,
            name: blob.data.name,
          });
      console.log('body-->', body);
      axios
        .post('/services/services-media/create', body)
        .then((response) => {
          console.log(response);
          let array = [...images];
          array.push(response.data);
          setImages(array);
        })
        .catch((error) => {
          console.log('error -->', error?.response);
          props.openAlert(error?.response?.message);
          props.setLoading(false);
        });
    }
  };
  const deleteImage = (id) => {
    let array = [...images];
    let index = array.findIndex((o) => o.id == id);
    setImageLoading(true);
    setImageLoadingIndex(index);
    axios
      .delete('/services/services-media/delete/' + id)
      .then((response) => {
        console.log('response delete image', response);
        array.splice(index, 1);
        setImages(array);
        setImageLoading(false);
        setImageLoadingIndex(null);
      })
      .catch((error) => {
        console.log('error delete image', error?.response?.data);
        props.openAlert(error?.response?.data?.detail);
        setImageLoading(false);
        setImageLoadingIndex(null);
      });
  };

  const openActionSheet = (index) => {
    console.log('index', index);
    const options = [
      strings('actionSheet.takePhoto'),
      strings('actionSheet.choosePhoto'),
      strings('actionSheet.removePhoto'),
      strings('actionSheet.cancel'),
    ];
    const cancelButtonIndex = 3;

    props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) openCamera(index);
        if (buttonIndex === 1) openImagePicker(index);
        if (buttonIndex === 2) removePhoto(index);
      },
    );
  };
  const removePhoto = (index) => {
    let array = [...images];

    if (!index && index != 0) {
      return null;
    } else {
      if (props.route?.params?.item) {
        let id = array[index]?.id;
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: 'Are you sure you want to remove this photo?',
            leftButton: strings('common.yes'),
            rightButton: strings('common.no'),
            leftPress: () => {
              deleteImage(id);
            },
            rightPress: () => {
              null;
            },
          }),
        );
      } else {
        array.splice(index, 1);
      }
    }

    setImages(array);
  };

  const openCamera = async (index) => {
    await ImagePicker.requestCameraPermissionsAsync();

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: [4, 3],

      quality: 0.8,
      base64: true,
    });

    if (result.uri) {
      let array = [...images];

      // if function have not any index of image
      if (!index && index != 0) {
        // if function have index and also product is old then call api
        if (props.route?.params?.item) {
          UpdateOldProductImages(null, result.uri);

          // if function have index but product is new
        } else {
          let obj = new Object({
            photo: result.uri,
          });
          array.push(obj);
        }

        // if function have any  image index
      } else {
        // for old product
        if (props.route?.params?.item) {
          let id = array[index]?.id;
          UpdateOldProductImages(id, result.uri);

          // for new product
        } else {
          array[index] = {
            ...array[index],
            photo: result.uri,
          };
        }
      }
      setImages(array);
    }
  };

  const openImagePicker = async (index) => {
    await ImagePicker.requestMediaLibraryPermissionsAsync();

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      aspect: [4, 3],

      quality: 0.8,
      base64: true,
    });

    console.log('result====>>', result);

    if (result.uri) {
      let array = [...images];

      // if function have not any index of image
      if (!index && index != 0) {
        // if function have index and also product is old then call api
        if (props.route?.params?.item) {
          UpdateOldProductImages(null, result.uri);

          // if function have index but product is new
        } else {
          let obj = new Object({
            photo: result.uri,
          });
          array.push(obj);
        }

        // if function have any  image index
      } else {
        // for old product
        if (props.route?.params?.item) {
          let id = array[index]?.id;
          UpdateOldProductImages(id, result.uri);

          // for new product
        } else {
          array[index] = {
            ...array[index],
            photo: result.uri,
          };
        }
      }
      setImages(array);
    }
  };

  useEffect(() => {
    item?.images?.length > 0 && setImages(item?.images);
  }, [item]);

  return (
    <View style={styles.container}>
      <MainHeader
        title={
          item?.id
            ? strings('editServices.title.edit')
            : strings('editServices.title.add')
        }
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer>
        <KeyboardAwareScrollView>
          <>
            <View style={styles.productStrengthContainer}>
              {images.length > 0 ? (
                <ScrollView
                  showsHorizontalScrollIndicator={false}
                  horizontal
                  centerContent={true}
                >
                  {images.map((o, index) =>
                    imageLoading && imageLoadingIndex == index ? (
                      <TouchableOpacity
                        style={[
                          styles.imageContainer,
                          {
                            width: 200,
                            aspectRatio: 4 / 3,
                          },
                        ]}
                        onPress={() => openActionSheet(index)}
                        key={index}
                      >
                        <ActivityIndicator
                          size="small"
                          color={Colors.theme_color}
                        />
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity
                        style={[
                          styles.imageContainer,
                          {
                            width: 200,
                            aspectRatio: 4 / 3,
                          },
                        ]}
                        onPress={() => openActionSheet(index)}
                        key={index}
                      >
                        <View>
                          <Image
                            source={{uri: o.photo}}
                            style={[
                              styles.image,
                              {
                                width: 200,
                                aspectRatio: 4 / 3,
                              },
                            ]}
                          />
                          <TouchableOpacity
                            style={styles.cameraIcon}
                            onPress={() => openActionSheet(index)}
                          >
                            <Icon name="camera" color={Colors.black20} />
                          </TouchableOpacity>
                        </View>
                      </TouchableOpacity>
                    ),
                  )}
                  {images.length < 8 && (
                    <TouchableOpacity
                      style={[
                        styles.imageContainer,
                        {
                          width: 200,
                          aspectRatio: 4 / 3,
                        },
                      ]}
                      onPress={() => openActionSheet(undefined)}
                    >
                      <Icon name="add" size={16} color={Colors.black60} />
                    </TouchableOpacity>
                  )}
                </ScrollView>
              ) : (
                <TouchableOpacity
                  style={[
                    styles.imageContainer,
                    {
                      width: 200,
                      aspectRatio: 4 / 3,
                    },
                  ]}
                  onPress={() => openActionSheet(undefined)}
                >
                  <Icon name="add" size={16} color={Colors.black60} />
                </TouchableOpacity>
              )}
            </View>

            <AppConfigCheckbox
              title={'Service is active'}
              desc={'Please check if service is active'}
              checked={servicesData?.is_active}
              onChangeCheck={(checked) => {
                setServicesData({
                  ...servicesData,
                  is_active: checked,
                });
              }}
            />

            <View>
              <View style={styles.textInputContainer}>
                <AppText style={styles.inputLabel} required>
                  {strings('editServices.label.serviceName')}
                </AppText>
                <TextInput
                  underlineColorAndroid="transparent"
                  autoCorrect={false}
                  placeholder={strings('editServices.placeholder.serviceName')}
                  placeholderTextColor={Colors.color30}
                  returnKeyType="done"
                  style={
                    Platform.OS == 'web'
                      ? styles.textInputWeb
                      : styles.textInput
                  }
                  value={servicesData.name}
                  onChangeText={(text) =>
                    setServicesData({...servicesData, name: text})
                  }
                />
              </View>
              <View
                style={[styles.textInputContainer, {minHeight: setValue(90)}]}
              >
                <AppText style={styles.inputLabel}>
                  {strings('editServices.placeholder.description')}
                </AppText>
                <TextInput
                  underlineColorAndroid="transparent"
                  autoCorrect={false}
                  multiline={true}
                  numberOfLines={3}
                  placeholder={strings('editServices.placeholder.description')}
                  placeholderTextColor={Colors.color30}
                  returnKeyType="done"
                  style={
                    Platform.OS == 'web'
                      ? styles.textInputWeb
                      : styles.multiLineTextInput
                  }
                  value={servicesData.description}
                  onChangeText={(text) =>
                    setServicesData({...servicesData, description: text})
                  }
                />
              </View>

              <View style={styles.textInputContainer}>
                <AppText style={styles.inputLabel} required>
                  {strings('editServices.label.price')}
                </AppText>
                <TextInput
                  underlineColorAndroid="transparent"
                  autoCorrect={false}
                  placeholder={strings('editServices.placeholder.price')}
                  keyboardType={
                    Platform.OS === 'ios'
                      ? 'numbers-and-punctuation'
                      : 'decimal-pad'
                  }
                  placeholderTextColor={Colors.color30}
                  returnKeyType="done"
                  style={
                    Platform.OS == 'web'
                      ? styles.textInputWeb
                      : styles.textInput
                  }
                  value={servicesData.price}
                  onChangeText={(text) =>
                    setServicesData({...servicesData, price: text})
                  }
                />
              </View>

              <View style={styles.textInputContainer}>
                <AppText style={styles.inputLabel}>
                  {strings('editServices.label.discount') + '  (%)'}
                </AppText>
                <TextInput
                  underlineColorAndroid="transparent"
                  autoCorrect={false}
                  placeholder={strings('editServices.placeholder.discount')}
                  keyboardType={
                    Platform.OS === 'ios'
                      ? 'numbers-and-punctuation'
                      : 'decimal-pad'
                  }
                  placeholderTextColor={Colors.color30}
                  returnKeyType="done"
                  style={
                    Platform.OS == 'web'
                      ? styles.textInputWeb
                      : styles.textInput
                  }
                  value={servicesData.discount}
                  onChangeText={(text) =>
                    setServicesData({...servicesData, discount: text})
                  }
                />
              </View>

              <View style={styles.textInputContainer}>
                <AppText style={styles.inputLabel} required>
                  {strings('editServices.label.tax') + ' (%)'}
                </AppText>
                <TextInput
                  underlineColorAndroid="transparent"
                  autoCorrect={false}
                  keyboardType="numeric"
                  placeholder={strings('editServices.placeholder.tax') + ' (%)'}
                  placeholderTextColor={Colors.color30}
                  returnKeyType="done"
                  style={
                    Platform.OS == 'web'
                      ? styles.textInputWeb
                      : styles.multiLineTextInput
                  }
                  value={servicesData.tax}
                  onChangeText={(text) =>
                    setServicesData({...servicesData, tax: text})
                  }
                />
              </View>

              <View style={styles.textInputContainer}>
                <TouchableOpacity
                  onPress={() => {
                    setIsTimeVisible(true);
                  }}
                >
                  <AppText style={styles.inputLabel} required>
                    {strings('editServices.label.requiredTime')}
                  </AppText>
                  <View style={styles.calendarContainer}>
                    {item?.id ? (
                      <AppText style={styles.date}>
                        {servicesData?.required_time.split(':')[0] +
                          ':' +
                          servicesData?.required_time.split(':')[1]}
                      </AppText>
                    ) : (
                      <AppText style={styles.date}>
                        {(selectedTime?.selectedHour
                          ? selectedTime?.selectedHour
                          : '00') +
                          ':' +
                          (selectedTime?.selectedMinute
                            ? selectedTime?.selectedMinute
                            : '00')}
                      </AppText>
                    )}
                    <TouchableOpacity
                      onPress={() => {
                        setIsTimeVisible(true);
                      }}
                    >
                      <Icon name="clock" size={18} color="gray" />
                    </TouchableOpacity>
                  </View>
                </TouchableOpacity>
              </View>

              <TimePicker
                visible={isTimeVisible}
                onClose={() => setIsTimeVisible(false)}
                onTimeChanged={(timeValue) => {
                  console.log('timeValue-->.', timeValue);
                  const time =
                    timeValue?.selectedHour +
                    ':' +
                    timeValue?.selectedMinute +
                    ':' +
                    '00';
                  setServicesData({...servicesData, required_time: time});
                  console.log('call', timeValue);
                  setSelectedTime(timeValue);
                  const event = new Date();
                  event.setHours(
                    timeValue.selectedHour,
                    timeValue.selectedMinute,
                  );
                  console.log('event', event);
                  console.log('event', event.toISOString());
                  setIsTimeVisible(false);
                }}
                initValue={''}
                okBtnText={'Ok'}
              />

              <View style={styles.textInputContainer}>
                <AppText style={styles.inputLabel} required>
                  {strings('editServices.label.type')}
                </AppText>
                <DropDownInput
                  data={[
                    {value: 'onetime', label: 'OneTime'},
                    {value: 'quarterly', label: 'Quarterly'},
                    {value: 'monthly', label: 'Monthly'},
                    {value: 'yearly', label: 'Yearly'},
                  ]}
                  value={servicesData?.type}
                  inputStyle={{
                    paddingHorizontal: 10,
                    marginVertical: Platform.OS == 'web' ? 5 : 7,
                  }}
                  onChangeText={(itemValue) => {
                    console.log('itemValue--->>', itemValue);
                    setServicesData({...servicesData, type: itemValue});
                  }}
                />
              </View>

              <View style={styles.textInputContainer}>
                <AppText style={styles.inputLabel} required>
                  {strings('editProductScreen.label.category')}
                </AppText>
                <DropDownInput
                  data={serviceCategoryList?.map((o) => ({
                    label: o?.name,
                    value: o?.id,
                  }))}
                  value={servicesData?.category}
                  inputStyle={[
                    styles.textInput,
                    {
                      paddingHorizontal: 10,
                      marginTop: 10,
                    },
                  ]}
                  onChangeText={(text) => {
                    setServicesData({...servicesData, category: text});
                  }}
                />
              </View>
            </View>
          </>

          {Platform.OS == 'web' && (
            <LongButton
              onPress={() => onSave()}
              loading={false}
              text={
                item?.id
                  ? strings('addNewGallery.edit')
                  : strings('editProductCategory.button.save')
              }
              style={{
                marginTop: setValue(20),
                marginBottom: setValue(45),
              }}
            />
          )}
        </KeyboardAwareScrollView>
      </MainContainer>

      {Platform.OS !== 'web' && (
        <LongButton
          onPress={() => onSave()}
          loading={false}
          text={
            item?.id
              ? strings('addNewGallery.edit')
              : strings('editProductCategory.button.save')
          }
          style={{borderRadius: 0, width: '100%'}}
        />
      )}
    </View>
  );
};
const ConnectedEditServices = connectActionSheet(EditServicesScreen);

export default connect(
  (state) => ({
    config: state.config,
    categories: state.services.servicesCategories,
    common: state.common,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setLoading: CommonActions.setLoading,
        openAlert: (content, leftButton, leftPress, rightButton, rightPress) =>
          CommonActions.setAlert({
            visible: true,
            content,
            leftButton,
            leftPress,
            rightButton,
            rightPress,
          }),
        getServices,
        onUpdateServices,
        onCreateServices,
      },
      dispatch,
    ),
)(ConnectedEditServices);
