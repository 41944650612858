import {Platform, StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {
  screenHeight,
  setValue,
  setXAxisValue,
  setYAxisValue,
} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 2,
};

export default StyleSheet.create({
  container: {
    // flex: Platform.OS != 'web' ? 1 : null,
    flex: 1,
    // height:  screenHeight - setValue(200),
  },
  mainContainer: {
    flex: Platform.OS != 'web' ? 1 : null,
    height: '100%',
    maxHeight: Platform.OS != 'web' ? null : screenHeight - setValue(80),
    padding: setValue(5),
    backgroundColor: '#aaa',
    maxWidth: 1024,
    width: '100%',
    alignSelf: 'center',
  },
  headerContainer: {
    // flex: 1,
    flexDirection: 'row',
  },
  headerImage: {
    width: setValue(40),
    height: setValue(40),
    borderRadius: setValue(100),
    borderWidth: 1,
    padding: setValue(1),
    borderColor: Colors.black60,
  },
  headerTitle: {
    fontFamily: 'Roboto-Bold',
    fontSize: setValue(16),
    color: Colors.white,
  },
  headerStatus: {
    fontSize: setValue(14),
    color: Colors.color50,
  },
  chatContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  date: {
    backgroundColor: Colors.primary_color,
    color: Colors.black40,
    margin: setValue(2),
    paddingVertical: setValue(5),
    paddingHorizontal: setValue(10),
    textAlign: 'center',
    borderRadius: setValue(5),
    ...shadow,
  },
  boubleCorner:
    Platform.OS == 'ios'
      ? {
          // borderWidth: 6,
          position: 'absolute',
          borderColor: Colors.white,
          top: 0,
          width: 0,
          height: 0,
          borderTopColor: Colors.white,
          borderBottomWidth: 0,
          // borderBottomColor: 'rgba(0,0,0,0)',
        }
      : {
          borderWidth: 6,
          position: 'absolute',
          top: 0,
          width: 0,
          height: 0,
          borderTopColor: Colors.white,
          borderBottomColor: 'rgba(0,0,0,0)',
        },
  boubleCornerLeft: {
    borderLeftColor: 'rgba(0,0,0,0)',
    borderRightColor: Colors.white,
    left: setValue(-5),
  },
  boubleCornerRight: {
    borderLeftColor: Colors.white,
    borderRightColor: 'rgba(0,0,0,0)',
    right: setValue(-5),
  },
  chatItem:
    Platform.OS == 'ios'
      ? {
          maxWidth: '75%',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          backgroundColor: Colors.white,
          color: Colors.black,
          margin: setValue(5),
          paddingVertical: setValue(7),
          paddingHorizontal: setValue(10),
          alignSelf: 'flex-end',
          borderRadius: setValue(30),
          ...shadow,
        }
      : {
          maxWidth: '75%',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          backgroundColor: Colors.white,
          color: Colors.black,
          margin: setValue(5),
          paddingVertical: setValue(7),
          paddingHorizontal: setValue(10),
          alignSelf: 'flex-end',
          borderRadius: setValue(5),
          ...shadow,
        },
  reciver: {
    alignSelf: 'flex-start',
  },
  sender: {
    alignSelf: 'flex-end',
  },
  timeAndTick: {
    paddingLeft: setValue(5),
    marginBottom: setValue(-2),
    fontSize: setValue(10),
    alignSelf: 'flex-end',
    color: Colors.black40,
  },
  textInputContainer: {
    flex: 1,
    borderRadius: setValue(30),
    backgroundColor: Colors.white_cream,
    padding: setValue(5),
    flexDirection: 'row',
    margin: setValue(2),
    alignItems: 'flex-end',
  },
  textInput: {
    alignSelf: 'center',
    maxHeight: setValue(100),
    flex: 1,
    padding: setValue(5),
  },
  icons: {
    padding: setValue(8),
  },
  sendButton: {
    alignSelf: 'flex-end',
    borderRadius: setValue(100),
    backgroundColor: Colors.theme_color,
    padding: setValue(14),
    margin: setValue(2),
  },
});
