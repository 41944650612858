import {Platform, StyleSheet} from 'react-native';
import {Colors} from '../../../constants';
import {setValue} from '../../../utils';

const shadow = {
  shadowColor: Colors.black60,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 3,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  mainContainer: {
    flex: Platform.OS != 'web' ? 1 : null,
  },
  itemContainer: {
    marginVertical: 5,
    marginHorizontal: 3,
    flexDirection: 'row',
    backgroundColor: Colors.white,
    borderRadius: 5,
    ...shadow,
  },
  categoryContainer: {
    backgroundColor: Colors.white,
    // marginHorizontal: setValue(5),
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    padding: 15,
  },
  categoryDetailContainer: {
    justifyContent: 'center',
    flex: 1,
  },
  categoryImages: {
    justifyContent: 'center',
    alignItems: 'center',
    width: setValue(55),
    height: setValue(55),
  },
  categoryName: {
    color: Colors.black60,
    fontSize: setValue(16),
    marginLeft: setValue(8),
    fontFamily: 'Roboto-Bold',
  },
  categoryDescripation: {
    color: Colors.black60,
    fontSize: setValue(14),
    marginLeft: setValue(8),
  },
  image: {
    width: setValue(65),
    height: setValue(65),
    overflow: 'hidden',
    borderRadius: 55,
    // borderWidth: 1,
    // borderColor: Colors.theme_color,
  },
  categoryImage: {
    width: setValue(55),
    height: setValue(55),
  },
  buttonContainer: {
    flexDirection: 'row',
    paddingLeft: setValue(5),
  },
  button: {
    width: setValue(33),
    height: setValue(33),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.theme_color,
    marginVertical: setValue(5),
    marginHorizontal: setValue(5),
    borderRadius: setValue(40),
  },
});
