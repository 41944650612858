import { Platform, StyleSheet } from 'react-native';

import { Colors, Layout } from '../../constants';
import { setValue, setXAxisValue, setYAxisValue } from '../../utils';

const wrapper = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 8,
  padding: setValue(10),
  backgroundColor: Colors.secondary_color,
  borderRadius: 8,
  marginBottom: setYAxisValue(15)
};

export default StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    flex: 1,
    backgroundColor: Colors.theme_color
  },
  crossIcon: {
    position: 'absolute',
    top: Platform.select({ web: setYAxisValue(20), default: 20 }),
    right: setXAxisValue(20)
  },
  webView: {
    borderRadius: 8,
    backgroundColor: 'transparent'
  },
  webViewContainer: {
    ...wrapper,
    height: Layout.window.height * 0.75
  }
});
