/* eslint-disable import/no-unresolved */
// import Constants from 'expo-constants';

// const {extra, slug} = Constants.manifest;

// let logo;
// let order;

// switch (slug) {
//   case 'demo-retail':
//     logo = require('../../assets/images/demo-retail/logo.png');
//     break;
//   case 'demo-restaurants':
//     logo = require('../../assets/images/demo-restaurants/logo.png');
//     break;
//   case 'quickcutsales':
//     logo = require('../../assets/images/quickcutsales/logo.png');
//     break;
//   case 'medical':
//     logo = require('../../assets/images/medical/logo.png');
//     break;
//   case 'clothing':
//     logo = require('../../assets/images/clothing/logo.png');
//     break;
//   case 'nilkanthtechnology':
//     logo = require('../../assets/images/nilkanthtechnology/logo.png');
//     break;
//   case 'partsportal':
//     logo = require('../../assets/images/partsportal/logo.png');
//     break;
//   case 'electronics':
//     logo = require('../../assets/images/electronics/logo.png');
//     break;
//   default:
//     logo = require('../../assets/images/demo-retail/logo.png');
//     break;
// }

export default {
  rocket: require('../../assets/images/General/rocket.png'),
  logo: require('../../assets/images/brand/logo.png'),
  profile: require('../../assets/images/General/profile.png'),
  order: require('../../assets/images/General/order.png'),
  signUp: require('../../assets/images/General/signup.png'),
  noInternet: require('../../assets/images/General/no-internet.png'),
  noWifi: require('../../assets/images/General/no-wifi.png'),
};
