import React, {useState, useEffect} from 'react';
import {
  Text,
  Platform,
  Modal,
  View,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Image,
} from 'react-native';
import styles from './styles';
import {strings} from '../../i18n';
import {AppText, Icon} from '../';
import {Colors} from '../../constants';
import DropDownInput from '../DropDownInput';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import axios from 'axios';
import {connectActionSheet} from '@expo/react-native-action-sheet';
import {connect} from 'react-redux';
import * as ImagePicker from 'expo-image-picker';
import {bindActionCreators} from 'redux';

const EditProductImageModal = (props) => {
  const {isVisible, onClose, productId, variantList} = props;
  console.log('isVisible', isVisible);

  const [images, setImages] = useState(null);
  const [variant, setVariant] = useState(null);
  const [selectedVariantType, setSelectedVariantType] = useState(null);

  const textColor = Colors.white;

  let variantArray = [];
  variantList?.map(
    (item) =>
      item?.variant_type?.id == selectedVariantType &&
      item?.variants.map((o) =>
      variantArray.push({label: o?.variant?.name, value: o?.id}),
      ),
  );
  let variantTypeArray = [];
  variantList?.map((item) =>
  variantTypeArray.push({
      label: item?.variant_type?.type,
      value: item?.variant_type?.id,
    }),
  );
  useEffect(() => {
    isVisible?.item != null && setImages({uri: isVisible?.item?.photo});
    setVariant(isVisible?.item?.variant);
  }, [isVisible?.item]);

  const openActionSheet = () => {
    const options = [
      strings('actionSheet.takePhoto'),
      strings('actionSheet.choosePhoto'),
      strings('actionSheet.removePhoto'),
      strings('actionSheet.cancel'),
    ];
    const cancelButtonIndex = 3;

    props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        useModal: true,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) openCamera();
        if (buttonIndex === 1) openImagePicker();
        if (buttonIndex === 2) removePhoto();
      },
    );
  };

  const removePhoto = () => {
    props.onClose();
    props.openAlert(
      strings('editProductScreen.alert.askImageDelete'),
      strings('settingScreen.confirm'),
      () => deleteImage(isVisible?.item?.id),
      strings('settingScreen.cancel'),
      null,
    );
    setImages(null);
  };

  const openCamera = async () => {
    await ImagePicker.requestCameraPermissionsAsync();

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: [4 , 3],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      setImages(result);
    }

    if (!result.cancelled) {
      // changeProfilePicture(result);
    }
  };

  const openImagePicker = async () => {
    await ImagePicker.requestMediaLibraryPermissionsAsync();

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      setImages(result);
    }

    if (!result.cancelled) {
    }
  };

  const dataURLtoFile = (dataurl) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], 'image1.jpg', {type: mime});
  };

  const saveNewProductImages = async () => {
    let body = new FormData();
    body.append('product', productId);
    body.append('variant', variant);

    if (images?.uri && images?.uri?.slice(0, 4) != 'http') {
      if (Platform.OS == 'web') {
        body.append('photo', dataURLtoFile(images?.uri));
      } else {
        let blob = await (await fetch(images?.uri)).blob();
        body.append('photo', {
          uri: images?.uri,
          type: blob.type,
          name: blob.data.name,
        });
      }
    } else if (!images?.uri) {
      body.append('photo', '');
    }
    console.log('body', body);

    return axios
      .post('/products/images/create', body)
      .then((response) => {
        console.log('res-->', response);
        // props.getProducts();
        // props.setLoading(false);
        goBack(navigation);
      })
      .catch((error) => {
        console.log('error -->', error?.response);
        props.openAlert(error?.response?.message);
        props.setLoading(false);
      });
  };

  const UpdateOldProductImages = async (id, url) => {
    if (isVisible?.item) {
      let body = new FormData();
      body.append('product', productId);
      // body.append('variant', variant);
      if (variant != null) {
        body.append('variant', variant);
      }
      if (images?.uri && images?.uri?.slice(0, 4) != 'http') {
        if (Platform.OS == 'web') {
          body.append('photo', dataURLtoFile(images?.uri));
        } else {
          let blob = await (await fetch(images?.uri)).blob();
          body.append('photo', {
            uri: images?.uri,
            type: blob.type,
            name: blob.data.name,
          });
        }
      } else if (!images?.uri) {
        body.append('photo', '');
      }

      console.log('body-->', body);

      axios
        .put('/products/images/update/' + isVisible?.item?.id, body)
        .then((response) => {
          console.log('products image update response', response.data);
          props.onSave();
        })
        .catch((error) => {
          console.log('products image update error -->', error?.response);
          props.openAlert(error?.response?.message);
          props.setLoading(false);
          props.onClose();
        });
    } else {
      let body = new FormData();
      body.append('product', productId);
      // body.append('variant', variant);
      if (variant != null) {
        body.append('variant', variant);
      }
      if (images?.uri && images?.uri?.slice(0, 4) != 'http') {
        if (Platform.OS == 'web') {
          body.append('photo', dataURLtoFile(images?.uri));
        } else {
          let blob = await (await fetch(images?.uri)).blob();
          body.append('photo', {
            uri: images?.uri,
            type: blob.type,
            name: blob.data.name,
          });
        }
      } else if (!images?.uri) {
        body.append('photo', '');
      }
      console.log('body', body);
      axios
        .post('/products/images/create', body)
        .then((response) => {
          console.log(response);
          props.onSave();
        })
        .catch((error) => {
          console.log('error -->', error?.response);
          props.openAlert(error?.response?.message);
          props.setLoading(false);
          props.onClose();
        });
    }
  };

  const deleteImage = (id) => {
    axios
      .delete('/products/images/delete/' + id)
      .then((response) => {
        console.log('response delete image', response);
        // array.splice(index, 1);
        setImages(null);
      })
      .catch((error) => {
        console.log('error delete image', error?.response?.data);
        props.openAlert(error?.response?.data?.detail);
      });
  };

  return (
    <Modal animationType={'fade'} transparent visible={isVisible?.show}>
      <View style={styles.modal}>
        <View style={styles.modalContainer}>
          <View style={styles.modalDataContainer}>
            <Text style={styles.modalTitle}>{'Create New Product Image'}</Text>
          </View>

          <View style={styles.productStrengthContainer}>
            <TouchableOpacity
              style={styles.imageContainer}
              onPress={openActionSheet}
            >
              {!images ? (
                <Icon name="add" size={16} color={Colors.black60} />
              ) : (
                <View>
                  <Image source={{uri: images?.uri}} style={styles.image} />
                  <TouchableOpacity
                    style={styles.cameraIcon}
                    onPress={openActionSheet}
                  >
                    <Icon name="camera" color={Colors.black20} />
                  </TouchableOpacity>
                </View>
              )}
            </TouchableOpacity>
          </View>

          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>{'Variant Type'}</AppText>
            <DropDownInput
              data={variantTypeArray}
              value={selectedVariantType}
              inputStyle={[
                {
                  paddingHorizontal: 10,
                  marginTop: 10,
                },
              ]}
              placeholder={'Select variant '}
              onChangeText={(text) => {
                setSelectedVariantType(text);
              }}
            />
          </View>
          {selectedVariantType && (
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>{'Variant '}</AppText>
              <DropDownInput
                data={variantArray}
                value={variant}
                inputStyle={[
                  {
                    paddingHorizontal: 10,
                    marginTop: 10,
                  },
                ]}
                placeholder={'Select variant '}
                onChangeText={(text) => {
                  setVariant(text);
                }}
              />
            </View>
          )}

          <View style={styles.buttonContainer}>
            <TouchableOpacity
              style={[styles.addQuantityButton, {flex: 3}]}
              onPress={() => {
                UpdateOldProductImages();
                onClose();
                setImages(null);
              }}
            >
              <Text style={{color: textColor}}>{'Save'}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.addQuantityButton, {flex: 2}]}
              onPress={() => {
                onClose();
                setImages(null);
              }}
            >
              <Text style={{color: textColor}}>
                {strings('selectVeriant.cancel')}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const ConnectedEditProductImageModal = connectActionSheet(
  EditProductImageModal,
);
export default connect(
  (state) => ({
    config: state.config,
    categories: state.products.categories,
    common: state.common,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setLoading: CommonActions.setLoading,
        openAlert: (content, leftButton, leftPress, rightButton, rightPress) =>
          CommonActions.setAlert({
            visible: true,
            content,
            leftButton,
            leftPress,
            rightButton,
            rightPress,
          }),
      },
      dispatch,
    ),
)(ConnectedEditProductImageModal);
// export default connect()(ConnectedEditProductImageModal);
