import React from 'react';
import {Image, TouchableOpacity, View} from 'react-native';
import {AppText, Icon} from '../../components';
import styles from './styles';
import {Colors, Images} from '../../constants';
import {goBack, setValue} from '../../utils';

const PendingApprovement = (props) => {
  return (
    <View style={styles.safeArea}>
      <View style={styles.container}>
        <Image source={Images.logo} style={styles.imageAvatar} />
        <AppText style={styles.title}>Verfication Required </AppText>
        <AppText style={styles.description}>
          Account is not verified by merchant. Please wait until active your
          account :)
        </AppText>
        <View style={{marginTop: setValue(50), width: '40%'}}>
          <TouchableOpacity
            onPress={() => {
              goBack(props.navigation);
            }}
            style={styles.closeBtn}
          >
            <AppText style={styles.iconText}>
              <Icon name="arrowLeft" size={8} color={Colors.theme_color} />
            </AppText>
            <AppText style={styles.closeBtnText}>Go Back</AppText>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default PendingApprovement;
