import React, {useEffect, useState} from 'react';
import {
  FlatList,
  Keyboard,
  Platform,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import {
  AppText,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
  NoRecordFound,
  DropDownInput,
} from '../../components';
import {Colors} from '../../constants';
import {goBack} from '../../utils';
import styles from './styles';
import {connectActionSheet} from '@expo/react-native-action-sheet';
import {connect, useDispatch} from 'react-redux';
import {strings} from '../../i18n';
import {bindActionCreators} from 'redux';
import axios from 'axios';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {useSelector} from 'react-redux';
import Icon4 from '@expo/vector-icons/FontAwesome';
import {setValue} from '../../utils';
import {useIsFocused} from '@react-navigation/native';

const EditVariantTypes = (props) => {
  let item = props?.route?.params?.item;
  const config = useSelector((state) => state.config);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [variantList, setVariantList] = useState([]);
  const [variantTypesDetail, setVariantTypesDetail] = useState({
    type: item?.type ? item?.type : '',
    category: item?.category ? item?.category : null,
    sub_category: item?.sub_category ? item?.sub_category : null,
    brand: item?.brand ? item?.brand : null,
  });

  const onSave = async () => {
    dispatch(CommonActions.setLoading(true));
    Keyboard.dismiss();

    if (variantTypesDetail.type == '') {
      props.setLoading(false);
      props.setAlert({
        visible: true,
        content: 'type is required',
      });
      return;
    }

    let body = new FormData();
    body.append('type', variantTypesDetail?.type);
    variantTypesDetail?.category &&
      body.append('category', variantTypesDetail?.category);
    variantTypesDetail.sub_category &&
      body.append('sub_category', variantTypesDetail.sub_category);
    variantTypesDetail.brand && body.append('brand', variantTypesDetail.brand);

    const header = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    console.log('body', body);
    if (item?.id) {
      await axios
        .patch('/products/variant-type/update/' + item?.id, body, header)
        .then((response) => {
          console.log('response of update variant type', response);
          goBack(navigation);
          dispatch(CommonActions.setLoading(false));
        })
        .catch((error) => {
          props.setAlert({
            visible: true,
            content: error?.response?.message,
          });
          dispatch(CommonActions.setLoading(false));
        });
    } else {
      await axios
        .post('products/variant-type/create', body, header)
        .then((response) => {
          console.log('response', response);
          goBack(navigation);
          dispatch(CommonActions.setLoading(false));
        })
        .catch((error) => {
          console.log('error', error.response);
          props.setAlert({
            visible: true,
            content: error?.response?.message,
          });
          dispatch(CommonActions.setLoading(false));
        });
    }
  };

  const getCategoryList = () => {
    dispatch(CommonActions.setLoading(true));
    axios
      .get('/products/product-category/list/' + config?.businessId)
      .then((response) => {
        console.log('response of category list===', response);
        setCategoryList(response?.data);
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        console.log('error in response of category list===', error);
        dispatch(CommonActions.setLoading(false));
      });
  };

  const getBrandList = () => {
    dispatch(CommonActions.setLoading(true));
    axios
      .get('/products/product-brand/list/' + config?.businessId)
      .then((response) => {
        console.log('response of brand list===', response);
        setBrandList(response?.data);
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        console.log('error in response of brand list===', error);
        dispatch(CommonActions.setLoading(false));
      });
  };

  const getSubCategoryList = (categoryId) => {
    console.log('categoryId---', categoryId);
    dispatch(CommonActions.setLoading(true));
    axios
      .get(
        '/products/product-sub-category/list/' +
          config?.businessId +
          '/' +
          categoryId,
      )
      .then((response) => {
        console.log('response of sub category list===', response);
        setSubCategoryList(response?.data);
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        console.log('error in response of sub category list===', error);
        dispatch(CommonActions.setLoading(false));
      });
  };

  const getVariantList = () => {
    dispatch(CommonActions.setLoading(true));
    axios
      .get('/products/variant/list/' + config?.businessId)
      .then((response) => {
        console.log('response of variant list===', response);
        let array = [];
        if (['merchant', 'manager'].includes(user.type)) {
          array = [
            {static: true},
            ...response.data.filter(
              (item) => item.variant_type == props.route.params?.item?.id,
            ),
          ];
        } else {
          array = [
            ...response.data.filter(
              (item) => item.variant_type == props.route.params?.item?.id,
            ),
          ];
        }
        setVariantList(array);
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        console.log('error in response of variant list===', error);
        dispatch(CommonActions.setLoading(false));
      });
  };

  const deleteVariant = async (id) => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios.delete('/products/variant/delete/' + id).then((response) => {
        console.log('response --> ', response);
        getVariantList();
        dispatch(CommonActions.setLoading(false));
      });
    } catch (error) {
      dispatch(CommonActions.setLoading(false));
      console.log('message', error?.response?.message);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };

  useEffect(() => {
    getCategoryList();
    getBrandList();
    isFocused && getVariantList();
  }, [isFocused]);

  useEffect(() => {
    variantTypesDetail?.category &&
      getSubCategoryList(variantTypesDetail?.category);
  }, [variantTypesDetail?.category]);

  return (
    <View style={styles.container}>
      <MainHeader
        title={item?.id ? 'Edit Variant Types' : 'Add Variant Types'}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer>
        <View>
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel} required>
              {'Type'}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              // autoCapitalize="words"
              autoCorrect={false}
              placeholder={strings('editProductCategory.placeholder.name')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
              value={variantTypesDetail.type}
              onChangeText={(text) =>
                setVariantTypesDetail({...variantTypesDetail, type: text})
              }
            />
          </View>
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('editProductScreen.label.category')}
            </AppText>
            <DropDownInput
              data={categoryList?.map((o) => ({
                label: o?.name,
                value: o?.id,
              }))}
              value={variantTypesDetail?.category}
              inputStyle={{
                paddingHorizontal: 10,
                marginVertical: Platform.OS == 'web' ? 0 : 10,
              }}
              onChangeText={(text) => {
                setVariantTypesDetail({...variantTypesDetail, category: text});
              }}
            />
          </View>

          {subCategoryList.length != 0 && (
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>{'Sub Category'}</AppText>
              <DropDownInput
                data={subCategoryList?.map((o) => ({
                  label: o?.name,
                  value: o?.id,
                }))}
                value={variantTypesDetail?.sub_category}
                inputStyle={{
                  paddingHorizontal: 10,
                  marginVertical: Platform.OS == 'web' ? 0 : 10,
                }}
                onChangeText={(text) => {
                  setVariantTypesDetail({
                    ...variantTypesDetail,
                    sub_category: text,
                  });
                }}
              />
            </View>
          )}

          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>{'Brand'}</AppText>
            <DropDownInput
              data={brandList?.map((o) => ({
                label: o?.name,
                value: o?.id,
              }))}
              value={variantTypesDetail?.brand}
              inputStyle={{
                paddingHorizontal: 10,
                marginVertical: Platform.OS == 'web' ? 0 : 10,
              }}
              onChangeText={(text) => {
                setVariantTypesDetail({...variantTypesDetail, brand: text});
              }}
            />
          </View>
        </View>

        {props.route.params?.item?.id && (
          <View style={styles.subCategoryConatiner}>
            <AppText style={styles.subCatogoryTitle}>{'Variant'}</AppText>
            {variantList?.length > 0 ? (
              <FlatList
                data={variantList}
                showsVerticalScrollIndicator={false}
                keyExtractor={(item, index) => index}
                renderItem={({item, index, isActive}) => {
                  if (item?.static) {
                    return (
                      <TouchableOpacity
                        key={index}
                        style={styles.staticItemContainer}
                        onPress={() =>
                          props.navigation.navigate('edit-variant', {
                            edit: false,
                            item: item,
                            variant_type: props.route.params?.item?.id,
                          })
                        }
                      >
                        <AppText style={styles.iconContainer}>
                          <Icon name="add" size={18} style={styles.iconStyle} />
                        </AppText>
                      </TouchableOpacity>
                    );
                  }
                  return (
                    <TouchableOpacity
                      // onLongPress={drag}
                      disabled={isActive}
                      style={styles.itemContainer}
                      key={item?.id}
                    >
                      <View style={styles.categoryDetailContainer}>
                        <AppText style={styles.categoryName} numberOfLines={2}>
                          {item?.name}
                        </AppText>
                        <View style={styles.buttonContainer}>
                          <TouchableOpacity
                            onPress={() =>
                              props.navigation.navigate('edit-variant', {
                                variant_type: props.route.params?.item?.id,
                                item: item,
                                edit: true,
                              })
                            }
                            style={styles.button}
                          >
                            <Icon
                              name="edit"
                              size={setValue(18)}
                              color="#fff"
                            />
                          </TouchableOpacity>
                          <TouchableOpacity
                            style={styles.button}
                            onPress={() => deleteVariant(item?.id)}
                          >
                            <Icon4
                              name="trash"
                              size={setValue(18)}
                              color="#fff"
                            />
                          </TouchableOpacity>
                        </View>
                      </View>
                    </TouchableOpacity>
                  );
                }}
                ListFooterComponent={() => <View style={{height: 50}}></View>}
              />
            ) : (
              <NoRecordFound
                title={strings('editProductCategory.norecord.title')}
                message={strings('editProductCategory.norecord.message')}
              />
            )}
          </View>
        )}
        {Platform.OS == 'web' && (
          <LongButton
            text={'Save'}
            onPress={onSave}
            style={{marginTop: 20, marginBottom: 50}}
          />
        )}
      </MainContainer>
      {Platform.OS !== 'web' && (
        <LongButton
          text={'Save'}
          style={{borderRadius: 0, width: '100%'}}
          onPress={onSave}
        />
      )}
    </View>
  );
};

const ConnectedEditProduct = connectActionSheet(EditVariantTypes);

export default connect(
  (state) => ({
    categories: state.products?.categories,
    user: state.auth?.user,
    config: state.config,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setLoading: CommonActions.setLoading,
        setAlert: CommonActions.setAlert,
      },
      dispatch,
    ),
)(ConnectedEditProduct);
