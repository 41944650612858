import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
  borderRadius: 5,
  backgroundColor: Colors.white,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  qrCodeScanner: {
    ...shadow,
    width: setValue(60),
    height: setValue(60),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.theme_color,
    borderRadius: setValue(60),
    position: 'absolute',
    zIndex: 10000,
    right: 10,
    bottom: 15,
  },
  transactionItem: {
    ...shadow,
    marginBottom: setYAxisValue(15),
    padding: setValue(8),
  },
  coin: {
    color: Colors.black60,
    fontFamily: 'Roboto-Bold',
    fontSize: setValue(18),
  },
  userName: {
    color: Colors.black60,
    fontFamily: 'Roboto-Bold',
    fontSize: setValue(15),
    textTransform: 'capitalize',
  },
});
