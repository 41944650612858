import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderColor: Colors.theme_color,
    borderWidth: 1,
    borderRadius: 5,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
  },
  control: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.white,
  },
  controlSelected: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.theme_color,
  },
  first: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  last: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  selectedTitle: {
    paddingTop: setYAxisValue(15),
    paddingBottom: setYAxisValue(3),
    fontSize: setXAxisValue(12),
    color: Colors.white,
    fontFamily: 'Roboto-Bold',
    borderBottomWidth: 2,
    borderBottomColor: Colors.white,
    marginBottom: setYAxisValue(10),
   
  },
  title: {
    color: Colors.black40,
    fontFamily: 'Roboto-Regular',
    paddingTop: setYAxisValue(15),
    paddingBottom: setYAxisValue(3),
    fontSize: setXAxisValue(12),
    borderBottomWidth: 2,
    borderBottomColor: Colors.white,
    marginBottom: setYAxisValue(10),
  },
});
