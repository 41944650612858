import {Appearance, Platform, StyleSheet} from 'react-native';
import {Colors, Layout} from '../../../constants';
import {setXAxisValue, setYAxisValue} from '../../../utils';
const colorScheme = Appearance.getColorScheme();
const backgroundColor = colorScheme == 'dark' ? Colors.white : Colors.white;
export default StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: Platform.OS === 'web' ? setYAxisValue(10) : 0,
    paddingHorizontal: setXAxisValue(
      Platform.select({ios: 15, android: 0, web: 0}),
    ),
    backgroundColor: backgroundColor,
  },
  webContainer: {
    // display: 'flex',
    height: 'calc(100vh - 35px)',
    overflow: 'scroll',
    paddingTop: setYAxisValue(10),
    paddingHorizontal: setXAxisValue(
      Platform.select({ios: 15, android: 0, web: 0}),
    ),
    backgroundColor: backgroundColor,
  },
  childrenContainer: {
    flex: 1,
    alignSelf: 'center',
    paddingHorizontal: setXAxisValue(
      Platform.select({ios: 0, android: 15, web: 15}),
    ),
    maxWidth: 400,
    width: '100%',
  },
  containerUseView:
    Platform.OS == 'web'
      ? {
          height: 'calc(100vh - 35px)',
          zIndex: -999,
          backgroundColor: backgroundColor,
        }
      : {
          flex: 1,
          zIndex: -999,
          backgroundColor: backgroundColor,
        },
  topOval: {
    flex: 1,
    backgroundColor: Colors.theme_color,
    width: Layout.window.width * 2,
    height: Layout.window.width * 2,
    borderRadius: Layout.window.width,
    top: -Layout.window.width * 1.5,
    alignSelf: 'center',
    position: 'absolute',
  },
  topOvalWallet: {
    flex: 1,
    backgroundColor: Colors.theme_color,
    width: Layout.window.width * 2,
    height: Layout.window.width * 2,
    borderRadius: Layout.window.width,
    top: -Layout.window.width * 1.2,
    alignSelf: 'center',
    position: 'absolute',
  },
  checkoutOrderingOvalWallet: {
    flex: 1,
    backgroundColor: Colors.theme_color,
    width: Layout.window.width * 2,
    height: Layout.window.width * 2,
    borderRadius: Layout.window.width,
    top: -Layout.window.width * 1.48,
    alignSelf: 'center',
    position: 'absolute',
  },
  logoImage: {
    marginVertical: setYAxisValue(40),
    width: setXAxisValue(120),
    height: setXAxisValue(120),
    alignSelf: 'center',
    borderRadius: 8,
  },
  viewOval: {
    top: -Layout.window.width * 1.8,
  },
});
