import { get } from 'lodash';
import moment from 'moment';
import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import {useSelector} from 'react-redux';
import AppText from '../AppText';
import styles from './styles';

const SubscriptionItem = (props) => {
  const {item} = props;

  const config = useSelector((state) => state?.config);
  const currencySymbol = get(config, 'currencySymbol', '₹');

  return (
    <TouchableOpacity
      onPress={() => {
        props.navigation.navigate('subscription-detail', {
          id: item.id,
        });
      }}
    >
      <View style={styles.itemContainer}>
        <View style={styles.topContainer}>
          <AppText style={styles.orderUId}>{'SUB00' + item.id}</AppText>
          <AppText>
            <AppText style={styles.orderUId}>
              {moment(item.created_at).format('DD MMM YYYY hh:mm A')}
            </AppText>
          </AppText>
        </View>
        <View style={styles.bottomContainer}>
          <AppText
            style={styles.orderPrice}
          >{`${currencySymbol} ${item.total}`}</AppText>
          <AppText
            style={
              item?.status === 'paid'
                ? [styles.orderPrice, {color: '#056eb0'}]
                : item?.status === 'accepted'
                ? [styles.orderPrice, {color: '#008c96'}]
                : item?.status === 'in-process'
                ? [styles.orderPrice, {color: '#f69c00'}]
                : item?.status === 'completed'
                ? [styles.orderPrice, {color: '#05a912'}]
                : styles.orderPrice
            }
          >
            {item?.status?.toUpperCase()}
          </AppText>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default SubscriptionItem;
