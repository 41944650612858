import {Platform, StyleSheet} from 'react-native';
import {Colors, Layout} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  serachContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchBarContainer: {
    flexDirection: 'row',
    height: setValue(50),
    backgroundColor: 'white',
    borderRadius: setValue(8),
    marginBottom: setYAxisValue(15),
  },
  searchBarText: {
    color: 'black',
    marginHorizontal: setXAxisValue(20),
    flex: 1,
  },
  searchBarIcon: {
    marginRight: setXAxisValue(10),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  addMenuButton: {
    marginBottom: setValue(10),
    padding: setValue(8),
    backgroundColor: 'white',
    borderRadius: setValue(5),
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 3,
  },
  categoryButtonIconContainer: {
    backgroundColor: Colors.white,
    borderRadius: setValue(10),
    width: setValue(60),
    height: setValue(60),
    marginRight: setXAxisValue(5),
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: setValue(3),
    borderLeftWidth: 0,
    borderColor: 'transparent',
  },
  categoryButtonIconDarkContainer: {
    backgroundColor: Colors.white,
    borderRadius: setValue(15),
    width: setValue(50),
    height: setValue(30),
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    borderWidth: Platform.OS == 'web' ? 1 : 0,
    borderColor: Colors.theme_color,
  },

  categoryImages: {
    width: setValue(50),
    justifyContent: 'center',
    height: setValue(50),
    alignItems: 'center',
  },
  categoryButtonText: {
    fontSize: setXAxisValue(12),
    textAlign: 'center',
    marginVertical: setYAxisValue(5),
    marginBottom: setYAxisValue(8),
    width: 60,
  },
  categoryImage: {
    width: setValue(60),
    height: setValue(60),
  },
  addMenuButtonText: {
    alignSelf: 'center',
    padding: 5,
    color: Platform.OS == 'web' ? Colors.theme_color : Colors.black,
  },
});
