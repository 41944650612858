import get from 'lodash.get';

export const goBack = (navigation) => {
  if (window.history) {
    window.history.back();
  } else {
    if (navigation.canGoBack()) {
      navigation.goBack();
    }
    else{
      navigation.replace('app')
    }
  }
};

export const getParam = (route, key, defaultValue = null) => {
  return get(route, `params.${key}`, defaultValue);
};
