import {Appearance, Platform, StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';
const colorScheme = Appearance.getColorScheme();
const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 5,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  customerProfileCard: {
    backgroundColor: Colors.white,
    padding: 7,
    flexDirection: 'row',
    borderRadius: 10,
    ...shadow,
  },
  imageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  image: {
    borderRadius: setValue(50),
    width: setValue(60),
    height: setValue(60),
  },
  customerDetailsContainer: {
    padding: 5,
  },
  customerName: {
    fontFamily: 'Roboto-Bold',
    fontSize: setValue(18),
    color: colorScheme == 'dark' ? Colors.white : Colors.black,
    marginBottom: 4,
  },
  customerInfo: {
    fontSize: setValue(14),
    color: colorScheme == 'dark' ? Colors.white_cream : Colors.black40,
  },
  customerAbout: {
    fontSize: setValue(12),
    color: colorScheme == 'dark' ? Colors.white_cream : Colors.black60,
    marginTop:5
    
  },
  callButton: {
    flex: 1,
    // maxWidth: Platform.OS == 'web' ? setValue(140) : null,
    marginTop:10,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: setValue(10),
    borderWidth: 2,
    borderColor: Colors.theme_color,
    backgroundColor: Colors.white,
    ...shadow,
  },
  callButtonOutline: {
    padding: 3,
  },
  callButtonText: {
    textAlign: 'center',
    textAlignVertical: 'center',
    paddingHorizontal: setValue(5),
    paddingVertical: setValue(10),
    color: Colors.white,
  },
  customerMoreContainer: {
    // flex: 1,
    // justifyContent: 'center',
    marginTop: 10,
  },
  customerMore: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  moreText: {
    fontFamily: 'Roboto-Bold',
    margin: 5,
    fontSize: setValue(14),
  },
  moreSubText: {
    color: Colors.black40,
    fontSize: setValue(12),
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  drpDownBackground: {
    backgroundColor: Colors.white,
    width: setValue(100),
    color: Colors.black60,
    fontSize: setValue(18),
  },
});
