import {Appearance, Platform, StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

const colorScheme = Appearance.getColorScheme();
const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 5,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  userId: {
    textAlign: 'center',
    padding: 20,
  },
  userProfileCard: {
    backgroundColor: Colors.white,
    padding: 7,
    flexDirection: 'row',
    borderRadius: 10,
  },
  imageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  image: {
    borderRadius: setValue(50),
    width: setValue(60),
    height: setValue(60),
  },
  userDetailsContainer: {
    padding: 5,
  },
  userName: {
    fontFamily: 'Roboto-Bold',
    fontSize: setValue(18),
    color: colorScheme == 'dark' ? Colors.white : Colors.black,
    marginBottom: 4,
  },
  userInfo: {
    fontSize: setValue(14),
    color: colorScheme == 'dark' ? Colors.white_cream : Colors.black40,
  },
  userAbout: {
    fontSize: setValue(12),
    color: colorScheme == 'dark' ? Colors.white_cream : Colors.black60,
    marginTop: 5,
  },
  textInputConatiner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  currencySymbol: {
    fontSize: setValue(55),
    color: Colors.color30,
    paddingHorizontal: 10,
  },
  textInput: {
    fontSize: setValue(60),
    color: Colors.color30,
    textAlign: 'center',
  },
  addNoteButtonContainer: {
    marginTop: 40,
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 5,

    alignItems: 'center',
  },
});
