import {Platform, StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {setValue, setXAxisValue} from '../../utils';

export default StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  subContainer: {
    maxWidth: 400,
    padding: Platform.OS == 'web' ? setValue(25) : setValue(15),
    borderWidth: Platform.OS == 'web' ? 2 : 0,
    borderColor: Colors.border,
    backgroundColor: Colors.white,
    borderRadius: 5,
  },
  question: {
    textAlign: 'center',
    marginBottom: setValue(20),
    fontSize: setValue(20),
    fontFamily: 'Roboto-Regular',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btn: {
    width: '49%',
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: setValue(10),
    borderRadius: setValue(5),
  },
  logoImage: {
    alignSelf: 'center',
    width: setXAxisValue(120),
    height: setXAxisValue(120),
    borderRadius: 8,
    marginBottom: setValue(15),
  },
});
