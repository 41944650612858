import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.theme_color,
    borderRadius: 20,
    paddingHorizontal: setXAxisValue(10),
    paddingVertical: setYAxisValue(5),
  },
  text: {
    fontSize: setXAxisValue(11),
    color: Colors.primary_color,
    // fontFamily: ''
  },
  wrapper: {flexDirection: 'row', flexWrap: 'wrap'},
});
