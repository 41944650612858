import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {screenWidth, setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 8,
  borderRadius: 5,
  backgroundColor: Colors.white,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  mainContainer: {
    flex:1,
    paddingTop: setValue(10),
  },
  notificationContainer: {
    padding:10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: Colors.white,
    ...shadow,
    paddingHorizontal: setValue(8),
    marginBottom: setYAxisValue(10),
  },
});
