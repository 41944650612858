import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {FlatList, Platform, View} from 'react-native';
import {useDispatch} from 'react-redux';
import {
  AppText,
  LongButton,
  MainContainer,
  MainHeader,
  SwipeRow,
} from '../../components';
import {goBack, setValue} from '../../utils';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import styles from './styles';
import {useIsFocused} from '@react-navigation/native';
import {strings} from '../../i18n';

const DeliveryPartnerConfig = (props) => {
  const [deliveryPartnerList, setDeliveryPartnerList] = useState([]);

  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const getPartnerList = () => {
    dispatch(CommonActions.setLoading(true));
    axios
      .get('/delivery/deliverypartnerconfig/list')
      .then((response) => {
        console.log('delivery partner list --', response);
        setDeliveryPartnerList(response?.data);
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        console.log('error delivery partner list --', error);
        dispatch(CommonActions.setLoading(false));
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.message || 'Something went wrong!',
          }),
        );
      });
  };

  useEffect(() => {
    getPartnerList();
  }, [isFocused]);

  const deletePartner = (id) => {
    dispatch(CommonActions.setLoading(true));
    axios
      .delete('/delivery/deliverypartnerconfig/delete/' + id)
      .then((response) => {
        console.log('delete delivery partner --', response);
        getPartnerList();
      })
      .catch((error) => {
        console.log('error delete delivery partner --', error);
        dispatch(CommonActions.setLoading(false));
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.message || 'Something went wrong!',
          }),
        );
      });
  };

  return (
    <View style={{flex: 1}}>
      <MainHeader
        title={'Delivery Partners'}
        leftIcon="back"
        leftPress={() => goBack(props?.navigation)}
      />
      <MainContainer>
        {deliveryPartnerList?.length > 0 && (
          <FlatList
            data={deliveryPartnerList}
            showsVerticalScrollIndicator={false}
            keyExtractor={(item, index) => index}
            renderItem={({item, index}) => (
              <SwipeRow
                key={index}
                editable={true}
                leftOpenValue={70}
                rightOpenValue={-100}
                onPressEdit={() => {
                  props.navigation.navigate('edit-delivery-partner', {
                    item: item,
                  });
                }}
                onPressDelete={() =>
                  dispatch(
                    CommonActions.setAlert({
                      visible: true,
                      content: 'Are you sure, you want to remove partner?',
                      rightButton: strings('settingScreen.confirm'),
                      rightPress: async () => {
                        deletePartner(item?.id);
                      },
                      leftButton: strings('settingScreen.cancel'),
                      leftPress: null,
                    }),
                  )
                }
              >
                <View style={styles.partnerItem}>
                  <AppText style={styles.partnerType}>{item?.type}</AppText>
                  <AppText style={styles.partnerUserName}>
                    {'Username : ' + item?.username}
                  </AppText>
                  {/* <AppText style={styles.partnerPass}>
                    {'Password : ' + item?.password.substring(0, 2) + '****'}
                  </AppText> */}
                </View>
              </SwipeRow>
            )}
          />
        )}
        {Platform.OS === 'web' && (
          <LongButton
            text={'Add New Partner'}
            style={{
              marginTop: setValue(20),
              marginBottom: setValue(45),
            }}
            onPress={() => {
              props.navigation.navigate('edit-delivery-partner');
            }}
          />
        )}
      </MainContainer>

      {Platform.OS !== 'web' && (
        <LongButton
          text={'Add New Partner'}
          style={{
            width: '100%',
            borderRadius: 0,
          }}
          onPress={() => {
            props.navigation.navigate('edit-delivery-partner');
          }}
        />
      )}
    </View>
  );
};

export default DeliveryPartnerConfig;
