import axios from 'axios';
import * as Font from 'expo-font';
import React, {useCallback, useEffect, useState} from 'react';
import AuthActions from './src/redux/reduxsauce/authRedux';
import {LogBox, View} from 'react-native';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {store, persistor} from './src/redux';
import MainApp from './src/screens/app';
import _ from 'lodash';
import * as SplashScreen from 'expo-splash-screen';
// import * as AxiosLogger from 'axios-logger';
// import * as Sentry from 'sentry-expo';
// import * as Segment from 'expo-analytics-segment';

const ENV = 'production';

// Sentry.init({
//   environment: ENV == undefined ? 'local' : ENV,
//   dsn: 'https://29e1f6c16b8c4bdfa01481373bd311d8@o1131335.ingest.sentry.io/6175707',
//   enableInExpoDevelopment: false,
//   debug: ENV == undefined, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
// });

if (ENV == 'local') {
  axios.defaults.baseURL = 'http://192.168.1.11:9000';
  // AxiosLogger.setGlobalConfig({
  //   prefixText: 'spyhunteritsolution',
  //   dateFormat: 'HH:MM:ss',
  //   status: false,
  //   headers: true,
  // });
}

if (ENV == 'development') {
  axios.defaults.baseURL = 'https://wa.dev.spyhunteritsolution.in';
}

if (ENV == 'production') {
  axios.defaults.baseURL = 'https://api.whitelabelapp.in';
}

// axios.defaults.baseURL = 'https://api.whitelabelapp.in';

// axios.interceptors.request.use(
//   AxiosLogger.requestLogger,
//   AxiosLogger.errorLogger,
// );
// if (Platform.OS != 'web') {
//   Segment.initialize({
//     androidWriteKey: 'WZDaKgGwGrp1y5EuB2DDtPgAKTZZXvXu',
//     iosWriteKey: 'WZDaKgGwGrp1y5EuB2DDtPgAKTZZXvXu',
//   });

//   // Segment.setEnabledAsync(false);
// }

window.store = store;
SplashScreen.preventAutoHideAsync();

const getErrorMessage = (data) => {
  // check for data is object

  if (data?.message && data?.message != undefined) {
    return data.message;
  }

  if (typeof data == 'object' && !Array.isArray(data)) {
    return getErrorMessage(data[Object.keys(data)[0]]);
  }
  //  check for array
  if (Array.isArray(data)) {
    return getErrorMessage(
      data.length > 0 ? data[0] : 'Error message not found.',
    );
  }
  if (typeof data == 'string') {
    return data;
  }
};

axios.interceptors.response.use(
  function (response) {
    // ENV == 'local' && AxiosLogger.requestLogger(response);
    return response;
  },
  function (error) {
    // ENV == 'local' && AxiosLogger.errorLogger(error);
    // 401 means user is not authenticated, set user to empty object({})
    if (error.response && error.response.status === 401) {
      axios.defaults.headers.common['Authorization'] = '';
      delete axios.defaults.headers.common['Authorization'];
      window.store.dispatch(AuthActions.logout());
      error = {
        ...error,
        response: {message: getErrorMessage(error.response.data)},
      };
    }

    if (typeof error.response === 'undefined') {
      error = {...error, response: {message: 'Network error!'}};
    }

    if (error.response.status === 400) {
      error = {
        ...error,
        response: {
          ...error.response,
          message: getErrorMessage(error.response.data),
        },
      };
    }

    if (error.response.status === 403) {
      error = {
        ...error,
        response: {
          ...error.response,
          message: "You don't have permission to access this resource.",
        },
      };
    }

    if (error.response.status === 500) {
      error = {
        ...error,
        response: {
          message:
            getErrorMessage(error.response?.data?.message) ||
            'Internal server error!',
        },
      };
    }

    return Promise.reject(error);
  },
);

LogBox.ignoreAllLogs(true);

export default (props) => {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  useEffect(() => {
    async function prepare() {
      try {
        await Promise.all([
          await Font.loadAsync({
            'Roboto-Regular': require('./assets/fonts/Roboto-Regular.ttf'),
            'Roboto-Bold': require('./assets/fonts/Roboto-Bold.ttf'),
            'wl-assets': require('./assets/fonts/wl-assets.ttf'),
          }),
        ]);
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } catch (e) {
        console.warn(e);
      } finally {
        setLoadingComplete(true);
      }
    }
    prepare();
  }, []);

  const onLayoutRootView = useCallback(async () => {
    if (isLoadingComplete) {
      await SplashScreen.hideAsync();
    }
  }, [isLoadingComplete]);

  if (!isLoadingComplete) {
    return null;
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <View onLayout={onLayoutRootView} style={{flex: 1}}>
          <MainApp />
        </View>
      </PersistGate>
    </Provider>
  );
};
