import React, {useEffect, useState} from 'react';
import {View, Text, TouchableOpacity, TextInput, Platform} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {
  AppText,
  ClientAppImage,
  DropDownInput,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../components';
import {Colors} from '../../constants';
import styles from './styles';
import {updateBannerCard} from '../../redux/reduxsauceActions/configActions';
import CommonAction from '../../redux/reduxsauce/commonRedux';
import {goBack, setValue} from '../../utils';
import {strings} from '../../i18n';

const BannerCard = (props) => {
  const config = useSelector((state) => state.config);
  const dispatch = useDispatch();

  let screenList = [
    {label: 'Home', value: 'home'},
    {label: config?.type == 'retail' ? 'Products' : 'Menu', value: 'products'},
    {label: 'Notification', value: 'notifications'},
    {label: 'Offer', value: 'offer'},
  ];

  if (config?.type == 'restaurants' && config?.restaurant?.enable_table_booking) {
    screenList.push(
      {label: 'Table Booking', value: 'table-booking'},
      // {label: 'Services', value: 'services-list'},
      // {label: 'Menu', value: 'menu-list'},
    );
  }

  const [urlType, setUrlType] = useState('URL');
  const [pageUrl, setPageUrl] = useState('home');
  const [loading, setLoading] = useState(false);
  const [bannerCardData, setbannerCardData] = useState({
    type: 'URL',
    url: '',
    image: '',
  });

  const saveConfigData = async () => {
    if (!bannerCardData?.image || bannerCardData?.image == '') {
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: strings('bannerCardScreen.alert.image'),
        }),
      );
      return;
    } else if (!bannerCardData?.url || bannerCardData?.url == '') {
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: strings('bannerCardScreen.alert.url'),
        }),
      );
      return;
    } else {
      let bannerCardList =
        config?.appHomePage?.bannerCard?.length > 0
          ? [...config?.appHomePage?.bannerCard]
          : [];

      bannerCardList = [...bannerCardList, bannerCardData];

      console.log('bannerCardList', bannerCardList);
      await dispatch(updateBannerCard(bannerCardList));
      props.navigation.goBack();
    }
  };
  useEffect(() => {
    if (props.route?.params?.edit) {
      setbannerCardData({
        type: props.route.params?.item?.type || 'URL',
        url: props.route.params?.item?.url,
        image: props.route.params?.item?.image,
      });
      setUrlType(props.route.params?.item?.type);
    }
  }, []);

  const updateConfigData = async () => {
    if (!bannerCardData?.image || bannerCardData?.image == '') {
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: strings('bannerCardScreen.alert.bennerImage'),
        }),
      );
      return;
    } else if (!bannerCardData?.url || bannerCardData?.url == '') {
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: strings('bannerCardScreen.alert.url'),
        }),
      );
      return;
    } else {
      const bannerCardList =
        config?.appHomePage?.bannerCard?.length > 0
          ? [...config?.appHomePage?.bannerCard]
          : [];
      bannerCardList[props.route.params?.index] = {
        ...bannerCardList[props.route.params?.index],
        type: bannerCardData.type,
        url: bannerCardData.url,
        image: bannerCardData.image,
      };
      await dispatch(updateBannerCard(bannerCardList));
      props.navigation.goBack();
    }
  };

  return (
    <View style={{flex: 1}}>
      <MainHeader
        title={strings('bannerCardScreen.title')}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer useView>
        <View style={{marginTop: 10}}>
          <ClientAppImage
            image={bannerCardData.image}
            type={'bannerCard'}
            styles={{
              width: '90%',
              aspectRatio: 10 / 4,
              alignSelf: 'center',
            }}
            ChangeClientAppImage={(url) => {
              console.log('url', url);
              setbannerCardData({...bannerCardData, image: url});
            }}
            setLoading={(loading) => {
              setLoading(loading);
            }}
          />
        </View>

        <View style={styles.textInputContainer}>
          <Text style={styles.inputLabel}>
            {strings('bannerCardScreen.label.type')}
          </Text>
          <DropDownInput
            inputStyle={{
              marginVertical: 10,
              marginHorizontal: 10,
            }}
            data={[
              {label: 'Redirect URL', value: 'URL'},
              {label: 'Screen', value: 'Screen'},
            ]}
            value={urlType}
            onChangeText={(text) => {
              if (text == 'Screen') {
                setbannerCardData({...bannerCardData, type: text, url: 'home'});
                setUrlType(text);
              } else {
                setbannerCardData({...bannerCardData, type: text, url: ''});
                setUrlType(text);
              }
            }}
          />
          {/* <Picker
            style={{backgroundColor: Colors.white, maxHeight:150}}
            selectedValue={urlType}
            onValueChange={(text) => {
              console.log('text', text);
              if (text == 'Screen') {
                setbannerCardData({...bannerCardData, type: text, url: 'home'});
                setUrlType(text);
              } else {
                setbannerCardData({...bannerCardData, type: text, url: ''});
                setUrlType(text);
              }
            }}
          >
            <Picker.Item label="Redirect URL" value="URL" />
            <Picker.Item label="Screen" value="Screen" />
          </Picker> */}
        </View>

        <View style={{marginTop: 5}}>
          {urlType == 'Screen' ? (
            <View style={styles.textInputContainer}>
              <Text style={styles.inputLabel}>
                {' '}
                {strings('bannerCardScreen.label.screen')}
              </Text>
              <DropDownInput
                inputStyle={{
                  marginVertical: 10,
                  marginHorizontal: 10,
                }}
                data={screenList}
                value={bannerCardData.url}
                onChangeText={(text) => {
                  setPageUrl(text);
                  setbannerCardData({...bannerCardData, url: text});
                }}
              />
              {/* <Picker
                mode={Platform.OS == 'dialog' ? 'dialog' : 'dialog'}
                selectedValue={bannerCardData.url}
                style={{backgroundColor: Colors.white, minHeight: setValue(50)}}
                onValueChange={(text) => {
                  setPageUrl(text);
                  setbannerCardData({...bannerCardData, url: text});
                }}
              >               
                {screenList.map((o, index) => (
                  <Picker.Item label={o.label} key={index} value={o.value} />
                ))}
              </Picker> */}
            </View>
          ) : (
            <View style={[styles.textInputContainer, {height: 80}]}>
              <AppText required style={styles.inputLabel}>
                {urlType}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                // autoCapitalize="words"
                autoCorrect={false}
                placeholder={'URL'}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={
                  Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
                }
                value={bannerCardData.url}
                onChangeText={(text) => {
                  setbannerCardData({...bannerCardData, url: text});
                }}
              />
            </View>
          )}
        </View>
        {/* <View style={styles.btnContainer}>
          <TouchableOpacity
            style={[styles.btn, {marginRight: 5}]}
            onPress={addBannerCard}
          >
            <Text style={styles.btnText}>Add</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.btn, {marginLeft: 5}]}
            onPress={removeBannerCard}
          >
            <Text style={styles.btnText}>Remove</Text>
          </TouchableOpacity>
        </View> */}
        {Platform.OS == 'web' && (
          <LongButton
            text={
              props.route?.params?.edit
                ? strings('bannerCardScreen.update')
                : strings('bannerCardScreen.add')
            }
            style={{marginTop: setValue(10), marginBottom: setValue(45)}}
            disabled={loading}
            onPress={
              props.route?.params?.edit ? updateConfigData : saveConfigData
            }
          />
        )}
      </MainContainer>
      {Platform.OS != 'web' && (
        <LongButton
          text={
            props.route?.params?.edit
              ? strings('bannerCardScreen.update')
              : strings('bannerCardScreen.add')
          }
          style={{
            width: '100%',
            borderRadius: 0,
          }}
          disabled={loading}
          onPress={
            props.route?.params?.edit ? updateConfigData : saveConfigData
          }
        />
      )}
    </View>
  );
};

export default BannerCard;
