import {Platform, StyleSheet} from 'react-native';
import {Colors} from '../../../constants';
import {setValue, setYAxisValue, setXAxisValue} from '../../../utils';

const shadow = {
  shadowColor: Colors.black60,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 3,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  mainContainer: {
    flex: Platform.OS != 'web' ? 1 : null,
  },
  itemContainer: {
    marginVertical: 5,
    marginHorizontal: 3,
    flexDirection: 'row',
    backgroundColor: Colors.white,
    borderRadius: 5,
    ...shadow,
  },
  image: {
    width: setValue(65),
    height: setValue(65),
    overflow: 'hidden',
    borderRadius: 55,
    // borderWidth: 1,
    // borderColor: Colors.theme_color,
  },

  buttonContainer: {
    flexDirection: 'row',
    paddingLeft: setValue(5),
  },
  button: {
    width: setValue(33),
    height: setValue(33),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.theme_color,
    marginVertical: setValue(5),
    marginHorizontal: setValue(5),
    borderRadius: setValue(40),
  },
  settingView: {
    ...shadow,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: setYAxisValue(20),
    paddingHorizontal: setXAxisValue(10),
    borderRadius: 8,
    alignItems: 'center',
    marginBottom: setYAxisValue(10),
    backgroundColor: Colors.white,
  },
});
