import {StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 5,
};

export default StyleSheet.create({
  imageLogo: {
    width: (setValue(200) * 4) / 4,
    height: (setValue(200) * 2) / 4,
    borderRadius: setValue(10),
    marginBottom: setValue(20),
    alignSelf: 'center',
  },
  textInputContainer: {
    backgroundColor: Colors.white,
    ...shadow,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
  },
  inputLabel: {
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    fontFamily: 'Roboto-Bold',
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  textInput: {
    height: setValue(50),
    padding: 0,
    paddingHorizontal: setXAxisValue(10),
  },
  textInputWeb: {
    height: setValue(50),
    padding: setValue(5),
    paddingHorizontal: setXAxisValue(10),
  },

  addressContainer: {
    ...shadow,
    backgroundColor: Colors.white,
    borderRadius: 5,
    padding: 10,
    maxHeight: 350,
    marginBottom: setYAxisValue(10),
  },
  addressContainerLabel: {
    fontSize: setXAxisValue(15),
    fontFamily: 'Roboto-Bold',
    color: Colors.black20,
    marginBottom: setYAxisValue(5),
  },
  //address item
  radioContainer: {
    justifyContent: 'center',
  },
  radio: {
    textAlign: 'center',
    textAlignVertical: 'center',
  },
  address: {
    flexDirection: 'row',
    borderRadius: 5,
    padding: 5,
    marginBottom: 10,
    marginHorizontal: 2,
  },
  addressType: {
    fontWeight: '700',
    fontSize: 17,
    color: Colors.black20,
  },
  location: {
    color: Colors.black40,
  },
  // footer component
  addressBtn: {
    borderWidth: 1.5,
    backgroundColor: '#FFF',
    borderColor: Colors.theme_color,
    color: Colors.theme_color,
    paddingVertical: 5,
    borderRadius: 5,
    ...shadow,
  },
  addressBtnText: {
    textAlign: 'center',
    padding: 8,
    color: Colors.theme_color,
    fontFamily: 'Roboto-Bold',
  },

  //courier company
  itemContainer: {
    flex: 1,
    padding: 5,
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  CompanyName: {
    fontFamily: 'Roboto-Bold',
    fontSize: setValue(15),
    marginBottom: 3,
  },
});
