import {Platform, StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 2,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  chatUserContainer: {
    borderColor: Colors.color30,
    borderBottomWidth: 1,
    padding: setValue(10),
    backgroundColor: Colors.white,
    flexDirection: 'row',
  },
  chatUserImage: {
    marginHorizontal: setValue(5),
    width: setValue(55),
    height: setValue(55),
    borderRadius: setValue(100),
    borderWidth: 1,
    padding: setValue(1),
    borderColor: Colors.black40,
  },
  chatUserDetailContainer: {
    flex: 1,
    backgroundColor: Colors.white,
    justifyContent: 'center',
    paddingVertical: setValue(2),
    marginHorizontal: setValue(10),
  },
  userDetailSubContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chatUserName: {
    fontFamily: 'Roboto-Bold',
    fontSize: setValue(18),
    color: Colors.black,
  },
  chatUserLastMessage: {
    alignSelf: 'center',
    fontSize: setValue(16),
    color: Colors.black60,
  },
  date: {
    fontSize: setValue(12),
    color: Colors.black,
  },
  badge: {
    borderRadius: setValue(100),
    fontSize: setValue(10),
    color: Colors.black,
    padding: setValue(3),
    minWidth: setValue(20),
    fontFamily: 'Roboto-Bold',
    textAlign: 'center',
    alignSelf: 'flex-end',
    margin: setValue(2),
    backgroundColor: Colors.theme_color,
    ...shadow,
  },
  searchBar: {
    backgroundColor: Colors.white,
    padding: 8,
    zIndex: -999,
    elevation: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchTextInput: {
    borderBottomWidth: 1,
    borderColor: Colors.border,
    borderRadius: 5,
    padding: 8,
    paddingHorizontal: setValue(8),
    fontSize: setXAxisValue(15),
    width: '90%',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
