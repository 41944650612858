import axios from 'axios';
import get from 'lodash.get';
import React, {Component} from 'react';
import {
  FlatList,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ActivityIndicator,
} from 'react-native';
import {SwipeRow} from 'react-native-swipe-list-view';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  AppText,
  Icon,
  MainContainer,
  MainHeader,
  Modal,
  NoRecordFound,
  NotificationItem,
} from '../../../components';
import {Colors} from '../../../constants';
import {strings} from '../../../i18n';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {
  getNotificationList,
  deleteNotification,
  deleteNotificationByList,
} from '../../../redux/reduxsauceActions/notificationActions';
import styles from './styles';

class NotificationScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      removeList: [],
      isOpen: false,
      isModalVisible: false,
      isHistoryModalVisible: false,
      fetching: props.fetching || false,
      refresh: false,
      loadMore: false,
      loading: false,
      notificationList: [],
    };
    this.onEndReachedCalledDuringMomentum = true;
    this.swipeRef = [];
  }

  componentDidMount() {
    this.props.getNotificationList();
  }

  handleRefresh = async () => {
    this.props.setLoading(true);
    this.setState({refresh: true});
    await this.props.getNotificationList();
    this.setState({refresh: false});
    this.props.setLoading(false);
  };

  handleLoadMore = () => {
    const {loadMore} = this.state;
    this.props.getNotificationList();
  };

  UNSAFE_componentWillReceiveProps(nexProps) {
    this.setState({notificationList: nexProps.notifications});
  }
  renderAddNotifications() {
    return (
      <TouchableOpacity
        style={styles.addNotification}
        onPress={() => this.props.navigation.navigate('edit-notifications')}
      >
        <AppText style={styles.addNotificationText}>
          {strings('notificationScreen.sendNotification')}
        </AppText>
      </TouchableOpacity>
    );
  }
  render() {
    const {notifications, user, appConfig, isLogin} = this.props;
    const {removeList, isOpen, notificationList} = this.state;

    return (
      <View style={styles.container}>
        <MainHeader
          leftIcon={'back'}
          leftPress={() => this.props.navigation.goBack()}
          title={strings('notificationScreen.memberNotification')}
          navigation={this.props.navigation}
          rightOtherIcon={'listView'}
          rightIcon={'dustBinSmaller'}
          rightOtherPress={() => {
            if (notifications?.length) {
              notifications.forEach((i, index) => {
                if (isOpen) {
                  this.swipeRef[index]?.manuallySwipeRow(0);
                  if (notifications.length - 1 === index) {
                    this.setState({isOpen: false});
                  }
                } else {
                  this.swipeRef[index]?.manuallySwipeRow(70);
                  if (notifications.length - 1 === index) {
                    this.setState({removeList: [], isOpen: true});
                  }
                }
              });
            }
          }}
          rightPress={async () => {
            if (removeList.length === 0) {
              if (isOpen) {
                notificationList.forEach((i, index) => {
                  this.swipeRef[index]?.manuallySwipeRow(0);
                });
                this.setState({isOpen: false});
              }
              let removeNotification = notificationList
                .map((o) => o.id)
                .toString();
              this.props.openAlert(
                strings('notificationScreen.deleteMultipleMessage'),
                strings('common.yes'),
                async () => {
                  this.props.deleteNotificationByList(removeNotification);
                  this.setState({removeList: []});
                },
                strings('common.no'),
                null,
              );
            } else {
              let removeNotification = removeList.toString();
              if (isOpen) {
                notificationList.forEach((i, index) => {
                  this.swipeRef[index]?.manuallySwipeRow(0);
                });
                this.setState({isOpen: false});
              }
              this.props.openAlert(
                strings('notificationScreen.deleteSelectMessage'),
                strings('common.yes'),
                async () => {
                  this.props.deleteNotificationByList(removeNotification);
                  this.setState({removeList: []});
                },
                strings('common.no'),
                null,
              );
            }
          }}
        />
        <MainContainer useView>
          {['merchant', 'manager'].includes(user?.type) &&
            this.renderAddNotifications()}
          {notificationList?.length ? (
            <FlatList
              data={notificationList}
              renderItem={({item, index}) =>
                isLogin ? (
                  <SwipeRow
                    ref={(ref) => {
                      this.swipeRef[index] = ref;
                    }}
                    leftOpenValue={70}
                    rightOpenValue={
                      ['merchant', 'manager'].includes(user?.type) ? -100 : -70
                    }
                  >
                    <View
                      style={styles.hiddenButton}
                      onPress={() =>
                        this.props.openAlert(
                          strings('notificationScreen.deleteMessage'),
                          strings('common.yes'),
                          () => {
                            this.props.deleteNotification(item.id);
                          },
                          strings('common.no'),
                          null,
                        )
                      }
                    >
                      <TouchableOpacity
                        style={styles.trashIcon}
                        onPress={() => {
                          if (removeList.includes(item.id)) {
                            this.setState({
                              removeList: removeList.filter(
                                (i) => i !== item.id,
                              ),
                            });
                          } else {
                            this.setState({
                              removeList: [...removeList, item.id],
                            });
                          }
                        }}
                      >
                        <Icon
                          name={
                            removeList.includes(item.id) ? 'check' : 'unCheck'
                          }
                          color="black"
                        />
                      </TouchableOpacity>
                      <View style={{flexDirection: 'row'}}>
                        {['merchant', 'manager'].includes(user?.type) && (
                          <TouchableOpacity
                            style={styles.trashIcon}
                            onPress={() =>
                              this.props.navigation.navigate(
                                'edit-notifications',
                                item,
                              )
                            }
                          >
                            <Icon name="edit" color="black" size={18} />
                          </TouchableOpacity>
                        )}
                        <TouchableOpacity
                          style={styles.trashIcon}
                          onPress={() =>
                            this.props.openAlert(
                              strings('notificationScreen.deleteMessage'),
                              strings('common.yes'),
                              () => {
                                this.props.deleteNotification(item.id);
                              },
                              strings('common.no'),
                              null,
                            )
                          }
                        >
                          <Icon name="dustBinSmaller" color="black" />
                        </TouchableOpacity>
                      </View>
                    </View>
                    <NotificationItem
                      item={item}
                      onPressNotification={() =>
                        this.props.navigation.navigate('notification-detail', {
                          data: item,
                        })
                      }
                    />
                  </SwipeRow>
                ) : (
                  <NotificationItem
                    item={item}
                    onPressNotification={() =>
                      this.props.navigation.navigate('notification-detail', {
                        data: item,
                      })
                    }
                  />
                )
              }
              ListFooterComponent={() => (
                <View style={{paddingTop: 15, paddingBottom: 20}}>
                  {this.state.loadMore && <ActivityIndicator color="#000" />}
                </View>
              )}
              keyExtractor={(item) => item.id}
              keyboardShouldPersistTaps="always"
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              refreshing={this.state.refresh}
              onRefresh={this.handleRefresh}
              onEndReached={this.handleLoadMore}
              onEndReachedThreshold={0.5}
              onMomentumScrollBegin={() => {
                this.onEndReachedCalledDuringMomentum = false;
              }}
            />
          ) : (
            <View style={{flex: 1}}>
              <NoRecordFound
                title={strings('notificationScreen.norecord.title')}
                message={strings('notificationScreen.norecord.message')}
              />
            </View>
          )}
        </MainContainer>
      </View>
    );
  }
}

export default connect(
  (state) => ({
    user: state.auth.user,
    isLogin: get(state.auth.user, 'id', false),
    notifications: state.notification,
    appConfig: state?.config,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getNotificationList,
        deleteNotification,
        deleteNotificationByList,
        openAlert: (content, leftButton, leftPress, rightButton, rightPress) =>
          CommonActions.setAlert({
            visible: true,
            content,
            leftButton,
            leftPress,
            rightButton,
            rightPress,
          }),
        setLoading: CommonActions.setLoading,
      },
      dispatch,
    ),
)(NotificationScreen);
