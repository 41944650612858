import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  promotionImage: {
    width: '100%',
    height: setYAxisValue(180),
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  itemContainer: {
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 3,
    padding: 0,
    backgroundColor: Colors.white,
    borderRadius: 8,
    marginBottom: setYAxisValue(15),
  },
  title: {
    fontSize: setXAxisValue(18),
    marginVertical: setYAxisValue(5),
  },
  desc: {
    fontSize: setXAxisValue(13),
  },
  infoBar: {
    marginTop: setYAxisValue(15),
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  moneyContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  validText: {
    fontSize: setXAxisValue(14),
    marginBottom: setYAxisValue(5),
  },
  itemLeft: {
    flexDirection: 'row',
  },
  numberLeft: {
    fontSize: setXAxisValue(14),
    marginRight: setXAxisValue(5),
  },
  leftText: {
    fontSize: setXAxisValue(12),
  },
  discountView: {
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },
  currencySymbolText: {
    fontSize: setXAxisValue(16),
  },
  discountText: {
    fontSize: setXAxisValue(10),
    textDecorationLine: 'line-through',
  },
  currentPriceText: {
    fontSize: setXAxisValue(16),
    marginLeft: setXAxisValue(5),
  },
  lastItem: {
    // marginBottom: setYAxisValue(100)
  },
  iconNewPromotion: {
    width: setXAxisValue(30),
    height: setYAxisValue(30),
    marginTop: setYAxisValue(10),
  },
  couponCodeContainer: {
    marginVertical: setYAxisValue(10),
    flexDirection: 'row',
    alignItems: 'center',
  },
  couponCodeText: {
    paddingVertical: setYAxisValue(10),
    width: '75%',
    textAlign: 'center',
    fontFamily: 'Roboto-Bold',
    letterSpacing: 1.5,
    borderWidth: 2,
    borderColor: '#ccc',
    borderStyle: 'dotted',
    borderTopLeftRadius: setValue(5),
    borderBottomLeftRadius: setValue(5),
  },
  copyText: {
    color: Colors.white,
    paddingVertical: setYAxisValue(10),
    width: '25%',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Roboto-Bold',
    backgroundColor: Colors.theme_color,
    borderWidth: 2,
    borderColor: Colors.theme_color,
    borderBottomRightRadius: setValue(5),
    borderTopRightRadius: setValue(5),
  },
  promotionOffer: {
    position: 'absolute',
    right: setXAxisValue(15),
    backgroundColor:Colors.wallet_card_red,
    padding: setValue(5),
    paddingHorizontal: setValue(13),
    borderRadius: setValue(50),
    top: setYAxisValue(-15),
  },
  offerText: {
    color: Colors.white,
    fontFamily: 'Roboto-Bold',
  },
});
