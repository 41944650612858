import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
  borderRadius: 5,
  backgroundColor: Colors.white,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  filterByDateContainer: {
    ...shadow,
    padding: setValue(15),
    marginBottom: setYAxisValue(15),
  },

  // product item styles
  categoryButtonIconContainer: {
    backgroundColor: 'white',
    borderRadius: setValue(8),
    width: setValue(60),
    height: setValue(60),
    alignItems: 'center',
    justifyContent: 'center',
    ...shadow,
  },
  categoryImages: {
    width: setValue(50),
    height: setValue(50),
  },
  image: {
    flex: 1,
    width: undefined,
    height: undefined,
    overflow: 'hidden',
    borderRadius: 150 / 2,
  },
  selected: {
    borderColor: Colors.black,
    borderWidth: setValue(4),
  },
  categoryText: {
    fontSize: setXAxisValue(12),
    textAlign: 'center',
    color: Colors.black60,
    marginVertical: setYAxisValue(5),
    width: 70,
  },
  // end product item
});
