import React, {useEffect, useState} from 'react';
import {
  ActivityIndicator,
  Image,
  Text,
  TouchableOpacity,
  View,
  TextInput,
  Platform,
  Keyboard,
} from 'react-native';
import {setYAxisValue} from '../../utils';
import {Colors, Images} from '../../constants';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import ConfigActions from '../../redux/reduxsauce/configRedux';
import {AppText, Icon, MainContainer, DropDownInput} from '../../components';
import {strings} from '../../i18n';
import styles from './styles';
import {connectActionSheet} from '@expo/react-native-action-sheet';
import * as ImagePicker from 'expo-image-picker';
import CommonActions from '../../redux/reduxsauce/commonRedux';

const NoConfigFound = (props) => {
  const dispatch = useDispatch();
  const business = useSelector((state) => state.auth?.user?.business);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [onboarding, setOnboarding] = useState(false);
  const [onboardingDetail, setOnboardingDetail] = useState({
    logo: null,
    app_name: '',
    business_type: null,
    theme_color: '',
    currency: null,
    domain: '',
  });
  const [paymentConfigData, setPaymentConfigData] = useState({
    label: '',
    type: 'razorpay',
    key: '',
    secret_key: '',
    is_active: true,
  });
  const [currencyList, setCurrencyList] = useState([]);
  const [businessTypeList, setBusinessTypeList] = useState([]);

  const getAppSlug = async () => {
    console.log(business);
    setLoading(true);
    setErrorMessage(false);

    await axios
      .get(`/business/details/${business}`)
      .then((response) => {
        console.log('response slug', response?.data);
        !response?.data?.business_setting?.on_boarding_step ||
        response?.data?.business_setting?.on_boarding_step != 'finished'
          ? setOnboarding(true)
          : getAppConfig(response?.data?.domain);
      })
      .catch((error) => {
        console.log('error get config->', error.response);
        setErrorMessage(error.response?.message);
        setLoading(false);
      });
  };

  const getAppConfig = async (domain) => {
    setLoading(true);
    setErrorMessage(false);
    await fetch(axios.defaults.baseURL + `/business/app/config/${domain}`)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        console.log('response app config ----------------------', response);
        response.success
          ? dispatch(ConfigActions.setAppConfiguration(response.data))
          : setErrorMessage(
              response?.message ||
                'Something went wrong. please try again later.',
            );

        setLoading(false);
      })
      .catch((error) => {
        console.log('error get config->', error.response);
        setErrorMessage(error.response?.message);
        setLoading(false);
      });
  };

  const getCurrencyList = async () => {
    setLoading(true);
    setErrorMessage(false);

    try {
      const currency = await axios.get('/lookup/currency/list');
      setCurrencyList(currency?.data);
      const businessType = await axios.get('/lookup/business/types/list');
      setBusinessTypeList(businessType?.data);
      console.log('response of currencyList-->>', currencyList);
      console.log('response of businessTypeList-->>', businessTypeList);
      setLoading(false);
    } catch (error) {
      console.log('error-->>', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAppSlug();
    getCurrencyList();
  }, []);

  if (onboarding) {
    let paymentMethods = [
      {label: 'Razorpay', value: 'razorpay'},
      {label: 'Cashfree', value: 'cashfree'},
      {label: 'Stripe', value: 'stripe'},
      {label: 'Paytm', value: 'paytm'},
      {label: 'UPI Gateway', value: 'upi'},
    ];

    const onSave = async () => {
      Keyboard.dismiss();

      if (
        onboardingDetail?.logo == null ||
        onboardingDetail?.app_name == '' ||
        onboardingDetail?.business_type == null ||
        onboardingDetail?.theme_color == '' ||
        onboardingDetail?.currency == null ||
        paymentConfigData?.type == null ||
        paymentConfigData?.label == '' ||
        paymentConfigData?.key == '' ||
        paymentConfigData?.secret_key == '' ||
        onboardingDetail?.domain == ''
      ) {
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content:
              (onboardingDetail?.logo == null && 'Please upload logo') ||
              (onboardingDetail?.app_name == '' && 'Please enter app name') ||
              (onboardingDetail?.business_type == null &&
                'Please select business type') ||
              (onboardingDetail?.theme_color == '' &&
                'Please select theme color') ||
              (onboardingDetail?.currency == null &&
                'Please select currency') ||
              (paymentConfigData?.type == null &&
                'Please select payment method') ||
              (paymentConfigData?.label == '' && 'Please add label') ||
              (paymentConfigData?.key == '' && 'Please add key') ||
              (paymentConfigData?.secret_key == '' &&
                'Please add secret key') ||
              (onboardingDetail?.domain == '' && 'Please add domain'),
          }),
        );
        return;
      }

      let imageData = new FormData();
      const header = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      if (
        onboardingDetail?.logo?.uri &&
        onboardingDetail?.logo?.uri?.slice(0, 4) != 'http'
      ) {
        if (Platform.OS == 'web') {
          imageData.append('logo', dataURLtoFile(onboardingDetail?.logo?.uri));
        } else {
          let blob = await (await fetch(onboardingDetail?.logo?.uri)).blob();
          imageData.append('logo', {
            uri: onboardingDetail?.logo?.uri,
            type: blob.type,
            name: blob.data.name,
          });
        }
      } else if (!onboardingDetail?.logo?.uri) {
        imageData.append('logo', '');
      }
      dispatch(CommonActions.setLoading(true));
      await axios
        .patch('/business/logo/update/' + business, imageData, header)
        .then((response) => {
          console.log('response update onboarding logo', response);
          dispatch(CommonActions.setLoading(false));
          getAppSlug();
        })
        .catch((error) => {
          console.log('error update onboarding logo', error);
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: error?.response?.message,
            }),
          );
          dispatch(CommonActions.setLoading(false));
        });

      let body = {
        domain: onboardingDetail?.domain.trim(),
        type: onboardingDetail?.business_type,
        currency: onboardingDetail?.currency,
        business_setting: {
          app_name: onboardingDetail?.app_name,
          theme: {theme_color: onboardingDetail?.theme_color},
          on_boarding_step: 'finished',
        },
        payment_method: {
          label: paymentConfigData?.label,
          is_active: paymentConfigData?.is_active,
          type: paymentConfigData?.type,
          payment_key: paymentConfigData?.key,
          payment_secret_key: paymentConfigData?.secret_key,
        },
      };
      dispatch(CommonActions.setLoading(true));
      await axios
        .patch('/business/on_boarding/update/' + business, body)
        .then((response) => {
          console.log('response update onboarding ', response);
          setOnboarding(false);
          dispatch(CommonActions.setLoading(false));
          getAppSlug();
        })
        .catch((error) => {
          setOnboarding(false);
          console.log('error update onboarding --', error);
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: error?.response?.message,
            }),
          );
          dispatch(CommonActions.setLoading(false));
        });
    };

    const openActionSheet = () => {
      const options = [
        strings('actionSheet.takePhoto'),
        strings('actionSheet.choosePhoto'),
        strings('actionSheet.removePhoto'),
        strings('actionSheet.cancel'),
      ];
      const cancelButtonIndex = 3;

      props.showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex,
        },
        (buttonIndex) => {
          if (buttonIndex === 0) openCamera();
          if (buttonIndex === 1) openImagePicker();
          if (buttonIndex === 2) removePhoto();
        },
      );
    };

    const removePhoto = () => {
      // props.removeProfilePicture();
      setOnboardingDetail({
        ...onboardingDetail,
        logo: null,
      });
    };

    const openCamera = async () => {
      await ImagePicker.requestCameraPermissionsAsync();

      const result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: false,
        aspect: [30, 17],
        quality: 1,
        base64: true,
      });
      if (result.uri) {
        setOnboardingDetail({
          ...onboardingDetail,
          logo: result,
        });
      }

      if (!result.cancelled) {
        // changeProfilePicture(result);
      }
    };

    const openImagePicker = async () => {
      await ImagePicker.requestMediaLibraryPermissionsAsync();

      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: false,
        aspect: [30, 17],
        quality: 1,
        base64: true,
      });
      if (result.uri) {
        setOnboardingDetail({
          ...onboardingDetail,
          logo: result,
        });
      }

      if (!result.cancelled) {
      }
    };

    const dataURLtoFile = (dataurl) => {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], 'image1.jpg', {type: mime});
    };

    return (
      <View
        style={
          Platform.OS == 'web'
            ? {display: 'flex', overflow: 'auto', height: '100vh'}
            : styles.onboardingContainer
        }
      >
        <View style={styles.safeView}>
          <AppText style={styles.title}>{'Onboarding'}</AppText>
        </View>
        <MainContainer>
          <View style={[styles.profileStrengthContainer]}>
            <TouchableOpacity
              style={styles.imageContainer}
              onPress={openActionSheet}
            >
              {!onboardingDetail?.logo ? (
                <Icon name="add" size={16} color={Colors.black60} />
              ) : (
                <View>
                  <Image
                    source={{uri: onboardingDetail?.logo?.uri}}
                    style={styles.image}
                  />
                  <TouchableOpacity
                    style={styles.cameraIcon}
                    onPress={openActionSheet}
                  >
                    <Icon name="camera" color={Colors.black20} />
                  </TouchableOpacity>
                </View>
              )}
            </TouchableOpacity>
          </View>

          <View style={{alignItems: 'center'}}>
            <AppText style={styles.logoRequirements} required>
              {'(Please upload 512*512, PNG logo image)'}
            </AppText>
          </View>

          {/* name */}
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel} required>
              {'App Name'}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              placeholder={'Please enter app name'}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
              value={onboardingDetail.product_name}
              onChangeText={(text) =>
                setOnboardingDetail({...onboardingDetail, app_name: text})
              }
            />
          </View>

          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel} required>
              {'Business Type'}
            </AppText>
            <DropDownInput
              data={businessTypeList?.map((o) => ({
                label: o?.type,
                value: o?.id,
              }))}
              placeholder={'Please select business type'}
              value={onboardingDetail?.business_type}
              inputStyle={{
                marginVertical: 10,
                marginHorizontal: 10,
              }}
              onChangeText={(text) => {
                setOnboardingDetail({...onboardingDetail, business_type: text});
              }}
            />
          </View>

          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel} required>
              {'Theme Color'}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              placeholder={'Please select theme color'}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
              value={onboardingDetail.product_name}
              onChangeText={(text) =>
                setOnboardingDetail({...onboardingDetail, theme_color: text})
              }
            />
          </View>

          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel} required>
              {'Currency'}
            </AppText>
            <DropDownInput
              data={currencyList?.map((o) => ({
                label: o?.currency_code,
                value: o?.id,
              }))}
              placeholder={'Please select currency'}
              value={onboardingDetail?.currency}
              inputStyle={{
                marginVertical: 10,
                marginHorizontal: 10,
              }}
              onChangeText={(text) => {
                setOnboardingDetail({...onboardingDetail, currency: text});
              }}
            />
          </View>

          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel} required>
              {'Domain'}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              placeholder={'Please enter Business domain'}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
              value={onboardingDetail?.domain}
              onChangeText={(text) =>
                setOnboardingDetail({...onboardingDetail, domain: text})
              }
            />
          </View>

          <View style={{paddingTop: 15, paddingBottom: 10}}>
            <AppText>{'Payment Details'}</AppText>
          </View>

          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('paymentSetting.label.payMethod')}
            </AppText>
            <DropDownInput
              inputStyle={{
                marginVertical: 10,
                marginHorizontal: 10,
              }}
              data={paymentMethods}
              value={paymentConfigData.type}
              onChangeText={(text) => {
                setPaymentConfigData({
                  ...paymentConfigData,
                  type: text.toLowerCase(),
                  key: '',
                  secret_key: '',
                });
              }}
            />
          </View>

          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel} required>
              {'Label'}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              placeholder={'Enter method label'}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              value={paymentConfigData?.label}
              onChangeText={(text) =>
                setPaymentConfigData({...paymentConfigData, label: text})
              }
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
            />
          </View>

          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel} required>
              {strings('paymentSetting.label.key')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              placeholder={strings('paymentSetting.placeholder.key')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              value={paymentConfigData?.key}
              onChangeText={(text) =>
                setPaymentConfigData({...paymentConfigData, key: text})
              }
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
            />
          </View>

          {paymentConfigData.type !== 'upi' && (
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel} required>
                {strings('paymentSetting.label.secretkey')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCorrect={false}
                placeholder={strings('paymentSetting.placeholder.secretkey')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                value={paymentConfigData?.secret_key}
                onChangeText={(text) =>
                  setPaymentConfigData({
                    ...paymentConfigData,
                    secret_key: text,
                  })
                }
                style={
                  Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
                }
              />
            </View>
          )}

          {Platform.OS == 'web' && (
            <View style={styles.buttonContainerWeb}>
              <TouchableOpacity
                style={styles.buttonButtonWeb}
                onPress={() => onSave()}
              >
                <AppText style={styles.buttonText}>{'save'}</AppText>
              </TouchableOpacity>
            </View>
          )}
        </MainContainer>
        {Platform.OS !== 'web' && (
          <View style={styles.buttonContainer}>
            <TouchableOpacity style={styles.buttonButton} onPress={onSave}>
              <AppText style={styles.buttonText}>{'save'}</AppText>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  }

  if (errorMessage) {
    return (
      <View style={styles.container}>
        <View>
          <Image source={Images.logo} style={styles.signUpImage} />
        </View>
        <View style={{marginTop: setYAxisValue(30)}}>
          <Text style={styles.textDes}>{errorMessage}</Text>
        </View>
        <View style={{marginTop: setYAxisValue(30)}}>
          <TouchableOpacity
            onPress={() => getAppConfig()}
            style={styles.refreshButton}
          >
            {loading ? (
              <ActivityIndicator size="small" />
            ) : (
              <Text style={[styles.textMessage, {color: Colors.white}]}>
                Retry
              </Text>
            )}
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View>
        <Image source={Images.logo} style={styles.signUpImage} />
      </View>
      <View style={{marginTop: setYAxisValue(50)}}>
        <Text style={styles.textMessage}>We are setting up app ...</Text>
      </View>
      <View style={{marginTop: setYAxisValue(30), alignItems: 'center'}}>
        <ActivityIndicator size="large" color={Colors.theme_color} />
      </View>
    </View>
  );
};

const ConnectedNoConfigFound = connectActionSheet(NoConfigFound);

export default ConnectedNoConfigFound;
