import {StyleSheet} from 'react-native';
import {Colors} from '../../../constants';
import {screenWidth, setValue} from '../../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 5,
};
export default StyleSheet.create({
  container: {
    flex: 1,
  },
  basicCard: {
    backgroundColor: Colors.white,
    borderRadius: 5,
    marginBottom: 10,
    ...shadow,
  },
  headerItems: {
    padding: 10,
    flexDirection: 'row',
  },
  headerText: {
    fontFamily: 'Roboto-Bold',
    flex: 1,
    color: Colors.black60,
  },
  headerBtn: {
    justifyContent: 'center',
  },
  border: {
    borderWidth: 1,
    borderColor: Colors.border,
  },
  cardContent: {
    paddingHorizontal: setValue(10),
    paddingVertical: setValue(5),
  },
  inputStyleContainer: {marginBottom: 5},
  inputStyle: {
    borderBottomWidth: 1,
    borderColor: Colors.border,
    minHeight: 30,
    paddingHorizontal: 5,
  },
  label: {
    paddingVertical: 5,
    color: Colors.black60,
  },
  customerField: {
    paddingVertical: 5,
    flexDirection: 'row',
  },
  fieldLabel: {
    flex: 1,
    color: Colors.black,
    fontFamily: 'Roboto-Bold',
  },
  varientItem: {
    borderWidth: 2,
    justifyContent: 'center',
    borderRadius: 5,
    borderColor: Colors.theme_color,
    marginHorizontal: 3,
    padding: 5,
  },
  // cart item
  cartContainer: {
    backgroundColor: Colors.white,
    marginVertical: 8,
    borderRadius: 8,
    flexDirection: 'row',
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 3,
  },
  image: {
    margin: 10,
    borderRadius: 10,
    width: 75,
    height: 75,
  },
  cartItem: {
    flex: 1,
    padding: 10,
  },
  addRemoveQtyButton: {
    width: setValue(25),
    height: setValue(25),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.theme_color,
    borderRadius: setValue(30),
  },
  itemName: {
    fontSize: setValue(18),
    fontWeight: 'bold',
  },
  itemQtyText: {
    textAlign: 'center',
    marginHorizontal: 12,
    marginVertical: 3,
  },
  buttonContainer: {
    flexDirection: 'row',
    paddingTop: 5,
  },
  variantsText: {
    padding: setValue(5),
    fontSize: setValue(12),
    color: Colors.black40,
  },
  variantsTextContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addProduct: {
    textAlign: 'center',
    padding: setValue(10),
    fontFamily: 'Roboto-Bold',
  },
  buttonContainerForProduct: {
    flexDirection: 'row',
    marginTop: setValue(7),
    justifyContent: 'space-between',
  },
  // customer detail
  editCutomerDetailaButton: {
    padding: 5,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.facebook,
  },
  editCutomerDetailaButtonText: {
    fontSize: 16,
    color: Colors.facebook,
    textAlign: 'center',
  },
  // customer detail edit modal
  modalOverlay: {
    backgroundColor: Colors.overlay,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    backgroundColor: Colors.white,
    borderRadius: 5,
    padding: 10,
    maxWidth: 400,
    width: '90%',
  },
  modalHeader: {
    color: Colors.black,
    fontSize: 20,
    padding: 10,
    textAlign: 'center',
    fontFamily: 'Roboto-Bold',
  },
  modalButtonContainer: {
    flexDirection: 'row',
    paddingVertical: 5,
    marginTop: 5,
  },
  modalButton: {
    flex: 1,
    padding: 10,
    borderRadius: 5,
    backgroundColor: Colors.theme_color,
  },
  modalButtonCancel: {
    flex: 1,
    padding: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.theme_color,
  },
  modalButtonText: {
    color: Colors.white,
    fontSize: 16,
    textAlign: 'center',
  },
  modalRequireMessage: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
  },
});
