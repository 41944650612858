import React from 'react';
import {View} from 'react-native';
import CheckBox from '../CheckBox';
import AppText from '../AppText';
import Icon from '../Icon';
import styles from './styles';

export default function AppConfigCheckbox(props) {
  const {title, desc, icon, checked, style, descStyle, titleStyle} = props;

  return (
    <View style={[styles.itemContainer, style]}>
      {icon && (
        <View style={styles.itemIcon}>
          <Icon name={icon} />
        </View>
      )}
      <View style={styles.itemDetail}>
        {title && (
          <AppText style={[styles.itemTitle, titleStyle]}>{title}</AppText>
        )}
        {desc && <AppText style={[styles.itemDesc, descStyle]}>{desc}</AppText>}
      </View>
      <View style={styles.itemCheckBox}>
        <CheckBox
          checked={checked}
          onChange={(checked) => {
            props.onChangeCheck(checked);
          }}
        />
      </View>
    </View>
  );
}
