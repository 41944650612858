import {Appearance, Platform, StyleSheet} from 'react-native';

import {Colors} from '../../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../../utils';
const colorScheme = Appearance.getColorScheme();
const textColor = colorScheme == 'dark' ? Colors.black60 : Colors.black60;

const wrapper = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 8,
  padding: setValue(5),
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  imageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: setValue(5),
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
    backgroundColor: Colors.white,
    marginRight: setValue(5),
    marginBottom: Platform.OS == 'web' ? setValue(5) : 0,
  },
  image: {
    borderRadius: setValue(5),
  },
  imageVariants: {
    fontSize: 15,
    fontWeight:"500",
    height: 23,
    alignSelf: 'center',
    position:"absolute",
    zIndex:999,
    backgroundColor:Colors.white,
    left:0, bottom:0, right:0,
    borderBottomLeftRadius:setValue(5),
    borderBottomRightRadius:setValue(5),
    paddingHorizontal:10
  },
  productStrengthContainer: {
    // ...wrapper,
    flexDirection: 'row',
    justifyContent: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    // borderTopRightRadius: setValue(90) / 2,
    // borderBottomRightRadius: setValue(90) / 2,
    marginBottom: setYAxisValue(20),
  },
  percentText: {
    fontSize: setXAxisValue(35),
    color: Colors.theme_color,
  },
  buttonContainer: {
    backgroundColor: Colors.white,
    paddingVertical: setYAxisValue(15),
  },
  buttonContainerWeb: {
    backgroundColor: Colors.white,
    marginVertical: setValue(50),
  },
  buttonButton: {
    width: '100%',
    backgroundColor: Colors.theme_color,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    bottom: 0,
    position: 'absolute',
    paddingVertical: setYAxisValue(15),
  },
  buttonButtonWeb: {
    width: '100%',
    backgroundColor: Colors.theme_color,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 5,
    bottom: 0,
    position: 'absolute',
    paddingVertical: setYAxisValue(15),
    ...wrapper,
  },
  buttonText: {
    fontSize: setXAxisValue(14),
    color: Colors.white,
  },
  inputStyle: {
    // height: setValue(30),
    padding: 0,
    flex: 1,
    // borderWidth:2,
    marginBottom: setYAxisValue(0),
    borderRadius: 5,
    backgroundColor: Colors.white,
    paddingHorizontal: setXAxisValue(10),
  },
  title: {
    // fontSize: setXAxisValue(14),
    // marginBottom: setYAxisValue(5),
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  content: {
    fontSize: setXAxisValue(14),
    color: Colors.black40,
  },
  textInputContainer: {
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 5,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
    flex: 1,
  },
  inputLabel: {
    // color: Colors.black,
    // fontSize: setValue(17),
    // paddingBottom: setYAxisValue(7),
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    fontFamily: 'Roboto-Bold',
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  textInput: {
    height: setValue(50),
    padding: 0,
    flex: 1,
    paddingHorizontal: setXAxisValue(10),
  },
  textInputWeb: {
    height: setValue(50),
    padding: setValue(5),
    paddingHorizontal: setXAxisValue(10),
  },

  multiLineTextInput: {
    padding: setValue(10),
    textAlignVertical: 'top',
    minHeight: 100,
  },
  inputContainerStyle: {
    height: setValue(45),
  },
  iconStyle: {
    height: setValue(40),
  },
  addRomoveContainer: {
    flexDirection: 'row',
    marginBottom: setValue(30),
    marginTop: setValue(10),
  },
  addRomoveButton: {
    borderWidth: 1.5,
    borderColor: Colors.theme_color,
    flex: 1,
    padding: setValue(10),
    // margin: setValue(10),
    borderRadius: setValue(5),
  },
  addRomoveButtonText: {
    color: textColor,
    textAlign: 'center',
  },
  cameraIcon: {
    backgroundColor: Colors.white,
    padding: 5,
    paddingHorizontal: setValue(7),
    borderRadius: setValue(5),
    position: 'absolute',
    alignSelf: 'center',
    top: '40%',
  },
  isNewProduct: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
  },
  viewContainer: {
    flexDirection: 'row',
  },
  scanButtonContainer: {
    width: '20%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  scanButton: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  scanBarcodeContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: -6,
  },
  otherSectionContainer: {
    backgroundColor: Colors.white,
    borderRadius: setValue(10),
    marginBottom: setYAxisValue(10),
    paddingVertical: setYAxisValue(15),
    paddingHorizontal: setXAxisValue(15),
    ...wrapper,
  },
  optionGroupTitle: {
    fontSize: setXAxisValue(16),
    fontFamily: 'Roboto-Bold',
    color: Colors.black20,
    marginTop: 0,
    marginBottom: 5,
  },
  variantType: {
    fontSize: setXAxisValue(16),
    color: Colors.black20,
    marginTop: 0,
    marginBottom: 5,
  },
  variantTitle: {
    fontSize: setXAxisValue(16),
    width: '40%',
    color: Colors.black,
    fontFamily: 'Roboto-Bold',
  },
});
