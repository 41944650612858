import axios from 'axios';
import get from 'lodash.get';
import React, {Component} from 'react';
import {
  FlatList,
  TouchableOpacity,
  View,
  ActivityIndicator,
  Platform,
} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  AppText,
  MainContainer,
  MainHeader,
  NoRecordFound,
  SubscriptionItem,
} from '../../components';
import {Colors} from '../../constants';
import {goBack} from '../../utils';
import styles from './styles';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import moment from 'moment';
import {strings} from '../../i18n';

class MerchantSubscriptionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      refreshing: false,
      orderList: [],
      limit: 10,
      offset: 0,
      loading: false,
      totalCount: 0,
    };
    this.currencySymbol = get(props.appConfig, 'currencySymbol', '₹');
    this.pageActive = 1;
    this.pageCompleted = 1;
    this.onEndReachedCalledDuringMomentum = {
      active: true,
      completed: true,
    };
  }

  componentDidMount = () => {
    const {navigation} = this.props;

    this.setState(
      {
        offset: 0,
        searchText: '',
        orderList: [],
      },
      () => {
        console.log('calling');
        this.fetchOrderList();
      },
    );
    navigation.addListener('focus', () => {
      this.handleRefresh();
    });
  };

  fetchOrderList = () => {
    const {limit, offset, searchText, orderList} = this.state;
    const {user} = this.props;

    let url = '/subcription/list/business?limit=' + limit;
    if (offset > 0) {
      url += '&offset=' + offset;
    }
    if (searchText) {
      url += '&search=' + searchText;
    }

    this.setState({loading: true});
    try {
      axios
        .get(url)
        .then((response) => {
          console.log('response-------------->>>>>>>>>', response);
          offset > 0
            ? this.setState({
                orderList: orderList.concat(response?.data?.results),
                loading: false,
                totalCount: response?.data?.count,
              })
            : this.setState({
                orderList: response?.data?.results,
                loading: false,
                totalCount: response?.data?.count,
              });
        })
        .catch((error) => {
          // console.log('error-->', error);
          // this.props.onpenAlert(error?.response?.message);
          this.setState({loading: false});
        });
    } catch (error) {
      this.setState({loading: false});
      this.props.onpenAlert(error?.response?.message);
      console.log('error---->', error);
    }
  };

  handleRefresh = () => {
    this.setState(
      {
        offset: 0,
        searchText: '',
        orderList: [],
      },
      () => this.fetchOrderList(),
    );
  };

  handleLoadMore = (status) => {
    const {offset, orderList} = this.state;
    console.log('aa->');
    this.setState(
      {
        offset: orderList.length,
      },
      () => this.fetchOrderList(),
    );
  };

  renderCurrentOrders = () => {
    const {orderList, limit, totalCount} = this.state;
    const {appConfig} = this.props;
    let currencySymbol = get(appConfig, 'currencySymbol', '₹');

    return (
      <View style={{paddingTop: 10, flex: 1}}>
        <FlatList
          data={orderList}
          renderItem={({item}) => (
            <SubscriptionItem item={item} navigation={this.props.navigation} />
          )}
          refreshing={this.state.refreshing}
          onRefresh={() => this.handleRefresh('active')}
          keyboardShouldPersistTaps="always"
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          keyExtractor={(item) => item.id}
          onEndReached={() => {
            if (orderList.length < 7) {
              return;
            }
            orderList.length > limit - 1 &&
              totalCount != orderList.length &&
              this.handleLoadMore('active');
          }}
          onEndReachedThreshold={0.5}
          onMomentumScrollBegin={() => {
            this.onEndReachedCalledDuringMomentum.active = false;
          }}
          ListFooterComponent={() =>
            orderList?.length !== totalCount ? (
              <View style={{padding: Platform.OS == 'web' ? 30 : 20}}>
                <ActivityIndicator size="large" color="#000" />
              </View>
            ) : (
              <View style={{height: 50}} />
            )
          }
        />
      </View>
    );
  };

  render() {
    const {orderList, offset, loading} = this.state;
    const {user} = this.props;

    return (
      <View style={styles.container}>
        <MainHeader
          title={strings('subscriptionHistory.title')}
          leftIcon="back"
          leftPress={() => goBack(this.props.navigation)}
        />
        <MainContainer useView>
          {/* <View style={styles.textInputContainer}>
            <TextInput
              ref={(input) => {
                this.passwordInput = input;
              }}
              underlineColorAndroid="transparent"
              autoCapitalize="none"
              autoCorrect={false}
              placeholder={strings('transactionHistoryScreen.search')}
              placeholderTextColor={Colors.color30}
              style={styles.textInput}
              returnKeyType="done"
              value={this.state.searchText}
              onChangeText={(text) => {
                this.setState({searchText: text, offset: 0}, () =>
                  this.fetchOrderList(),
                );
              }}
            />

            {this.state.searchText.length === 0 ? (
              <TouchableOpacity style={styles.inputRightSide}>
                <Icon name="search" size={22} color={Colors.black20} />
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                style={styles.inputRightSide}
                onPress={() => this.handleRefresh()}
              >
                <Icon name="cross" size={22} color="gray" />
              </TouchableOpacity>
            )}
          </View> */}

          {offset == 0 && loading ? (
            <View
              style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}
            >
              <ActivityIndicator size="large" color={Colors.theme_color} />
            </View>
          ) : (
            <View useView style={{flex: 1}}>
              {orderList?.length == 0 ? (
                <NoRecordFound
                  title="Orders"
                  message="We'll notify you when there is something now!"
                  hideImage={true}
                />
              ) : (
                this.renderCurrentOrders()
              )}
            </View>
          )}
        </MainContainer>
      </View>
    );
  }
}

export default connect(
  (state) => ({
    appConfig: state.config,
    user: state.auth.user,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setLoading: CommonActions.setLoading,
        onpenAlert: (content) =>
          CommonActions.setAlert({visible: true, content: content}),
      },
      dispatch,
    ),
)(MerchantSubscriptionList);
