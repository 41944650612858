import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {View, Platform, TextInput, TouchableOpacity} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import CommonAction from '../../../redux/reduxsauce/commonRedux';
import {
  AppConfigCheckbox,
  AppText,
  CheckBox,
  DeliveryRistComp,
  DropDownInput,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../../components';
import {Colors} from '../../../constants';
import {strings} from '../../../i18n';
import {goBack, setValue} from '../../../utils';
import styles from './styles';
import {getAppConfig} from '../../../redux/reduxsauceActions/configActions';

const TableSetting = (props) => {
  const config = useSelector((state) => state.config);
  const dispatch = useDispatch();

  const [restaurantConfig, setRestaurantConfig] = useState({
    autoAccept: false,
    cancelFromUser: false,
    table_booking_interval: '30',
    table_booking_price_per_person: '0',
    enable_table_booking: false,
  });

  useEffect(() => {
    const setting = config;
    setRestaurantConfig({
      ...restaurantConfig,
      autoAccept: setting?.restaurant?.autoAccept,
      cancelFromUser: setting?.restaurant?.cancelFromUser || false,
      table_booking_interval:
        setting?.restaurant?.table_booking_interval.toString() || '30',
      table_booking_price_per_person:
        setting?.restaurant?.table_booking_price_per_person.toString() || '0',
      enable_table_booking: setting?.restaurant?.enable_table_booking || false,
    });
  }, []);

  const saveOrderConfigData = async () => {
    if (restaurantConfig?.enable_table_booking) {
      if (parseFloat(restaurantConfig?.table_booking_price_per_person) < 1) {
        dispatch(
          CommonAction.setAlert({
            visible: true,
            content: 'Table booking price per person is not less then 1',
          }),
        );
        return;
      }
    }
    dispatch(CommonAction.setLoading(true));
    try {
      const response = await axios.patch(
        `/business/update/business-setting/${config?.businessId}`,
        {
          restaurant: {
            ...config?.restaurant,
            ...restaurantConfig,
            table_booking_price_per_person: parseFloat(
              restaurantConfig?.table_booking_price_per_person,
            ),
            table_booking_interval: parseInt(
              restaurantConfig?.table_booking_interval,
            ),
          },
        },
      );
      dispatch(getAppConfig());
      dispatch(CommonAction.setLoading(false));
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: strings('subscriptionSetting.alert.success'),
        }),
      );
      goBack(navigation);
    } catch (error) {
      dispatch(CommonAction.setLoading(false));
      console.log('error', error.response);
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: error?.response?.message || 'Something went wrong :(',
        }),
      );
    }
  };

  return (
    <View
      style={Platform.OS === 'web' ? styles.webContainer : styles.container}
    >
      <MainHeader
        title={strings('tableSettingScreen.title')}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer useView>
        <View style={[styles.orderSettingContainer]}>
          <AppConfigCheckbox
            icon={'foodForkDrink'}
            title={strings('tableSettingScreen.enableTableBooking')}
            desc={strings('tableSettingScreen.enableTableBookingDesc')}
            checked={restaurantConfig?.enable_table_booking}
            onChangeCheck={(checked) => {
              console.log('checked', checked);
              setRestaurantConfig({
                ...restaurantConfig,
                enable_table_booking: checked,
              });
            }}
          />
          <AppConfigCheckbox
            icon={'foodForkDrink'}
            title={strings('tableSettingScreen.autoAccept')}
            desc={strings('tableSettingScreen.autoAcceptDesc')}
            checked={restaurantConfig?.autoAccept}
            onChangeCheck={(checked) =>
              setRestaurantConfig({
                ...restaurantConfig,
                autoAccept: checked,
              })
            }
          />

          <AppConfigCheckbox
            icon={'foodForkDrink'}
            title={strings('tableSettingScreen.enableCancel')}
            desc={strings('tableSettingScreen.enableCancelDesc')}
            checked={restaurantConfig?.cancelFromUser}
            onChangeCheck={(checked) =>
              setRestaurantConfig({
                ...restaurantConfig,
                cancelFromUser: checked,
              })
            }
          />

          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('tableSettingScreen.bookingInterval')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              keyboardType="number-pad"
              autoCorrect={false}
              placeholder={'eg. 30'}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              value={restaurantConfig.table_booking_interval}
              onChangeText={(text) => {
                setRestaurantConfig({
                  ...restaurantConfig,
                  table_booking_interval: text,
                });
              }}
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
            />
          </View>

          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('tableSettingScreen.pricePerPerson')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              keyboardType="number-pad"
              autoCorrect={false}
              placeholder={'eg. 10'}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              value={restaurantConfig.table_booking_price_per_person}
              onChangeText={(text) => {
                setRestaurantConfig({
                  ...restaurantConfig,
                  table_booking_price_per_person: text,
                });
              }}
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
            />
          </View>

          <TouchableOpacity
            style={styles.settingView}
            onPress={() => {
              props.navigation.navigate('floors');
            }}
          >
            <AppText style={styles.title}>
              {strings('tableSettingScreen.updateTable')}
            </AppText>
            <Icon name="forward" size={20} />
          </TouchableOpacity>
        </View>

        {Platform.OS == 'web' && (
          <LongButton
            text={strings('subscriptionSetting.btn')}
            style={{marginTop: setValue(10), marginBottom: setValue(45)}}
            onPress={saveOrderConfigData}
          />
        )}
      </MainContainer>
      {Platform.OS !== 'web' && (
        <LongButton
          text={strings('subscriptionSetting.btn')}
          style={{
            width: '100%',
            borderRadius: 0,
          }}
          onPress={saveOrderConfigData}
        />
      )}
    </View>
  );
};

export default TableSetting;
