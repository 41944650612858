import Constants from 'expo-constants';
import {StyleSheet, Platform} from 'react-native';

import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 5,
};
export default StyleSheet.create({
  homeContainer: {
    flex: 1,
  },
  headerContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: Platform.OS != 'web' ? setValue(7) : setValue(10),
    paddingTop: Platform.OS != 'web' ? setValue(28) : setValue(10),
    backgroundColor: Colors.theme_color,
  },
  headerTitle: {
    fontWeight: 'bold',
    fontSize: setValue(25),
    color: Colors.white,
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  mainContainer: {
    marginTop: setValue(15),
    flex: 1,
  },
  signUpCardContainer: {
    backgroundColor: Colors.white,
    flex: 1,
    flexDirection: 'row',
    padding: setValue(10),
    borderRadius: 8,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
    justifyContent: 'space-between',
  },
  welcomeText: {
    fontSize: setXAxisValue(12),
    marginBottom: setYAxisValue(10),
    lineHeight: setYAxisValue(17),
  },
  textView: {
    width: '60%',
  },
  imageView: {
    width: '40%',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  newsFeedImage1: {
    width: '100%',
    height: setValue(130),
    borderRadius: 8,
  },
  newFeedContainer: {
    backgroundColor: Colors.white,
    borderRadius: 8,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
    marginTop: setValue(15),
    justifyContent: 'center',
    alignItems: 'center',
  },
  topOfferItemView: {
    padding: setValue(5),
    width: setValue(110),
    backgroundColor: Colors.white,
    marginRight: setXAxisValue(10),
    borderRadius: 15,
    justifyContent: 'center',
  },
  topOfferItemImage: {
    width: setValue(100),
    height: setValue(70),
    borderRadius: 8,
  },
  topOfferItemText: {
    fontSize: setXAxisValue(12),
    marginVertical: setYAxisValue(8),
  },
  topOfferTitle: {
    fontSize: setXAxisValue(14),
    marginVertical: setYAxisValue(15),
  },
  extraSpaceView: {
    height: setYAxisValue(75),
  },
  fullScreenImage: {
    width: '100%',
    height: '85%',
    borderRadius: 8,
  },
  webView: {
    width: '100%',
    height: setValue(300),
  },
  numberLeft: {
    fontSize: setXAxisValue(14),
    marginRight: setXAxisValue(5),
  },
  rmText: {
    fontSize: setXAxisValue(10),
  },
  creditText: {
    fontSize: setXAxisValue(16),
  },

  moneyText: {
    fontSize: setXAxisValue(12),
  },
  rowView: {
    flexDirection: 'row',
  },
  viewTopFeatures: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingLeft: 5,
  },
  imageBackgroud: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    width: setValue(120),
  },
  expoIcon: {
    paddingTop: 5,
    color: Colors.primary_color,
  },
  featureButtonText: {
    fontSize: setXAxisValue(12),
    color: Colors.primary_color,
  },
  featureButtonMoney: {
    fontSize: setXAxisValue(14),
    flexDirection: 'row',

    color: Colors.primary_color,
  },
  tooltip: {
    padding: setValue(10),
    textAlign: 'center',
  },
  merchantCardContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  merchantCardItem: {
    // borderWidth: 1,
    flexDirection: 'row',
    backgroundColor: Colors.white,
    flex: 1,
    // width: '31%',
    borderRadius: 5,
    padding: 10,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
  },
  merchantCardItemReport: {
    flexDirection: 'row',
    backgroundColor: Colors.white,
    width: '48%',
    borderRadius: 5,
    padding: 10,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
  },

  topSliderContainer: {
    // marginTop: 15,
    height: setValue(180),
  },
  topSliderContainerWeb: {
    marginTop: 15,
    height: setValue(250),
    // borderRadius:5,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
  },
  topSliderItem: {
    height: '100%',
    borderRadius: 5,
  },
  topSliderStaticItem: {
    height: '100%',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    backgroundColor: Colors.white,
  },
  topSliderImage: {
    aspectRatio: 4 / 2,
    // height: '100%',
    width: '100%',
    borderRadius: 5,
  },
  topSliderImageWeb: {
    display: 'flex',
    height: '100%',
    width: '100%',
    borderRadius: 5,
  },
  productContainer: {
    borderRadius: 5,
    marginLeft: -7,
  },
  addTopProductItemButton: {
    flex: 1,
    width: setValue(200),
    borderRadius: 5,
    padding: 0,
    backgroundColor: Colors.white,
    marginHorizontal: 7,
    marginBottom: 4,
    justifyContent: 'center',
    alignItems: 'center',
    ...shadow,
  },
  addTopProductItemButtonWeb: {
    display: 'flex',
    height: 200,
    width: setValue(200),
    borderRadius: 5,
    backgroundColor: Colors.white,
    marginHorizontal: 7,
    marginBottom: 4,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 5,
  },
  addTopProductItemButtonText: {
    flex: 1,
    height: '100%',
    display: 'flex',
    borderWidth: 2,
    textAlign: 'center',
    textAlignVertical: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  addTopProductItemButtonTextWeb: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    textAlign: 'center',
  },
  addBannerCardBtn: {
    backgroundColor: Colors.white,
    borderRadius: 5,
    elevation: 5,
  },
  addBannerCardBtnWeb: {
    backgroundColor: Colors.white,
    borderRadius: 5,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 5,
  },
  showSetUpCards: {
    backgroundColor: Colors.white,
    borderRadius: 5,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
    marginBottom: setYAxisValue(15),
    padding: setValue(10),
  },
  showReportCards: {
    backgroundColor: Colors.white,
    borderRadius: 5,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
    marginTop: setYAxisValue(15),
    padding: setValue(10),
  },
  setUpCardHeader: {
    color: Colors.black60,
    fontSize: setValue(16),
    fontFamily: 'Roboto-Bold',
    marginBottom: 5,
  },
  setUpCardDesc: {
    color: Colors.black60,
    fontSize: setValue(15),
    fontFamily: 'Roboto-Regular',
    textAlignVertical: 'center',
    minHeight: setValue(50),
    marginLeft: 5,
  },
  setUpButton: {
    backgroundColor: Colors.theme_color,
    // paddingHorizontal: setXAxisValue(15),
    width: setXAxisValue(120),
    alignItems: 'center',
    paddingVertical: setYAxisValue(10),
    borderRadius: setValue(5),
  },
  cancelButton: {
    width: setXAxisValue(100),
    alignItems: 'center',
    paddingVertical: setYAxisValue(8),
    borderRadius: setValue(5),
    borderWidth: 2,
    borderColor: Colors.theme_color,
    marginLeft: setXAxisValue(15),
  },
  setUpButtonText: {
    color: Colors.white,
    fontWeight: '700',
  },
  welcomeText: {
    fontSize: setXAxisValue(12),
    marginBottom: setYAxisValue(10),
    lineHeight: setYAxisValue(17),
  },
  textView: {
    // width: '60%',
    flex: 1,
    paddingLeft: 15,
  },
  imageView: {
    width: '40%',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  merchentCardContainer: {
    // flexDirection: 'row',
    // flex: 1,
    width: '50%',
    marginBottom: setValue(14),
  },
  cardContainer: {
    ...shadow,
    flexDirection: 'row',
    marginBottom: setValue(14),
    borderRadius: 12,
    backgroundColor: Colors.white,
  },
  invoiceCardContainer: {
    flexDirection: 'row',
    justifyContent:"center",
    alignItems:"center",
    borderRadius: 12,
    backgroundColor: Colors.white,
    marginBottom: setValue(14),
    padding: setXAxisValue(15),
    ...shadow,
  },
  infoContainer: {
    width: '75%',
    flexDirection: 'row',
    paddingHorizontal: setXAxisValue(15),
    paddingVertical: setYAxisValue(20),
    alignItems: 'center',
  },
  avatar: {
    width: setXAxisValue(70),
    height: setXAxisValue(70),
    borderRadius: setXAxisValue(70) / 2,
  },
  qrContainer: {
    width: '25%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.white,
    paddingHorizontal: setXAxisValue(15),
    paddingVertical: setYAxisValue(20),
    borderRadius: 12,
  },
  infoContent: {
    marginLeft: setXAxisValue(15),
    width: '65%',
  },
  name: {
    fontSize: setXAxisValue(16),
    color: Colors.black20,
    fontWeight: '700',
    textTransform: 'capitalize',
  },
  phone: {
    fontSize: setXAxisValue(16),
    color: Colors.black20,
    marginVertical: setYAxisValue(7),
    letterSpacing: setXAxisValue(2),
  },
  email: {
    fontSize: setXAxisValue(12),
    color: Colors.black20,
    marginVertical: setYAxisValue(7),
  },
  date: {
    fontSize: setXAxisValue(11),
    color: Colors.black20,
  },
  deshView: {
    borderBottomWidth: 4,
    marginTop: 5,
    borderColor: Colors.theme_color,
    width: 15,
  },
  titleText: {
    marginTop: 5,
    color: Colors.black60,
    fontSize: 13,
  },
  switchContainer: {
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  appVersionContainer: {
    alignItems: 'center',
    // width: '100%',
    padding: 15,
    marginTop: 30,
    alignSelf: 'center',
    paddingHorizontal: 30,
    borderTopWidth: 1,
    borderTopColor: Colors.color30,
  },
  appVersionText: {
    color: Colors.color30,
  },
});
