import React from 'react';
import {TouchableWithoutFeedback, View} from 'react-native';
import AppText from '../AppText';
import Icon from '../Icon';
import {dateTimeFormat} from '../../utils';
import styles from './styles';
import {Colors} from '../../constants';

export default function NotificationItem(props) {
  const {item} = props;
  return (
    <TouchableWithoutFeedback onPress={() => props.onPressNotification()}>
      <View style={styles[true]}>
        <View style={{flex: 9}}>
          <AppText style={styles.title}>{item.title}</AppText>
          <AppText style={styles.content}>{item.description}</AppText>
          <AppText style={styles.date}>
            {dateTimeFormat((item.created_at))}
          </AppText>
        </View>
        <View style={{flex: 0.5}}>
          <Icon name="forward" color={Colors.theme_color} size={16} />
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
}
