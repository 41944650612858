import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
  borderRadius: 5,
  backgroundColor: Colors.white,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  textInputContainer: {
    height: setValue(60),
  },
  textInput: {
    height: setValue(50),
    flex: 1,
    paddingHorizontal: setXAxisValue(15),
    marginBottom: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    //
    ...shadow,
  },
  filterByDateContainer: {
    ...shadow,
    padding: setValue(15),
    marginBottom: setYAxisValue(15),
  },
  calendarContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputRightSide: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    right: setValue(15),
    alignSelf: 'center',
    top: setValue(12),
    elevation: 10,
  },
  orderItemList: {
    backgroundColor: Colors.white,
    borderRadius: setValue(5),
    padding: setValue(3),
    marginBottom: setYAxisValue(10),
  },
  vcRedeemed: {
    color: 'green',
    fontSize: setValue(10),
  },
  qrCodeScanner: {
    ...shadow,
    width: setValue(60),
    height: setValue(60),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.theme_color,
    borderRadius: setValue(60),
    position: 'absolute',
    zIndex: 10000,
    right: 10,
    bottom: 15,
  },
 
  
});
