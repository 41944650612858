import {Appearance, Platform, StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {setXAxisValue, setYAxisValue} from '../../utils';
const colorScheme = Appearance.getColorScheme();

export default StyleSheet.create({
  container: {
    // flex: 1,
    // marginVertical: 50,
    alignItems: 'center',
  },
  imageContainer: {
    padding: 5,
  },
  title: {
    marginVertical: 30,
  },
  titleText: {
    fontSize: 20,
    color: colorScheme == 'dark' ? 'gray' : 'gray',
    fontFamily: 'Roboto-Bold',
  },
  borderStyle: {
    borderColor: colorScheme == 'dark' ? 'gray' : 'gray',
    borderWidth: 2,
    minWidth: 100,
  },
  message: {
    marginVertical: 30,
  },
  messageText: {
    fontSize: 16,
    color: colorScheme == 'dark' ? 'gray' : 'gray',
    paddingHorizontal: 30,
    textAlign: 'center',
  },
});
