import {StyleSheet} from 'react-native';

import {Colors, Layout} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  input: {
    height: setValue(30),
    flex: 1,
    color: Colors.black,
    // paddingHorizontal: setXAxisValue(5),
    borderRadius: 5,
    // backgroundColor: Colors.white,
    // shadowOffset: {
    //   width: 0,
    //   height: 4,
    // },
    // shadowOpacity: 0.3,
    // shadowRadius: 5,
    // elevation: 8,
  },
  inputView: {
    opacity: 1,
  },
  itemView: {
    // height: setValue(50),
    paddingLeft: setXAxisValue(10),
    borderBottomColor: Colors.color30,
    borderBottomWidth: StyleSheet.hairlineWidth,
    justifyContent: 'center',
  },
  item: {
    textAlign: 'left',
    fontSize: setXAxisValue(16),
    color: Colors.black,
    padding: 15,
  },
  scroll: {
    flex: 1,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  },
  picker: {
    marginTop: setValue(50),
    width: Layout.window.width - setXAxisValue(30),
    // maxHeight: setValue(50) * 5,
    maxWidth: setValue(400),
    borderRadius: 2,
    // position: 'absolute',
    alignSelf: 'center',
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
    backgroundColor: Colors.white,
  },
  overlay: {
    // ...StyleSheet.absoluteFillObject,
    backgroundColor: Colors.overlay,
    flex: 1,
    // justifyContent: 'center',
  },
  overlayWeb: {
    backgroundColor: Colors.overlay,
    flex: 1,
    // justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    width: '100%',
  },
  downIcon: {
    // position: 'absolute',
    justifyContent: 'center',
    // alignSelf: 'center',
    alignItems: 'center',
    borderRadius: 5,
    // paddingHorizontal: setXAxisValue(5),
    // right: setXAxisValue(5),
    // top: setYAxisValue(3),
    height: setValue(30),
  },
  textInputContainer: {
    height: setValue(30),
    flexDirection: 'row',
    // flex: 1,
    justifyContent: 'center',
  },
  title: {
    marginLeft: setYAxisValue(5),
    fontSize: setXAxisValue(12),
    //
    color: Colors.black,
    marginTop: setYAxisValue(5),
  },
});
