import {Platform, StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {
  screenHeight,
  setValue,
  setXAxisValue,
  setYAxisValue,
  screenWidth,
} from '../../utils';

export default StyleSheet.create({
  overlay: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.9)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: setYAxisValue(15),
    paddingHorizontal: setXAxisValue(15),
  },
  title: {
    fontSize: setXAxisValue(25),
    textAlign: 'center',
    lineHeight: setXAxisValue(32),
    marginTop: setYAxisValue(30),
    color: Colors.white,
  },
  description: {
    fontSize: setXAxisValue(14),
    textAlign: 'center',
    lineHeight: setXAxisValue(22),
    paddingHorizontal: setXAxisValue(40),
    marginTop: setYAxisValue(5),
    color: Colors.white,
  },
  crossIcon: {
    position: 'absolute',
    top: Platform.select({web: setYAxisValue(10), default: 0}),
    left: setXAxisValue(10),
  },
  noWifi: {
    position: 'absolute',
    alignSelf: 'center',
    top: setYAxisValue(-10),
  },
  closeButton: {
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: setValue(8),
    padding: setXAxisValue(8),
    borderColor: Colors.white,
    marginTop: setYAxisValue(20),
    width: screenWidth / 3,
  },
  closeButtonText: {
    color: Colors.white,
  },
});
