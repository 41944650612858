import { Component, createElement } from 'react';

import Portal from './Portal';

class Modal extends Component {
  componentDidMount() {
    const { visible } = this.props;

    if (visible) this.handleShow();
  }

  UNSAFE_componentWillReceiveProps({ visible }) {
    if (visible && !this.props.visible) this.handleShow();
  }

  handleShow() {
    const { animationType, onShow } = this.props;

    if (!onShow) return;

    if (animationType === 'none') {
      onShow();
    } else {
      setTimeout(onShow, 300);
    }
  }

  render() {
    const { transparent, visible, animationType, children } = this.props;

    const baseStyle = {
      backgroundColor: transparent ? 'transparent' : '#fff',
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 9999
    };

    const displayStyles = {
      hidden: {
        display: 'none'
      },
      visible: {
        display: 'flex'
      }
    };

    const slideStyles = {
      hidden: {
        transform: 'translateY(100%)',
        transition: 'transform 300ms ease-in-out'
      },
      visible: {
        transform: 'translateY(0%)',
        transition: 'transform 500ms ease-in-out'
      }
    };

    const animationStyles = {
      none: displayStyles,
      fade: displayStyles,
      slide: slideStyles
    };

    const animationState = visible ? 'visible' : 'hidden';
    const animationStyle = animationStyles[animationType][animationState];

    const style = { ...baseStyle, ...animationStyle };

    return createElement(Portal, null, createElement('div', { style, children }));
  }
}

Modal.defaultProps = {
  animationType: 'none',
  onShow: () => {}
};

export default Modal;
