import {Appearance, Platform, StyleSheet} from 'react-native';
import {Colors, Layout} from '../../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../../utils';
// const colorScheme = Appearance.getColorScheme();
const wrapper = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
  marginHorizontal: setXAxisValue(5),
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  hiddenButton: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    height: '100%',
  },
  trashIcon: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: setValue(40),
    height: setValue(40),
    marginHorizontal: setXAxisValue(5),
    // ...wrapper,
  },
  addNotification: {
    width: '100%',
    borderWidth: 2,
    borderColor: Platform.OS == 'web' ? Colors.theme_color : Colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    paddingVertical: setYAxisValue(10),
    borderRadius: 5,
    marginBottom: setValue(15),
  },
  addNotificationText: {
    color: Platform.OS == 'web' ? Colors.theme_color : Colors.white,
  },
});
