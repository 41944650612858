import React, {useState, useEffect} from 'react';
import {
  Text,
  Platform,
  Modal,
  View,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';
import styles from './styles';
import {strings} from '../../i18n';
import {AppText} from '../';
import {Colors} from '../../constants';
import DropDownInput from '../DropDownInput';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import axios from 'axios';

const EditProductVeriantModal = (props) => {
  const {isVisible, onClose, productId, variantTypesList, item} = props;

  const dispatch = useDispatch();
  const textColor = Colors.white;

  const [variantDetail, setVariantDetail] = useState({
    product: productId,
    variant: null,
    additional_price: '0',
  });
  const [variantTypeList, setVariantTypeList] = useState([]);
  const [variantsList, setVariantsList] = useState([]);
  const [selectedVariantType, setSelectedVariantType] = useState(null);
  const [alert, setAlert] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setVariantTypeList(variantTypesList);
  }, [isVisible]);

  useEffect(() => {
    setSelectedVariantType(
      item?.variant?.variant_type?.id ? item?.variant?.variant_type?.id : null,
    );
    setVariantDetail({
      ...variantDetail,
      variant: item?.variant?.id ? item?.variant?.id : null,
      additional_price: item?.additional_price ? item?.additional_price : '',
    });
  }, [item]);

  const CreateVariant = async () => {
    if (!variantDetail?.variant) {
      setAlert('Please select variant');
      return;
    }
    if (!variantDetail?.additional_price) {
      setAlert('Please enter price');
      return;
    }

    setLoading(true);
    await axios
      .post('/products/product-variants/create', variantDetail)
      .then((response) => {
        console.log('response product variants create', response);
        setVariantDetail({
          ...variantDetail,
          variant: null,
          additional_price: '0',
        });
        setSelectedVariantType(null);
        setLoading(false);
        props.onSave();
      })
      .catch((error) => {
        console.log('error product variants create', error.response);
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error.response.message,
          }),
        );
        setLoading(false);
        onClose();
      });
  };

  const UpdateVariant = async () => {
    if (!variantDetail?.variant) {
      setAlert('Please select variant');
      return;
    }
    if (!variantDetail?.additional_price) {
      setAlert('Please enter price');
      return;
    }
    setLoading(true);
    await axios
      .patch('/products/product-variants/update/' + item?.id, variantDetail)
      .then((response) => {
        console.log('response product variants update', response);
        setVariantDetail({
          ...variantDetail,
          variant: null,
          additional_price: '0',
        });
        setSelectedVariantType(null);
        setLoading(false);
        props.onSave();
      })
      .catch((error) => {
        console.log('error product variants update', error.response);
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.message,
          }),
        );
        setLoading(false);
        setVariantDetail({
          ...variantDetail,
          variant: null,
          additional_price: '0',
        });
        setSelectedVariantType(null);
        onClose();
      });
  };
  return (
    <Modal animationType={'fade'} transparent visible={isVisible}>
      <View style={styles.modal}>
        <View style={styles.modalContainer}>
          <View style={styles.modalDataContainer}>
            <Text style={styles.modalTitle}>{'Create New Variant'}</Text>
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>{'Variant Type'}</AppText>
              <DropDownInput
                data={variantTypeList?.map((o) => ({
                  label: o?.type,
                  value: o?.id,
                }))}
                value={selectedVariantType}
                inputStyle={[
                  styles.textInput,
                  {
                    paddingHorizontal: 10,
                    marginTop: 10,
                  },
                ]}
                placeholder={'Select variant type'}
                onChangeText={(text) => {
                  setSelectedVariantType(text);
                  setVariantDetail({
                    product: productId,
                    variant: null,
                    additional_price: '0',
                  });
                  let obj = variantTypeList.find((o) => o.id == text);
                  setVariantsList(obj.variants);
                }}
              />
            </View>
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>{'Variant'}</AppText>
              <DropDownInput
                data={variantsList?.map((o) => ({
                  label: o?.name,
                  value: o?.id,
                }))}
                value={variantDetail?.variant}
                inputStyle={[
                  styles.textInput,
                  {
                    paddingHorizontal: 10,
                    marginTop: 10,
                  },
                ]}
                placeholder={'Select variant'}
                onChangeText={(text) => {
                  setVariantDetail({...variantDetail, variant: text});
                  text && setAlert(null);
                }}
                disabled={selectedVariantType == null}
              />
            </View>

            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>{'Additional Price'}</AppText>
              <TextInput
                underlineColorAndroid="transparent"
                placeholder={'price'}
                keyboardType="numeric"
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={
                  Platform.OS == 'web'
                    ? styles.textInputWeb
                    : styles.multiLineTextInput
                }
                value={variantDetail?.additional_price}
                onChangeText={(text) => {
                  setVariantDetail({...variantDetail, additional_price: text});
                  text && setAlert(null);
                }}
              />
            </View>
            {alert && (
              <View>
                <Text style={{color: Colors.wallet_card_red}}>{alert}</Text>
              </View>
            )}
          </View>
          <View style={styles.buttonContainer}>
            <TouchableOpacity
              style={[styles.addQuantityButton, {flex: 3}]}
              onPress={() => {
                item?.id ? UpdateVariant() : CreateVariant();
              }}
              disabled={loading}
            >
              {loading ? (
                <ActivityIndicator color={textColor} size="small" />
              ) : (
                <Text style={{color: textColor}}>{'Save'}</Text>
              )}
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.addQuantityButton, {flex: 2}]}
              onPress={() => {
                onClose();
                setVariantDetail({
                  ...variantDetail,
                  variant: null,
                  additional_price: '0',
                });
              }}
              disabled={loading}
            >
              <Text style={{color: textColor}}>
                {strings('selectVeriant.cancel')}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};
export default EditProductVeriantModal;
