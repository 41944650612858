import React, { Component } from 'react';
import { findNodeHandle, FlatList, TextInput, TouchableOpacity, TouchableWithoutFeedback, UIManager, View } from 'react-native';

import { Colors } from '../../constants';
import AppText from '../AppText';
import Icon from '../Icon';
import Modal from '../Modal';
import styles from './styles';

class LocationPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      top: 0
    };
  }

  setModalVisible = visible => {
    this.setState({ visible });
  };

  onPressItem = item => {
    this.setModalVisible(false);
    this.props.onChangeText(item);
  };

  onPress = () => {
    this.setModalVisible(true);
  };

  render() {
    const { inputStyle, inputContainerStyle, titleStyle, iconStyle, pickerStyle, title, customView } = this.props;
    return (
      <View
        style={styles.inputView}
        ref={ref => {
          this.containerRef = ref;
        }}
        onLayout={() => {
          if (this.containerRef) {
            const handle = findNodeHandle(this.containerRef);
            UIManager.measureInWindow(handle, (x, y) => {
              if (this.state.top < y && y) {
                this.setState({ top: y });
              }
            });
          }
        }}
      >
        {customView ? (
          customView(this.onPress)
        ) : (
          <TouchableOpacity onPress={this.props.onClick} style={inputContainerStyle || styles.textInputContainer} disabled={this.props.disabled}>
            {title && <AppText style={titleStyle || styles.title}>{title}</AppText>}
            <TextInput
              style={inputStyle || styles.input}
              underlineColorAndroid="transparent"
              autoCorrect={false}
              autoCapitalize="none"
              pointerEvents="none"
              editable={false}
              placeholderTextColor={Colors.color30}
              value={this.props.value}
              placeholder={this.props.placeholder}
            />
            {!this.props.disabled && (
              <View style={[styles.downIcon, iconStyle]}>
                <Icon size={25} name="location" color={Colors.color30} />
              </View>
            )}
          </TouchableOpacity>
        )}
      </View>
    );
  }
}

LocationPicker.defaultProps = {
  value: '',
  placeholder: '',
  data: [],
  onChangeText: () => {}
};

export default LocationPicker;
