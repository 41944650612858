import {translate} from 'i18n-js';
import {Platform, StyleSheet} from 'react-native';

import {Colors, Layout} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue, screenWidth} from '../../utils';

let TotalWidth =
  screenWidth > 1023 ? 992 : screenWidth - 2 * setXAxisValue(15) - 22;
const shadow = {
  shadowColor: Colors.black60,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 5,
};
export default StyleSheet.create({
  productContainer:
    screenWidth > 576
      ? {
          marginVertical: setValue(7.5),
          marginHorizontal: setValue(3),
          backgroundColor: 'white',
          marginLeft: 5,
          marginRight: 0,
          borderRadius: setValue(10),
          width:
            180 +
            180 *
              ((TotalWidth / 180 - Math.floor(TotalWidth / 180)) /
                Math.floor(TotalWidth / 180)) -
            Math.floor(TotalWidth / 180),
          ...shadow,
        }
      : {
          marginVertical: setValue(3),
          // flex: 0.5,
          width: '48%',
          marginLeft: 5,
          backgroundColor: 'white',
          borderRadius: setValue(10),
          elevation: 3,
          ...shadow,
        },
  productImage: {
    width: '100%',
    height: undefined,
    aspectRatio: 3 / 2,
    borderTopLeftRadius: setValue(10),
    borderTopRightRadius: setValue(10),
    borderColor: 'rgba(0, 0, 0, 0.4)',
  },
  productName: {
    marginTop: setYAxisValue(10),
    marginHorizontal: setXAxisValue(10),
    fontSize: setXAxisValue(13),
    width: '70%',
    fontWeight: 'bold',
  },
  productPrice: {
    marginBottom: setYAxisValue(10),
    fontSize: setXAxisValue(12),
  },
  productAddButton: {
    width: setValue(36),
    height: setValue(36),
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: Colors.theme_color,
    right: setXAxisValue(12),
    borderRadius: setValue(30),
  },
  hidePriceText: {
    // width: setValue(36),
    height: setValue(36),
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: Colors.theme_color,
    right: setXAxisValue(12),
    borderRadius: setValue(30),
  },
  outOfStock: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    alignSelf: 'center',
    backgroundColor: Colors.wallet_card_red,
    paddingHorizontal: 10,
    borderWidth: 2,
    borderRadius: setValue(5),
    borderColor: Colors.wallet_card_red,
    zIndex: 100000,
  },
  productTypeIcon: {
    width: setValue(20),
    height: setValue(20),
    marginTop: setYAxisValue(5),
    marginHorizontal: setXAxisValue(3),
  },
  productOutOfStockImage: {
    position: 'absolute',
    width: Layout.window.width / 3,
    alignSelf: 'center',
  },
  newProductBurst: {
    position: 'absolute',
    bottom: 0,
    right: 5,
  },
  discountText: {
    paddingTop: setValue(3),
    color: Colors.color30,
    fontSize: setValue(12),
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  FoodType: {
    padding: setValue(3),
    borderWidth: 1.5,
    position: 'absolute',
    right: setXAxisValue(5),
    top: setYAxisValue(5),
    borderRadius: 3,
    zIndex: 1000,
    backgroundColor: Colors.white_gray,
  },
});
