import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Platform,
  Keyboard,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {
  AppConfigCheckbox,
  AppText,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../components';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import {goBack, setValue} from '../../utils';
import styles from './styles';
import CommonActions from '../../redux/reduxsauce/commonRedux';

const EmailConfiguaration = (props) => {
  const [postEmailConfigurationData, setPostEmailConfigurationData] = useState({
    use_tls: false,
    use_ssl:false,
    host: '',
    user: '',
    password: '',
    port: null,
  });
  const [id, setId] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    fetchEmailConfiguarationData();
  }, []);

  const fetchEmailConfiguarationData = async () => {
    try {
      dispatch(CommonActions.setLoading(true));
      const response = await axios.get('/business/email/config/details');
      console.log('response', response);
      setId(response?.data?.data?.id);
      setPostEmailConfigurationData({
        host: response?.data?.data?.host,
        user: response?.data?.data?.user,
        use_tls: response?.data?.data?.use_tls,
        use_ssl: response?.data?.data?.use_ssl,
        password: response?.data?.data?.password,
        port: response?.data?.data?.port?.toString(),
      });
      dispatch(CommonActions.setLoading(false));
    } catch (error) {
      console.log('error', error);
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };

  const onSaveEmailConfiguration = async () => {
    Keyboard.dismiss();
    try {
      dispatch(CommonActions.setLoading(true));
      const response = await axios.patch(
        `/business/email/config/update/${id}`,
        postEmailConfigurationData,
      );
      console.log('response --', response);
      fetchEmailConfiguarationData();
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('emailConfig.update'),
        }),
      );
      dispatch(CommonActions.setLoading(false));
      props.navigation.goBack();
    } catch (error) {
      console.log('error', error.response);
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };
  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('settingScreen.emailConfig')}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer>
        <View style={styles.textInputContainer}>
          <Text style={styles.inputLabel}>
            {strings('emailConfig.label.host')}
          </Text>
          <TextInput
            underlineColorAndroid="transparent"
            autoCorrect={false}
            placeholder={strings('emailConfig.placeholder.host')}
            placeholderTextColor={Colors.color30}
            returnKeyType="done"
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={postEmailConfigurationData?.host}
            onChangeText={(text) =>
              setPostEmailConfigurationData({
                ...postEmailConfigurationData,
                host: text,
              })
            }
          />
        </View>
        <View style={styles.textInputContainer}>
          <Text style={styles.inputLabel}>
            {strings('emailConfig.label.user')}
          </Text>
          <TextInput
            underlineColorAndroid="transparent"
            // autoCapitalize="words"
            autoCorrect={false}
            placeholder={strings('emailConfig.placeholder.user')}
            placeholderTextColor={Colors.color30}
            returnKeyType="done"
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={postEmailConfigurationData?.user}
            onChangeText={(text) =>
              setPostEmailConfigurationData({
                ...postEmailConfigurationData,
                user: text,
              })
            }
          />
        </View>

        <View style={styles.textInputContainer}>
          <Text style={styles.inputLabel}>
            {strings('emailConfig.label.password')}
          </Text>
          <TextInput
            underlineColorAndroid="transparent"
            autoCorrect={false}
            placeholder={strings('emailConfig.placeholder.password')}
            placeholderTextColor={Colors.color30}
            returnKeyType="done"
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={postEmailConfigurationData?.password}
            onChangeText={(text) =>
              setPostEmailConfigurationData({
                ...postEmailConfigurationData,
                password: text,
              })
            }
          />
        </View>

        <AppConfigCheckbox
          title={strings('emailConfig.label.useTLS')}
          checked={postEmailConfigurationData?.use_tls}
          onChangeCheck={(checked) => {
            setPostEmailConfigurationData({
              ...postEmailConfigurationData,
              use_tls: checked,
            });
          }}
        />
         <AppConfigCheckbox
          title={"Use SSL"}
          checked={postEmailConfigurationData?.use_ssl}
          onChangeCheck={(checked) => {
            setPostEmailConfigurationData({
              ...postEmailConfigurationData,
              use_ssl: checked,
            });
          }}
        />

        <View style={styles.textInputContainer}>
          <Text style={styles.inputLabel}>
            {strings('emailConfig.label.port')}
          </Text>
          <TextInput
            underlineColorAndroid="transparent"
            autoCorrect={false}
            placeholder={strings('emailConfig.placeholder.port')}
            placeholderTextColor={Colors.color30}
            returnKeyType="done"
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={postEmailConfigurationData?.port}
            onChangeText={(text) =>
              setPostEmailConfigurationData({
                ...postEmailConfigurationData,
                port: text,
              })
            }
          />
        </View>
        {Platform.OS == 'web' && (
          <LongButton
            text={strings('emailConfig.button.addAddress')}
            style={{marginTop: setValue(10), marginBottom: setValue(45)}}
            onPress={onSaveEmailConfiguration}
          />
        )}
      </MainContainer>
      {Platform.OS !== 'web' && (
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            style={styles.buttonButton}
            onPress={onSaveEmailConfiguration}
          >
            <AppText style={styles.buttonText}>
              {strings('emailConfig.button.save')}
            </AppText>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

export default EmailConfiguaration;
