import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {screenWidth, setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 8,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  timeContainer: {
    marginBottom: 10,
  },
  textInputContainer: {
    flex: 1,
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 3,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
  },
  inputLabel: {
    fontFamily: 'Roboto-Bold',
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  calendarContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#ccc',
    padding: setValue(10),
  },
});
