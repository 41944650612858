import axios from 'axios';
import {connectActionSheet} from '@expo/react-native-action-sheet';
import {Camera} from 'expo-camera';
import * as ImagePicker from 'expo-image-picker';
import React, {useState, useEffect} from 'react';
import {
  Image,
  Keyboard,
  Platform,
  TextInput,
  TouchableOpacity,
  View,
  ScrollView,
  ActivityIndicator,
  Appearance,
  FlatList,
  Text,
  StyleSheet,
} from 'react-native';
import {connect, useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  AppText,
  DropDownInput,
  Icon,
  MainContainer,
  MainHeader,
  VariantItem,
  SpecificationItem,
  CheckBox,
  AppConfigCheckbox,
} from '../../../components';
import {Colors, Images} from '../../../constants';
import {strings} from '../../../i18n';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {goBack, setValue, setXAxisValue} from '../../../utils';
import styles from './styles';
import {getProducts} from '../../../redux/reduxsauceActions/productActions';
import {AntDesign} from '@expo/vector-icons';
import EditProductVeriantModal from '../../../components/EditProductVariantModal';
import {SwipeRow} from '../../../components';
import EditProductImageModal from '../../../components/EditProductImageModal';

const colorScheme = Appearance.getColorScheme();
const textColor = colorScheme == 'dark' ? Colors.black60 : Colors.black60;
const EditProductScreen = (props) => {
  const config = useSelector((state) => state.config);
  const {categories} = props;
  const dispatch = useDispatch();

  const [productData, setProductData] = useState({
    product_name: '',
    product_category: categories[0]?.name || '',
    product_sub_category: '',
    product_price: '0',
    product_qty_type: 'Always available stock',
    product_qty: '1',
    product_description: '',
    product_specification: null,
    product_tax: '0',
    product_hsn: '',
    product_barcode: '',
    product_unit: 'Pc',
    product_keywords: '',
    product_discount: '0',
    product_weight: '0',
    parts_number: '',
    available_for_subscription: false,
  });

  const unitsArray = [
    'kg',
    'Gm',
    'Ml',
    'Mm',
    'Ft',
    'Box',
    'Pair',
    'Tablet',
    'Bundle',
    'Day',
    'Month',
    'Year',
    'Minute',
    'Ltr',
    'Pc',
    'Inch',
    'Pound',
    'Km',
    'Service',
  ];
  const [images, setImages] = useState([]);
  const [specification, setSpecification] = useState([]);
  const [variantsArray, setVariantsArray] = useState([]);
  const [isNewProduct, setIsNewProduct] = useState(true);
  const [isVeg, setIsVeg] = useState('veg');
  const [imageLoading, setImageLoading] = useState(false);
  const [imageLoadingIndex, setImageLoadingIndex] = useState(null);
  const [variantTypesList, setVariantTypesList] = useState([]);
  // const [variantList, setVariantList] = useState([]);
  const [variantModal, setVariantModal] = useState({
    show: false,
    item: null,
  });
  const [productVariant, setProductVariant] = useState(
    props.route.params?.item?.variant,
  );
  const [isProductImageModalVisible, setIsProductImageModalVisible] = useState({
    show: false,
    item: null,
  });

  let ar1 = config?.product?.aspectRatio ? config?.product?.aspectRatio[0] : 4;
  let ar2 = config?.product?.aspectRatio ? config?.product?.aspectRatio[1] : 3;

  let ratio = config?.product?.aspectRatio ? ar1 / ar2 : 4 / 3;

  const checkCameraPermission = async () => {
    const {status} = await Camera.requestCameraPermissionsAsync();
    if (status === 'granted') {
      props.navigation.navigate('scan-product-barcode', {
        item: props.route.params?.item,
      });
    }
  };

  useEffect(() => {
    if (props.route.params?.item) {
      let item = props.route.params?.item;
      let category =
        categories?.find((o) => o.id == props.route?.params?.item?.category) ||
        null;

      let subcategory = category?.sub_category?.find(
        (o) => o.id == props.route?.params?.item?.sub_category,
      );
      console.log('item =', item);

      setProductData({
        ...productData,
        product_name: item?.name,
        product_description: item?.description ? item?.description : '',
        product_price: parseInt(item.price).toString(),
        product_qty: parseInt(item.stock_qty).toString(),
        product_barcode: item.barcode ? item.barcode : '',
        product_tax: parseInt(item.tax).toString(),
        product_category: category?.name,
        product_sub_category: subcategory?.name,
        product_weight: parseFloat(item?.weight).toString(),
        product_discount: parseInt(item.discount).toString(),
        product_unit: item?.unit,
        product_keywords: item?.keywords ? item?.keywords : '',
        product_qty_type: item?.always_available_stock
          ? 'Always available stock'
          : 'Limited stock',
        parts_number: item?.parts_number,
        available_for_subscription: item?.available_for_subscription,
      });
      item?.images?.length > 0 && setImages(item?.images);
      setIsNewProduct(item?.is_new);
      item?.food_type == 'non-veg' ? setIsVeg('non-veg') : setIsVeg('veg');

      item?.details?.length && setSpecification(item?.details);
      item?.variants?.length && setVariantsArray(item?.variants);
    }
  }, [props.route.params?.item]);

  useEffect(() => {
    if (props.route.params?.barcode) {
      setProductData({
        ...productData,
        product_barcode: props.route.params?.barcode,
      });
    }
  }, [props.route.params?.barcode]);

  const onSave = () => {
    const {config} = props;
    Keyboard.dismiss();

    if (productData.product_name.trim() == '') {
      props.openAlert(strings('editProductScreen.alert.name'));
      return;
    }
    // if (config?.product?.showCategory && productData.product_category == '') {
    //   props.openAlert(strings('editProductScreen.alert.category'));
    //   return;
    // }
    if (
      productData.product_price.trim() == '' ||
      productData.product_price == 0
    ) {
      props.openAlert(strings('editProductScreen.alert.price'));
      return;
    }
    if (productData.stock_qty) {
      props.openAlert(strings('editProductScreen.alert.qty'));
      return;
    }
    let specificationArray = specification.filter((o) => {
      if (o.label.trim() != '' && o.label != undefined && o.label != null) {
        if (
          o.type == 'text' ||
          (o.type == 'longText' && o.value != null && o.value.trim() != '')
        ) {
          o.label = o.label.trim();
          o.value = o.value.trim();
          return o;
        } else if (o.type == 'array' && o.value.length > 0) {
          let valueArray = o.value.filter((o) => o != '');
          if (valueArray?.length > 0) {
            valueArray = valueArray.map((item) => {
              return item?.trim();
            });
            o.value = valueArray;
            o.label = o.label.trim();
            return o;
          }
        }
      }
    });

    let ArrayOfVariants = variantsArray.filter((o) => {
      if (o.type.trim() != '' && o.type != null && o.type != undefined) {
        if (o.variants?.length > 0) {
          let array = o.variants.filter(
            (o) => o.value.trim() != '' && o.price.trim() != '',
          );
          if (array?.length > 0) {
            array = array.map((item) => {
              return {
                value: item?.value?.trim(),
                price: item?.price?.trim(),
              };
            });
            o.type = o.type.trim();
            o.variants = array;

            return {};
          }
        }
      }
    });

    let categoryId = categories?.filter(
      (o) => o?.name == productData?.product_category,
    )[0]?.id;

    let body = {
      business: config.businessId,
      name: productData.product_name.trim(),
      price: productData.product_price
        ? parseInt(productData.product_price)
        : 0,
      discount: parseInt(productData.product_discount),
      weight: parseFloat(productData.product_weight).toFixed(2),
      keywords: productData.product_keywords.trim(),
      stock_qty: productData.product_qty
        ? parseInt(productData.product_qty)
        : 0,
      tax: productData.product_tax ? parseInt(productData.product_tax) : 0,
      variants: null,
      details: null,
      always_available_stock:
        productData.product_qty_type == 'Always available stock',
    };

    if (productData.product_description) {
      body = {
        ...body,
        description: productData.product_description.trim(),
      };
    }
    if (config?.product?.showCategory && productData.product_category) {
      body = {
        ...body,
        category: categoryId,
      };

      if (productData.product_sub_category) {
        let subCategory = categories?.find(
          (o) => o?.id == categoryId,
        )?.sub_category;
        let subCategoryId = subCategory?.filter(
          (o) => o.name == productData.product_sub_category,
        )[0]?.id;

        body = {
          ...body,
          sub_category: subCategoryId,
        };
      }
    }
    if (config?.product?.showVariants && ArrayOfVariants?.length > 0) {
      body = {
        ...body,
        variants: ArrayOfVariants,
      };
    }
    if (specificationArray?.length > 0) {
      body = {
        ...body,
        details: specificationArray,
      };
    }

    if (productData.product_barcode) {
      body = {
        ...body,
        barcode: productData.product_barcode,
      };
    }
    if (productData.product_unit) {
      body = {
        ...body,
        unit: productData.product_unit.trim(),
      };
    }
    if (config.type == 'restaurants') {
      body = {
        ...body,
        food_type: isVeg.trim(),
      };
    }

    if (config?.domain == 'partsportal.in') {
      body = {
        ...body,
        parts_number: productData?.parts_number
          ? productData?.parts_number.trim()
          : null,
      };
    }

    if (config?.product?.availableForSubscription) {
      body = {
        ...body,
        available_for_subscription: productData?.available_for_subscription,
      };
    }

    props.setLoading(true);
    if (props.route.params?.item) {
      axios
        .put('/products/update/' + props.route.params?.item.id, body)
        .then(async (response) => {
          console.log('response updated product -->', response.data);
          props.getProducts();
          goBack(navigation);
          props.setLoading(false);
        })
        .catch((error) => {
          console.log('error updated product -->', error?.response);
          props.openAlert(error?.response?.message);
          props.setLoading(false);
        });
    } else {
      axios
        .post('/products/create', body)
        .then(async (response) => {
          console.log('response -->', response?.data);
          await saveNewProductImages(response?.data?.id);
        })
        .catch((error) => {
          console.log('error -->', error.response);
          props.openAlert(error?.response?.message);
          props.setLoading(false);
        });
    }
  };

  const dataURLtoFile = (dataurl) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], 'image1.jpg', {type: mime});
  };

  const saveNewProductImages = (id) => {
    console.log('calling');
    Promise.all(
      images.map(async (o, index) => {
        let blob = await (await fetch(o.photo)).blob();
        let body = new FormData();
        body.append('product', id);
        Platform.OS == 'web'
          ? body.append('photo', await dataURLtoFile(o.photo))
          : body.append('photo', {
              uri: o.photo,
              type: blob.type,
              name: blob.data.name,
            });

        console.log('body', body);

        return axios.post('/products/images/create', body);
      }),
    )
      .then((response) => {
        console.log('res-->', response);
        props.getProducts();
        props.setLoading(false);
        goBack(navigation);
      })
      .catch((error) => {
        console.log('error -->', error?.response);
        props.openAlert(error?.response?.message);
        props.setLoading(false);
      });
  };

  // change in any specification field
  const onSpecificationChange = (label, value, type, index) => {
    let arr = [...specification];
    if (label != undefined) {
      arr[index] = {
        ...arr[index],
        label: label,
      };
      setSpecification(arr);
    } else if (value != undefined) {
      arr[index] = {
        ...arr[index],
        value: value,
      };
      setSpecification(arr);
    } else if (type != undefined) {
      if (type == 'array') {
        arr[index] = {
          ...arr[index],
          type: type,
          value: [],
        };
      } else {
        arr[index] = {
          ...arr[index],
          type: type,
          value: '',
        };
      }
      setSpecification(arr);
    }
  };

  // add specification  fields(label, type ,  value)
  const addSpecificationField = () => {
    let index = specification?.length;

    if (index) {
      if (
        specification[index - 1].label == null ||
        specification[index - 1].label == undefined ||
        specification[index - 1].label == ''
      ) {
        props.openAlert(strings('editProductScreen.alert.label'));
      } else if (
        specification[index - 1].value == null ||
        specification[index - 1].value == undefined ||
        specification[index - 1].value == ''
      ) {
        props.openAlert(strings('editProductScreen.alert.value'));
      } else {
        setSpecification([
          ...specification,
          {label: '', value: null, type: 'text'},
        ]);
      }
    } else {
      setSpecification([
        ...specification,
        {label: '', value: null, type: 'text'},
      ]);
    }
  };

  // remove last specification field
  const removeSpecificationField = () => {
    let specifications = [...specification];
    specifications.pop();
    setSpecification([...specifications]);
  };

  // when specification type array then add value field
  //here value is an array
  const AddValueField = (index) => {
    let valueArray = [...specification[index].value];

    let fullArray = [...specification];

    if (valueArray.length) {
      if (valueArray[valueArray.length - 1] == '') {
        props.openAlert(
          strings('editProductScreen.alert.point') +
            ' ' +
            valueArray.length +
            strings('editProductScreen.alert.pointValue'),
        );
      } else {
        valueArray.push('');
        fullArray[index] = {
          ...fullArray[index],
          value: valueArray,
        };
        setSpecification(fullArray);
      }
    } else {
      valueArray.push('');
      fullArray[index] = {
        ...fullArray[index],
        value: valueArray,
      };
      setSpecification(fullArray);
    }
  };

  // when specification type array then remove last value field
  //here value is an array
  const RemoveValueField = (index) => {
    let valueArray = [...specification[index].value];
    let fullArray = [...specification];

    valueArray.pop();
    fullArray[index] = {
      ...fullArray[index],
      value: valueArray,
    };
    setSpecification(fullArray);
  };

  // when value is array then that handle specific value filed
  const SpecificationPointsChange = (text, i, index) => {
    let valueArray = [...specification[index].value];
    let fullArray = [...specification];

    valueArray[i] = text;

    fullArray[index] = {
      ...fullArray[index],
      value: valueArray,
    };

    setSpecification(fullArray);
  };

  // Add new variants field (type and variants array )
  const AddVariantFields = () => {
    setVariantsArray([
      ...variantsArray,
      {type: '', variants: [{value: '', price: ''}]},
    ]);
  };

  const getVariantTypesList = () => {
    axios
      .get('/products/variant-type/list/' + config?.businessId)
      .then((response) => {
        console.log('response of variant type list===', response);
        setVariantTypesList(response?.data);
      })
      .catch((error) => {
        console.log('error in response of variant type list===', error);
      });
  };

  // const getVariantList = () => {
  //   axios
  //     .get('/products/variant/list/' + config?.businessId)
  //     .then((response) => {
  //       console.log('response of variant list===', response);

  //       setVariantList(response?.data);
  //     })
  //     .catch((error) => {
  //       console.log('error in response of variant list===', error);
  //     });
  // };

  const getProductDetail = async () => {
    dispatch(CommonActions.setLoading(true));
    await axios
      .get('/products/details/' +  props?.route?.params?.item?.id)
      .then((response) => {
        console.log('product ========>', response.data);
        setImages(response.data.images);
        setProductVariant(response.data.variant)
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        console.log('error->', error.response);
        dispatch(CommonActions.setLoading(false));
      });
  };

  useEffect(() => {
    getVariantTypesList();
    // getVariantList();
    getProductDetail();
  }, []);

  const DeleteVariant = async (id) => {
    dispatch(CommonActions.setLoading(true));
    await axios
      .delete('/products/product-variants/delete/' + id)
      .then((response) => {
        console.log('response of delete product variant', response);
        dispatch(CommonActions.setLoading(false));
        getProductDetail();
      })
      .catch((error) => {
        console.log('error of delete product variant', error?.response);
        dispatch(openAlert(error?.response?.message));
        dispatch(CommonActions.setLoading(false));
      });
  };

  const RenderVariants = ({item}) => {
    return (
      <>
        <View style={styles.otherSectionContainer}>
          {/* <View style={{flexDirection: 'row'}}> */}
          <AppText style={styles.optionGroupTitle}>
            {item?.variant_type?.type}
          </AppText>
          {/* </View> */}
          <View
            style={{
              justifyContent: 'space-between',
              flexDirection: 'row',
              width: '100%',
              marginBottom: 8,
            }}
          >
            <AppText style={styles.variantTitle}>{'Label'}</AppText>
            <AppText style={styles.variantTitle}>{'Value'}</AppText>
            <AppText style={{fontSize: 16, width: '10%'}}>{''}</AppText>
          </View>
          {item?.variants &&
            item?.variants?.map((o, index) => (
              <View
                style={{
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  marginBottom: 5,
                }}
              >
                <AppText style={{fontSize: 16, width: '40%'}}>
                  {o?.variant?.name}
                </AppText>
                <AppText style={{fontSize: 16, width: '40%'}}>
                  {o?.additional_price}
                </AppText>
                <TouchableOpacity
                  key={index}
                  style={{width: '10%'}}
                  onPress={() => {
                    DeleteVariant(o.id);
                  }}
                >
                  <Icon name="deleteItem" color={Colors.black20} />
                </TouchableOpacity>
              </View>
            ))}
        </View>
      </>
    );
  };

  return (
    <View
      style={
        Platform.OS == 'web'
          ? {display: 'flex', overflow: 'auto', height: '100vh'}
          : styles.container
      }
    >
      <MainHeader
        // title={strings('EditProductScreen.profileEditing')}
        title={
          props.route.params?.item
            ? strings('editProductScreen.title.edit')
            : strings('editProductScreen.title.add')
        }
        leftIcon="back"
        leftPress={() => {
          props.getProducts();
          goBack(navigation);
        }}
      />
      <MainContainer>
        {props?.route?.params?.item && (
          <View style={styles.productStrengthContainer}>
            {images.length > 0 ? (
              <ScrollView
                showsHorizontalScrollIndicator={false}
                horizontal
                centerContent={true}
              >
                {images.map((o, index) =>
                  (
                    <TouchableOpacity
                      style={[
                        styles.imageContainer,
                        {
                          width: 200,
                          aspectRatio: ratio,
                        },
                      ]}
                      // onPress={() => openActionSheet(index)}
                      onPress={() =>
                        setIsProductImageModalVisible({show: true, item: o})
                      }
                      key={index}
                    >
                      <View>
                        <Image
                          source={{uri: o.photo}}
                          style={[
                            styles.image,
                            {
                              width: 200,
                              aspectRatio: ratio,
                            },
                          ]}
                        />
                        {o.variants === null ? (
                          <View></View>
                        ) : (
                          <Text style={styles.imageVariants}>
                            {o?.variants?.variant?.variant_type?.type} :{' '}
                            {o?.variants?.variant?.name}
                          </Text>
                        )}

                        <TouchableOpacity
                          style={styles.cameraIcon}
                          // onPress={() => openActionSheet(index)}
                          onPress={() =>
                            setIsProductImageModalVisible({
                              show: true,
                              item: o,
                            })
                          }
                        >
                          <Icon name="camera" color={Colors.black20} />
                        </TouchableOpacity>
                      </View>
                    </TouchableOpacity>
                  ),
                )}
                {images.length < 8 && (
                  <TouchableOpacity
                    style={[
                      styles.imageContainer,
                      {
                        width: 200,
                        aspectRatio: ratio,
                      },
                    ]}
                    // onPress={() => openActionSheet(undefined)}
                    onPress={() =>
                      setIsProductImageModalVisible({
                        show: true,
                        item: undefined,
                      })
                    }
                  >
                    <Icon name="add" size={16} color={Colors.black60} />
                  </TouchableOpacity>
                )}
              </ScrollView>
            ) : (
              <TouchableOpacity
                style={[
                  styles.imageContainer,
                  {
                    width: 200,
                    aspectRatio: ratio,
                  },
                ]}
                // onPress={() => openActionSheet(undefined)}
                onPress={() =>
                  setIsProductImageModalVisible({show: true, item: null})
                }
              >
                <Icon name="add" size={16} color={Colors.black60} />
              </TouchableOpacity>
            )}
          </View>
        )}

        <View>
          {/* name */}
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel} required>
              {strings('editProductScreen.label.name')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              // autoCapitalize="words"
              autoCorrect={false}
              placeholder={strings('editProductScreen.placeholder.name')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
              // style={
              //   Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              // }
              value={productData.product_name}
              onChangeText={(text) =>
                setProductData({...productData, product_name: text})
              }
            />
          </View>

          {/* product description */}
          <View
            style={[
              styles.textInputContainer,
              Platform.OS == 'web' && {minHeight: 90},
            ]}
          >
            <AppText style={styles.inputLabel}>
              {strings('editProductScreen.label.description')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              multiline={true}
              numberOfLines={3}
              placeholder={strings('editProductScreen.placeholder.description')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web'
                  ? [styles.textInputWeb]
                  : styles.multiLineTextInput
              }
              value={productData.product_description}
              onChangeText={(text) =>
                setProductData({...productData, product_description: text})
              }
            />
          </View>

          {/* parts number for only partsportal */}
          {config?.domain == 'partsportal.in' && (
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>
                {strings('editProductScreen.label.partsNumber')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCorrect={false}
                placeholder={strings(
                  'editProductScreen.placeholder.partsNumber',
                )}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={styles.textInput}
                value={productData.parts_number}
                onChangeText={(text) =>
                  setProductData({...productData, parts_number: text})
                }
              />
            </View>
          )}

          {/* keywords */}
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('editProductScreen.label.keywords')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              placeholder={strings('editProductScreen.placeholder.keywords')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
              value={productData.product_keywords}
              onChangeText={(text) =>
                setProductData({...productData, product_keywords: text})
              }
            />
          </View>

          {/* category */}
          {categories?.length > 0 && config?.product?.showCategory && (
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>
                {strings('editProductScreen.label.category')}
              </AppText>
              <DropDownInput
                data={categories?.length > 0 && categories?.map((o) => o?.name)}
                value={productData.product_category}
                inputStyle={{
                  paddingHorizontal: 10,
                  marginVertical: Platform.OS == 'web' ? 0 : 10,
                }}
                onChangeText={(text) => {
                  setProductData({...productData, product_category: text});
                }}
              />
            </View>
          )}

          {/* sub category */}
          {categories?.find((o) => o?.name == productData?.product_category)
            ?.sub_category?.length > 0 && (
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>
                {strings('editProductScreen.label.subCategory')}
              </AppText>
              <DropDownInput
                data={categories
                  ?.find((o) => o.name == productData.product_category)
                  ?.sub_category?.map((o) => o?.name)}
                value={productData.product_sub_category}
                inputStyle={{
                  paddingHorizontal: 10,
                  marginVertical: Platform.OS == 'web' ? 0 : 10,
                }}
                onChangeText={(text) => {
                  setProductData({...productData, product_sub_category: text});
                }}
              />
            </View>
          )}

          {/* price */}
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel} required>
              {strings('editProductScreen.label.price')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              placeholder={strings('editProductScreen.placeholder.price')}
              keyboardType={
                Platform.OS === 'ios'
                  ? 'numbers-and-punctuation'
                  : 'decimal-pad'
              }
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
              value={productData.product_price}
              onChangeText={(text) =>
                setProductData({...productData, product_price: text})
              }
            />
          </View>

          {/* discounts */}
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('editProductScreen.label.discount')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              placeholder={strings('editProductScreen.placeholder.discount')}
              keyboardType={
                Platform.OS === 'ios'
                  ? 'numbers-and-punctuation'
                  : 'decimal-pad'
              }
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
              value={productData.product_discount}
              onChangeText={(text) =>
                setProductData({...productData, product_discount: text})
              }
            />
          </View>

          {/* weight */}
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('editProductScreen.label.weight') + ' (Kg.)'}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              placeholder={strings('editProductScreen.placeholder.weight')}
              keyboardType={
                Platform.OS === 'ios'
                  ? 'numbers-and-punctuation'
                  : 'decimal-pad'
              }
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
              value={productData?.product_weight}
              onChangeText={(text) =>
                setProductData({...productData, product_weight: text})
              }
            />
          </View>

          {/* stock availabilities */}
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('editProductScreen.label.inventory')}
            </AppText>
            <DropDownInput
              data={['Always available stock', 'Limited stock']}
              value={productData.product_qty_type}
              inputStyle={{
                paddingHorizontal: 10,
                marginVertical: Platform.OS == 'web' ? 0 : 10,
              }}
              onChangeText={(text) => {
                setProductData({...productData, product_qty_type: text});
              }}
            />
          </View>

          {/* stock availabilities unit wise */}
          <View style={styles.viewContainer}>
            {productData.product_qty_type != 'Always available stock' && (
              <View
                style={[styles.textInputContainer, {marginRight: setValue(3)}]}
              >
                <AppText style={styles.inputLabel} required>
                  {strings('editProductScreen.label.qty')}
                </AppText>
                <TextInput
                  underlineColorAndroid="transparent"
                  autoCorrect={false}
                  keyboardType="numeric"
                  placeholder={strings('editProductScreen.placeholder.qty')}
                  placeholderTextColor={Colors.color30}
                  returnKeyType="done"
                  style={
                    Platform.OS == 'web'
                      ? styles.textInputWeb
                      : [styles.textInput, {marginHorizontal: setValue(3)}]
                  }
                  value={productData.product_qty}
                  onChangeText={(text) =>
                    setProductData({...productData, product_qty: text})
                  }
                />
              </View>
            )}
            {/* units dropdown */}
            <View
              style={[
                styles.textInputContainer,
                productData.product_qty_type != 'Always available stock' && {
                  marginLeft: setValue(2),
                  paddingHorizontal: 10,
                },
              ]}
            >
              <AppText
                style={[
                  styles.inputLabel,
                  productData.product_qty_type != 'Always available stock' && {
                    marginHorizontal: 0,
                  },
                ]}
              >
                {strings('editProductScreen.label.units')}
              </AppText>
              {/* {Platform.OS == 'ios' ? ( */}
              <DropDownInput
                data={unitsArray.map((o) => o)}
                value={productData.product_unit}
                inputStyle={{
                  paddingHorizontal: 10,
                  marginLeft:
                    Platform.OS == 'web' &&
                    productData.product_qty_type != 'Always available stock'
                      ? 5
                      : 0,
                  marginVertical: 10,
                }}
                onChangeText={(text) => {
                  setProductData({...productData, product_unit: text});
                }}
              />
            </View>
          </View>

          {/* product barcode */}
          {config.type == 'retail' && (
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>
                {strings('editProductScreen.label.barcode')}
              </AppText>
              <View style={styles.scanBarcodeContainer}>
                <TextInput
                  keyboardType="numeric"
                  underlineColorAndroid="transparent"
                  // autoCapitalize="words"
                  autoCorrect={false}
                  placeholder={strings('editProductScreen.placeholder.barcode')}
                  placeholderTextColor={Colors.color30}
                  returnKeyType="done"
                  style={
                    Platform.OS == 'web'
                      ? [styles.textInputWeb, {width: '75%'}]
                      : [styles.textInput, {width: '75%'}]
                  }
                  value={productData.product_barcode}
                  onChangeText={(text) =>
                    setProductData({...productData, product_barcode: text})
                  }
                />
                <View style={styles.scanButtonContainer}>
                  <TouchableOpacity
                    style={styles.scanButton}
                    onPress={() => {
                      checkCameraPermission();
                    }}
                  >
                    <AntDesign name="qrcode" size={24} color={Colors.black60} />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          )}

          {/* tax */}
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('editProductScreen.label.tax')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              keyboardType="numeric"
              placeholder={strings('editProductScreen.placeholder.tax')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={styles.textInput}
              value={productData.product_tax}
              onChangeText={(text) =>
                setProductData({...productData, product_tax: text})
              }
            />
          </View>

          {config?.product?.availableForSubscription && (
            <AppConfigCheckbox
              desc={strings('editProductScreen.productSubscription')}
              checked={productData.available_for_subscription}
              onChangeCheck={(checked) => {
                setProductData({
                  ...productData,
                  available_for_subscription: checked,
                });
              }}
              style={{alignItems: 'center'}}
              descStyle={{
                color: Colors.black,
                fontSize: setValue(15),
              }}
            />
          )}

          {/* new product boolean */}
          {false && (
            <TouchableOpacity
              style={[styles.isNewProduct, styles.textInputContainer]}
              onPress={() => setIsNewProduct(!isNewProduct)}
            >
              <AppText
                style={{
                  color: Colors.black60,
                  fontSize: setValue(15),
                }}
              >
                {strings('editProductScreen.button.showAsNewProduct')}
              </AppText>
              <CheckBox
                size={30}
                checked={isNewProduct}
                onChange={(checked) => setIsNewProduct(checked)}
              />
            </TouchableOpacity>
          )}

          {/* food type */}
          {config.type == 'restaurants' && (
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>
                {strings('editProductScreen.label.foodType')}
              </AppText>
              <DropDownInput
                data={['veg', 'non-veg']}
                value={isVeg}
                inputStyle={{
                  paddingHorizontal: 10,
                  marginVertical: Platform.OS == 'web' ? 0 : 8,
                }}
                onChangeText={(text) => {
                  setIsVeg(text);
                }}
              />
            </View>
          )}

          {/* Variants section */}
          {config?.product?.showVariants && props.route.params?.item && (
            <>
              <View
                style={{
                  paddingVertical: 10,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <AppText
                  style={{
                    color: textColor,
                    fontSize: setValue(20),
                    fontWeight: 'bold',
                  }}
                >
                  {strings('editProductScreen.variants')}
                </AppText>
              </View>

              <FlatList
                data={productVariant}
                showsVerticalScrollIndicator={false}
                keyExtractor={(item, index) => index}
                renderItem={({item, index}) => (
                  <RenderVariants item={item} index={index} />
                )}
              />

              <View
                style={[
                  styles.addRomoveContainer,
                  {marginBottom: setValue(10)},
                ]}
              >
                <TouchableOpacity
                  style={[styles.addRomoveButton, {marginLeft: 5}]}
                  onPress={() => {
                    setVariantModal({
                      ...variantModal,
                      show: true,
                      item: null,
                    });
                  }}
                >
                  <AppText style={styles.addRomoveButtonText}>
                    {'Add New Variant'}
                  </AppText>
                </TouchableOpacity>
              </View>
            </>
          )}

          {/* details list */}
          <>
            <View
              style={{
                paddingVertical: 10,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <AppText
                style={{
                  color: textColor,
                  fontSize: setValue(20),
                  fontWeight: 'bold',
                }}
              >
                {strings('editProductScreen.details')}
              </AppText>
            </View>

            {specification &&
              specification.map((item, index) => (
                <SpecificationItem
                  item={item}
                  key={index}
                  index={index}
                  onSpecificationKeyChange={(text, index) =>
                    onSpecificationChange(text, undefined, undefined, index)
                  }
                  onSpecificationValueChange={(text, index) =>
                    onSpecificationChange(undefined, text, undefined, index)
                  }
                  onSpecificationTypeChange={(text, index) =>
                    onSpecificationChange(undefined, undefined, text, index)
                  }
                  AddValueField={(index) => AddValueField(index)}
                  RemoveValueField={(index) => RemoveValueField(index)}
                  SpecificationPointsChange={(text, i, index) =>
                    SpecificationPointsChange(text, i, index)
                  }
                />
              ))}
            <View style={styles.addRomoveContainer}>
              <TouchableOpacity
                onPress={removeSpecificationField}
                style={[styles.addRomoveButton, {marginRight: 5}]}
              >
                <AppText style={styles.addRomoveButtonText}>
                  {strings('editProductScreen.button.remove')}
                </AppText>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={addSpecificationField}
                style={[styles.addRomoveButton, {marginLeft: 5}]}
              >
                <AppText style={styles.addRomoveButtonText}>
                  {strings('editProductScreen.button.add')}
                </AppText>
              </TouchableOpacity>
            </View>
          </>
        </View>

        {Platform.OS == 'web' && (
          <View style={styles.buttonContainerWeb}>
            <TouchableOpacity
              style={styles.buttonButtonWeb}
              onPress={() => onSave()}
            >
              <AppText style={styles.buttonText}>
                {props.route.params?.item
                  ? strings('editProductScreen.button.update')
                  : strings('editProductScreen.button.save')}
              </AppText>
            </TouchableOpacity>
          </View>
        )}
      </MainContainer>
      {Platform.OS !== 'web' && (
        <View style={styles.buttonContainer}>
          <TouchableOpacity style={styles.buttonButton} onPress={onSave}>
            <AppText style={styles.buttonText}>
              {props.route.params?.item
                ? strings('editProductScreen.button.update')
                : strings('editProductScreen.button.save')}
            </AppText>
          </TouchableOpacity>
        </View>
      )}

      <EditProductVeriantModal
        variantTypesList={variantTypesList}
        productId={props?.route?.params?.item?.id}
        isVisible={variantModal?.show}
        onClose={() => {
          setVariantModal({
            show: false,
            item: null,
          });
        }}
        onSave={() => {
          getProductDetail();
          setVariantModal({
            show: false,
            item: null,
          });
        }}
        item={variantModal?.item}
      />
      <EditProductImageModal
        productId={props?.route?.params?.item?.id}
        variantList={props?.route?.params?.item?.variant}
        productImages={props?.route?.params?.item?.images}
        isVisible={isProductImageModalVisible}
        onClose={() => {
          setIsProductImageModalVisible({show: false, item: null});
        }}
        onSave={() => {
          getProductDetail();
          setIsProductImageModalVisible({
            show: false,
            item: null,
          });
        }}
      />
    </View>
  );
};
const ConnectedEditProduct = connectActionSheet(EditProductScreen);

export default connect(
  (state) => ({
    config: state.config,
    categories: state.products.categories,
    common: state.common,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setLoading: CommonActions.setLoading,
        openAlert: (content, leftButton, leftPress, rightButton, rightPress) =>
          CommonActions.setAlert({
            visible: true,
            content,
            leftButton,
            leftPress,
            rightButton,
            rightPress,
          }),
        getProducts,
      },
      dispatch,
    ),
)(ConnectedEditProduct);
