import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {screenHeight, screenWidth, setValue} from '../../utils';

export default StyleSheet.create({
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    flex: 1,
    justifyContent: 'flex-end',
  },
  modalTitleContainer: {
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: 22,
    paddingBottom: setValue(5),
    borderBottomWidth: 2,
    borderBottomColor: Colors.black,
  },
  filterBySort: {
    marginBottom: setValue(10),
  },
  itemContainer: {
    backgroundColor: Colors.white,
    borderRadius: 5,
    minHeight: screenHeight / 3,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 3,
    borderWidth: 2,
    borderColor: Colors.color30,
  },
  itemContainerWeb: {
    width: screenWidth > 1024 ? 1024 : screenWidth,
    alignSelf: 'center',
  },
  filterItemContainer: {
    margin: setValue(0),
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  filterTitle: {
    fontSize: 18,
    fontWeight: '700',
    color: Colors.black40,
    marginBottom: 5,
    textTransform: 'capitalize',
  },
  filterItem: {
    margin: setValue(5),
    padding: setValue(5),
    borderWidth: 2,
    borderColor: Colors.color30,
    borderRadius: 5,
  },
  selectedFilterItem: {
    backgroundColor: Colors.theme_color,
    margin: setValue(5),
    padding: setValue(5),
    borderWidth: 2,
    borderColor: Colors.theme_color,
    borderRadius: 5,
  },
  filterItemText: {
    fontSize: 16,
    color: Colors.black,
  },
  selectedFilterItemText: {
    fontSize: 16,
    color: Colors.white,
  },
  actionButtons: {
    justifyContent: 'space-evenly',
    flexDirection: 'row',
  },
});
