import {Platform, StyleSheet} from 'react-native';

import {Colors, Layout} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue, screenWidth} from '../../utils';

let TotalWidth = screenWidth - 3 * setXAxisValue(15);

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
};

export default StyleSheet.create({
  productContainer:
    Platform.OS != 'android' && Platform.OS != 'ios'
      ? {
          marginVertical: setValue(10),
          marginHorizontal: setValue(3),
          padding: setValue(3),
          backgroundColor: 'white',
          borderRadius: setValue(10),
          // width: 220,
          width:
            220 +
            220 *
              ((TotalWidth / 220 - Math.floor(TotalWidth / 220)) /
                Math.floor(TotalWidth / 220)) -
            2 * Math.floor(TotalWidth / 220),
        }
      : {
          marginVertical: setValue(3),
          flex: 1,
          flexDirection: 'row',
          padding: setValue(8),
          backgroundColor: 'white',
          borderRadius: setValue(5),
          ...shadow,
        },
  productImage: {
    width: '100%',
    height: undefined,
    aspectRatio: 3 / 2,
    borderRadius: setValue(5),
    borderColor: 'rgba(0, 0, 0, 0.1)',
    borderWidth: 1,
  },
  productName: {
    // marginBottom: setValue(5),
    fontSize: setXAxisValue(15),
    fontWeight: 'bold',
  },
  productPrice: {
    // marginBottom: setValue(5),
    fontSize: setXAxisValue(15),
  },
  productAddButton: {
    width: setValue(36),
    height: setValue(36),
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: Colors.theme_color,
    right: setXAxisValue(12),
    borderRadius: setValue(30),
  },
  productTypeIcon: {
    width: setValue(20),
    height: setValue(20),
    marginTop: setYAxisValue(5),
    marginHorizontal: setXAxisValue(3),
  },
  productOutOfStockImage: {
    position: 'absolute',
    width: Layout.window.width / 3,
    alignSelf: 'center',
  },
  newProductBurst: {
    position: 'absolute',
    bottom: 0,
    right: 5,
  },
  outOfStock: {
    backgroundColor: Colors.wallet_card_red,
    paddingHorizontal: 10,
    borderWidth: 2,
    marginLeft: setValue(8),
    borderRadius: setValue(30),
    borderColor: Colors.wallet_card_red,
    zIndex: 10000,
    position: 'absolute',
    alignSelf: 'center',
  },
  discountText: {
    paddingTop: setValue(3),
    color: Colors.color30,
    fontSize: setValue(12),
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  FoodType: {
    padding: setValue(3),
    borderWidth: 1.5,
    position: 'absolute',
    left: '36%',
    top: setYAxisValue(11),
    borderRadius: 3,
    zIndex: 1000,
    backgroundColor: Colors.white_gray,
  },
});
