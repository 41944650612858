import {Appearance, StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {screenWidth, setValue, setXAxisValue, setYAxisValue} from '../../utils';
const colorScheme = Appearance.getColorScheme();
const textColor =  Colors.black60;
export default StyleSheet.create({
  container: {
    flex: 1,
  },
  appleButton: {
    height: setValue(50),
    width: '100%',
  },
  mainContainer: {
    flex: 1,
  },
  textInput: {
    alignItems: 'center',
    height: setValue(50),
    padding: 0,
    // flex: 1,
    paddingHorizontal: setXAxisValue(5),
    marginBottom: setYAxisValue(10),
    borderRadius: 5,
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
  },
  textInputContainer: {
    height: setValue(60),
  },
  inputRightSide: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    right: setValue(10),
    alignSelf: 'center',
    top: setValue(15),
    elevation: 10,
  },
  forgetPwText: {
    fontSize: setXAxisValue(14),
    color: textColor,
    marginVertical: setYAxisValue(15),
  },
  dividerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider: {
    flex: 1,
    height: StyleSheet.hairlineWidth,
    backgroundColor: textColor
  },
  orText: {
    color:textColor,
    marginVertical: setXAxisValue(15),
    marginHorizontal: setYAxisValue(20),
    textAlign: 'center',
  },
  fbButton: {
    backgroundColor: Colors.facebook,
  },
  RadioGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'center',
    alignItems: 'center',
    marginVertical: setYAxisValue(20),
  },
  selectionCard: {
    flexDirection: 'row',
    borderWidth: 2,
    paddingHorizontal:setXAxisValue(15),
    marginHorizontal: 5,
    padding: setValue(10),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    borderColor: '#ccc',
    backgroundColor: Colors.white,
  },
  selectedSelectionCard: {
    flexDirection: 'row',
    borderWidth: 2,
    marginHorizontal: 5,
    paddingHorizontal:setXAxisValue(15),
    padding: setValue(10),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    borderColor: Colors.theme_color,
    backgroundColor: Colors.theme_color,
  },
  selectionText: {
    color: Colors.black20,
    fontSize: setValue(15),
    marginLeft: setValue(10),
  },
  selectedSelectionText: {
    color: Colors.white,
    fontSize: setValue(15),
    marginLeft: setValue(10),
  },
});
