import React, {useEffect} from 'react';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {Platform, View, FlatList} from 'react-native';
import {
  LongButton,
  MainContainer,
  MainHeader,
  SwipeRow,
  FranchiseItem,
  AppText,
  NoRecordFound,
} from '../../../components';
import {goBack, setValue} from '../../../utils';
import styles from './styles';
import {strings} from '../../../i18n';
import {useDispatch, useSelector} from 'react-redux';
import {
  franchiseList,
  deleteFranchise,
} from '../../../redux/reduxsauceActions/franchiseAction';

const FranchiseList = (props) => {
  const franchise = useSelector((state) => state.franchise);
  const dispatch = useDispatch();

  const openAlert = (content, leftButton, leftPress, rightButton, rightPress) =>
    CommonActions.setAlert({
      visible: true,
      content,
      leftButton,
      leftPress,
      rightButton,
      rightPress,
    });

  useEffect(() => {
    dispatch(franchiseList());
  }, []);

  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('franchise.title')}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer useView>
        {franchise.length > 0 ? (
          <View style={styles.mainContainer}>
            <FlatList
              data={franchise}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item) => item.id}
              renderItem={({item, index}) => (
                <SwipeRow
                  editable={true}
                  leftOpenValue={70}
                  rightOpenValue={-100}
                  onPressEdit={() =>
                    props.navigation.navigate('edit-franchise', {
                      item: item,
                      edit: true,
                    })
                  }
                  onPressDelete={() =>
                    dispatch(
                      openAlert(
                        strings('franchise.askDelete'),
                        strings('settingScreen.confirm'),
                        async () => {
                          dispatch(deleteFranchise(item.id));
                        },
                        strings('settingScreen.cancel'),
                        null,
                      ),
                    )
                  }
                >
                  <FranchiseItem item={item} key={index} />
                </SwipeRow>
              )}
            />
          </View>
        ) : (
          <View style={styles.mainContainer}>
            <NoRecordFound
              title={strings('franchise.noRecord.title')}
              message={strings('franchise.noRecord.message')}
            />
          </View>
        )}
        {Platform.OS == 'web' && (
          <LongButton
            text={strings('franchise.addNew')}
            style={{marginTop: setValue(15), marginBottom: setValue(50)}}
            onPress={() => props.navigation.navigate('edit-franchise')}
          />
        )}
      </MainContainer>
      {Platform.OS != 'web' && (
        <LongButton
          style={{borderRadius: 0}}
          text={strings('franchise.addNew')}
          onPress={() => props.navigation.navigate('edit-franchise')}
        />
      )}
    </View>
  );
};
export default FranchiseList;
