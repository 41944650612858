import {View, Text, TouchableOpacity, Image} from 'react-native';
import React from 'react';
import {Colors} from '../../constants';
import styles from './styles';
import {Icon} from '..';
import ProgressiveImage from '../ProgressiveImage';

const SliderItem = props => {
  const {item, drag, index} = props;
  return (
      <TouchableOpacity
        style={styles.sliderItemContainer}
        onLongPress={drag}
        key={index}
      >
        <TouchableOpacity style={styles.deleteBtn} onPress={props.onDeleteItem}>
          <Text style={styles.deleteIcon}>
            <Icon name="dustBin" color={Colors.white} />
          </Text>
        </TouchableOpacity>
        <View style={styles.imageContainer}>
          {/* <Image source={{uri: item.image}} style={styles.sliderImage} /> */}
          <ProgressiveImage
            thumbnailSource={{uri: item.image}}
            source={{uri: item.image}}
            style={styles.sliderImage}
            resizeMode="cover"
          />
        </View>
      </TouchableOpacity>
  );
};

export default SliderItem;
