import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {View, Platform, TextInput} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import CommonAction from '../../redux/reduxsauce/commonRedux';
import ConfigActions from '../../redux/reduxsauce/configRedux';
import {
  AppConfigCheckbox,
  AppText,
  CheckBox,
  DeliveryRistComp,
  DropDownInput,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../components';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import {goBack, setValue} from '../../utils';
import styles from './styles';
import Constants from 'expo-constants';
import {getAppConfig} from '../../redux/reduxsauceActions/configActions';

const OrderSetting = () => {
  const config = useSelector((state) => state.config);
  const dispatch = useDispatch();

  const [orderPickupTime, setOrderPickupTime] = useState('00');
  const [orderSettingData, setOrderSettingData] = useState({
    delivery: false,
    // deliveryMethod: 'self',
    dinein: false,
    pickup: false,
    autoAccept: false,
    cancelFromUser: false,
    cashOnDelivery: [],
    orderDeliveryCharge: '00',
    orderDeliveryChargeType: 'Fixed',
    orderDeliveryChargeOnMinAmount: '00',
  });

  const [deliveryRist, setDeliveryRist] = useState({
    pincode: [],
    city: [],
    state: [],
    country: [],
  });

  let orderChargesType = [
    {value: 'Fixed', label: 'Fixed Charge'},
    {value: 'On-Weight', label: 'Based On Weight'},
    {
      value: 'On-Total',
      label: 'Based On Order Total',
    },
  ];

  useEffect(() => {
    const setting = config;
    setOrderSettingData({
      ...orderSettingData,
      delivery: setting?.order?.delivery,
      dinein: setting?.order?.dinein,
      pickup: setting?.order?.pickup,
      autoAccept: setting?.order?.autoAccept,
      // deliveryMethod: setting?.order?.deliveryMethod || 'self',
      cancelFromUser: setting?.order?.cancelFromUser || false,
      cashOnDelivery: setting?.order?.cashOnDelivery || [],
      orderDeliveryCharge: setting?.order?.orderDeliveryCharge || '00',
      orderDeliveryChargeType:
        setting?.order?.orderDeliveryChargeType || 'Fixed',
      orderDeliveryChargeOnMinAmount:
        setting?.order?.orderDeliveryChargeOnMinAmount || '00',
    });
    setOrderPickupTime(
      setting?.order?.orderPreparartionTime
        ? setting?.order?.orderPreparartionTime?.toString()
        : '00',
    );

    setDeliveryRist({
      ...deliveryRist,
      pincode: setting?.delivery?.availableLocation?.pincode
        ? setting?.delivery?.availableLocation?.pincode
        : [],
      city: setting?.delivery?.availableLocation?.city
        ? setting?.delivery?.availableLocation?.city
        : [],
      state: setting?.delivery?.availableLocation?.state
        ? setting?.delivery?.availableLocation?.state
        : [],
      country: setting?.delivery?.availableLocation?.country
        ? setting?.delivery?.availableLocation?.country
        : [],
    });
  }, []);

  const saveOrderConfigData = async () => {
    console.log(
      'orderDeliveryChargeType',
      orderSettingData?.orderDeliveryChargeType,
    );
    if (orderSettingData?.orderDeliveryChargeType == 'On-Total') {
      if (parseInt(orderSettingData?.orderDeliveryChargeOnMinAmount) == 0) {
        dispatch(
          CommonAction.setAlert({
            visible: true,
            content: strings('orderSetting.alert.orderDeliveryChargeOnAmount'),
          }),
        );
        return;
      }
    }

    dispatch(CommonAction.setLoading(true));
    if (
      config?.type == 'restaurants' &&
      (parseInt(orderPickupTime) == 0 || parseInt(orderPickupTime) < 10)
    ) {
      dispatch(CommonAction.setLoading(false));
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: strings('orderSetting.alert.orderPreparationTime'),
        }),
      );
      return;
    }

    let obj = deliveryRist;
    obj['pincode'] = deliveryRist.pincode;
    obj['city'] = deliveryRist.city;
    obj['state'] = deliveryRist.state;
    obj['country'] = deliveryRist.country;

    console.log('obj', obj);
    try {
      const response = await axios.patch(
        `/business/update/business-setting/${config?.businessId}`,
        {
          order: {
            ...config?.order,
            ...orderSettingData,
            orderPreparartionTime: parseInt(orderPickupTime),
          },
          delivery: {
            ...config?.delivery,
            availableLocation: obj,
          },
        },
      );
      dispatch(getAppConfig());
      dispatch(CommonAction.setLoading(false));
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: strings('orderSetting.alert.success'),
        }),
      );
      goBack(navigation);
    } catch (error) {
      dispatch(CommonAction.setLoading(false));
      console.log('error', error.response);
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: error?.response?.message || 'Something went wrong :(',
        }),
      );
    }
  };

  return (
    <View
      style={Platform.OS === 'web' ? styles.webContainer : styles.container}
    >
      <MainHeader
        title={strings('settingScreen.OrderSetting')}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer>
        <View style={styles.orderSettingContainer}>
          <AppConfigCheckbox
            icon={'delivery'}
            title={strings('orderSetting.delivery')}
            desc={strings('orderSetting.deliveryDescrption')}
            checked={orderSettingData?.delivery}
            onChangeCheck={(checked) =>
              setOrderSettingData({
                ...orderSettingData,
                delivery: checked,
              })
            }
          />
          {orderSettingData?.delivery && (
            <>
              <AppText style={styles.deliveryRist}>
                {strings('orderSetting.deliveryRistric')}
              </AppText>
              {/* {Pincode Wise} */}
              <DeliveryRistComp
                title={strings('orderSetting.label.pincode')}
                type={'pincode'}
                list={deliveryRist?.pincode}
                updateList={(data) => {
                  setDeliveryRist({...deliveryRist, pincode: data});
                }}
              />

              {/* city wise */}
              <DeliveryRistComp
                title={strings('orderSetting.label.city')}
                type={'city'}
                list={deliveryRist?.city}
                updateList={(data) => {
                  setDeliveryRist({...deliveryRist, city: data});
                }}
              />

              {/* state wise */}

              <DeliveryRistComp
                title={strings('orderSetting.label.state')}
                type={'state'}
                list={deliveryRist?.state}
                updateList={(data) => {
                  setDeliveryRist({...deliveryRist, state: data});
                }}
              />
              {/* Country wise */}
              <DeliveryRistComp
                title={strings('orderSetting.label.country')}
                type={'country'}
                list={deliveryRist?.country}
                updateList={(data) => {
                  setDeliveryRist({...deliveryRist, country: data});
                }}
              />
            </>
          )}

          <AppConfigCheckbox
            icon={'pickUp'}
            title={strings('orderSetting.pickup')}
            desc={strings('orderSetting.pickupescrpation')}
            checked={orderSettingData?.pickup}
            onChangeCheck={(checked) =>
              setOrderSettingData({
                ...orderSettingData,
                pickup: checked,
              })
            }
          />

          {config?.type == 'restaurants' && (
            <AppConfigCheckbox
              icon={'dinein'}
              title={strings('orderSetting.dineIn')}
              desc={strings('orderSetting.dineInDescrption')}
              checked={orderSettingData?.dinein}
              onChangeCheck={(checked) =>
                setOrderSettingData({
                  ...orderSettingData,
                  dinein: checked,
                })
              }
            />
          )}

          <AppConfigCheckbox
            icon={'foodForkDrink'}
            title={strings('orderSetting.autoOrderaccepat')}
            desc={strings('orderSetting.autoOrderaccepatDescrption')}
            checked={orderSettingData?.autoAccept}
            onChangeCheck={(checked) =>
              setOrderSettingData({
                ...orderSettingData,
                autoAccept: checked,
              })
            }
          />

          {/* {(config?.ENV == 'local' || config?.ENV == 'development') && ( */}
          <AppConfigCheckbox
            icon={'foodForkDrink'}
            title={strings('orderSetting.enableCancelOrder')}
            desc={strings('orderSetting.enableCancelOrderDescrption')}
            checked={orderSettingData?.cancelFromUser}
            onChangeCheck={(checked) =>
              setOrderSettingData({
                ...orderSettingData,
                cancelFromUser: checked,
              })
            }
          />
          {/* )} */}

          {config?.type == 'restaurants' && (
            <View style={styles.inputContainer}>
              <View style={styles.textInputContainer}>
                <AppText style={styles.inputLabel}>
                  {strings('orderSetting.orderPreparationTime')}
                </AppText>
                <TextInput
                  underlineColorAndroid="transparent"
                  keyboardType="number-pad"
                  autoCorrect={false}
                  placeholder={strings('orderSetting.placeholder.minute')}
                  placeholderTextColor={Colors.color30}
                  returnKeyType="done"
                  value={orderPickupTime}
                  onChangeText={(text) => {
                    setOrderPickupTime(text);
                  }}
                  style={
                    Platform.OS == 'web'
                      ? styles.textInputWeb
                      : styles.textInput
                  }
                />
              </View>
            </View>
          )}
          <View style={styles.inputContainer}>
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>
                {strings('orderSetting.selectDeliveryChargeType')}
              </AppText>
              <DropDownInput
                inputStyle={{
                  marginVertical: 10,
                  marginHorizontal: 10,
                }}
                data={orderChargesType}
                value={orderSettingData?.orderDeliveryChargeType}
                onChangeText={(text) => {
                  setOrderSettingData({
                    ...orderSettingData,
                    orderDeliveryChargeType: text,
                  });
                }}
              />
            </View>
          </View>

          {orderSettingData?.orderDeliveryChargeType == 'On-Total' && (
            <View style={styles.inputContainer}>
              <View style={styles.textInputContainer}>
                <AppText style={styles.inputLabel}>
                  {strings('orderSetting.OrderDeliveryChargePerAmount')}

                  <AppText style={styles.subInputLabel} required>
                    {' '}
                    ex. 500
                  </AppText>
                </AppText>
                <TextInput
                  underlineColorAndroid="transparent"
                  keyboardType="number-pad"
                  autoCorrect={false}
                  placeholder={'00'}
                  placeholderTextColor={Colors.color30}
                  returnKeyType="done"
                  value={orderSettingData.orderDeliveryChargeOnMinAmount}
                  onChangeText={(text) => {
                    setOrderSettingData({
                      ...orderSettingData,
                      orderDeliveryChargeOnMinAmount: text,
                    });
                  }}
                  style={
                    Platform.OS == 'web'
                      ? styles.textInputWeb
                      : styles.textInput
                  }
                />
              </View>
            </View>
          )}

          <View style={styles.inputContainer}>
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>
                {strings('orderSetting.OrderDeliveryCharge')}
                {orderSettingData?.orderDeliveryChargeType == 'On-Weight' && (
                  <AppText style={styles.subInputLabel} required>
                    {' '}
                    per kg
                  </AppText>
                )}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                keyboardType="number-pad"
                autoCorrect={false}
                placeholder={'00'}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                value={orderSettingData.orderDeliveryCharge}
                onChangeText={(text) => {
                  setOrderSettingData({
                    ...orderSettingData,
                    orderDeliveryCharge: text,
                  });
                }}
                style={
                  Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
                }
              />
            </View>
          </View>

          {/* {(config?.ENV == 'local' || config?.ENV == 'development') && ( */}
          <View style={styles.inputContainer}>
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>
                {strings('orderSetting.label.cashOnDelivery')}
              </AppText>
              <View
                style={
                  config?.type == 'restaurants'
                    ? {
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: 10,
                      }
                    : {
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        padding: 10,
                      }
                }
              >
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <CheckBox
                    checked={orderSettingData?.cashOnDelivery?.includes(
                      'pickup',
                    )}
                    onChange={(checked) => {
                      if (checked) {
                        let arr = [...orderSettingData.cashOnDelivery];
                        arr.push('pickup');
                        setOrderSettingData({
                          ...orderSettingData,
                          cashOnDelivery: [...arr],
                        });
                      } else {
                        let arr = orderSettingData.cashOnDelivery.filter(
                          (o) => o != 'pickup',
                        );
                        setOrderSettingData({
                          ...orderSettingData,
                          cashOnDelivery: [...arr],
                        });
                      }
                    }}
                  />
                  <AppText style={{marginLeft: 5}}>
                    {strings('orderSetting.label.pickUp')}
                  </AppText>
                </View>
                {config?.type == 'restaurants' && (
                  <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <CheckBox
                      checked={orderSettingData?.cashOnDelivery?.includes(
                        'dinein',
                      )}
                      onChange={(checked) => {
                        if (checked) {
                          let arr = [...orderSettingData?.cashOnDelivery];
                          arr.push('dinein');
                          setOrderSettingData({
                            ...orderSettingData,
                            cashOnDelivery: [...arr],
                          });
                        } else {
                          let arr = orderSettingData.cashOnDelivery.filter(
                            (o) => o != 'dinein',
                          );
                          setOrderSettingData({
                            ...orderSettingData,
                            cashOnDelivery: [...arr],
                          });
                        }
                      }}
                    />
                    <AppText style={{marginLeft: 5}}>
                      {strings('orderSetting.label.dinein')}
                    </AppText>
                  </View>
                )}
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <CheckBox
                    checked={orderSettingData?.cashOnDelivery?.includes(
                      'delivery',
                    )}
                    onChange={(checked) => {
                      if (checked) {
                        let arr = [...orderSettingData.cashOnDelivery];
                        arr.push('delivery');
                        setOrderSettingData({
                          ...orderSettingData,
                          cashOnDelivery: [...arr],
                        });
                      } else {
                        let arr = orderSettingData.cashOnDelivery.filter(
                          (o) => o != 'delivery',
                        );
                        setOrderSettingData({
                          ...orderSettingData,
                          cashOnDelivery: [...arr],
                        });
                      }
                    }}
                  />
                  <AppText style={{marginLeft: 5}}>
                    {strings('orderSetting.deliverys')}
                  </AppText>
                </View>
              </View>
            </View>
          </View>
          {/* )} */}
        </View>

        {Platform.OS == 'web' && (
          <LongButton
            text={strings('orderSetting.button.save')}
            style={{marginTop: setValue(10), marginBottom: setValue(45)}}
            onPress={saveOrderConfigData}
          />
        )}
      </MainContainer>
      {Platform.OS !== 'web' && (
        <LongButton
          text={strings('orderSetting.button.save')}
          style={{
            width: '100%',
            borderRadius: 0,
          }}
          onPress={saveOrderConfigData}
        />
      )}
    </View>
  );
};

export default OrderSetting;
