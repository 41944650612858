import React from 'react';
import {TouchableOpacity} from 'react-native';

import {Colors} from '../../constants';
import {setValue} from '../../utils';
import AppText from '../AppText';
import Icon from '../Icon';
import styles from './styles';
import Icon4 from '@expo/vector-icons/FontAwesome';

export default props => (
  <TouchableOpacity onPress={props.onPress} style={styles.drawerItem}>
    {props.name == 'cart' ? (
      <Icon4 name="shopping-cart" size={35} style={styles.iconColor} />
    ) : (
      <Icon
        name={props.name}
        size={props.size || setValue(30)}
        style={styles.iconColor}
      />
    )}
    <AppText style={styles.title}>{props.title}</AppText>
  </TouchableOpacity>
);
