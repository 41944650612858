import axios from 'axios';
import {get} from 'lodash';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import {AppText, MainContainer, MainHeader, DatePicker} from '../../components';
import {strings} from '../../i18n';
import {goBack, isDateValid} from '../../utils';
import styles from './styles';
import CommonAction from '../../redux/reduxsauce/commonRedux';
import {useDispatch, useSelector} from 'react-redux';

const SubscriptionDetail = (props) => {
  const {id} = props?.route?.params;
  const config = useSelector((state) => state?.config);
  const currencySymbol = get(config, 'currencySymbol', '₹');
  const dispatch = useDispatch();

  const [subscriptionDetail, setSubscriptionDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState('');
  const [isDateVisible, setIsDateVisible] = useState(false);
  const [dailyStatusList, setDailyStatusList] = useState([]);

  const getSubscriptionDetail = () => {
    setLoading(true);

    axios
      .get('/subcription/get/details/' + id)
      .then((response) => {
        console.log('response -->', response);
        setSubscriptionDetail(response?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log('error --->', error?.response);
        setLoading(false);
      });
  };

  const fetchDailyStatusList = () => {
    dispatch(CommonAction.setLoading(true));

    axios
      .get('/subcription/dailystatus/list/' + id)
      .then((response) => {
        console.log('response of dailystatus list --', response);
        setDailyStatusList(response.data);
        dispatch(CommonAction.setLoading(false));
      })
      .catch((error) => {
        console.log('error of dailystatus list --', error);
        dispatch(CommonAction.setLoading(false));
      });
  };

  useEffect(() => {
    getSubscriptionDetail();
    fetchDailyStatusList();
  }, [id]);

  const updateSubscriptionStatus = (status) => {
    setLoading(true);
    axios
      .patch('/subcription/update/status/' + id, {
        status: status,
      })
      .then((response) => {
        console.log('response --', response);
        getSubscriptionDetail();
      })
      .catch((error) => {
        console.log('error --', error);
        getSubscriptionDetail();
      });
  };

  const createDailyStatus = (status) => {
    let body = {
      delivered_at: date,
      status: true,
      subscription: id,
    };
    if (body.delivered_at == '') {
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: 'Please select date first',
        }),
      );
      return;
    }

    dispatch(CommonAction.setLoading(true));
    axios
      .post('/subcription/dailystatus/create', body)
      .then((response) => {
        console.log('response of dailystatus --', response);
        getSubscriptionDetail();
        fetchDailyStatusList();
        dispatch(CommonAction.setLoading(false));
      })
      .catch((error) => {
        console.log('error of dailystatus --', error);
        dispatch(
          CommonAction.setAlert({
            visible: true,
            content: error?.response?.message || 'Something want wrong',
          }),
        );
        getSubscriptionDetail();
        fetchDailyStatusList();
        dispatch(CommonAction.setLoading(false));
      });
  };

  // render methods
  const renderSubscriptionSteps = () => {
    let doneAtStepIndex = 0;

    doneAtStepIndex =
      subscriptionDetail?.status == 'unpaid'
        ? 0
        : subscriptionDetail?.status == 'paid'
        ? 1
        : subscriptionDetail?.status == 'accepted'
        ? 3
        : subscriptionDetail?.status == 'in-process'
        ? 3
        : subscriptionDetail?.status == 'completed' && 4;

    if (
      subscriptionDetail?.status == 'cancelled' ||
      subscriptionDetail?.status == 'declined'
    ) {
      return (
        <View style={[styles.cardContainer, {padding: 20}]}>
          <View
            style={[
              styles.orderStatusStepContainer,
              {
                justifyContent: 'center',
              },
            ]}
          >
            <View style={{alignItems: 'center', justifyContent: 'center'}}>
              <View style={[styles.doneStep, styles.declinedStep]}>
                <Image
                  style={styles.stepIcon}
                  source={require('../../../assets/images/Ordering/close.png')}
                />
              </View>
              <Text style={styles.textStep}>
                {subscriptionDetail?.status == 'cancelled'
                  ? strings('ordering.orderStatusPending.cancelled')
                  : strings('ordering.orderStatusPending.declined')}
              </Text>
            </View>
          </View>
        </View>
      );
    }
    return (
      <View style={[styles.cardContainer, {padding: 20}]}>
        <View style={styles.orderStatusStepContainer}>
          <View style={styles.separator} />

          <View style={{alignItems: 'center', justifyContent: 'center'}}>
            <View
              style={doneAtStepIndex >= 1 ? styles.doneStep : styles.undoneStep}
            >
              <Image
                style={styles.stepIconForPaid}
                source={require('../../../assets/images/Ordering/paid.png')}
              />
            </View>
            <Text style={styles.textStep}>
              {strings('ordering.orderStatusPending.paid')}
            </Text>
          </View>

          <View style={{alignItems: 'center', justifyContent: 'center'}}>
            <View
              style={doneAtStepIndex >= 2 ? styles.doneStep : styles.undoneStep}
            >
              <Image
                style={styles.stepIcon}
                source={require('../../../assets/images/Ordering/order_status_image_accepted.png')}
              />
            </View>
            <Text style={styles.textStep}>
              {strings('ordering.orderStatusPending.accepted')}
            </Text>
          </View>
          <View style={{alignItems: 'center', justifyContent: 'center'}}>
            <View
              style={doneAtStepIndex >= 3 ? styles.doneStep : styles.undoneStep}
            >
              <Image
                style={styles.processIcon}
                source={require('../../../assets/images/Ordering/process.png')}
              />
            </View>
            <Text style={styles.textStep}>
              {strings('ordering.orderStatusPending.inProcess')}
            </Text>
          </View>

          <View style={{alignItems: 'center', justifyContent: 'center'}}>
            <View
              style={doneAtStepIndex >= 4 ? styles.doneStep : styles.undoneStep}
            >
              <Image
                style={styles.stepIcon}
                source={require('../../../assets/images/Ordering/order_status_image_deliverd.png')}
              />
            </View>
            <Text style={styles.textStep}>
              {strings('ordering.orderStatusPending.completed')}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const renderDeliveryLocation = () => {
    return (
      <View style={styles.cardContainer}>
        <View style={styles.detailCard}>
          <AppText style={styles.cardTitle}>{'Delivery Address'}</AppText>
          <View style={styles.cardTitleLine} />

          <View style={{paddingTop: 10}}>
            <AppText>
              {subscriptionDetail?.address?.street +
                ', ' +
                subscriptionDetail?.address?.area +
                ','}
            </AppText>
            <AppText>
              {subscriptionDetail?.address?.city +
                ', ' +
                subscriptionDetail?.address?.state +
                ', ' +
                subscriptionDetail?.address?.country +
                ' - ' +
                subscriptionDetail?.address?.pincode}
            </AppText>
          </View>
        </View>
      </View>
    );
  };

  const renderSubscriptionDetail = () => {
    return (
      <View style={styles.cardContainer}>
        <View style={styles.detailCard}>
          <AppText style={styles.cardTitle}>
            {strings('subscriptionDetailScreen.detailCard.title')}
          </AppText>
          <View style={styles.cardTitleLine} />

          <View style={{paddingTop: 10}}>
            <View style={styles.detailItem}>
              <AppText style={styles.detailLabel}>
                {strings(
                  'subscriptionDetailScreen.detailCard.subscriptionNumber',
                )}
              </AppText>
              <AppText style={styles.detailValue}>
                {'SUB00' + subscriptionDetail?.id}
              </AppText>
            </View>

            <View style={[styles.detailItem, {marginVertical: 5}]}>
              <AppText style={styles.detailLabel}>
                {strings('subscriptionDetailScreen.detailCard.createdAt')}
              </AppText>
              <AppText style={styles.detailValue}>
                {subscriptionDetail?.created_at &&
                  moment(subscriptionDetail?.created_at).format(
                    'DD MMMM YYYY hh:mm A',
                  )}
              </AppText>
            </View>
            <View style={[styles.detailItem, {marginVertical: 5}]}>
              <AppText style={styles.detailLabel}>
                {strings('subscriptionDetailScreen.detailCard.type')}
              </AppText>
              <AppText style={[styles.detailValue, {fontWeight: '700'}]}>
                {subscriptionDetail?.type.toUpperCase().replace('_', ' ')}
              </AppText>
            </View>
            <View style={[styles.detailItem, {marginVertical: 5}]}>
              <AppText style={styles.detailLabel}>
                {strings('subscriptionDetailScreen.detailCard.bookingFrom')}
              </AppText>
              <AppText style={styles.detailValue}>
                {subscriptionDetail?.start_date &&
                  moment(subscriptionDetail?.start_date).format('DD MMMM YYYY')}
              </AppText>
            </View>
            <View style={[styles.detailItem, {marginVertical: 5}]}>
              <AppText style={styles.detailLabel}>
                {strings('subscriptionDetailScreen.detailCard.bookingTo')}
              </AppText>
              <AppText style={styles.detailValue}>
                {subscriptionDetail?.end_date &&
                  moment(subscriptionDetail?.end_date).format('DD MMMM YYYY')}
              </AppText>
            </View>
            <View style={[styles.detailItem, {marginVertical: 5}]}>
              <AppText style={styles.detailLabel}>
                {strings('subscriptionDetailScreen.detailCard.status')}
              </AppText>
              <AppText style={styles.detailValue}>
                {subscriptionDetail?.status.toUpperCase()}
              </AppText>
            </View>
            <View style={[styles.detailItem, {marginVertical: 5}]}>
              <AppText style={styles.detailLabel}>
                {strings('subscriptionDetailScreen.detailCard.paymentMethod')}
              </AppText>
              <AppText style={styles.detailValue}>
                {subscriptionDetail?.payment?.from_coin
                  ? 'COIN'
                  : subscriptionDetail?.payment?.payment_method?.type?.toUpperCase()}
              </AppText>
            </View>
          </View>
        </View>
      </View>
    );
  };

  const renderCustomerDetail = () => {
    return (
      <View style={styles.cardContainer}>
        <View style={styles.detailCard}>
          <AppText style={styles.cardTitle}>
            {strings('subscriptionDetailScreen.customerDetailCard.title')}
          </AppText>
          <View style={styles.cardTitleLine} />

          <View style={{paddingTop: 10}}>
            <View style={styles.detailItem}>
              <AppText style={styles.detailLabel}>
                {strings('subscriptionDetailScreen.customerDetailCard.name')}
              </AppText>
              <AppText style={styles.detailValue}>
                {subscriptionDetail?.user?.name}
              </AppText>
            </View>
            <View style={styles.detailItem}>
              <AppText style={styles.detailLabel}>
                {strings(
                  'subscriptionDetailScreen.customerDetailCard.mobileNumber',
                )}
              </AppText>
              <AppText style={styles.detailValue}>
                {subscriptionDetail?.user?.mobile}
              </AppText>
            </View>
          </View>
        </View>
      </View>
    );
  };

  const renderSubscribedItem = () => {
    let price = parseFloat(subscriptionDetail?.product?.price);

    price = parseFloat(
      parseFloat(subscriptionDetail?.total) /
        parseFloat(subscriptionDetail?.total_days),
    ).toFixed(2);

    console.log('price', price, subscriptionDetail?.total_days);
    return (
      <View style={styles.cardContainer}>
        <View style={styles.detailCard}>
          <AppText style={styles.cardTitle}>
            {strings('subscriptionDetailScreen.subscriptionItemCard.title')}
          </AppText>
          <View style={styles.cardTitleLine} />

          <View style={{paddingTop: 10}}>
            {/* header */}
            <View style={styles.orderDetailItem}>
              <View style={{width: '50%', justifyContent: 'center'}}>
                <AppText
                  style={[styles.itemName, {fontFamily: 'Roboto-Bold'}]}
                  numberOfLines={1}
                >
                  {strings('ordering.orderStatusPending.products')}
                </AppText>
              </View>
              <View style={styles.itemDetail2Container}>
                <AppText
                  style={[styles.itemQuantityText, {fontFamily: 'Roboto-Bold'}]}
                >
                  {strings('ordering.orderStatusPending.qty')}
                </AppText>
                <AppText
                  style={[styles.priceText, {fontFamily: 'Roboto-Bold'}]}
                >
                  {strings('ordering.orderStatusPending.price')}
                </AppText>
              </View>
            </View>
            {/* body */}
            <View style={styles.orderDetailItem}>
              <View style={{width: '50%', justifyContent: 'center'}}>
                <AppText style={[styles.itemName]}>
                  {subscriptionDetail?.product?.name}
                  {subscriptionDetail?.product_variants?.length > 0 && (
                    <AppText style={styles.productVarientsText}>
                      {' (' +
                        subscriptionDetail?.product_variants
                          ?.map((o) => o.value)
                          .join(', ') +
                        ')'}
                    </AppText>
                  )}
                </AppText>
              </View>
              <View style={styles.itemDetail2Container}>
                <AppText style={[styles.itemQuantityText]}>
                  {subscriptionDetail?.product_quantity}
                </AppText>
                <AppText style={[styles.priceText]}>
                  {currencySymbol + ' ' + price}
                </AppText>
              </View>
            </View>
            <View style={styles.orderTotalLine} />
            <View style={[styles.orderDetailItem]}>
              <View style={{width: '50%', justifyContent: 'center'}}>
                <AppText
                  style={[
                    styles.itemName,
                    {
                      fontFamily: 'Roboto-Bold',
                    },
                  ]}
                >
                  {strings(
                    'subscriptionDetailScreen.subscriptionItemCard.days',
                  )}
                </AppText>
              </View>
              <View
                style={{
                  width: '50%',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <AppText style={[styles.itemName]}>
                  {' x ' + subscriptionDetail?.total_days}
                </AppText>
              </View>
            </View>
            <View style={styles.orderDetailItem}>
              <View style={{width: '50%', justifyContent: 'center'}}>
                <AppText
                  style={[
                    styles.itemName,
                    {
                      fontFamily: 'Roboto-Bold',
                    },
                  ]}
                >
                  {strings('ordering.orderStatusPending.total')}
                </AppText>
              </View>
              <View
                style={{
                  width: '50%',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <AppText
                  style={[
                    styles.itemName,
                    {
                      fontFamily: 'Roboto-Bold',
                    },
                  ]}
                >
                  {currencySymbol +
                    ' ' +
                    parseFloat(subscriptionDetail?.total).toFixed(2).toString()}
                </AppText>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  };

  const renderButtons = () => {
    return subscriptionDetail?.status == 'paid' ? (
      <>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TouchableOpacity
            style={[styles.btn, styles.declineBtn]}
            onPress={() => updateSubscriptionStatus('declined')}
          >
            <AppText style={styles.declineBtnText}>
              {strings('ordering.orderStatusPending.decline')}
            </AppText>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.btn, styles.acceptBtn]}
            onPress={() => updateSubscriptionStatus('accepted')}
          >
            <AppText style={styles.acceptBtnText}>
              {strings('ordering.orderStatusPending.accept')}
            </AppText>
          </TouchableOpacity>
        </View>
      </>
    ) : null;
  };

  const renderSubscriptionStatus = () => {
    return (
      <View style={styles.cardContainer}>
        <View style={styles.detailCard}>
          <AppText style={styles.cardTitle}>
            {'Subscription Delivery Status'}
          </AppText>
          <View style={styles.cardTitleLine} />

          <View>
            <DatePicker
              title={
                <AppText style={styles.date}>
                  {(date && moment(date).format('yyyy-MM-DD')) ||
                    strings('coinTransactionList.value.selectDate')}
                </AppText>
              }
              visible={isDateVisible}
              minDate={new Date(subscriptionDetail?.start_date)}
              maxDate={new Date(subscriptionDetail?.end_date)}
              onClose={() => {
                setIsDateVisible(false);
              }}
              onDayPress={(date) => {
                setIsDateVisible(false);
                setDate(date?.dateString);
              }}
              onCancelPress={() => {
                setIsDateVisible(false);
              }}
              navigation={props.navigation}
              onSetDataForClass={(text) => {
                if (isDateValid(text)) {
                  setDate(text);
                }
              }}
              onCalenderPress={() => {
                if (date != '') {
                  setDate('');
                  setIsDateVisible(false);
                } else {
                  setIsDateVisible(true);
                }
              }}
            />
          </View>
          <View style={{paddingTop: 10}}>
            <TouchableOpacity
              style={[styles.btn, styles.acceptBtn, {width: '100%'}]}
              onPress={() => createDailyStatus()}
            >
              <AppText style={styles.acceptBtnText}>{'Delevered'}</AppText>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  };

  const dailyStatus = () => {
    return (
      <>
        {dailyStatusList?.map((item, index) => (
          <View style={styles.statusCardContainer} key={index}>
            <View style={styles.detailCard}>
              <View style={styles.detailItem}>
                <AppText style={styles.detailLabel}>
                  {item?.delivered_at}
                </AppText>
                <AppText style={styles.detailValue}>
                  {item?.status == true ? 'True' : 'False'}
                </AppText>
              </View>
            </View>
          </View>
        ))}
      </>
    );
  };

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <MainHeader
        title={strings('subscriptionDetailScreen.title')}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      {!loading && (
        <MainContainer>
          {renderSubscriptionSteps()}

          {renderDeliveryLocation()}

          {renderSubscriptionDetail()}
          {renderCustomerDetail()}
          {renderSubscribedItem()}
          {renderButtons()}
          {(subscriptionDetail?.status == 'accepted' ||
            subscriptionDetail?.status == 'in-process') &&
            renderSubscriptionStatus()}
          {dailyStatus()}
          <View style={{height: 50}} />
        </MainContainer>
      )}
    </View>
  );
};

export default SubscriptionDetail;
