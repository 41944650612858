import {Platform, StyleSheet} from 'react-native';

import {Colors, Layout} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  categoryButtonIconContainer: {
    flexDirection: 'row',
    borderRadius: setValue(9),
    alignItems: 'center',
    justifyContent: 'center',
  },
  categoryItem: {
    flexDirection: 'row',
    backgroundColor: Colors.white,
    marginRight: 10,
    height: '100%',
    borderRadius: 15,
    borderWidth: Platform.OS == 'web' ? 1 : 0,
    borderColor: Colors.theme_color,
  },
  categoryImages: {
    justifyContent: 'center',
  },
  categoryButtonText: {
    color: Colors.black60,
    textAlignVertical: 'center',
    fontSize: 13,
    paddingHorizontal: 10,
    alignSelf: 'center',
  },
  selected: {
    flexDirection: 'row',
    backgroundColor: Colors.theme_color,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: Colors.white,
    // marginLeft: 10,
  },
  image: {
    width: setValue(30),
    height: setValue(30),
    borderRadius: 5,
  },
  categoryImage: {},
  selectedCategoryButtonText: {
    color: Colors.white,
    textAlignVertical: 'center',
    fontSize: 13,
    justifyContent: 'center',
    paddingHorizontal: 10,
    alignSelf: 'center',
  },
  selectedCategoryButtonTextWeb: {
    color: Colors.white,
    textAlignVertical: 'center',
    justifyContent: 'center',
    fontSize: 13,
    paddingHorizontal: 10,
  },
  categoryButtonTextWeb: {
    color: Colors.black60,
    textAlignVertical: 'center',
    fontSize: 13,
    paddingHorizontal: 10,
  },
  selectedCategoryTextWebContainer: {
    justifyContent: 'center',
  },
  categoryTextWebContainer: {
    justifyContent: 'center',
  },
});
