import {Platform, StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    flex: 1,
    backgroundColor: Colors.white,
  },
  overlay: {
    flex: 1,
    backgroundColor: Colors.white,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    fontSize: setXAxisValue(18),
    color: Colors.black20,
    marginBottom: setYAxisValue(10),
    marginTop: setYAxisValue(20),
    textAlign: 'center',
    //
    paddingHorizontal: setXAxisValue(30),
    lineHeight: setXAxisValue(22),
  },
  content2: {
    fontSize: setXAxisValue(16),
    color: Colors.black20,
    marginBottom: setYAxisValue(20),
    marginTop: setYAxisValue(20),
    textAlign: 'center',
    paddingHorizontal: setXAxisValue(30),
    lineHeight: setXAxisValue(22),
  },
  images: {
    marginVertical: setYAxisValue(40),
    width: setXAxisValue(300),
    height: setXAxisValue(300),
    alignSelf: 'center',
  },
  crossIcon: {
    position: 'absolute',
    top: Platform.select({web: setYAxisValue(20), default: 20}),
    right: setXAxisValue(20),
  },
});
