import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styles from './styles';
import CommonAction from '../../redux/reduxsauce/commonRedux';
import axios from 'axios';
import {goBack, setValue} from '../../utils';
import {
  AppText,
  CheckBox,
  DropDownInput,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
  Modal,
} from '../../components';
import {
  Button,
  Image,
  Linking,
  Platform,
  TouchableOpacity,
  View,
  TextInput,
  Text,
  FlatList,
} from 'react-native';
import {Colors} from '../../constants';
import PhoneInput from '../../lib/react-native-phone-input';
import {strings} from '../../i18n';
import {get} from 'lodash';
import {useIsFocused} from '@react-navigation/native';

const ShipRocketDelivery = (props) => {
  const orderId = props?.route?.params?.orderId;

  const isFocused = useIsFocused();
  const dispatch = useDispatch();

  const [shippingDetail, setShippingDetail] = useState(null);
  const [orderDetail, setOrderDetail] = useState(null);

  const trackOrderNow = () => {
    axios
      .get('/delivery/order/track/' + orderId)
      .then((response) => {
        console.log('ship-rocket  order tracking', response);

        if (response?.data?.success === true) {
          console.log('shipment detail', shippingDetail);
          setShippingDetail({...shippingDetail, ...response?.data?.data?.data});
        } else {
          dispatch(
            CommonAction.setAlert({
              visible: true,
              content: response?.data?.message || 'Something want wrong!',
            }),
          );
        }
        dispatch(CommonAction.setLoading(false));
      })
      .catch((error) => {
        console.log('ship-rocket order tracking error', error?.response);
        dispatch(CommonAction.setLoading(false));
      });
  };

  const getOrderDetail = () => {
    dispatch(CommonAction.setLoading(true));

    axios
      .get('/order/detail/' + orderId)
      .then(async (response) => {
        console.log('order Detail --', response);
        if (response?.data?.data?.delivery_information) {
          setShippingDetail({
            ...shippingDetail,
            ...response?.data?.data?.delivery_information,
          });
          setOrderDetail(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log('error-->', error?.response);
        dispatch(CommonAction.setLoading(false));
      });
  };

  // login                      https://apiv2.shiprocket.in/v1/external/auth/login
  // get pickup address         https://apiv2.shiprocket.in/v1/external/settings/company/pickup
  // order create               https://apiv2.shiprocket.in/v1/external/orders/create/adhoc
  // generate awb               https://apiv2.shiprocket.in/v1/external/courier/assign/awb
  // get courier companies      https://apiv2.shiprocket.in/v1/external/courier/serviceability/
  // generate label             https://apiv2.shiprocket.in/v1/external/courier/generate/label
  // order detail status        https://apiv2.shiprocket.in/v1/external/orders/show/

  // flow
  // login  -> check shipping detail ? -> order detail  status
  // : -> pickup address list (in our case this is not in use)
  // create order -> courier company  -> generate awb
  //  shipping detail -> shipping Id = > generate label

  useEffect(() => {
    orderId && getOrderDetail();
  }, [isFocused, orderId]);

  useEffect(() => {
    shippingDetail?.awb_code && trackOrderNow();
  }, [orderDetail]);

  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('shipRocketDeliveryScreen.pageTitle')}
        leftIcon={'back'}
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer>
        <View style={styles.orderDetailCard}>
          <View>
            <AppText style={styles.cardHeader}>
              {strings('shipRocketDeliveryScreen.static.title')}
            </AppText>
          </View>

          <View style={styles.detailRow}>
            <View style={{maxWidth: '35%'}} numberOfLine={2}>
              <AppText
                style={{
                  fontFamily: 'Roboto-Regular',
                  fontSize: setValue(15),
                }}
              >
                {strings('shipRocketDeliveryScreen.static.orderId')}
              </AppText>
            </View>
            <View style={{maxWidth: '60%'}} numberOfLine={2}>
              <AppText
                style={{
                  fontFamily: 'Roboto-Regular',
                  fontSize: setValue(15),
                }}
              >
                {shippingDetail?.order_id}
              </AppText>
            </View>
          </View>

          <View style={styles.detailRow}>
            <View style={{maxWidth: '35%'}} numberOfLine={2}>
              <AppText
                style={{
                  fontFamily: 'Roboto-Regular',
                  fontSize: setValue(15),
                }}
              >
                {strings('shipRocketDeliveryScreen.static.shipmentId')}
              </AppText>
            </View>
            <View style={{maxWidth: '60%'}} numberOfLine={2}>
              <AppText
                style={{
                  fontFamily: 'Roboto-Regular',
                  fontSize: setValue(15),
                }}
              >
                {shippingDetail?.shipment_id}
              </AppText>
            </View>
          </View>

          <View style={styles.detailRow}>
            <View style={{maxWidth: '35%'}} numberOfLine={2}>
              <AppText
                style={{
                  fontFamily: 'Roboto-Regular',
                  fontSize: setValue(15),
                }}
              >
                {strings('shipRocketDeliveryScreen.static.awb')}
              </AppText>
            </View>
            <View style={{maxWidth: '60%'}} numberOfLine={2}>
              <AppText
                style={{
                  fontFamily: 'Roboto-Regular',
                  fontSize: setValue(15),
                }}
              >
                {shippingDetail?.awb_code}
              </AppText>
            </View>
          </View>

          <View style={styles.detailRow}>
            <View style={{maxWidth: '35%'}} numberOfLine={2}>
              <AppText
                style={{
                  fontFamily: 'Roboto-Regular',
                  fontSize: setValue(15),
                }}
              >
                {strings('shipRocketDeliveryScreen.label.name')}
              </AppText>
            </View>
            <View style={{maxWidth: '60%'}} numberOfLine={2}>
              <AppText
                style={{
                  fontFamily: 'Roboto-Regular',
                  fontSize: setValue(15),
                  textTransform: 'capitalize',
                }}
              >
                {shippingDetail?.courier_name}
              </AppText>
            </View>
          </View>

          <View style={styles.detailRow}>
            <View style={{maxWidth: '35%'}} numberOfLine={2}>
              <AppText
                style={{
                  fontFamily: 'Roboto-Regular',
                  fontSize: setValue(15),
                }}
              >
                {strings('shipRocketDeliveryScreen.label.id')}
              </AppText>
            </View>
            <View style={{maxWidth: '60%'}} numberOfLine={2}>
              <AppText
                style={{
                  fontFamily: 'Roboto-Regular',
                  fontSize: setValue(15),
                }}
              >
                {shippingDetail?.courier_company_id}
              </AppText>
            </View>
          </View>

          <View style={styles.detailRow}>
            <View style={{maxWidth: '35%'}} numberOfLine={2}>
              <AppText
                style={{
                  fontFamily: 'Roboto-Regular',
                  fontSize: setValue(15),
                }}
              >
                {strings('shipRocketDeliveryScreen.static.status')}
              </AppText>
            </View>
            <View style={{maxWidth: '60%'}} numberOfLine={2}>
              <AppText
                style={{
                  fontFamily: 'Roboto-Regular',
                  fontSize: setValue(15),
                }}
              >
                {shippingDetail?.status}
              </AppText>
            </View>
          </View>
        </View>

        {shippingDetail?.label && (
          <View style={{marginVertical: setValue(20)}}>
            <TouchableOpacity
              style={styles.downloadButton}
              onPress={() => Linking.openURL(shippingDetail?.label)}
            >
              <AppText style={styles.btnText}>
                {strings('shipRocketDeliveryScreen.button.download')}
              </AppText>
            </TouchableOpacity>
          </View>
        )}
      </MainContainer>
    </View>
  );
};

export default ShipRocketDelivery;
