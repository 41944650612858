import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {setValue, setXAxisValue} from '../../utils';

export default StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.theme_color,
    borderRadius: 5,
    height: setValue(50),
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 5,
    width: '100%',
  },
  text: {
    fontSize: setXAxisValue(14),
    color: Colors.primary_color,
    // fontFamily: ''
  },
  disabledContainer: {
    backgroundColor: Colors.theme_color,
    opacity: 0.5,
  },
});
