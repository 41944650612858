import { Platform } from 'react-native';
import WebWebView from 'react-native-web-webview';
import { WebView as RNWebView } from 'react-native-webview';

const WebView = Platform.select({
  web: WebWebView,
  default: RNWebView
});

export default WebView;
