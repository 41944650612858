import CommonActions from '../../../redux/reduxsauce/commonRedux';
import React, {Component} from 'react';
import {
  TextInput,
  TouchableOpacity,
  View,
  Platform,
  ActivityIndicator,
} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import axios from 'axios';
import {
  AppText,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../../components';
import {Colors, ApiKey} from '../../../constants';
import {strings} from '../../../i18n';
import {goBack, screenHeight, screenWidth, setValue} from '../../../utils';
import styles from './styles';
import * as Location from 'expo-location';
import MapView from 'react-native-maps';
import Geocoder from 'react-native-geocoding';
import _ from 'lodash';
import {
  addFranchise,
  updateFranchise,
} from '../../../redux/reduxsauceActions/franchiseAction';

class EditFranchise extends Component {
  constructor(props) {
    super(props);
    const item = props.route?.params?.item;
    this.state = {
      mapRegion: {
        latitude: item?.latitude ? parseFloat(item?.latitude) : '',
        longitude: item?.longitude ? parseFloat(item?.longitude) : '',
      },
      isLocationLoading: false,
      locationPermission: null,
      locationFetchWaiting: false,
      name: item?.name ? item?.name : '',
      detail: item?.details ? item?.details : '',
      street: item?.street ? item?.street : '',
      area: item?.area ? item?.area : '',
      city: item?.city ? item?.city : '',
      state: item?.state ? item?.state : '',
      country: item?.state ? item?.country : '',
      pincode: item?.pincode ? item?.pincode : '',
    };
  }

  onAddNewAddress = async () => {
    const {navigation, profile, openAlert, route} = this.props;
    const {
      street,
      area,
      type,
      customType,
      city,
      state,
      pincode,
      mapRegion,
      country,
      name,
      detail,
    } = this.state;

    if (!name) {
      openAlert(strings('message.outletName'));
    } else if (!street || street == '') {
      openAlert(strings('message.streetRequire'));
    } else if (!area || area == '') {
      openAlert(strings('message.areaRequire'));
    } else if (!city || city == '') {
      openAlert(strings('message.cityRequire'));
    } else if (!state || state == '') {
      openAlert(strings('message.stateRequire'));
    } else if (!country || country == '') {
      openAlert(strings('message.countryRequire'));
    } else if (!pincode || pincode == '') {
      openAlert(strings('addFranchiseScreen.alert.pincode'));
    } else {
      var body = {
        name: name,
        street: street,
        area: area,
        pincode: pincode,
        city: city,
        state: state,
        country: country,
        latitude: mapRegion.latitude || 0,
        longitude: mapRegion.longitude || 0,
      };
      if (detail) {
        body = {
          ...body,
          details: detail,
        };
      }

      console.log('Budy==>', body);
      if (this.props.route.params?.edit) {
        this.props.updateFranchise(
          body,
          this.props.route.params?.item?.id,
          navigation,
        );
      } else {
        this.props.addFranchise(body, navigation);
      }
    }
  };

  _handleMapRegionChange = (mapRegion) => {
    this.setState({mapRegion});
  };

  handleLocation = async (mapRegion) => {
    if (
      this.state.mapRegion.latitude.toFixed(5) !=
        mapRegion.latitude.toFixed(5) &&
      this.state.mapRegion.longitude.toFixed(5) !=
        mapRegion.longitude.toFixed(5)
    ) {
      await this.setState({locationFetchWaiting: true, mapRegion: mapRegion});
      Geocoder.init(ApiKey.GOOGLE_GEOCODING_APIKEY);
      Geocoder.from(mapRegion.latitude, mapRegion.longitude)
        .then((address) => {
          let level = '';
          this.setState({
            area: '',
            city: '',
            state: '',
            country: '',
            pincode: '',
          });
          address.results[0].address_components.forEach((component) => {
            if (component.types.includes('sublocality_level_2')) {
              this.setState({
                area: level
                  ? component.long_name + ', ' + level
                  : component.long_name,
              });
              level = component.long_name;
            }
            if (component.types.includes('sublocality_level_3')) {
              this.setState({
                area: level
                  ? level + ', ' + component.long_name
                  : component.long_name,
              });
              level = component.long_name;
            }

            if (component.types[0] === 'locality') {
              this.setState({city: component.long_name});
            }
            if (component.types[0] === 'administrative_area_level_1') {
              this.setState({state: component.long_name});
            }
            if (component.types[0] === 'country') {
              this.setState({country: component.long_name});
            }
            if (component.types[0] === 'postal_code') {
              this.setState({pincode: component.long_name});
            }
          });

          this.setState({locationFetchWaiting: false});
        })
        .catch((error) => {
          console.log('geocoder error -->', error);
          this.setState({locationFetchWaiting: false});
        });
    }
  };

  autoCompleteInputs = async () => {
    await this.setState({locationFetchWaiting: true});
    Geocoder.init(ApiKey.GOOGLE_GEOCODING_APIKEY);
    Geocoder.from(
      this.state.mapRegion.latitude,
      this.state.mapRegion.longitude,
    ).then((address) => {
      let level = '';
      address.results[0].address_components
        .forEach((component) => {
          if (component.types.includes('sublocality_level_2')) {
            this.setState({
              area: level
                ? component.long_name + ', ' + level
                : component.long_name,
            });
            level = component.long_name;
          }
          if (component.types.includes('sublocality_level_3')) {
            this.setState({
              area: level
                ? level + ', ' + component.long_name
                : component.long_name,
            });
            level = component.long_name;
          }

          if (component.types[0] === 'locality') {
            this.setState({city: component.long_name});
          }
          if (component.types[0] === 'administrative_area_level_1') {
            this.setState({state: component.long_name});
          }
          if (component.types[0] === 'country') {
            this.setState({country: component.long_name});
          }
          if (component.types[0] === 'postal_code') {
            this.setState({pincode: component.long_name});
          }
        })
        .catch((error) => {
          console.log('geocoder error -->', error);
          this.setState({locationFetchWaiting: false});
        });
      this.setState({locationFetchWaiting: false});
    });
  };

  componentDidMount = () => {
    this.getLocationAsync();
  };

  getLocationAsync = async () => {
    let {status} = await Location.requestForegroundPermissionsAsync();
    if (status !== 'granted') {
      // setErrorMsg('Permission to access location was denied');
      this.setState({
        locationPermission: false,
      });
      this.props.openAlert(strings('addFranchiseScreen.alert.permission'));
      return;
    }
    this.setState({isLocationLoading: true});
    let location = await Location.getCurrentPositionAsync({});
    // setLocation(location);
    if (
      this.props.route?.params?.item?.latitude &&
      this.props.route?.params?.item?.longitude
    ) {
      this.setState({
        locationPermission: true,
        isLocationLoading: false,
      });
    } else {
      Geocoder.init(ApiKey.GOOGLE_GEOCODING_APIKEY);
      Geocoder.from(location.coords.latitude, location.coords.longitude)
        .then((address) => {
          let level = '';
          address.results[0].address_components.forEach((component) => {
            if (component.types.includes('sublocality_level_2')) {
              this.setState({
                area: level
                  ? component.long_name + ', ' + level
                  : component.long_name,
              });
              level = component.long_name;
            }
            if (component.types.includes('sublocality_level_3')) {
              this.setState({
                area: level
                  ? level + ', ' + component.long_name
                  : component.long_name,
              });
              level = component.long_name;
            }

            if (component.types[0] === 'locality') {
              this.setState({city: component.long_name});
            }
            if (component.types[0] === 'administrative_area_level_1') {
              this.setState({state: component.long_name});
            }
            if (component.types[0] === 'country') {
              this.setState({country: component.long_name});
            }
            if (component.types[0] === 'postal_code') {
              this.setState({pincode: component.long_name});
            }
          });
        })
        .catch((error) => {
          console.log('geocoder error -->', error);
          this.setState({locationFetchWaiting: false});
        });
      this.setState({
        locationPermission: true,
        isLocationLoading: false,
        mapRegion: {
          latitude: location.coords.latitude,
          longitude: location.coords.longitude,
        },
      });
    }

    console.log('location', location);
  };

  render() {
    const {navigation, config} = this.props;
    const {
      name,
      detail,
      street,
      area,
      city,
      state,
      country,
      pincode,
      locationPermission,
      isLocationLoading,
      locationFetchWaiting,
    } = this.state;
    return (
      <View style={styles.container}>
        <MainHeader
          title={
            this.props?.route?.params?.item?.id
              ? strings('addFranchiseScreen.title.edit')
              : strings('addFranchiseScreen.title.add')
          }
          leftIcon="back"
          leftPress={() => goBack(navigation)}
        />
        <MainContainer>
          <View style={styles.textInputContainer}>
            {isLocationLoading ? (
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: screenHeight / 4,
                }}
              >
                <ActivityIndicator size="large" color={Colors.theme_color} />
              </View>
            ) : Platform.OS == 'web' && locationPermission ? (
              <MapView
                style={styles.map}
                provider={MapView.PROVIDER_GOOGLE}
                showsUserLocation={true}
                zoomEnabled={true}
                zoomControlEnabled={true}
                zoomTapEnabled={true}
                initialRegion={{
                  ...this.state.mapRegion,
                  latitudeDelta: 0.0922,
                  longitudeDelta: 0.0421,
                }}
                onRegionChangeComplete={this.handleLocation}
              >
                <MapView.Marker
                  coordinate={this.state.mapRegion}
                  ref={(ref) => {
                    this._markerRef = ref;
                  }}
                  title={strings('addFranchiseScreen.location')}
                  draggable
                  pinColor={'blue'}
                  onDragEnd={(e) => {
                    this.setState(
                      {
                        mapRegion: {
                          ...this.state.mapRegion,
                          latitude: e.latLng.lat(),
                          longitude: e.latLng.lng(),
                        },
                      },
                      () => this.autoCompleteInputs(),
                    );
                  }}
                />
              </MapView>
            ) : locationPermission ? (
              <MapView
                style={styles.map}
                provider={MapView.PROVIDER_GOOGLE}
                showsUserLocation={true}
                zoomEnabled={true}
                zoomControlEnabled={true}
                zoomTapEnabled={true}
                initialRegion={{
                  ...this.state.mapRegion,
                  latitudeDelta: 0.0922,
                  longitudeDelta: 0.0421,
                }}
                onRegionChange={_.debounce(this._handleMapRegionChange)}
                onRegionChangeComplete={this.handleLocation}
              >
                <MapView.Marker
                  coordinate={this.state.mapRegion}
                  ref={(ref) => {
                    this._markerRef = ref;
                  }}
                  title={strings('addFranchiseScreen.location')}
                  draggable
                  onDragEnd={(e) => {
                    this.setState({mapRegion: e.nativeEvent.coordinate}, () =>
                      this.autoCompleteInputs(),
                    );
                  }}
                />
              </MapView>
            ) : (
              locationPermission == false &&
              locationPermission != null && (
                <View
                  style={{
                    marginVertical: setValue(50),
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <AppText
                    style={{
                      width: screenWidth / 1.5,
                      textAlign: 'center',
                      color: Colors.wallet_card_red,
                    }}
                  >
                    {strings('addFranchiseScreen.liveLocation')}
                  </AppText>
                  <TouchableOpacity
                    style={styles.LocationRequestButton}
                    onPress={() => this.getLocationAsync()}
                  >
                    <AppText style={{color: Colors.white}}>
                      {strings('addFranchiseScreen.button.request')}
                    </AppText>
                  </TouchableOpacity>
                </View>
              )
            )}
          </View>
          {locationFetchWaiting ? (
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ActivityIndicator size="large" color={Colors.theme_color} />
            </View>
          ) : (
            <KeyboardAwareScrollView>
              <>
                <View style={styles.textInputContainer}>
                  <AppText style={styles.title} required>
                    {strings('addFranchiseScreen.label.name')}
                  </AppText>
                  <TextInput
                    ref={(input) => {
                      this.name = input;
                    }}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                    autoCorrect={false}
                    placeholder={strings('addFranchiseScreen.placeholder.name')}
                    placeholderTextColor={Colors.color30}
                    returnKeyType="done"
                    style={styles.textInput}
                    onSubmitEditing={() => this.detail.focus()}
                    value={name}
                    onChangeText={(text) => this.setState({name: text})}
                  />
                </View>

                <View style={styles.textInputContainer}>
                  <AppText style={styles.title}>
                    {strings('addFranchiseScreen.label.detail')}
                  </AppText>
                  <TextInput
                    ref={(input) => {
                      this.detail = input;
                    }}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                    autoCorrect={false}
                    placeholder={strings(
                      'addFranchiseScreen.placeholder.detail',
                    )}
                    placeholderTextColor={Colors.color30}
                    multiline={true}
                    numberOfLines={3}
                    returnKeyType="done"
                    style={
                      Platform.OS == 'web'
                        ? styles.textInputWeb
                        : styles.multiLineTextInput
                    }
                    // style={styles.textInput}
                    onSubmitEditing={() => this.streetInput.focus()}
                    value={detail}
                    onChangeText={(text) => this.setState({detail: text})}
                  />
                </View>
                <View style={styles.textInputContainer}>
                  <AppText required style={styles.title}>
                    {strings('addNewAddressScreen.title.street')}
                  </AppText>
                  <TextInput
                    ref={(input) => {
                      this.streetInput = input;
                    }}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                    autoCorrect={false}
                    placeholder={strings(
                      'addNewAddressScreen.placeHolder.street',
                    )}
                    placeholderTextColor={Colors.color30}
                    returnKeyType="done"
                    style={styles.textInput}
                    onSubmitEditing={() => this.areaInput.focus()}
                    value={street}
                    onChangeText={(text) => this.setState({street: text})}
                  />
                </View>
                <View style={styles.textInputContainer}>
                  <AppText required style={styles.title}>
                    {strings('addNewAddressScreen.title.area')}
                  </AppText>
                  <TextInput
                    ref={(input) => {
                      this.areaInput = input;
                    }}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                    autoCorrect={false}
                    placeholder={strings(
                      'addNewAddressScreen.placeHolder.area',
                    )}
                    placeholderTextColor={Colors.color30}
                    returnKeyType="done"
                    style={styles.textInput}
                    onSubmitEditing={() => this.cityInput.focus()}
                    value={area}
                    onChangeText={(text) => this.setState({area: text})}
                  />
                </View>
                <View style={styles.textInputContainer}>
                  <AppText required style={styles.title}>
                    {strings('addNewAddressScreen.title.city')}
                  </AppText>
                  <TextInput
                    ref={(input) => {
                      this.cityInput = input;
                    }}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                    autoCorrect={false}
                    placeholder={strings(
                      'addNewAddressScreen.placeHolder.city',
                    )}
                    placeholderTextColor={Colors.color30}
                    returnKeyType="done"
                    style={styles.textInput}
                    onSubmitEditing={() => this.stateInput.focus()}
                    value={city}
                    onChangeText={(text) => this.setState({city: text})}
                  />
                </View>
                <View style={styles.textInputContainer}>
                  <AppText required style={styles.title}>
                    {strings('addNewAddressScreen.title.state')}
                  </AppText>
                  <TextInput
                    ref={(input) => {
                      this.stateInput = input;
                    }}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                    autoCorrect={false}
                    placeholder={strings(
                      'addNewAddressScreen.placeHolder.state',
                    )}
                    placeholderTextColor={Colors.color30}
                    returnKeyType="done"
                    style={styles.textInput}
                    onSubmitEditing={() => this.pincodeINput.focus()}
                    value={state}
                    onChangeText={(text) => this.setState({state: text})}
                  />
                </View>
                <View style={styles.textInputContainer}>
                  <AppText required style={styles.title}>
                    {strings('addNewAddressScreen.title.country')}
                  </AppText>
                  <TextInput
                    ref={(input) => {
                      this.stateInput = input;
                    }}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                    autoCorrect={false}
                    placeholder={strings(
                      'addNewAddressScreen.placeHolder.country',
                    )}
                    placeholderTextColor={Colors.color30}
                    returnKeyType="done"
                    style={styles.textInput}
                    onSubmitEditing={() => this.pincodeINput.focus()}
                    value={country}
                    onChangeText={(text) => this.setState({country: text})}
                  />
                </View>
                <View style={styles.textInputContainer}>
                  <AppText required style={styles.title}>
                    {strings('addNewAddressScreen.title.postcode')}
                  </AppText>
                  <TextInput
                    ref={(input) => {
                      this.pincodeINput = input;
                    }}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                    autoCorrect={false}
                    placeholder={strings(
                      'addNewAddressScreen.placeHolder.postcode',
                    )}
                    placeholderTextColor={Colors.color30}
                    returnKeyType="done"
                    style={styles.textInput}
                    // onSubmitEditing={() => this.address2Input.focus()}
                    value={String(pincode)}
                    onChangeText={(text) => this.setState({pincode: text})}
                  />
                </View>
                {Platform.OS == 'web' && (
                  <LongButton
                    text={
                      this.props?.route?.params?.item?.id
                        ? strings('addNewAddressScreen.edit')
                        : strings('addNewAddressScreen.add')
                    }
                    style={{
                      marginTop: setValue(20),
                      marginBottom: setValue(45),
                    }}
                    onPress={this.onAddNewAddress}
                  />
                )}
              </>
            </KeyboardAwareScrollView>
          )}
        </MainContainer>
        {Platform.OS !== 'web' && (
          <LongButton
            text={
              this.props?.route?.params?.item?.id
                ? strings('addNewAddressScreen.edit')
                : strings('addNewAddressScreen.add')
            }
            style={{
              width: '100%',
              borderRadius: 0,
            }}
            onPress={this.onAddNewAddress}
          />
        )}
      </View>
    );
  }
}

export default connect(
  (state) => ({
    profile: state.auth.profile,
    config: state.config,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        addFranchise,
        openAlert: (content) =>
          CommonActions.setAlert({visible: true, content: content}),
        updateFranchise,
      },
      dispatch,
    ),
)(EditFranchise);
