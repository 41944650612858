import React, {useEffect, useState} from 'react';
import {TextInput, View, Platform} from 'react-native';
import styles from './styles';
import {Colors} from '../../constants';
import AppText from '../AppText';
import DropDownInput from '../DropDownInput';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import CommonActions from '../../redux/reduxsauce/commonRedux';

export default function VariantItem(props) {
  const config = useSelector((state) => state.config);
  const dispatch = useDispatch();

  const [variantTypesList, setVariantTypesList] = useState([]);
  const [variantList, setVariantList] = useState([]);
  const [selectedDetail, setSelectedDetail] = useState({
    variant_type: null,
    variant: null,
    price: '',
  });

  const getVariantTypesList = () => {
    dispatch(CommonActions.setLoading(true));
    axios
      .get('/products/variant-type/list/' + config?.businessId)
      .then((response) => {
        console.log('response of variant type list===', response);
        setVariantTypesList(response?.data);
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        console.log('error in response of variant type list===', error);
        dispatch(CommonActions.setLoading(false));
      });
  };

  const getVariantList = () => {
    dispatch(CommonActions.setLoading(true));
    axios
      .get('/products/variant/list/' + config?.businessId)
      .then((response) => {
        console.log('response of variant list===', response);

        setVariantList(response?.data);
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        console.log('error in response of variant list===', error);
        dispatch(CommonActions.setLoading(false));
      });
  };

  const arr = variantList.filter(
    (item) => item?.variant_type == selectedDetail?.variant_type,
  );

  useEffect(() => {
    getVariantTypesList();
    getVariantList();
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.textInputContainer}>
        <AppText style={styles.inputLabel}>{'Variant Type'}</AppText>
        <DropDownInput
          data={variantTypesList?.map((o) => ({
            label: o?.type,
            value: o?.id,
          }))}
          value={selectedDetail?.variant_type}
          inputStyle={[
            styles.textInput,
            {
              paddingHorizontal: 10,
              marginTop: 10,
            },
          ]}
          placeholder={'Select variant type'}
          onChangeText={(text) => {
            setSelectedDetail({...selectedDetail, variant_type: text});
          }}
        />
      </View>

      <View style={styles.textInputContainer}>
        <AppText style={styles.inputLabel}>{'Variant'}</AppText>
        <DropDownInput
          data={arr?.map((o) => ({
            label: o?.name,
            value: o?.id,
          }))}
          value={selectedDetail?.variant}
          inputStyle={[
            styles.textInput,
            {
              paddingHorizontal: 10,
              marginTop: 10,
            },
          ]}
          placeholder={'Select variant'}
          onChangeText={(text) => {
            setSelectedDetail({...selectedDetail, variant: text});
            props.variant(text);
          }}
        />
      </View>

      <View style={styles.textInputContainer}>
        <AppText style={styles.inputLabel}>{'Additional Price'}</AppText>
        <TextInput
          underlineColorAndroid="transparent"
          placeholder={'price'}
          keyboardType="numeric"
          placeholderTextColor={Colors.color30}
          returnKeyType="done"
          style={
            Platform.OS == 'web'
              ? styles.textInputWeb
              : styles.multiLineTextInput
          }
          value={selectedDetail?.price}
          onChangeText={(text) => {
            setSelectedDetail({...selectedDetail, price: text});
            props.price(text);
          }}
        />
      </View>
    </View>
  );
}
