import { StyleSheet } from 'react-native';

import { Colors } from '../../../constants';
import { setValue, setXAxisValue, setYAxisValue } from '../../../utils';

export default StyleSheet.create({
  container: {
    flex: 1
  },
  textInput: {
    height: setValue(50),
    padding: 0,
    flex: 1,
    paddingHorizontal: setXAxisValue(5),
    marginBottom: setYAxisValue(10),
    borderRadius: 5,
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8
  },
  textInputContainer: {
    height: setValue(60)
  },
  button: {
    marginVertical: setYAxisValue(25)
  },
  title: {
    fontSize: setXAxisValue(14),
    color: Colors.white,
    textAlign: 'center',
    marginBottom: setYAxisValue(20)
  },
  body: {
    justifyContent: 'space-between',
    flex: 1
  }
});
