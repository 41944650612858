import React, {useState, useEffect} from 'react';
import {
  Platform,
  TouchableOpacity,
  View,
  Text,
  Image,
  ScrollView,
} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {get} from 'lodash';
import {
  AppText,
  MainContainer,
  MainHeader,
  ProgressiveImage,
} from '../../components';
import {strings} from '../../i18n';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {goBack} from '../../utils';
import styles from './styles';
import axios from 'axios';
import {getServices} from '../../redux/reduxsauceActions/servicesActions';
import {useIsFocused} from '@react-navigation/native';
import {Colors} from '../../constants';
import Swiper from '../../components/Swiper';
import {useDispatch} from 'react-redux';

const ServiceDetailScreen = (props) => {
  const {
    user,
    navigation,
    route,
    openAlert,
    appConfig,
    setLoading,
  } = props;
  let currencySymbol = get(appConfig, 'currencySymbol', '₹');
  const {item} = route.params;
  const isFocused = useIsFocused();
  const dispatch = useDispatch();

  const [visibleImage, setVisibleImage] = useState(false);
  const [serviceDetail, setServiceDetail] = useState({});

  let discountAmount =
    parseInt(serviceDetail.price) +
    (parseInt(serviceDetail.price) * parseInt(serviceDetail.discount)) / 100;

  const getServiceDetail = async () => {
    dispatch(CommonActions.setLoading(true));

    await axios
      .get('/services/detail/get/' + item?.id)
      .then((response) => {
        console.log('services detail response', response.data);
        setServiceDetail(response?.data);
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        console.log('error->', error.response);
        dispatch(CommonActions.setLoading(false));
      });
  };

  useEffect(() => {
    isFocused && getServiceDetail();
  }, [isFocused]);

  let specificationText = serviceDetail?.description
    ? serviceDetail.description
    : strings('ordering.productDetail.noDesc');

  return (
    <View
      style={
        Platform.OS == 'web'
          ? {overflow: 'visible', height: '100vh'}
          : styles.container
      }
    >
      <MainHeader
        title={`${serviceDetail?.name}`}
        leftIcon="back"
        rightIcon={['merchant', 'manager'].includes(user?.type) && 'edit'}
        rightPress={() =>
          navigation.navigate('edit-services', {
            item,
          })
        }
        leftPress={() => goBack(navigation)}
        rightOtherIcon={
          user?.id &&
          ['merchant', 'manager'].includes(user?.type) &&
          'dustBinSmaller'
        }
        rightOtherPress={() => {
          openAlert(
            strings('serviceDetailsScreen.alert.delete'),
            strings('common.yes'),
            async () => {
              setLoading(true);
              await axios
                .delete('/services/delete/' + item.id)
                .then((response) => {
                  goBack(navigation);
                  setLoading(false);
                })
                .catch((error) => {
                  openAlert(error?.response?.message);
                  setLoading(false);
                });
            },
            strings('common.no'),
            null,
          );
        }}
      />
      <MainContainer>
        <KeyboardAwareScrollView>
          <View
            style={
              appConfig?.product?.aspectRatio
                ? {
                    aspectRatio:
                      appConfig?.product?.aspectRatio[0] /
                      appConfig?.product?.aspectRatio[1],
                  }
                : {
                    aspectRatio: 4 / 3,
                  }
            }
          >
            {item.images?.length > 0 ? (
              <Swiper
                autoplay={true}
                activeDotColor={Colors.theme_color}
                autoplayTimeout={7}
              >
                {item?.images?.map((o, index) => (
                  <TouchableOpacity
                    onPress={() => {
                      setVisibleImage(true);
                    }}
                    key={index}
                  >
                    <ProgressiveImage
                      thumbnailSource={{uri: o?.photo}}
                      source={{uri: o?.photo}}
                      style={
                        appConfig?.product?.aspectRatio
                          ? [
                              styles.image,

                              {
                                aspectRatio:
                                  appConfig?.product?.aspectRatio[0] /
                                  appConfig?.product?.aspectRatio[1],
                              },
                            ]
                          : [styles.image, {aspectRatio: 4 / 3}]
                      }
                      resizeMode="cover"
                      key={index}
                    />
                  </TouchableOpacity>
                ))}
              </Swiper>
            ) : (
              <Image
                resizeMode="cover"
                source={require('../../../assets/images/General/no-img-found.jpg')}
                style={
                  appConfig?.product?.aspectRatio
                    ? [
                        styles.image,

                        {
                          aspectRatio:
                            appConfig?.product?.aspectRatio[0] /
                            appConfig?.product?.aspectRatio[1],
                        },
                      ]
                    : [styles.image, {aspectRatio: 4 / 3}]
                }
              />
            )}
          </View>
          <View
            style={[
              styles.sectionContainer,
              {
                flexDirection: 'column',
              },
            ]}
          >
            <AppText style={styles.summaryPriceText}>
              {`${currencySymbol} ${serviceDetail.price} ${' '}`}
              {(!serviceDetail.discount || serviceDetail.discount == 0) && (
                <Text style={{fontSize: 14, color: Colors.black60}}>
                  (Inclusive of all taxes)
                </Text>
              )}
              {serviceDetail.discount && serviceDetail.discount != 0 && (
                <AppText style={styles.discountText}>
                  {`${currencySymbol} ${discountAmount}`}
                </AppText>
              )}
            </AppText>
            {serviceDetail.discount && serviceDetail.discount != 0 && (
              <AppText style={{fontSize: 16, color: Colors.black60}}>
                You save :
                <AppText style={{fontSize: 16, color: 'green'}}>
                  {' ' +
                    currencySymbol +
                    ' ' +
                    (
                      parseFloat(discountAmount) - parseInt(serviceDetail.price)
                    ).toFixed(1) +
                    '   '}
                </AppText>
                <AppText style={{fontSize: 14, color: Colors.black60}}>
                  (Inclusive of all taxes)
                </AppText>
              </AppText>
            )}
          </View>
          <View style={styles.summaryDescContainer}>
            <AppText
              style={{
                color: '#5D5D5D',
                fontSize: 12,
                lineHeight: 17,
                paddingVertical: 2,
              }}
            >
              {specificationText}
            </AppText>
          </View>
        </KeyboardAwareScrollView>
      </MainContainer>
    </View>
  );
};

export default connect(
  (state) => ({
    appConfig: state.config,
    user: state.auth.user,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getServices,
        setLoading: CommonActions.setLoading,
        openAlert: (content, leftButton, leftPress, rightButton, rightPress) =>
          CommonActions.setAlert({
            visible: true,
            content,
            leftButton,
            leftPress,
            rightButton,
            rightPress,
          }),
      },
      dispatch,
    ),
)(ServiceDetailScreen);
