import axios from 'axios';
import React, {useState, useRef, useEffect} from 'react';
import {
  View,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';

import {
  AppText,
  LongButton,
  MainContainer,
  MainHeader,
  Icon,
} from '../../components';
import {strings} from '../../i18n';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {verifyOTP} from '../../redux/reduxsauceActions/authActions';
import {goBack, screenWidth, setYAxisValue} from '../../utils';
import {Colors} from '../../constants';
import styles from './styles';

const ResetPasswordScreen = (props) => {
  const otp1 = useRef();
  const otp2 = useRef();
  const otp3 = useRef();
  const otp4 = useRef();
  const otp5 = useRef();
  const otp6 = useRef();

  const dispatch = useDispatch();
  const [otp, setOtp] = useState({});
  const [userOTP, setUserOTP] = useState('');
  const [newPassword, setNewPassord] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const {navigation} = props;
  const [loading, setLoading] = useState(false);

  const [isSecureNewPassord, setIsSecureNewPassord] = useState(true);
  const [isSecureConfirmPassord, setIsSecureConfirmPassord] = useState(true);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);

  const onChangePassword = (text, val, perv, next) => {
    var temperoryOtp = otp;
    if (perv && (text == '' || !text)) {
      perv.current.focus();
    }
    if (next && !(text == '' || !text)) {
      next.current.focus();
    }
    temperoryOtp[val] = text[0];
    var fullOtp = '';
    Object.values(temperoryOtp).forEach((a) => (fullOtp += a ? a : ''));
    console.log(fullOtp);
    setUserOTP(fullOtp);
  };
  const onOpenAlert = (data) =>
    dispatch(
      CommonActions.setAlert({
        visible: true,
        content: data,
      }),
    );
  const onResendOtp = async () => {
    dispatch(CommonActions.setLoading(true));
    await axios
      .post('/user/merchant/resend-verification-otp', {
        username: props.route.params.username,
        business: props.route.params.business,
      })
      .then((response) => {
        onOpenAlert(response?.data?.message);
        dispatch(CommonActions.setLoading(false));
        setMinutes(2);
        setSeconds(0);
      })
      .catch((error) => {
        onOpenAlert(error?.response?.message);
        dispatch(CommonActions.setLoading(false));
      });
  };

  const onSubmitData = async () => {
    if (userOTP == '' || userOTP.length != 6) {
      onOpenAlert('OTP is required!');
    } else if (newPassword == '') {
      onOpenAlert('New password is required!');
    } else if (newPassword.length < 8) {
      onOpenAlert('Password must be 8 characters!');
    } else if (confirmPassword == '') {
      onOpenAlert('Confirm password is required!');
    } else if (confirmPassword != newPassword) {
      onOpenAlert('New password and Confirm password not matched!');
    } else if (userOTP && userOTP.length == 6) {
      dispatch(CommonActions.setLoading(true));

      await axios
        .post('/user/merchant/reset-password', {
          new_password: newPassword,
          otp: userOTP,
          confirm_password: confirmPassword,
          username: props?.route?.params?.username,
          business: props.route.params.business,
        })
        .then((response) => {
          onOpenAlert(response?.data?.message);
          navigation.navigate('login');
          dispatch(CommonActions.setLoading(false));
        })
        .catch((error) => {
          console.log('error->', error?.response);
          onOpenAlert(error?.response?.message);
          dispatch(CommonActions.setLoading(false));
        });
    } else {
      onOpenAlert('Invalid OTP Please try again.');
    }
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
      // console.log(minutes, seconds)
    }, 1500);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <View style={styles.container}>
      <MainHeader
        title={'Reset Your Password'}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer showLogo>
        <View style={styles.informationContainer}>
          <View style={styles.otpTextInputContainer}>
            <View style={{flexDirection: 'row'}}>
              <TextInput
                selectTextOnFocus
                editable={!loading}
                keyboardType={'numeric'}
                maxLength={1}
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                // autoCorrect={false}
                style={[styles.textInput, {textAlign: 'center'}]}
                returnKeyType="done"
                onChangeText={(text) => {
                  onChangePassword(text, 0, undefined, otp2);
                }}
                ref={otp1}
              />
              <TextInput
                selectTextOnFocus
                editable={!loading}
                keyboardType={'numeric'}
                maxLength={1}
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                // autoCorrect={false}
                style={[styles.textInput, {textAlign: 'center'}]}
                returnKeyType="done"
                onChangeText={(text) => {
                  onChangePassword(text, 1, otp1, otp3);
                }}
                ref={otp2}
              />
              <TextInput
                selectTextOnFocus
                editable={!loading}
                keyboardType={'numeric'}
                maxLength={1}
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                // autoCorrect={false}
                style={[styles.textInput, {textAlign: 'center'}]}
                returnKeyType="done"
                onChangeText={(text) => {
                  onChangePassword(text, 2, otp2, otp4);
                }}
                ref={otp3}
              />
              <TextInput
                selectTextOnFocus
                keyboardType={'numeric'}
                editable={!loading}
                maxLength={1}
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                // autoCorrect={false}
                style={[styles.textInput, {textAlign: 'center'}]}
                returnKeyType="done"
                onChangeText={(text) => {
                  onChangePassword(text, 3, otp3, otp5);
                }}
                ref={otp4}
              />
              <TextInput
                selectTextOnFocus
                editable={!loading}
                keyboardType={'numeric'}
                maxLength={1}
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                // autoCorrect={false}
                style={[styles.textInput, {textAlign: 'center'}]}
                returnKeyType="done"
                onChangeText={(text) => {
                  onChangePassword(text, 4, otp4, otp6);
                }}
                ref={otp5}
              />
              <TextInput
                selectTextOnFocus
                editable={!loading}
                keyboardType={'numeric'}
                maxLength={1}
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                // autoCorrect={false}
                style={[styles.textInput, {textAlign: 'center'}]}
                returnKeyType="done"
                onChangeText={(text) => {
                  onChangePassword(text, 5, otp5);
                }}
                ref={otp6}
              />
            </View>
            <View style={styles.textInputContainer}>
              <TextInput
                editable={!loading}
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={'New password'}
                placeholderTextColor={Colors.color30}
                secureTextEntry={isSecureNewPassord}
                style={[styles.textInput]}
                returnKeyType="done"
                value={newPassword}
                onChangeText={(text) => setNewPassord(text)}
              />
              <TouchableOpacity
                onPress={() => setIsSecureNewPassord(!isSecureNewPassord)}
                style={styles.inputRightSide}
              >
                <Icon
                  name={isSecureNewPassord ? 'revealPwd' : 'revealPwd-no'}
                  size={22}
                  color={Colors.color30}
                />
              </TouchableOpacity>
            </View>
            <View style={styles.textInputContainer}>
              <TextInput
                editable={!loading}
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={'Confirm Password'}
                placeholderTextColor={Colors.color30}
                secureTextEntry={isSecureConfirmPassord}
                style={[styles.textInput]}
                returnKeyType="done"
                value={confirmPassword}
                onChangeText={(text) => setConfirmPassword(text)}
              />
              <TouchableOpacity
                onPress={() =>
                  setIsSecureConfirmPassord(!isSecureConfirmPassord)
                }
                style={styles.inputRightSide}
              >
                <Icon
                  name={isSecureConfirmPassord ? 'revealPwd' : 'revealPwd-no'}
                  size={22}
                  color={Colors.color30}
                />
              </TouchableOpacity>
            </View>
          </View>

          <LongButton
            style={{marginTop: setYAxisValue(15)}}
            disabled={loading}
            text={
              loading ? (
                <ActivityIndicator size={20} color={'#ffffff'} />
              ) : (
                'Reset Password'
              )
            }
            onPress={onSubmitData}
          />
          <View style={styles.dividerContainer}>
            <View style={styles.divider} />
            <AppText style={styles.orText}>{strings('loginScreen.or')}</AppText>
            <View style={styles.divider} />
          </View>
          <LongButton
            disabled={minutes == 0 && seconds == 0 ? false : true}
            text={
              minutes == 0 && seconds == 0
                ? `Resend OTP`
                : `${minutes}: ${seconds < 10 ? `0${seconds}` : seconds}`
            }
            onPress={onResendOtp}
          />
        </View>
      </MainContainer>
    </View>
  );
};

export default ResetPasswordScreen;
