import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {View, Text, TextInput, TouchableOpacity, Platform} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {
  AppText,
  LongButton,
  MainContainer,
  MainHeader,
  AppConfigCheckbox,
} from '../../components';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import {goBack, setValue} from '../../utils';
import styles from './styles';
import CommonAction from '../../redux/reduxsauce/commonRedux';
import {getAppConfig} from '../../redux/reduxsauceActions/configActions';

const RedeemCoinConfiguaration = (props) => {
  const config = useSelector((state) => state.config);
  const dispatch = useDispatch();

  const [redeemCoinData, setRedeemCoinData] = useState({
    min_coin: null,
    redeem_code: false,
  });

  useEffect(() => {
    const setting = config;
    setRedeemCoinData({
      ...redeemCoinData,
      min_coin: JSON.stringify(setting?.redeem_coin?.min_coin),
      redeem_code: setting?.redeem_coin?.redeem_code || false,
    });
  }, []);

  const saveRedeemCoinConfigData = async () => {
    dispatch(CommonAction.setLoading(true));

    try {
      const response = await axios.patch(
        `/business/update/business-setting/${config?.businessId}`,
        {
          redeem_coin: {
            min_coin: Number(redeemCoinData?.min_coin),
            redeem_code: redeemCoinData?.redeem_code,
          },
        },
      );
      console.log('response  redeem coin configuration ', response);
      dispatch(getAppConfig());
      dispatch(CommonAction.setLoading(false));
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: strings('subscriptionSetting.alert.success'),
        }),
      );
      goBack(props.navigation);
    } catch (error) {
      dispatch(CommonAction.setLoading(false));
      console.log('error', error);
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: error?.response?.message || 'Something went wrong :(',
        }),
      );
    }
  };
  return (
    <View style={styles.container}>
      <MainHeader
        title={'Redeem Coin Configuration'}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer>
        <View style={styles.textInputContainer}>
          <AppText style={styles.inputLabel} required>
            {'Minimum Coin'}
          </AppText>
          <TextInput
            underlineColorAndroid="transparent"
            autoCorrect={false}
            keyboardType="numeric"
            placeholder={'Enter donation reward coin multiplier'}
            placeholderTextColor={Colors.color30}
            returnKeyType="done"
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={redeemCoinData?.min_coin}
            onChangeText={(text) =>
              setRedeemCoinData({
                ...redeemCoinData,
                min_coin: text,
              })
            }
          />
        </View>

        <AppConfigCheckbox
          title={'Redeem code is active'}
          desc={'Please check if referral is active'}
          checked={redeemCoinData?.redeem_code}
          onChangeCheck={(checked) => {
            setRedeemCoinData({
              ...redeemCoinData,
              redeem_code: checked,
            });
          }}
        />

        {Platform.OS == 'web' && (
          <LongButton
            text={'Save Configuration'}
            style={{marginTop: setValue(10), marginBottom: setValue(45)}}
            onPress={saveRedeemCoinConfigData}
          />
        )}
      </MainContainer>
      {Platform.OS !== 'web' && (
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            style={styles.buttonButton}
            onPress={saveRedeemCoinConfigData}
          >
            <AppText style={styles.buttonText}>{'Save Configuration'}</AppText>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

export default RedeemCoinConfiguaration;
