import React, {useState} from 'react';
import {FlatList, Image, Platform, TouchableOpacity, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {
  MainHeader,
  MainContainer,
  Icon,
  HomeSettingCard,
  AppText,
  SwipeRow,
  HomeBannerCard,
} from '../../components';
import {strings} from '../../i18n';
import {goBack} from '../../utils';
import styles from './styles';
import Swiper from '../../lib/react-native-swiper';
import {Colors} from '../../constants';
import TopProductItem from '../../components/TopProductItem';
import {useEffect} from 'react';
import CommonAction from '../../redux/reduxsauce/commonRedux';
import axios from 'axios';
import {removeBannerCard} from '../../redux/reduxsauceActions/configActions';

const HomePageConfig = (props) => {
  const homePageConfig = useSelector((state) => state?.config?.appHomePage);
  const config = useSelector((state) => state?.config);
  const auth = useSelector((state) => state?.auth);
  const dispatch = useDispatch();

  const [topProductsList, setTopProductsList] = useState([]);

  const homeSlider =
    homePageConfig?.slider?.length > 0
      ? [...homePageConfig?.slider, {static: true}]
      : [{static: true}];

  const bannerCardList =
    homePageConfig?.bannerCard?.length > 0
      ? [...homePageConfig?.bannerCard, {static: true}]
      : [{static: true}];

  const getTopProducts = async () => {
    if (homePageConfig?.topProducts?.length > 0) {
      dispatch(CommonAction.setLoading(true));
      try {
        const response = await axios.get(
          `/v2/product/list/${
            config?.businessId
          }?ids=${homePageConfig?.topProducts?.map((o) => o)}`,
        );
        setTopProductsList([...response.data]);
        dispatch(CommonAction.setLoading(false));
      } catch (error) {
        console.log('top product error', error?.response);
        dispatch(CommonAction.setLoading(false));
      }
    }
  };

  useEffect(() => {
    getTopProducts();
  }, []);

  useEffect(() => {
    getTopProducts();
  }, [config?.appHomePage?.topProducts]);

  return (
    <View style={styles.container}>
      <MainHeader
        title={'Home Page Configuration'}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />

      <MainContainer>
        {/* slider */}
        <View style={{marginBottom: 20}}>
          <AppText
            style={[
              styles.title,
              {color: Platform.OS === 'web' ? Colors.black60 : Colors.white},
            ]}
          >
            {'Sliders'}
          </AppText>
          {homeSlider && (
            <View
              style={
                Platform.OS === 'web'
                  ? styles.topSliderContainerWeb
                  : styles.topSliderContainer
              }
            >
              <Swiper
                autoplay={true}
                activeDotColor={Colors.theme_color}
                autoplayTimeout={5}
              >
                {homeSlider?.map((o, index) => {
                  if (o?.static) {
                    return (
                      <TouchableOpacity
                        style={styles.topSliderStaticItem}
                        key={index}
                        onPress={() => props.navigation.navigate('home-slider')}
                      >
                        <Icon name="add" color={Colors.black60} size={20} />
                      </TouchableOpacity>
                    );
                  }
                  return (
                    <TouchableOpacity style={styles.topSliderItem} key={index}>
                      <Image
                        source={
                          o.image
                            ? {uri: o.image}
                            : require('../../../assets/images/General/no-img-found.jpg')
                        }
                        style={
                          Platform.OS === 'web'
                            ? styles.topSliderImageWeb
                            : styles.topSliderImage
                        }
                      />
                    </TouchableOpacity>
                  );
                })}
              </Swiper>
            </View>
          )}
        </View>

        {/* top products */}
        <View style={{marginBottom: 20}}>
          {topProductsList.length > 0 && (
            <>
              <AppText style={styles.title}>{'Top products'}</AppText>
              <FlatList
                data={topProductsList}
                style={Platform.OS == 'web' && {paddingHorizontal: 2}}
                horizontal={true}
                keyExtractor={(item, index) => index}
                showsHorizontalScrollIndicator={false}
                renderItem={({item}) => (
                  <View style={styles.productContainer}>
                    {
                      <TopProductItem
                        item={item}
                        navigation={props.navigation}
                        useTouch={true}
                        isAddToCart={false}
                        onAddToCart={() =>
                          item?.variants && item.variants?.length > 0
                            ? setState({item: item, isVisible: true})
                            : props.addToCart(item)
                        }
                        onPress={() => {
                          props.navigation.navigate('productDetail', {
                            slug: item.name
                              .toLowerCase()
                              .replace(/ /g, '-')
                              .replace(/[^\w-]+/g, ''),
                            id: item.id,
                          });
                        }}
                      />
                    }
                  </View>
                )}
                ListFooterComponent={() => {
                  return (
                    <TouchableOpacity
                      style={
                        Platform.OS === 'web'
                          ? styles.addTopProductItemButtonWeb
                          : styles.addTopProductItemButton
                      }
                      onPress={() =>
                        props.navigation.navigate('selected-top-products')
                      }
                    >
                      {Platform.OS === 'web' ? (
                        <AppText style={styles.addTopProductItemButtonTextWeb}>
                          <Icon name="add" color={Colors.black60} size={20} />
                        </AppText>
                      ) : (
                        <Icon name="add" color={Colors.black60} size={20} />
                      )}
                    </TouchableOpacity>
                  );
                }}
              />
            </>
          )}
          {topProductsList.length == 0 && (
            <HomeSettingCard
              title={config?.type == 'retail' ? 'Top Products' : 'Top Items'}
              description={
                config?.type == 'retail'
                  ? 'Manage top products'
                  : 'Manage top items'
              }
              onPress={() => {
                props.navigation.navigate('selected-top-products');
              }}
            />
          )}
        </View>

        {/* banner cards */}
        <View style={{marginBottom: Platform.OS == 'web' ? 50 : 20}}>
          {bannerCardList.length > 0 && (
            <>
              <AppText style={styles.title}>{'Banner Cards'}</AppText>
              {bannerCardList?.map((item, index) => {
                if (item?.static) {
                  return (
                    <TouchableOpacity
                      style={
                        Platform.OS == 'web'
                          ? styles.addBannerCardBtnWeb
                          : styles.addBannerCardBtn
                      }
                      key={index}
                      onPress={() => props.navigation.navigate('banner-card')}
                    >
                      <Icon name="add" color={Colors.black60} size={20} />
                    </TouchableOpacity>
                  );
                }
                return (
                  <SwipeRow
                    key={index}
                    leftOpenValue={60}
                    rightOpenValue={-100}
                    disableSwipe={
                      !['merchant', 'manager'].includes(auth.user?.type)
                    }
                    editable={true}
                    onPressEdit={() => {
                      navigation.navigate('banner-card', {
                        index: index,
                        item: item,
                        edit: true,
                      });
                    }}
                    onPressDelete={() => {
                      dispatch(
                        CommonAction.setAlert({
                          visible: true,
                          content:
                            'Are you sure you want to delete this banner ?',
                          rightButton: 'cancel',
                          leftButton: 'confirm',
                          rightPress: null,
                          leftPress: () => dispatch(removeBannerCard(index)),
                        }),
                      );
                    }}
                    item={item}
                  >
                    <HomeBannerCard item={item} />
                  </SwipeRow>
                );
              })}
            </>
          )}
        </View>
      </MainContainer>
    </View>
  );
};

export default HomePageConfig;
