import {MaterialCommunityIcons} from '@expo/vector-icons';
import React, {Component} from 'react';
import {TouchableOpacity} from 'react-native';

import {Colors} from '../../constants';

class CheckBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChange = () => {
    this.props.onChange && this.props.onChange(!this.props.checked);
  };

  render() {
    return (
      <TouchableOpacity onPress={this.onChange} disabled={this.props.disabled}>
        {!this.props.checked ? (
          // <MaterialCommunityIcons name={this.props.isMultipleChoice ? 'checkbox-blank-outline' : 'checkbox-blank-circle-outline'} size={this.props.size ? this.props.size :  24} color="black" />
          <MaterialCommunityIcons
            name={'checkbox-blank-outline'}
            size={this.props.size ? this.props.size : 24}
            color="black"
          />
        ) : (
          // <MaterialCommunityIcons name={this.props.isMultipleChoice ? 'checkbox-marked' : 'checkbox-marked-circle'} size={this.props.size ? this.props.size :  24} color={Colors.theme_color} />
          <MaterialCommunityIcons
            name={'checkbox-marked'}
            size={this.props.size ? this.props.size : 24}
            color={Colors.theme_color}
          />
        )}
      </TouchableOpacity>
    );
  }
}

export default CheckBox;
