import {StyleSheet} from 'react-native';

import {Colors} from '../../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../../utils';

const wrapper = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
  borderRadius: 8,
  padding: setValue(10),
  backgroundColor: Colors.white,
  marginBottom: setYAxisValue(15),
};

export default StyleSheet.create({
  crossCtn: {
    position: 'absolute',
    top: -setXAxisValue(10),
    right: -setXAxisValue(10),
  },
  crossIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    height: setXAxisValue(30),
    width: setXAxisValue(30),
    borderRadius: setXAxisValue(30),
    backgroundColor: 'white',
    borderColor: Colors.theme_color,
    borderWidth: setXAxisValue(0.8),
  },
  container: {
    flex: 1,
  },
  mainContainer: {
    flex: 1,
  },
  promotionImage: {
    width: '100%',
    height: setYAxisValue(180),
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  itemContainer: {
    ...wrapper,
    padding: 0,
  },
  title: {
    fontSize: setXAxisValue(18),
    marginVertical: setYAxisValue(5),
  },
  desc: {
    fontSize: setXAxisValue(13),
  },
  infoBar: {
    marginTop: setYAxisValue(18),
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  moneyView: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  validText: {
    fontSize: setXAxisValue(14),
    marginBottom: setYAxisValue(5),
  },
  itemLeft: {
    flexDirection: 'row',
  },
  numberLeft: {
    fontSize: setXAxisValue(14),
    //
    marginRight: setXAxisValue(5),
  },
  leftText: {
    fontSize: setXAxisValue(14),
  },
  discountView: {
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },
  rmText: {
    fontSize: setXAxisValue(18),
    // fontFamily: ''
  },
  discountText: {
    fontSize: setXAxisValue(10),
    textDecorationLine: 'line-through',
  },
  currentPriceText: {
    fontSize: setXAxisValue(18),
    //
    marginLeft: setXAxisValue(5),
  },
  quantityView: {
    ...wrapper,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  quantityActionView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  quantityButton: {
    marginHorizontal: setXAxisValue(22),
  },
  quantityNumberText: {
    fontSize: setXAxisValue(20),
    color: Colors.black60,
    // fontFamily: ''
  },
  periodView: {
    ...wrapper,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  validAtItem: {
    backgroundColor: Colors.black60,
    paddingHorizontal: setXAxisValue(10),
    paddingVertical: setYAxisValue(5),
    borderRadius: 20,
    marginRight: setXAxisValue(5),
    marginBottom: setYAxisValue(5),
  },
  validAtItemText: {
    fontSize: setXAxisValue(14),
    color: Colors.white,
  },
  validAtItemMore: {
    backgroundColor: Colors.theme_color,
    paddingHorizontal: setXAxisValue(10),
    paddingVertical: setYAxisValue(5),
    borderRadius: 20,
    marginRight: setXAxisValue(5),
    marginBottom: setYAxisValue(5),
  },
  validAtItemTextMore: {
    fontSize: setXAxisValue(14),
    color: Colors.white,
  },
  outletList: {
    marginTop: setYAxisValue(15),
  },
  flexWrap: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: setYAxisValue(10),
  },
  termView: {
    flexDirection: 'row',
    marginBottom: setYAxisValue(5),
  },
  termText: {
    fontSize: setXAxisValue(16),
    width: '50%',
  },
  titleTerm: {
    //
    fontSize: setXAxisValue(16),
    marginBottom: setYAxisValue(15),
  },
  termAndConText: {
    fontSize: setXAxisValue(16),
    marginBottom: setYAxisValue(10),
    marginLeft: setXAxisValue(10),
  },
  buyNowContainer: {
    backgroundColor: Colors.white,
    paddingVertical: setYAxisValue(15),
  },
  buyNowButton: {
    width: '80%',
    backgroundColor: Colors.theme_color,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 5,
    paddingVertical: setYAxisValue(15),
  },
  buyNowText: {
    fontSize: setXAxisValue(14),
    //
    color: Colors.primary_color,
  },
  indicatorContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  overlay: {
    flex: 1,
    backgroundColor: Colors.overlay,
    alignItems: 'center',
    justifyContent: 'center',
  },
  informationContainer: {
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
    backgroundColor: Colors.white,
    justifyContent: 'center',
    alignSelf: 'center',
    borderRadius: 8,
    width: '85%',
    marginBottom: setYAxisValue(40),
    padding: setValue(2),
  },
  information: {
    backgroundColor: Colors.theme_color,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: setYAxisValue(10),
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  informationText: {
    fontSize: setXAxisValue(16),
    color: Colors.white,
    // fontFamily: ''
  },
  paymentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: setXAxisValue(10),
    paddingVertical: setYAxisValue(5),
  },
  paymentText: {
    fontSize: setXAxisValue(14),
    textAlign: 'center',
    color: Colors.black40,
    lineHeight: setYAxisValue(20),
    paddingHorizontal: setXAxisValue(20),
  },
  paymentImage: {
    height: setXAxisValue(40),
    width: setXAxisValue(40),
  },
  flatList: {
    marginVertical: setXAxisValue(10),
  },
  webViewPaymentContainer: {
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
    backgroundColor: Colors.white,
    borderRadius: 5,
    width: '90%',
    height: '90%',
    padding: setValue(2),
  },
  couponCodeContainer: {
    marginVertical: setYAxisValue(10),
    flexDirection: 'row',
    alignItems: 'center',
  },
  couponCodeText: {
    paddingVertical: setYAxisValue(10),
    width: '75%',
    textAlign: 'center',
    fontFamily: 'Roboto-Bold',
    letterSpacing: 1.5,
    borderWidth: 2,
    borderColor: '#ccc',
    borderStyle: 'dotted',
    borderTopLeftRadius: setValue(5),
    borderBottomLeftRadius: setValue(5),
  },
  copyText: {
    paddingVertical: setYAxisValue(10),
    width: '25%',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Roboto-Bold',
    backgroundColor: Colors.theme_color,
    borderWidth: 2,
    borderColor: Colors.theme_color,
    borderBottomRightRadius: setValue(5),
    borderTopRightRadius: setValue(5),
    color:Colors.white
  },
  promotionOffer: {
    position: 'absolute',
    right: setXAxisValue(15),
    backgroundColor: Colors.wallet_card_red,
    padding: setValue(5),
    paddingHorizontal: setValue(13),
    borderRadius: setValue(50),
    top: setYAxisValue(170),
  },
  offerText: {
    color: Colors.white,
    fontFamily: 'Roboto-Bold',
  },
});
