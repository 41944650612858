import React, {useState} from 'react';
import {
  Image,
  Keyboard,
  Linking,
  Platform,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {
  AppText,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../../../components';
import {Colors} from '../../../../constants';
import {strings} from '../../../../i18n';
import {goBack, setValue} from '../../../../utils';
import styles from './styles';
import * as ImagePicker from 'expo-image-picker';
import {connectActionSheet} from '@expo/react-native-action-sheet';
import axios from 'axios';
import CommonActions from '../../../../redux/reduxsauce/commonRedux';
import {useDispatch, useSelector} from 'react-redux';

const AddNewExperience = (props) => {
  const item = props?.route?.params?.item;
  const dispatch = useDispatch();
  const config = useSelector((state) => state?.config);

  const [experienceDetail, setExperienceDetail] = useState({
    name: item?.name ? item?.name : '',
    description: item?.description ? item?.description : '',
    image: item?.image ? {uri: item?.image} : null,
    business: item?.business ? item?.business : config?.businessId,
  });

  const openActionSheet = () => {
    const options = [
      strings('actionSheet.takePhoto'),
      strings('actionSheet.choosePhoto'),
      strings('actionSheet.removePhoto'),
      strings('actionSheet.cancel'),
    ];
    const cancelButtonIndex = 3;

    props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) openCamera();
        if (buttonIndex === 1) openImagePicker();
        if (buttonIndex === 2) removePhoto();
      },
    );
  };

  const removePhoto = () => {
    // props.removeProfilePicture();
    setExperienceDetail({
      ...experienceDetail,
      image: null,
    });
  };

  const openCamera = async () => {
    await ImagePicker.requestCameraPermissionsAsync();

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: [30, 17],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      setExperienceDetail({
        ...experienceDetail,
        image: result,
      });
    }

    if (!result.cancelled) {
      // changeProfilePicture(result);
    }
    console.log('result====', result);
  };

  const openImagePicker = async () => {
    await ImagePicker.requestMediaLibraryPermissionsAsync();

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: [30, 17],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      setExperienceDetail({
        ...experienceDetail,
        image: result,
      });
    }

    if (!result.cancelled) {
    }
  };

  const dataURLtoFile = (dataurl) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], 'image1.jpg', {type: mime});
  };

  const onSave = async () => {
    Keyboard.dismiss();
    dispatch(CommonActions.setLoading(true));
    if (experienceDetail.name.trim() == '') {
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('editExperience.alert.nameAlert'),
        }),
      );
      return;
    }
    let body = new FormData();
    body.append('name', experienceDetail?.name.trim());
    experienceDetail?.description.trim().length > 0 &&
      body.append('description', experienceDetail?.description.trim());
    experienceDetail?.business != '' &&
      body.append('business', experienceDetail?.business);

    if (
      experienceDetail?.image?.uri &&
      experienceDetail?.image?.uri?.slice(0, 4) != 'http'
    ) {
      if (Platform.OS == 'web') {
        body.append('image', dataURLtoFile(experienceDetail?.image?.uri));
      } else {
        let blob = await (await fetch(experienceDetail?.image?.uri)).blob();
        body.append('image', {
          uri: experienceDetail?.image?.uri,
          type: blob.type,
          name: blob.data.name,
        });
      }
    } else if (!experienceDetail?.image?.uri) {
      body.append('image', '');
    }

    const header = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    console.log('body', body);
    if (item?.id) {
      await axios
        .patch('/hospitality/Experiences/update/' + item?.id, body, header)
        .then((response) => {
          console.log('response update blog', response);
          dispatch(CommonActions.setLoading(false));
          props.navigation.replace('experience-list');
        })
        .catch((error) => {
          console.log('error update blog --', error?.response);
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: error?.response?.message,
            }),
          );
          dispatch(CommonActions.setLoading(false));
        });
    } else {
      await axios
        .post('/hospitality/Experiences/create', body, header)
        .then((response) => {
          console.log('response of create Experiences', response);
          dispatch(CommonActions.setLoading(false));
          props.navigation.replace('experience-list');
        })
        .catch((error) => {
          console.log('error create blog', error.response);
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: error?.response?.message,
            }),
          );
          dispatch(CommonActions.setLoading(false));
        });
    }
  };

  return (
    <View style={styles.container}>
      <MainHeader
        title={
          item?.id ? 'Edit experience detail' : strings('editExperience.title')
        }
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer>
        <KeyboardAwareScrollView>
          <>
            <View style={styles.profileStrengthContainer}>
              <TouchableOpacity
                style={styles.imageContainer}
                onPress={openActionSheet}
              >
                {!experienceDetail?.image ? (
                  <Icon name="add" size={16} color={Colors.black60} />
                ) : (
                  <View>
                    <Image
                      source={{uri: experienceDetail?.image?.uri}}
                      style={styles.image}
                    />
                    <TouchableOpacity
                      style={styles.cameraIcon}
                      onPress={openActionSheet}
                    >
                      <Icon name="camera" color={Colors.black20} />
                    </TouchableOpacity>
                  </View>
                )}
              </TouchableOpacity>
            </View>

            <View style={styles.textInputContainer}>
              <AppText style={styles.title} required>
                {strings('editExperience.name.name')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={strings('editExperience.placehoder.name')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={styles.textInput}
                value={experienceDetail?.name}
                onChangeText={(text) =>
                  setExperienceDetail({
                    ...experienceDetail,
                    name: text,
                  })
                }
              />
            </View>

            <View style={styles.textInputContainer}>
              <AppText style={styles.title}>
                {strings('addNewBlog.title.description')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={strings('editExperience.placehoder.description')}
                placeholderTextColor={Colors.color30}
                numberOfLines={3}
                multiline={true}
                returnKeyType="done"
                style={styles.multiLineTextInput}
                value={experienceDetail?.description}
                onChangeText={(text) =>
                  setExperienceDetail({
                    ...experienceDetail,
                    description: text,
                  })
                }
              />
            </View>
          </>

          {Platform.OS == 'web' && (
            <LongButton
              onPress={() => onSave()}
              loading={false}
              text={
                item?.id
                  ? strings('addNewGallery.edit')
                  : strings('editProductCategory.button.save')
              }
              style={{
                marginTop: setValue(20),
                marginBottom: setValue(45),
              }}
            />
          )}
        </KeyboardAwareScrollView>
      </MainContainer>

      {Platform.OS !== 'web' && (
        <LongButton
          onPress={() => onSave()}
          loading={false}
          text={
            item?.id
              ? strings('addNewGallery.edit')
              : strings('editProductCategory.button.save')
          }
          style={{borderRadius: 0, width: '100%'}}
        />
      )}
    </View>
  );
};
const ConnectedAddNewExperience = connectActionSheet(AddNewExperience);

export default ConnectedAddNewExperience;
