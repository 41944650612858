import {Dimensions, Platform} from 'react-native';

const {width, height} = Dimensions.get('window');

export const iphoneMaxWidth = Platform.OS === 'web' ? 414 : width;

export const iphoneMaxHeight = Platform.OS === 'web' ? 896 : height;

export default {
  window: {
    width: Platform.select({default: width}),
    height: Platform.select({default: height}),
  },
};
