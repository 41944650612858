import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  buttonLeft: {
    flex: 1,
    marginRight: setXAxisValue(2),
    marginVertical: setYAxisValue(15),
    backgroundColor: 'rgb(180, 180, 180)',
    marginLeft: setXAxisValue(15),
  },
  buttonRight: {
    flex: 1,
    marginLeft: setXAxisValue(2),
    marginVertical: setYAxisValue(15),
    marginRight: setXAxisValue(15),
  },
  mainContent: {
    backgroundColor: 'white',
    borderRadius: setValue(15),
    flex: 1,
    marginVertical: setYAxisValue(20),
    padding: setValue(20),
  },
  textTitle: {
    color: 'gray',
    fontSize: setXAxisValue(14),
    marginVertical: setYAxisValue(10),
    marginEnd: setXAxisValue(10),
  },
  textInput: {
    height: setValue(50),
    padding: 0,
    flex: 1,
    paddingHorizontal: setXAxisValue(10),
  },
  textInputContainer: {
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
  },
  iconDropdown: {
    top: setYAxisValue(25),
  },
  pickerStyle: {
    marginTop: setValue(70),
    width: '80%',
    borderRadius: 5,
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
  },
  title: {
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(14),
    //
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  textContent: {
    fontWeight: 'bold',
    marginBottom: setYAxisValue(10),
  },
  specialInput: {
    backgroundColor: '#e6e6e6',
    borderRadius: setValue(10),
    padding: setXAxisValue(10),
    alignItems: 'flex-start',
    textAlignVertical: 'top',
  },
  customerInfo: {
    fontSize: setXAxisValue(16),
    fontWeight: 'bold',
  },
  mobileNoInput: {
    marginTop: setYAxisValue(10),
    backgroundColor: '#e6e6e6',
    borderRadius: setValue(10),
    padding: setXAxisValue(10),
  },
});
