import get from 'lodash.get';
import React, {Component} from 'react';
import {
  ActivityIndicator,
  Image,
  Keyboard,
  Platform,
  SafeAreaView,
  ScrollView,
  Text,
  Share,
  TextInput,
  ToastAndroid,
  TouchableOpacity,
  View,
  FlatList,
} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  AppText,
  CheckBox,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
  ProductItemGird,
  ProgressiveImage,
  SelectProductVeriantsModal,
  ZoomImage,
} from '../../components';
import {strings} from '../../i18n';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {cashFormat, getParam, goBack, setValue} from '../../utils';
import styles from './styles';
import lodash, {result, values} from 'lodash';
import {getProducts} from '../../redux/reduxsauceActions/productActions';
import axios from 'axios';
import {Colors} from '../../constants';
import Swiper from '../../components/Swiper';
import {Helmet} from 'react-helmet';
import TopProductItem from '../../components/TopProductItem';
import {Entypo, FontAwesome} from '@expo/vector-icons';
import ProductAction from '../../redux/reduxsauce/recentProductRedux';
class ProductDetailScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: {},
      isVisible: false,
      itemQuantity: 1,
      loading: false,
      addQtyLoading: false,
      addQty: '',
      selectedVariants: [],
      visibleImage: false,
      relatedProductList: [],
    };
    this.currencyCode = '&#8377;';
    this.currencySymbol = get(props.appConfig, 'currencySymbol', '₹');
  }

  componentDidMount = () => {
    this.unsubscribe = this.props.navigation.addListener('focus', () => {
      this.getProductDetail();
    });
    this.addIntoRecentProducts();
  };
  addIntoRecentProducts = () => {
    console.log('0000000----------');
    this.props.setRecentProduct(this.props.route?.params?.id);
  };

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  getProductDetail = async () => {
    this.props.setLoading(true);

    await axios
      .get('/products/details/' + this.props.route?.params?.id)
      .then((response) => {
        console.log('product ', response.data);
        // response.data?.category &&
        this.setState({item: response.data});
        this.props.setLoading(false);
        // !response.data?.category && this.props.setLoading(false);
      })
      .catch((error) => {
        console.log('error->', error.response);
        this.props.setLoading(false);
      });
  };

  onMinusItem = () => {
    if (this.state.itemQuantity > 1) {
      this.setState({itemQuantity: --this.state.itemQuantity});
    }
  };

  onAddItem = () => {
    if (Number(this.state.itemQuantity) === 999) {
      // this.props.openAlert(
      //   `${strings('ordering.myDiningCart.addMoreConfirmation1')} ${this.state.itemQuantity} ${strings('ordering.myDiningCart.addMoreConfirmation2')}`,
      //   strings('ordering.myDiningCart.yes'),
      //   () => this.setState({ itemQuantity: ++this.state.itemQuantity }),
      //   strings('ordering.myDiningCart.no'),
      //   null
      // );
      this.props.openAlert(
        `${strings('ordering.myDiningCart.maxQuantityAlert')}`,
      );
    } else {
      this.setState({itemQuantity: ++this.state.itemQuantity});
    }
  };

  selectUnselectVariants = (type, value) => {
    const {selectedVariants} = this.state;

    if (selectedVariants?.some((o) => o.value == value)) {
      let arr = selectedVariants?.filter((o) => o.value != value);
      this.setState({
        selectedVariants: arr,
      });
    } else if (selectedVariants?.some((o) => o.type == type)) {
      let arr = selectedVariants;
      let index = selectedVariants?.findIndex((o) => o.type == type);
      arr[index] = {
        ...arr[index],
        value: value,
      };
      this.setState({
        selectedVariants: arr,
      });
    } else {
      this.setState((prev) => ({
        selectedVariants: [
          ...prev.selectedVariants,
          {type: type, value: value},
        ],
      }));
    }
  };

  getCalculateTotalPrice = () => {
    const {variants} = this.state.item;
    const {selectedVariants} = this.state;
    let verientsPrice = 0;

    selectedVariants?.forEach((o) => {
      let variantIndex = variants?.findIndex((v) => v.type == o.type);
      let price = variants[variantIndex].variants?.find(
        (v) => o.value == v.value,
      ).price;
      verientsPrice += Number(price);
    });
    return Number(this.state.item.price) + Number(verientsPrice);
  };

  validateQuantity = (itemQuantity) => {
    this.setState({
      itemQuantity: itemQuantity.length === 0 ? 1 : itemQuantity,
    });
  };

  UpdateQuantity = () => {
    const {item, addQty, addQtyLoading} = this.state;
    let updatedQty = parseFloat(item.stock_qty) + parseFloat(addQty);
    this.setState({addQtyLoading: true});

    axios
      .put('/products/stock/update/' + item?.id, {
        stock_qty: updatedQty,
      })
      .then((response) => {
        console.log('response-->', response);
        this.setState({
          item: {
            ...item,
            stock_qty: response?.data?.stock_qty,
          },
          addQtyLoading: false,
        });
        this.props.getProducts();
        console.log('ts->', this.state?.item);
      })
      .catch((error) => {
        console.log('error', error.response);
        this.setState({addQtyLoading: false});
      });
  };

  renderSummary = () => {
    const {item} = this.state;
    const {appConfig} = this.props;

    let discountAmount =
      parseInt(this.state.item.price) +
      (parseInt(this.state.item.price) * parseInt(item.discount)) / 100;
    return (
      <View>
        <View
          style={
            appConfig?.product?.aspectRatio
              ? {
                  aspectRatio:
                    appConfig?.product?.aspectRatio[0] /
                    appConfig?.product?.aspectRatio[1],
                }
              : {
                  aspectRatio: 4 / 3,
                }
          }
        >
          {item.images?.length > 0 ? (
            <Swiper
              autoplay={true}
              activeDotColor={Colors.theme_color}
              autoplayTimeout={7}
            >
              {item.images?.map((o, index) => (
                <View>
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({
                        visibleImage: true,
                      });
                    }}
                    key={index}
                  >
                    {/* <Image
                    source={{uri: o.photo}}
                    style={styles.image}
                    key={index}
                  /> */}
                    <ProgressiveImage
                      thumbnailSource={{uri: o.photo}}
                      source={{uri: o.photo}}
                      style={
                        appConfig?.product?.aspectRatio
                          ? [
                              styles.image,

                              {
                                aspectRatio:
                                  appConfig?.product?.aspectRatio[0] /
                                  appConfig?.product?.aspectRatio[1],
                              },
                            ]
                          : [styles.image, {aspectRatio: 4 / 3}]
                      }
                      resizeMode="cover"
                      key={index}
                    />
                    {o.variants === null ? (
                          <View></View>
                    ) : (
                      <Text style={styles.imageVarinatsText}>
                        {o?.variants?.variant?.variant_type?.type} :{' '}
                        {o?.variants?.variant?.name}
                      </Text>
                    )}
                  </TouchableOpacity>
                </View>
              ))}
            </Swiper>
          ) : (
            <Image
              resizeMode="cover"
              source={require('../../../assets/images/General/no-img-found.jpg')}
              style={
                appConfig?.product?.aspectRatio
                  ? [
                      styles.image,

                      {
                        aspectRatio:
                          appConfig?.product?.aspectRatio[0] /
                          appConfig?.product?.aspectRatio[1],
                      },
                    ]
                  : [styles.image, {aspectRatio: 4 / 3}]
              }
            />
          )}
        </View>

        <View
          style={[
            styles.sectionContainer,
            {
              flexDirection: 'column',
            },
          ]}
        >
          <View>
            <AppText style={styles.summaryPriceText}>
              {`${this.currencySymbol} ${item.price} ${' '}`}
              {(!item.discount || item.discount == 0) && (
                <Text style={{fontSize: 14, color: Colors.black60}}>
                  (Inclusive of all taxes)
                </Text>
              )}
              {item.discount && item.discount != 0 && (
                <AppText style={styles.discountText}>
                  {`${this.currencySymbol} ${discountAmount}`}
                </AppText>
              )}
            </AppText>
            {item.discount && item.discount != 0 && (
              <AppText style={{fontSize: 16, color: Colors.black60}}>
                You save :
                <AppText style={{fontSize: 16, color: 'green'}}>
                  {' ' +
                    this.currencySymbol +
                    ' ' +
                    (
                      parseFloat(discountAmount) -
                      parseInt(this.state.item.price)
                    ).toFixed(1) +
                    '   '}
                </AppText>
                <AppText style={{fontSize: 14, color: Colors.black60}}>
                  (Inclusive of all taxes)
                </AppText>
              </AppText>
            )}
          </View>
        </View>
      </View>
    );
  };
  renderDescription = () => {
    const {item} = this.state;

    let specificationText = this.state.item?.description
      ? this.state.item.description
      : strings('ordering.productDetail.noDesc');
    return (
      <View style={styles.summaryDescContainer}>
        <AppText
          style={{
            color: '#5D5D5D',
            fontSize: 12,
            lineHeight: 17,
            paddingVertical: 2,
          }}
        >
          {specificationText}
        </AppText>
      </View>
    );
  };

  renderPartsNumber = () => {
    const {item} = this.state;

    return (
      <View
        style={[
          styles.summaryDescContainer,
          {
            flexDirection: 'row',
            justifyContent: 'space-between',
          },
        ]}
      >
        <AppText
          style={{
            color: Colors.black60,
            fontWeight: '700',
            fontSize: 15,
            lineHeight: 15,
            paddingVertical: 2,
            width: '40%',
          }}
        >
          {'Parts Number'}
        </AppText>
        <AppText
          style={{
            color: Colors.black40,
            fontWeight: '500',
            fontSize: 15,
            lineHeight: 15,
            paddingVertical: 2,
            width: '55%',
            textAlign: 'right',
          }}
        >
          {item?.parts_number}
        </AppText>
      </View>
    );
  };

  renderReview = () => {
    const {item} = this.state;
    let filled = Math.floor(item?.rating || 0);
    let reviewArray = [];

    if (filled == 0) {
      for (let i = 0; i < 5; i++) {
        reviewArray.push('star-o');
      }
    }
    if (filled == 5) {
      for (let i = 0; i < 5; i++) {
        reviewArray.push('star');
      }
    }

    if (filled > 0 && filled < 5) {
      if (item?.rating > filled) {
        for (let i = 0; i < filled; i++) {
          reviewArray.push('star');
        }
        reviewArray.push('star-half-empty');
      } else {
        for (let i = 0; i < filled; i++) {
          reviewArray.push('star');
        }
      }
      if (reviewArray.length < 5) {
        for (let i = reviewArray.length; i < 5; i++) {
          reviewArray.push('star-o');
        }
      }
    }

    return (
      <View style={styles.otherSectionContainer}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TouchableOpacity
            onPress={
              item?.total_review !== 0
                ? () =>
                    this.props.navigation.navigate('rating-review', {
                      slug: item.name
                        .toLowerCase()
                        .replace(/ /g, '-')
                        .replace(/[^\w-]+/g, ''),
                      id: item?.id,
                    })
                : null
            }
          >
            <AppText
              style={{color: Colors.black60, fontWeight: '700', fontSize: 17}}
            >
              {item?.total_review || 0} Ratings
            </AppText>
          </TouchableOpacity>
          <View style={{flexDirection: 'row'}}>
            {reviewArray.map((o, index) => (
              <FontAwesome
                name={o}
                key={index}
                size={24}
                color="#ffbe3b"
                style={{marginRight: 5}}
              />
            ))}
          </View>
        </View>
      </View>
    );
  };

  renderVariants = () => {
    const {item, selectedVariants} = this.state;
    let variants = item?.variant;
    return (
      <View style={styles.otherSectionContainer}>
        {variants &&
          variants?.map((o, index) => (
            <View
              style={{
                marginBottom:
                  variants?.length > 0 && variants?.length - 1 != index
                    ? 10
                    : 0,
              }}
              key={index}
            >
              <AppText
                style={[
                  styles.optionGroupTitle,
                  {marginTop: 0, marginBottom: 5},
                ]}
              >
                {o.variant_type.type}
              </AppText>
              <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                {o.variants &&
                  o.variants?.map((v, i) => {
                    return (
                      <View style={styles.variants} key={i}>
                        <AppText style={{fontSize: 16, color: Colors.black20}}>
                          {v.variant.name}
                        </AppText>
                        <AppText style={{fontSize: 12, color: Colors.black20}}>
                          {this.currencySymbol + ' ' + v.additional_price}
                        </AppText>
                      </View>
                    );
                  })}
              </View>
            </View>
          ))}
      </View>
    );
  };

  renderSpecifications = () => {
    const {item} = this.state;
    let details = item?.details;
    return (
      <View>
        <View style={styles.otherSectionContainer}>
          {/* <AppText
            style={[styles.optionGroupTitle, {marginTop: 0, marginBottom: 5}]}
          >
            {'Details'}
          </AppText> */}

          {details?.length &&
            details.map((o, index) => (
              <View
                style={[
                  styles.specificationContainer,
                  o.type == 'array' && {flexDirection: 'column'},
                ]}
                key={index}
              >
                <AppText style={styles.specificationKeys}>{o.label}</AppText>

                {(o.type == 'text' || o.type == 'longText') && (
                  <AppText style={styles.specificationValues}>
                    {o.value}
                  </AppText>
                )}

                {o.type == 'array' && (
                  <View
                    style={[styles.specificationValues, {padding: setValue(5)}]}
                  >
                    {o.value &&
                      o.value.map((item, index) => (
                        <View style={{flexDirection: 'row'}} key={index}>
                          <View style={styles.pointStyle}></View>
                          <AppText style={{marginLeft: setValue(10)}}>
                            {item}
                          </AppText>
                        </View>
                      ))}
                  </View>
                )}
              </View>
            ))}
        </View>
      </View>
    );
  };

  renderAddProductQuantity = () => {
    const {addQty, addQtyLoading, item} = this.state;
    return (
      <View style={[styles.sectionContainer, {flexDirection: 'column'}]}>
        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
          <AppText style={styles.optionGroupTitle}>{'Add Quantity'}</AppText>
          <AppText style={styles.optionGroupTitle}>
            {'Available Stock: ' + item?.stock_qty + ' ' + item?.unit}
          </AppText>
        </View>
        <View
          style={{
            flex: 1,
            marginTop: 10,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TextInput
            placeholder={'0'}
            style={styles.specialRequestText}
            onSubmitEditing={Keyboard.dismiss}
            keyboardType="number-pad"
            value={addQty}
            onChangeText={(text) => this.setState({addQty: text})}
          />
          <TouchableOpacity
            style={styles.addQuantityButton}
            onPress={() => this.UpdateQuantity()}
            disabled={addQtyLoading}
          >
            {addQtyLoading ? (
              <ActivityIndicator size="small" color={Colors.white} />
            ) : (
              <AppText style={{color: Colors.white, fontWeight: '700'}}>
                Add
              </AppText>
            )}
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  renderQuantity = () => {
    const {addItem} = this.props.route.params;
    const {item} = this.state;

    if (item.always_available_stock || item.stock_qty > 0) {
      return (
        <View style={[styles.sectionContainer, {marginBottom: 5}]}>
          <Text style={styles.quantityTitle}>{strings('common.quantity')}</Text>
          <View>
            <View style={styles.quantityContainer}>
              <TouchableOpacity
                style={styles.updateQuantityIconContainer}
                onPress={() => this.onMinusItem()}
              >
                <Icon name="orderMinus" color={'black'} />
              </TouchableOpacity>
              <TextInput
                style={styles.itemQuantityText}
                value={this.state.itemQuantity.toString()}
                maxLength={3}
                keyboardType="numeric"
                returnKeyType="done"
                onChangeText={(itemQuantity) => {
                  if (Number(itemQuantity) > 0 || !itemQuantity) {
                    this.setState({itemQuantity});
                  } else {
                    this.setState({itemQuantity: 1});
                  }
                }}
                onEndEditing={(event) => {
                  this.validateQuantity(event.nativeEvent.text);
                }}
              />
              <TouchableOpacity
                style={styles.updateQuantityIconContainer}
                onPress={() => this.onAddItem()}
              >
                <Icon name="orderPlus" color={'black'} />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      );
    } else {
      return (
        <View style={[styles.sectionContainer, {justifyContent: 'center'}]}>
          <AppText
            style={{color: Colors.wallet_card_red, fontFamily: 'Roboto-Bold'}}
          >
            OUT OF STOCK
          </AppText>
        </View>
      );
    }
  };

  getSupport = async () => {
    const {item} = this.state;
    this.setState({loading: true});
    try {
      const response = await axios.get(`/support/get/${item?.id}/product`);
      console.log('response--', response);
      this.setState({loading: false});
      this.props.navigation.navigate('live-support', {...response.data.data});
    } catch (error) {
      this.setState({loading: false});
      console.log('error', error);
    }
  };

  onShare = async () => {
    const {item} = this.state;
    const currencySymbol = this.props.appConfig?.currencySymbol || '₹';
    let commanUrl =
      this.props.appConfig?.domain &&
      this.props.appConfig?.domain != 'null' &&
      'https://' + this.props.appConfig?.domain?.replace('/', '') + '/';
    Share.share({
      title: item?.name,
      message:
        'Name : ' +
        item?.name +
        '\n\nDescription : ' +
        item?.description +
        '\n\nPrice : ' +
        currencySymbol +
        item?.price +
        '/- only' +
        '\n\n' +
        commanUrl +
        'product/' +
        item?.name?.toLowerCase().replace(' ', '-') +
        '/' +
        item?.id,
    });
  };

  render() {
    const {user, appConfig} = this.props;
    const {item, relatedProductList} = this.state;
    // console.log('config====>', appConfig?.product?.aspectRatio);
    return (
      <View
        style={
          Platform.OS == 'web'
            ? {overflow: 'visible', height: '100vh'}
            : styles.container
        }
      >
        <Helmet>
          <title>{item?.name}</title>
          <meta name="description" content={item?.description} />
        </Helmet>
        <MainHeader
          title={this.props.loading ? '' : `${item.name}`}
          leftIcon="back"
          rightIcon={
            ['merchant', 'manager'].includes(user?.type) &&
            !item?.deleted &&
            'edit'
          }
          rightPress={
            !item?.deleted
              ? () =>
                  this.props.navigation.replace('edit-product', {
                    item: item,
                  })
              : null
          }
          leftPress={() => goBack(this.props.navigation)}
          rightOtherIcon={
            this.props?.user?.id &&
            ['merchant', 'manager'].includes(user?.type) &&
            !item?.deleted &&
            'dustBinSmaller'
          }
          rightOtherPress={
            !item?.deleted
              ? () => {
                  this.props.openAlert(
                    strings('ordering.productDetail.askDelete'),
                    strings('common.yes'),
                    async () => {
                      this.props.setLoading(true);
                      await axios
                        .delete('/products/delete/' + item.id)
                        .then(async (response) => {
                          console.log(
                            'response Delete product ---->',
                            response.status,
                          );
                          await this.props.getProducts();
                          goBack(navigation);
                          this.props.setLoading(false);
                        })
                        .catch((error) => {
                          console.log(
                            'error Delete product-->',
                            error.response.data.detail,
                          );
                          this.props.setLoading(false);
                        });
                    },
                    strings('common.no'),
                    null,
                  );
                }
              : null
          }
        />
        {!this.props.loading && (
          <MainContainer>
            <KeyboardAwareScrollView
              style={{marginBottom: Platform.OS != 'web' ? setValue(100) : 0}}
            >
              <SelectProductVeriantsModal
                isVisible={this.state.isVisible}
                item={this.state.isVisible}
                onClose={() => this.setState({isVisible: false})}
              />
              <ZoomImage
                imageUrls={
                  item?.images?.length > 0 &&
                  item?.images?.map((o) => {
                    return (o = {url: o.photo});
                  })
                }
                visible={this.state.visibleImage}
                handleClose={() => {
                  this.setState({visibleImage: false});
                }}
              />

              {this.renderSummary()}
              {this.renderDescription()}
              {appConfig?.domain == 'partsportal.in' &&
                item?.parts_number?.length > 0 &&
                this.renderPartsNumber()}

              {this.props?.user?.id &&
                this.props?.appConfig?.product?.showReview &&
                this.renderReview()}
              {item?.variant?.length > 0 && this.renderVariants()}

              {item?.details?.length > 0 && this.renderSpecifications()}
              {['merchant', 'manager'].includes(user?.type) &&
                !item?.always_available_stock &&
                this.renderAddProductQuantity()}

              {this.renderQuantity()}
            </KeyboardAwareScrollView>
          </MainContainer>
        )}
        {Platform.OS != 'web' && !item?.deleted && (
          <TouchableOpacity
            onPress={() => this.onShare()}
            style={styles.shareButton}
          >
            <AppText style={styles.shareText}>
              <Icon name="share" color={Colors.white} size={22} />
            </AppText>
          </TouchableOpacity>
        )}
      </View>
    );
  }
}

export default connect(
  (state) => ({
    appConfig: state.config,
    user: state.auth.user,
    loading: state.common.loading,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getProducts,
        setRecentProduct: (data) => ProductAction.setRecentProduct(data),
        setLoading: CommonActions.setLoading,
        openAlert: (content, leftButton, leftPress, rightButton, rightPress) =>
          CommonActions.setAlert({
            visible: true,
            content,
            leftButton,
            leftPress,
            rightButton,
            rightPress,
          }),
      },
      dispatch,
    ),
)(ProductDetailScreen);
