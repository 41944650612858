import React, { Component } from 'react';
import { AppState } from 'react-native';

import AppText from '../AppText';

class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: props.seconds || 0,
      wentBackgroundAt: null
    };
  }

  componentDidMount() {
    AppState.addEventListener('change', this.onAppChange);
    this.props.autoStart && this.start();
  }

  componentWillUnmount() {
    AppState.removeEventListener('change', this.onAppChange);
    clearInterval(this.timer);
  }

  onAppChange = currentAppState => {
    const { seconds, wentBackgroundAt } = this.state;
    if (currentAppState === 'active' && wentBackgroundAt) {
      const secondsMiss = (Date.now() - wentBackgroundAt) / 1000;
      this.setState({ seconds: Math.max(0, seconds - secondsMiss) });
    }
    if (currentAppState === 'background') {
      this.setState({ wentBackgroundAt: Date.now() });
    }
  };

  start = () => {
    const { onEnd } = this.props;
    this.timer = setInterval(() => {
      this.setState(
        prevState => ({
          seconds: prevState.seconds - 1
        }),
        () => {
          if (this.state.seconds <= 0) {
            clearInterval(this.timer);
            setTimeout(() => onEnd && onEnd(), 1000);
          }
        }
      );
    }, 1000);
  };

  reset = () => {
    const { seconds } = this.props;
    this.setState({ seconds }, this.start);
  };

  render() {
    const { seconds } = this.state;
    const hour = `0${Math.floor(seconds / 3600)}`.slice(-2);
    const min = `0${Math.floor((seconds % 3600) / 60)}`.slice(-2);
    const second = `0${Math.floor((seconds % 3600) % 60)}`.slice(-2);
    return (
      <AppText {...this.props}>
        {hour === '00' ? '' : `${Number(hour) !== -1 ? hour : '00'}:`}
        {Number(min) !== -1 ? min : '00'}:{Number(second) !== -1 ? second : '00'}
      </AppText>
    );
  }
}

export default Timer;
