import {StyleSheet} from 'react-native';
import {Colors} from '../../../constants';
import {
  setYAxisValue,
  setValue,
  setXAxisValue,
  screenWidth,
} from '../../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 3,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  webContainer: {
    overflow: 'visible',
    height: '100vh',
  },
  searchBarContainer: {
    flexDirection: 'row',
    marginTop: setYAxisValue(20),
    height: setValue(50),
    backgroundColor: 'white',
    borderRadius: setValue(8),
    marginBottom: setYAxisValue(10),
    ...shadow,
  },
  searchBarText: {
    color: 'black',
    marginHorizontal: setXAxisValue(20),
    flex: 1,
  },
  searchBarIcon: {
    marginRight: setXAxisValue(10),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  itemContainer: {
    // flex: 1,
    width: '50%',
    paddingVertical: 3,
    alignSelf: 'center',
  },
  saveBtn: {
    backgroundColor: Colors.theme_color,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  saveBtnText: {
    textAlign: 'center',
    fontFamily: 'Roboto-Bold',
    color: Colors.white,
    padding: 15,
  },
  saveBtnWeb: {
    backgroundColor: Colors.theme_color,
    marginTop: 50,
    // marginLeft: 10,
    width: 390,
    borderRadius: 5,
    marginBottom: 10,
    ...shadow,
  },
});
