import {Platform, StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {setYAxisValue, setValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 5,
};

export default StyleSheet.create({
  customerCard:
    Platform.OS == 'web'
      ? {
          flexDirection: 'row',
          backgroundColor: Colors.white,
          padding: setValue(10),
          marginHorizontal:2,
          marginVertical: setYAxisValue(5),
          borderRadius: 5,
          ...shadow,
        }
      : {
          flexDirection: 'row',
          backgroundColor: Colors.white,
          padding: setValue(10),
          marginVertical: setYAxisValue(5),
          borderRadius: 5,
          ...shadow,
        },
  customerDetailContainer: {
    marginLeft: 15,
  },
  customerName: {
    fontSize: setValue(17),
    color: Colors.black20,
  },
  customerEmail: {
    color: Colors.black40,
    paddingVertical: 5,
  },
  customerPhone: {
    color: Colors.black40,
  },
  imageContainer: {
    width: setValue(50),
    height: setValue(50),
    borderRadius: 100,
    overflow: 'hidden',
  },
  image: {
    flex: 1,
    aspectRatio: 1,
    resizeMode: 'contain',
  },
});
