import axios from 'axios';
import {Camera} from 'expo-camera';
import React, {useEffect, useState} from 'react';
import {TouchableOpacity, Vibration, View} from 'react-native';
import BarcodeMask from 'react-native-barcode-mask';

import {AppText, Icon} from '../../components';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import {goBack} from '../../utils';
import styles from './styles';

export default function ScanProductBarcode(props) {
  const [cameraAvailable, setCameraAvailable] = useState(false);
  const [flashModeOn, setFlashModeOn] = useState(false);
  const [scanned, setScanned] = useState(false);
  const {params} = props.route;

  const handleBarCodeScanned = ({data}) => {
    console.log('data ---->', data);
    Vibration.vibrate();
    setScanned(true);
    props.navigation.navigate(
      params?.navigate ? params?.navigate : 'edit-product',
      params?.item
        ? {
            barcode: data,
            item: params?.item,
          }
        : {
            barcode: data,
          },
    );
  };

  useEffect(() => {
    checkCameraPermission();
  }, []);

  const checkCameraPermission = async () => {
    const {status} = await Camera.requestCameraPermissionsAsync();
    console.log('status: ', status);
    if (status === 'granted') {
      setCameraAvailable(true);
    } else {
      goBack(props.navigation);
    }
  };

  return (
    <View style={styles.container}>
      {cameraAvailable ? (
        <Camera
          onBarCodeScanned={scanned ? null : handleBarCodeScanned}
          style={styles.qrcode}
          type={Camera.Constants.Type.back}
          flashMode={
            flashModeOn
              ? Camera.Constants.FlashMode.torch
              : Camera.Constants.FlashMode.off
          }
        >
          <BarcodeMask
            edgeColor={Colors.green}
            animatedLineColor={Colors.wallet_card_red}
            outerMaskOpacity={0}
            lineAnimationDuration={1200}
          />
          <View
            style={[
              styles.content,
              scanned
                ? styles.qrcodeContentDarkOverlay
                : styles.qrcodeContentTransparent,
            ]}
          >
            <View style={styles.header}>
              <TouchableOpacity
                style={{flexDirection: 'row', alignItems: 'center'}}
                onPress={() => goBack(props.navigation)}
              >
                <Icon name="back" color="white" size={20} />
                <AppText style={styles.title}>{'Barcode Scanner'}</AppText>
              </TouchableOpacity>
            </View>
            <View>
              <TouchableOpacity onPress={() => setFlashModeOn(!flashModeOn)}>
                <AppText style={styles.flashMode}>{`${strings(
                  'ordering.checkInOrderScreen.flashMode',
                )}: ${flashModeOn ? 'off' : 'on'}`}</AppText>
              </TouchableOpacity>
            </View>
          </View>
        </Camera>
      ) : null}
    </View>
  );
}
