import React, {useState} from 'react';
import {Modal, LongButton} from '../../components';
import {
  TouchableOpacity,
  View,
  TouchableWithoutFeedback,
  Text,
  ScrollView,
  Platform,
} from 'react-native';
import styles from './styles';
import {screenHeight} from '../../utils';
import {strings} from '../../i18n';
import {useSelector} from 'react-redux';

const ProductFilterModal = (props) => {
  const config = useSelector((state) => state.config);
  const sortingArray = [
    {label: 'Popularity', value: 'popularity'},
    {label: 'Newest', value: 'newest_first'},
    {label: 'Price : High to Low', value: 'high_to_low'},
    {label: 'Price : Low to High', value: 'low_to_high'},
  ];
  const foodTypeArray = [
    {label: 'All', value: ''},
    {label: 'Veg', value: 'veg'},
    {label: 'NonVeg', value: 'non-veg'},
  ];

  let sortArray =
    props.sortBy != ''
      ? [{label: 'Default', value: ''}, ...sortingArray]
      : [...sortingArray];

  return (
    <Modal
      visible={props.isVisible}
      onClose={props.onClose}
      transparent
      onRequestClose={props.onClose}
      style={{marginRight: 18}}
    >
      <TouchableOpacity onPress={props.onClose} style={{flex: 1}}>
        <View style={styles.overlay}>
          <TouchableWithoutFeedback>
            <View
              style={
                Platform.OS === 'web'
                  ? [styles.itemContainer, styles.itemContainerWeb]
                  : styles.itemContainer
              }
            >
              <View style={{padding: 15, maxHeight: screenHeight / 2}}>
                <View style={styles.modalTitleContainer}>
                  <Text style={styles.modalTitle}>
                    {strings('ProductListScreen.filterBy')}
                  </Text>
                </View>
                <ScrollView showsVerticalScrollIndicator={false}>
                  <View style={styles.filterBySort}>
                    <Text style={styles.filterTitle}>
                      {strings('ProductListScreen.sortBy')}
                    </Text>
                    <View style={styles.filterItemContainer}>
                      {sortArray.map((o, index) => (
                        <TouchableOpacity
                          key={index}
                          style={
                            props.sortBy === o.value
                              ? styles.selectedFilterItem
                              : styles.filterItem
                          }
                          onPress={() => props.updateSortBy(o.value)}
                        >
                          <Text
                            style={
                              props.sortBy === o.value
                                ? styles.selectedFilterItemText
                                : styles.filterItemText
                            }
                          >
                            {o.label}
                          </Text>
                        </TouchableOpacity>
                      ))}
                    </View>
                  </View>
                  {config.type == 'restaurants' && (
                    <View style={styles.filterBySort}>
                      <Text style={styles.filterTitle}>
                        {strings('ProductListScreen.foodType')}
                      </Text>
                      <View style={styles.filterItemContainer}>
                        {foodTypeArray.map((o, index) => (
                          <TouchableOpacity
                            key={index}
                            style={
                              props.foodType === o.value
                                ? styles.selectedFilterItem
                                : styles.filterItem
                            }
                            onPress={() => props.updateFoodType(o.value)}
                          >
                            <Text
                              style={
                                props.foodType === o.value
                                  ? styles.selectedFilterItemText
                                  : styles.filterItemText
                              }
                            >
                              {o.label}
                            </Text>
                          </TouchableOpacity>
                        ))}
                      </View>
                    </View>
                  )}
                  
                  {Object.keys(props.staticFilters).length > 0 &&
                    Object.keys(props.staticFilters).map((item, i) => (
                      <View style={styles.filterBySort} key={i}>
                        <Text style={styles.filterTitle}>{item + ' :'}</Text>
                        <View style={styles.filterItemContainer}>
                          {props.staticFilters[item].map((o, index) => (
                            <TouchableOpacity
                              key={index}
                              style={
                                props.selectedFilter?.includes(o)
                                  ? styles.selectedFilterItem
                                  : styles.filterItem
                              }
                              onPress={() => props.updateSelectedFilter(o)}
                            >
                              <Text
                                style={
                                  props.selectedFilter.includes(o)
                                    ? styles.selectedFilterItemText
                                    : styles.filterItemText
                                }
                              >
                                {o}
                              </Text>
                            </TouchableOpacity>
                          ))}
                        </View>
                      </View>
                    ))}
                </ScrollView>
              </View>
              <View style={[styles.actionButtons]}>
                <LongButton
                  style={{borderRadius: 0}}
                  text={'Apply'}
                  onPress={props.onApply}
                />
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableOpacity>
    </Modal>
  );
};
export default ProductFilterModal;
