import axios from 'axios';
import {connectActionSheet} from '@expo/react-native-action-sheet';
import {Camera} from 'expo-camera';
import * as ImagePicker from 'expo-image-picker';
import React, {useState, useEffect} from 'react';
import {
  Image,
  Keyboard,
  Platform,
  TextInput,
  TouchableOpacity,
  View,
  ScrollView,
  ActivityIndicator,
  Appearance,
} from 'react-native';
import {connect, useSelector} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  AppText,
  DropDownInput,
  Icon,
  MainContainer,
  MainHeader,
  SpecificationItem,
  DatePicker,
} from '../../components';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {goBack, setValue} from '../../utils';
import styles from './styles';
import {getMenu} from '../../redux/reduxsauceActions/menuActions';

const colorScheme = Appearance.getColorScheme();
const textColor =
  colorScheme == 'dark' ? 'rgba(255, 255, 255,0.8)' : Colors.black60;
const EditMenuScreen = (props) => {
  const config = useSelector((state) => state.config);
  const [images, setImages] = useState([]);
  const [menuData, setMenuData] = useState({
    menu_name: '',
    menu_price: '0',
    menu_qty_type: 'Always available stock',
    menu_qty: '1',
    menu_description: '',
    menu_specification: null,
    menu_tax: '0',
    menu_discount: '0',
  });

  const [specification, setSpecification] = useState([]);
  const [variantsArray, setVariantsArray] = useState([]);
  const [isDateVisible, setIsDateVisible] = useState(false);
  const [date, setDate] = useState(
    props.route.params?.item?.available_at || '',
  );
  const [isNewMenu, setIsNewMenu] = useState(true);
  const [isVeg, setIsVeg] = useState('veg');
  const [imageLoading, setImageLoading] = useState(false);
  const [imageLoadingIndex, setImageLoadingIndex] = useState(null);

  const checkCameraPermission = async () => {
    const {status} = await Camera.requestCameraPermissionsAsync();
    if (status === 'granted') {
      props.navigation.navigate('scan-Menu-barcode');
    }
  };

  useEffect(() => {
    if (props.route.params?.item) {
      let item = props.route.params?.item;

      setMenuData({
        ...menuData,
        menu_name: item?.name,
        menu_description: item.description,
        menu_price: parseInt(item.price).toString(),
        menu_qty: parseInt(item.stock_qty).toString(),
        menu_barcode: item.barcode,
        menu_tax: parseInt(item.tax).toString(),
        menu_weight: parseFloat(item?.weight).toString(),
        menu_discount: parseInt(item.discount).toString(),
        menu_unit: item?.unit,
        menu_keywords: item?.keywords,
        menu_qty_type: item?.always_available_stock
          ? 'Always available stock'
          : 'Limited stock',
      });
      item?.images.length > 0 && setImages(item?.images);
      setIsNewMenu(item?.is_new);
      item?.food_type == 'non-veg' ? setIsVeg('non-veg') : setIsVeg('veg');
      item?.details?.length && setSpecification(item?.details);
    }
    setDate(props.route.params?.item?.available_at || '');
    console.log('date ---------->', props.route.params?.item?.available_at);
  }, [props.route.params?.item]);

  const onSave = () => {
    const {config} = props;
    Keyboard.dismiss();

    if (menuData.menu_name == '') {
      props.openAlert(strings('editMenuScreen.alert.menuName'));
      return;
    }
    if (menuData.menu_price == '' || menuData.menu_price == 0) {
      props.openAlert(strings('editMenuScreen.alert.price'));
      return;
    }
    if (menuData.stock_qty) {
      props.openAlert(strings('editMenuScreen.alert.price'));
      return;
    }
    if (!date || date == '') {
      props.openAlert(strings('editMenuScreen.alert.availableAt'));
      return;
    }
    let specificationArray = specification.filter((o) => {
      if (o.label != '' && o.label != undefined && o.label != null) {
        if (
          o.type == 'text' ||
          (o.type == 'longText' && o.value != null && o.value != '')
        ) {
          return o;
        } else if (o.type == 'array' && o.value.length > 0) {
          let valueArray = o.value.filter((o) => o != '');
          if (valueArray?.length > 0) {
            o.value = valueArray;
            return o;
          }
        }
      }
    });

    let body = {
      business: config.businessId,
      name: menuData.menu_name,
      price: menuData.menu_price ? parseInt(menuData.menu_price) : 0,
      discount: parseInt(menuData.menu_discount),
      weight: null,
      keywords: null,
      stock_qty: menuData.menu_qty ? parseInt(menuData.menu_qty) : 0,
      tax: menuData.menu_tax ? parseInt(menuData.menu_tax) : 0,
      details: null,
      available_at: date,
      always_available_stock:
        menuData.menu_qty_type == 'Always available stock',
    };

    if (menuData.menu_description) {
      body = {
        ...body,
        description: menuData.menu_description,
      };
    }
    if (specificationArray?.length > 0) {
      body = {
        ...body,
        details: specificationArray,
      };
    }

    if (config.type == 'restaurants') {
      body = {
        ...body,
        food_type: isVeg,
      };
    }
    props.setLoading(true);
    if (props.route.params?.item) {
      axios
        .put('/products/update/' + props.route.params?.item.id, body)
        .then(async (response) => {
          props.getMenu();
          goBack(props.navigation);
          props.setLoading(false);
        })
        .catch((error) => {
          props.openAlert(error?.response?.message);
          props.setLoading(false);
        });
    } else {
      axios
        .post('/products/create', body)
        .then(async (response) => {
          await saveNewMenuImages(response?.data?.id);
        })
        .catch((error) => {
          props.openAlert(error?.response?.message);
          props.setLoading(false);
        });
    }
  };

  const dataURLtoFile = (dataurl) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], 'image1.jpg', {type: mime});
  };

  const saveNewMenuImages = (id) => {
    Promise.all(
      images.map(async (o, index) => {
        let blob = await (await fetch(o.photo)).blob();
        let body = new FormData();
        body.append('product', id);
        Platform.OS == 'web'
          ? body.append('photo', await dataURLtoFile(o.photo))
          : body.append('photo', {
              uri: o.photo,
              type: blob.type,
              name: blob.data.name,
            });

        return axios.post('/products/images/create', body);
      }),
    )
      .then((response) => {
        props.getMenu();
        props.setLoading(false);
        goBack(props.navigation);
      })
      .catch((error) => {
        props.openAlert(error?.response?.message);
        props.setLoading(false);
      });
  };

  const UpdateOldMenuImages = async (id, url) => {
    let menuId = props.route?.params?.item?.id;
    let blob = await (await fetch(url)).blob();

    if (id) {
      let array = [...images];
      let index = array.findIndex((o) => o.id == id);

      if (index >= 0) {
        setImageLoading(true);
        setImageLoadingIndex(index);
      }

      let body = new FormData();
      body.append('product', menuId);
      Platform.OS == 'web'
        ? body.append('photo', dataURLtoFile(url))
        : body.append('photo', {
            uri: url,
            type: blob.type,
            name: blob.data.name,
          });
      axios
        .put('/products/images/update/' + id, body)
        .then((response) => {
          console.log(response.data);
          array[index] = {
            ...array[index],
            ...response.data,
          };
          setImages(array);
          setImageLoading(false);
          setImageLoadingIndex(null);
        })
        .catch((error) => {
          props.openAlert(error?.response?.message);
          props.setLoading(false);
          setImageLoading(false);
          setImageLoadingIndex(null);
        });
    } else {
      let body = new FormData();
      body.append('product', menuId);
      Platform.OS == 'web'
        ? body.append('photo', dataURLtoFile(url))
        : body.append('photo', {
            uri: url,
            type: blob.type,
            name: blob.data.name,
          });

      axios
        .post('/products/images/create', body)
        .then((response) => {
          let array = [...images];
          array.push(response.data);
          setImages(array);
        })
        .catch((error) => {
          props.openAlert(error?.response?.message);
          props.setLoading(false);
        });
    }
  };

  const deleteImage = (id) => {
    let array = [...images];
    let index = array.findIndex((o) => o.id == id);
    setImageLoading(true);
    setImageLoadingIndex(index);
    axios
      .delete('/products/images/delete/' + id)
      .then((response) => {
        console.log('response delete image', response);
        array.splice(index, 1);
        setImages(array);
        setImageLoading(false);
        setImageLoadingIndex(null);
      })
      .catch((error) => {
        props.openAlert(error?.response?.data?.detail);
        setImageLoading(false);
        setImageLoadingIndex(null);
      });
  };

  const openActionSheet = (index) => {
    const options = [
      strings('actionSheet.takePhoto'),
      strings('actionSheet.choosePhoto'),
      strings('actionSheet.removePhoto'),
      strings('actionSheet.cancel'),
    ];
    const cancelButtonIndex = 3;

    props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) openCamera(index);
        if (buttonIndex === 1) openImagePicker(index);
        if (buttonIndex === 2) removePhoto(index);
      },
    );
  };

  const removePhoto = (index) => {
    let array = [...images];

    if (!index && index != 0) {
      return null;
    } else {
      if (props.route?.params?.item) {
        let id = array[index]?.id;
        props.openAlert(
          strings('editMenuScreen.alert.delete'),
          strings('settingScreen.confirm'),
          () => deleteImage(id),
          strings('settingScreen.cancel'),
          null,
        );
      } else {
        array.splice(index, 1);
      }
    }

    setImages(array);
  };

  const openCamera = async (index) => {
    await ImagePicker.requestCameraPermissionsAsync();

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });

    if (result.uri) {
      let array = [...images];

      // if function have not any index of image
      if (!index && index != 0) {
        // if function have index and also Menu is old then call api
        if (props.route?.params?.item) {
          UpdateOldMenuImages(null, result.uri);

          // if function have index but Menu is new
        } else {
          let obj = new Object({
            photo: result.uri,
          });
          array.push(obj);
        }

        // if function have any  image index
      } else {
        // for old Menu
        if (props.route?.params?.item) {
          let id = array[index]?.id;
          UpdateOldMenuImages(id, result.uri);

          // for new Menu
        } else {
          array[index] = {
            ...array[index],
            photo: result.uri,
          };
        }
      }
      setImages(array);
    }
  };

  const openImagePicker = async (index) => {
    await ImagePicker.requestMediaLibraryPermissionsAsync();

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });

    if (result.uri) {
      let array = [...images];

      // if function have not any index of image
      if (!index && index != 0) {
        // if function have index and also Menu is old then call api
        if (props.route?.params?.item) {
          UpdateOldMenuImages(null, result.uri);

          // if function have index but Menu is new
        } else {
          let obj = new Object({
            photo: result.uri,
          });
          array.push(obj);
        }

        // if function have any  image index
      } else {
        // for old Menu
        if (props.route?.params?.item) {
          let id = array[index]?.id;
          UpdateOldMenuImages(id, result.uri);

          // for new Menu
        } else {
          array[index] = {
            ...array[index],
            photo: result.uri,
          };
        }
      }
      setImages(array);
    }
  };

  // change in any specification field
  const onSpecificationChange = (label, value, type, index) => {
    let arr = [...specification];
    if (label != undefined) {
      arr[index] = {
        ...arr[index],
        label: label,
      };
      setSpecification(arr);
    } else if (value != undefined) {
      arr[index] = {
        ...arr[index],
        value: value,
      };
      setSpecification(arr);
    } else if (type != undefined) {
      if (type == 'array') {
        arr[index] = {
          ...arr[index],
          type: type,
          value: [],
        };
      } else {
        arr[index] = {
          ...arr[index],
          type: type,
          value: '',
        };
      }
      setSpecification(arr);
    }
  };

  // add specification  fields(label, type ,  value)
  const addSpecificationField = () => {
    let index = specification?.length;

    if (index) {
      if (
        specification[index - 1].label == null ||
        specification[index - 1].label == undefined ||
        specification[index - 1].label == ''
      ) {
        props.openAlert(strings('editMenuScreen.alert.label'));
      } else if (
        specification[index - 1].value == null ||
        specification[index - 1].value == undefined ||
        specification[index - 1].value == ''
      ) {
        props.openAlert(strings('editMenuScreen.alert.value'));
      } else {
        setSpecification([
          ...specification,
          {label: '', value: null, type: 'text'},
        ]);
      }
    } else {
      setSpecification([
        ...specification,
        {label: '', value: null, type: 'text'},
      ]);
    }
  };

  // remove last specification field
  const removeSpecificationField = () => {
    let specifications = [...specification];
    specifications.pop();
    setSpecification([...specifications]);
  };

  // when specification type array then add value field
  //here value is an array
  const AddValueField = (index) => {
    let valueArray = [...specification[index].value];

    let fullArray = [...specification];

    if (valueArray.length) {
      if (valueArray[valueArray.length - 1] == '') {
        props.openAlert(
          strings('editMenuScreen.alert.point_above') +
            valueArray.length +
            strings('editMenuScreen.alert.point_below'),
        );
      } else {
        valueArray.push('');
        fullArray[index] = {
          ...fullArray[index],
          value: valueArray,
        };
        setSpecification(fullArray);
      }
    } else {
      valueArray.push('');
      fullArray[index] = {
        ...fullArray[index],
        value: valueArray,
      };
      setSpecification(fullArray);
    }
  };

  const RemoveValueField = (index) => {
    let valueArray = [...specification[index].value];
    let fullArray = [...specification];

    valueArray.pop();
    fullArray[index] = {
      ...fullArray[index],
      value: valueArray,
    };
    setSpecification(fullArray);
  };

  // when value is array then that handle specific value filed
  const SpecificationPointsChange = (text, i, index) => {
    let valueArray = [...specification[index].value];
    let fullArray = [...specification];

    valueArray[i] = text;

    fullArray[index] = {
      ...fullArray[index],
      value: valueArray,
    };

    setSpecification(fullArray);
  };

  return (
    <View
      style={
        Platform.OS == 'web'
          ? {display: 'flex', overflow: 'auto', height: '100vh'}
          : styles.container
      }
    >
      <MainHeader
        title={
          props.route.params?.item
            ? strings('editMenuScreen.title.edit')
            : strings('editMenuScreen.title.add')
        }
        leftIcon="back"
        leftPress={() => {
          props.getMenu();
          goBack(navigation);
        }}
      />
      <MainContainer>
        <View style={styles.MenuStrengthContainer}>
          {images.length > 0 ? (
            <ScrollView
              showsHorizontalScrollIndicator={false}
              horizontal
              centerContent={true}
            >
              {images.map((o, index) =>
                imageLoading && imageLoadingIndex == index ? (
                  <TouchableOpacity
                    style={styles.imageContainer}
                    onPress={() => openActionSheet(index)}
                    key={index}
                  >
                    <ActivityIndicator
                      size="small"
                      color={Colors.theme_color}
                    />
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity
                    style={styles.imageContainer}
                    onPress={() => openActionSheet(index)}
                    key={index}
                  >
                    <View>
                      <Image source={{uri: o.photo}} style={styles.image} />
                      <TouchableOpacity
                        style={styles.cameraIcon}
                        onPress={() => openActionSheet(index)}
                      >
                        <Icon name="camera" color={Colors.black20} />
                      </TouchableOpacity>
                    </View>
                  </TouchableOpacity>
                ),
              )}
              {images.length < 8 && (
                <TouchableOpacity
                  style={styles.imageContainer}
                  onPress={() => openActionSheet(undefined)}
                >
                  <Icon name="add" size={16} color={Colors.black60} />
                </TouchableOpacity>
              )}
            </ScrollView>
          ) : (
            <TouchableOpacity
              style={styles.imageContainer}
              onPress={() => openActionSheet(undefined)}
            >
              <Icon name="add" size={16} color={Colors.black60} />
            </TouchableOpacity>
          )}
        </View>

        <View>
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel} required>
              {strings('editMenuScreen.label.name')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              placeholder={strings('editMenuScreen.placeholder.name')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
              value={menuData.menu_name}
              onChangeText={(text) =>
                setMenuData({...menuData, menu_name: text})
              }
            />
          </View>

          <View
            style={[
              styles.textInputContainer,
              Platform.OS == 'web' && {minHeight: 90},
            ]}
          >
            <AppText style={styles.inputLabel}>
              {strings('editMenuScreen.label.description')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              multiline={true}
              numberOfLines={3}
              placeholder={strings('editMenuScreen.placeholder.description')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web'
                  ? [styles.textInputWeb]
                  : styles.multiLineTextInput
              }
              value={menuData.menu_description}
              onChangeText={(text) =>
                setMenuData({...menuData, menu_description: text})
              }
            />
          </View>
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel} required>
              {strings('editMenuScreen.placeholder.price')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              placeholder={strings('editMenuScreen.placeholder.price')}
              keyboardType={
                Platform.OS === 'ios'
                  ? 'numbers-and-punctuation'
                  : 'decimal-pad'
              }
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
              value={menuData.menu_price}
              onChangeText={(text) =>
                setMenuData({...menuData, menu_price: text})
              }
            />
          </View>
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('editMenuScreen.label.discount') + '  (%)'}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              placeholder={strings('editMenuScreen.placeholder.discount')}
              keyboardType={
                Platform.OS === 'ios'
                  ? 'numbers-and-punctuation'
                  : 'decimal-pad'
              }
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
              value={menuData.menu_discount}
              onChangeText={(text) =>
                setMenuData({...menuData, menu_discount: text})
              }
            />
          </View>

          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('editMenuScreen.label.inventory')}
            </AppText>
            <DropDownInput
              data={['Always available stock', 'Limited stock']}
              value={menuData.menu_qty_type}
              inputStyle={{
                paddingHorizontal: 10,
                marginVertical: Platform.OS == 'web' ? 0 : 10,
              }}
              onChangeText={(text) => {
                setMenuData({...menuData, menu_qty_type: text});
              }}
            />
          </View>
          {menuData.menu_qty_type != 'Always available stock' && (
            <View style={styles.viewContainer}>
              <View
                style={[styles.textInputContainer, {marginRight: setValue(3)}]}
              >
                <AppText style={styles.inputLabel} required>
                  {strings('editMenuScreen.label.qty')}
                </AppText>
                <TextInput
                  underlineColorAndroid="transparent"
                  autoCorrect={false}
                  keyboardType="numeric"
                  placeholder={strings('editMenuScreen.placeholder.qty')}
                  placeholderTextColor={Colors.color30}
                  returnKeyType="done"
                  style={
                    Platform.OS == 'web'
                      ? [styles.textInputWeb, {height: setValue(40)}]
                      : [styles.textInput, {marginHorizontal: setValue(3)}]
                  }
                  value={menuData.menu_qty}
                  onChangeText={(text) =>
                    setMenuData({...menuData, menu_qty: text})
                  }
                />
              </View>
            </View>
          )}
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('editMenuScreen.label.availableAt')}
            </AppText>
            <View style={styles.datePicker}>
              <DatePicker
                pickupDate={date}
                title={
                  <AppText>
                    {date == ''
                      ? strings('editMenuScreen.placeholder.availableAt')
                      : date}
                  </AppText>
                }
                showCross={!date || date == '' ? false : true}
                minDate={Date.now()}
                visible={isDateVisible}
                onClose={() => {
                  setIsDateVisible(false);
                  setDate('');
                }}
                onDayPress={(selectedDate) => {
                  setIsDateVisible(false);
                  setDate(selectedDate?.dateString);
                }}
                onCancelPress={() => {
                  setIsDateVisible(false);
                  setDate('');
                }}
                onSetDataForWeb={setDate}
                onCalenderPress={() => {
                  if (date != '') {
                    setDate('');
                  } else {
                    setIsDateVisible(true);
                  }
                }}
              />
            </View>
          </View>

          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('editMenuScreen.label.tax') + ' (%)'}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              keyboardType="numeric"
              placeholder={strings('editMenuScreen.placeholder.tax') + ' (%)'}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={styles.textInput}
              value={menuData.menu_tax}
              onChangeText={(text) =>
                setMenuData({...menuData, menu_tax: text})
              }
            />
          </View>
          {config.type == 'restaurants' && (
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>
                {strings('editMenuScreen.label.foodType')}
              </AppText>
              {
                <DropDownInput
                  data={[
                    strings('editMenuScreen.dropDownPicker.vag'),
                    strings('editMenuScreen.dropDownPicker.vag'),
                  ]}
                  value={isVeg}
                  inputStyle={{
                    paddingHorizontal: 10,
                    marginVertical: Platform.OS == 'web' ? 0 : 8,
                  }}
                  onChangeText={(text) => {
                    setIsVeg(text);
                  }}
                />
              }
            </View>
          )}

          <View
            style={{
              paddingVertical: 10,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AppText
              style={{
                color: textColor,
                fontSize: setValue(20),
                fontWeight: 'bold',
              }}
            >
              {strings('editMenuScreen.label.details')}
            </AppText>
          </View>

          {specification &&
            specification.map((item, index) => (
              <SpecificationItem
                item={item}
                key={index}
                index={index}
                onSpecificationKeyChange={(text, index) =>
                  onSpecificationChange(text, undefined, undefined, index)
                }
                onSpecificationValueChange={(text, index) =>
                  onSpecificationChange(undefined, text, undefined, index)
                }
                onSpecificationTypeChange={(text, index) =>
                  onSpecificationChange(undefined, undefined, text, index)
                }
                AddValueField={(index) => AddValueField(index)}
                RemoveValueField={(index) => RemoveValueField(index)}
                SpecificationPointsChange={(text, i, index) =>
                  SpecificationPointsChange(text, i, index)
                }
              />
            ))}
          <View style={styles.addRomoveContainer}>
            <TouchableOpacity
              onPress={removeSpecificationField}
              style={[styles.addRomoveButton, {marginRight: 5}]}
            >
              <AppText style={styles.addRomoveButtonText}>
                {strings('editMenuScreen.button.remove')}
              </AppText>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={addSpecificationField}
              style={[styles.addRomoveButton, {marginLeft: 5}]}
            >
              <AppText style={styles.addRomoveButtonText}>
                {strings('editMenuScreen.button.add')}
              </AppText>
            </TouchableOpacity>
          </View>
        </View>

        {Platform.OS == 'web' && (
          <View style={styles.buttonContainerWeb}>
            <TouchableOpacity style={styles.buttonButtonWeb} onPress={onSave}>
              <AppText style={styles.buttonText}>
                {props.route.params?.item
                  ? strings('editMenuScreen.button.update')
                  : strings('editMenuScreen.button.save')}
              </AppText>
            </TouchableOpacity>
          </View>
        )}
      </MainContainer>
      {Platform.OS !== 'web' && (
        <View style={styles.buttonContainer}>
          <TouchableOpacity style={styles.buttonButton} onPress={onSave}>
            <AppText style={styles.buttonText}>
              {props.route.params?.item
                ? strings('editMenuScreen.button.update')
                : strings('editMenuScreen.button.save')}
            </AppText>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};
const ConnectedEditMenu = connectActionSheet(EditMenuScreen);

export default connect(
  (state) => ({
    config: state.config,
    common: state.common,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setLoading: CommonActions.setLoading,
        openAlert: (content, leftButton, leftPress, rightButton, rightPress) =>
          CommonActions.setAlert({
            visible: true,
            content,
            leftButton,
            leftPress,
            rightButton,
            rightPress,
          }),
        getMenu,
      },
      dispatch,
    ),
)(ConnectedEditMenu);
