import get from 'lodash.get';
import moment from 'moment';
import React, {Component} from 'react';
import {
  Image,
  Platform,
  RefreshControl,
  TouchableOpacity,
  View,
  ScrollView,
} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {AppText, Icon, MainContainer, MainHeader} from '../../components';
import {Colors, Images} from '../../constants';
import {strings} from '../../i18n';
import {cashFormat, setValue} from '../../utils';
import styles from './styles';
import Constants from 'expo-constants';


class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVerify: false,
      isRefreshing: false,
      walletGuideStep: null,
    };

    this.currencySymbol = get(props.appConfig, 'currencySymbol', '₹');
  }

  renderInfoCard = () => (
    <View style={styles.cardContainer}>
      <View style={styles.infoContainer}>
        <TouchableOpacity
          onPress={() => {
            if (this.state.walletGuideStep === null) {
              this.props.navigation.navigate('edit-profile');
            }
          }}
        >
          <Image
            source={
              this.props?.user?.photo
                ? {uri: `${this.props?.user?.photo}`}
                : Images.profile
            }
            style={styles.avatar}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.infoContent}
          onPress={() => {
            if (this.state.walletGuideStep === null) {
              this.props.navigation.navigate('edit-profile');
            }
          }}
        >
          <View style={{width: '100%'}}>
            <AppText style={styles.name} numberOfLines={1}>
              {this.props.user &&
                this.props.user.first_name + ' ' + this.props.user.last_name}
            </AppText>
            <AppText numberOfLines={1} style={styles.email}>
              {this.props.user && this.props.user.phone}
            </AppText>
            <AppText numberOfLines={1} style={styles.date}>
              {this.props.user.email}
            </AppText>
          </View>
        </TouchableOpacity>
      </View>
      <TouchableOpacity
        style={styles.qrContainer}
        onPress={() => this.props.navigation.navigate('my-qr')}
      >
        <Icon name="qrCode" size={20} color={Colors.black20} />
      </TouchableOpacity>
    </View>
  );

  // renderVouchers = () => (
  //   <TouchableOpacity
  //     style={[styles.itemContainer, styles.borderBottom]}
  //     onPress={() => this.props.navigation.navigate('VoucherList')}
  //   >
  //     <AppText>{strings('walletScreen.vouchers')}</AppText>
  //     <View style={styles.numberContainer}>
  //       <AppText style={styles.number}>
  //         {this.props.voucherCount && this.props.voucherCount}
  //       </AppText>
  //       <Icon name="forward" size={14} />
  //     </View>
  //   </TouchableOpacity>
  // );

  renderAvailableCoin = () => {
    return (
      <View style={styles.coinCardContainer}>
        <TouchableOpacity
          style={[styles.itemContainer]}
          onPress={() => this.props.navigation.navigate('coin-detail')}
        >
          <AppText>{strings('walletScreen.coins')}</AppText>
          <View style={styles.numberContainer}>
            <AppText style={styles.currency}>{this.currencySymbol}</AppText>
            <AppText style={styles.number}>{this.props.user?.coin}</AppText>
            <Icon name="forward" size={14} />
          </View>
        </TouchableOpacity>
        {Platform.OS != 'web' && (
          <TouchableOpacity
            style={[styles.itemContainer, {marginBottom: setValue(10)}]}
            onPress={() =>
              this.props.navigation.navigate('scan-customers-qr', {
                navigate: 'send-coins',
              })
            }
          >
            <AppText>{strings('walletScreen.sendCoins')}</AppText>
            <View style={styles.numberContainer}>
              <Icon name="forward" size={14} />
            </View>
          </TouchableOpacity>
        )}
      </View>
    );
  };

  // renderStoreCredit = () => {
  //   return (
  //     <TouchableOpacity
  //       style={styles.itemContainer}
  //       onPress={() => {
  //         this.props.navigation.navigate('StoreCredit', {isFromWallet: true});
  //       }}
  //     >
  //       <AppText>{strings('walletScreen.storeCreditHistory')}</AppText>
  //       <View style={styles.numberContainer}>
  //         <AppText style={styles.currency}>{this.currencySymbol}</AppText>
  //         <AppText style={styles.number}>
  //           {cashFormat(this.props.walletSummary?.creditBalance)}
  //         </AppText>
  //         <Icon name="forward" size={14} />
  //       </View>
  //     </TouchableOpacity>
  //   );
  // };

  renderBusinessCard = () => {
    return (
      <View>
        <View style={styles.transContainer}>
          {/* <View style={styles.historyTitleBackground}>
            <AppText style={styles.historyTitle}>Business</AppText>
          </View> */}
          <TouchableOpacity
            onPress={() => this.props.navigation.navigate('business-overview')}
            style={[styles.itemContainer, styles.borderBottom]}
          >
            <AppText>{'Overview'}</AppText>
            <Icon name="forward" size={14} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.props.navigation.navigate('edit-business-info')}
            style={[styles.itemContainer, styles.borderBottom]}
          >
            <AppText>{'Information'}</AppText>
            <Icon name="forward" size={14} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.props.navigation.navigate('business-setting')}
            style={[styles.itemContainer, styles.borderBottom]}
          >
            <AppText>{'Setting'}</AppText>
            <Icon name="forward" size={14} />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  renderHistoryAction = () => {
    return (
      <View>
        {this.props.appConfig?.enableAppCoin && this.renderAvailableCoin()}
        <View style={styles.historyContainer}>
          {/* {isPrepaid && (
            <TouchableOpacity
              style={[styles.itemContainer, styles.borderBottom]}
              onPress={() =>
                this.props.navigation.navigate('StoreCreditHistory')
              }
            >
              <AppText>{strings('walletScreen.storeCreditHistory')}</AppText>
              <Icon name="forward" size={14} />
            </TouchableOpacity>
          )} */}
          <TouchableOpacity
            style={[styles.itemContainer]}
            onPress={() => this.props.navigation.navigate('address-list', {})}
          >
            <AppText>{strings('walletScreen.savedAddress')}</AppText>
            <Icon name="forward" size={14} />
          </TouchableOpacity>

          <TouchableOpacity
            style={[styles.itemContainer]}
            onPress={() => this.props.navigation.navigate('order-history')}
          >
            <AppText>{strings('walletScreen.orderHistory')}</AppText>
            <Icon name="forward" size={14} />
          </TouchableOpacity>
          {/* {rewardMechanism === 'CashBack' && enableLPCP && (
            <TouchableOpacity
              style={[styles.itemContainer, styles.borderBottom]}
              onPress={() => this.props.navigation.navigate('CashbackHistory')}
            >
              <AppText>{strings('walletScreen.cashbackHistory')}</AppText>
              <Icon name="forward" size={14} />
            </TouchableOpacity>
          )}
          <TouchableOpacity
            style={[styles.itemContainer, styles.borderBottom]}
            onPress={() => this.props.navigation.navigate('TransactionHistory')}
          >
            <AppText>{strings('walletScreen.transactionHistory')}</AppText>
            <Icon name="forward" size={14} />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.itemContainer}
            onPress={() => this.props.navigation.navigate('GiftingAndReferral')}
          >
            <AppText>{strings('walletScreen.giftingReferral')}</AppText>
            <Icon name="forward" size={14} />
          </TouchableOpacity> */}
        </View>
        {/* <View style={styles.historyTitleBackground}>
          <AppText style={styles.historyTitle}>Histories</AppText>
        </View> */}
      </View>
    );
  };

  render() {
    const {appConfig} = this.props;
    return (
      <View style={styles.container}>
        <MainHeader
          leftIcon={!appConfig?.theme?.showTabBar ? 'back' : null}
          leftPress={
            !appConfig?.theme?.showTabBar
              ? () => this.props.navigation.goBack()
              : null
          }
          title={strings('walletScreen.wallet')}
          navigation={this.props.navigation}
          route={this.props.route}
        />
        {Platform.OS === 'ios' ? (
          <MainContainer useView>
            <ScrollView
              refreshControl={
                <RefreshControl
                  refreshing={this.state.isRefreshing}
                  onRefresh={async () => {
                    this.setState({isRefreshing: true});
                    await Promise.all([]).then(() => {
                      this.setState({isRefreshing: false});
                    });
                  }}
                />
              }
            >
              {this.renderInfoCard()}
              {/* {false && this.renderTierAndChopCard()} */}
              {/* {this.renderCashCard()} */}
              {this.renderHistoryAction()}
              {/* {this.props.user.type === 'merchant' && this.renderBusinessCard()} */}
            </ScrollView>
          </MainContainer>
        ) : (
          <MainContainer
            useView
            refreshControl={
              <RefreshControl
                refreshing={this.state.isRefreshing}
                onRefresh={async () => {
                  this.setState({isRefreshing: true});
                  await Promise.all([
                    // this.props.getProductVoucherCount(),
                    // this.props.getVCCouponCount(),
                    // this.props.getCashbackDetail(),
                  ]).then(() => {
                    this.setState({isRefreshing: false});
                  });
                }}
              />
            }
          >
            {this.renderInfoCard()}
            {/* { this.renderTierAndChopCard()} */}
            {/* {this.renderCashCard()}  */}
            {this.renderHistoryAction()}
            {/* {this.props.user.type === 'merchant' && this.renderBusinessCard()} */}
          </MainContainer>
        )}
      </View>
    );
  }
}

export default connect(
  (state) => ({
    user: state.auth.user,
    verify: state.auth.verify,
    profile: state.auth.profile,
    companies: state.home.companies,
    appConfig: state.config,
    feature: state.home.feature,
    rewardMechanism: state.home.rewardMechanism,
    enableLPCP: state.home.enableLPCP,
  }),
  (dispatch) => bindActionCreators({}, dispatch),
)(Account);
