import axios from 'axios';
import React, {useRef} from 'react';
import {View} from 'react-native';
import {MainContainer, MainHeader, WebView} from '../../components';
import {goBack} from '../../utils';

const OfflineOrderPrintPreview = (props) => {
  const id = props.route.params?.id;
  let WebviewRef = useRef();

  /**
   * ON BODY LOAD SEND POST MESSAGE TO REACT NATIVE WEBVIEW
   * HANDLE ONMESSAGE FUNCTION AND SAVE IN STATE
   * ON PRINT ORDER FUNCTION DOWNLOAD PDF LIKE ORDER DETAIL PAGE
   * END PROCESS
   */

  const printOrder = () => {};

  return (
    <View style={{flex: 1}}>
      <MainHeader
        title={'Order Print'}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
        rightIcon={'print'}
        rightPress={() => printOrder()}
      />

      <MainContainer useView>
        <View style={{flex: 1}}>
          <WebView
            useWebKit
            source={{uri: axios.defaults.baseURL + '/invoice/' + id}}
            style={{flex: 1}}
            originWhitelist={['*']}
            onMessage={(event) => {
              console.log('E-------', event);
            }}
            javaScriptEnabled
            injectJavaScript={`window.ReactNativeWebView.postMessage(document.documentElement.innerHTML)`}
          />
        </View>
      </MainContainer>
    </View>
  );
};

export default OfflineOrderPrintPreview;
