import React, {useEffect} from 'react';
import {TouchableOpacity, Image, View, Text, Platform} from 'react-native';
import styles from './styles';
import AppText from '../AppText';
import {Colors} from '../../constants';

export default function ProductCategoryDarkItem(props) {
  const {item, selectedItem} = props;
  return (
    <TouchableOpacity
      style={[
        styles.categoryItem,
        selectedItem === item.id ? styles.selected : null,
      ]}
      onPress={() => {
        props.OnPressCategory();
      }}
      key={item.id}
    >
      {item.type != 'all' && (
        <View style={[styles.categoryButtonIconContainer]}>
          {/* <View style={styles.categoryImages}>
            <Image                                                    
              style={styles.image}
              source={item.type == 'all' ? item.photo : {uri: item.photo}}
              resizeMode="cover"
            />
          </View> */}
        </View>
      )}
      {Platform.OS !== 'web' ? (
        <Text
          style={
            ([styles.categoryButtonText],
            selectedItem === item.id
              ? styles.selectedCategoryButtonText
              : styles.categoryButtonText)
          }
          numberOfLines={1}
        >
          {item.name + ' '}
          {item?.product_count >= 0 && '(' + item?.product_count + ')'}
        </Text>
      ) : (
        <View
          style={
            selectedItem === item.id
              ? styles.selectedCategoryTextWebContainer
              : styles.categoryTextWebContainer
          }
        >
          <AppText
            style={
              selectedItem === item.id
                ? styles.selectedCategoryButtonTextWeb
                : styles.categoryButtonTextWeb
            }
            numberOfLines={1}
          >
            {item.name + ' '}
            {item?.product_count >= 0 && '(' + item?.product_count + ')'}
          </AppText>
        </View>
      )}
    </TouchableOpacity>
  );
}
