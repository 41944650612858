import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {
  View,
  Platform,
  TextInput,
  ActivityIndicator,
  Text,
  TouchableOpacity,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import CommonAction from '../../redux/reduxsauce/commonRedux';
import {
  AppText,
  MainContainer,
  MainHeader,
  LongButton,
  AppConfigCheckbox,
  DropDownInput,
  Icon,
} from '../../components';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import {goBack, setValue} from '../../utils';
import styles from './styles';
import Constants from 'expo-constants';
import {getAppConfig} from '../../redux/reduxsauceActions/configActions';
import {LinearGradient} from 'expo-linear-gradient';
import {MaterialIcons} from '@expo/vector-icons';

const ClientAppSetting = (props) => {
  const config = useSelector((state) => state.config);
  const [oval, setOval] = useState(false);
  const [gradient, setGradient] = useState(false);
  const [enableCoin, setEnableCoin] = useState(config.enableAppCoin);
  const [appName, setAppName] = useState(config?.appName);
  const [authentication_type, setAuthenticationType] = useState('phone');
  const [androidPackageName, setAndroidPackageName] = useState(
    config?.androidPackageName,
  );
  const [iosBundleIdentifier, setIosBundleIdentifier] = useState(
    config?.iosBundleIdentifier,
  );
  const [theme, setTheme] = useState({
    showTabBar: true,
    screenBackground: '',
    isLoginRequired: true,
    dark: false,
    themeColor: '#ffb800',
    primaryColor: '#ffffff',
    secondaryColor: '#d3d3d3',
  });

  // dark: false,
  //   primaryColor: '#ffffff',
  //   secondaryColor: '#d3d3d3',
  //   themeColor: '#ffb800',

  const dispatch = useDispatch();

  useEffect(() => {
    const setting = config?.theme;

    setAuthenticationType(config?.authentication_type || 'phone');
    if (
      setting?.screenBackground == 'oval' ||
      !setting?.screenBackground ||
      setting?.screenBackground == undefined
    ) {
      setTheme({
        ...theme,
        screenBackground: 'oval',
      });
      setOval(true);
    }
    if (setting?.screenBackground == 'gradient') {
      setGradient(true);
      setTheme({
        ...theme,
        screenBackground: 'gradient',
      });
    }
    setTheme({
      ...theme,
      isLoginRequired: setting?.isLoginRequired ? true : false,
      dark: setting?.dark ? true : false,
      themeColor: setting?.themeColor ? setting?.themeColor : '',
      primaryColor: setting?.primaryColor ? setting?.primaryColor : '',
      secondaryColor: setting?.secondaryColor ? setting?.secondaryColor : '',
      showTabBar: setting?.showTabBar ? true : false,
    });
  }, []);

  const saveConfigData = async () => {
    dispatch(CommonAction.setLoading(true));
    try {
      const response = await axios.patch(
        `/business/update/business-setting/${config?.businessId}`,
        {
          enable_app_coin: enableCoin,
          authentication_type: authentication_type,
          theme: {...theme},
        },
      );
      console.log('response', response);
      dispatch(getAppConfig());
      dispatch(CommonAction.setLoading(false));
      Platform.OS == 'web' &&
        dispatch(
          CommonAction.setAlert({
            visible: true,
            content: strings('settingScreen.clientSetting.success'),
          }),
        );
      Platform.OS == 'web' && window.location.reload('/');
      goBack(navigation);
    } catch (error) {
      dispatch(CommonAction.setLoading(false));
      console.log('error', error);
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: error?.response?.message || 'Something went wrong :(',
        }),
      );
    }
  };

  return (
    <View
      style={Platform.OS === 'web' ? styles.webContainer : styles.container}
    >
      <MainHeader
        title={strings('settingScreen.clientSetting.title')}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer>
        <View style={{marginBottom: 50, height: '100%'}}>
          {/* --------------static App setting Disabled------------------- */}
          <View style={styles.inputContainer}>
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>
                {strings('settingScreen.clientSetting.inputTitle.appName')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                keyboardType="number-pad"
                autoCorrect={false}
                placeholder={strings(
                  'settingScreen.clientSetting.placeholder.appName',
                )}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                value={appName}
                editable={false}
                style={
                  Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
                }
              />
            </View>
          </View>

          <View style={styles.inputContainer}>
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>
                {strings(
                  'settingScreen.clientSetting.inputTitle.androidPackage',
                )}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                keyboardType="number-pad"
                autoCorrect={false}
                placeholder={strings(
                  'settingScreen.clientSetting.placeholder.androidPackage',
                )}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                value={androidPackageName}
                editable={false}
                style={
                  Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
                }
              />
            </View>
          </View>
          <View style={styles.inputContainer}>
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>
                {strings('settingScreen.clientSetting.inputTitle.iosBundle')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                keyboardType="number-pad"
                autoCorrect={false}
                placeholder={strings(
                  'settingScreen.clientSetting.placeholder.iosBundle',
                )}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                value={iosBundleIdentifier}
                editable={false}
                style={
                  Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
                }
              />
            </View>
          </View>

          {/* ------------ Authentication Configuration ------------ */}
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>{'Authentication Type'}</AppText>
            <DropDownInput
              data={[
                // {value: 'text', label: 'Select Type'},
                {value: 'phone', label: 'Phone'},
                {value: 'email', label: 'Email'},
                // {value: 'phone-and-email', label: 'Phone and Email'},
              ]}
              value={authentication_type}
              inputStyle={{
                paddingHorizontal: 10,
                marginVertical: Platform.OS == 'web' ? 5 : 7,
              }}
              onChangeText={(itemValue) => {
                setAuthenticationType(itemValue);
              }}
            />
          </View>

          {/* ---------------------- App Coin ---------------------- */}
          <AppConfigCheckbox
            title={strings('settingScreen.clientSetting.checkbox.coin')}
            desc={strings('settingScreen.clientSetting.checkbox.coinDesc')}
            checked={enableCoin}
            onChangeCheck={(checked) => {
              setEnableCoin(checked);
            }}
          />

          {/* --------------- Home screen configuration -------------- */}
          <TouchableOpacity
            style={styles.settingView}
            onPress={() => {
              props?.navigation.navigate('home-page-config');
            }}
          >
            <AppText style={styles.title}>{'Home page config'}</AppText>
            <Icon name="forward" size={20} />
          </TouchableOpacity>

          {/* ----------- Tab bar configuration ------------ */}
          {/* <AppConfigCheckbox
              title={strings('settingScreen.clientSetting.checkbox.tabBar')}
              desc={strings('settingScreen.clientSetting.checkbox.tabBarDesc')}
              checked={theme.showTabBar}
              onChangeCheck={(checked) => {
                 setTheme({
                  ...theme,
                  showTabBar: checked
                })
              }}
            /> */}

          {/* ------------------ login required configuration  --------  */}

          {/* <AppConfigCheckbox
              title={strings(
                'settingScreen.clientSetting.checkbox.loginRequire',
              )}
              desc={strings(
                'settingScreen.clientSetting.checkbox.loginRequireDesc',
              )}
              checked={theme.isLoginRequired}
              onChangeCheck={(checked) => {
                setTheme({
                  ...theme,
                  isLoginRequired: checked
                })
              }}
            /> */}

          {/* ----------------------- Screen Background Layout ---------------------*/}
          {Platform.OS !== 'web' && (
            <>
              <View
                style={{
                  height: 200,
                  flexDirection: 'row',
                  marginBottom: 10,
                }}
              >
                <TouchableOpacity
                  style={[styles.backgroundLayoutCard, {marginRight: 5}]}
                  onPress={() => {
                    setOval(true);
                    setGradient(false);
                    setTheme({
                      ...theme,
                      screenBackground: 'oval',
                    });
                  }}
                >
                  {oval && (
                    <View
                      style={{
                        position: 'absolute',
                        zIndex: 1111,
                        backgroundColor: 'rgba(0,0,0,0.1)',
                        justifyContent: 'center',
                        height: '100%',
                        width: '100%',
                        borderRadius: 5,
                        alignItems: 'center',
                      }}
                    >
                      <MaterialIcons
                        name="done"
                        size={40}
                        color={Colors.black60}
                      />
                    </View>
                  )}
                  <View
                    style={{
                      backgroundColor: Colors.theme_color,
                      width: '100%',
                      height: '55%',
                      borderTopRightRadius: 5,
                      borderTopLeftRadius: 5,
                      borderBottomRightRadius: 100,
                      borderBottomLeftRadius: 100,
                    }}
                  />
                  <View
                    style={{
                      flex: 0.7,
                      justifyContent: 'center',
                      alignSelf: 'center',
                    }}
                  >
                    <Text style={{fontFamily: 'Roboto-Bold'}}>Oval</Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[styles.backgroundLayoutCard, , {marginLeft: 5}]}
                  onPress={() => {
                    setGradient(true);
                    setOval(false);
                    setTheme({
                      ...theme,
                      screenBackground: 'gradient',
                    });
                  }}
                >
                  {gradient && (
                    <View
                      style={{
                        position: 'absolute',
                        zIndex: 1111,
                        backgroundColor: 'rgba(0,0,0,0.1)',
                        justifyContent: 'center',
                        height: '100%',
                        borderRadius: 5,
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <MaterialIcons
                        name="done"
                        size={40}
                        color={Colors.black60}
                      />
                    </View>
                  )}
                  <LinearGradient
                    colors={[Colors.theme_color, Colors.white]}
                    style={{
                      flex: 1,
                      borderRadius: 5,
                      justifyContent: 'flex-end',
                    }}
                    start={[0, 0]}
                    end={[0, 0.7]}
                  >
                    <View
                      style={{
                        flex: 0.5,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Text style={{fontFamily: 'Roboto-Bold'}}>Gradient</Text>
                    </View>
                  </LinearGradient>
                </TouchableOpacity>
              </View>
            </>
          )}

          {/*------------ Dark Mode */}
          {(config?.ENV == 'local' || config?.ENV == 'development') && (
            <AppConfigCheckbox
              title={strings('settingScreen.clientSetting.checkbox.dark')}
              desc={strings('settingScreen.clientSetting.checkbox.darkDesc')}
              checked={theme.dark}
              onChangeCheck={(checked) => {
                setTheme({
                  ...theme,
                  dark: checked,
                });
              }}
            />
          )}

          {/* <View style={styles.inputContainer}>
              <View style={styles.textInputContainer}>
                <AppText style={styles.inputLabel}>
                  {strings('settingScreen.clientSetting.inputTitle.primary')}
                </AppText>
                <TextInput
                  underlineColorAndroid="transparent"
                  keyboardType="number-pad"
                  autoCorrect={false}
                  placeholder={strings(
                    'settingScreen.clientSetting.placeholder.primary',
                  )}
                  placeholderTextColor={Colors.color30}
                  returnKeyType="done"
                  editable={false}
                  value={theme.primaryColor}
                  onChangeText={(text) => {
                    setTheme({
                      ...theme,
                      primaryColor: text
                    })
                  }}
                  style={
                    Platform.OS == 'web'
                      ? styles.textInputWeb
                      : styles.textInput
                  }
                />
              </View>
            </View> 
            <View style={styles.inputContainer}>
              <View style={styles.textInputContainer}>
                <AppText style={styles.inputLabel}>
                  {strings('settingScreen.clientSetting.inputTitle.secondary')}
                </AppText>
                <TextInput
                  underlineColorAndroid="transparent"
                  keyboardType="number-pad"
                  autoCorrect={false}
                  placeholder={strings(
                    'settingScreen.clientSetting.placeholder.secondary',
                  )}
                  placeholderTextColor={Colors.color30}
                  returnKeyType="done"
                  editable={false}
                  value={theme.secondaryColor}
                  onChangeText={(text) => {
                    setTheme({
                      ...theme,
                      secondaryColor: text
                    })
                  }}
                  style={
                    Platform.OS == 'web'
                      ? styles.textInputWeb
                      : styles.textInput
                  }
                />
              </View>
            </View> 
            <View style={styles.inputContainer}>
              <View style={styles.textInputContainer}>
                <AppText style={styles.inputLabel}>
                  {strings('settingScreen.clientSetting.inputTitle.theme')}
                </AppText>
                <TextInput
                  underlineColorAndroid="transparent"
                  keyboardType="number-pad"
                  autoCorrect={false}
                  placeholder={strings(
                    'settingScreen.clientSetting.placeholder.theme',
                  )}
                  placeholderTextColor={Colors.color30}
                  returnKeyType="done"
                  editable={false}
                  value={theme.themeColor}
                  onChangeText={(text) => {
                    setTheme({
                      ...theme,
                      themeColor: text
                    })
                  }}
                  style={
                    Platform.OS == 'web'
                      ? styles.textInputWeb
                      : styles.textInput
                  }
                />
              </View>
            </View> */}
          {/* )} */}

          {Platform.OS == 'web' && (
            <LongButton
              text={strings('settingScreen.clientSetting.save')}
              style={{marginTop: setValue(10), marginBottom: setValue(45)}}
              onPress={saveConfigData}
            />
          )}
        </View>
      </MainContainer>
      {Platform.OS !== 'web' && (
        <LongButton
          text={strings('settingScreen.clientSetting.save')}
          style={{
            width: '100%',
            borderRadius: 0,
          }}
          onPress={saveConfigData}
        />
      )}
    </View>
  );
};

export default ClientAppSetting;
