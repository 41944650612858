import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    flex: 1,
  },
  overlay: {
    flex: 1,
    backgroundColor: Colors.overlay,
    alignItems: 'center',
    justifyContent: 'center',
  },
  alertContainer: {
    shadowColor: Colors.theme_color,
    maxWidth:350,
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 10,
    elevation: 20,
    backgroundColor: Colors.white,
    justifyContent: 'center',
    alignSelf: 'center',
    borderRadius: 10,
    width: '85%',
    marginBottom: setYAxisValue(40),
    padding: setValue(2),
  },
  button: {
    width: '95%',
    marginLeft: setYAxisValue(10),
    marginRight: setYAxisValue(10),
    marginBottom: setYAxisValue(10),
    backgroundColor: Colors.theme_color,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    paddingVertical: setYAxisValue(10),
  },
  okText: {
    fontSize: setXAxisValue(14),
    // //
    color: Colors.white,
    textTransform: 'uppercase',
  },
  information: {
    marginTop: setYAxisValue(10),
    marginBottom: setYAxisValue(5),
  },
  title: {
    textAlign: 'center',
    fontSize: setXAxisValue(16),
    color: Colors.black20,
    // fontFamily: ''
  },
  content: {
    fontSize: setXAxisValue(14),
    color: Colors.black20,
    marginBottom: setYAxisValue(20),
    marginTop: setYAxisValue(20),
    textAlign: 'center',
  },
  button2: {
    width: '45%',
    backgroundColor: Colors.theme_color,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    paddingVertical: setYAxisValue(10),
  },
  button2Container: {
    marginTop: setYAxisValue(10),
    marginBottom: setYAxisValue(10),
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
  },
  images: {
    marginVertical: setYAxisValue(40),
    width: setXAxisValue(120),
    height: setXAxisValue(120),
    alignSelf: 'center',
  },
});
