import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import {AppText, Icon, MainContainer, MainHeader} from '../../components';
import {strings} from '../../i18n';
import {goBack} from '../../utils';
import styles from './styles';

export default function OfflineOrderSetting(props) {
  return (
    <View style={styles.container}>
      <MainHeader
        title={"Invoice Setting"}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer useView>
        <TouchableOpacity
          style={styles.settingView}
          onPress={() =>props.navigation.navigate("select-default-template")}
        >
          <AppText style={styles.title}>
            {"Default Template"}
          </AppText>
          <Icon name="forward" size={20} />
        </TouchableOpacity>
      </MainContainer>
    </View>
  );
}
