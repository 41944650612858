import AsyncStorage from '@react-native-async-storage/async-storage';
import NoConfigFound from '../components/NoConfigFound';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import * as React from 'react';
import {useEffect, useState} from 'react';
import get from 'lodash.get';
// import * as Segment from 'expo-analytics-segment';

// screen import
import EditProductScreen from '../screens/Product/EditProduct';
import ContactUsScreen from '../screens/ContactUsScreen';
import FAQScreen from '../screens/FAQScreen';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';
import HomeScreen from '../screens/HomeScreen';
import IntroductionScreen from '../screens/IntroductionScreen';
import MyQRScreen from '../screens/MyQRScreen';
import NotificationDetailScreen from '../screens/Notification/NotificationDetailScreen';
import NotificationScreen from '../screens/Notification/NotificationScreen';
import OrderHistoryDetailScreen from './OrderHistoryDetailScreen';
import VerifyOtpScreen from '../screens/VerifyOtpScreen';
import OrderHistoryScreen from './OrderHistoryScreen';
import ProductDetailScreen from '../screens/ProductDetailScreen';
import ProductListScreen from '../screens/ProductListScreen';
import EditProfileScreen from '../screens/Profile/EditProfileScreen';
import ProfileScreen from '../screens/Profile/ProfileScreen';
import UpdateEmailScreen from '../screens/Profile/UpdateEmailScreen';
import UpdatePhoneScreen from '../screens/Profile/UpdatePhoneScreen';
import PromotionDetailScreen from '../screens/Promotion/PromotionDetailScreen';
import PromotionListScreen from '../screens/Promotion/PromotionListScreen';
import RegisterScreen from '../screens/RegisterScreen';
import SettingScreen from '../screens/SettingScreen';
import SupportScreen from '../screens/SupportScreen';
import TermAndPolicyScreen from '../screens/TermAndPolicyScreen';
import Account from '../screens/Account';
// import {TabBar} from '../components';
import LoginScreen from './LoginScreen';
import {AppState, Platform} from 'react-native';
import ChangePasswordScreen from './ChangePasswordScreen';
import EditAddressScreen from './AddressScreen/EditAddressScreen';
import AddressListScreen from './AddressScreen/AddressList';
import Customer from './Customer';
import BusinessEditInfo from '../screens/Business/EditInformation';
import BusinessOverview from '../screens/Business/Overview';
import EditProductCategory from './Product/EditProductCategory';
import ChooseOrderType from '../screens/ChooseOrderType';
import SelectPickUpOrderConfig from '../screens/SelectPickUpOrderConfig';
import CustomerDetail from './CustomerDetail';
import ClientAppSetting from './ClientAppSetting';
import EmailConfiguration from './EmailConfiguration';
import LiveSupport from './LiveSupport';
import ActiveChatList from './ActiveChatList';
import ManageCategory from './ManageCategory';
import OrderSetting from './OrderSetting';
import CreatePromotion from './Promotion/CreatePromotion';
import ShopTimingSetting from './ShopTimingSetting';
import EditNotification from './Notification/EditNotification';
import MerchantOrderList from './MerchantOrderList';
import SelectTopProduct from './Product/SelectTopProduct';
import NotificationSetting from './NotificationSetting';
import OrderPromotionList from './OrderPromotionList';
import PaymentConfigList from './PaymentSetting/PaymentList';
import AddPaymentConfig from './PaymentSetting/AddPaymentMethod';
import ScanOrderQr from './ScanOrderQr';
import {useSelector} from 'react-redux';
import ScanCustomersQr from './ScanCustomersQr';
import {screenWidth} from '../utils';
import ProductSetting from './ProductSetting';
import ScanProductBarcode from './ScanProductBarcode';
import PickupLocationListScreen from './PickupLocation/PickupLocationList';
import EditPickupLocationScreen from './PickupLocation/EditPickupLocation';
import CurrencySetting from './CurrencySetting';
import ResetPasswordScreen from './ResetPasswprd';
import ManageIntroduction from './IntroductionSetting';
import EditServicesScreen from './Services/EditServices';
import AddNewServiceCategory from './Services/EditServicesCategories';
import ManageServicesCategoryScreen from './ManageServicesCategory';
import ServicesListScreen from './ServicesListScreen';
import ServiceDetailScreen from './ServiceDetailScreen';
import ServiceSettingScreen from './Services/ServicesSetting';
import BusinessDetail from './Business/BusinessDetail';
import Reports from './Reports';
import FranchiseList from './Franchise/FranchiseList';
import EditFranchise from './Franchise/EditFranchise';
import LocationMap from './LocationMap';
import RatingReview from './RatingReview';
import NimbusDelivery from './NimbusDelivery';
import MenuScreen from './Menu';
import MenuDetailScreen from './MenuDetailScreen';
import EditMenuScreen from './EditMenu';
import CoinDetailScreen from './CoinDetail';
import PendingApprovement from './PendingApprovement';
import EditSubCategory from './Product/EditSubCategory';
import SendCoinScreen from './SendCoin';
import ProductFilter from './ProductListScreen/productFilter';
import ContactUs from './ContactUs';
import DeleteConfirmation from './DeleteConfirmation';
import ShipRocketDelivery from './ShiprocketDelivery';
import OfflineOrderList from './OfflineOrder/OrderList';
import EditOfflineOrder from './OfflineOrder/EditOfflineOrder';
import SelectCustomerFromList from './OfflineOrder/SelectCustomerFromList';
import SelectProductFromList from './OfflineOrder/SelectProductFromList';
import ScanDeliveryPartner from './ScanDeliveryPartner';
import RefundsPolicyScreen from './RefundsPolicyScreen';
import ShippingPolicyScreen from './ShippingPolicyScreen';
import AboutUSScreen from './AboutUSScreen';
import CancelOrderList from './CancelOrderList';
import CreateBusiness from './CreateBusiness';
import ThankYouScreen from './ThankYou';
import SocialMediaLinksScreen from './SocialMediaLinksScreen';
//new
import OfflineOrderSetting from './OfflineOrderSetting';
import SelectDefaultTemplate from './OfflineOrderSetting/SelectDefaultTemplate';
import MerchantCoinTransactionList from './MerchantCoinTrasactionList';
import HomePageConfig from './HomePageConfig';
import HomeSlider from './HomeSlider';
import BannerCard from './BannerCard';
import BlogList from './Blogs/BlogsList';
import AddNewBlog from './Blogs/EditBlog';
import BlogDetail from './Blogs/BlogDetail';
import BlogCategoryList from './Blogs/BlogCategoryList';
import EditBlogCategory from './Blogs/EditBlogCategory';
import GalleryList from './Gallery/GalleryList';
import GalleryDetail from './Gallery/GalleryDetail';
import EditGallery from './Gallery/EditGallery';
import DeliveryPartnerConfig from './DeliveryPartnerConfig';
import EditDeliveryPartner from './DeliveryPartnerConfig/EditDeliveryPartner';
import FloorList from './TableConfiguration/FloorList';
import AddNewFloor from './TableConfiguration/EditFloor';
import AddNewRoom from './TableConfiguration/EditRoom';
import AddNewTable from './TableConfiguration/EditTable';
import NimbusOrderCreate from './NimbusOrderCreate';
import ShipRocketOrderCreate from './ShiprocketOrderCreate';
import SubscriptionSetting from './SubscriptionSetting';
import TableSetting from './TableConfiguration/TableSetting';
import MerchantTableBookingList from './MerchantTableBookingList';
import MerchantSubscriptionList from './MerchantSubscriptionList';
import SubscriptionDetail from './SubscriptionDetail';
import TableBookingDetail from './TableBookingDetail';
import OfflineOrderDetail from './OfflineOrderDetail';
import SubscriptionDelivery from './SubscriptionDelivery';
import Hospitality from './Hospitality/Hospitality';
import AddNewAccommodation from './Hospitality/EditAccommodation';
import AccommodationSetting from './Hospitality/AccommodationSetting';
import ExperienceList from './Hospitality/Experience/ExpirenceList';
import AddNewExperience from './Hospitality/Experience/EditExperience';
import FeaturesList from './Hospitality/Features/FeaturesList';
import AddNewFeatures from './Hospitality/Features/EditFeatures';
import BusinessInvoiceList from './BusinessInvoiceList';
import PayStripeScreen from './PayStripeScreen';
import OfflineOrderPrintPreview from './OfflineOrderPrintPreview';
import Bookings from './Bookings';
import DonationConfiguaration from './DonationConfiguration';
import ReferralConfiguaration from './ReferralSetting';
import RedeemCoinConfiguaration from './RedeemCoinSettings';
import VariantTypes from './VariantType';
import EditVariantTypes from './EditVariantTypes';
import EditVariant from './EditVariant';
import RateCodeList from './Hospitality/RateCode/RateCodeList';
import EditRateCode from './Hospitality/RateCode/EditRateCode';
import MerchantDonationList from './MerchantDonationList';

const BottomTab = createBottomTabNavigator();
function BottomTabNavigator(props) {
  const config = useSelector((state) => state.config);
  const isLogin = useSelector((state) => get(state.auth.user, 'id', false));

  return (
    <BottomTab.Navigator screenOptions={{headerShown: false}}>
      <BottomTab.Screen
        name="home"
        component={HomeScreen}
        options={{title: config?.businessName}}
      />
      <BottomTab.Screen
        name="products"
        component={ProductListScreen}
        options={{title: 'Products'}}
      />
      {isLogin && (
        <BottomTab.Screen
          name="account"
          component={Account}
          options={{title: 'Account'}}
        />
      )}
      <BottomTab.Screen
        name="notifications"
        component={NotificationScreen}
        options={{title: 'Notifications'}}
      />

      <BottomTab.Screen
        name="offer"
        component={PromotionListScreen}
        options={{title: 'Top Offers'}}
      />
    </BottomTab.Navigator>
  );
}

const MainStack = createStackNavigator();
function AppNavigator(props) {
  const setting = useSelector((state) => state.config);
  const isLogin = useSelector((state) => get(state.auth.user, 'id', false));
  const user = useSelector((state) => state.auth.user);

  if (!setting?.domain) {
    return <NoConfigFound />;
  }

  let linking = {
    // prefixes: ['partsportal://', 'https://partsportal.in/'],
    config: {
      screens: {
        home: '',
        productDetail: {path: 'product/:slug/:id'},
        products: {path: 'products'},
        'offline-orders': {path: 'offline-orders'},
        account: {path: 'account'},
        notifications: {path: 'notification'},
        'product-filter': {path: 'product-filter'},
        'select-customer': {path: 'select-customer'},
        'select-products': {path: 'select-products'},
        'active-chat-list': {path: 'active-chat-list'},
        'live-support': {path: 'live-support/:id/:chatId'},
        'edit-address': {path: 'edit-address'},
        'address-list': {path: 'address-list'},
        'pickup-location-list': {path: 'pickup-location-list'},
        'edit-pickup-location': {path: 'edit-pickup-location'},
        customers: {path: 'customers'},
        'selected-top-products': {path: 'selected-top-products'},
        'manage-category': {path: 'manage-category'},
        'business-details': {path: 'business-details'},
        'order-setting': {path: 'order-setting'},
        'payment-configuration-list': {path: 'payment-configuration-list'},
        'product-setting': {path: 'product-setting'},
        'currency-setting': {path: 'currency-setting'},
        'edit-business-info': {path: 'edit-business-info'},
        'business-overview': {path: 'business-overview'},
        'rating-review': {path: 'product/:slug/rate-and-review/:id'},
        'edit-product': {path: 'edit-product'},
        'edit-products-category': {path: 'edit-products-category'},
        'edit-sub-category': {path: 'edit-sub-category'},
        'services-list': {path: 'services-list'},
        'manage-services-category': {path: 'manage-services-category'},
        'edit-services': {path: 'edit-services'},
        'edit-services-category': {path: 'edit-services-category'},
        'Service Setting': {path: 'Service Setting'},
        'choose-order-type': {path: 'choose-order-type'},
        'scan-product-barcode': {path: 'scan-product-barcode'},
        'pickup-order-config': {path: 'pickup-order-config'},
        'customer-detail': {path: 'customer-detail'},
        about: {path: 'about'},
        'change-password': {path: 'change-password'},
        'contact-us': {path: 'contact-us'},
        'edit-profile': {path: 'edit-profile'},
        faq: {path: 'faq'},
        'my-qr': {path: 'my-qr'},
        'order-history': {path: 'order-history'},
        'order-history-detail': {path: 'order-history-detail/:id'},
        'merchant-order-list': {path: 'merchant-order-list'},
        'notification-settings': {path: 'notification-settings'},
        support: {path: 'support'},
        login: {path: 'login'},
        register: {path: 'register'},
        'forgot-password': {path: 'forgot-password'},
        'reset-password': {path: 'reset-password'},
        payment: {path: 'payment/:id'},
        'menu-list': {path: 'menu-list'},
        'menu-detail': {path: 'menu-detail/:slug/:id'},
        'edit-menu': {path: 'edit-menu/:id'},
        'term-policy': {path: 'term-policy'},
        'send-coins': {path: 'send-coins'},
        'delete-confirmation': {path: 'delete/:id/:token'},
        'create-promotion': {path: 'create-offer'},
        'cancel-order-list': {path: 'cancel-order-list'},
      },
    },
    enabled: true,
  };

  return (
    <NavigationContainer
      linking={linking}
      ref={props.navRef}
      onStateChange={(state) => {
        // let screenName = state.routes[state.routes.length - 1]?.name;
        // Platform.OS != 'web' && Segment.screen(screenName);
      }}
    >
      <MainStack.Navigator screenOptions={{headerShown: false, title: false}}>
        <>
          <MainStack.Screen
            name="home"
            component={HomeScreen}
            options={{title: setting?.businessName}}
          />
          <MainStack.Screen
            name="notifications"
            component={NotificationScreen}
            options={{title: 'Notifications'}}
          />
          <MainStack.Screen
            name="offer"
            component={PromotionListScreen}
            options={{title: 'Top Offers'}}
          />
          <MainStack.Screen
            name="products"
            component={ProductListScreen}
            options={{title: 'Products'}}
          />
          <MainStack.Screen
            name="account"
            component={Account}
            options={{title: 'Account'}}
          />
        </>
        <MainStack.Screen
          name="product-filter"
          component={ProductFilter}
          options={{title: 'Filter'}}
        />
        <MainStack.Screen
          name="active-chat-list"
          component={ActiveChatList}
          options={{title: 'Chat List'}}
        />
        <MainStack.Screen
          name="live-support"
          component={LiveSupport}
          options={{title: 'Chat'}}
        />
        <MainStack.Screen
          name="delete-confirmation"
          options={{title: 'Delete Confirmation'}}
          component={DeleteConfirmation}
        />
        <MainStack.Screen
          name="edit-address"
          options={{title: 'Edit Address'}}
          component={EditAddressScreen}
        />
        <MainStack.Screen
          name="address-list"
          options={{title: 'Address List'}}
          component={AddressListScreen}
        />
        <MainStack.Screen
          name="pickup-location-list"
          options={{title: 'Pickup Location List'}}
          component={PickupLocationListScreen}
        />
        <MainStack.Screen
          options={{title: 'Edit Pickup Location'}}
          name="edit-pickup-location"
          component={EditPickupLocationScreen}
        />
        <MainStack.Screen
          name="customers"
          options={{title: 'Customers'}}
          component={Customer}
        />
        <MainStack.Screen
          name="offline-orders"
          options={{title: 'Offlin Orders'}}
          component={OfflineOrderList}
        />
        <MainStack.Screen
          name="select-customer"
          options={{title: 'Select Customer'}}
          component={SelectCustomerFromList}
        />
        <MainStack.Screen
          name="select-products"
          options={{title: 'Select Products'}}
          component={SelectProductFromList}
        />
        <MainStack.Screen
          name="add-offline-order"
          options={{title: 'Add Order'}}
          component={EditOfflineOrder}
        />
        <MainStack.Screen
          options={{title: 'Edit Notification'}}
          name="edit-notifications"
          component={EditNotification}
        />
        <MainStack.Screen
          options={{title: 'Select Top Products'}}
          name="selected-top-products"
          component={SelectTopProduct}
        />
        <MainStack.Screen
          options={{title: 'Rating and Review'}}
          name="rating-review"
          component={RatingReview}
        />
        <MainStack.Screen
          name="manage-category"
          options={{title: 'Manage Category'}}
          component={ManageCategory}
        />
        <MainStack.Screen
          name="business-details"
          options={{title: 'Business Detail'}}
          component={BusinessDetail}
        />
        <MainStack.Screen
          name="order-setting"
          options={{title: 'Order Setting'}}
          component={OrderSetting}
        />
        <MainStack.Screen
          name="cancel-order-list"
          options={{title: 'Cancel Orders'}}
          component={CancelOrderList}
        />
        <MainStack.Screen
          name="payment-configuration-list"
          options={{title: 'Payment Setting'}}
          component={PaymentConfigList}
        />
        <MainStack.Screen
          name="add-payment-configuration"
          options={{title: 'Payment Setting'}}
          component={AddPaymentConfig}
        />
        <MainStack.Screen
          name="nimbus-delivery"
          options={{title: 'Product-delivery'}}
          component={NimbusDelivery}
        />
        <MainStack.Screen
          name="product-setting"
          options={{title: 'Product Setting'}}
          component={ProductSetting}
        />
        <MainStack.Screen
          name="currency-setting"
          options={{title: 'Currency Setting'}}
          component={CurrencySetting}
        />

        <MainStack.Screen
          name="location-map"
          options={{title: 'Location Map'}}
          component={LocationMap}
        />

        <MainStack.Screen
          name="edit-business-info"
          options={{title: 'Edit Business Info'}}
          component={BusinessEditInfo}
        />
        <MainStack.Screen
          name="business-overview"
          options={{title: 'Business Overview'}}
          component={BusinessOverview}
        />
        <MainStack.Screen
          options={{title: 'Edit Product'}}
          name="edit-product"
          component={EditProductScreen}
        />
        <MainStack.Screen
          options={{title: 'Edit Product Category'}}
          name="edit-products-category"
          component={EditProductCategory}
        />
        <MainStack.Screen
          options={{title: 'Edit Sub Category'}}
          name="edit-sub-category"
          component={EditSubCategory}
        />
        <MainStack.Screen
          options={{title: 'Service Details'}}
          name="services-details"
          component={ServiceDetailScreen}
        />
        <MainStack.Screen
          options={{title: 'Edit Menu'}}
          name="edit-menu"
          component={EditMenuScreen}
        />
        <MainStack.Screen
          options={{title: 'Social Media Links'}}
          name="social-media-link"
          component={SocialMediaLinksScreen}
        />
        <MainStack.Screen
          options={{title: 'Coins'}}
          name="coin-detail"
          component={CoinDetailScreen}
        />

        <MainStack.Screen
          name="menu-list"
          options={{title: 'Menu'}}
          component={MenuScreen}
        />
        <MainStack.Screen
          options={{title: 'Menu Details'}}
          name="menu-detail"
          component={MenuDetailScreen}
        />
        <MainStack.Screen
          name="services-list"
          options={{title: 'Service List'}}
          component={ServicesListScreen}
        />
        <MainStack.Screen
          name="manage-services-category"
          options={{title: 'Manage Service Category'}}
          component={ManageServicesCategoryScreen}
        />
        <MainStack.Screen
          name="edit-services"
          options={{title: 'Edit Service'}}
          component={EditServicesScreen}
        />
        <MainStack.Screen
          name="edit-services-category"
          options={{title: 'Edit Service category'}}
          component={AddNewServiceCategory}
        />
        <MainStack.Screen
          options={{title: 'Service Setting'}}
          name="service-setting"
          component={ServiceSettingScreen}
        />
        <MainStack.Screen
          options={{title: 'Choose Order Type'}}
          name="choose-order-type"
          component={ChooseOrderType}
        />
        <MainStack.Screen
          name="scan-delivery-partner"
          options={{title: 'Scan Delivery Partner'}}
          component={ScanDeliveryPartner}
        />
        <MainStack.Screen
          options={{title: 'Scan Product Barcode'}}
          name="scan-product-barcode"
          component={ScanProductBarcode}
        />
        <MainStack.Screen
          name="pickup-order-config"
          options={{title: 'Select PickUp Order Config'}}
          component={SelectPickUpOrderConfig}
        />
        <MainStack.Screen
          name="customer-detail"
          options={{title: 'Customer Detail'}}
          component={CustomerDetail}
        />

        <MainStack.Screen
          name="change-password"
          options={{title: 'Change Password'}}
          component={ChangePasswordScreen}
        />
        <MainStack.Screen
          name="notification-detail"
          options={{title: 'Notification Detail'}}
          component={NotificationDetailScreen}
        />
        <MainStack.Screen
          name="contact-us"
          options={{title: 'Contact Us'}}
          component={ContactUs}
        />
        <MainStack.Screen
          name="edit-profile"
          options={{title: 'Edit Profile'}}
          component={EditProfileScreen}
        />
        <MainStack.Screen
          name="faq"
          options={{title: 'FAQ'}}
          component={FAQScreen}
        />
        <MainStack.Screen
          name="my-qr"
          options={{title: 'My QR'}}
          component={MyQRScreen}
        />
        <MainStack.Screen
          name="order-history"
          options={{title: 'Order History'}}
          component={OrderHistoryScreen}
        />
        <MainStack.Screen
          name="merchant-order-list"
          options={{title: 'Merchant Order List'}}
          component={MerchantOrderList}
        />
        <MainStack.Screen
          name="notification-settings"
          options={{title: 'Notification Setting'}}
          component={NotificationSetting}
        />
        <MainStack.Screen
          name="order-history-detail"
          options={{title: 'Order History Detail'}}
          component={OrderHistoryDetailScreen}
        />
        <MainStack.Screen
          name="scan-order-qr"
          options={{title: 'Scan Order Qr'}}
          component={ScanOrderQr}
        />
        <MainStack.Screen
          name="scan-customers-qr"
          options={{title: 'Scan Customers Qr'}}
          component={ScanCustomersQr}
        />
        <MainStack.Screen
          name="productDetail"
          options={{title: ''}}
          component={ProductDetailScreen}
        />
        <MainStack.Screen
          name="profile"
          options={{title: 'Profile'}}
          component={ProfileScreen}
        />
        <MainStack.Screen
          name="offer-detail"
          options={{title: 'Offer Detail'}}
          component={PromotionDetailScreen}
        />
        <MainStack.Screen
          name="setting"
          options={{title: 'Setting'}}
          component={SettingScreen}
        />
        <MainStack.Screen
          name="support"
          options={{title: 'Support'}}
          component={SupportScreen}
        />
        <MainStack.Screen
          name="term-policy"
          options={{title: 'Term and Policy'}}
          component={TermAndPolicyScreen}
        />
        <MainStack.Screen
          name="refund-policy"
          options={{title: 'Refunds Policy'}}
          component={RefundsPolicyScreen}
        />
        <MainStack.Screen
          name="shipping-policy"
          options={{title: 'Shipping Policy'}}
          component={ShippingPolicyScreen}
        />
        <MainStack.Screen
          name="about-us"
          options={{title: 'About us'}}
          component={AboutUSScreen}
        />
        <MainStack.Screen
          name="update-email"
          options={{title: 'Update Email'}}
          component={UpdateEmailScreen}
        />
        <MainStack.Screen
          name="update-phone"
          options={{title: 'Update Phone Number'}}
          component={UpdatePhoneScreen}
        />
        <MainStack.Screen
          name="introduction"
          options={{title: 'Introduction'}}
          component={IntroductionScreen}
        />

        <MainStack.Screen
          name="create-promotion"
          options={{title: 'Create Offer'}}
          component={CreatePromotion}
        />
        <MainStack.Screen
          name="order-offer-list"
          options={{title: 'Order Offer List'}}
          component={OrderPromotionList}
        />
        <MainStack.Screen
          name="client-setting"
          options={{title: 'Client App Setting'}}
          component={ClientAppSetting}
        />
        <MainStack.Screen
          name="email-configuration"
          options={{title: 'Email Configuration'}}
          component={EmailConfiguration}
        />
        <MainStack.Screen
          name="shop-time-setting"
          options={{title: 'Shop Timing Setting'}}
          component={ShopTimingSetting}
        />
        <MainStack.Screen
          name="franchise"
          options={{title: 'Franchise List'}}
          component={FranchiseList}
        />
        <MainStack.Screen
          name="edit-franchise"
          options={{title: 'Edit Franchise'}}
          component={EditFranchise}
        />
        <MainStack.Screen
          name="introduction-setting"
          options={{title: 'Manage Introduction'}}
          component={ManageIntroduction}
        />

        {!isLogin && (
          <MainStack.Screen
            name="login"
            options={{title: 'Login'}}
            component={LoginScreen}
          />
        )}

        <MainStack.Screen
          name="register"
          options={{title: 'Register'}}
          component={RegisterScreen}
        />
        <MainStack.Screen
          name="forgot-password"
          options={{title: 'Forgot Password'}}
          component={ForgotPasswordScreen}
        />
        <MainStack.Screen
          name="reset-password"
          options={{title: 'Reset Password'}}
          component={ResetPasswordScreen}
        />
        <MainStack.Screen
          name="verify-otp"
          options={{title: 'Verify OTP'}}
          component={VerifyOtpScreen}
        />
        <MainStack.Screen
          name="reports"
          options={{title: 'Reports'}}
          component={Reports}
        />
        <MainStack.Screen
          name="send-coins"
          options={{title: 'send-coins'}}
          component={SendCoinScreen}
        />
        <MainStack.Screen
          name="pending-approvement"
          component={PendingApprovement}
          options={{title: 'Pending Approvement'}}
        />
        <MainStack.Screen
          name="ship-rocket-delivery"
          component={ShipRocketDelivery}
          options={{title: 'ShipRocket Delivery'}}
        />

        {/* new added  */}
        <MainStack.Screen
          name="offline-order-settings"
          component={OfflineOrderSetting}
          options={{title: 'Offline Order Setting'}}
        />

        <MainStack.Screen
          name="select-default-template"
          component={SelectDefaultTemplate}
          options={{title: 'Select Default Templates'}}
        />

        <MainStack.Screen
          name="merchant-coin-transaction-list"
          component={MerchantCoinTransactionList}
          options={{title: 'Coin Transaction List'}}
        />
        <MainStack.Screen
          name="home-page-config"
          component={HomePageConfig}
          options={{title: 'Home Page Configuration'}}
        />
        <MainStack.Screen
          name="home-slider"
          options={{title: 'Home Page Slider'}}
          component={HomeSlider}
        />
        <MainStack.Screen
          name="banner-card"
          options={{title: 'Banner Card'}}
          component={BannerCard}
        />
        {/* blogs */}
        <MainStack.Screen
          name="blogs"
          options={{title: 'Blogs'}}
          component={BlogList}
        />
        <MainStack.Screen
          name="edit-blog"
          options={{title: 'Edit Blog'}}
          component={AddNewBlog}
        />
        <MainStack.Screen
          name="blog-detail"
          options={{title: 'Blog Detail'}}
          component={BlogDetail}
        />
        <MainStack.Screen
          name="blog-category-list"
          options={{title: 'Blog Category List'}}
          component={BlogCategoryList}
        />
        <MainStack.Screen
          name="edit-blog-category"
          options={{title: 'Edit Blog Category'}}
          component={EditBlogCategory}
        />
        {/* gallery */}
        <MainStack.Screen
          name="gallery"
          options={{title: 'Gallery'}}
          component={GalleryList}
        />
        <MainStack.Screen
          name="edit-gallery"
          options={{title: 'Edit Gallery'}}
          component={EditGallery}
        />
        <MainStack.Screen
          name="gallery-detail"
          options={{title: 'Gallery Detail'}}
          component={GalleryDetail}
        />
        {/* delivery partner configs  */}
        <MainStack.Screen
          name="delivery-partners"
          options={{title: 'Delivery Partners'}}
          component={DeliveryPartnerConfig}
        />
        <MainStack.Screen
          name="edit-delivery-partner"
          options={{title: 'Edit Delivery Partners'}}
          component={EditDeliveryPartner}
        />
        <MainStack.Screen
          name="nimbus-order-create"
          options={{title: 'Nimbus Order Create'}}
          component={NimbusOrderCreate}
        />
        <MainStack.Screen
          name="ship-rocket-order-create"
          options={{title: 'ShipRocket Order Create'}}
          component={ShipRocketOrderCreate}
        />
        {/* table */}
        <MainStack.Screen
          name="floors"
          options={{title: 'Floor  list'}}
          component={FloorList}
        />
        <MainStack.Screen
          name="edit-floor"
          options={{title: 'Edit floor'}}
          component={AddNewFloor}
        />
        <MainStack.Screen
          name="edit-room"
          options={{title: 'Edit room'}}
          component={AddNewRoom}
        />
        <MainStack.Screen
          name="edit-table"
          options={{title: 'Edit table'}}
          component={AddNewTable}
        />
        <MainStack.Screen
          name="subscription-setting"
          options={{title: 'Subscription Setting'}}
          component={SubscriptionSetting}
        />
        <MainStack.Screen
          name="table-setting"
          options={{title: 'Table Setting'}}
          component={TableSetting}
        />
        <MainStack.Screen
          name="merchant-table-booking-list"
          options={{title: 'Merchant TableBooking List'}}
          component={MerchantTableBookingList}
        />
        <MainStack.Screen
          name="merchant-subscription-list"
          options={{title: 'Merchant Subscription List'}}
          component={MerchantSubscriptionList}
        />
        <MainStack.Screen
          name="subscription-detail"
          options={{title: 'Subscription Detail'}}
          component={SubscriptionDetail}
        />
        <MainStack.Screen
          name="table-booking-detail"
          options={{title: 'Table Booking Detail'}}
          component={TableBookingDetail}
        />
        <MainStack.Screen
          name="offline-order-detail"
          options={{title: 'Offline Order Detail'}}
          component={OfflineOrderDetail}
        />
        <MainStack.Screen
          name="subscription-delivery-list"
          options={{title: 'Subscription Delivery List'}}
          component={SubscriptionDelivery}
        />
        <MainStack.Screen
          name="hospitality"
          options={{title: 'Hospitality'}}
          component={Hospitality}
        />
        <MainStack.Screen
          name="edit-accommodation"
          options={{title: 'Edit Accommodation'}}
          component={AddNewAccommodation}
        />
        <MainStack.Screen
          name="accommodation-setting"
          options={{title: 'Accommodation Setting List'}}
          component={AccommodationSetting}
        />
        <MainStack.Screen
          name="experience-list"
          options={{title: 'Experience List'}}
          component={ExperienceList}
        />
        <MainStack.Screen
          name="add-new-experience"
          options={{title: 'Add New Experience'}}
          component={AddNewExperience}
        />
        <MainStack.Screen
          name="features-list"
          options={{title: 'Add New Feature'}}
          component={FeaturesList}
        />
        <MainStack.Screen
          name="edit-features"
          options={{title: 'Add New Feature'}}
          component={AddNewFeatures}
        />
        <MainStack.Screen
          name="business-invoice-list"
          options={{title: 'Business Invoice List'}}
          component={BusinessInvoiceList}
        />
        <MainStack.Screen
          name="stripe-pay"
          options={{title: 'Stripe Payment'}}
          component={PayStripeScreen}
        />
        <MainStack.Screen
          name="offline-order-print-preview"
          options={{title: 'Offline Order Print Preview'}}
          component={OfflineOrderPrintPreview}
        />
        <MainStack.Screen
          name="bookings"
          options={{title: 'Booking List'}}
          component={Bookings}
        />
        <MainStack.Screen
          name="donation-configuration"
          options={{title: 'Donation Configuration'}}
          component={DonationConfiguaration}
        />
        <MainStack.Screen
          name="referral-setting"
          options={{title: 'Referral Setting'}}
          component={ReferralConfiguaration}
        />
        <MainStack.Screen
          name="redeem-coin-setting"
          options={{title: 'Redeem Coin Setting'}}
          component={RedeemCoinConfiguaration}
        />
        <MainStack.Screen
          name="variant-types"
          options={{title: 'Variant Types'}}
          component={VariantTypes}
        />
        <MainStack.Screen
          name="edit-variant-types"
          options={{title: 'Edit Variant Types'}}
          component={EditVariantTypes}
        />
        <MainStack.Screen
          name="edit-variant"
          options={{title: 'Edit Variant'}}
          component={EditVariant}
        />
        <MainStack.Screen
          name="rate-code-list"
          options={{title: 'Rate Code List'}}
          component={RateCodeList}
        />
        <MainStack.Screen
          name="edit-rate-code"
          options={{title: 'Edit Rate Code'}}
          component={EditRateCode}
        />
        <MainStack.Screen
          name="merchant-donation-list"
          options={{title: 'Edit Rate Code'}}
          component={MerchantDonationList}
        />
      </MainStack.Navigator>
    </NavigationContainer>
  );
}

const AuthStack = createStackNavigator();
function AuthNavigator(props) {
  const [skipIntro, setSkipINtro] = useState(null);
  const {setting} = useSelector((state) => state.config);

  useEffect(() => {
    if (Platform.OS === 'web') {
      setSkipINtro(true);
    }

    AsyncStorage.getItem('skipIntro').then((o) => {
      if (o === 'yes') {
        setSkipINtro(true);
      } else {
        setSkipINtro(false);
      }
    });
  }, []);

  if (skipIntro === null) {
    return <></>;
  }
  let linking = {
    config: {
      screens: {
        introduction: 'introduction',
        login: 'login',
        register: 'register',
        'forgot-password': 'forgot-password',
        'verify-otp': 'verify-otp',
        'reset-password': 'reset-password',
      },
    },
    enabled: true,
  };

  return (
    <NavigationContainer
      linking={linking}
      onStateChange={(state) => {
        // let screenNames = state.routes[state.routes.length - 1]?.name;
        // Platform.OS != 'web' && Segment.screen(screenNames);
      }}
    >
      <AuthStack.Navigator screenOptions={{headerShown: false}}>
        {false && !skipIntro && setting?.splash?.length > 0 && (
          <AuthStack.Screen
            name="introduction"
            component={IntroductionScreen}
          />
        )}
        <AuthStack.Screen
          name="login"
          component={LoginScreen}
          options={{title: 'Login'}}
        />
        <AuthStack.Screen
          name="pending-approvement"
          component={PendingApprovement}
          options={{title: 'Pending Approvement'}}
        />
        <AuthStack.Screen
          name="register"
          component={RegisterScreen}
          options={{title: 'Register'}}
        />
        <AuthStack.Screen
          name="forgot-password"
          component={ForgotPasswordScreen}
          options={{title: 'Forgot Password'}}
        />
        <AuthStack.Screen
          name="reset-password"
          options={{title: 'Reset Password'}}
          component={ResetPasswordScreen}
        />
        <AuthStack.Screen
          name="verify-otp"
          component={VerifyOtpScreen}
          options={{title: 'Verify Otp'}}
        />
        <AuthStack.Screen
          name="create-business"
          component={CreateBusiness}
          options={{title: 'Login'}}
        />
        <AuthStack.Screen
          name="thank-you"
          component={ThankYouScreen}
          options={{title: 'Login'}}
        />
      </AuthStack.Navigator>
    </NavigationContainer>
  );
}

export {AppNavigator, AuthNavigator};
