import React, {useState, useEffect} from 'react';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import ProductActions from '../../redux/reduxsauce/productRedux';
import {Platform, Text, View, TouchableOpacity, FlatList} from 'react-native';
import {
  LongButton,
  CategoryItem,
  MainContainer,
  MainHeader,
  NoRecordFound,
} from '../../components';
import {goBack} from '../../utils';
import styles from './styles';
import {strings} from '../../i18n';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';

const ManageCategory = props => {
  const initialData = useSelector(state => state.products.categories);
  const dispatch = useDispatch();
  const [data, setData] = useState(initialData);

  const setCategories = id => {
    dispatch(
      ProductActions.getCategories({
        categories: id ? data.filter((a, b) => a.id != id) : data,
      }),
    );
  };

  useEffect(() => {
    setCategories();
  }, [data]);

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  const openAlert = (content, leftButton, leftPress, rightButton, rightPress) =>
    CommonActions.setAlert({
      visible: true,
      content,
      leftButton,
      leftPress,
      rightButton,
      rightPress,
    });

  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('ManageCategories.title')}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer useView>
        <View style={styles.mainContainer}>
          {data.length != 0 ? (
            <FlatList
              data={data}
              // dragItemOverflow={false}
              showsVerticalScrollIndicator={false}
              // onDragEnd={({data}) => setData(data)}
              keyExtractor={item => item.id}
              renderItem={({item, index, isActive}) => (
                <CategoryItem
                  item={item}
                  isActive={isActive}
                  onEditPress={() =>
                    props.navigation.navigate('edit-products-category', item)
                  }
                  onDeletePress={() =>
                    dispatch(
                      openAlert(
                        strings('ManageCategories.askDelete'),
                        strings('settingScreen.confirm'),
                        async () => {
                          dispatch(CommonActions.setLoading(true));
                          await axios
                            .delete(
                              '/products/product-category/delete/' + item.id,
                            )
                            .then(response => {
                              setCategories(item.id);
                              dispatch(CommonActions.setLoading(false));
                            })
                            .catch(error => {
                              console.log(
                                'error -------------->',
                                error?.response,
                              );
                              dispatch(openAlert(error?.response?.message));
                              dispatch(CommonActions.setLoading(false));
                            });
                        },
                        strings('settingScreen.cancel'),
                        null,
                      ),
                    )
                  }
                />
              )}
            />
          ) : (
            <NoRecordFound
              title={strings('ManageCategories.norecord.title')}
              message={strings('ManageCategories.norecord.message')}
            />
          )}
        </View>
        {Platform.OS == 'web' && (
          <TouchableOpacity
            style={styles.addCategoryButtonWeb}
            onPress={() => props.navigation.navigate('edit-products-category')}
          >
            <Text style={styles.addCategoryText}>{strings('ManageCategories.button.createNew')}</Text>
          </TouchableOpacity>
        )}
      </MainContainer>
      {Platform.OS != 'web' && (
        <LongButton
          text={strings('ManageCategories.button.createNew')}
          style={{marginTop: 10, borderRadius: 0}}
          onPress={() => props.navigation.navigate('edit-products-category')}
        />
      )}
    </View>
  );
};
export default ManageCategory;
