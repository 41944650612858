import React, {useEffect, useState} from 'react';
import {
  Keyboard,
  Platform,
  TextInput,
  View,
  FlatList,
  TouchableOpacity,
} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {
  AppText,
  LongButton,
  MainContainer,
  MainHeader,
  Icon,
  DropDownInput,
  NoRecordFound,
  TableItem,
} from '../../../components';
import {Colors} from '../../../constants';
import {strings} from '../../../i18n';
import {goBack, setValue} from '../../../utils';
import styles from './styles';
import {connectActionSheet} from '@expo/react-native-action-sheet';
import axios from 'axios';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {useDispatch, useSelector} from 'react-redux';
import {useIsFocused} from '@react-navigation/native';

const AddNewRoom = (props) => {
  const item = props?.route?.params?.item;
  const [tableList, setTableList] = useState([]);
  const [roomDetail, setRoomDetail] = useState({
    name: item?.name ? item?.name : '',
    description: item?.description ? item?.description : '',
    floor: props?.route?.params?.floorItem?.id
      ? props?.route?.params?.floorItem?.id
      : '',
  });
  const dispatch = useDispatch();
  const config = useSelector((state) => state?.config);
  const auth = useSelector((state) => state?.auth);

  const isFocused = useIsFocused();

  const getTableList = async () => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios
        .get('/restaurant/table/list/' + config.businessId)
        .then((response) => {
          console.log('response --> roomList', response);
          let array = [];
          if (['merchant', 'manager'].includes(auth.user.type)) {
            array = [{static: true}, ...response.data];
          } else {
            array = [...response.data];
          }
          setTableList(array);
          dispatch(CommonActions.setLoading(false));
        });
    } catch (error) {
      dispatch(CommonActions.setLoading(false));
      console.log('message roomList', error?.response?.message);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };

  useEffect(() => {
    isFocused && getTableList();
  }, [isFocused]);

  const onSave = async () => {
    Keyboard.dismiss();
    dispatch(CommonActions.setLoading(true));
    if (roomDetail.name == '') {
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('addNewRoom.alert.name'),
        }),
      );
      return;
    }
    if (roomDetail.description == '') {
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('addNewRoom.alert.description'),
        }),
      );
      return;
    }
    if (roomDetail.floor == '') {
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('addNewRoom.alert.floor'),
        }),
      );
      return;
    }

    let body = new FormData();
    body.append('name', roomDetail?.name.trim());
    roomDetail?.description.trim().length > 0 &&
      body.append('description', roomDetail?.description.trim());
    body.append('floor', roomDetail?.floor);

    const header = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    if (item?.id) {
      await axios
        .patch('/restaurant/room/update/' + item?.id, body, header)
        .then((response) => {
          goBack(props.navigation);
          console.log('response update floor', response);
          dispatch(CommonActions.setLoading(false));
        })
        .catch((error) => {
          console.log('error update floor --', error?.response);
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: error?.response?.message,
            }),
          );
          dispatch(CommonActions.setLoading(false));
        });
    } else {
      await axios
        .post('/restaurant/room/create', body, header)
        .then((response) => {
          console.log('response create room', response);
          goBack(navigation);
          dispatch(CommonActions.setLoading(false));
        })
        .catch((error) => {
          console.log('error create floor', error.response);
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: error?.response?.message,
            }),
          );
          dispatch(CommonActions.setLoading(false));
        });
    }
  };

  const deleteTable = async (id) => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios.delete('/restaurant/table/delete/' + id).then((response) => {
        console.log('response of delete room--> ', response);
        getRoomList();
        props.navigation.navigate('edit-room');
        dispatch(CommonActions.setLoading(false));
      });
    } catch (error) {
      dispatch(CommonActions.setLoading(false));
      console.log('message', error?.response?.message);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };

  return (
    <View style={styles.container}>
      <MainHeader
        title={
          item?.id
            ? strings('addNewRoom.editRoom')
            : strings('addNewRoom.createRoom')
        }
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer>
        <KeyboardAwareScrollView>
          <>
            <View style={styles.textInputContainer}>
              <AppText style={styles.name} required>
                {strings('addNewRoom.title.roomName')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={strings('addNewRoom.placeHolder.roomName')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={styles.textInput}
                value={roomDetail?.name}
                onChangeText={(text) =>
                  setRoomDetail({
                    ...roomDetail,
                    name: text,
                  })
                }
              />
            </View>

            <View style={styles.textInputContainer}>
              <AppText style={styles.name} required>
                {strings('addNewRoom.title.description')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={strings('addNewRoom.placeHolder.description')}
                placeholderTextColor={Colors.color30}
                numberOfLines={3}
                multiline={true}
                returnKeyType="done"
                style={styles.multiLineTextInput}
                value={roomDetail?.description}
                onChangeText={(text) =>
                  setRoomDetail({
                    ...roomDetail,
                    description: text,
                  })
                }
              />
            </View>

            <View style={styles.textInputContainer}>
              <AppText style={styles.name} required>
                {strings('addNewRoom.title.floor')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                editable={false}
                returnKeyType="done"
                style={styles.textInput}
                value={props?.route?.params?.floorItem?.name}
              />
            </View>

            {item?.id && (
              <View>
                <AppText style={styles.subCatogoryTitle}>
                  {' '}
                  {strings('addNewRoom.tables')}
                </AppText>
                {tableList.length > 0 ? (
                  <>
                    <FlatList
                      data={tableList}
                      showsVerticalScrollIndicator={false}
                      keyExtractor={(item, index) => index}
                      renderItem={({item, index, isActive}) => {
                        if (item.static) {
                          return (
                            <TouchableOpacity
                              style={styles.staticItemContainer}
                              onPress={() =>
                                props.navigation.navigate('edit-table', {
                                  roomItem: props?.route?.params?.item,
                                })
                              }
                            >
                              <AppText style={styles.iconContainer}>
                                <Icon
                                  name="add"
                                  size={18}
                                  style={styles.iconStyle}
                                />
                              </AppText>
                            </TouchableOpacity>
                          );
                        }
                        return (
                          <>
                            {item?.room === props?.route?.params?.item?.id && (
                              <TableItem
                                key={index}
                                item={item}
                                isActive={isActive}
                                onEditPress={() =>
                                  props.navigation.navigate('edit-table', {
                                    edit: true,
                                    item: item,
                                    roomItem: props?.route?.params?.item,
                                  })
                                }
                                onDeletePress={() =>
                                  dispatch(
                                    CommonActions.setAlert({
                                      visible: true,
                                      content: strings('addNewRoom.askDelete'),
                                      rightButton: strings(
                                        'settingScreen.confirm',
                                      ),
                                      rightPress: () => {
                                        deleteTable(item?.id);
                                      },
                                      leftButton: strings(
                                        'settingScreen.cancel',
                                      ),
                                      leftPress: null,
                                    }),
                                  )
                                }
                              />
                            )}
                          </>
                        );
                      }}
                      ListFooterComponent={() => (
                        <View style={{height: 100}}></View>
                      )}
                    />
                  </>
                ) : (
                  <NoRecordFound
                    title={strings('addNewRoom.noRecord.title')}
                    message={strings('addNewRoom.noRecord.message')}
                  />
                )}
              </View>
            )}
          </>

          {Platform.OS == 'web' && (
            <LongButton
              onPress={() => onSave()}
              loading={false}
              text={
                item?.id
                  ? strings('addNewGallery.edit')
                  : strings('editProductCategory.button.save')
              }
              style={{
                marginTop: setValue(20),
                marginBottom: setValue(45),
              }}
            />
          )}
        </KeyboardAwareScrollView>
      </MainContainer>

      {Platform.OS !== 'web' && (
        <LongButton
          onPress={() => onSave()}
          loading={false}
          text={
            item?.id
              ? strings('addNewGallery.edit')
              : strings('editProductCategory.button.save')
          }
          style={{borderRadius: 0, width: '100%'}}
        />
      )}
    </View>
  );
};
const ConnectedAddNewRoom = connectActionSheet(AddNewRoom);

export default ConnectedAddNewRoom;
