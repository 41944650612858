import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {screenHeight, setValue, setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  mainOnboardContainer: {
    flex: 1,
  },
  sliderContainer: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: Colors.purple,
  },
  sliderMarketContainer: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: Colors.purple,
  },
  dotContainer: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    marginBottom: setYAxisValue(50),
  },
  activeDot: {
    backgroundColor: Colors.black20,
    width: setXAxisValue(10),
    height: setXAxisValue(10),
    marginRight: setXAxisValue(10),
    borderRadius: setXAxisValue(10) / 2,
  },
  normalDot: {
    backgroundColor: Colors.black60,
    opacity: 0.5,
    width: setXAxisValue(10),
    height: setXAxisValue(10),
    marginRight: setXAxisValue(10),
    borderRadius: setXAxisValue(10) / 2,
  },
  paginationContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: setXAxisValue(20),
    backgroundColor: Colors.transparent,
    marginBottom: setYAxisValue(20),
  },
  sliderTextContainer: {
    flex: 4.5,
  },
  imageContainer: {
    flex: 5.5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  skipText: {
    fontSize: setXAxisValue(18),
    color: Colors.black60,
    opacity: 0.5,
    letterSpacing: 1.2,
  },
  nextText: {
    fontSize: setXAxisValue(18),
    color: Colors.black20,
    letterSpacing: 1.2,
  },
  headerOnboard: {
    fontSize: setXAxisValue(35),
    lineHeight: setXAxisValue(45),
    textAlign: 'center',
    marginBottom: setYAxisValue(40),
  },
  descriptionOnboard: {
    fontSize: setXAxisValue(16),
    textAlign: 'center',
    lineHeight: setXAxisValue(22),
  },
  image: {
    height: setXAxisValue(250),
    width: setXAxisValue(250),
    // borderRadius: 25,
    // backgroundColor: 'gray',
  },
  addSplashBtn: {
    justifyContent: 'center',
    marginHorizontal: 15,
    borderRadius: 5,
    backgroundColor: Colors.white,
    height: screenHeight / 3,
    elevation:5
  },
  btnContainer: {
    position: 'absolute',
    top: setValue(50),
    right: setValue(20),
  },
  deleteBtn: {
    backgroundColor: 'rgba(255,0,0,1)',
    borderRadius: 5,
    elevation: 5,
  },
  editBtn: {
    backgroundColor: Colors.white,
    borderRadius: 5,
    marginTop: 10,
    elevation: 5,
  },
  deleteIcon: {
    padding: 8,
    flex: 1,
    alignSelf: 'center',
  },
});
