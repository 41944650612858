import {StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black20,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
};
export default StyleSheet.create({
  container: {
    flex: 1,
  },
  inputPromotion: {
    width: '75%',
    borderWidth: 0,
    borderBottomWidth: 1,
    paddingHorizontal: setValue(2),
    borderBottomColor: Colors.border,
  },
  applyButtonTop: {
    width: '25%',
    paddingRight: setValue(10),
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  topInputContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: setValue(10),
    paddingVertical: setYAxisValue(15),
    backgroundColor: Colors.white,
    borderRadius: setValue(8),
    ...shadow,
    marginBottom: setValue(15),
  },
});
