import React, {useEffect, useState} from 'react';
import {
  Image,
  Keyboard,
  Linking,
  Platform,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {
  AppText,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
  DropDownInput,
} from '../../../components';
import {Colors} from '../../../constants';
import {strings} from '../../../i18n';
import {goBack, setValue} from '../../../utils';
import styles from './styles';
import * as ImagePicker from 'expo-image-picker';
import {connectActionSheet} from '@expo/react-native-action-sheet';
import axios from 'axios';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {useDispatch, useSelector} from 'react-redux';

const AddNewBlog = (props) => {
  const item = props?.route?.params?.item;

  console.log('item ---', item);
  const [blogCategoryList, setBlogCategoryList] = useState([]);
  const [blogDetail, setBlogDetail] = useState({
    title: item?.title ? item?.title : '',
    description: item?.description ? item?.description : '',
    image: item?.image ? {uri: item?.image} : null,
    body: item?.body ? item?.body : '',
    slug: item?.slug ? item?.slug : '',
    category: item?.category ? item?.category.id : '',
  });
  const dispatch = useDispatch();
  const config = useSelector((state) => state?.config);

  const openActionSheet = () => {
    const options = [
      strings('actionSheet.takePhoto'),
      strings('actionSheet.choosePhoto'),
      strings('actionSheet.removePhoto'),
      strings('actionSheet.cancel'),
    ];
    const cancelButtonIndex = 3;

    props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) openCamera();
        if (buttonIndex === 1) openImagePicker();
        if (buttonIndex === 2) removePhoto();
      },
    );
  };

  const removePhoto = () => {
    // props.removeProfilePicture();
    setBlogDetail({
      ...blogDetail,
      image: null,
    });
  };

  const openCamera = async () => {
    await ImagePicker.requestCameraPermissionsAsync();

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: [30, 17],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      setBlogDetail({
        ...blogDetail,
        image: result,
      });
    }

    if (!result.cancelled) {
      // changeProfilePicture(result);
    }
  };

  const openImagePicker = async () => {
    await ImagePicker.requestMediaLibraryPermissionsAsync();

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: [30, 17],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      setBlogDetail({
        ...blogDetail,
        image: result,
      });
    }

    if (!result.cancelled) {
    }
  };

  const dataURLtoFile = (dataurl) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], 'image1.jpg', {type: mime});
  };

  const getBlogCategoryList = () => {
    dispatch(CommonActions.setLoading(true));
    axios
      .get('/blog/category/list/' + config?.businessId)
      .then((response) => {
        console.log('response of category list===', response);
        setBlogCategoryList(response?.data);
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        console.log('error in response of category list===', error);
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.message,
          }),
        );
        dispatch(CommonActions.setLoading(false));
      });
  };

  useEffect(() => {
    getBlogCategoryList();
  }, []);

  const onSave = async () => {
    Keyboard.dismiss();
    dispatch(CommonActions.setLoading(true));
    if (blogDetail.title.trim() == '') {
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('addNewBlog.alert.title'),
        }),
      );
      return;
    }
    if (blogDetail.slug.trim() == '') {
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('addNewBlog.alert.slug'),
        }),
      );
      return;
    }
    if (blogDetail.category == '') {
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('addNewBlog.alert.category'),
        }),
      );
      return;
    }
    let body = new FormData();
    body.append('title', blogDetail?.title.trim());
    blogDetail?.description.trim().length > 0 &&
      body.append('description', blogDetail?.description.trim());
    blogDetail?.body.trim().length > 0 &&
      body.append('body', blogDetail?.body.trim());
    blogDetail?.slug.trim().length > 0 &&
      body.append('slug', blogDetail?.slug.trim());
    body.append('category', blogDetail?.category);

    if (
      blogDetail?.image?.uri &&
      blogDetail?.image?.uri?.slice(0, 4) != 'http'
    ) {
      if (Platform.OS == 'web') {
        body.append('image', dataURLtoFile(blogDetail?.image?.uri));
      } else {
        let blob = await (await fetch(blogDetail?.image?.uri)).blob();
        body.append('image', {
          uri: blogDetail?.image?.uri,
          type: blob.type,
          name: blob.data.name,
        });
      }
    } else if (!blogDetail?.image?.uri) {
      body.append('image', '');
    }

    const header = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    console.log('body', body);
    if (item?.id) {
      await axios
        .patch('/blog/update/' + item?.id, body, header)
        .then((response) => {
          console.log('response update blog', response);
          dispatch(CommonActions.setLoading(false));
          props.navigation.replace('blogs');
        })
        .catch((error) => {
          console.log('error update blog --', error?.response);
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: error?.response?.message,
            }),
          );
          dispatch(CommonActions.setLoading(false));
        });
    } else {
      await axios
        .post('/blog/create', body, header)
        .then((response) => {
          console.log('response create blog', response);
          dispatch(CommonActions.setLoading(false));
          props.navigation.replace('blogs');
        })
        .catch((error) => {
          console.log('error create blog', error.response);
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: error?.response?.message,
            }),
          );
          dispatch(CommonActions.setLoading(false));
        });
    }
  };

  return (
    <View style={styles.container}>
      <MainHeader
        title={
          props?.route?.params?.item?.id
            ? strings('addNewBlog.editBlog')
            : strings('addNewBlog.addNewBlog')
        }
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer>
        <KeyboardAwareScrollView>
          <>
            <View style={styles.profileStrengthContainer}>
              <TouchableOpacity
                style={styles.imageContainer}
                onPress={openActionSheet}
              >
                {!blogDetail?.image ? (
                  <Icon name="add" size={16} color={Colors.black60} />
                ) : (
                  <View>
                    <Image
                      source={{uri: blogDetail?.image?.uri}}
                      style={styles.image}
                    />
                    <TouchableOpacity
                      style={styles.cameraIcon}
                      onPress={openActionSheet}
                    >
                      <Icon name="camera" color={Colors.black20} />
                    </TouchableOpacity>
                  </View>
                )}
              </TouchableOpacity>
            </View>

            <View style={styles.textInputContainer}>
              <AppText style={styles.title} required>
                {strings('addNewBlog.title.title')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={strings('addNewBlog.placeHolder.title')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={styles.textInput}
                value={blogDetail?.title}
                onChangeText={(text) =>
                  setBlogDetail({
                    ...blogDetail,
                    title: text,
                  })
                }
              />
            </View>

            <View style={styles.textInputContainer}>
              <AppText style={styles.title}>
                {strings('addNewBlog.title.description')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={strings('addNewBlog.placeHolder.description')}
                placeholderTextColor={Colors.color30}
                numberOfLines={3}
                multiline={true}
                returnKeyType="done"
                style={styles.multiLineTextInput}
                value={blogDetail?.description}
                onChangeText={(text) =>
                  setBlogDetail({
                    ...blogDetail,
                    description: text,
                  })
                }
              />
            </View>

            <View style={styles.textInputContainer}>
              <AppText style={styles.title} required>
                {strings('addNewBlog.title.slug')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={strings('addNewBlog.placeHolder.slug')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={styles.textInput}
                value={blogDetail?.slug}
                onChangeText={(text) =>
                  setBlogDetail({
                    ...blogDetail,
                    slug: text,
                  })
                }
              />
            </View>
            {blogCategoryList?.length > 0 ? (
              <View style={styles.textInputContainer}>
                <AppText style={styles.title} required>
                  {strings('editProductScreen.label.category')}
                </AppText>
                <DropDownInput
                  data={blogCategoryList?.map((o) => ({
                    label: o?.name,
                    value: o?.id,
                  }))}
                  value={blogDetail?.category}
                  inputStyle={[styles.textInput, {
                    paddingHorizontal: 10,
                    marginTop: 10,
                  }]}
                  onChangeText={(text) => {
                    setBlogDetail({...blogDetail, category: text});
                  }}
                />
              </View>
            ) : (
              <TouchableOpacity
                style={styles.settingView}
                onPress={() => {
                  props.navigation.navigate('edit-blog-category');
                }}
              >
                <AppText style={styles.actionTitle}>
                  {'Add Blog Category'}
                </AppText>
                <Icon name="forward" size={20} />
              </TouchableOpacity>
            )}
            {item && item.token && (
              <TouchableOpacity
                style={styles.settingView}
                onPress={() => {
                  Linking.openURL(
                    axios.defaults.baseURL +
                      '/blog/editor/' +
                      item.id +
                      '/' +
                      item.token,
                  );
                }}
              >
                <AppText style={styles.actionTitle}>{'Edit Blog Body'}</AppText>
                <Icon name="forward" size={20} />
              </TouchableOpacity>
            )}
          </>

          {Platform.OS == 'web' && (
            <LongButton
              onPress={() => onSave()}
              loading={false}
              text={
                item?.id
                  ? strings('addNewGallery.edit')
                  : strings('editProductCategory.button.save')
              }
              style={{
                marginTop: setValue(20),
                marginBottom: setValue(45),
              }}
            />
          )}
        </KeyboardAwareScrollView>
      </MainContainer>

      {Platform.OS !== 'web' && (
        <LongButton
          onPress={() => onSave()}
          loading={false}
          text={
            item?.id
              ? strings('addNewGallery.edit')
              : strings('editProductCategory.button.save')
          }
          style={{borderRadius: 0, width: '100%'}}
        />
      )}
    </View>
  );
};
const ConnectedAddNewBlog = connectActionSheet(AddNewBlog);

export default ConnectedAddNewBlog;
