import React, {useEffect, useState} from 'react';
import {FlatList, View, TouchableOpacity} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {
  AppText,
  MainContainer,
  MainHeader,
  NoRecordFound,
} from '../../components';
import {goBack} from '../../utils';
import styles from './styles';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import axios from 'axios';
import {useIsFocused} from '@react-navigation/native';
import moment from 'moment';
import {get} from 'lodash';

const MerchantDonationList = (props) => {
  const isFocused = useIsFocused();
  const dispatch = useDispatch();
  const currencySymbol = get(config, 'currencySymbol', '₹');
  const config = useSelector((state) => state?.config);

  const [merchantDonationList, setMerchantDonationList] = useState([]);

  const getMerchantDonationList = async () => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios.get('/donation/merchant/list').then((response) => {
        console.log('merchant donaFtion list response --> ', response);
        setMerchantDonationList(response?.data);
        dispatch(CommonActions.setLoading(false));
      });
    } catch (error) {
      dispatch(CommonActions.setLoading(false));
      console.log('merchant donation  list error', error?.response?.message);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };
  console.log('merchantDonationList--->>', merchantDonationList);

  useEffect(() => {
    isFocused && getMerchantDonationList();
  }, [isFocused]);

  return (
    <View style={styles.container}>
      <MainHeader
        title={'Donation History'}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer useView>
        {merchantDonationList?.length == 0 ? (
          <NoRecordFound
            title="Orders"
            message="We'll notify you when there is something now!"
            hideImage={true}
          />
        ) : (
          <View style={{paddingTop: 10, flex: 1}}>
            <FlatList
              data={merchantDonationList}
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              renderItem={({item}) => (
                <TouchableOpacity>
                  <View style={styles.itemContainer}>
                    <View style={styles.topContainer}>
                      <AppText style={styles.orderUId}>
                        {'SUB00' + item.id}
                      </AppText>
                      <AppText>
                        <AppText style={styles.orderUId}>
                          {moment(item.created_at).format(
                            'DD MMM YYYY hh:mm A',
                          )}
                        </AppText>
                      </AppText>
                    </View>
                    <View style={styles.bottomContainer}>
                      <AppText
                        style={styles.orderPrice}
                      >{`${currencySymbol} ${item.amount}`}</AppText>
                      <AppText
                        style={
                          item?.status === 'paid'
                            ? [styles.orderPrice, {color: '#056eb0'}]
                            : // : item?.status === 'accepted'
                            // ? [styles.orderPrice, {color: '#008c96'}]
                            // : item?.status === 'in-process'
                            // ? [styles.orderPrice, {color: '#f69c00'}]
                            item?.status === 'unpaid'
                            ? [styles.orderPrice, {color: '#f69c00'}]
                            : styles.orderPrice
                        }
                      >
                        {item?.status?.toUpperCase()}
                      </AppText>
                    </View>
                  </View>
                </TouchableOpacity>
              )}
            />
          </View>
        )}
      </MainContainer>
    </View>
  );
};

export default MerchantDonationList;
