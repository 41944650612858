import {Platform, StyleSheet} from 'react-native';

import {Colors, Layout} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black60,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 5,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  image: {
    height: setYAxisValue(50),
    width: setXAxisValue(50),
    borderRadius: setValue(50),
    marginRight: setXAxisValue(10),
  },
  card: {
    backgroundColor: Colors.white,
    borderRadius: 5,
    padding: setValue(15),
    marginBottom: setYAxisValue(10),
    ...shadow,
  },
  userDetailContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: setYAxisValue(10),
    width: '90%',
  },
  ratingTitle: {
    fontFamily: 'Roboto-Bold',
    fontSize: setValue(15),
    width: '90%',
  },
  ratingMessage: {
    fontFamily: 'Roboto-Regular',
  },

  editReview: {
    backgroundColor: Colors.theme_color,
    position: 'absolute',
    padding: setValue(8),
    borderRadius: setValue(30),
    right: setValue(5),
    top: setValue(5),
  },
  deleteReview: {
    backgroundColor: Colors.wallet_card_red,
    position: 'absolute',
    padding: setValue(8),
    borderRadius: setValue(30),
    right: setValue(5),
    top: setValue(45),
  },
});
