import {View, Image, TouchableOpacity} from 'react-native';
import React from 'react';
import {AppText, CheckBox} from '..';
import styles from './styles';
import {Colors} from '../../constants';
import {useSelector} from 'react-redux';
import {cashFormat, setValue} from '../../utils';
import {get} from 'lodash';

const MenuItem = (props) => {
  const appConfig = useSelector((state) => state.config);
  const {item} = props;
  let currencySymbol = get(appConfig, 'currencySymbol', '₹');
  return (
    <TouchableOpacity onPress={props.onPress} style={styles.itemConatiner}>
      <View style={styles.leftSideView}>
        <Image
          style={styles.image}
          source={
            item?.images[0]?.photo
              ? {uri: item?.images[0]?.photo}
              : require('../../../assets/images/General/no-img-found.jpg')
          }
        />
      </View>
      <View style={styles.rightSideView}>
        <View style={{marginHorizontal: 5}}>
          <AppText numberOfLines={1} style={styles.itemTitle}>
            {item?.name}
          </AppText>
          <AppText
            numberOfLines={2}
            style={[styles.itemDescription, {height: setValue(40)}]}
          >
            {item?.description}
          </AppText>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <AppText style={{fontSize: 15}}>
              {currencySymbol +
                ' ' +
                cashFormat(parseInt(item?.price)) +
                ' /- '}
            </AppText>
            {item?.discount != 0 && (
              <AppText style={styles.textDiscountPrice}>
                {currencySymbol +
                  ' ' +
                  cashFormat(
                    (parseInt(item?.price) * parseInt(item?.discount)) / 100 +
                      parseInt(item?.price),
                  ) +
                  ' /-'}
              </AppText>
            )}
          </View>
        </View>
      </View>
      <View style={styles.radioContainer}>
        <AppText style={styles.radio}>
          <CheckBox
            checked={item?.checked}
            onChange={(checked) => {
              props.onCheckItem(checked, item?.id);
            }}
          />
        </AppText>
      </View>
    </TouchableOpacity>
  );
};
export default MenuItem;
