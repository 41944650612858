import axios from 'axios';
import {Camera} from 'expo-camera';
import React, {Component, useEffect, useState} from 'react';
import {
  TouchableOpacity,
  Vibration,
  View,
  TouchableWithoutFeedback,
} from 'react-native';
import BarcodeMask from 'react-native-barcode-mask';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {useDispatch} from 'react-redux';
import {AppText, Icon} from '../../components';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {goBack} from '../../utils';
import styles from './styles';

export default function ScanOrderQr(props) {
  const [cameraAvailable, setCameraAvailable] = useState(false);
  const [flashModeOn, setFlashModeOn] = useState(false);
  const [scanned, setScanned] = useState(false);
  const dispatch = useDispatch();
  const openAlert = (
    content,
    leftButton,
    leftPress,
    rightButton,
    rightPress,
  ) => {
    dispatch(
      CommonActions.setAlert({
        visible: true,
        content,
        leftButton,
        leftPress,
        rightButton,
        rightPress,
      }),
    );
  };

  const handleBarCodeScanned = ({data}) => {
    console.log('data ---->', data);
    Vibration.vibrate();
    setScanned(true);
    dispatch(CommonActions.setLoading(true));

    axios
      .get('/order/get/' + data)
      .then((response) => {
        console.log('resonse get qr data', response?.data);
        let item = response?.data;
        dispatch(CommonActions.setLoading(false));
        props.navigation.replace('order-history-detail', {
          id: item.id, // TODO_CHANGE
        });
      })
      .catch((error) => {
        console.log('error ->', error?.response);
        dispatch(CommonActions.setLoading(false));
        openAlert(error?.response?.data?.detail);
        props.navigation.goBack();
      });
  };

  useEffect(() => {
    checkCameraPermission();
  }, []);

  const checkCameraPermission = async () => {
    const {status} = await Camera.requestCameraPermissionsAsync();
    console.log('status: ', status);
    if (status === 'granted') {
      setCameraAvailable(true);
    }else{
      openAlert(strings('common.permision'))
      props.navigation.goBack()
    }
  };

  return (
    <View style={styles.container}>
      {cameraAvailable ? (
        <Camera
          onBarCodeScanned={scanned ? null : handleBarCodeScanned}
          style={styles.qrcode}
          type={Camera.Constants.Type.back}
          flashMode={
            flashModeOn
              ? Camera.Constants.FlashMode.torch
              : Camera.Constants.FlashMode.off
          }
        >
          <BarcodeMask
            edgeColor={Colors.green}
            animatedLineColor={Colors.wallet_card_red}
            outerMaskOpacity={0}
            lineAnimationDuration={1200}
          />
          <View
            style={[
              styles.content,
              scanned
                ? styles.qrcodeContentDarkOverlay
                : styles.qrcodeContentTransparent,
            ]}
          >
            <View style={styles.header}>
              <TouchableOpacity
                style={{flexDirection: 'row', alignItems: 'center'}}
                onPress={() => goBack(props.navigation)}
              >
                <Icon name="back" color="white" size={20} />
                <AppText style={styles.title}>{'Order QR Scanner'}</AppText>
              </TouchableOpacity>
            </View>
            <View>
              <TouchableOpacity onPress={() => setFlashModeOn(!flashModeOn)}>
                <AppText style={styles.flashMode}>{`${strings(
                  'ordering.checkInOrderScreen.flashMode',
                )}: ${flashModeOn ? 'off' : 'on'}`}</AppText>
              </TouchableOpacity>
            </View>
          </View>
        </Camera>
      ) : null}
    </View>
  );
}
