import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import { Colors } from '../../constants';
import { setValue, setXAxisValue, setYAxisValue } from '../../utils';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  textInputContainer: {
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 5,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
  },
  inputLabel: {
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    fontFamily: 'Roboto-Bold',
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  textInput: {
    height: setValue(50),
    color: Colors.black20,
    paddingHorizontal: setXAxisValue(10),
  },
  textInputWeb: {
    height: setValue(50),
    padding: setValue(10),
    paddingHorizontal: setXAxisValue(10),
  },
  btn: {
    backgroundColor: Colors.theme_color,
    position:"absolute",
    left:0,
    right:0,
    bottom:0,
  },
  btnText: {
    textAlign: 'center',
    padding: 15,
    color: Colors.white,
    fontFamily:"Roboto-Bold"
  },
});
