import {Appearance, StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';
// const colorScheme = Appearance.getColorScheme();

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  textInput: {
    color: Colors.black,
    padding: setValue(10),
    
  },
  textInputContainer: {
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 5,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
  },
  textInputWeb: {
    height: setValue(80),
    padding: setValue(5),
    paddingHorizontal: setXAxisValue(10),
  },
  multiLineTextInput: {
    padding: setValue(10),
    textAlignVertical: 'top',
    height: 80,
  },
  inputRightSide: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    right: setValue(10),
    alignSelf: 'center',
    top: setValue(15),
    elevation: 10,
  },
  descContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  registerButton: {
    marginVertical: setYAxisValue(25),
  },
  agreeText: {
    fontSize: setXAxisValue(12),
    color: Colors.color30,
  },
  termAndConText: {
    fontSize: setXAxisValue(12),
    color: Colors.black,
  },
  datePickerContainer: {
    flexDirection: 'row',
  },
  dateInput: {
    width: '15%',
    marginRight: setXAxisValue(10),
    height: setValue(60),
  },
  marqueeText: {
    width: '75%',
    color: Colors.color30,
    alignSelf: 'center',
  },
  marqueeContainer: {
    height: setValue(50),
    padding: 0,
    flex: 1,
    paddingHorizontal: setXAxisValue(5),
    marginBottom: setYAxisValue(10),
    borderRadius: 5,
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  calendarIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    height: setValue(50),
    right: setXAxisValue(10),
    elevation: 10,
  },
  yearInput: {
    width: '20%',
    marginRight: setXAxisValue(10),
    height: setValue(60),
  },
  dropDownPickerStyles: {
    color: Colors.black,
    paddingHorizontal: 5,
    marginBottom: 10,
    borderRadius: 5,
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
  },
  agreementText: {
    textAlignVertical: 'center',
    color: Colors.color30,
    padding: 2,
  },
});
