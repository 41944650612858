import axios from 'axios';
import {get} from 'lodash';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import {useSelector} from 'react-redux';
import {AppText, MainContainer, MainHeader} from '../../components';
import {strings} from '../../i18n';
import {goBack} from '../../utils';
import styles from './styles';

const TableBookingDetail = (props) => {
  const {id} = props?.route?.params;
  const config = useSelector((state) => state?.config);
  const currencySymbol = get(config, 'currencySymbol', '₹');

  const [tableBookingDetail, setTableBookingDetail] = useState(null);
  const [loading, setLoading] = useState(false);

  const getTableBookingDetail = () => {
    setLoading(true);

    axios
      .get('/restaurant/tablebooking/get/details/' + id)
      .then((response) => {
        console.log('response -->', response);
        setTableBookingDetail(response?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log('error --->', error?.response);
        setLoading(false);
      });
  };

  useEffect(() => {
    getTableBookingDetail();
  }, [id]);

  const updateSubscriptionStatus = (status) => {
    setLoading(true);
    axios
      .patch('/restaurant/tablebooking/update/status/' + id, {
        status: status,
      })
      .then((response) => {
        console.log('response --', response);
        getTableBookingDetail();
      })
      .catch((error) => {
        console.log('error --', error);
        getTableBookingDetail();
      });
  };

  // render methods
  const renderSteps = () => {
    let doneAtStepIndex = 0;

    doneAtStepIndex =
      tableBookingDetail?.status == 'unpaid'
        ? 0
        : tableBookingDetail?.status == 'paid'
        ? 1
        : tableBookingDetail?.status == 'accepted'
        ? 2
        : tableBookingDetail?.status == 'completed' && 3;

    console.log('sadad', 'completed' == tableBookingDetail?.status);

    if (
      tableBookingDetail?.status == 'cancelled' ||
      tableBookingDetail?.status == 'declined'
    ) {
      return (
        <View style={[styles.cardContainer, {padding: 20}]}>
          <View
            style={[
              styles.orderStatusStepContainer,
              {
                justifyContent: 'center',
              },
            ]}
          >
            <View style={{alignItems: 'center', justifyContent: 'center'}}>
              <View style={[styles.doneStep, styles.declinedStep]}>
                <Image
                  style={styles.stepIcon}
                  source={require('../../../assets/images/Ordering/close.png')}
                />
              </View>
              <Text style={styles.textStep}>
                {tableBookingDetail?.status == 'cancelled'
                  ? strings('ordering.orderStatusPending.cancelled')
                  : strings('ordering.orderStatusPending.declined')}
              </Text>
            </View>
          </View>
        </View>
      );
    }
    return (
      <View style={[styles.cardContainer, {padding: 20}]}>
        <View style={styles.orderStatusStepContainer}>
          <View style={styles.separator} />

          <View style={{alignItems: 'center', justifyContent: 'center'}}>
            <View
              style={doneAtStepIndex >= 1 ? styles.doneStep : styles.undoneStep}
            >
              <Image
                style={styles.stepIconForPaid}
                source={require('../../../assets/images/Ordering/paid.png')}
              />
            </View>
            <Text style={styles.textStep}>
              {strings('ordering.orderStatusPending.paid')}
            </Text>
          </View>

          <View style={{alignItems: 'center', justifyContent: 'center'}}>
            <View
              style={doneAtStepIndex >= 2 ? styles.doneStep : styles.undoneStep}
            >
              <Image
                style={styles.stepIcon}
                source={require('../../../assets/images/Ordering/order_status_image_accepted.png')}
              />
            </View>
            <Text style={styles.textStep}>
              {strings('ordering.orderStatusPending.accepted')}
            </Text>
          </View>
          <View style={{alignItems: 'center', justifyContent: 'center'}}>
            <View
              style={doneAtStepIndex >= 3 ? styles.doneStep : styles.undoneStep}
            >
              <Image
                style={styles.stepIcon}
                source={require('../../../assets/images/Ordering/order_status_image_deliverd.png')}
              />
            </View>
            <Text style={styles.textStep}>
              {strings('ordering.orderStatusPending.completed')}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const renderDetail = () => {
    return (
      <View style={styles.cardContainer}>
        <View style={styles.detailCard}>
          <AppText style={styles.cardTitle}>
            {strings('tableBookingDetailScreen.detailCard.title')}
          </AppText>
          <View style={styles.cardTitleLine} />

          <View style={{paddingTop: 10}}>
            <View style={styles.detailItem}>
              <AppText style={styles.detailLabel}>
                {strings('tableBookingDetailScreen.detailCard.bookingNumber')}
              </AppText>
              <AppText style={styles.detailValue}>
                {'TBG00' + tableBookingDetail?.id}
              </AppText>
            </View>

            <View style={[styles.detailItem, {marginVertical: 5}]}>
              <AppText style={styles.detailLabel}>
                {strings('tableBookingDetailScreen.detailCard.createdAt')}
              </AppText>
              <AppText style={styles.detailValue}>
                {tableBookingDetail?.created_at &&
                  moment(tableBookingDetail?.created_at).format(
                    'DD MMMM YYYY hh:mm A',
                  )}
              </AppText>
            </View>
            <View style={[styles.detailItem, {marginVertical: 5}]}>
              <AppText style={styles.detailLabel}>
                {strings('tableBookingDetailScreen.detailCard.bookingDate')}
              </AppText>
              <AppText style={styles.detailValue}>
                {tableBookingDetail?.booking_from &&
                  moment(tableBookingDetail?.booking_from).format(
                    'DD MMMM YYYY',
                  )}
              </AppText>
            </View>
            <View style={[styles.detailItem, {marginVertical: 5}]}>
              <AppText style={styles.detailLabel}>
                {strings('tableBookingDetailScreen.detailCard.bookingFrom')}
              </AppText>
              <AppText style={styles.detailValue}>
                {tableBookingDetail?.booking_from &&
                  moment(tableBookingDetail?.booking_from).format('hh:mm A')}
              </AppText>
            </View>
            <View style={[styles.detailItem, {marginVertical: 5}]}>
              <AppText style={styles.detailLabel}>
                {strings('tableBookingDetailScreen.detailCard.bookingTo')}
              </AppText>
              <AppText style={styles.detailValue}>
                {tableBookingDetail?.booking_to &&
                  moment(tableBookingDetail?.booking_to).format('hh:mm A')}
              </AppText>
            </View>
            <View style={[styles.detailItem, {marginVertical: 5}]}>
              <AppText style={styles.detailLabel}>
                {' '}
                {strings('tableBookingDetailScreen.detailCard.status')}
              </AppText>
              <AppText style={styles.detailValue}>
                {tableBookingDetail?.status.toUpperCase()}
              </AppText>
            </View>
            <View style={[styles.detailItem, {marginVertical: 5}]}>
              <AppText style={styles.detailLabel}>
                {strings('tableBookingDetailScreen.detailCard.paymentMethod')}
              </AppText>
              <AppText style={styles.detailValue}>
                {tableBookingDetail?.payment?.from_coin
                  ? 'COIN'
                  : tableBookingDetail?.payment?.payment_method?.type?.toUpperCase()}
              </AppText>
            </View>
          </View>
        </View>
      </View>
    );
  };

  const renderCustomerDetail = () => {
    return (
      <View style={styles.cardContainer}>
        <View style={styles.detailCard}>
          <AppText style={styles.cardTitle}>
            {strings('tableBookingDetailScreen.customerDetailCard.title')}
          </AppText>
          <View style={styles.cardTitleLine} />

          <View style={{paddingTop: 10}}>
            <View style={styles.detailItem}>
              <AppText style={styles.detailLabel}>
                {strings('tableBookingDetailScreen.customerDetailCard.name')}
              </AppText>
              <AppText style={styles.detailValue}>
                {tableBookingDetail?.user?.name}
              </AppText>
            </View>
            <View style={styles.detailItem}>
              <AppText style={styles.detailLabel}>
                {strings(
                  'tableBookingDetailScreen.customerDetailCard.mobileNo',
                )}
              </AppText>
              <AppText style={styles.detailValue}>
                {tableBookingDetail?.user?.mobile}
              </AppText>
            </View>
          </View>
        </View>
      </View>
    );
  };

  const renderSelectedTable = () => {
    let array = [];
    tableBookingDetail?.floors.map((o) => {
      o?.rooms.map((obj) => {
        obj?.tables.map((item) => {
          array.push({
            floor: o.name,
            room: obj.name,
            table: item.number,
            floorId: o.id,
            roomId: obj.id,
            tableId: item.id,
            capacity: item.capacity,
          });
        });
      });
    });

    return (
      <View style={styles.cardContainer}>
        <View style={styles.detailCard}>
          <AppText style={styles.cardTitle}>
            {strings('tableBookingDetailScreen.tableDetailCard.title')}
          </AppText>
          <View style={styles.cardTitleLine} />

          <View style={{paddingTop: 10}}>
            {/* header */}
            <View style={styles.orderDetailItem}>
              <View style={{width: '35%', justifyContent: 'center'}}>
                <AppText
                  style={[styles.itemName, {fontFamily: 'Roboto-Bold'}]}
                  numberOfLines={1}
                >
                  {strings('tableBookingDetailScreen.tableDetailCard.floor')}
                </AppText>
              </View>
              <View style={{width: '35%', justifyContent: 'center'}}>
                <AppText
                  style={[styles.itemName, {fontFamily: 'Roboto-Bold'}]}
                  numberOfLines={1}
                >
                  {strings('tableBookingDetailScreen.tableDetailCard.room')}
                </AppText>
              </View>
              <View
                style={{
                  width: '30%',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                }}
              >
                <AppText
                  style={[styles.priceText, {fontFamily: 'Roboto-Bold'}]}
                >
                  {strings('tableBookingDetailScreen.tableDetailCard.table')}
                </AppText>
              </View>
            </View>
            {/* body */}
            {array.map((o, i) => {
              return (
                <View style={styles.orderDetailItem} key={i}>
                  <View style={{width: '35%', justifyContent: 'center'}}>
                    <AppText style={styles.itemName} numberOfLines={1}>
                      {o.floor}
                    </AppText>
                  </View>
                  <View style={{width: '35%', justifyContent: 'center'}}>
                    <AppText style={styles.itemName} numberOfLines={1}>
                      {o.room}
                    </AppText>
                  </View>
                  <View
                    style={{
                      width: '30%',
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                    }}
                  >
                    <AppText style={styles.priceText}>
                      {'Table -' + o.table + ' (' + o.capacity + ')'}
                    </AppText>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      </View>
    );
  };

  const renderButtons = () => {
    return tableBookingDetail?.status == 'paid' ? (
      <>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TouchableOpacity
            style={[styles.btn, styles.declineBtn]}
            onPress={() => updateSubscriptionStatus('declined')}
          >
            <AppText style={styles.declineBtnText}>
              {strings('ordering.orderStatusPending.decline')}
            </AppText>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.btn, styles.acceptBtn]}
            onPress={() => updateSubscriptionStatus('accepted')}
          >
            <AppText style={styles.acceptBtnText}>
              {strings('ordering.orderStatusPending.accept')}
            </AppText>
          </TouchableOpacity>
        </View>
      </>
    ) : tableBookingDetail?.status == 'accepted' ? (
      <TouchableOpacity
        style={[styles.btn, styles.acceptBtn, {width: '100%'}]}
        onPress={() => updateSubscriptionStatus('completed')}
      >
        <AppText style={styles.acceptBtnText}>
          {strings('ordering.orderStatusPending.completed')}
        </AppText>
      </TouchableOpacity>
    ) : null;
  };

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <MainHeader
        title={strings('tableBookingDetailScreen.title')}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      {!loading && (
        <MainContainer>
          {renderSteps()}
          {renderDetail()}
          {renderCustomerDetail()}
          {renderSelectedTable()}
          {renderButtons()}
          <View style={{height: 50}} />
        </MainContainer>
      )}
    </View>
  );
};

export default TableBookingDetail;
