import React, {useState} from 'react';
import {Image, View} from 'react-native';
import {useDispatch} from 'react-redux';
import {AppText, MainContainer, MainHeader, WebView} from '../../../components';
import {strings} from '../../../i18n';
import {goBack, screenHeight} from '../../../utils';
import styles from './styles';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import axios from 'axios';

const BlogDetail = (props) => {
  const {item} = props?.route?.params;
  const dispatch = useDispatch();

  const deleteBlog = async (id) => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios.delete('/blog/delete/' + item?.id).then((response) => {
        console.log('response --> ', response);
        dispatch(CommonActions.setLoading(false));
        goBack(props.navigation);
      });
    } catch (error) {
      dispatch(CommonActions.setLoading(false));
      console.log('message', error?.response);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };

  return (
    <View style={{flex: 1}}>
      <MainHeader
        title={item?.title} // after we add in language json file
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
        rightOtherIcon={'edit'}
        rightIcon={'dustBinSmaller'}
        rightOtherPress={() =>
          props.navigation.navigate('edit-blog', {
            item: item,
            edit: true,
          })
        }
        rightPress={() =>
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: strings('blogList.askDelete'),
              rightButton: strings('settingScreen.confirm'),
              rightPress: async () => {
                deleteBlog(item?.id);
              },
              leftButton: strings('settingScreen.cancel'),
              leftPress: null,
            }),
          )
        }
      />
      <MainContainer contentContainerStyle={{flexGrow: 1}} showFullWidth>
        <View style={styles.blogItem}>
          <Image
            source={
              item?.image
                ? {uri: item?.image}
                : require('../../../../assets/images/General/no-img-found.jpg')
            }
            style={[styles.blogImage, {aspectRatio: 30 / 17}]}
          />
          {item?.title && (
            <AppText style={styles.blogTitle}>{item?.title}</AppText>
          )}
          {item?.description && (
            <AppText style={styles.blogDesc}>{item?.description}</AppText>
          )}
          <WebView
            originWhitelist={['*']}
            source={{
              html: `<html><head><meta name="viewport" content="width=device-width, initial-scale=1.0"></head><body>${item?.body}</body></html>`,
            }}
            style={{
              height: 500,
              marginTop: 10,
              // border: "1px solid ",
            }}
            textStyle={{fontSize: 14}}
            automaticallyAdjustContentInsets={true}
            scrollEnable={true}
            nestedScrollEnabled={true}
          />
        </View>
        <View style={{height: 50}} />
      </MainContainer>
    </View>
  );
};

export default BlogDetail;
