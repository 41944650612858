import {StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {screenWidth, setValue, setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  container: {
    marginBottom: 15,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    backgroundColor: Colors.white,
    borderRadius: 5,
    shadowColor: Colors.black60,
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 5,
  },
  btnContainer: {
    position: 'absolute',
    zIndex: 1000,
    right: 10,
    top: 20,
  },
  deleteIcon: {
    backgroundColor: 'red',
    padding: 8,
    borderRadius: 3,
    elevation: 5,
  },
  editIcon: {
    marginTop: 10,
    backgroundColor: Colors.white,
    padding: 8,
    borderRadius: 3,
    elevation: 5,
  },
  imageContainer: {
    backgroundColor: Colors.white,
    borderRadius: 5,
  },
  image: {
    // height: 120,
    width: '100%',
    borderRadius: 5,
    // aspectRatio: 10 / 4,
    aspectRatio: screenWidth > 576 ? 4 / 1 : 10 / 4,
  },
  textInputContainer: {
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 5,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
  },
  inputLabel: {
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    fontFamily: 'Roboto-Bold',
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  textInput: {
    height: setValue(50),
    color: Colors.black20,
    flex: 1,
    paddingHorizontal: setXAxisValue(10),
  },
  textInputWeb: {
    height: setValue(50),
    padding: setValue(10),
    flex: 1,
    paddingHorizontal: setXAxisValue(10),
  },
});
