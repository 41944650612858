import React, {useState, useEffect, useRef} from 'react';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {useDispatch, useSelector} from 'react-redux';
import {
  Image,
  FlatList,
  Platform,
  TextInput,
  View,
  ActivityIndicator,
  TouchableOpacity,
} from 'react-native';
import {Colors} from '../../constants';
import {NoRecordFound, AppText, MainHeader} from '../../components';
import {goBack, setValue} from '../../utils';
import styles from './styles';
import moment from 'moment';
import {dateFormat} from '../../utils';
import * as ImagePicker from 'expo-image-picker';
import {strings} from '../../i18n';
import axios from 'axios';
import {AntDesign} from '@expo/vector-icons';

const ActiveChatList = (props) => {
  const [lists, setLists] = useState([]);
  const [searchBar, setSearchBar] = useState(false);
  const [searchText, setSearchText] = useState('');

  const dispatch = useDispatch();
  const {config, common} = useSelector((state) => state);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    props.navigation.addListener('focus', () => {
      getChatListData();
      dispatch(CommonActions.setLoading(true));
    });
  }, [props.navigation]);

  const getChatListData = async (search) => {
    const url =
      search && search != ''
        ? `/support/list?search=` + search
        : '/support/list';
    setLoading(true);
    await axios
      .get(url)
      .then((response) => {
        // let sortResponse = response?.data?.sort(
        //   (a, b) =>
        //    new Date(b?.last_message_date) - new Date(a?.last_message_date),
        // );

        setLists(response.data);
        setLoading(false);
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        setLoading(false);
        dispatch(CommonActions.setLoading(false));
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.message,
          }),
        );
      });
  };

  const chaListDateFormate = (dateString) => {
    const date = new Date(dateString);
    const dualize = (x) => (x < 10 ? '0' + x : x);

    const getTime = () =>
      (date.getHours() > 12
        ? dualize(date.getHours() - 12)
        : dualize(date.getHours())) +
      ':' +
      dualize(date.getMinutes()) +
      ' ' +
      moment(date).format('A');
    const dateDiff = Math.round((new Date() - date) / (1000 * 60 * 60 * 24));
    if (dateDiff === 0) {
      return getTime();
    } else if (dateDiff === 1) {
      return 'Yesterday';
    } else {
      return dateFormat(date);
    }
  };
  const onChangeScreen = (item) => {
    let newList = [];
    lists.forEach((a) => {
      if (a.id == item?.id) {
        a.unReadMessage = 0;
      }
      newList.push(a);
    });
    setLists([...newList]);
    props.navigation.navigate('live-support', {
      // ...item,
      // order: {
      //   id: item.order,
      // },
      type: item?.type,
      id: item?.type == 'order' ? item?.order : item?.product,
      chatId: item?.id,
      photo: item?.user_photo,
    });
  };
  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('activeChatListScreen.title')}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
        rightIcon={'search'}
        rightPress={() => setSearchBar(!searchBar)}
      />
      <View
        style={
          Platform.OS == 'web'
            ? {
                height: '90vh',
                maxWidth: 480,
                width: '100%',
                alignSelf: 'center',
              }
            : {
                maxWidth: 400,
                width: '100%',
                alignSelf: 'center',
                flex: 1,
              }
        }
      >
        {searchBar && (
          <View style={styles.searchBar}>
            <TextInput
              style={styles.searchTextInput}
              placeholder={strings('activeChatListScreen.search')}
              placeholderTextColor={Colors.color30}
              value={searchText}
              onChangeText={async (text) => {
                setSearchText(text);
                getChatListData(text);
              }}
            />
            <TouchableOpacity
              onPress={() =>
                searchText !== '' ? setSearchText('') : setSearchBar(false)
              }
            >
              <AntDesign name="close" size={24} color={Colors.color30} />
            </TouchableOpacity>
          </View>
        )}
        {!common.loading && (
          <View style={{flex: 1}}>
            {loading ? (
              <View style={styles.loadingContainer}>
                <ActivityIndicator color={Colors.theme_color} size={40} />
              </View>
            ) : lists.length == 0 ? (
              <NoRecordFound
                title={strings('activeChatListScreen.noRecord.title')}
                message={strings('activeChatListScreen.noRecord.message')}
              />
            ) : (
              <FlatList
                data={lists}
                keyExtractor={(item, index) => index}
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                renderItem={({item}) => {
                  // console.log('item', item);
                  return (
                    <TouchableOpacity
                      onPress={() => onChangeScreen(item)}
                      key={item?.id}
                      style={styles.chatUserContainer}
                    >
                      <Image
                        style={styles.chatUserImage}
                        source={
                          item?.user_photo
                            ? {uri: item?.user_photo}
                            : require('../../../assets/images/General/profile.png')
                        }
                      />
                      <View style={styles.chatUserDetailContainer}>
                        <View style={styles.userDetailSubContainer}>
                          <AppText
                            numberOfLines={1}
                            style={styles.chatUserName}
                          >
                            {item?.type == 'order'
                              ? 'ORD00' + item?.order
                              : 'PRD00' + item?.product}
                          </AppText>
                          <AppText style={styles.date}>
                            {chaListDateFormate(item?.last_message_date)}
                          </AppText>
                        </View>
                        <View style={styles.userDetailSubContainer}>
                          <AppText
                            numberOfLines={1}
                            style={styles.chatUserLastMessage}
                          >
                            {item?.last_message}
                          </AppText>
                          {parseInt(item?.unReadMessage) > 0 ? (
                            <AppText style={styles.badge}>
                              {item?.unReadMessage}
                            </AppText>
                          ) : null}
                        </View>
                      </View>
                    </TouchableOpacity>
                  );
                }}
              />
            )}
          </View>
        )}
      </View>
    </View>
  );
};
export default ActiveChatList;
