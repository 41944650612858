import get from 'lodash.get';
import React, {Component} from 'react';
import {
  FlatList,
  Keyboard,
  TextInput,
  TouchableOpacity,
  View,
  ActivityIndicator,
} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  AppText,
  Icon,
  MainContainer,
  MainHeader,
  NoRecordFound,
  PromotionItem,
} from '../../../components';
import {strings} from '../../../i18n';
import {getPromotionList} from '../../../redux/reduxsauceActions/promotionActions';
import styles from './styles';
import {Helmet} from 'react-helmet';
class PromotionListScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      fetching: false,
      searchKeyWord: '',
      companyId: null,
      outletId: '',
      loadMore: false,
      loading: false,
      promotionList: [],
    };
    this.currencySymbol = get(props.config, 'currencySymbol', '₹');
    this.onEndReachedCalledDuringMomentum = true;
  }

  componentDidMount() {
    this.props.getPromotionList();
  }

  handleRefresh = async () => {
    // this.setState({fetching: true});
    // await this.props.getPromotionsByPage(
    //   1,
    //   this.state.searchKeyWord,
    //   this.state.companyId,
    //   this.state.outletId,
    // );
    this.props.getPromotionList();
    // this.setState({page: 1, fetching: false});
  };

  handleLoadMore = () => {
    const {fetching, loadMore} = this.state;

    // if (!fetching && !loadMore && !this.onEndReachedCalledDuringMomentum) {
    //   this.setState(
    //     prev => ({page: prev.page + 1, loadMore: true}),
    //     async () => {
    //       const {page} = this.state;
    //       await this.props.getPromotionsByPage(
    //         page,
    //         this.state.searchKeyWord,
    //         this.state.companyId,
    //         this.state.outletId,
    //       );
    //       this.setState({loadMore: false});
    //       this.onEndReachedCalledDuringMomentum = true;
    //     },
    //   );
    // }
  };

  renderSearchPanel = () => {
    return <View>{this.renderSearchBar()}</View>;
  };

  renderSearchBar = () => {
    return (
      <View style={styles.searchBarContainer}>
        <Icon name="search" size={22} color="gray" />
        <TextInput
          style={styles.searchBarText}
          placeholder={strings('common.search')}
          underlineColorAndroid="transparent"
          keyboardType="default"
          enablesReturnKeyAutomatically
          returnKeyType="search"
          blurOnSubmit
          multiline={false}
          onBlur={() => {
            Keyboard.dismiss();
          }}
          autoCorrect={false}
          defaultValue={this.state.searchKeyWord}
          onChangeText={(text) => {
            this.setState({searchKeyWord: text}, () => this.handleRefresh());
          }}
        />
        <TouchableOpacity
          onPress={() => {
            this.setState({searchKeyWord: '', companyId: null}, () =>
              this.handleRefresh(),
            );
          }}
        >
          <Icon name="cross" size={22} color="gray" />
        </TouchableOpacity>
      </View>
    );
  };

  renderAddPromotion = () => {
    return (
      <TouchableOpacity
        style={styles.addPromotion}
        onPress={() => this.props.navigation.navigate('create-promotion')}
      >
        <AppText style={styles.addPromotionText}>
          {strings('PromotionListScreen.createNew')}
        </AppText>
      </TouchableOpacity>
    );
  };

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   this.setState({promotionList: nextProps.promotionData});
  // }

  render() {
    const {promotionList, fetching, navigation, user, config} = this.props;

    return (
      <View style={styles.container}>
        <Helmet>
          <meta
            name="description"
            content="India's biggest online store for Mobiles, Fashion (Clothes/Shoes), Electronics, Home Appliances, Books, Home, Furniture, Grocery, Jewelry, Sporting goods, Beauty & Personal Care and more! Find the largest selection from all brands at the lowest prices in India. Payment options - COD, EMI, Credit card, Debit card &amp; more."
          />
        </Helmet>
        <MainHeader
          leftIcon={'back'}
          leftPress={() => this.props.navigation.goBack()}
          title={strings('PromotionListScreen.promotions')}
          navigation={this.props.navigation}
        />
        <MainContainer useView>
          {['merchant', 'manager'].includes(user?.type) &&
            this.renderAddPromotion()}
          {/* {this.companyIds.length > 1 && this.renderSearchPanel()} */}
          {promotionList.length != 0 ? (
            <FlatList
              removeClippedSubviews={false}
              data={promotionList}
              renderItem={({item, index}) => (
                <PromotionItem
                  item={item}
                  navigation={navigation}
                  // currencySymbol={this.currencySymbol}
                />
              )}
              keyExtractor={(item, index) => String(index)}
              keyboardShouldPersistTaps="always"
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              refreshing={this.state.fetching}
              onRefresh={this.handleRefresh}
              onEndReached={this.handleLoadMore}
              onEndReachedThreshold={0.5}
              onMomentumScrollBegin={() => {
                this.onEndReachedCalledDuringMomentum = false;
              }}
              ListFooterComponent={() => (
                <View style={{paddingTop: 15, paddingBottom: 20}}>
                  {this.state.loadMore && <ActivityIndicator color="#000" />}
                </View>
              )}
            />
          ) : (
            <View style={{flex: 1}}>
              <NoRecordFound
                title="No Offer Active ...)"
                message="We'll notify you when there is something now!"
              />
            </View>
          )}
        </MainContainer>
      </View>
    );
  }
}

export default connect(
  (state) => ({
    promotionList: state.promotion,
    user: state.auth.user,
    config: state.config,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getPromotionList,
      },
      dispatch,
    ),
)(PromotionListScreen);
