import {StyleSheet} from 'react-native';

import {Colors, Layout} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  informationContainer: {
    shadowColor: Colors.black,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    width: '100%',
    marginVertical: setYAxisValue(40),
    paddingHorizontal: setYAxisValue(10),
  },
  information: {
    backgroundColor: Colors.theme_color,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: setYAxisValue(10),
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  informationText: {
    fontSize: setXAxisValue(14),
    color: Colors.white,
    // fontFamily: ''
  },
  descriptionText: {
    fontSize: setXAxisValue(12),
    textAlign: 'center',
    color: Colors.black40,
    lineHeight: setYAxisValue(20),
    paddingVertical: setYAxisValue(20),
    paddingHorizontal: setXAxisValue(20),
  },
  textInputContainer: {
    width: '100%',
    height: setValue(60),
  },
  otpTextInputContainer: {
    width: '100%',
  },
  textInput: {
    width: '100%',
    flex: 1,
    height: setValue(50),
    padding: 0,
    paddingHorizontal: setXAxisValue(5),
    marginBottom: setYAxisValue(10),
    marginHorizontal: setXAxisValue(3),
    borderRadius: 5,
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
  },
  inputRightSide: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    right: setValue(10),
    alignSelf: 'center',
    top: setValue(15),
    elevation: 10,
  },
  dividerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider: {
    flex: 1,
    height: StyleSheet.hairlineWidth,
    backgroundColor: Colors.black20,
  },
  orText: {
    marginVertical: setXAxisValue(15),
    marginHorizontal: setYAxisValue(20),
    textAlign: 'center',
  },
});
export default styles;
