import {Platform, StyleSheet} from 'react-native';
import {Colors} from '../../../constants';
import {setXAxisValue, setYAxisValue, setValue} from '../../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  webContainer: {
    overflow: 'scroll',
    height: '100vh',
    // marginVertical: 20,
  },
});
