import {StyleSheet} from 'react-native';

import {Colors, Layout} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: Colors.overlay,
  },
  overlayMobile: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'flex-end',
  },
  calendarContainer: {
    flexDirection: 'row',
    marginVertical: 5,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  datePickerBground: {
    backgroundColor: Colors.white,
  },
  datePicker: {
    backgroundColor: Colors.white,
  },
  textInput: {
    height: setValue(50),
    padding: 0,
    textAlign: 'center',
    paddingHorizontal: setXAxisValue(5),
    marginBottom: setYAxisValue(10),
    borderRadius: 5,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
  },
  buttons: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: Colors.white,
    paddingHorizontal: setYAxisValue(15),
    paddingTop: setXAxisValue(5),
    alignItems: 'center',
  },
  button: {
    fontSize: setXAxisValue(18),
    textTransform: 'uppercase',
  },
});
