import Constants from 'expo-constants';
import {StyleSheet} from 'react-native';

import {Colors, Layout} from '../../../constants';
import {screenWidth, setValue, setXAxisValue, setYAxisValue} from '../../../utils';


const wrapper = {
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
    padding: setValue(5),
  };

export default StyleSheet.create({
 container: {
    flex: 1,
  },
  buttonContainer: {
    backgroundColor: Colors.white,
    paddingVertical: setYAxisValue(15),
  },
  buttonContainerWeb: {
    backgroundColor: Colors.white,
    marginTop:setValue(50)
  },
  buttonButton: {
    width: '100%',
    backgroundColor: Colors.theme_color,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    bottom: 0,
    position: 'absolute',
    paddingVertical: setYAxisValue(15),
  },
  buttonButtonWeb: {
    width: '100%',
    backgroundColor: Colors.theme_color,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 5,
    bottom: 0,
    position: 'absolute',
    paddingVertical: setYAxisValue(15),
    ...wrapper,
  },
  buttonText: {
    fontSize: setXAxisValue(14),

    color: Colors.white,
  },
  textInputContainer: {
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 5,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
  },
  infoTitle: {
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  infoText: {
    width: '60%',
    fontSize: setXAxisValue(12),
    paddingBottom: setYAxisValue(20),
  },
  inputLabel: {
    // color: Colors.black,
    // fontSize: setValue(17),
    // paddingBottom: setYAxisValue(7),
    fontFamily:"Roboto-Bold",
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  textInput: {
    height: setValue(50),
    padding: 0,
    flex: 1,
    paddingHorizontal: setXAxisValue(10),
  },
  textInputWeb: {
    height: setValue(50),
    padding: setValue(10),
    flex: 1,
    paddingHorizontal: setXAxisValue(10),
  },
})