import axios from 'axios';
import {createActions, createReducer} from 'reduxsauce';

const INITIAL_STATE = {
  connected: false,
  liveData: [],
};

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  setConnected: ['connected'],
  setLiveData: ['payload'],
});

export const LiveTypes = Types;
export default Creators;

/* ------------- Reducers ------------- */

const setConnected = (state, action) => {
  let data = action.connected;
  return {
    ...state,
    connected: data,
  };
};

const setLiveData = (state, action) => {
  let array = [...state.liveData];
  // console.log('action=======', action.payload);
  // console.log('state=======', state);
  
  let data = [];
  data.push(action.payload);
  // console.log("data", data);
  return {...state, liveData: [...array, ...data]};
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_CONNECTED]: setConnected,
  [Types.SET_LIVE_DATA]: setLiveData,
});
