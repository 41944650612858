import React from 'react';
import {TouchableOpacity,  View,  Platform} from 'react-native';
import styles from './styles';
import AppText from '../AppText';

export default function SelectDateItem(props) {
  const {item,index, selectedItem} = props;
  return (
    <TouchableOpacity
      style={[
        styles.selectDateItem,
        selectedItem === item ? styles.selected : null,
      ]}
      onPress={() => {
        props.OnPressDate();
      }}
      key={index}
    >
      {Platform.OS !== 'web' ? (
        <AppText
          style={
            ([styles.selectDateButtonText],
            selectedItem === item
              ? styles.selectedselectDateButtonText
              : styles.selectDateButtonText)
          }
          numberOfLines={1}
        >
          {item + ' '}
        </AppText>
      ) : (
        <View
          style={
            selectedItem === item
              ? styles.selectedselectDateTextWebContainer
              : styles.selectDateTextWebContainer
          }
        >
          <AppText
            style={
              selectedItem === item.id
                ? styles.selectedselectDateButtonTextWeb
                : styles.selectDateButtonTextWeb
            }
            numberOfLines={1}
          >
            {item + ' '}
          </AppText>
        </View>
      )}
    </TouchableOpacity>
  );
}
