import axios from 'axios';
import get from 'lodash.get';
import React, {Component} from 'react';
import {
  FlatList,
  TouchableOpacity,
  View,
  ActivityIndicator,
  Platform,
} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  AppText,
  MainContainer,
  MainHeader,
  NoRecordFound,
  SubscriptionItem,
} from '../../components';
import {Colors} from '../../constants';
import {goBack} from '../../utils';
import styles from './styles';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import moment from 'moment';
import {strings} from '../../i18n';

class BookingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      refreshing: false,
      bookingList: [],
      limit: 10,
      offset: 0,
      loading: false,
      totalCount: 0,
    };
    this.currencySymbol = get(props.appConfig, 'currencySymbol', '₹');
    this.pageActive = 1;
    this.pageCompleted = 1;
    this.onEndReachedCalledDuringMomentum = {
      active: true,
      completed: true,
    };
  }

  componentDidMount = () => {
    const {navigation} = this.props;

    this.setState(
      {
        offset: 0,
        searchText: '',
        bookingList: [],
      },
      () => {
        console.log('calling');
        this.fetchOrderList();
      },
    );
    navigation.addListener('focus', () => {
      this.handleRefresh();
    });
  };

  fetchOrderList = () => {
    this.setState({loading: true});
    try {
      axios
        .get('/hospitality/booking/merchant/list')
        .then((response) => {
          console.log('response bookings-------------->>>>>>>>>', response);
          this.setState({
            bookingList: response?.data,
            loading: false,
          });
        })
        .catch((error) => {
          console.log('error-->', error);
          this.setState({loading: false});
        });
    } catch (error) {
      this.setState({loading: false});
      this.props.onpenAlert(error?.response?.message);
      console.log('error---->', error);
    }
  };

  handleRefresh = () => {
    this.setState(
      {
        offset: 0,
        searchText: '',
        bookingList: [],
      },
      () => this.fetchOrderList(),
    );
  };

  handleLoadMore = (status) => {
    const {bookingList} = this.state;
    console.log('aa->');
    this.setState(
      {
        offset: bookingList.length,
      },
      () => this.fetchOrderList(),
    );
  };

  renderCurrentBooking = () => {
    const {bookingList} = this.state;
    return (
      <View style={{paddingTop: 10, flex: 1}}>
        <FlatList
          data={bookingList}
          renderItem={({item}) => (
            <TouchableOpacity
              // onPress={() => {
              //   this.props.navigation.navigate('booking-detail', {
              //     id: item.id,
              //   });
              // }}
            >
              <View style={styles.itemContainer}>
                <View style={styles.topContainer}>
                  <AppText style={styles.orderUId}>{'ACM00' + item.id}</AppText>
                </View>
                <View style={styles.bottomContainer}>
                  <AppText>
                    <AppText style={{fontWeight: 'bold'}}>
                      {'Check In:'}
                    </AppText>
                    <AppText style={styles.orderUId}>
                      {moment(item.check_in).format('DD MMM YYYY hh:mm A')}
                    </AppText>
                  </AppText>
                  <AppText
                    style={
                      item?.status === 'paid'
                        ? [styles.orderPrice, {color: '#056eb0'}]
                        : item?.status === 'confirmed'
                        ? [styles.orderPrice, {color: '#008c96'}]
                        : item?.status === 'declined'
                        ? [styles.orderPrice, {color: '#f69c00'}]
                        : item?.status === 'completed'
                        ? [styles.orderPrice, {color: '#05a912'}]
                        : styles.orderPrice
                    }
                  >
                    {item?.status?.toUpperCase()}
                  </AppText>
                </View>
                <View style={styles.bottomContainer}>
                  <AppText>
                    <AppText style={{fontWeight: 'bold'}}>
                      {'Check Out:'}
                    </AppText>
                    <AppText style={styles.orderUId}>
                      {moment(item.check_out).format('DD MMM YYYY hh:mm A')}
                    </AppText>
                  </AppText>
                </View>
              </View>
            </TouchableOpacity>
          )}
          refreshing={this.state.refreshing}
          onRefresh={() => this.handleRefresh('active')}
          keyboardShouldPersistTaps="always"
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          keyExtractor={(item) => item.id}
        />
      </View>
    );
  };

  render() {
    const {bookingList} = this.state;

    return (
      <View style={styles.container}>
        <MainHeader
          title={'Booking History'}
          leftIcon="back"
          leftPress={() => goBack(this.props.navigation)}
        />
        <MainContainer useView>
          <View useView style={{flex: 1}}>
            {bookingList?.length == 0 ? (
              <NoRecordFound
                title="Bookings"
                message="We'll notify you when there is something now!"
                hideImage={true}
              />
            ) : (
              this.renderCurrentBooking()
            )}
          </View>
        </MainContainer>
      </View>
    );
  }
}

export default connect(
  (state) => ({
    appConfig: state.config,
    user: state.auth.user,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setLoading: CommonActions.setLoading,
        onpenAlert: (content) =>
          CommonActions.setAlert({visible: true, content: content}),
      },
      dispatch,
    ),
)(BookingList);
