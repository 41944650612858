import React, { Component } from 'react';
import { KeyboardAvoidingView, Platform, TextInput, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { LongButton, MainContainer, MainHeader } from '../../../components';
import { Colors } from '../../../constants';
import { strings } from '../../../i18n';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import { sendVerifyEmail } from '../../../redux/reduxsauceActions/profileActions';
import { goBack, isEmail, setYAxisValue } from '../../../utils';
import styles from './styles';

class UpdateEmailScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
  }

  onConfirm = () => {
    const { navigation } = this.props;
    const { email } = this.state;
    if (!email || !isEmail(email.trim())) {
      this.props.openAlert(strings('registerScreen.validate.email'));
    } else {
      // this.props.sendEmail(email, navigation);
    }
  };

  render() {
    const { navigation } = this.props;
    const { email } = this.state;
    return (
      <View style={styles.container}>
        <MainHeader title={strings('updateEmailScreen.updateEmail')} leftIcon="back" leftPress={() => goBack(navigation)} />
        <MainContainer useView>
          <KeyboardAvoidingView style={styles.body} behavior={Platform.OS === 'ios' ? 'padding' : 'height'} keyboardVerticalOffset={setYAxisValue(60)}>
            <View style={styles.textInputContainer}>
              <TextInput
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={strings('updateEmailScreen.placeHolder.email')}
                placeholderTextColor={Colors.color30}
                keyboardType="email-address"
                returnKeyType="done"
                style={styles.textInput}
                clearButtonMode="always"
                value={email}
                onChangeText={text => this.setState({ email: text })}
              />
            </View>
            <LongButton text={strings('updateEmailScreen.confirm')} style={styles.button} onPress={this.onConfirm} />
          </KeyboardAvoidingView>
        </MainContainer>
      </View>
    );
  }
}

export default connect(null, dispatch =>
  bindActionCreators(
    {
      openAlert: content => CommonActions.setAlert({ visible: true, content }),
    },
    dispatch
  )
)(UpdateEmailScreen);
