import React, {useState, useEffect} from 'react';
import {Text, Platform, Modal, View, TouchableOpacity} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';
import styles from './styles';
// import {addToCart} from '../../redux/reduxsauceActions/cartAction';
import {strings} from '../../i18n';
import {cashFormat} from '../../utils';
import {AppText} from '../';
import {Colors} from '../../constants';
import {get} from 'lodash';

const SelectProductVeriantsModal = (props) => {
  const {isVisible, onClose} = props;
  const config = useSelector((state) => state.config);
  let currencySymbol = get(config, 'currencySymbol', '₹');
  const dispatch = useDispatch();

  const [selectedVariants, setSelectedVariants] = useState([]);
  const [item, setItem] = useState({});
  useEffect(() => {
    setItem(props?.item);
  }, [props.isVisible]);
  const textColor = Colors.white;

  const selectUnselectVariants = (type, value) => {
    if (
      selectedVariants.length > 0 &&
      selectedVariants.some((o) => o.value == value)
    ) {
      let arr = selectedVariants.filter((o) => o.value != value);
      setSelectedVariants(arr);
    } else if (
      selectedVariants.length > 0 &&
      selectedVariants.some((o) => o.type == type)
    ) {
      let arr = selectedVariants;
      let index = selectedVariants.findIndex((o) => o.type == type);
      arr[index] = {
        ...arr[index],
        value: value,
      };
      setSelectedVariants([...arr]);
    } else {
      setSelectedVariants([...selectedVariants, {type: type, value: value}]);
    }
  };

  const renderVariants = () => {
    let variants = item?.variants;
    return (
      <View style={styles.otherSectionContainer}>
        {variants?.length > 0 &&
          variants?.map((o, index) => (
            <View
              style={{
                marginBottom:
                  variants?.length > 0 && variants?.length - 1 != index
                    ? 10
                    : 0,
              }}
              key={index}
            >
              <AppText
                style={[
                  styles.optionGroupTitle,
                  {marginTop: 0, marginBottom: 5},
                ]}
              >
                {o.type}
              </AppText>
              <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                {o.variants &&
                  o.variants?.map((v, i) => {
                    const selectedColor =selectedVariants.some((o) => o.value == v.value)
                      ? Colors.white
                      : Colors.black20;

                    return (
                      <TouchableOpacity
                        style={
                          selectedVariants.some((o) => o.value == v.value)
                            ? styles.selectedVariants
                            : styles.variants
                        }
                        key={i}
                        onPress={() => selectUnselectVariants(o.type, v.value)}
                      >
                        <AppText style={{fontSize: 16, color: selectedColor}}>
                          {v.value}
                        </AppText>
                        <AppText style={{fontSize: 12, color: selectedColor}}>
                          {currencySymbol + ' ' + v.price}
                        </AppText>
                      </TouchableOpacity>
                    );
                  })}
              </View>
            </View>
          ))}
      </View>
    );
  };

  const getCalculateTotalPrice = () => {
    const variants = item?.variants;
    let verientsPrice = 0;
    selectedVariants.forEach((o) => {
      let variantIndex = variants.findIndex((v) => v.type == o.type);
      let price = variants[variantIndex]?.variants?.find(
        (v) => o.value == v.value,
      )?.price;
      verientsPrice += Number(price);
    });
    return Number(item?.price) + Number(verientsPrice);
  };
  return (
    <Modal animationType={'fade'} transparent visible={isVisible}>
      <View style={styles.modal}>
        <View style={styles.modalContainer}>
          <View style={styles.modalDataContainer}>
            <Text style={styles.modalTitle}>
              {strings('selectVeriant.title')}
            </Text>
            {renderVariants()}
          </View>
          <View style={styles.buttonContainer}>
            <TouchableOpacity
              disabled={
                selectedVariants.length == item?.variants?.length ? false : true
              }
              style={[
                styles.addQuantityButton,
                {flex: 3},
                selectedVariants.length == item?.variants?.length
                  ? null
                  : styles.disabledButton,
              ]}
              onPress={
                props.addToProduct
                  ? () => {
                      props.addToProduct({
                        ...item,
                        final_price: getCalculateTotalPrice(),
                        selectedVariants: selectedVariants,
                      });
                      setSelectedVariants([]);
                      onClose(!isVisible);
                    }
                  : () => {
                      // dispatch(
                      //   addToCart({
                      //     ...item,
                      //     final_price: getCalculateTotalPrice(),
                      //     selectedVariants: selectedVariants,
                      //   }),
                      // );
                      setSelectedVariants([]);
                      onClose(!isVisible);
                    }
              }
            >
              <Text style={{color: textColor}}>
                {`${strings(
                  'ordering.productDetail.add',
                )}  - ${currencySymbol} ${cashFormat(
                  getCalculateTotalPrice(),
                )}`}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.addQuantityButton, {flex: 2}]}
              onPress={() => {
                setSelectedVariants([]);
                onClose(!isVisible);
              }}
            >
              <Text style={{color: textColor}}>
                {strings('selectVeriant.cancel')}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};
export default SelectProductVeriantsModal;
