import {View, Text, TextInput, TouchableOpacity} from 'react-native';
import React, {useState} from 'react';
import {
  AppText,
  ClientAppImage,
  MainContainer,
  MainHeader,
} from '../../components';
import {strings} from '../../i18n';
import {goBack} from '../../utils';
import styles from './styles';
import {Colors} from '../../constants';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {updateSplashList} from '../../redux/reduxsauceActions/configActions';
import CommonAction from '../../redux/reduxsauce/commonRedux';

const IntroductionSetting = props => {
  const config = useSelector(state => state.config);
  const dispatch = useDispatch();
  const [introductionData, setIntroductionData] = useState({
    image: '',
    header: '',
    description: '',
  });

  useEffect(() => {
    props.route.params?.edit &&
      setIntroductionData({
        image: props.route.params?.item?.image,
        header: props.route.params?.item?.header,
        description: props.route.params?.item?.description,
      });
  }, []);

  const saveIntroduction = async () => {
    if (!introductionData.image || introductionData.image == '') {
      dispatch(
        CommonAction.setAlert({visible: true, content: 'Please select image.'}),
      );
    } else if (!introductionData.header || introductionData.header == '') {
      dispatch(
        CommonAction.setAlert({visible: true, content: 'Header is required.'}),
      );
    } else {
      dispatch(CommonAction.setLoading(true));
      let array = (config?.splash?.length > 0 && [...config?.splash]) || [];
      array = [...array, {...introductionData}];
      await dispatch(updateSplashList(array));
      dispatch(CommonAction.setLoading(false));
      goBack(props.navigation);
    }
  };

  const updateIntroduction = async () => {
    if (!introductionData.image || introductionData.image == '') {
      dispatch(
        CommonAction.setAlert({visible: true, content: 'Please select image.'}),
      );
    } else if (!introductionData.header || introductionData.header == '') {
      dispatch(
        CommonAction.setAlert({visible: true, content: 'Header is required.'}),
      );
    } else {
      dispatch(CommonAction.setLoading(true));
      let array = (config?.splash?.length > 0 && [...config?.splash]) || [];
      array[props.route.params?.index] = {
        ...introductionData,
      };
      console.log('new edited array', array);
      await dispatch(updateSplashList(array));
      dispatch(CommonAction.setLoading(false));
      goBack(props.navigation);
    }
  };

  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('settingScreen.introductionSetting')}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer useView>
        <View style={{marginTop: 10}}>
          <ClientAppImage
            image={introductionData.image}
            type={'splash'}
            styles={{
              width: '90%',
              aspectRatio: 1 / 1,
              alignSelf: 'center',
            }}
            ChangeClientAppImage={url => {
              console.log('url', url);
              setIntroductionData({...introductionData, image: url});
            }}
          />
        </View>
        <View style={styles.textInputContainer}>
          <AppText style={styles.inputLabel}>{'Header'}</AppText>
          <TextInput
            underlineColorAndroid="transparent"
            autoCorrect={false}
            placeholder={'Header'}
            placeholderTextColor={Colors.color30}
            returnKeyType="done"
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={introductionData.header}
            onChangeText={text => {
              setIntroductionData({...introductionData, header: text});
            }}
          />
        </View>
        <View style={styles.textInputContainer}>
          <AppText style={styles.inputLabel}>{'Description'}</AppText>
          <TextInput
            underlineColorAndroid="transparent"
            multiline={true}
            autoCorrect={false}
            placeholder={'Description'}
            placeholderTextColor={Colors.color30}
            returnKeyType="done"
            style={
              Platform.OS == 'web'
                ? styles.textInputWeb
                : [styles.textInput, {height: 100, textAlignVertical: 'top'}]
            }
            value={introductionData.description}
            onChangeText={text => {
              setIntroductionData({...introductionData, description: text});
            }}
          />
        </View>
      </MainContainer>
      {props.route?.params?.edit ? (
        <TouchableOpacity style={styles.btn} onPress={updateIntroduction}>
          <AppText style={styles.btnText}>Update</AppText>
        </TouchableOpacity>
      ) : (
        <TouchableOpacity style={styles.btn} onPress={saveIntroduction}>
          <AppText style={styles.btnText}>Add</AppText>
        </TouchableOpacity>
      )}
    </View>
  );
};

export default IntroductionSetting;
