import CommonActions from '../../redux/reduxsauce/commonRedux';
import React, {useState, useEffect} from 'react';
import {View, TextInput, TouchableOpacity, Platform} from 'react-native';
import {useDispatch} from 'react-redux';
import {
  AppText,
  MainHeader,
  Icon,
  MainContainer,
  LongButton,
} from '../../components';
import DropDownInput from '../../components/DropDownInput';

import {Colors} from '../../constants';
import styles from './styles';
import axios from 'axios';

const CreateBusiness = (props) => {
  const categoryList = [
    'Kirana Store, FMCG and Grocery',
    'Restaurants and Hotels',
    'Fashion Apparels, Shoes and Accessories',
    'Fruits and Vegetables',
    'Mobiles, Computers and Other Accessories',
    'Books and Stationery Products',
    'Beauty and Cosmetics',
    'Home Decoration and Electronic Appliances',
    'Pharmacy and Medical Care',
    'Bakery and Cake Shops',
    'Fresh Chicken, Fish, and Meat',
    'Local and Online Services',
    'Jewellery, Gold, and Gems',
    'Insurance and Financial Services',
    'Paan Shop',
    'Gym and Sports Equipment',
    'Educational Institutions, Schools, and Teachers',
    'Hardware and Construction Tools',
    'Transportation, Taxi, Travel, and Tourism',
    'Car, Bike, Tractor and Vehicle Accessories',
    'Other',
  ];

  const [business, setBusiness] = useState({
    name: '',
    details: '',
    street: '',
    state: '',
    city: '',
    area: '',
    type: 'Kirana Store, FMCG and Grocery',
    otherType: '',
    pincode: '',
  });

  const dispatch = useDispatch();

  const onSubmit = async () => {
    if (business.name == '') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: 'Business name is required!',
        }),
      );
    }
    //  if (business.details == '') {
    //   dispatch(
    //     CommonActions.setAlert({
    //       visible: true,
    //       content: 'Business details is required!',
    //     }),
    //   );
    // }else
    else if (!business.type || business.type == '') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: 'Business type is required!',
        }),
      );
    } else if (business.type == 'Other' && business.otherType == '') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: 'Business other type is required!',
        }),
      );
    } else if (business.street == '') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: 'Street is required!',
        }),
      );
    } else if (business.area == '') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: 'Area is required!',
        }),
      );
    } else if (business.city == '') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: 'City is required!',
        }),
      );
    } else if (business.state == '') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: 'State is required!',
        }),
      );
    } else {
      let body = {
        name: business.name,
        description: business.details,
        category: business.type == 'Other' ? business.otherType : business.type,
        street: business.street,
        area: business.area,
        city: business.city,
        state: business.state,
        country: business.country,
      };
      if (business.pincode && business.pincode != '') {
        body = {...body, pincode: business.pincode};
      }
      dispatch(CommonActions.setLoading(true));
      try {
        const response = await axios.post('/business/create', body, {
          headers: {
            Authorization:
              props.route.params?.user?.token &&
              'Token ' + props.route.params?.user?.token,
          },
        });
        console.log('response.data------->', response.data);
        dispatch(CommonActions.setLoading(false));
        props.navigation.replace('thank-you');
      } catch (error) {
        console.log('error', error);
        dispatch(CommonActions.setLoading(false));
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.message || 'Something want worng :(',
          }),
        );
      }
    }
  };
  return (
    <View style={styles.container}>
      <MainHeader
        title={'Create Business'}
        leftIcon={'back'}
        leftPress={() => {
          props.navigation.replace('login');
        }}
      />
      <MainContainer>
        <View style={styles.textInputContainer}>
          <TextInput
            underlineColorAndroid="transparent"
            autoCapitalize="none"
            autoCorrect={false}
            placeholder={'Name'}
            placeholderTextColor={Colors.color30}
            secureTextEntry={false}
            style={styles.textInput}
            returnKeyType="done"
            value={business.name}
            onChangeText={(text) => setBusiness({...business, name: text})}
          />
        </View>
        <View style={[styles.textInputContainer, {height: 100}]}>
          <TextInput
            underlineColorAndroid="transparent"
            autoCapitalize="none"
            autoCorrect={false}
            placeholder={'Details'}
            placeholderTextColor={Colors.color30}
            secureTextEntry={false}
            multiline={true}
            numberOfLines={3}
            style={[
              styles.textInput,
              {flex: 1, textAlignVertical: 'top', paddingVertical: 7},
            ]}
            returnKeyType="done"
            value={business.details}
            onChangeText={(text) => setBusiness({...business, details: text})}
          />
        </View>
        <View style={styles.textInputContainer}>
          <View style={[styles.textInput, {justifyContent: 'center'}]}>
            <DropDownInput
              placeholder={'Type'}
              data={categoryList}
              value={business.type}
              inputStyle={{
                paddingHorizontal: 0,
                marginVertical: Platform.OS == 'web' ? 5 : 7,
              }}
              onChangeText={(itemValue) => {
                setBusiness({...business, type: itemValue});
              }}
            />
          </View>
        </View>
        {business.type == 'Other' && (
          <View style={styles.textInputContainer}>
            <TextInput
              underlineColorAndroid="transparent"
              autoCapitalize="none"
              autoCorrect={false}
              placeholder={'Other Type'}
              placeholderTextColor={Colors.color30}
              secureTextEntry={false}
              style={styles.textInput}
              returnKeyType="done"
              value={business.otherType}
              onChangeText={(text) =>
                setBusiness({...business, otherType: text})
              }
            />
          </View>
        )}
        <View style={styles.textInputContainer}>
          <TextInput
            underlineColorAndroid="transparent"
            autoCapitalize="none"
            autoCorrect={false}
            placeholder={'Street'}
            placeholderTextColor={Colors.color30}
            secureTextEntry={false}
            style={styles.textInput}
            returnKeyType="done"
            value={business.street}
            onChangeText={(text) => setBusiness({...business, street: text})}
          />
        </View>
        <View style={styles.textInputContainer}>
          <TextInput
            underlineColorAndroid="transparent"
            autoCapitalize="none"
            autoCorrect={false}
            placeholder={'Area'}
            placeholderTextColor={Colors.color30}
            secureTextEntry={false}
            style={styles.textInput}
            returnKeyType="done"
            value={business.area}
            onChangeText={(text) => setBusiness({...business, area: text})}
          />
        </View>
        <View style={styles.textInputContainer}>
          <TextInput
            underlineColorAndroid="transparent"
            autoCapitalize="none"
            autoCorrect={false}
            placeholder={'City'}
            placeholderTextColor={Colors.color30}
            secureTextEntry={false}
            style={styles.textInput}
            returnKeyType="done"
            value={business.city}
            onChangeText={(text) => setBusiness({...business, city: text})}
          />
        </View>
        <View style={styles.textInputContainer}>
          <TextInput
            underlineColorAndroid="transparent"
            autoCapitalize="none"
            autoCorrect={false}
            placeholder={'State'}
            placeholderTextColor={Colors.color30}
            secureTextEntry={false}
            style={styles.textInput}
            returnKeyType="done"
            value={business.state}
            onChangeText={(text) => setBusiness({...business, state: text})}
          />
        </View>
        <View style={styles.textInputContainer}>
          <TextInput
            underlineColorAndroid="transparent"
            autoCapitalize="none"
            autoCorrect={false}
            placeholder={'Country'}
            placeholderTextColor={Colors.color30}
            secureTextEntry={false}
            style={styles.textInput}
            returnKeyType="done"
            value={business.country}
            onChangeText={(text) => setBusiness({...business, country: text})}
          />
        </View>
        <View style={styles.textInputContainer}>
          <TextInput
            underlineColorAndroid="transparent"
            autoCapitalize="none"
            autoCorrect={false}
            placeholder={'Pincode'}
            placeholderTextColor={Colors.color30}
            secureTextEntry={false}
            style={styles.textInput}
            returnKeyType="done"
            value={business.pincode}
            onChangeText={(text) => setBusiness({...business, pincode: text})}
          />
        </View>
        <LongButton text={'Create'} onPress={onSubmit} />
      </MainContainer>
    </View>
  );
};
export default CreateBusiness;
