import React, {useEffect, useState} from 'react';
import {
  View,
  TextInput,
  TouchableOpacity,
  FlatList,
  ScrollView,
  ToastAndroid,
  Platform,
} from 'react-native';
import {
  AppText,
  MainContainer,
  MainHeader,
  NoRecordFound,
  OrderPromotionCard,
} from '../../components';
import styles from './styles';
import {Colors} from '../../constants';
import {goBack, setValue} from '../../utils';
import {useDispatch, useSelector} from 'react-redux';
import {getPromotionList} from '../../redux/reduxsauceActions/promotionActions';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import axios from 'axios';

export default function OrderPromotionList(props) {
  const promotion = useSelector((state) => state.promotion);
  const [manualPromo, setManualPromo] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPromotionList());
  }, []);

  const pressApply = (item) => {
    dispatch(CommonActions.setLoading(true));
    axios
      .get('/promotion/availability/' + item.id)
      .then((response) => {
        console.log('apply promotion', response);
        dispatch(
          CommonActions.setShowToast({
            visible: true,
            content: 'Applied successfully',
          }),
        );
        props.navigation.navigate('place-order', {promoCode: item});
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        console.log('error apply promotion->', error.response);
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.data?.message,
          }),
        );
        dispatch(CommonActions.setLoading(false));
      });
  };

  const ApplyManually = () => {
    props.navigation.navigate('place-order', {
      promoCode: {
        coupon_code: manualPromo,
      },
    });
  };

  return (
    <View style={styles.container}>
      <MainHeader
        leftIcon="back"
        leftPress={() => goBack(navigation)}
        title={'Available Offers'}
      />
      <MainContainer useView>
        {/* Promotion input field */}
        {/* <View style={styles.topInputContainer}>
          <TextInput
            placeholder="Enter promo code"
            placeholderTextColor={Colors.border}
            style={styles.inputPromotion}
            value={manualPromo}
            onChangeText={(text) => setManualPromo(text)}
          />
          <TouchableOpacity
            style={styles.applyButtonTop}
            onPress={ApplyManually}
          >
            <AppText
              style={{color: Colors.theme_color, fontFamily: 'Roboto-Bold'}}
            >
              Apply
            </AppText>
          </TouchableOpacity>
        </View> */}
        {promotion?.length > 0 ? (
          <View>
            <FlatList
              data={promotion}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item, index) => index}
              renderItem={({item, index}) => {
                return (
                  <OrderPromotionCard
                    key={index}
                    item={item}
                    pressApply={() => pressApply(item)}
                  />
                );
              }}
            />
          </View>
        ) : (
          <NoRecordFound
            title="No Offer Active ...)"
            message="We'll notify you when there is something now!"
          />
        )}
      </MainContainer>
    </View>
  );
}
