import {StyleSheet} from 'react-native';
import {Colors} from '../../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
};

export default StyleSheet.create({
  textInput: {
    height: setValue(50),
    padding: 5,
    flex: 1,
    paddingHorizontal: setXAxisValue(10),
  },
  textInputContainer: {
    height: 70,
    backgroundColor: Colors.white,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
    ...shadow,
  },
  title: {
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  iconDropdown: {
    top: setYAxisValue(25),
  },
  pickerStyle: {
    marginTop: setValue(70),
  },
  inputRightSide: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    right: setValue(10),
    alignSelf: 'center',
    bottom: setValue(12),
    elevation: 10,
  },
});
