import {
  Entypo,
  FontAwesome5,
  FontAwesome,
  MaterialCommunityIcons,
} from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import * as Notifications from 'expo-notifications';
import _ from 'lodash';
import get from 'lodash.get';
import moment from 'moment';
import queryString from 'query-string';
import React, {Component} from 'react';
import {
  FlatList,
  Image,
  Switch,
  ImageBackground,
  Linking,
  Platform,
  RefreshControl,
  ScrollView,
  TouchableOpacity,
  View,
  ActivityIndicator,
  Share,
  Text,
} from 'react-native';
import Swiper from '../../components/Swiper';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {bindActionCreators} from 'redux';
import {AppText, Icon, MainContainer} from '../../components';
import {Colors, Images} from '../../constants';
import {strings, switchLanguage} from '../../i18n';
import AuthActions from '../../redux/reduxsauce/authRedux';
import CommonActions from '../../redux/reduxsauce/commonRedux';
// import {removeBannerCard} from '../../redux/reduxsauceActions/configActions';
import {notificationHandler, screenWidth, setValue} from '../../utils';
import styles from './styles';
import Constants from 'expo-constants';
// import {addToCart} from '../../redux/reduxsauceActions/cartAction';
import ConfigAction from '../../redux/reduxsauce/configRedux';
import ForceUpdate from '../../components/ForceUpdate';

const HomeItem = ({item, navigation, index}) => {
  return (
    <View style={styles.merchentCardContainer}>
      <TouchableOpacity
        style={[
          styles.merchantCardItem,
          index % 2 == 0 && {marginRight: setValue(14)},
        ]}
        onPress={() => navigation.navigate(item?.navigate, item?.params)}
      >
        <View style={{flex: 1}}>
          <Icon name={item?.icon} size={22} color={Colors.color30} />
          <AppText style={styles.titleText}>
            {item?.itemName}
            {item?.itemName.toLowerCase() == 'offline-orders' && (
              <AppText
                style={[
                  styles.titleText,
                  {
                    fontWeight: '600',
                  },
                ]}
              >
                {' (beta)'}
              </AppText>
            )}
          </AppText>
          <View style={styles.deshView}></View>
        </View>
        <View>
          {item?.counter && parseInt(item?.counter) > 0 ? (
            <AppText style={{color: Colors.black60}}>{item?.counter}</AppText>
          ) : null}
        </View>
      </TouchableOpacity>
    </View>
  );
};

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: false,
  }),
});

const registerForPushNotificationsAsync = async () => {
  let token;
  if (Constants.isDevice) {
    const {status: existingStatus} = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const {status} = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus == 'denied') {
      return;
    }
    if (finalStatus !== 'granted') {
      alert(strings('homeScreen.pushToken') + finalStatus);
      return;
    }
    // {experienceId: '@spyhunteritsolution/whitelabelapp',}
    token = await Notifications.getExpoPushTokenAsync()
      .then((response) => {
        console.log('====>', response);
        return response.data;
      })
      .catch((error) => {
        console.log('error ===>', error);
      });
  } else {
    // alert('Must use physical device for Push Notifications');
  }
  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('order', {
      name: 'Order Notification',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      // sound:"../../../assets/sounds/oder_notification.wav"
      sound: 'oder_notification.wav',
    });
    await Notifications.setNotificationChannelAsync('offer', {
      name: 'Offer Notification',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
    });
    await Notifications.setNotificationChannelAsync('message', {
      name: 'Message Notification',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
    });
    await Notifications.setNotificationChannelAsync('transaction', {
      name: 'Transaction Notification',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
    });
  }

  return token;
};

const slug = Constants.manifest.slug;
class HomeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceUpdateModal: false,
      forceUpdateData: null,
      showSetupConfigModal: false,
      isOpen: false,
      isInvoiceDue: false,
      homeList: [
        // app types -- 'retail', 'restaurants', "services", "group","ngo", "hospitality"
        {
          icon: 'cart',
          itemName: strings('homeScreen.order'),
          counter: 0,
          navigate: 'merchant-order-list',
          slug: 'orders',
          appType: ['retail', 'restaurants'],
        },
        {
          icon: 'cartoff',
          itemName: strings('homeScreen.cancelOrder'),
          counter: 0,
          navigate: 'cancel-order-list',
          slug: 'cancelOrder',
          appType: ['retail', 'restaurants',],
        },
        {
          icon: 'subscription',
          itemName: strings('homeScreen.subscriptions'),
          counter: 0,
          navigate: 'merchant-subscription-list',
          slug: 'subscription',
          appType: ['retail', 'restaurants', 'services'],
          visible:
            props?.config?.product?.availableForSubscription == true
              ? true
              : false,
        },
        {
          icon: 'delivery',
          itemName: 'Subscription Delivery',
          counter: 0,
          navigate: 'subscription-delivery-list',
          slug: 'subscription-delivery-list',
          appType: ['retail', 'restaurants', 'services'],
          visible:
            props?.config?.product?.availableForSubscription == true
              ? true
              : false,
        },
        {
          icon: 'table',
          itemName: strings('homeScreen.bookedTable'),
          counter: 0,
          navigate: 'merchant-table-booking-list',
          slug: 'tableBooking',
          appType: ['restaurants'],
          visible:
            props?.config.restaurant?.enable_table_booking == true &&
            props?.config.type == 'restaurants'
              ? true
              : false,
        },
        {
          icon: 'profile',
          itemName: strings('homeScreen.customers'),
          counter: 0,
          navigate: 'customers',
          slug: 'customer',
        },
        {
          icon: 'boxOpen',
          itemName: strings('homeScreen.products'),
          counter: 0,
          navigate: 'products',
          slug: 'products',
          appType: ['retail', 'restaurants','services'],
        },
        {
          icon: 'boxOpen',
          itemName: 'Services',
          counter: 0,
          navigate: 'services-list',
          visible: false,
          appType: ['restaurants','services'],
        },
        {
          icon: 'donation',
          itemName: "Donation List",
          counter: 0,
          navigate: 'merchant-donation-list',
          appType: ['ngo'],
        },
        {
          icon: 'listalt',
          itemName: strings('homeScreen.report'),
          counter: 0,
          navigate: 'reports',
          appType: ['hospitality', 'restaurants'],
        },
        {
          icon: 'cart',
          itemName: strings('homeScreen.offlineOrder'),
          counter: 0,
          navigate: 'offline-orders',
          slug: 'offlineOrder',
          appType: ['retail', 'restaurants'],
        },
        {
          icon: 'bell',
          itemName: strings('notificationScreen.memberNotification'),
          counter: 0,
          navigate: 'notifications',
        },
        {
          icon: 'promotion',
          itemName: strings('menu.promotion'),
          counter: 0,
          navigate: 'offer',
          appType: ['retail', 'restaurants'],
        },
        {
          icon: 'hospitality',
          itemName: 'Hospitality',
          counter: 0,
          navigate: 'hospitality',
          visible: __DEV__ ? true : false,
          appType: ['hospitality'],
        },
        {
          icon: 'hospitality',
          itemName: 'Bookings',
          counter: 0,
          navigate: 'bookings',
          visible: __DEV__ ? true : false,
          appType: ['hospitality'],
        },
        {
          icon: 'message',
          itemName: 'Live Support',
          counter: 0,
          navigate: 'active-chat-list',
          slug: 'liveSupport',
          appType: ['hospitality','retail', 'restaurants','services'],
        },
        {
          icon: 'coins',
          itemName: strings('walletScreen.coins'),
          counter: 0,
          navigate: 'merchant-coin-transaction-list',
        },
        {
          icon: 'blog',
          itemName: 'Blogs',
          counter: 0,
          navigate: 'blogs',
        },
        {
          icon: 'gallery',
          itemName: 'Gallery',
          counter: 0,
          navigate: 'gallery',
        },
        {
          icon: 'setting',
          itemName: strings('settingScreen.settings'),
          counter: 0,
          navigate: 'setting',
        },
      ],
    };
    console.log('props----------', this.props.config);
    this.currencySymbol = get(props?.config, 'currencyCode', '₹');
  }

  getMerchantSummary = async () => {
    try {
      const response = await axios.get('/business/home/statistics');
      console.log('response -->', response?.data);
      let dataArray = Object.keys(response?.data?.data);
      console.log('dataArray', dataArray);

      let newArray = this.state.homeList.map((o) => {
        let obj1 = dataArray.find((i) => i == o?.slug);

        if (obj1 != undefined && obj1 != null) {
          let obj2 = {
            ...o,
            counter: response?.data?.data[obj1],
          };
          return obj2;
        } else {
          return o;
        }
      });
      console.log('new Array-->', newArray);

      this.setState({homeList: [...newArray]});
    } catch (error) {
      console.log('error', error);
    }
  };

  getInvoiceSummery = async () => {
    try {
      const response = await axios.get(
        '/business/invoice/payout/due_date/list',
      );
      console.log('response invoice due list -->\n', response?.data);
      if (response?.data.findIndex((o) => o.status == 'unpaid') > -1) {
        this.setState({
          isInvoiceDue: true,
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  getAppUpdate = async () => {
    const currentVersion = Constants.manifest.version;
    let splitCurrentVersion = currentVersion?.split('.');
    let {version} = this.props.config;
    let newVersion = version?.split('.');
    if (
      version &&
      currentVersion &&
      (parseInt(splitCurrentVersion[0]) < parseInt(newVersion[0]) ||
        parseInt(splitCurrentVersion[1]) < parseInt(newVersion[1]))
    ) {
      this.setState({
        // forceUpdateModal: true,
        forceUpdateData: {
          latestVersion: version,
          storeUrl:
            'https://play.google.com/store/apps/details?id=' +
            Constants?.manifest.android?.package,
        },
      });
    }
    // Linking.openURL("market://details?id=<>"); // Opens the app's store page so the user can download the update
    // Hardik code commeting becouse of @unimodules/core Error
    // try {
    //   VersionCheck.needUpdate()
    //     .then((res) => {
    //       console.log('hasNewVersion', res);
    //       let cv = parseInt(res?.currentVersion.replaceAll('.', ''));
    //       let lv = parseInt(res?.latestVersion.replaceAll('.', ''));
    //       if (cv < lv || res?.isNeeded) {
    //         this.setState({
    //           forceUpdateModal: true,
    //           forceUpdateData: res,
    //         });
    //       }
    //     })
    //     .catch((error) => console.log('error-->', error));
    // } catch (error) {
    //   console.log('error get App Update Info-->', error);
    // }
  };

  async componentDidMount() {
    // this.props.recentProducts?.length > 0 && this.fetchRecentProducts();
    // if (Platform.OS == 'android') {
    this.props.navigation.addListener('focus', async () => {
      await this.getMerchantSummary();
      await this.getInvoiceSummery();
    });

    Linking.getInitialURL()
      .then((url) => {
        let commanUrl =
          this.props.config?.domain && this.props.config?.domain != 'null'
            ? 'https://' + this.props.config?.domain?.replace('/', '') + '/'
            : 'https://' + this.props.config.slug + '.whitelabelapp.in/';

        // let commanUrl = 'exp://127.0.0.1:19000/--/';
        // cmd command  adb shell am start -W -a android.intent.action.VIEW -d "exp://127.0.0.1:19000/--/productDetail/demo-retail/330" host.exp.exponent

        let splitUrl = url?.replace(commanUrl, '').split('/');
        let screenName =
          splitUrl[0] == 'product'
            ? 'productDetail'
            : splitUrl[0] == 'delete'
            ? 'delete-confirmation'
            : splitUrl[0];

        const routes =
          splitUrl[0] == 'delete'
            ? {uidAndToken: splitUrl[1] + '/' + splitUrl[2]}
            : {id: splitUrl[2]};

        this.props.navigation.navigate(screenName, routes);
      })
      .catch((error) => {
        // console.log('error ------------->>>>>', error);
      });
    // }
    // await this.getTopProducts();
    Platform.OS != 'web' && (await this.getAppUpdate());

    Platform.OS != 'web' &&
      Notifications.getLastNotificationResponseAsync().then((res) => {
        if (res) {
          if (
            res.notification?.request?.content?.data?.action.toLowerCase() ===
            'promotion'
          ) {
            this.props.navigation.navigate('notifications');
          } else {
            ['merchant', 'manager'].includes(this.props?.user?.type)
              ? this.props.navigation.navigate('merchant-order-list')
              : this.props.navigation.navigate('order-history-detail', {
                  id: res.notification?.request?.content?.data?.orderId,
                });
          }
        }
      });

    this.props.isLogin &&
      Platform.OS != 'web' &&
      registerForPushNotificationsAsync().then((token) => {
        if (token !== '' && token !== undefined) {
          let body = {
            token_from: 'admin',
            token: token,
            type: 'expo',
          };
          axios
            .post('/user/fcm/token/create', body)
            .then((response) =>
              console.log('response update profile', response.data),
            )
            .catch((error) =>
              console.log('error to set fcm_token', error.response),
            );
        } else {
        }
      });

    // AppState.addEventListener('change', this.onAppChange);
    if (!this.props.lang) {
      this.props.setLanguage('en');
      switchLanguage('en', this);
    } else {
      switchLanguage(this.props.lang, this);
    }
    // this.getCountryCode();
    // this.handleRefresh();

    //when called app is foreground
    this.notificationListener =
      Platform.OS !== 'web' &&
      Notifications.addNotificationReceivedListener((notification) => {
        console.log('notification', notification);
        notificationHandler(notification, this.props.navigation);
        // this.props.getBadgeCount();
      });

    this.ResponseReceivedListener =
      Platform.OS !== 'web' &&
      Notifications.addNotificationResponseReceivedListener((notification) => {
        notificationHandler(notification.notification, this.props.navigation);
      });

    this.getUrlAsync();
    //  Linking.addEventListener('url', this.handleOpenUrl);
    // this.handleCheckGuideAvailable();
    // this.handleCheckCurrentOrderStatus();
    // this.handleForceUpdate();
  }

  // handleForceUpdate = () => {
  //   const localVersion = Constants.manifest.version;
  //   const appVersion = _.get(this.props, 'appConfig.currentVersion', '');
  //   if (
  //     !_.isEmpty(appVersion) &&
  //     appVersion.length &&
  //     compareVersions.compare(localVersion, appVersion, '<')
  //   ) {
  //     this.setState({visible: true});
  //   }
  // };

  // handleOpenUrl = _.debounce((e) => {
  //   const decodeURL = decodeURIComponent(e.url);
  //   const splitString = decodeURL.split('?');
  //   const params = queryString.parse(splitString[1]);
  //   if (typeof params.referralId !== 'undefined') {
  //     this.handleDynamicLink(params.referralId, params.type, {
  //       campaignId: params.campaignId,
  //     });
  //   }
  // }, 1000);

  // fetchRecentProducts = async () => {
  //   try {
  //     const response = await axios.get(
  //       `/products/list/${this.props.config?.businessId}?ids=` +
  //         this.props.recentProducts.map((o) => o),
  //     );
  //     console.log('recent prdct response-->>', response);
  //     this.setState({recentProductList: response.data});
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // };

  componentWillUnmount() {
    // AppState.removeEventListener('change', this.onAppChange);
    // this.unsubscribe && this.unsubscribe();
    // this.unsubscribeBlur && this.unsubscribeBlur();
    try {
      Platform.OS !== 'web' &&
        Notifications.removeNotificationSubscription(this.notificationListener);
      Platform.OS !== 'web' &&
        Notifications.removeNotificationSubscription(
          this.ResponseReceivedListener,
        );
    } catch (error) {
      console.log('error remove listener', error);
    }
    //  Linking.removeEventListener('url', this.handleOpenUrl);
  }

  // onAppChange = (currentAppState) => {
  //   if (currentAppState === 'background') {
  //     AsyncStorage.setItem('guideScreen', 'no');
  //   } else if (currentAppState === 'active') {
  //     // this.handleForceUpdate();
  //   }
  // };

  getUrlAsync = async () => {
    // Get the deep link used to open the app
    try {
      const getUrl = async (link) => {
        try {
          return await axios({
            method: 'get',
            url: link,
            maxRedirects: 0,
          });
        } catch (e) {
          if (Math.trunc(e.response.status / 100) === 3) {
            console.log(
              'e.response.headers.location',
              e.response.headers.location,
            );
            return getUrl(e.response.headers.location);
          }
          return null;
        }
      };
      const initialUrlApp = await Linking.getInitialURL();
      if (initialUrlApp?.includes('http')) {
        const response = await getUrl(initialUrlApp);

        const initialUrl =
          get(response, 'request.responseURL', null) || initialUrlApp;
        const decodeURL = decodeURIComponent(initialUrl);
        const splitString = decodeURL.split('?');
        const params = queryString.parse(splitString[1]);
        if (params.referralId) {
          this.handleDynamicLink(params.referralId, params.type, {
            campaignId: params.campaignId,
          });
        }
      }
    } catch (e) {
      console.log('e: ', e);
    }
  };

  // getCountryCode = async () => {
  //   // const location = await getReverseGeocode();
  //   // this.props.setCountryCode(location);
  // };

  handleDynamicLink = async (referralId, type, otherData) => {
    if (referralId != null && type != null) {
      AsyncStorage.setItem('referralId', referralId);
      AsyncStorage.setItem('type', type);
      if (this.props.isLogin && type !== 'APP_REFERRAL') {
        // await this.props.updateReferral(referralId);
      }
      if (
        this.props.isLogin &&
        type === 'PROMOTION_REFERRAL' &&
        otherData.campaignId
      ) {
        this.props.getPromotionDetail(
          otherData.campaignId,
          this.props.navigation,
        );
        this.props.navigation.navigate('offer-detail');
      }
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    let newList = this.state.homeList;

    let coinsObjIndex = newList.findIndex(
      (item) => item.itemName == strings('walletScreen.coins'),
    );

    let tableBookingIndex = newList.findIndex(
      (item) => item.itemName == strings('homeScreen.bookedTable'),
    );
    let subscriptionIndex = newList.findIndex(
      (item) => item.itemName == strings('homeScreen.subscriptions'),
    );

    newList[coinsObjIndex] = {
      ...newList[coinsObjIndex],
      visible: nextProps.config.enableAppCoin,
    };

    newList[tableBookingIndex] = {
      ...newList[tableBookingIndex],
      visible:
        nextProps?.config?.restaurant?.enable_table_booking == true &&
        nextProps?.config.type == 'restaurants'
          ? true
          : false,
    };

    newList[subscriptionIndex] = {
      ...newList[subscriptionIndex],
      visible:
        nextProps?.config?.product?.availableForSubscription == true
          ? true
          : false,
    };

    this.setState({homeList: [...newList]});
  }

  renderInfoCard = () => (
    <View style={styles.cardContainer}>
      <View style={styles.infoContainer}>
        <TouchableOpacity
          onPress={() => {
            this.props.navigation.navigate('edit-profile');
          }}
        >
          <Image
            source={
              this.props?.user?.photo
                ? {uri: `${this.props?.user?.photo}`}
                : Images.profile
            }
            style={styles.avatar}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.infoContent}
          onPress={() => {
            this.props.navigation.navigate('edit-profile');
          }}
        >
          <View style={{width: '100%'}}>
            <AppText style={styles.name} numberOfLines={1}>
              {this.props.user &&
                this.props.user.first_name + ' ' + this.props.user.last_name}
            </AppText>
            <AppText numberOfLines={1} style={styles.email}>
              {this.props.user && this.props.user.phone}
            </AppText>
            <AppText numberOfLines={1} style={styles.date}>
              {this.props.user.email}
            </AppText>
          </View>
        </TouchableOpacity>
      </View>
      <TouchableOpacity
        style={styles.qrContainer}
        onPress={() => this.props.navigation.navigate('my-qr')}
      >
        <Icon name="qrCode" size={20} color={Colors.black20} />
      </TouchableOpacity>
    </View>
  );

  renderInvoiceDueDate = () => (
    <TouchableOpacity
      style={styles.invoiceCardContainer}
      onPress={() => {
        this.props.navigation.navigate('business-invoice-list');
      }}
    >
      <Text
        style={{
          color: Colors.wallet_card_red,
          width: '100%',
        }}
      >
        {'New invoice Available, Click here to View.'}
      </Text>
    </TouchableOpacity>
  );

  render() {
    const {config} = this.props;
    const {
      showSetupConfigModal,
      forceUpdateModal,
      forceUpdateData,
      isInvoiceDue,
    } = this.state;

    let currentTime = moment(new Date()).format('HH:mm A');
    let shopOpenTime = moment(new Date(config?.openTime)).format('HH:mm A');
    let shopCloseTime = moment(new Date(config?.closeTime)).format('HH:mm A');
    let status;

    if (shopOpenTime < currentTime && shopCloseTime > currentTime) {
      status = 'online';
    } else {
      status = 'offline';
    }

    return (
      <View style={styles.homeContainer}>
        <Helmet>
          <meta
            name="description"
            content="India's biggest online store for Mobiles, Fashion (Clothes/Shoes), Electronics,"
          />
        </Helmet>
        <ForceUpdate visible={forceUpdateModal} item={forceUpdateData} />
        <View style={styles.headerContainer}>
          <Text style={styles.headerTitle}>{this.props.config?.appName}</Text>
        </View>

        <MainContainer>
          <View style={styles.mainContainer}>
            {this.renderInfoCard()}

            {isInvoiceDue &&
              ['merchant'].includes(this.props?.user?.type) &&
              this.renderInvoiceDueDate()}
            {showSetupConfigModal &&
              ['merchant', 'manager'].includes(this.props?.user?.type) && (
                <View style={styles.showSetUpCards}>
                  <View>
                    <AppText style={styles.setUpCardHeader}>
                      {strings('homeScreen.setupApp')}
                    </AppText>
                    <AppText style={styles.setUpCardDesc}>
                      {strings('homeScreen.setupAppDec')}
                    </AppText>
                  </View>

                  <View
                    style={{
                      marginTop: 10,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <TouchableOpacity
                      style={styles.setUpButton}
                      onPress={() => this.props.navigation.navigate('setting')}
                    >
                      <AppText style={styles.setUpButtonText}>
                        {strings('homeScreen.setupNow')}
                      </AppText>
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.cancelButton}>
                      <AppText
                        style={{fontWeight: '700', color: Colors.theme_color}}
                        onPress={() =>
                          this.setState({showSetupConfigModal: false})
                        }
                      >
                        {strings('homeScreen.later')}
                      </AppText>
                    </TouchableOpacity>
                  </View>
                </View>
              )}
            {/* <View style={styles.merchentCardContainer}>
                <View
                  style={[
                    styles.merchantCardItem,
                    {justifyContent: 'space-between'},
                  ]}
                >
                  <AppText>{strings('homeScreen.shopClose')}</AppText>
                  <View style={styles.switchContainer}>
                    <Switch
                      onTintColor={Colors.theme_color}
                      activeThumbColor={Colors.white}
                      value={this.state.isOpen}
                      onValueChange={(value) => {
                        this.props.openAlert(
                          this.state.isOpen
                            ? strings('homeScreen.askQustionOpen')
                            : strings('homeScreen.askQustionClose'),
                          strings('common.yes'),
                          () => {
                            this.props.setLoading(true);
                            axios
                              .patch(
                                `/business/update/business-setting/${config?.businessId}`,
                                {
                                  isOpen: this.state.isOpen,
                                },
                              )
                              .then((response) => {
                                this.props.setLoading(false);
                                this.setState({isOpen: !this.state.isOpen});
                                this.props.openAlert('Shop close successfully');
                              })
                              .catch((error) => {
                                this.props.setLoading(false);
                                this.props.openAlert(
                                  error?.response?.data ||
                                    'Something want worng :(',
                                );
                              });
                          },
                          strings('common.no'),
                          null,
                        );
                      }}
                    />
                  </View>
                </View>
              </View> */}
            <View
              style={{
                flexWrap: 'wrap',
                justifyContent: 'center',
                flexDirection: 'row',
              }}
            >
              {this.state.homeList
                .filter(
                  (a) =>
                    a.visible != false &&
                    (a.appType == undefined ||  a.appType.includes(this.props.config.type)),
                )
                .map((item, index) => {
                  return (
                    <HomeItem
                      index={index}
                      navigation={this.props.navigation}
                      key={index}
                      item={item}
                    />
                  );
                })}
            </View>
            <View style={styles.appVersionContainer}>
              <Text style={styles.appVersionText}>
                {Constants?.manifest?.version}
              </Text>
            </View>
          </View>
        </MainContainer>
      </View>
    );
  }
}

export default connect(
  (state) => ({
    verify: state.auth.verify,
    config: state.config,
    fetching: state.home.fetching,
    isLogin: get(state.auth.user, 'id', false),
    promotionData: state.promotion.data,
    companies: state.home.companies,
    feature: state.home.feature,
    isMenuOpen: state.common.isMenuShow,
    user: state.auth.user,
    lang: state.auth.lang,
    recentProducts: state.recentProducts,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        openAlert: (content, leftButton, leftPress, rightButton, rightPress) =>
          CommonActions.setAlert({
            visible: true,
            content,
            leftButton,
            leftPress,
            rightButton,
            rightPress,
          }),
        setCountryCode: CommonActions.setCountryCode,
        setLanguage: AuthActions.setLanguage,
        setLoading: CommonActions.setLoading,
        setAppConfiguration: ConfigAction.setAppConfiguration,
      },
      dispatch,
    ),
)(HomeScreen);
