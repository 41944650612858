import {Appearance, Platform, StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';
const colorScheme = Appearance.getColorScheme();
const backgroundColor = colorScheme == 'dark' ? Colors.black : Colors.white;
const textColor =
  colorScheme == 'dark' ? 'rgba(255, 255, 255,0.8)' : Colors.black60;
  
const wrapper = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 8,
  padding: setValue(5),
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  imageContainer: {
    width: (setValue(200) / 4) * 4,
    height: (setValue(200) / 4) * 3,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: setValue(5),
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
    backgroundColor: Colors.white,
    marginRight: setValue(5),
    marginBottom: Platform.OS == 'web' ? setValue(5) : 0,
  },
  image: {
    width: (setValue(200) / 4) * 4,
    height: (setValue(200) / 4) * 3,
    borderRadius: setValue(5),
  },
  MenuStrengthContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    marginBottom: setYAxisValue(20),
  },
  percentText: {
    fontSize: setXAxisValue(35),
    color: Colors.theme_color,
  },
  buttonContainer: {
    backgroundColor: Colors.white,
    paddingVertical: setYAxisValue(15),
  },
  buttonContainerWeb: {
    backgroundColor: Colors.white,
    marginVertical: setValue(50),
  },
  buttonButton: {
    width: '100%',
    backgroundColor: Colors.theme_color,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    bottom: 0,
    position: 'absolute',
    paddingVertical: setYAxisValue(15),
  },
  buttonButtonWeb: {
    width: '100%',
    backgroundColor: Colors.theme_color,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 5,
    bottom: 0,
    position: 'absolute',
    paddingVertical: setYAxisValue(15),
    ...wrapper,
  },
  buttonText: {
    fontSize: setXAxisValue(14),
    color: Colors.white,
  },
  inputStyle: {
    // height: setValue(30),
    padding: 0,
    flex: 1,
    marginBottom: setYAxisValue(0),
    borderRadius: 5,
    backgroundColor: Colors.white,
    paddingHorizontal: setXAxisValue(10),
  },
  title: {
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  content: {
    fontSize: setXAxisValue(14),
    color: Colors.black40,
  },
  textInputContainer: {
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 5,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
    flex: 1,
  },
  inputLabel: {
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    fontFamily: 'Roboto-Bold',
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  textInput: {
    height: setValue(50),
    padding: 0,
    flex: 1,
    paddingHorizontal: setXAxisValue(10),
  },
  textInputWeb: {
    height: setValue(50),
    padding: setValue(5),
    paddingHorizontal: setXAxisValue(10),
  },

  multiLineTextInput: {
    padding: setValue(10),
    textAlignVertical: 'top',
    minHeight: 100,
  },
  inputContainerStyle: {
    height: setValue(45),
  },
  iconStyle: {
    height: setValue(40),
  },
  addRomoveContainer: {
    flexDirection: 'row',
    marginBottom: setValue(30),
  },
  addRomoveButton: {
    borderWidth: 1.5,
    borderColor: Colors.theme_color,
    flex: 1,
    padding: setValue(10),
    // margin: setValue(10),
    borderRadius: setValue(5),
  },
  addRomoveButtonText: {
    color: textColor,
    textAlign: 'center',
  },
  cameraIcon: {
    backgroundColor: Colors.white,
    padding: 5,
    paddingHorizontal: setValue(7),
    borderRadius: setValue(5),
    position: 'absolute',
    alignSelf: 'center',
    top: '40%',
  },
  isNewMenu: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
  },
  viewContainer: {
    flexDirection: 'row',
  },
  scanButtonContainer: {
    width: '20%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  scanButton: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  scanBarcodeContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop : -6
  },
  datePicker: {
    paddingVertical: Platform.OS == 'web' ? 0 : 10,
    paddingHorizontal: 10,
  }
});
