import React, {Component} from 'react';
import {Platform, Text, TouchableOpacity, View} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {Colors} from '../../constants';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {screenWidth, setValue} from '../../utils';
import AppText from '../AppText';
import Icon from '../Icon';
import styles from './styles';
import Icon4 from '@expo/vector-icons/FontAwesome';
import {FontAwesome5} from '@expo/vector-icons';
import {strings} from '../../i18n';
import {get} from 'lodash';

class MainHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // getTotalCount = () => {
  //   return (
  //     this.props.cart?.list?.length > 0 &&
  //     this.props.cart?.list?.reduce((quantity, item) => {
  //       return (quantity = quantity + item?.cart_qty);
  //     }, 0)
  //   );
  // };

  render() {
    const {
      title,
      rightPress,
      rightIcon,
      leftPress,
      leftIcon,
      tableDetail,
      rightOtherIcon,
      rightOtherPress,
      rightBadge,
      isLogin,
    } = this.props;

    // let cartBadge = this.getTotalCount();

    return (
      <View style={styles.safeView}>
        <View style={styles.container}>
          <TouchableOpacity
            style={styles.menuIconViewLeft}
            onPress={leftPress || (() => this.props.toggleMenu())}
          >
            <Icon
              name={leftIcon || 'menu'}
              size={20}
              color={Colors.primary_color}
            />
            {title ? (
              <AppText numberOfLines={1} style={styles.title}>
                {title}
              </AppText>
            ) : null}
          </TouchableOpacity>
          <View style={styles.rightContainer}>
            {/* platform  is web and screen  width is more then 1023 then */}
            {Platform.OS == 'web' && screenWidth > 1023 && this?.props?.route && (
              <>
                <TouchableOpacity
                  style={
                    this?.props?.route?.name == 'home'
                      ? [styles.WebMenuItem, {backgroundColor: Colors.white}]
                      : styles.WebMenuItem
                  }
                  onPress={() => this.props?.navigation?.navigate('home')}
                >
                  <Text
                    style={
                      this?.props?.route?.name == 'home'
                        ? {color: Colors.theme_color, fontFamily: 'Roboto-Bold'}
                        : {color: Colors.white, fontFamily: 'Roboto-Regular'}
                    }
                  >
                    {strings('menu.home')}
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={
                    this?.props?.route?.name == 'products'
                      ? [styles.WebMenuItem, {backgroundColor: Colors.white}]
                      : styles.WebMenuItem
                  }
                  onPress={() => this.props?.navigation?.navigate('products')}
                >
                  <Text
                    style={
                      this?.props?.route?.name == 'products'
                        ? {color: Colors.theme_color, fontFamily: 'Roboto-Bold'}
                        : {color: Colors.white, fontFamily: 'Roboto-Regular'}
                    }
                  >
                    {strings('menu.products')}
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={
                    this?.props?.route?.name == 'notifications'
                      ? [styles.WebMenuItem, {backgroundColor: Colors.white}]
                      : styles.WebMenuItem
                  }
                  onPress={() =>
                    this.props?.navigation?.navigate('notifications')
                  }
                >
                  <Text
                    style={
                      this?.props?.route?.name == 'notifications'
                        ? {color: Colors.theme_color, fontFamily: 'Roboto-Bold'}
                        : {color: Colors.white, fontFamily: 'Roboto-Regular'}
                    }
                  >
                    {strings('menu.notifications')}
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={
                    this?.props?.route?.name == 'offer'
                      ? [styles.WebMenuItem, {backgroundColor: Colors.white}]
                      : styles.WebMenuItem
                  }
                  onPress={() => this.props?.navigation?.navigate('offer')}
                >
                  <Text
                    style={
                      this?.props?.route?.name == 'offer'
                        ? {color: Colors.theme_color, fontFamily: 'Roboto-Bold'}
                        : {color: Colors.white, fontFamily: 'Roboto-Regular'}
                    }
                  >
                    {strings('menu.promotion')}
                  </Text>
                </TouchableOpacity>
              </>
            )}

            {rightOtherIcon && (
              <TouchableOpacity
                style={
                  Platform.OS == 'web' &&
                  screenWidth > 1023 &&
                  this?.props?.route
                    ? styles.menuIconViewRight
                    : [
                        styles.menuIconViewRight,
                        {backgroundColor: Colors.white},
                      ]
                }
                onPress={rightOtherPress}
              >
                <Icon
                  name={rightOtherIcon}
                  size={
                    Platform.OS == 'web' &&
                    screenWidth > 1023 &&
                    this?.props?.route
                      ? 15
                      : 18
                  }
                  color={
                    Platform.OS == 'web' &&
                    screenWidth > 1023 &&
                    this?.props?.route
                      ? Colors.white
                      : Colors.black
                  }
                />
              </TouchableOpacity>
            )}
            {screenWidth < 1024 && tableDetail && (
              <View style={styles.tableDetail}>
                <AppText style={styles.tableDetailText}>{tableDetail}</AppText>
              </View>
            )}

            { rightIcon && (
              <TouchableOpacity
                style={
                  Platform.OS == 'web' &&
                  screenWidth > 1023 &&
                  this?.props?.route
                    ? styles.menuIconViewRight
                    : [
                        styles.menuIconViewRight,
                        {backgroundColor: Colors.white},
                      ]
                }
                onPress={rightPress}
              >
                {rightIcon == 'account' ? (
                  <FontAwesome5
                    name="user-alt"
                    size={18}
                    color={
                      Platform.OS == 'web' &&
                      screenWidth > 1023 &&
                      this?.props?.route
                        ? Colors.white
                        : Colors.black
                    }
                  />
                ) : rightIcon == 'login' ? (
                  <Icon4 name="sign-in" size={20} />
                ) : (
                  <Icon
                    name={rightIcon}
                    size={
                      Platform.OS == 'web' &&
                      screenWidth > 1023 &&
                      this?.props?.route
                        ? 15
                        : 18
                    }
                    color={
                      Platform.OS == 'web' &&
                      screenWidth > 1023 &&
                      this?.props?.route
                        ? Colors.white
                        : Colors.black
                    }
                  />
                )}
                {rightBadge && Number(rightBadge) > 0 ? (
                  rightBadge && Number(rightBadge) > 99 ? (
                    <View style={styles.badgeContainer}>
                      <AppText style={styles.badge}>99+</AppText>
                    </View>
                  ) : (
                    <View style={styles.badgeContainer}>
                      <AppText style={styles.badge}>{rightBadge}</AppText>
                    </View>
                  )
                ) : null}

                {/* // (
                //   <View style={styles.badgeContainer}>
                //     <AppText style={styles.badge}>{rightBadge}</AppText>
                //   </View>
                // ) : null} */}
              </TouchableOpacity>
            )}

            {Platform.OS == 'web' && screenWidth > 1023 && this?.props?.route && (
              <>
                <TouchableOpacity
                  style={styles.menuIconViewRight}
                  onPress={() => this.props.navigation.navigate('cart')}
                >
                  <Icon4 name="shopping-cart" size={18} color={Colors.white} />
                  {/* {cartBadge && Number(cartBadge) > 0 ? (
                    cartBadge && Number(cartBadge) > 99 ? (
                      <View style={styles.cartBadgeContainer}>
                        <AppText
                          style={[styles.badge, {fontSize: setValue(7)}]}
                        >
                          99+
                        </AppText>
                      </View>
                    ) : (
                      <View style={styles.cartBadgeContainer}>
                        <AppText
                          style={[styles.badge, {fontSize: setValue(7)}]}
                        >
                          {cartBadge}
                        </AppText>
                      </View>
                    )
                  ) : null} */}
                </TouchableOpacity>
                {isLogin && (
                  <TouchableOpacity
                    style={[
                      styles.WebMenuItem,
                      {
                        backgroundColor: Colors.white,
                        borderRadius: setValue(30),
                      },
                    ]}
                    onPress={() => this.props?.navigation?.navigate('account')}
                  >
                    <FontAwesome5
                      name="user-alt"
                      size={15}
                      color={Colors.black}
                    />
                  </TouchableOpacity>
                )}
              </>
            )}
          </View>
        </View>
      </View>
    );
  }
}

export default connect(
  (state) => ({
    badge: state.home.badge,
    // cart: state.cart,
    isLogin: get(state.auth.user, 'id', false),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        toggleMenu: () => CommonActions.setMenu(true),
      },
      dispatch,
    ),
)(MainHeader);
