import {View, Text, TouchableOpacity} from 'react-native';
import React, {useEffect, useState} from 'react';
import {
  AppText,
  DropDownInput,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../components';
import {goBack} from '../../utils';
import {strings} from '../../i18n';
import styles from './styles';
import {getCategories} from '../../redux/reduxsauceActions/productActions';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import CommonActions from '../../redux/reduxsauce/commonRedux';

const ProductFilter = (props) => {
  const dispatch = useDispatch();
  const [categoryId, setCategoryId] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [brand, setBrand] = useState('');
  const [brandList, setBrandList] = useState([]);
  const categories = useSelector((state) => state.products?.categories);
  const config = useSelector((state) => state.config);
  useEffect(() => {
    dispatch(getCategories());
    fetchProductBrand();
  }, []);

  const fetchProductBrand = async () => {
    try {
      const response = await axios.get(
        `/products/product-brand/list/${config.businessId}`,
      );
      console.log('response--brand-->', response.data);
      setBrandList(response?.data);
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    async function getList() {
      let findCategory = categories.find((o) => o.name == category);
      if (findCategory?.id) {
        dispatch(CommonActions.setLoading(true));
        try {
          const response = await axios.get(
            `/products/product-sub-category/list/${config.businessId}/${findCategory?.id}`,
          );
          console.log('response', response.data);
          dispatch(CommonActions.setLoading(false));
          let array = [];
          setSubCategoryList(response?.data);
        } catch (error) {
          console.log('error', error);
        }
      }
    }
    getList();
  }, [category]);

  console.log('categories', categoryId);

  return (
    <View style={{flex: 1}}>
      <MainHeader
        title={strings('filterScreen.title')}
        leftIcon={'back'}
        leftPress={() => goBack(props.navigation)}
        navigation={props.navigation}
      />
      <MainContainer>
        <View style={styles.textInputContainer}>
          <AppText style={styles.title}>
            {strings('filterScreen.label.category')}
          </AppText>
          <DropDownInput
            inputStyle={{
              marginVertical: 10,
              marginHorizontal: 10,
            }}
            data={categories?.length > 0 && categories?.map((o) => o.name)}
            value={category || strings('filterScreen.placeholder.category')}
            onChangeText={(text) => {
              console.log('text', text);
              setCategory(text);
            }}
          />
        </View>
        <View style={styles.textInputContainer}>
          <AppText style={styles.title}>
            {strings('filterScreen.label.subcategory')}
          </AppText>
          <DropDownInput
            inputStyle={{
              marginVertical: 10,
              marginHorizontal: 10,
            }}
            data={
              subCategoryList?.length > 0 && subCategoryList.map((o) => o.name)
            }
            value={
              subCategory || strings('filterScreen.placeholder.subcategory')
            }
            onChangeText={(text) => {
              console.log('text', text);
              setSubCategory(text);
            }}
          />
        </View>
        <View style={styles.textInputContainer}>
          <AppText style={styles.title}>
            {strings('filterScreen.label.selectBrand')}
          </AppText>
          <DropDownInput
            inputStyle={{
              marginVertical: 10,
              marginHorizontal: 10,
            }}
            data={brandList?.length > 0 && brandList.map((o) => o.name)}
            value={brand || strings('filterScreen.placeholder.brand')}
            onChangeText={(text) => {
              console.log('text', text);
              setBrand(text);
            }}
          />
        </View>
      </MainContainer>
      <LongButton
        text={strings('filterScreen.button.find')}
        style={{
          width: '100%',
          borderRadius: 0,
        }}
        onPress={() => props.navigation.navigate('edit-address')}
      />
    </View>
  );
};

export default ProductFilter;
