import {StyleSheet, Appearance} from 'react-native';
import {Colors} from '../../../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../../../utils';

const textColor = colorScheme == 'dark' ? Colors.black60 : Colors.black60;
const colorScheme = Appearance.getColorScheme();

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
};
export default StyleSheet.create({
  container: {
    flex: 1,
  },
  headerTitle: {
    paddingBottom: 5,
    fontSize: setXAxisValue(15),
  },
  plusDayInputContainer: {
    flexDirection: 'row',
    minHeight: 50,
    backgroundColor: Colors.white,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
    ...shadow,
  },
  textInputContainer: {
    minHeight: 70,
    backgroundColor: Colors.white,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
    ...shadow,
  },
  ratioInputContainer: {
    width: '85%',
    flexDirection: 'row',
    minHeight: 50,
    backgroundColor: Colors.white,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
    ...shadow,
  },
  plusDayInput: {
    width: '30%',
    height: setValue(50),
    flex: 1,
    paddingHorizontal: setXAxisValue(10),
  },
  textInput: {
    height: setValue(50),
    padding: 5,
    flex: 1,
    paddingHorizontal: setXAxisValue(10),
  },
  title: {
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(15),
    color: Colors.black20,
    marginTop: setYAxisValue(15),
    alignContent: 'center',
    minWidth: setValue(80),
  },
  sign: {
    fontSize: setXAxisValue(15),
    color: Colors.black20,
    marginTop: setYAxisValue(15),
    alignContent: 'center',
    minWidth: 20,
  },
  deleteButtonContainer: {
    alignSelf: 'center',
    marginLeft: 10,
  },
  deleteButton: {
    marginRight: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: setValue(32),
    height: setValue(32),
    backgroundColor: Colors.wallet_card_red,
    borderRadius: 100,
  },
  addRomoveContainer: {
    flexDirection: 'row',
    marginBottom: setValue(30),
  },
  addRomoveButton: {
    borderWidth: 1.5,
    borderColor: Colors.theme_color,
    flex: 1,
    padding: setValue(10),
    // margin: setValue(10),
    borderRadius: setValue(5),
  },
  addRomoveButtonText: {
    color: textColor,
    textAlign: 'center',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: setYAxisValue(10),
    marginBottom: setYAxisValue(30),
    marginHorizontal: 1,
  },
});
