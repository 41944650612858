import React, { PureComponent } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';

import { Colors } from '../../constants';

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: Colors.overlay,
    alignItems: 'center',
    justifyContent: 'center'
  }
});

class Loading extends PureComponent {
  render() {
    return this.props.loading ? (
      <View style={styles.container}>
        <ActivityIndicator color={Colors.theme_color} size="large" />
      </View>
    ) : null;
  }
}

export default connect(state => ({ loading: state.common.loading }))(Loading);
