import {Entypo} from '@expo/vector-icons';
import get from 'lodash.get';
import React, {useState, useEffect} from 'react';
import {
  ActivityIndicator,
  FlatList,
  Image,
  LogBox,
  SafeAreaView,
  Share,
  Text,
  TextInput,
  ToastAndroid,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import moment from 'moment';
import * as Clipboard from 'expo-clipboard';

import {connect, useSelector} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
  AppText,
  AutoHeightWebview as WebView,
  Icon,
  MainContainer,
  MainHeader,
  Modal,
} from '../../../components';
import {Colors, PromotionButton} from '../../../constants';
import {strings} from '../../../i18n';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {cashFormat, getParam, goBack, setValue} from '../../../utils';
import styles from './styles';
import axios from 'axios';
import {
  getPromotionList,
  deletePromotion,
} from '../../../redux/reduxsauceActions/promotionActions';

const PromotionDetailScreen = (props) => {
  const [promotionData, setPromotionData] = useState({
    quantity: '1',
    isModalVisible: false,
    isPaymentModalVisible: false,
    paymentUrl: '',
    paymentData: '',
    isShowMore: false,
    orderId: '',
    isVerify: false,
    goToStackName: null,
  });
  const [images, setImages] = useState(null);
  const config = useSelector((state) => state.config);
  let currencySymbol = get(config, 'currencySymbol', '₹');

  useEffect(() => {
    let updatedData = props.promotionList.find(
      (a, index) => a.id == route?.params?.item?.id,
    );
    setImages({uri: updatedData?.image});
    setPromotionData({...promotionData, ...updatedData});
  }, [props.promotionList]);

  const renderVoucherCard = (item) => {
    console.log('fdfd ==>', item);
    return (
      <TouchableOpacity style={styles.itemContainer}>
        <Image
          source={
            images?.uri
              ? {uri: `${images?.uri}`}
              : require('../../../../assets/images/General/no-img-found.jpg')
          }
          style={styles.promotionImage}
        />
        <View style={{padding: setValue(10)}}>
          <View style={{paddingRight: 20}}>
            <View
              style={{flexDirection: 'row', justifyContent: 'space-between'}}
            >
              <AppText style={styles.title}>{promotionData.name}</AppText>
            </View>
          </View>
          <AppText style={styles.desc}>{promotionData.description}</AppText>
          <TouchableOpacity
            style={styles.couponCodeContainer}
            onPress={() => {
              Clipboard.setString(item?.coupon_code);
              props.showToast('Copied to clipboard');
            }}
          >
            <Text style={styles.couponCodeText}>
              {promotionData.coupon_code}
            </Text>
            <Text style={styles.copyText}>{'COPY'}</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.promotionOffer}>
          <Text style={styles.offerText}>
            {parseFloat(promotionData.percentage_of) + '% OFF'}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  const renderQuantity = () => {
    let {issueLimit} = props.data;
    if (issueLimit === 0) {
      issueLimit = 999;
    }

    return (
      <View style={styles.quantityView}>
        {props.data.selling ? (
          <View>
            <AppText style={styles.currentPriceText}>
              {currencySymbol}
              {cashFormat(
                Number(props.data.promotionSellingPrice) *
                  Number(promotionData.quantity),
              )}
            </AppText>
          </View>
        ) : (
          <View>
            <AppText style={styles.currentPriceText}>
              {strings('promotionDetailScreen.free')}
            </AppText>
          </View>
        )}

        <View style={styles.quantityActionView}>
          <TouchableOpacity
            onPress={() =>
              setPromotionData((prev) => {
                const currentQuantity = prev.quantity;
                const nextQuantity = Number(currentQuantity) - 1;
                if (nextQuantity >= 1) {
                  return {quantity: String(nextQuantity)};
                }
              })
            }
          >
            <Icon
              name="minus"
              size={20}
              style={styles.quantityButton}
              color={Colors.color30}
            />
          </TouchableOpacity>
          <TextInput
            style={styles.quantityNumberText}
            value={promotionData.quantity}
            keyboardType="numeric"
            maxLength={3}
            onChangeText={(text) =>
              setPromotionData({...promotionData, quantity: text})
            }
            returnKeyType="done"
          />
          <TouchableOpacity
            onPress={() =>
              setPromotionData((prev) => {
                const currentQuantity = prev.quantity;
                const nextQuantity = Number(currentQuantity) + 1;
                if (nextQuantity <= issueLimit) {
                  return {quantity: String(nextQuantity)};
                }
              })
            }
          >
            <Icon
              name="plus"
              size={20}
              style={styles.quantityButton}
              color={Colors.color30}
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  const renderPeriod = () => (
    <View style={styles.periodView}>
      <AppText>{strings('promotionDetailScreen.offerPeriod')}</AppText>
      <AppText>
        {props.data.campaignStartDate} - {props.data.campaignEndDate}
      </AppText>
    </View>
  );

  const renderValidAt = () => (
    <View style={styles.itemContainer}>
      <AppText>
        {strings('promotionDetailScreen.validAt')} (
        {props.data.campaignOutletInfoS.length}
        {props.data.campaignOutletInfoS.length > 1
          ? strings('promotionDetailScreen.outlets')
          : strings('promotionDetailScreen.outlet')}
        )
      </AppText>
      <View style={styles.flexWrap}>
        {props.data.campaignOutletInfoS.map((item, index) =>
          index < 2 || promotionData.isShowMore ? (
            <TouchableOpacity key={item.outletId} style={styles.validAtItem}>
              <AppText style={styles.validAtItemText}>
                {item.outletName}
              </AppText>
            </TouchableOpacity>
          ) : null,
        )}
        {!promotionData.isShowMore &&
        props.data.campaignOutletInfoS.length - 2 > 0 ? (
          <TouchableOpacity
            style={styles.validAtItemMore}
            onPress={() =>
              setPromotionData({...promotionData, isShowMore: true})
            }
          >
            <AppText style={styles.validAtItemTextMore}>
              + {props.data.campaignOutletInfoS.length - 2} More
            </AppText>
          </TouchableOpacity>
        ) : null}
      </View>
    </View>
  );

  const renderDesc = () =>
    props.data.promotionDescription ? (
      <View style={styles.itemContainer}>
        {/* <WebView
          style={{
            minHeight: props.data.promotionDescription.length * 0.5,
            width: Layout.window.width - setValue(50),
          }}
          source={{
          }}
          scrollEnabled={false}
          androidHardwareAccelerationDisabled
        /> */}
      </View>
    ) : null;

  const renderTerm = () =>
    props.data.promotionTermsAndConditions ? (
      <View style={styles.itemContainer}>
        {/* <WebView
          style={{
            minHeight:
              props.data.promotionTermsAndConditions.replace(
                /<[^>]*>?/gm,
                '',
              ).length * 1.25,
            width: Layout.window.width - setValue(60),
          }}
          source={{
          }}
          scrollEnabled={false}
          startInLoadingState
          androidHardwareAccelerationDisabled
        /> */}
      </View>
    ) : null;

  const renderTermsAndConditions = () =>
    props.data.promotionRedemptionRules?.length ? (
      <View style={styles.itemContainer}>
        {/* <WebView
          style={{
            minHeight:
              props.data.promotionRedemptionRules.replace(/<[^>]*>?/gm, '')
                .length * 0.6,
            width: Layout.window.width - setValue(50),
          }}
          source={{
          }}
          scrollEnabled={false}
          startInLoadingState
          androidHardwareAccelerationDisabled
        /> */}
      </View>
    ) : null;

  const onDownloadVoucher = () => {
    const {campaignId, promotionProfileId} = props.data;
    const {quantity} = promotionData;
    const {navigation} = props;

    props.downloadVoucher1(
      {
        campaignId,
        voucherProfileId: promotionProfileId,
        qty: quantity,
        code: '2',
      },
      navigation,
      callBackForWallet,
    );
  };

  const callBackForWallet = () => {
    // alert('call');
    // setPromotionData({...promotionData,
    //   isVerify: true,
    //   goToStackName: 'Wallet',
    // });

    const {
      verify: {lastVerify, afterMs},
    } = props;
    const isVerify = lastVerify
      ? moment().valueOf() <= lastVerify + afterMs
      : false;

    setPromotionData(
      {...promotionData, isVerify: !isVerify, goToStackName: 'Wallet'},
      () => {
        // setVerifyMode(false);
        if (isVerify) {
          navigation.navigate('Wallet', {isFromWallet: true});
        }
      },
    );

    // navigation.navigate('VoucherList', {isFromWallet: true});
    // navigation.navigate('Wallet');
  };

  const onPurchaseVoucher = async (item) => {
    await setPromotionData({...promotionData, isModalVisible: false});
    const {user} = props;
    const {campaignId, promotionProfileId} = props.data;
    const {quantity} = promotionData;
    // props.loaderAction(true);
    // setTimeout(async () => {
    //   const response = await downloadVoucherApi(
    //     {
    //       campaignId,
    //       voucherProfileId: promotionProfileId,
    //       qty: quantity,
    //       code: item.code,
    //     },
    //     user.memberId,
    //     user.token,
    //   );

    //   if (response.code) {
    //     setPromotionData({...promotionData,isModalVisible: false});
    //     props.loaderAction(false);
    //     props.errorAlert(response.error);
    //     return;
    //   }

    //   if (typeof response.checkoutPageUrl === 'undefined') {
    //     setPromotionData({...promotionData,isModalVisible: false});
    //     props.loaderAction(false);
    //     props.errorAlert(strings('promotionDetailScreen.onlinePayment'));
    //   } else {
    //     props.loaderAction(false);
    //     let url = response.checkoutPageUrl.split('//');
    //     url = `${url[0]}//${response.userName}:${response.password}@${url[1]}`;
    //     setPromotionData({...promotionData,
    //       isModalVisible: false,
    //       orderId: response.orderId,
    //       isPaymentModalVisible: true,
    //       paymentData: `apiKey=${API_KEY}&serviceRefId=${
    //         response.orderId
    //       }&amount=
    //     ${response.amount}&currency=${response.currency}&description=
    //     ${encodeURI(response.billDesc)}&memberId=${response.memberId}
    //     &service=${response.serviceName}&paymentId=${response.payMode}
    //     &callbackUrl=${response.callbackUrl}`,
    //       paymentUrl: url,
    //     });
    //   }
    // }, 900);
  };

  const onOpenModalPayment = () => {
    setPromotionData({...promotionData, isModalVisible: true});
  };

  const onNavigateLogin = () => {
    props.navigation.navigate('Wallet');
  };

  const {navigation, route, openAlert, setLoading, getPromotionList, user} =
    props;
  return (
    <View style={styles.container}>
      {/* {promotionData.isVerify ? (
          renderVerifyScreen()
        ) : ( */}
      <>
        <MainHeader
          title={strings(`promotionDetailScreen.title`)}
          leftIcon="back"
          leftPress={() => navigation.goBack()}
          rightIcon={
            ['merchant', 'manager'].includes(user?.type) && 'dustBinSmaller'
          }
          rightOtherIcon={
            ['merchant', 'manager'].includes(user?.type) && 'edit'
          }
          rightOtherPress={() => {
            ['merchant', 'manager'].includes(user?.type) &&
              props.navigation.navigate('create-promotion', promotionData);
          }}
          rightPress={() => {
            ['merchant', 'manager'].includes(user?.type) &&
              openAlert(
                strings('promotionDetailScreen.askDelete'),
                strings('common.yes'),
                async () => {
                  props.deletePromotion(route?.params?.item?.id, navigation);
                },
                strings('common.no'),
                null,
              );
          }}
        />
        {route.params?.item ? (
          <View style={styles.container}>
            <MainContainer>
              <View style={styles.mainContainer}>
                {renderVoucherCard(promotionData)}
              </View>
            </MainContainer>
          </View>
        ) : (
          <View style={styles.indicatorContainer}>
            <ActivityIndicator color={Colors.theme_color} size="large" />
          </View>
        )}
      </>
      {/* )} */}
    </View>
  );
};

export default connect(
  (state) => ({
    data: state.promotion.detail,
    verify: state.auth.verify,
    paymentMode: state.promotion.paymentMode,
    promotionList: state.promotion,
    user: state.auth.user,
    appConfig: state.config,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getPromotionList,
        deletePromotion,
        setLoading: CommonActions.setLoading,
        openAlert: (content, leftButton, leftPress, rightButton, rightPress) =>
          CommonActions.setAlert({
            visible: true,
            content,
            leftButton,
            rightButton,
            leftPress,
            rightPress,
          }),
        showToast: (content) =>
          CommonActions.setShowToast({
            visible: true,
            content: content,
          }),
        errorAlert: (content) =>
          CommonActions.setAlert({visible: true, content}),
      },
      dispatch,
    ),
)(PromotionDetailScreen);
