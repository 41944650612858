import {Platform, StyleSheet} from 'react-native';

import {Colors} from '../../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../../utils';

const wrapper = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 8,
  padding: setValue(5),
};

export default StyleSheet.create({
  ANAContainer: {
    marginTop: setYAxisValue(20),
  },
  container: {
    flex: 1,
  },
  imageContainer: {
    width: setValue(100),
    height: setValue(100),
    borderRadius: setValue(100) / 2,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
    backgroundColor: Colors.white_gray,
  },
  image: {
    width: setValue(100),
    height: setValue(100),
    borderRadius: setValue(100) / 2,
  },
  profileStrengthContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: setYAxisValue(20),
  },
  profileStrengthBody: {
    marginLeft: setXAxisValue(10),
  },
  profileStrengthText: {
    fontSize: setXAxisValue(14),
    color: Colors.color30,

    marginVertical: setYAxisValue(5),
  },
  percentText: {
    fontSize: setXAxisValue(35),
    color: Colors.theme_color,
  },
  basicInfoContainer: {
    ...wrapper,
    borderRadius: 8,
    padding: setValue(15),
  },
  infoView: {
    flexDirection: 'row',
    paddingVertical: setYAxisValue(5),
  },
  infoTitle: {
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  infoText: {
    width: '60%',
    fontSize: setXAxisValue(12),
    paddingBottom: setYAxisValue(20),
  },
  infoBody: {
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  thirdParty: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  thirdPartyContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  thirdPartyText: {
    fontSize: setXAxisValue(12),
    color: Colors.color30,
    marginVertical: setYAxisValue(10),
  },
  connectedText: {
    fontSize: setXAxisValue(12),

    color: Colors.facebook,
    marginLeft: setYAxisValue(15),
  },
  connectText: {
    fontSize: setXAxisValue(12),

    color: Colors.color30,
    marginLeft: setYAxisValue(15),
  },
  buttonContainer: {
    backgroundColor: Colors.white,
    paddingVertical: setYAxisValue(15),
  },
  buttonContainerWeb: {
    backgroundColor: Colors.white,
    marginTop: setValue(50),
  },
  buttonButton: {
    width: '100%',
    backgroundColor: Colors.theme_color,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    bottom: 0,
    position: 'absolute',
    paddingVertical: setYAxisValue(15),
  },
  buttonButtonWeb: {
    width: '100%',
    backgroundColor: Colors.theme_color,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 5,
    bottom: 0,
    position: 'absolute',
    paddingVertical: setYAxisValue(15),
    ...wrapper,
  },
  buttonText: {
    fontSize: setXAxisValue(14),

    color: Colors.white,
  },
  pickerStyle: {
    marginTop: setYAxisValue(20),
    width: '55%',
    alignSelf: 'flex-end',
    right: setXAxisValue(18),
  },
  genderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dateContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '60%',
  },
  infoTextDisable: {
    width: '60%',
    fontSize: setXAxisValue(12),

    paddingBottom: setYAxisValue(20),
    color: Colors.color30,
  },
  headerAddress: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginVertical: setYAxisValue(15),
  },
  addressHeader: {
    fontSize: setXAxisValue(14),

    color: Colors.color30,
  },
  addIcon: {
    width: setValue(28),
    height: setValue(28),
    borderRadius: setValue(28) / 2,
    backgroundColor: Colors.theme_color,
    justifyContent: 'center',
    alignItems: 'center',
  },
  hiddenButton: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    padding: setValue(20),
    marginTop: setYAxisValue(5),
  },
  standaloneRowFront: {
    ...wrapper,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 8,
    padding: setValue(15),
    marginTop: setYAxisValue(5),
    flexDirection: 'row',
  },
  inputStyle: {
    // height: setValue(30),
    padding: 0,
    flex: 1,
    // borderWidth:2,
    marginBottom: setYAxisValue(0),
    borderRadius: 5,
    backgroundColor: Colors.white,
    paddingHorizontal: setXAxisValue(10),
  },
  title: {
    // fontSize: setXAxisValue(14),
    // marginBottom: setYAxisValue(5),
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  content: {
    fontSize: setXAxisValue(14),
    color: Colors.black40,
  },
  appleContainer: {
    width: setXAxisValue(30),
    height: setXAxisValue(30),
    backgroundColor: Colors.color30,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    marginLeft: setYAxisValue(10),
  },
  // new added

  textInputContainer: {
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 5,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
  },
  inputLabel: {
    // color: Colors.black,
    // fontSize: setValue(17),
    // paddingBottom: setYAxisValue(7),
    fontFamily: 'Roboto-Bold',
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    color: Colors.black20,
    marginTop: setYAxisValue(5),
  },
  textInput: {
    // height: setValue(50),
    padding: Platform.OS != 'ios' ? 10 : 15,
    flex: 1,
    paddingHorizontal: setXAxisValue(10),
  },
  textInputWeb: {
    height: setValue(50),
    padding: setValue(10),
    flex: 1,
    paddingHorizontal: setXAxisValue(10),
  },
  inputContainerStyle: {
    height: setValue(45),
  },
  iconStyle: {
    height: setValue(40),
  },
  calendarIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    height: setValue(45),
    right: setXAxisValue(10),
    bottom: setValue(10),
    elevation: 10,
  },
});
