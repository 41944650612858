import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import AppText from '../AppText';
import styles from './styles';

export default props => {
  const { values, selectedIndex, onChange, style } = props;
  return (
    <View style={[styles.container, style]}>
      {values.map((value, index) => {
        const isFirst = index === 0;
        const isLast = index === values.length - 1;
        const isSelected = index === selectedIndex;
        return (
          <TouchableOpacity
            style={[isSelected ? styles.controlSelected : styles.control, isFirst && styles.first, isLast && styles.last]}
            onPress={() => !isSelected && onChange && onChange(index)}
            disabled={props.disabled}
            key={value}
          >
            <AppText style={isSelected ?styles.selectedTitle: styles.title}>{value}</AppText>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};
