import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {View, Text, TextInput, TouchableOpacity, Platform} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {
  AppText,
  LongButton,
  MainContainer,
  MainHeader,
  AppConfigCheckbox,
} from '../../components';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import {goBack, setValue} from '../../utils';
import styles from './styles';
import CommonAction from '../../redux/reduxsauce/commonRedux';
import {getAppConfig} from '../../redux/reduxsauceActions/configActions';

const ReferralConfiguaration = (props) => {
  const config = useSelector((state) => state.config);
  const dispatch = useDispatch();

  const [referralData, setReferralData] = useState({
    new_user_coin: config?.referral?.new_user_coin
      ? JSON.stringify(config?.referral?.new_user_coin)
      : null,
    referral: config?.referral?.referral ? config?.referral?.referral : false,
    referral_per_user: config?.referral?.referral_per_user
      ? JSON.stringify(config?.referral?.referral_per_user)
      : null,
    referred_coin: config?.referral?.referred_coin
      ? JSON.stringify(config?.referral?.referred_coin)
      : null,
  });

  const saveReferralConfigData = async () => {
    dispatch(CommonAction.setLoading(true));

    try {
      const response = await axios.patch(
        `/business/update/business-setting/${config?.businessId}`,
        {
          referral: {
            new_user_coin: Number(referralData?.new_user_coin),
            referral_per_user: Number(referralData?.referral_per_user),
            referred_coin: Number(referralData?.referred_coin),
            referral: referralData?.referral,
          },
        },
      );
      console.log('response  donoation configuration ', response);
      dispatch(getAppConfig());
      dispatch(CommonAction.setLoading(false));
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: strings('subscriptionSetting.alert.success'),
        }),
      );
      goBack(props.navigation);
    } catch (error) {
      dispatch(CommonAction.setLoading(false));
      console.log('error', error);
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: error?.response?.message || 'Something went wrong :(',
        }),
      );
    }
  };
  return (
    <View style={styles.container}>
      <MainHeader
        title={'referral Configuration'}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer>
        <View style={styles.textInputContainer}>
          <AppText style={styles.inputLabel} required>
            {'New User Coin'}
          </AppText>
          <TextInput
            underlineColorAndroid="transparent"
            autoCorrect={false}
            keyboardType="numeric"
            placeholder={'Enter donation reward coin multiplier'}
            placeholderTextColor={Colors.color30}
            returnKeyType="done"
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={referralData?.new_user_coin}
            onChangeText={(text) =>
              setReferralData({
                ...referralData,
                new_user_coin: text,
              })
            }
          />
        </View>
        <View style={styles.textInputContainer}>
          <AppText style={styles.inputLabel} required>
            {'Referral per user'}
          </AppText>
          <TextInput
            underlineColorAndroid="transparent"
            autoCorrect={false}
            keyboardType="numeric"
            placeholder={'Enter donation reward coin multiplier'}
            placeholderTextColor={Colors.color30}
            returnKeyType="done"
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={referralData?.referral_per_user}
            onChangeText={(text) =>
              setReferralData({
                ...referralData,
                referral_per_user: text,
              })
            }
          />
        </View>
        <View style={styles.textInputContainer}>
          <AppText style={styles.inputLabel} required>
            {'Referred Coin'}
          </AppText>
          <TextInput
            underlineColorAndroid="transparent"
            autoCorrect={false}
            keyboardType="numeric"
            placeholder={'Enter donation reward coin multiplier'}
            placeholderTextColor={Colors.color30}
            returnKeyType="done"
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={referralData?.referred_coin}
            onChangeText={(text) =>
              setReferralData({
                ...referralData,
                referred_coin: text,
              })
            }
          />
        </View>
        <AppConfigCheckbox
          title={'Referral is active'}
          desc={'Please check if referral is active'}
          checked={referralData?.referral}
          onChangeCheck={(checked) => {
            setReferralData({
              ...referralData,
              referral: checked,
            });
          }}
        />

        {Platform.OS == 'web' && (
          <LongButton
            text={'Save Configuration'}
            style={{marginTop: setValue(10), marginBottom: setValue(45)}}
            onPress={saveReferralConfigData}
          />
        )}
      </MainContainer>
      {Platform.OS !== 'web' && (
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            style={styles.buttonButton}
            onPress={saveReferralConfigData}
          >
            <AppText style={styles.buttonText}>{'Save Configuration'}</AppText>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

export default ReferralConfiguaration;
