import {Platform, StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {screenWidth, setValue, setXAxisValue, setYAxisValue} from '../../utils';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  webContainer: {
    overflow: 'visible',
    height: '100vh',
  },
  orderSettingContainer: {
    flex: 1,
  },
  itemContainer: {
    marginBottom: 10,
    flex: 1,
    flexDirection: 'row',
    backgroundColor: Colors.white,
    borderRadius: 5,
    padding: 15,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
    // marginHorizontal: setXAxisValue(3),
    // marginVertical: 3,
  },
  itemIcon: {
    justifyContent: 'center',
  },
  itemDetail: {
    marginLeft: 10,
    flex: 1,
  },
  itemCheckBox: {
    justifyContent: 'center',
  },
  itemTitle: {
    fontWeight: 'bold',
    color: Colors.black60,
  },
  itemDesc: {
    color: Colors.black40,
  },
  textInputContainer: {
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 5,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
  },
  multiLineTextInput: {
    padding: setValue(10),
    textAlignVertical: 'top',
    // minHeight: 90,
  },
  inputLabel: {
    fontFamily: 'Roboto-Bold',
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(15),
    color: Colors.black60,
    marginTop: setYAxisValue(5),
  },
  subInputLabel: {
    fontFamily: 'Roboto-Bold',
    marginLeft: 10,
    fontSize: setXAxisValue(15),
    color: Colors.color30,
    marginTop: setYAxisValue(5),
  },
  textInput: {
    height: setValue(50),
    color: Colors.black60,
    flex: 1,
    paddingHorizontal: setXAxisValue(10),
  },
  textInputWeb: {
    height: setValue(50),
    padding: setValue(10),
    flex: 1,
    paddingHorizontal: setXAxisValue(10),
  },
  inputContainer: {
    marginTop: setYAxisValue(10),
  },
  deliveryRist: {
    marginBottom: setYAxisValue(10),
    fontFamily: 'Roboto-Bold',
    color: Platform.OS == 'web' ? Colors.black : Colors.white,
    paddingHorizontal: setXAxisValue(2),
    fontSize: setValue(17),
  },
});
