import {StyleSheet} from 'react-native';
import {Colors} from '../../../constants';
import {setYAxisValue, setValue} from '../../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 5,
};

export default StyleSheet.create({
  methodItem: {
    backgroundColor: Colors.white,
    padding: setValue(10),
    marginHorizontal: 0,
    borderRadius: 5,
    ...shadow,
  },
  methodType: {
    fontSize: setValue(17),
    fontFamily: 'Roboto-Bold',
  },
  methodDetail: {
    fontSize: setValue(15),
    fontFamily: 'Roboto-Regular',
    fontWeight:"400",
    color: Colors.black,
    textTransform:"capitalize",
    maxWidth:"95%",
  },
});
