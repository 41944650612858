import get from 'lodash.get';
import React, {Component} from 'react';
import {
  ActivityIndicator,
  Animated,
  Easing,
  FlatList,
  Image,
  SafeAreaView,
  ScrollView,
  TextInput,
  TouchableOpacity,
  View,
  Text,
  Platform,
  TouchableWithoutFeedback,
} from 'react-native';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {bindActionCreators} from 'redux';
import {
  AppText,
  Icon,
  Modal,
  MainContainer,
  MainHeader,
  ProductCategoryDarkItem,
  ProductCategoryItem,
  ProductItemGird,
  ProductItemList,
  SelectProductVeriantsModal,
  LongButton,
  ProductFilterModal,
} from '../../components';
import {Colors, Layout} from '../../constants';
import {strings} from '../../i18n';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {
  getCategories,
  getProductsV1,
  getProductsFilter,
  getProductsV2,
} from '../../redux/reduxsauceActions/productActions';
import styles from './styles';
import {NoRecordFound} from '../../components';
import {
  goBack,
  cashFormat,
  screenWidth,
  setValue,
  setYAxisValue,
  setXAxisValue,
  screenHeight,
} from '../../utils';
import {StatusBar} from 'expo-status-bar';
import {AntDesign} from '@expo/vector-icons';

class ProductListScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageProducts: 1,
      searchText: '',
      item: null,
      itemId: null,
      hasScrolledProducts: false,
      showAllCategory: false,
      chefChoice: false,
      selectSubCategory: null,
      selectedItem: null,
      scrollPosition: 0,
      refresh: false,
      animatedValue: new Animated.Value(1),
      alreadyAddedToCartItem: null,
      xOffsetToPage: 0,
      yOffsetToPage: 0,
      subCategoriesList: [],
      imageHeight: 0,
      itemQuantity: props.order.itemQuantity,
      products: props.products || [],
      changeView: 'grid-view',
      isVisible: false,
      filterModal: false,
      offset: 0,
      limit: 7,
      sortBy: '',
      foodType: '',
      selectedFilter: [],
      staticFilters: {},
      staticSortBy: '',
      staticFoodType: '',
      staticSelectedFilter: [],
    };
  }

  UNSAFE_componentWillReceiveProps(nProps) {
    nProps.products &&
      this.setState({
        products: [...nProps.products],
      });
  }

  componentDidMount = () => {
    this.props.navigation.addListener('focus', async () => {
      this.props?.appConfig?.product?.showCategory &&
        (await this.props.getCategories());
      this.props.order.categories.find(
        (a) => a?.id == this.state.selectedItem,
      ) &&
        this.setState({
          subCategoriesList: [
            ...this.props.order.categories.find(
              (a) => a.id == this.state.selectedItem,
            )?.sub_category,
          ],
        });
    });
    // this.setState({
    //   selectedItem: null,
    // });

    this.props.getProductsFilter((action, data) => {
      const size = data.data;
      console.log('size data------------------>>>', size);
      if (action == 'success') {
        this.setState({
          staticFilters: size,
        });
      }
    });

    this.props.getProductsV2(
      {
        limit: this.state.limit,
        offset: 0,
        search: false,
        category: false,
        subCategory: this.state.selectSubCategory,
      },
      (action, data) => {
        if (action == 'success') {
          this.setState({
            offset: this.state.offset + this.state.limit,
            count: data?.count,
          });
        }
      },
      true,
    );
  };

  handleRefresh = async () => {
    this.setState({refresh: true});
    await this.props.getProductsV2(
      {
        limit: this.state.limit,
        offset: 0,
        search: false,
        category: false,
        subCategory: this.state.selectSubCategory,
      },
      (action, data) => {
        if (action == 'success') {
          this.setState({
            offset: 0 + this.state.limit,
            count: data?.count,
          });
        }
      },
      true,
    );
    this.setState({refresh: false});
  };

  componentDidUpdate = (prevProps) => {};

  onClearText = () => {
    // this.state.pageProducts = 1;
    // this.state.searchText = '';
    this.setState({searchText: '', pageProducts: 1, selectedItem: ''});
    this.handleRefresh();
    // this.state.selectedItem = '';
    // this.refreshProducts(false, '');
  };

  renderSearchBar = () => {
    let timer;
    return (
      <View style={styles.searchBarContainer}>
        <TextInput
          underlineColorAndroid="transparent"
          placeholder={strings('ordering.placeOrderScreen.searchHint')}
          placeholderTextColor="gray"
          autoCorrect={false}
          style={styles.searchBarText}
          enablesReturnKeyAutomatically
          returnKeyType="search"
          onChangeText={(text) => {
            this.setState({searchText: text});
            this.props.getProductsV2(
              {
                limit: this.state.limit,
                offset: 0,
                search: text,
                category: this.state.selectedItem
                  ? this.state.selectedItem
                  : false,
                subCategory: this.state.selectSubCategory,
              },
              (action, data) => {
                if (action == 'success') {
                  this.setState({
                    offset: 0 + this.state.limit,
                    count: data?.count,
                  });
                }
              },
              true,
            );
            clearTimeout(timer);
            timer = setTimeout(() => {}, 600);
          }}
          defaultValue={this.state.searchText}
          onFocus={() => {
            this.setState({showAllCategory: false});
          }}
        />
        <View style={styles.searchBarIcon}>
          {this.state.searchText.length == 0 ? (
            <Icon name="search" size={18} color="gray" />
          ) : (
            <TouchableOpacity onPress={this.onClearText}>
              <Icon name="cross" size={18} color="gray" />
            </TouchableOpacity>
          )}
        </View>
      </View>
    );
  };

  handleSelection = (id, code) => {
    console.log('id =----', id);
    const selectedId = this.state.selectedItem;
    const {chefChoice} = this.state;
    let currentChoice;
    let selectedItem = '';
    this.state.pageProducts = 1;
    if (code === 'CC') {
      currentChoice = !chefChoice;
      this.setState({chefChoice: !chefChoice});
    }

    let updateObj = {};

    // if already selected then it's clear last selected value
    if (selectedId === id) {
      updateObj = {selectedItem: null, searchText: ''};
      this.props.getProductsV2(
        {
          limit: this.state.limit,
          offset: 0,
          search: false,
          category: false,
          subCategory: this.state.selectSubCategory,
        },
        (action, data) => {
          if (action == 'success') {
            this.setState({
              offset: 0 + this.state.limit,
              count: data?.count,
            });
          }
        },
        true,
      );
    } else {
      updateObj = {selectedItem: id, searchText: ''};
      selectedItem = id;
      this.props.getProductsV2(
        {
          limit: this.state.limit,
          offset: 0,
          search: false,
          category: id,
          subCategory: this.state.selectSubCategory,
        },
        (action, data) => {
          if (action == 'success') {
            this.setState({
              offset: 0 + this.state.limit,
              count: data?.count,
            });
          }
        },
        true,
      );
    }
    this.setState(
      updateObj,
      //   () => {
      //   this.refreshProducts(currentChoice, selectedItem);
      // }
    );
  };

  renderSubCategoryList = () => {
    const {subCategoriesList, selectedItem} = this.state;
    const isDarkTheme = true;
    let addSubCategory = {
      name: 'Add Sub Categories',
      icon: 'add',
      type: 'merchant',
      onClickChange: () => {
        this.props.navigation.navigate('edit-sub-category', {
          edit: false,
          category: selectedItem,
        });
      },
    };
    let showAllItem = {
      id: null,
      name: 'Show All',
      description: '',
      photo: require('../../../assets/images/General/show-all.png'),
      deleted: false,
      business: null,
      type: 'all',
    };
    let data = [addSubCategory, showAllItem, ...subCategoriesList];
    return (
      <View style={{flexDirection: 'row', marginBottom: setValue(15)}}>
        <FlatList
          ref="subcategoryList"
          contentContainerStyle={{flexGrow: 0}}
          horizontal
          scrollEnabled={true}
          keyExtractor={(item, index) => index}
          data={data}
          renderItem={({item, index}) => {
            if (item?.type == 'merchant') {
              return (
                <TouchableOpacity onPress={item?.onClickChange}>
                  <View style={styles.categoryButtonIconDarkContainer}>
                    <View style={styles.categoryImages}>
                      <Icon
                        name={item?.icon}
                        size={16}
                        color={Colors.black60}
                      />
                    </View>
                  </View>
                </TouchableOpacity>
              );
            }

            return (
              <View style={{height: 30}}>
                <ProductCategoryDarkItem
                  item={item}
                  index={index}
                  OnPressCategory={() => {
                    this.setState({
                      products: [],
                      offset: 0 + this.state.limit,
                      selectSubCategory: item?.id,
                    });
                    this.props.getProductsV2(
                      {
                        subCategory: item?.id,
                        search: this.state.searchText,
                        category: this.state.selectedItem,
                        offset: 0,
                        limit: this.state.limit,
                      },
                      (action, data) => {
                        if (action == 'success') {
                          this.setState({
                            count: data?.count,
                            offset: 0 + this.state.limit,
                          });
                        }
                      },
                      true,
                    );
                  }}
                  selectedItem={this.state?.selectSubCategory}
                />
              </View>
            );
          }}
          showsHorizontalScrollIndicator={false}
          extraData={selectedItem}
        />
      </View>
    );
  };
  renderCategoryList = () => {
    const {selectedItem} = this.state;
    const isDarkTheme = true;

    const categoriesInShort = this.props.order.categories;
    let addCategory = {
      name: strings('ProductListScreen.addCatggory'),
      icon: 'add',
      type: 'merchant',
      onClickChange: () => {
        this.props.navigation.navigate('manage-category');
      },
    };
    let showAllItem = {
      id: null,
      name: 'Show All',
      description: '',
      photo: require('../../../assets/images/General/show-all.png'),
      deleted: false,
      business: null,
      type: 'all',
    };
    let data = ['merchant', 'manager'].includes(this.props?.user?.type)
      ? [addCategory, showAllItem, ...categoriesInShort]
      : [showAllItem, ...categoriesInShort];
    return (
      <View style={{flexDirection: 'row'}}>
        <FlatList
          ref="categoryList"
          contentContainerStyle={{flexGrow: 0}}
          horizontal
          scrollEnabled={true}
          keyExtractor={(item, index) => index}
          data={data}
          renderItem={({item, index}) => {
            if (item?.type == 'merchant') {
              return (
                <TouchableOpacity onPress={item?.onClickChange}>
                  <View style={styles.categoryButtonIconContainer}>
                    <View style={styles.categoryImages}>
                      <Icon
                        name={item?.icon}
                        size={16}
                        color={Colors.black60}
                      />
                    </View>
                  </View>
                  <AppText
                    style={[styles.categoryButtonText, {color: Colors.black60}]}
                    numberOfLines={2}
                  >
                    {item.name}
                  </AppText>
                </TouchableOpacity>
              );
            }

            return (
              <View>
                <ProductCategoryItem
                  item={item}
                  index={index}
                  OnPressCategory={() => {
                    this.handleSelection(item.id, item.code);
                    this.setState({
                      selectSubCategory: null,
                      subCategoriesList: item?.sub_category || [],
                    });
                  }}
                  selectedItem={this.state.selectedItem}
                />
              </View>
            );
          }}
          showsHorizontalScrollIndicator={false}
          extraData={selectedItem}
        />
      </View>
    );
  };

  renderProductList = () => {
    const {products, changeView} = this.state;
    const {productSearchLoading} = this.props;

    let TotalWidth =
      screenWidth > 1023 ? 992 : screenWidth - 2 * setXAxisValue(15) - 22;

    let addProduct = {
      id: '0',
      name: strings('ProductListScreen.addProduct'),
      icon: 'add',
      type: 'merchant',
    };

    let productList = ['merchant', 'manager'].includes(this.props?.user?.type)
      ? [addProduct, ...products]
      : [...products];

    if (productSearchLoading) {
      return (
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ActivityIndicator color={Colors.theme_color} size="large" />
        </View>
      );
    } else if (productList.length === 0) {
      return (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <NoRecordFound title="Product" hideImage={true} />
        </View>
      );
    } else if (changeView == 'grid-view') {
      return (
        <FlatList
          key="grid"
          // ref="productList"
          style={{width: '100%'}}
          contentContainerStyle={
            Platform.OS == 'web'
              ? {width: '100%', alignSelf: 'flex-start', marginHorizontal: -3}
              : {width: '100%', paddingBottom: 20}
          }
          data={productList}
          showsVerticalScrollIndicator={false}
          numColumns={screenWidth > 576 ? parseInt(TotalWidth / 180) : 2}
          refreshing={this.state.refresh}
          onRefresh={this.handleRefresh}
          renderItem={({item, index}) => {
            if (item?.type == 'merchant') {
              return (
                <TouchableOpacity
                  style={styles.productItemGridView}
                  onPress={() => this.props.navigation.navigate('edit-product')}
                >
                  <View style={[styles.addProductContainer]}>
                    <Icon name={item?.icon} size={16} color={Colors.black60} />
                    <AppText style={styles.addProductTitle}>
                      {item.name}
                    </AppText>
                  </View>
                </TouchableOpacity>
              );
            } else {
              return (
                <ProductItemGird
                  item={item}
                  index={index}
                  navigation={this.props.navigation}
                  onPressProductItem={() =>
                    this.props.navigation.navigate('productDetail', {
                      slug: item.name
                        .toLowerCase()
                        .replace(/ /g, '-')
                        .replace(/[^\w-]+/g, ''),
                      id: item.id,
                    })
                  }
                />
              );
            }
          }}
          onEndReachedThreshold={0.4}
          onEndReached={() => {
            console.log('end reached ---------', this.state.offset);
            productList.length > 7 &&
              this.props.getProductsV2(
                {
                  search: this.state.searchText,
                  category: this.state.selectedItem,
                  offset: this.state.offset,
                  limit: this.state.limit,
                },
                (action, data) => {
                  this.setState({
                    offset: this.state.offset + this.state.limit,
                    count: data.count,
                  });
                },
              );
          }}
          keyExtractor={(item, index) => index}
          ListFooterComponent={
            <View
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                height:
                  Platform.OS == 'web'
                    ? screenWidth < 576
                      ? setYAxisValue(150)
                      : setYAxisValue(100)
                    : setYAxisValue(50),
              }}
            >
              {this.state.count > productList.length && (
                <ActivityIndicator color={Colors.theme_color} size={25} />
              )}
            </View>
          }
        />
      );
    } else {
      return (
        <FlatList
          key="list"
          // ref="productList"
          style={{width: '100%'}}
          contentContainerStyle={
            Platform.OS == 'web' ? {alignSelf: 'center'} : {paddingBottom: 20}
          }
          data={productList}
          showsVerticalScrollIndicator={false}
          numColumns={Platform.OS == 'web' ? parseInt(screenWidth / 220) : 1}
          refreshing={this.state.refresh}
          onRefresh={this.handleRefresh}
          renderItem={({item}) => {
            if (item?.type == 'merchant') {
              return (
                <TouchableOpacity
                  style={styles.productItemListView}
                  onPress={() => this.props.navigation.navigate('edit-product')}
                >
                  <View style={[styles.addProductContainer]}>
                    <Icon name={item?.icon} size={16} color={Colors.black60} />
                    {/* <AppText style={styles.addProductTitle}>
                      {item.name}
                    </AppText> */}
                  </View>
                </TouchableOpacity>
              );
            } else {
              return (
                <ProductItemList
                  item={item}
                  onPressProductItem={() =>
                    this.props.navigation.navigate('productDetail', {
                      slug: item.name
                        .toLowerCase()
                        .replace(/ /g, '-')
                        .replace(/[^\w-]+/g, ''),
                      id: item.id,
                    })
                  }
                  onPressPlusIcon={() =>
                    item?.variants && item?.variants.length != 0
                      ? this.setState({
                          item: item,
                          itemId: item?.id,
                          isVisible: true,
                        })
                      : this.onAddToCart(item)
                  }
                />
              );
            }
          }}
          onEndReachedThreshold={0.4}
          onEndReached={() => {
            console.log('end reached ---------', this.state.offset);
            productList.length > 7 &&
              this.props.getProductsV2(
                {
                  search: this.state.searchText,
                  category: this.state.selectedItem,
                  offset: this.state.offset,
                  limit: this.state.limit,
                },
                (action, data) => {
                  this.setState({
                    offset: this.state.offset + this.state.limit,
                    count: data.count,
                  });
                },
              );
          }}
          keyExtractor={(item, index) => index}
          ListFooterComponent={
            <View
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                height: setYAxisValue(100),
              }}
            >
              {this.state.count > productList.length && (
                <ActivityIndicator color={Colors.theme_color} size={25} />
              )}
            </View>
          }
        />
      );
    }
  };

  renderData = () => {
    const {appConfig} = this.props;

    if (this.state.showAllCategory) {
      return (
        <ScrollView
          contentContainerStyle={{flexWrap: 'wrap', flexDirection: 'row'}}
          showsHorizontalScrollIndicator={false}
        >
          {this.props.order.categories.map((item) =>
            this.renderCategoryItem({item}),
          )}
          {this.renderShowHideCategoriesButton()}
        </ScrollView>
      );
    }
    return (
      <View style={{flex: 1}}>
        {appConfig?.product?.showCategory && this.renderCategoryList()}
        {appConfig?.product?.showCategory &&
          this.state.selectedItem &&
          this.renderSubCategoryList()}
        {/* {this.props.user.type === 'merchant' && (
          <TouchableOpacity
            style={styles.addProductBtn}
            onPress={() => this.props.navigation.navigate('edit-product')}
          >
            <AppText style={styles.addProductBtnText}>Add Product</AppText>
          </TouchableOpacity>
        )} */}
        {this.renderProductList()}
      </View>
    );
  };

  renderShoppingCart = () => {
    if (this.state.itemQuantity === 0 || this.state.showAllCategory)
      return null;

    // if (this.state.showAllCategory) return null;

    let totalContent = this.state.itemQuantity;
    if (typeof this.props.order.orderItems !== 'undefined') {
      totalContent = this.props.order.orderItems.reduce(
        (acc, v) => acc + v.orderQty,
        0,
      );
      console.log('totalContent: ', totalContent);
    }
    return (
      <SafeAreaView>
        <TouchableOpacity
          style={styles.cartContainer}
          onPress={() => this.props.navigation.navigate('cart')}
        >
          <View style={styles.cartInfoContainer}>
            <AppText style={styles.cartQuantity}>
              {/* {this.getTotalCount()} */}
            </AppText>
            <AppText style={styles.cartText}>
              {strings('ordering.placeOrderScreen.addToCart')}
            </AppText>
          </View>
          <Icon
            name="cart"
            style={styles.cartIcon}
            color={Colors.primary_color}
          />
        </TouchableOpacity>
      </SafeAreaView>
    );
  };

  sortByProduct = () => {
    this.props.getProductsV2(
      {
        search: this.state.searchText,
        category: this.state.selectedItem,
        offset: 0,
        limit: this.state.limit,
        sortBy: this.state.sortBy,
        variants: this.state.staticSelectedFilter,
        food_type: this.state.staticFoodType,
      },
      (action, data) => {
        this.setState({
          offset: this.state.offset + this.state.limit,
          count: data.count,
        });
      },
      true,
    );
  };

  renderModal = () => {
    const {appConfig} = this.props;
    const {filterModal, staticFilters} = this.state;

    return (
      <ProductFilterModal
        isVisible={filterModal}
        onClose={() =>
          this.setState({
            filterModal: false,
            sortBy: this.state.staticSortBy,
            selectedFilter: this.state.staticSelectedFilter,
            foodType: this.state.staticFoodType,
          })
        }
        sortBy={this.state.sortBy}
        foodType={this.state.foodType}
        updateSortBy={(value) => {
          this.setState({
            sortBy: value,
          });
        }}
        updateFoodType={(value) => {
          this.setState({
            foodType: value,
          });
        }}
        updateSelectedFilter={(o) =>
          this.setState(
            this.state.selectedFilter.includes(o)
              ? {
                  selectedFilter: this.state.selectedFilter.filter(function (
                    value,
                  ) {
                    return value !== o;
                  }),
                }
              : {
                  selectedFilter: this.state.selectedFilter.concat([o]),
                },
          )
        }
        staticFilters={this.state.staticFilters}
        selectedFilter={this.state.selectedFilter}
        onApply={() => {
          this.setState(
            {
              filterModal: false,
              offset: 0,
              staticSortBy: this.state.sortBy,
              staticSelectedFilter: this.state.selectedFilter,
              staticFoodType: this.state.foodType,
            },
            () => this.sortByProduct(),
          );
        }}
      />
    );
  };

  render() {
    const {appConfig} = this.props;
    const {filterModal, item, isVisible} = this.state;
    console.log('item---------', item);
    return (
      <View style={styles.container}>
        <Helmet>
          <meta
            name="description"
            content="India's biggest online store for Mobiles, Fashion (Clothes/Shoes), Electronics, Home Appliances, Books, Home, Furniture, Grocery, Jewelry, Sporting goods, Beauty & Personal Care and more! Find the largest selection from all brands at the lowest prices in India. Payment options - COD, EMI, Credit card, Debit card &amp; more."
          />
        </Helmet>
        {isVisible && <StatusBar backgroundColor={Colors.overlay} />}
        <SelectProductVeriantsModal
          isVisible={isVisible}
          onClose={() => this.setState({isVisible: false})}
          item={item}
        />
        <MainHeader
          leftIcon={'back'}
          leftPress={() => this.props.navigation.goBack()}
          navigation={this.props.navigation}
          title={
            this.props.route.params?.type == 'restaurant'
              ? this.props.route.params?.outletName
              : strings('ProductListScreen.products')
          }
          tableDetail={
            this.props.route.params?.type == 'restaurant' &&
            `Table No. ${this.props.route.params?.tableNumber}`
          }
          // rightOtherIcon={'listView'}
          rightOtherPress={() =>
            this.setState((prev) => ({
              changeView:
                prev.changeView == 'list-view' ? 'grid-view' : 'list-view',
            }))
          }
        />
        <MainContainer useView showFullWidth={true}>
          {/* <AppText style={styles.outletName}>
            {`${this.props.route.params?.outletName}`}
            <AppText>{this.props.order.mode === 'PICKUP' && ' PICKUP'}</AppText>
            <AppText>
              {this.props.order.mode !== 'PICKUP' &&
                `- TABLE NO. ${this.props.route.params?.tableNumber}`}
            </AppText>
          </AppText> */}
          {/* <AppText style={styles.outletName}>
            {this.props.order.mode === 'PICKUP' &&
              this.props.order.orderPickDetail &&
              this.props.order.orderPickDetail.pickUpPoint}
          </AppText> */}
          {/* {this.props.route.params && (
            <View>
              <Text
                style={{
                  fontSize: setXAxisValue(17),
                  color: Colors.white,
                }}
              >
                Table No. {this.props.route.params.tableNumber}
              </Text>
            </View>
          )} */}
          {this.renderSearchBar()}
          {!this.props.common.loading && this.renderData()}
          <TouchableOpacity
            style={Platform.OS == 'web' ? styles.fabBtnWeb : styles.fabBtn}
            onPress={() => {
              this.setState({filterModal: true});
              // this.props.navigation.navigate('product-filter');
            }}
          >
            <Text style={styles.fabTextIcon}>
              <AntDesign name="filter" size={26} color={Colors.white} />
            </Text>
          </TouchableOpacity>
        </MainContainer>
        {/* {['local', 'development'].includes(appConfig?.ENV) && ( */}

        {/*  )} */}
        {/* {this.props.route.params?.type == 'restaurant' &&
          this.renderShoppingCart()} */}
        {/* {true && this.renderAddToCartAnimation()} */}
        {this.renderModal()}
      </View>
    );
  }
}

export default connect(
  (state) => ({
    order: state.products,
    productAdditionImages: state.order?.productAdditionImages,
    appConfig: state.config,
    products: state.products.products,
    productSearchLoading: state.products.productSearchLoading,
    common: state.common,
    user: state.auth.user,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getCategories,
        getProductsV2,
        getProductsFilter,
        openAlert: (content, leftButton, leftPress, rightButton, rightPress) =>
          CommonActions.setAlert({
            visible: true,
            content,
            leftButton,
            leftPress,
            rightButton,
            rightPress,
          }),
      },
      dispatch,
    ),
)(ProductListScreen);
