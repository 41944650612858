import React, {useState, useEffect} from 'react';
import {
  View,
  FlatList,
  Platform,
  Image,
  TouchableWithoutFeedback,
} from 'react-native';
import {
  MainHeader,
  MainContainer,
  AddressItem,
  LongButton,
  AppText,
} from '../../../components';
import {goBack, setValue} from '../../../utils';
import styles from './styles';
import {strings} from '../../../i18n';
import {SwipeRow} from '../../../components';
import NoRecordFound from '../../../components/NoRecordFound';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {useIsFocused} from '@react-navigation/native';

export default function GalleryList(props) {
  const [imageList, setImageList] = useState([]);

  const config = useSelector((state) => state?.config);
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const getGalleryList = async () => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios
        .get('/business/gallery/list/' + config?.domain)
        .then((response) => {
          console.log('response gallery list --> ', response);
          setImageList(response?.data);
          dispatch(CommonActions.setLoading(false));
        });
    } catch (error) {
      dispatch(CommonActions.setLoading(false));
      console.log('message gallery list', error?.response);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };

  const deleteImage = async (id) => {
    dispatch(CommonActions.setLoading(true));
    try {
      await axios.delete('/business/gallery/delete/' + id).then((response) => {
        console.log('response --> ', response);
        getGalleryList();
      });
    } catch (error) {
      dispatch(CommonActions.setLoading(false));
      console.log('message', error?.response?.message);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message,
        }),
      );
    }
  };

  useEffect(() => {
    isFocused && getGalleryList();
  }, [isFocused]);

  return (
    <View style={Platform.OS == 'web' ? styles.webContainer : styles.container}>
      <MainHeader
        title={strings('galleryList.title')}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer useView={Platform.OS === 'web' ? false : true}>
        {imageList ? (
          <FlatList
            data={imageList}
            showsVerticalScrollIndicator={false}
            keyExtractor={(item, index) => index}
            renderItem={({item, index}) => (
              <SwipeRow
                key={index}
                editable={true}
                leftOpenValue={70}
                rightOpenValue={-100}
                onPressEdit={() =>
                  props.navigation.navigate('edit-gallery', {
                    item: item,
                    edit: true,
                  })
                }
                onPressDelete={() =>
                  dispatch(
                    CommonActions.setAlert({
                      visible: true,
                      content: strings('galleryList.askDelete'),
                      rightButton: strings('settingScreen.confirm'),
                      rightPress: async () => {
                        deleteImage(item?.id);
                      },
                      leftButton: strings('settingScreen.cancel'),
                      leftPress: null,
                    }),
                  )
                }
              >
                <GalleryItem
                  item={item}
                  onPress={() => {
                    props.navigation.navigate('gallery-detail', {item: item});
                  }}
                />
              </SwipeRow>
            )}
          />
        ) : (
          <NoRecordFound
            title={strings('galleryList.noRecord.title')}
            message={strings('galleryList.noRecord.message')}
          />
        )}
        {/* if platform is web then edit address button with flat list  */}
        {Platform.OS == 'web' && (
          <LongButton
            text={strings('galleryList.addImage')}
            style={{marginTop: setValue(10), marginBottom: setValue(45)}}
            onPress={() => props.navigation.navigate('edit-gallery')}
          />
        )}
      </MainContainer>

      {/* if Platform os other then web then button is end of flex */}
      {Platform.OS != 'web' && (
        <LongButton
          text={strings('galleryList.addImage')}
          style={{
            width: '100%',
            borderRadius: 0,
          }}
          onPress={() => props.navigation.navigate('edit-gallery')}
        />
      )}
    </View>
  );
}

const GalleryItem = ({item, onPress}) => {
  return (
    <TouchableWithoutFeedback onPress={() => onPress()}>
      <View style={styles.blogItem}>
        {item?.image && (
          <Image
            source={{uri: item?.image}}
            style={[styles.blogImage, {aspectRatio: 30 / 17}]}
          />
        )}
        {item?.title !== null && item?.title !== '' && (
          <AppText style={styles.blogTitle} numberOfLines={2}>
            {item?.title}
          </AppText>
        )}
        {item?.description !== null && item?.description !== '' && (
          <AppText style={styles.blogDesc} numberOfLines={3}>
            {item?.description}
          </AppText>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};
