import {
  View,
  TextInput,
  ActivityIndicator,
  Image,
  TouchableOpacity,
  Platform,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Keyboard
} from 'react-native';
import React, {useState, useRef, useEffect} from 'react';
import {AppText, LongButton, MainContainer, MainHeader} from '../../components';
import styles from './styles';
import {goBack} from '../../utils';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {Colors} from '../../constants';
import moment from 'moment';
import {get} from 'lodash';
import {strings} from '../../i18n';

const SendCoinScreen = (props) => {
  const {navigation, route} = props;
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [note, setNote] = useState('');
  const [amount, setAmount] = useState(null);
  const dispatch = useDispatch();
  const {config} = useSelector((state) => state);
  const textInputRef = useRef();

  const getUserDetail = () => {
    dispatch(CommonActions.setLoading(true));
    setLoading(true);
    axios
      .get('/user/details/' + route.params?.customerID)
      .then((response) => {
        console.log('response =============>', response.data.data);
        setUserData(response?.data?.data);
        setLoading(false);
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        dispatch(CommonActions.setLoading(false));
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.message,
          }),
        );
        console.log(error?.response?.data);
        goBack(navigation);
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content:
              error?.response?.data?.message || strings('common.invalidQR'),
          }),
        );
        setLoading(false);
      });
  };

  const onSendCoins = () => {
    if (!amount || amount == '' || amount == '0') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('sendCoinScreen.required.coin'),
        }),
      );
      return;
    }
    dispatch(CommonActions.setLoading(true));
    axios
      .post('/coin/credit', {
        user: route.params.customerID,
        remark: note,
        coin: amount,
      })
      .then((response) => {
        console.log('send coin api=====', response);
        dispatch(CommonActions.setLoading(false));
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: strings('sendCoinScreen.alert.sentCoin'),
          }),
        );
        goBack(navigation);
      })
      .catch((error) => {
        console.log('err response=====', error.response);
        dispatch(CommonActions.setLoading(false));
        dispatch(CommonActions.setLoading(false));
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.message,
          }),
        );
      });
  };
  useEffect(getUserDetail, [route?.params]);

  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('sendCoinScreen.title')}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      {loading ? (
        <View style={styles.loadingContainer}>
          {/* <ActivityIndicator color={Colors.theme_color} size={'large'} /> */}
        </View>
      ) : (
        <MainContainer useView>
          <KeyboardAvoidingView
            style={{flex: 1}}
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          >
            <TouchableWithoutFeedback
              style={{flex: 1}}
              onPress={() => Keyboard.dismiss()}
            >
              <View style={{flex: 1}}>
                <View style={styles.userProfileCard}>
                  <View style={styles.imageContainer}>
                    <Image
                      style={styles.image}
                      source={
                        userData?.photo && userData?.photo !== null
                          ? {uri: userData?.photo}
                          : require('../../../assets/images/General/profile.png')
                      }
                    />
                  </View>
                  <View style={styles.userDetailsContainer}>
                    <AppText style={styles.userName}>
                      {userData?.first_name + ' ' + userData?.last_name}
                    </AppText>
                    <AppText style={styles.userInfo}>{userData?.email}</AppText>
                    <AppText style={styles.userInfo}>{userData?.phone}</AppText>
                    <AppText style={styles.userAbout}>{`${strings(
                      'sendCoinScreen.label.joinedDate',
                    )} : ${moment(userData?.date_joined).format(
                      'DD MMM YYYY',
                    )} `}</AppText>
                  </View>
                </View>

                <View style={styles.textInputConatiner}>
                  <View style={{flexDirection: 'row'}}>
                    <AppText style={styles.currencySymbol}>
                      {get(config, 'currencySymbol', '₹')}
                    </AppText>
                    <TextInput
                      keyboardType="numeric"
                      value={amount}
                      onChangeText={(text) => setAmount(text)}
                      autoFocus
                      style={styles.textInput}
                      placeholder="0.00"
                      placeholderTextColor={Colors.color30}
                    />
                  </View>
                  <View style={styles.addNoteButtonContainer}>
                    <TextInput
                      value={note}
                      multiline={true}
                      maxLength={256}
                      onChangeText={(text) => setNote(text)}
                      style={[
                        styles.textInput,
                        {fontSize: 20, maxWidth: '75%'},
                      ]}
                      placeholder={strings('sendCoinScreen.placehoder.addNote')}
                      placeholderTextColor={Colors.color30}
                    />
                  </View>
                </View>
                {Platform.OS == 'web' && (
                  <LongButton
                    text={strings('sendCoinScreen.button.tilte')}
                    onPress={onSendCoins}
                  />
                )}
              </View>
            </TouchableWithoutFeedback>
          </KeyboardAvoidingView>
        </MainContainer>
      )}
      {Platform.OS != 'web' && (
        <LongButton
          text={strings('sendCoinScreen.button.tilte')}
          onPress={onSendCoins}
        />
      )}
    </View>
  );
};

export default SendCoinScreen;
