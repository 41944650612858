import axios from 'axios';
import {connectActionSheet} from '@expo/react-native-action-sheet';
import React, {useState, useEffect} from 'react';
import {
  Alert,
  Image,
  Platform,
  TextInput,
  TouchableOpacity,
  View,
  Keyboard,
} from 'react-native';
import {
  AppText,
  CheckBox,
  DatePicker,
  DropDownInput,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../../components';
import {goBack, setValue} from '../../../utils';
import styles from './styles';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Colors} from '../../../constants';
import {strings} from '../../../i18n';
import * as ImagePicker from 'expo-image-picker';
import {
  getPromotionList,
  addPromotionInList,
  updatePromotionInList,
} from '../../../redux/reduxsauceActions/promotionActions';

const CreatePromotion = (props) => {
  const {navigation, route} = props;

  let initialState = {
    name: route?.params?.name || '',
    description: route?.params?.description || '',
    percentage_of: route?.params?.percentage_of || '',
    total_qty: route?.params?.total_qty || '',
    coupon_code: route?.params?.coupon_code || '',
    start_date: route?.params?.coupon_code || '',
    end_date: '',
    discount_upto_price: route?.params?.discount_upto_price || '0',
    max_count_per_user: route?.params?.max_count_per_user || 1,
    is_life_time: false,
    is_active: route?.params?.is_active || false,
    type:
      route?.params?.type == 'product-wise'
        ? 'Product'
        : route?.params?.type == 'fix-price'
        ? 'Price'
        : 'Discount',
  };
  const [images, setImages] = useState(
    route?.params?.image ? {uri: route?.params?.image} : null,
  );
  const [promotionData, setPromotionData] = useState(initialState);
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [datetype, setDateType] = useState('start_date');

  const openActionSheet = () => {
    const options = [
      strings('actionSheet.takePhoto'),
      strings('actionSheet.choosePhoto'),
      strings('actionSheet.removePhoto'),
      strings('actionSheet.cancel'),
    ];
    const cancelButtonIndex = 3;

    props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) openCamera();
        if (buttonIndex === 1) openImagePicker();
        if (buttonIndex === 2) removePhoto();
      },
    );
  };
  const removePhoto = () => {
    setImages(null);
  };

  const openCamera = async () => {
    await ImagePicker.requestCameraPermissionsAsync();

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      setImages(result);
    }
    if (!result.cancelled) {
    }
  };

  const openImagePicker = async () => {
    await ImagePicker.requestMediaLibraryPermissionsAsync();

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      setImages(result);
    }
    if (!result.cancelled) {
    }
  };

  const dataURLtoFile = (dataurl) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], 'image1.jpg', {type: mime});
  };

  const onSave = async () => {
    Keyboard.dismiss();
    console.log('data -->', promotionData);
    const {config, setLoading, openAlert, getPromotionList} = props;

    if (promotionData.name == '') {
      openAlert(strings('createOffers.alert.name'));
      return;
    }
    if (promotionData.type == '' && promotionData.type == null) {
      openAlert(strings('createOffers.alert.type'));
      return;
    }
    // if (promotionData.description == '') {
    //   openAlert(strings('createOffers.alert.descripation'));
    //   return;
    // }
    if (promotionData.percentage_of == '') {
      openAlert(strings('createOffers.alert.parcentge'));
      return;
    }
    if (promotionData.total_qty == '') {
      openAlert(strings('createOffers.alert.qty'));
      return;
    }
    if (promotionData.coupon_code == '') {
      openAlert(strings('createOffers.alert.cupone'));
      return;
    }

    let body = new FormData();
    body.append('business', config.businessId);

    body.append('name', promotionData.name);
    body.append('description', promotionData.description);
    body.append(
      'type',
      promotionData.type == 'Discount'
        ? 'discount-on-orders'
        : promotionData.type == 'Price'
        ? 'fix-price'
        : 'product-wise',
    );
    promotionData.discount_upto_price &&
      body.append(
        'discount_upto_price',
        parseInt(promotionData.discount_upto_price),
      );
    body.append('percentage_of', parseInt(promotionData.percentage_of));
    body.append('total_qty', parseInt(promotionData.total_qty));
    body.append('coupon_code', promotionData.coupon_code);
    // body.append('is_active', promotionData.is_active);

    promotionData.max_count_per_user &&
      body.append(
        'max_count_per_user',
        parseInt(promotionData.max_count_per_user),
      );

    if (images?.uri && images?.uri?.slice(0, 4) != 'http') {
      if (Platform.OS == 'web') {
        body.append('image', dataURLtoFile(images?.uri));
      } else {
        let blob = await (await fetch(images?.uri)).blob();
        body.append('image', {
          uri: images.uri,
          type: blob.type,
          name: blob.data.name,
        });
      }
    } else if (!images?.uri) {
      body.append('image', '');
    }

    console.log('body-->', body);
    if (route?.params?.id) {
      props.updatePromotionInList(body, route?.params?.id, navigation);
    } else {
      props.addPromotionInList(body, navigation);
    }
  };

  return (
    <View
      style={
        Platform.OS == 'web'
          ? {overflow: 'auto', height: '100vh'}
          : styles.container
      }
    >
      <MainHeader
        title={
          route?.params?.id
            ? strings('createOffers.title.edit')
            : strings('createOffers.title.add')
        }
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer>
        {/* Promotion Image */}
        <TouchableOpacity
          style={styles.imageContainer}
          onPress={openActionSheet}
        >
          {!images ? (
            <Icon name="add" size={16} color={Colors.black60} />
          ) : (
            <View>
              <Image source={{uri: images.uri}} style={styles.image} />
              <TouchableOpacity
                style={styles.cameraIcon}
                onPress={openActionSheet}
              >
                <Icon name="camera" color={Colors.black20} />
              </TouchableOpacity>
            </View>
          )}
        </TouchableOpacity>

        {/* Input fields */}
        <View>
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('createOffers.label.name')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              // autoCapitalize="words"
              autoCorrect={false}
              placeholder={strings('createOffers.placeholder.name')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
              value={promotionData.name}
              onChangeText={(text) =>
                setPromotionData({...promotionData, name: text})
              }
            />
          </View>

          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('createOffers.label.description')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              // autoCapitalize="words"
              autoCorrect={false}
              multiline={true}
              numberOfLines={3}
              placeholder={strings('createOffers.placeholder.description')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web'
                  ? styles.textInputWeb
                  : styles.multiLineTextInput
              }
              value={promotionData.description}
              onChangeText={(text) =>
                setPromotionData({...promotionData, description: text})
              }
            />
          </View>

          {/* <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>{strings('createOffers.placeholder.type')}</AppText> */}
          {/* <DropDownInput
              inputStyle={styles.textInput}
              iconStyle={styles.iconStyle}
              data={['Discount', 'Price', 'Product']}
              value={promotionData.type}
              onChangeText={text =>
                setPromotionData({...promotionData, type: text})
              }
            /> */}
          {/* <View style={styles.typePicker}>
              <Picker
                mode="dropdown"
                onValueChange={itemValue => {
                  setPromotionData({...promotionData, type: itemValue});
                }}
                style={{fontsize: setValue(16)}}
                selectedValue={promotionData.type}
              >
                <Picker.Item label="Discount" value="Discount" />
                <Picker.Item label="Price" value="Price" />
                <Picker.Item label="Product" value="Product" />
              </Picker>
            </View>
          </View> */}
          {promotionData.type == 'Discount' && (
            <View style={styles.textInputContainer}>
              <AppText style={styles.inputLabel}>
                {strings('createOffers.label.discount')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCorrect={false}
                keyboardType="number-pad"
                placeholder={strings('createOffers.placeholder.discount')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={
                  Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
                }
                value={promotionData.discount_upto_price}
                onChangeText={(text) =>
                  setPromotionData({
                    ...promotionData,
                    discount_upto_price: text,
                  })
                }
              />
            </View>
          )}
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('createOffers.label.percentage')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              keyboardType="number-pad"
              placeholder={strings('createOffers.placeholder.percentage')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
              value={promotionData.percentage_of}
              onChangeText={(text) =>
                setPromotionData({...promotionData, percentage_of: text})
              }
            />
          </View>

          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('createOffers.label.qty')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              keyboardType="number-pad"
              placeholder={strings('createOffers.label.qty')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
              value={promotionData.total_qty}
              onChangeText={(text) =>
                setPromotionData({...promotionData, total_qty: text})
              }
            />
          </View>

          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('createOffers.label.coupon')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              // autoCapitalize="words"
              autoCorrect={false}
              placeholder={strings('createOffers.placeholder.coupon')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
              value={promotionData.coupon_code}
              onChangeText={(text) =>
                setPromotionData({...promotionData, coupon_code: text})
              }
            />
          </View>

          {/* <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>{strings('createOffers.label.date')}</AppText>
            <View style={styles.calendarContainer}>
              <AppText style={styles.date}>
                {promotionData.start_date
                  ? promotionData.start_date
                  : strings('createOffers.placeholder.date')}
              </AppText>
              <TouchableOpacity
                onPress={() => {
                  setIsDateOpen(true);
                  setDateType('start_date');
                }}
              >
                <Icon name={'calendar'} size={22} color={Colors.black20} />
              </TouchableOpacity>
            </View>
          </View> */}

          {/* <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>{strings('createOffers.label.enddate')}</AppText>
            <View style={styles.calendarContainer}>
              <AppText style={styles.date}>
                {promotionData.end_date ? promotionData.end_date : strings('createOffers.placeholder.enddate')}
              </AppText>
              <TouchableOpacity
                onPress={() => {
                  setIsDateOpen(true);
                  setDateType('end_date');
                }}
              >
                <Icon name={'calendar'} size={22} color={Colors.black20} />
              </TouchableOpacity>
            </View>
          </View> */}

          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('createOffers.placeholder.limit')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              placeholder={'0'}
              keyboardType="numeric"
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
              value={promotionData.max_count_per_user.toString()}
              onChangeText={(text) =>
                setPromotionData({...promotionData, max_count_per_user: text})
              }
            />
          </View>

          {/* <TouchableOpacity
            style={[styles.isNewProduct, styles.textInputContainer]}
            onPress={() =>
              setPromotionData({
                ...promotionData,
                is_life_time: !promotionData.is_life_time,
              })
            }
          >
            <AppText
              style={{
                color: Colors.black60,
                fontSize: setValue(15),
              }}
            >
              {strings('createOffers.label.lifetime')}
            </AppText>
            <CheckBox
              size={30}
              checked={promotionData.is_life_time}
              onChange={checked =>
                setPromotionData({...promotionData, is_life_time: checked})
              }
            />
          </TouchableOpacity> */}

          {/* <TouchableOpacity
            style={[styles.isNewProduct, styles.textInputContainer]}
            onPress={() =>
              setPromotionData({
                ...promotionData,
                is_active: !promotionData.is_active,
              })
            }
          >
            <AppText
              style={{
                color: Colors.black60,
                fontSize: setValue(15),
              }}
            >
              {strings('createOffers.button.active')}
            </AppText>
            <CheckBox
              size={30}
              checked={promotionData.is_active}
              onChange={checked =>
                setPromotionData({...promotionData, is_active: checked})
              }
            />
          </TouchableOpacity> */}
        </View>

        {Platform.OS == 'web' && (
          <LongButton
            text={strings('createOffers.button.save')}
            style={{
              width: '100%',
              marginTop: setValue(10),
              marginBottom: setValue(45),
            }}
            onPress={onSave}
          />
        )}

        {/* <DatePicker
          visible={isDateOpen}
          onClose={() => setIsDateOpen(false)}
          onDayPress={date => {
            console.log('date--->', date, 'type -|> ', datetype);
            if (datetype == 'start_date') {
              setPromotionData({...promotionData, start_date: date.dateString});
            } else if (datetype == 'end_date') {
              setPromotionData({...promotionData, end_date: date.dateString});
            }
            setIsDateOpen(false);
          }}
          onCancelPress={
            () => setIsDateOpen(false)
            // this.onCheck();
          }
        /> */}
      </MainContainer>

      {Platform.OS !== 'web' && (
        <LongButton
          text={strings('createOffers.button.save')}
          style={{
            width: '100%',
            borderRadius: 0,
          }}
          onPress={onSave}
        />
      )}
    </View>
  );
};

const ConnectedCreatePromotion = connectActionSheet(CreatePromotion);

export default connect(
  (state) => ({
    user: state.auth.user,
    config: state.config,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getPromotionList,
        addPromotionInList,
        updatePromotionInList,
        setLoading: CommonActions.setLoading,
        openAlert: (content) =>
          CommonActions.setAlert({visible: true, content: content}),
      },
      dispatch,
    ),
)(ConnectedCreatePromotion);
