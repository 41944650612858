import axios from 'axios';
import CommonActions from '../reduxsauce/commonRedux';
import ProductActions from '../reduxsauce/productRedux';

export const getCategories = () => async (dispatch, getState) => {
  const {order, config} = getState();
  dispatch(CommonActions.setLoading(true));
  try {
    console.log('--categories----');
    const response = await axios
      .get('/products/product-category/list/' + config.businessId)
      .then((response) => response.data);
    console.log('checking response : ', response);
    if (response.error) {
      dispatch(
        CommonActions.setAlert({visible: true, content: response.error}),
      );
    } else {
      dispatch(
        ProductActions.getCategories({
          categories: response,
        }),
      );
    }
  } catch (error) {
    console.log('message', error?.response?.message);

    dispatch(
      CommonActions.setAlert({
        visible: true,
        content: error?.response?.message,
      }),
    );
  }
  dispatch(CommonActions.setLoading(false));
};

let cancelToken = axios.CancelToken.source();
export const getProducts =
  (search = false, category = false) =>
  async (dispatch, getState) => {
    const {
      auth: {user},
      order,
      config,
    } = getState();

    search || category
      ? dispatch(ProductActions.productSearchLoading(true))
      : dispatch(CommonActions.setLoading(true));

    let url =
      search && category
        ? '/v2/product/list/' +
          config.businessId +
          '?search=' +
          search +
          '&category=' +
          category
        : category
        ? '/v2/product/list/' + config.businessId + '?category=' + category
        : search
        ? '/v2/product/list/' + config.businessId + '?search=' + search
        : '/v2/product/list/' + config.businessId;

    if (cancelToken) {
      cancelToken.cancel();
      cancelToken = axios.CancelToken.source();
    }

    try {
      axios
        .get(url, {cancelToken: cancelToken.token})
        .then((response) => {
          console.log('response======================', response);
          dispatch(
            ProductActions.getProducts({
              fetching: false,
              products: response.data,
            }),
          );
          dispatch(ProductActions.productSearchLoading(false));
          dispatch(CommonActions.setLoading(false));
          return response.data;
        })
        .catch((error) => {
          console.log('error', error);
          // dispatch(
          //   CommonActions.setAlert({
          //     visible: true,
          //     content: error?.response?.message,
          //   }),
          // );
          dispatch(ProductActions.productSearchLoading(false));
          dispatch(CommonActions.setLoading(false));
        });
    } catch ({message}) {
      dispatch(CommonActions.setAlert({visible: true, content: message}));
      dispatch(ProductActions.productSearchLoading(false));
      dispatch(CommonActions.setLoading(false));
    }
  };
let cancelToken1 = axios.CancelToken.source();
export const getProductsV1 =
  (item, callback, loading = false) =>
  async (dispatch, getState) => {
    const {
      auth: {user},
      order,
      config,
      products,
    } = getState();

    item.search || item.category || item.subCategory
      ? await dispatch(ProductActions.productSearchLoading(loading))
      : await dispatch(CommonActions.setLoading(loading));

    let url = '/products/list/' + config['businessId'];
    if (item.limit) {
      if (url.includes('?')) {
        url += '&limit=' + item.limit;
      } else {
        url += '?limit=' + item.limit;
      }
    }
    if (item.offset) {
      if (url.includes('?')) {
        url += '&offset=' + item.offset;
      } else {
        url += '?offset=' + item.offset;
      }
    }
    if (item.search) {
      if (url.includes('?')) {
        url += '&search=' + item.search;
      } else {
        url += '?search=' + item.search;
      }
    }
    if (item.category) {
      if (url.includes('?')) {
        url += '&category=' + item.category;
      } else {
        url += '?category=' + item.category;
      }
    }
    if (item.subCategory) {
      if (url.includes('?')) {
        url += '&sub_category=' + item.subCategory;
      } else {
        url += '?sub_category=' + item.subCategory;
      }
    }
    if (item.maxPrice && item.maxPrice != '') {
      if (url.includes('?')) {
        url += '&max_price=' + item.maxPrice;
      } else {
        url += '?max_price=' + item.maxPrice;
      }
    }
    if (item.minPrice && item.minPrice != '') {
      if (url.includes('?')) {
        url += '&min_price=' + item.minPrice;
      } else {
        url += '?min_price=' + item.minPrice;
      }
    }
    if (item.sortBy && item.sortBy != '') {
      if (url.includes('?')) {
        url += '&sort_by=' + item.sortBy;
      } else {
        url += '?sort_by=' + item.sortBy;
      }
    }
    if (item.variants && item.variants.length > 0) {
      if (url.includes('?')) {
        url += '&variants=' + item.variants.toString();
      } else {
        url += '?variants=' + item.variants.toString();
      }
    }

    if (cancelToken1) {
      cancelToken1.cancel();
      cancelToken1 = axios.CancelToken.source();
    }

    try {
      await axios
        .get(url, {cancelToken: cancelToken1.token})
        .then(async (response) => {
          console.log('response======================', response);
          await dispatch(
            ProductActions.getProducts({
              fetching: false,
              products:
                item['offset'] == 0
                  ? [...response['data']['results']]
                  : [...products.products, ...response['data']['results']],
            }),
          );

          await dispatch(ProductActions.productSearchLoading(false));
          await dispatch(CommonActions.setLoading(false));
          callback && callback('success', response.data);
          return response.data;
        })
        .catch(async (error) => {
          console.log('error', error);
          // dispatch(
          //   CommonActions.setAlert({
          //     visible: true,
          //     content: error?.response?.message,
          //   }),
          // );
          await dispatch(ProductActions.productSearchLoading(false));
          await dispatch(CommonActions.setLoading(false));
        });
    } catch ({message}) {
      dispatch(CommonActions.setAlert({visible: true, content: message}));
      dispatch(ProductActions.productSearchLoading(false));
      dispatch(CommonActions.setLoading(false));
    }
  };

  export const getProductsV2 =
  (item, callback, loading = false) =>
  async (dispatch, getState) => {
    const {
      auth: {user},
      order,
      config,
      products,
    } = getState();

    item.search || item.category || item.subCategory
      ? await dispatch(ProductActions.productSearchLoading(loading))
      : await dispatch(CommonActions.setLoading(loading));

    let url = '/v2/product/list/' + config['businessId'];
    if (item.limit) {
      if (url.includes('?')) {
        url += '&limit=' + item.limit;
      } else {
        url += '?limit=' + item.limit;
      }
    }
    if (item.offset) {
      if (url.includes('?')) {
        url += '&offset=' + item.offset;
      } else {
        url += '?offset=' + item.offset;
      }
    }
    if (item.search) {
      if (url.includes('?')) {
        url += '&search=' + item.search;
      } else {
        url += '?search=' + item.search;
      }
    }
    if (item.category) {
      if (url.includes('?')) {
        url += '&category=' + item.category;
      } else {
        url += '?category=' + item.category;
      }
    }
    if (item.subCategory) {
      if (url.includes('?')) {
        url += '&sub_category=' + item.subCategory;
      } else {
        url += '?sub_category=' + item.subCategory;
      }
    }
    if (item.maxPrice && item.maxPrice != '') {
      if (url.includes('?')) {
        url += '&max_price=' + item.maxPrice;
      } else {
        url += '?max_price=' + item.maxPrice;
      }
    }
    if (item.minPrice && item.minPrice != '') {
      if (url.includes('?')) {
        url += '&min_price=' + item.minPrice;
      } else {
        url += '?min_price=' + item.minPrice;
      }
    }
    if (item.sortBy && item.sortBy != '') {
      if (url.includes('?')) {
        url += '&sort_by=' + item.sortBy;
      } else {
        url += '?sort_by=' + item.sortBy;
      }
    }
    if (item.variants && item.variants.length > 0) {
      if (url.includes('?')) {
        url += '&variants=' + item.variants.toString();
      } else {
        url += '?variants=' + item.variants.toString();
      }
    }
    if (item.food_type && item.food_type.length > 0) {
      if (url.includes('?')) {
        url += '&food_type=' + item.food_type.toString();
      } else {
        url += '?food_type=' + item.food_type.toString();
      }
    }

    if (cancelToken1) {
      cancelToken1.cancel();
      cancelToken1 = axios.CancelToken.source();
    }

    try {
      await axios
        .get(url, {cancelToken: cancelToken1.token})
        .then(async (response) => {
          console.log('response======================', response);
          await dispatch(
            ProductActions.getProducts({
              fetching: false,
              products:
                item['offset'] == 0
                  ? [...response['data']['results']]
                  : [...products.products, ...response['data']['results']],
            }),
          );

          await dispatch(ProductActions.productSearchLoading(false));
          await dispatch(CommonActions.setLoading(false));
          callback && callback('success', response.data);
          return response.data;
        })
        .catch(async (error) => {
          console.log('error', error);
          // dispatch(
          //   CommonActions.setAlert({
          //     visible: true,
          //     content: error?.response?.message,
          //   }),
          // );
          await dispatch(ProductActions.productSearchLoading(false));
          await dispatch(CommonActions.setLoading(false));
        });
    } catch ({message}) {
      dispatch(CommonActions.setAlert({visible: true, content: message}));
      dispatch(ProductActions.productSearchLoading(false));
      dispatch(CommonActions.setLoading(false));
    }
  };

export const getProductsFilter = (callback) => async (dispatch, getState) => {
  const {
    auth: {user},
    order,
    config,
    products,
  } = getState();

  let url = '/products/filter/option/' + config['businessId'];

  try {
    await axios
      .get(url)
      .then((response) => {
        console.log('getProductsFilter--------->', response);
        callback && callback('success', response.data);
        return response.data;
      })
      .catch((error) => {
        console.log('error-------->', error);
      });
  } catch (error) {
    console.log('error-------->', error);
  }
};
