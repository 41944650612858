import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {
  ActivityIndicator,
  Text,
  View,
  FlatList,
  Platform,
  TouchableOpacity,
  TextInput,
  Keyboard,
  Image,
  TouchableNativeFeedback,
  TouchableWithoutFeedback,
  ScrollView,
} from 'react-native';
import {Colors} from '../../constants';
import {AppText, CheckBox, LongButton, Modal} from '..';
import styles from './styles';

import {setValue} from '../../utils';
import {get} from 'lodash';
import Constants from 'expo-constants';
import {strings} from '../../i18n';
import {MaterialCommunityIcons} from '@expo/vector-icons';
import {useDispatch, useSelector} from 'react-redux';
import CommonActions from '../../redux/reduxsauce/commonRedux';

const ENV = Constants.manifest.extra.ENV;

export default function DeliveryPartnerModal(props) {
  const {isModalVisible, orderID} = props;

  const config = useSelector((state) => state?.config);

  const [deliveryType, setDeliveryType] = useState('self');
  const [errorMessage, setErrorMessage] = useState(null);
  const [selfDeliveryInfo, setSelfDeliveryInfo] = useState({
    delivery_partner: '',
    tracking_id: props.trackingId,
  });

  const [deliveryPartnerList, setDeliveryPartnerList] = useState([]);

  const resetState = () => {
    setDeliveryType('self');
    setSelfDeliveryInfo({
      delivery_partner: '',
      tracking_id: '',
    });
  };

  const dispatch = useDispatch();

  const getPartnerList = () => {
    axios
      .get('/delivery/deliverypartnerconfig/list')
      .then((response) => {
        console.log('delivery partner list --', response);
        setDeliveryPartnerList(response?.data);
      })
      .catch((error) => {
        console.log('error delivery partner list --', error);
      });
  };

  useEffect(() => {
    getPartnerList();
  }, []);

  return (
    <Modal
      visible={isModalVisible}
      transparent
      onRequestClose={() => {
        resetState();
        props.onClose();
      }}
    >
      <TouchableOpacity
        style={{flex: 1}}
        activeOpacity={1}
        onPressOut={() => {
          resetState();
          props.onClose();
        }}
      >
        <View style={styles.overlay}>
          <View style={styles.mainContainer}>
            <Text style={styles.headerTitle}>
              {strings('deliveryPartner.title')}
            </Text>

            <View>
              <View
                style={[
                  styles.selectBox,
                  {
                    padding: 15,
                    flexDirection: 'column',
                    alignItems: 'stretch',
                  },
                ]}
              >
                <TouchableOpacity
                  style={styles.selfCheckbox}
                  onPress={() => setDeliveryType('self')}
                >
                  <CheckBox
                    checked={deliveryType == 'self'}
                    onChange={(checked) => {
                      checked && setDeliveryType('self');
                    }}
                  />
                  <AppText
                    style={{
                      fontSize: setValue(20),
                      fontFamily: 'Roboto-Bold',
                      marginLeft: setValue(15),
                    }}
                  >
                    SELF
                  </AppText>
                </TouchableOpacity>
                {deliveryType == 'self' && (
                  <TouchableWithoutFeedback>
                    <View style={{paddingTop: 10}}>
                      <View style={{marginBottom: setValue(10)}}>
                        <Text style={styles.inputLabel}>
                          {'Delivery Partner'}
                        </Text>
                        <View style={styles.scanBarcodeContainer}>
                          <TextInput
                            underlineColorAndroid="transparent"
                            autoCorrect={false}
                            placeholder={'Delivery partner name'}
                            placeholderTextColor={Colors.color30}
                            returnKeyType="done"
                            style={
                              Platform.OS == 'web'
                                ? [styles.textInputWeb, {width: '100%'}]
                                : [styles.textInput, {width: '100%'}]
                            }
                            value={selfDeliveryInfo?.delivery_partner}
                            onChangeText={(text) =>
                              setSelfDeliveryInfo({
                                ...selfDeliveryInfo,
                                delivery_partner: text,
                              })
                            }
                          />
                        </View>
                      </View>

                      <View>
                        <Text style={styles.inputLabel}>{'Tracking Id'}</Text>
                        <View style={styles.scanBarcodeContainer}>
                          <TextInput
                            underlineColorAndroid="transparent"
                            autoCorrect={false}
                            placeholder={'Tracking id'}
                            placeholderTextColor={Colors.color30}
                            returnKeyType="done"
                            style={
                              Platform.OS == 'web'
                                ? [styles.textInputWeb, {width: '100%'}]
                                : [styles.textInput, {width: '80%'}]
                            }
                            value={selfDeliveryInfo?.tracking_id}
                            onChangeText={(text) =>
                              setSelfDeliveryInfo({
                                ...selfDeliveryInfo,
                                tracking_id: text,
                              })
                            }
                          />
                          {Platform.OS != 'web' && (
                            <View style={styles.scanButtonContainer}>
                              <TouchableOpacity
                                style={styles.scanButton}
                                onPress={() => props.scanBarcode()}
                              >
                                <MaterialCommunityIcons
                                  name="qrcode-scan"
                                  size={24}
                                  color={Colors.white}
                                />
                              </TouchableOpacity>
                            </View>
                          )}
                        </View>
                      </View>
                    </View>
                  </TouchableWithoutFeedback>
                )}
              </View>

              {deliveryPartnerList.length > 0 &&
                deliveryPartnerList?.map((o, i) => {
                  return (
                    <TouchableOpacity
                      key={i}
                      style={styles.selectBox}
                      onPress={() => {
                        setDeliveryType(o.id);
                        setSelfDeliveryInfo({
                          delivery_partner: '',
                          tracking_id: '',
                        });
                        setErrorMessage(null);
                      }}
                    >
                      <CheckBox
                        checked={o.id === deliveryType}
                        onChange={(checked) => {
                          if (checked) {
                            setDeliveryType(o.id);
                            setSelfDeliveryInfo({
                              delivery_partner: '',
                              tracking_id: '',
                            });
                            setErrorMessage(null);
                          }
                        }}
                      />
                      <View style={{marginLeft: setValue(15)}}>
                        <Image
                          source={
                            o.type === 'nimbuspost'
                              ? require('../../../assets/images/General/nimbuspost.jpg')
                              : require('../../../assets/images/General/shiprocket.jpg')
                          }
                          style={{
                            width: setValue(100),
                            height: setValue(40),
                          }}
                          resizeMode={
                            o.type == 'nimbuspost' ? 'contain' : 'cover'
                          }
                        />
                        <AppText style={{marginTop: 5, color: Colors.color30}}>
                          {o.username}
                        </AppText>
                      </View>
                    </TouchableOpacity>
                  );
                })}
            </View>
            <View>
              {errorMessage && (
                <AppText style={{color: Colors.wallet_card_red}}>
                  {errorMessage}
                </AppText>
              )}
            </View>

            <LongButton
              style={{
                marginTop: setValue(20),
              }}
              text={strings('deliveryPartner.button.startShipping')}
              onPress={() => {
                if (deliveryType == 'self') {
                  if (
                    !selfDeliveryInfo.tracking_id &&
                    !selfDeliveryInfo.delivery_partner
                  ) {
                    props.updateOrder();
                  } else {
                    if (
                      selfDeliveryInfo?.tracking_id &&
                      !selfDeliveryInfo?.delivery_partner
                    ) {
                      setErrorMessage(
                        'Delivery partner field must required with tracking id',
                      );
                      return;
                    } else if (
                      !selfDeliveryInfo?.tracking_id &&
                      selfDeliveryInfo?.delivery_partner
                    ) {
                      setErrorMessage(
                        'Tracking id field must required with delivery partner',
                      );
                      return;
                    } else {
                      props.updateOrder(selfDeliveryInfo);
                      resetState();
                    }
                  }
                } else {
                  let obj = deliveryPartnerList.find(
                    (o) => o.id === deliveryType,
                  );
                  if (obj.type === 'nimbuspost') {
                    props.onlyClose();
                    props.navigation.navigate('nimbus-order-create', {
                      orderId: orderID,
                      partnerId: obj.id,
                    });
                  } else if (obj.type == 'shiprocket') {
                    props.onlyClose();
                    props.navigation.navigate('ship-rocket-order-create', {
                      orderId: orderID,
                      partnerId: obj.id,
                    });
                  }
                }
              }}
            />
          </View>
        </View>
      </TouchableOpacity>
    </Modal>
  );
}
