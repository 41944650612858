import {AntDesign} from '@expo/vector-icons';
import React, {useEffect, useState} from 'react';
import {
  ActivityIndicator,
  FlatList,
  Platform,
  TouchableOpacity,
  View,
} from 'react-native';
import {useDispatch} from 'react-redux';
import {
  AppText,
  MainContainer,
  MainHeader,
  NoRecordFound,
} from '../../components';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import {dateTimeFormat, goBack, setValue} from '../../utils';
import styles from './styles';
import CommonAction from '../../redux/reduxsauce/commonRedux';
import axios from 'axios';
import {useIsFocused} from '@react-navigation/native';

const MerchantCoinTransactionList = (props) => {
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [transactionList, setTransactionList] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const limit = 10;
  const isFocused = useIsFocused();
  const dispatch = useDispatch();

  let onEndReachedCalledDuringMomentum = {
    active: true,
    completed: true,
  };

  useEffect(() => {
    console.log('offset --', offset);
    offset != null && offset !== undefined && getTransactionList();
  }, [offset]);

  useEffect(() => {
    isFocused && offset != null && offset !== undefined && getTransactionList();
  }, [isFocused]);

  const getTransactionList = async () => {
    setLoading(true);
    try {
      let url = '/coin/transactions/merchant/list?limit=' + limit;
      if (offset >= 0) {
        url += '&offset=' + offset;
      }

      const res = await axios.get(url);
      console.log('res -- -->', res);
      if (offset > 0) {
        let arr = [...transactionList];
        arr = arr.concat(res.data.results);
        console.log('arr  -', arr);
        setTransactionList(arr);
        setTotalCount(res?.data?.count);
      } else {
        setTransactionList(res?.data?.results);
        setTotalCount(res?.data?.count);
      }
      setLoading(false);
    } catch (error) {
      console.log('error -->', error);
      setLoading(false);
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: error?.response?.message || 'Something went wrong :(',
        }),
      );
    }
  };

  console.log('transactionList -->', transactionList);

  return (
    <View style={styles.container}>
      <MainHeader
        title={'Coin Transaction List'}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer useView>
        {/* qr code Scanner */}
        {Platform.OS != 'web' && (
          <TouchableOpacity
            style={styles.qrCodeScanner}
            onPress={() =>
              props.navigation.navigate('scan-customers-qr', {
                navigate: 'send-coins',
              })
            }
          >
            <AntDesign name="qrcode" size={30} color={Colors.white} />
          </TouchableOpacity>
        )}

        {offset == 0 && loading ? (
          <View
            style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}
          >
            <ActivityIndicator size="large" color={Colors.theme_color} />
          </View>
        ) : (
          <View useView style={{flex: 1}}>
            {transactionList?.length == 0 ? (
              <NoRecordFound
                title="Transactions"
                message="We'll notify you when there is something now!"
                hideImage={true}
              />
            ) : (
              <View
                style={{
                  paddingTop: 10,
                  flex: 1,
                }}
              >
                <FlatList
                  data={transactionList}
                  renderItem={({item}) => (
                    <View style={styles.transactionItem}>
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginBottom: setValue(5),
                        }}
                      >
                        <AppText style={styles.coin}>{item?.coin}</AppText>
                        <AppText>{dateTimeFormat(item?.created_at)}</AppText>
                      </View>
                      <AppText style={styles.userName}>
                        {item?.user?.name}
                      </AppText>
                      {item?.remark?.length > 0 && (
                        <AppText style={{marginTop: setValue(3)}}>
                          {item?.remark}
                        </AppText>
                      )}
                    </View>
                  )}
                  refreshing={refreshing}
                  onRefresh={() => {
                    setOffset(0);
                    offset == 0 && getTransactionList();
                  }}
                  keyboardShouldPersistTaps="always"
                  showsVerticalScrollIndicator={false}
                  showsHorizontalScrollIndicator={false}
                  keyExtractor={(item) => item.id}
                  onEndReached={() => {
                    if (transactionList.length < 7) {
                    }
                    transactionList.length > limit - 1 &&
                      totalCount != transactionList.length &&
                      setOffset(transactionList?.length);
                  }}
                  onEndReachedThreshold={0.5}
                  onMomentumScrollBegin={() => {
                    onEndReachedCalledDuringMomentum.active = false;
                  }}
                  ListFooterComponent={() => (
                    <View style={{paddingBottom: 35}}>
                      {loading && (
                        <ActivityIndicator size="large" color="#000" />
                      )}
                    </View>
                  )}
                />
              </View>
            )}
          </View>
        )}
      </MainContainer>
    </View>
  );
};

export default MerchantCoinTransactionList;
