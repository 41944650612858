import {Dimensions, Platform} from 'react-native';

// import { iphoneMaxHeight, iphoneMaxWidth } from '../constants/Layout';

const {width, height} = Dimensions.get('window');

export const screenWidth = width;

export const screenHeight = height;

export const setYAxisValue = value => {
  const ratio = screenHeight / screenHeight;
  return ratio * value;
};

export const setValue = value => {
  const ratio = (screenHeight * screenWidth) / (screenHeight * screenWidth);
  return ratio * value;
};

export const setXAxisValue = value => {
  const ratio = screenWidth / screenWidth;
  return ratio * value;
};
