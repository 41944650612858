import {StyleSheet} from 'react-native';
import { Colors } from '../../constants';
import { setValue } from '../../utils';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  textInputContainer: {
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 5,
    borderRadius: 5,
    marginBottom: setValue(10),
  },
  inputLabel: {
    // color: Colors.black,
    // fontSize: setValusColors(17),
    // paddingBottosColors: setValue(7),
    marginLeft: setValue(10),
    fontSize: setValue(12),
    fontFamily: 'Roboto-Bold',
    color: Colors.black20,
    marginTop: setValue(5),
  },
  textInput: {
    // height: setValue(50),
    // padding: 0,
    // flex: 1,
    paddingHorizontal: setValue(10),
    paddingBottom: setValue(10)
  },
  textInputWeb: {
    height: setValue(50),
    padding: setValue(5),
    paddingHorizontal: setValue(10),
  },
});
