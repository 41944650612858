import {Platform, StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {screenWidth, setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 5,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  itemContainer: {
    padding: setValue(10),
    backgroundColor: Colors.white,
    borderRadius: setValue(5),
    marginVertical: 5,
    ...shadow,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textInput: {
    borderRadius: setValue(5),
    height: setValue(50),
    flex: 1,
    paddingHorizontal: setXAxisValue(15),
    marginBottom: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    backgroundColor: Colors.white,
    ...shadow,
  },
  textInputContainer: {
    height: setValue(60),
  },

  inputRightSide: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    right: setValue(15),
    alignSelf: 'center',
    top: setValue(12),
    elevation: 10,
  },

  inputLabel: {
    fontSize: setXAxisValue(12),
    fontFamily: 'Roboto-Bold',
    color: Colors.black20,
  },
});
