import {AntDesign} from '@expo/vector-icons';
import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import AppText from '../AppText';
import moment from 'moment';
import styles from './styles';
import {useSelector} from 'react-redux';
import {get} from 'lodash';
import {Colors} from '../../constants';

export default function CancellationOrderItem(props) {
  const {item} = props;

  const config = useSelector((state) => state.config);
  let currencySymbol = get(config, 'currencySymbol', '₹');

  return (
    <TouchableOpacity
      key={item.id}
      style={styles.orderItemContainer}
      onPress={() =>
        props.navigation.navigate('order-history-detail', {
          id: item?.order?.id,
        })
      }
    >
      <View style={styles.topContainer}>
        <AppText style={styles.orderUId}>{'ORD00' + item?.order?.id}</AppText>
        <AppText>
          <AppText style={styles.orderUId}>
            {moment(item?.order?.created_at).format('DD MMM YYYY hh:mm A')}
          </AppText>
        </AppText>
      </View>
      <View style={styles.bottomContainer}>
        <AppText style={styles.orderPrice}>
          {`${currencySymbol} ${item?.order?.total}`}
        </AppText>
        <AppText
          style={
            item?.status === 'requested'
              ? [styles.orderPrice, {color: Colors.wallet_card_red}]
              : styles.orderPrice
          }
        >
          {item?.status?.toUpperCase()}
        </AppText>
      </View>
    </TouchableOpacity>
  );
}
