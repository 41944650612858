import React, {useState} from 'react';
import {
  TouchableOpacity,
  Image,
  View,
  Text,
  ActivityIndicator,
} from 'react-native';
import {strings} from '../../i18n';
import CommonActions from '.././../redux/reduxsauce/commonRedux';
import AppText from '../AppText';
import styles from './styles';
import Icon from '../Icon';
import {Colors} from '../../constants';
import {setValue} from '../../utils';
import {useDispatch, useSelector} from 'react-redux';
import Constants from 'expo-constants';
import {Fontisto, Feather} from '@expo/vector-icons';
import get from 'lodash.get';
import ProgressiveImage from '../ProgressiveImage';
import {getSupportInfo} from 'prettier';
import axios from 'axios';

export default function ProductItemGird(props) {
  const {item, index} = props;
  const [imageHeight, setImageHeight] = useState(0);
  const config = useSelector((state) => state.config);
  let currencySymbol = get(config, 'currencySymbol', '₹');

  let ratio = config?.product?.aspectRatio
    ? config?.product?.aspectRatio[0] / config?.product?.aspectRatio[1]
    : 4 / 3;

  return (
    <TouchableOpacity
      onPress={props.onPressProductItem}
      style={
        index % 2 == 0
          ? [styles.productContainer, {marginRight: setValue(3)}]
          : [styles.productContainer, {marginLeft: setValue(3)}]
      }
      keyExtractor={item.id}
    >
      {/* <Image
        source={{uri: item?.images[0]?.photo}}
        onLayout={event => {
          const {height} = event.nativeEvent.layout;
          setImageHeight(height);
        }}
        style={[styles.productImage, {opacity: item.stock_qty == 0 ? 0.5 : 1}]}
      /> */}
      {!item.always_available_stock && parseFloat(item?.stock_qty) == 0 ? (
        <View
          style={[
            styles.outOfStock,
            {top: imageHeight ? imageHeight / 2.2 : 0, alignSelf: 'center'},
          ]}
        >
          <Text
            style={{
              color: Colors.white,
              padding: setValue(3),
              fontWeight: 'bold',
              fontSize: setValue(12),
            }}
          >
            {'OUT OF STOCK'}
          </Text>
        </View>
      ) : null}
      <ProgressiveImage
        thumbnailSource={
          item?.images[0]?.photo
            ? {uri: item?.images[0]?.photo}
            : require('../../../assets/images/General/no-img-found.jpg')
        }
        source={
          item?.images[0]?.photo
            ? {uri: item?.images[0]?.photo}
            : require('../../../assets/images/General/no-img-found.jpg')
        }
        style={[
          styles.productImage,
          {
            aspectRatio: ratio,
            opacity:
              !item.always_available_stock && parseFloat(item?.stock_qty) == 0
                ? 0.5
                : 1,
          },
        ]}
        onLayout={(event) => {
          const {height} = event.nativeEvent.layout;
          setImageHeight(height);
        }}
        resizeMode="cover"
      />
      <AppText style={styles.productName} numberOfLines={1}>
        {item.name}
      </AppText>
      <View style={{flexDirection: 'row', paddingHorizontal: 10}}>
        {config.product?.hidePrice ? (
          <AppText></AppText>
        ) : (
          <AppText style={styles.productPrice}>
            {`${currencySymbol} ${item.price} ${' '}`}
            {item.discount && item.discount != 0 ? (
              <AppText style={styles.discountText}>
                {`${currencySymbol} ${
                  parseInt(item.price) +
                  (parseInt(item.price) * parseInt(item.discount)) / 100
                }`}
              </AppText>
            ) : null}
          </AppText>
        )}
        {/* {item.newItem && (
            <Image
              style={styles.productTypeIcon}
              source={{
                uri: `${APIUtils.ApiUrl}/${props.productAdditionImages.newItem}`,
              }}
            />
          )}
          {item.chefChoice && props.productAdditionImages ? (
            <Image
              style={styles.productTypeIcon}
              source={{
                uri: `${APIUtils.ApiUrl}/${props.productAdditionImages.chefChoice}`,
              }}
            />
          ) : null}
          {categoryImage && (
            <Image
              style={styles.productTypeIcon}
              source={{uri: `${APIUtils.ApiUrl}/${categoryImage}`}}
            />
          )}
          {item.productOptions && (
            <Icon name="filter" style={styles.productTypeIcon} />
          )} */}
      </View>
      <TouchableOpacity
        onPress={() => props.navigation.navigate('edit-product', {item: item})}
        style={[
          styles.productAddButton,
          {top: imageHeight ? imageHeight - 18 : 0},
        ]}
      >
        <Feather name="edit" size={16} color={Colors.white} />
      </TouchableOpacity>
      {config.type == 'restaurants' ? (
        item.food_type == 'veg' ? (
          <View style={[styles.FoodType, {borderColor: Colors.green}]}>
            <Fontisto name="record" size={10} color={Colors.green} />
          </View>
        ) : (
          item.food_type == 'non-veg' && (
            <View style={[styles.FoodType, {borderColor: 'red'}]}>
              <Fontisto
                name="record"
                size={10}
                color={Colors.wallet_card_red}
              />
            </View>
          )
        )
      ) : null}
    </TouchableOpacity>
  );
}
