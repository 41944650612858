import {get} from 'lodash';
import React from 'react';
import {View, TouchableOpacity, Image, Text, ToastAndroid} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {AppText} from '../../components';
import {strings} from '../../i18n';
import {dateFormat, setValue} from '../../utils';
import ProgressiveImage from '../ProgressiveImage';
import styles from './styles';
import * as Clipboard from 'expo-clipboard';
import CommonActions from '../../redux/reduxsauce/commonRedux';

const PromotionItem = (props) => {
  const {navigation, fetching, item} = props;
  const config = useSelector((state) => state.config);
  let currencySymbol = get(config, 'currencySymbol', '₹');

  const dispatch = useDispatch();

  return (
    <TouchableOpacity
      style={styles.itemContainer}
      onPress={() => {
        // if (!fetching) {
        // this.props.getPromotionDetail(item?.Id, navigation);
        navigation.navigate('offer-detail', {
          item,
        });
      }}
    >
      {/* <Image source={{uri: `${item.image}`}} style={styles.promotionImage} /> */}
      <ProgressiveImage
        thumbnailSource={{uri: `${item.image}`}}
        source={{uri: `${item.image}`}}
        style={styles.promotionImage}
        resizeMode="cover"
      />
      <View style={{padding: setValue(10)}}>
        <View style={{paddingRight: 20}}>
          <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
            <AppText style={styles.title}>{item.name}</AppText>
          </View>
        </View>
        <AppText style={styles.desc}>{item.description}</AppText>
        <TouchableOpacity
          style={styles.couponCodeContainer}
          onPress={() => {
            Clipboard.setString(item.coupon_code);
            dispatch(
              CommonActions.setShowToast({
                visible: true,
                content: 'Copied to clipboard',
              }),
            );
          }}
        >
          <Text style={styles.couponCodeText}>{item.coupon_code}</Text>
          <Text style={styles.copyText}>{'COPY'}</Text>
        </TouchableOpacity>
        <View style={styles.promotionOffer}>
          <Text style={styles.offerText}>
            {parseFloat(item.percentage_of) + '% OFF'}
          </Text>
        </View>
      </View>
    </TouchableOpacity>

    // <TouchableOpacity
    //   {...props}
    //   style={[styles.itemContainer]}
    //   onPress={() => {
    //     // if (!fetching) {
    //     // this.props.getPromotionDetail(item?.Id, navigation);
    //     navigation.navigate('promotion-detail', {
    //       item,
    //     });
    //     // }
    //   }}
    // >
    //   <Image
    //     source={{
    //       uri: `${item?.image}`,
    //     }}
    //     resizeMode="cover"
    //     style={styles.promotionImage}
    //   />
    //   <View style={{flexDirection: 'row'}}>
    //     <AppText style={styles.title}>{item?.name}</AppText>
    //     {item?.isNew ||
    //       (true && (
    //         <Image
    //           style={styles.iconNewPromotion}
    //           source={require('../../../assets/images/General/newPromo.png')}
    //         />
    //       ))}
    //   </View>
    //   <AppText style={styles.description}>{item?.description}</AppText>
    //   <View style={styles.infoBar}>
    //     {false && item && (
    //       <View style={styles.moneyContainer}>
    //         <View style={styles.discountView}>
    //           <AppText style={styles.currencySymbolText}>
    //             {currencySymbol}
    //           </AppText>
    //           <AppText style={styles.discountText}>
    //             {currencySymbol}
    //             {item?.price}
    //           </AppText>
    //         </View>
    //         <AppText style={styles.currentPriceText}>{'100.00'}</AppText>
    //       </View>
    //     )}
    //   </View>
    // </TouchableOpacity>
  );
};

export default PromotionItem;
