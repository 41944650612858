import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

const StripeProviderComp = ({children}) => {
  const config = useSelector((state) => state.config);
  const [loading, setLoading] = useState(true);

  return <>{children}</>;
};

export const StripeContext = React.createContext({
  sendPaymentByStripe: (payment, navigateScreen, navigation) => {
    navigation.replace('stripe-pay', {
      payment: payment,
      navigationScreen: navigateScreen,
    });
  },
});

export default StripeProviderComp;
