import axios from 'axios';
import get from 'lodash.get';
import moment from 'moment';
import React, {Component} from 'react';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Linking,
  Platform,
  SafeAreaView,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import {connect} from 'react-redux';
import {
  AppText,
  DeliveryPartnerModal,
  Icon,
  MainContainer,
  MainHeader,
  Modal,
  QRCode,
} from '../../components';
import {strings} from '../../i18n';
import {
  cashFormat,
  getParam,
  goBack,
  screenHeight,
  screenWidth,
  setValue,
  setXAxisValue,
} from '../../utils';
import styles from './styles';
import {Colors, Layout} from '../../constants';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {bindActionCreators} from 'redux';
import * as Location from 'expo-location';
import MapView from 'react-native-maps';
import MapViewDirections from 'react-native-maps-directions';
import * as Print from 'expo-print';
import Constants from 'expo-constants';

const GOOGLE_API_KEY = 'AIzaSyAW-2c6qBkLKg78SLFGqqj_xqKkJCCR_OA';
class OrderHistoryDetailScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: getParam(props.route, 'id', null),
      orderProductDetail: [],
      serviceCharge: 0,
      showModal: false,
      loading: false,
      status: '',
      showShippingModal: false,
      statusMessage: '',
      errorMessage: '',
      basicOrderDetail: null,
      orderStatusHistory: [],
      isLocationLoading: false,
      locationPermission: null,
      deliveryPartner: null,
      selectedPrinter: null,
      declineCancellation: false,
      selfTrackingId: '',
      coordinates: [
        {
          latitude: null,
          longitude: null,
        },
        {
          latitude: null,
          longitude: null,
        },
      ],
    };
    this.currencySymbol = get(props?.config, 'currencySymbol', '₹');
  }

  print = async () => {
    const {basicOrderDetail} = this.state;

    const getOrderList = () => {
      let newdata = ``;
      let data = this.state.orderProductDetail;
      for (let i = 0; i < data?.length; i++) {
        newdata += `<tr>
        <td style="text-transform: capitalize;width: 40%;">${
          data[i]?.product.name
        } ${
          // (data[i]?.selected_variants
          // ? ' (' +
          //   data[i]?.selected_variants?.map((o) => o.value).join(', ') +
          //   ')'
          // : '')
          ''
        }</td>
        <td style="text-transform: capitalize;text-align: center;width: 20%;">${parseInt(
          data[i]?.qty,
        )}</td>
        <td style="text-transform: capitalize;text-align: center;width: 20%;">${
          this.currencySymbol
        }${
          data[i]?.final_price
            ? parseInt(data[i]?.final_price)
            : parseInt(data[i]?.product.price)
        }</td>
        <td style="text-transform: capitalize;text-align: right;width: 20%;">${
          this.currencySymbol
        }${
          data[i]?.final_price
            ? parseInt(data[i]?.final_price) * parseInt(data[i]?.qty)
            : parseInt(data[i]?.product.price) * parseInt(data[i]?.qty)
        }</td>
    </tr>`;
      }
      return newdata;
    };
    const renderBussinessLocation = () => {
      const {config} = this.props;
      return config?.businessLocation?.street &&
        config?.businessLocation?.area &&
        config?.businessLocation?.city
        ? `${
            config?.businessLocation?.street
              ? config?.businessLocation?.street
              : ''
          }${
            config?.businessLocation?.area
              ? ', ' + config?.businessLocation?.area
              : ''
          }${
            config?.businessLocation?.city
              ? ', ' + config?.businessLocation?.city
              : ''
          }${
            config?.businessLocation?.state
              ? config?.businessLocation?.state
              : ''
          }${
            config?.businessLocation?.country
              ? ', ' + config?.businessLocation?.country
              : ''
          } ${
            config?.businessLocation?.pincode
              ? '- ' + config?.businessLocation?.pincode
              : ''
          }`
        : '-';
    };
    const html = `<html>
    <head>
        <style>
            @page {
                size: 58mm 105mm;
            }
            *{
              box-sizing: border-box;
              font-size: 1mm;
              font-weight: 400;
            }
            .mainDiv {
                width: 100%;
                height: 100%;
                border-width: 1px solid #000;
            }
            .title_container{ 
                border-bottom: 1px dotted black;
                text-align: center;
                padding: 5px;
            }
            tr{width: 100%;}
        </style>
    </head>
    <body>
        <div class="mainDiv">
            <div class="title_container">
                <div style=" font-size: 110%;text-transform: uppercase;padding-bottom: 5px; font-weight: 600;">${
                  this.props.config?.businessName
                }</div>
                <div style="text-transform: capitalize;">${renderBussinessLocation()}  </div>
            </div>
            <table style="width: 100%;border-bottom: 1px dotted black;">
                <!-- <tr>
                    <td style="text-transform: capitalize;">Invoice #</td>
                    <td style="text-transform: uppercase;text-align: right;">inv-0000</td>
                </tr> -->
                <tr>
                    <td style="text-transform: capitalize;">order ID</td>
                    <td style="text-transform: uppercase;text-align: right;">ORD00${
                      this.state.basicOrderDetail?.id
                    }</td>
                </tr>
               ${
                 this.state.basicOrderDetail?.type == 'dinein'
                   ? `<tr>
                <td style="text-transform: capitalize;">table no</td>
                <td style="text-transform: uppercase;text-align: right;">${this.state.basicOrderDetail?.table_number}</td>
            </tr>`
                   : ''
               }
                <tr>
                    <td style="text-transform: capitalize;">Date</td>
                    <td style="text-transform: uppercase;text-align: right;">${
                      this.state.basicOrderDetail?.created_at &&
                      moment(this.state.basicOrderDetail?.created_at).format(
                        'DD MMM YYYY hh:mm A',
                      )
                    }</td>
                </tr>
                <tr>
                    <td style="text-transform: capitalize;">name</td>
                    <td style="text-transform: uppercase;text-align: right;">${
                      this.state.basicOrderDetail?.user?.name
                    }</td>
                </tr>
                <tr>
                    <td style="text-transform: capitalize;">phone</td>
                    <td style="text-transform: uppercase;text-align: right;">${
                      this.state.basicOrderDetail?.user?.phone
                    }</td>
                </tr>
            </table>
            <table style="width: 100%;border-bottom: 1px dotted black;">
                <tr>
                    <td style="text-transform: capitalize;width: 40%;font-weight: 500;">Products</td>
                    <td style="text-transform: capitalize;text-align: center;width: 20%;font-weight: 500;">qty</td>
                    <td style="text-transform: capitalize;text-align: center;width: 20%;font-weight: 500;">rate</td>
                    <td style="text-transform: capitalize;text-align: right;width: 20%;font-weight: 500;">amount</td>
                </tr>
                ${getOrderList()}
            </table>
            ${
              this.state.basicOrderDetail?.promotion
                ? `<div style=" border-bottom: 1px dotted black; display: flex;flex-direction: row;justify-content: space-between">
            <div style="margin: 3px;width: 65%">
                <div>Discount : ${
                  this.state.basicOrderDetail?.promotion?.coupon_code
                }</div>
                <div>
                (${
                  parseFloat(basicOrderDetail?.promotion?.percentage_of) +
                  '% up to ' +
                  this.currencySymbol +
                  '' +
                  parseFloat(basicOrderDetail?.promotion?.discount_upto_price)
                }
                  )</div>
            </div>
            <div style="margin: 3px; ">
                - ${this.currencySymbol}${parseFloat(
                    basicOrderDetail?.order_total - basicOrderDetail?.total,
                  ).toFixed(2)}
            </div>
        </div>
        `
                : ``
            }
           ${
             parseFloat(this.state.basicOrderDetail?.delivery_charge) > 0
               ? `<div>
                 <div style="margin: 5px 0; display: flex;flex-direction: row;justify-content: flex-end;">
                   <div style="width: 50%; text-transform: capitalize; text-align: right;">
                     <div>delivery charge</div>
                   </div>
                   <div style="width: 30%; text-transform: capitalize; text-align: right;">
                     <div>
                       ${this.currencySymbol}
                       ${parseFloat(
                         this.state.basicOrderDetail?.delivery_charge,
                       ).toFixed(2)}
                       /-
                     </div>
                   </div>
                 </div>
               </div>`
               : ''
           }
            <div style="margin: 5px 0; display: flex;flex-direction: row;justify-content: flex-end;">
                <div style="width: 30%; text-transform: capitalize; text-align: right;">
                    <div>total</div>
                </div>
                <div style="width: 30%; text-transform: capitalize; text-align: right;">
                    <div>${this.currencySymbol}${parseFloat(
      this.state.basicOrderDetail?.total,
    ).toFixed(2)}/-</div>
                    <!-- <div style="">${this.currencySymbol}${parseFloat(
      this.state.basicOrderDetail?.total,
    )}/-</div>
                    <div>₹ 0.00/-</div>
                    <div style="">${this.currencySymbol}${parseFloat(
      this.state.basicOrderDetail?.total,
    )}/-</div> -->
                </div>
            </div>
            <div style="width: 100%;margin-top: 15px; text-transform: capitalize;">bill inclusive of tax</div>
            <div style="display: flex;flex-direction: row;">
                <div style="width: 100%;text-align: center;align-items: center;justify-content: right;">
                    <div style="height: 10px;"></div>
                    <div style="text-align: right;text-transform: capitalize;">For ${
                      this.props.config?.businessName
                    }</div>
                    <div style="text-align: right;text-transform: capitalize;">(signatory)</div>
                    <br/>
                    <br/>
                    <hr/>
                </div>
            </div>
        </div>
    </body>
</html>`;
    if (Platform.OS == 'web') {
      let printWin = window.open('', '', 'toolbar=0,scrollbars=0,status=0');
      printWin.document.write(html);
      printWin.document.close();
      printWin.focus();
      printWin.print();
      printWin.close();
      return;
    }
    await Print.printAsync({
      html: html,
      // width: 58,
      // height: 100,
      printerUrl: this.state.selectedPrinter?.url, // iOS only
    });
  };

  componentDidMount() {
    this.props.setLoading(true);
    this.unsubscribe = this.props.navigation.addListener('focus', () => {
      this.fetchOrderDetail();
    });
    // this.fetchActiveChatData();
    ['merchant', 'manager'].includes(this.props?.user?.type) &&
      this.fetchOrderStatusHistoryList();
  }

  componentWillUnmount() {
    this.props.setLoading(false);
    this.unsubscribe && this.unsubscribe();
  }

  // fetchActiveChatData = () => {
  //   // this.props.setLoading(true);
  //   axios
  //     .get(`/support/get/${this.state?.orderId}/order`)
  //     .then((response) => {
  //       this.setState({activeChatData: response.data?.data});
  //       // this.props.setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log('error', error);
  //       this.props.setLoading(false);
  //       this.props.openAlert(
  //         error.response?.message || 'Something went wrong.',
  //       );
  //     });
  // };

  fetchOrderDetail = async () => {
    const {coordinates} = this.state;
    this.props.setLoading(true);
    await axios
      .get(`/order/detail/${this.state?.orderId}`)
      .then((response) => {
        console.log('order Detail->', response?.data?.data);
        this.setState({status: (response?.data?.data?.status).toUpperCase()});
        this.setState({orderProductDetail: response?.data?.data?.products});
        this.setState({basicOrderDetail: response?.data?.data});
        let charge = this.state.serviceCharge;
        this.state.orderProductDetail.forEach((item) => {
          let itemCharge =
            (parseInt(item?.tax) *
              parseInt(item?.qty) *
              parseInt(item?.price)) /
            100;
          charge += parseInt(itemCharge);
        });
        this.setState({...this.state, serviceCharge: charge});

        ['merchant', 'manager', 'deliveryboy'].includes(
          this.props?.user?.type,
        ) &&
          response?.data?.data?.delivery_method == 'self' &&
          response?.data?.data?.type == 'delivery' &&
          this.getLocationAsync();

        if (response?.data?.data?.address) {
          let location = response?.data?.data?.address;
          let array = coordinates;
          array[1] = {
            ...array[1],
            latitude: parseFloat(location?.latitude),
            longitude: parseFloat(location?.longitude),
          };
          this.setState({
            coordinates: array,
          });
        }

        this.props.setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        this.props.setLoading(false);
        this.props.openAlert(error.response.message);
      });
  };

  fetchOrderStatusHistoryList = async () => {
    // this.props.setLoading(true);
    try {
      const response = await axios.get(
        `/order/status/history/list/${this.state?.orderId}`,
      );
      console.log('response order history', response);
      this.setState({
        orderStatusHistory: response.data,
      });
      // this.props.setLoading(false);
    } catch (error) {
      console.log('error', error);
      this.props.setLoading(false);
      this.props.openAlert(error?.response?.message);
    }
  };

  onUpdateStatus = async (id, status, deliveryMethod, deliveryInfo) => {
    this.props.setLoading(true);
    try {
      const response = await axios.put(`/order/update/status/${id}`, {
        status: status,
        status_message: this.state?.statusMessage,
        delivery_method: deliveryMethod ? deliveryMethod : null,
        delivery_information: deliveryInfo ? deliveryInfo : null,
      });
      this.setState({showModal: false});
      console.log('response---', response);
      this.fetchOrderDetail();
      this.fetchOrderStatusHistoryList();
      // this.props.setLoading(false);
    } catch (error) {
      console.log('error', error);
      this.props.openAlert(error?.response?.message);
      this.props.setLoading(false);
    }
  };

  requestToCancelOrder = async () => {
    this.props.setLoading(true);
    try {
      await axios
        .post('/order/cancel/request/', {
          status: 'requested',
          order: this.state.basicOrderDetail.id,
          message: this.state?.statusMessage,
        })
        .then((response) => {
          console.log('response->', response);
          this.fetchOrderDetail();
        });
    } catch (error) {
      console.log('error', error.response);
      this.props.openAlert(error?.response?.message);
      this.props.setLoading(false);
    }
  };

  updateCancellationOrder = async (id, status, message) => {
    let body = {status: status};
    if (message) {
      body = {
        ...body,
        message: message,
      };
    }
    this.props.setLoading(true);
    try {
      await axios
        .patch('/order/cancel/request/status/update/' + id, body)
        .then((response) => {
          console.log('response->', response);
          this.fetchOrderDetail();
          this.fetchOrderStatusHistoryList();
        });
    } catch (error) {
      console.log('error', error.response);
      this.props.openAlert(error?.response?.message);
      this.props.setLoading(false);
    }
  };

  UpdateStatusDelivered = async (id) => {
    // this.props.setLoading(true);
    try {
      const response = await axios.put(`/order/update/status/delivered/${id}`, {
        status: 'completed',
      });
      this.setState({showModal: false});
      console.log('response---', response);
      this.fetchOrderDetail();
      this.fetchOrderStatusHistoryList();
      // this.props.setLoading(false);
    } catch (error) {
      this.props.openAlert(error?.response?.message);
      console.log('error', error.response);
      this.props.setLoading(false);
    }
  };

  downloadShiprocketInvoice = (orderID) => {
    axios
      .post('/delivery/get/invoice/', {
        order: orderID,
      })
      .then((response) => {
        console.log('response get invoice', response);
        Linking.openURL(response?.data?.invoice_url);
      })
      .catch((error) => {
        console.log('error get invoice', error);
        this.props.openAlert(
          error?.response?.message || 'Something went wrong.',
        );
      });
  };

  downloadOrderInvoice = async () => {
    this.props.setLoading(true);
    await axios
      .get('/order/invoice/' + this.state.basicOrderDetail?.id)
      .then(async (response) => {
        console.log('response ------>', response);
        this.props.setLoading(false);
        if (Platform.OS == 'web') {
          let printWin = window.open('', '', 'toolbar=0,scrollbars=0,status=0');
          printWin.document.write(response.data);
          printWin.document.close();
          printWin.focus();
          printWin.print();
          printWin.close();
          return;
        }
        await Print.printAsync({
          html: response.data,
          // width: 58,
          // height: 100,
          printerUrl: this.state.selectedPrinter?.url, // iOS only
        });
      })
      .catch((error) => {
        console.log('error-->', error?.response);
        this.props.openAlert(
          error?.response?.message || 'Something went wrong.',
        );
        this.props.setLoading(false);
      });
  };

  renderVCRedeemedInOrderItem = (item) => {
    return (
      <View style={styles.itemDetailContainerRedeemedVC}>
        <View style={{flex: 2, justifyContent: 'center'}}>
          <AppText style={styles.netTotalItem1}>{item?.voucherName}</AppText>
        </View>
        <View style={styles.itemDetail2Container}>
          <AppText style={styles.itemQuantityText}>{item?.quality}</AppText>
          <AppText style={styles.priceSign}>{this.currencySymbol} </AppText>
          <AppText style={styles.netTotalItem2}>{`${cashFormat(
            item?.total,
          )}`}</AppText>
        </View>
      </View>
    );
  };

  renderOrderItem = ({item, index}) => {
    return (
      <View key={index} style={styles.orderDetailItem}>
        <View
          style={{width: '50%', flexDirection: 'row', alignItems: 'flex-start'}}
        >
          <AppText
            style={[styles.itemName]}
            onPress={() =>
              this.props.navigation.navigate('productDetail', {
                slug: item?.product?.name
                  .toLowerCase()
                  .replace(/ /g, '-')
                  .replace(/[^\w-]+/g, ''),
                id: item?.product?.id,
              })
            }
          >
            {item?.product?.name + ' '}
            {item?.product_variants?.length > 0 ? (
              <AppText style={styles.productVarientsText}>
                ({item?.product_variants?.map((o) => o.variant).join(', ')})
              </AppText>
            ) : (
              item?.selected_variants?.length > 0 && (
                <AppText style={styles.productVarientsText}>
                  ({item?.selected_variants?.map((o) => o.value).join(', ')})
                </AppText>
              )
            )}
          </AppText>
        </View>
        <View style={styles.itemDetail2Container}>
          <AppText style={styles.itemQuantityText}>
            {parseInt(item?.qty)}
          </AppText>
          <AppText style={styles.priceText}>
            {this.currencySymbol + ' ' + item?.final_price
              ? item?.final_price
              : item?.product?.price}
          </AppText>
        </View>
      </View>
    );
  };

  renderOrder = () => {
    const {item} = this.props.route.params;
    const {basicOrderDetail} = this.state;

    return (
      <View style={styles.infoList}>
        <AppText style={styles.info}>
          {strings('ordering.orderHistoryDetailScreen.orderItems')}
        </AppText>

        <View
          style={{
            height: 1,
            backgroundColor: 'gray',
            width: '100%',
          }}
        />
        <View style={styles.orderDetailContainer}>
          <View style={styles.orderDetailItem}>
            <View style={{width: '50%', justifyContent: 'center'}}>
              <AppText
                style={[styles.itemName, {fontFamily: 'Roboto-Bold'}]}
                numberOfLines={1}
              >
                {strings('ordering.orderStatusPending.products')}
              </AppText>
            </View>
            <View style={styles.itemDetail2Container}>
              <AppText
                style={[styles.itemQuantityText, {fontFamily: 'Roboto-Bold'}]}
              >
                {strings('ordering.orderStatusPending.qty')}
              </AppText>
              <AppText style={[styles.priceText, {fontFamily: 'Roboto-Bold'}]}>
                {strings('ordering.orderStatusPending.price')}
              </AppText>
            </View>
          </View>
          <View>
            {this.state.orderProductDetail?.length !== 0 &&
              this.state.orderProductDetail?.map((item, index) =>
                this.renderOrderItem({item, index}),
              )}
          </View>
          <View
            style={{
              marginHorizontal: -10,
              borderTopWidth: 1,
              borderColor: '#ccc',
              paddingHorizontal: 10,
              marginTop: 10,
            }}
          >
            {basicOrderDetail?.type == 'delivery' && (
              <View style={[styles.orderDetailItem, {marginTop: 10}]}>
                <AppText style={styles.subTotalItem1}>
                  {strings('ordering.orderStatusPending.deliveryCharge')}
                </AppText>
                <AppText style={styles.subTotalItem2}>
                  {this.currencySymbol +
                    ' ' +
                    basicOrderDetail?.delivery_charge}
                </AppText>
              </View>
            )}
            <View style={[styles.orderDetailItem]}>
              <AppText style={styles.subTotalItem1}>
                {strings('ordering.orderStatusPending.total')}
              </AppText>
              <AppText style={styles.subTotalItem2}>
                {this.currencySymbol + ' ' + basicOrderDetail?.total}
              </AppText>
            </View>
          </View>

          {false && this.state.order?.serviceCharge !== 0 && (
            <View style={styles.itemDetail2Container}>
              <AppText style={styles.netTotalItem1}>
                {strings('ordering.checkOutorder?.serviceTax')}
              </AppText>
              <AppText style={styles.netTotalItem2}>
                {this.currencySymbol +
                  ' ' +
                  cashFormat(this.state.serviceCharge)}
              </AppText>
            </View>
          )}
        </View>
      </View>
    );
  };

  renderGrandTotal = () => {
    return (
      <View
        style={[
          styles.totalContainer,
          {marginHorizontal: 10, marginVertical: 10},
        ]}
      >
        <AppText style={styles.totalItem1}>
          {strings('ordering.checkOutorder?.balancePayment')}
        </AppText>
        <AppText style={styles.totalItem2}>
          {this.currencySymbol} {cashFormat(this.state.basicOrderDetail?.total)}
        </AppText>
      </View>
    );
  };

  renderOrderInfo = () => {
    const {basicOrderDetail} = this.state;
    console.log('basicOrderDetail?.status', basicOrderDetail?.status);

    return (
      <View style={styles.infoList}>
        <AppText style={styles.info}>
          {strings('ordering.orderStatusPending.detail')}
        </AppText>
        <View
          style={{
            height: 1,
            backgroundColor: 'gray',
            width: '100%',
          }}
        />
        <View style={styles.orderDetailContainer}>
          <View style={styles.orderDetailItem}>
            <AppText style={styles.infoItemText1}>
              {strings('ordering.orderHistoryDetailScreen.orderNo')}
            </AppText>
            <AppText>{'ORD00' + basicOrderDetail?.id}</AppText>
          </View>
          <View style={[styles.orderDetailItem, {marginVertical: 5}]}>
            <AppText style={styles.infoItemText1}>
              {strings('ordering.orderHistoryDetailScreen.orderDate')}
            </AppText>
            <AppText>
              {basicOrderDetail?.created_at &&
                moment(basicOrderDetail?.created_at).format(
                  'DD MMMM YYYY hh:mm A',
                )}
            </AppText>
          </View>
          {basicOrderDetail?.type === 'pickup' && (
            <View style={[styles.orderDetailItem, {marginVertical: 5}]}>
              <AppText style={styles.infoItemText1}>
                {strings('ordering.orderHistoryDetailScreen.pickupTime')}
              </AppText>
              <AppText>
                {basicOrderDetail?.pickup_time &&
                  moment(basicOrderDetail?.pickup_time).format(
                    'DD MMMM YYYY hh:mm A',
                  )}
              </AppText>
            </View>
          )}
          <View style={[styles.orderDetailItem, {marginTop: 5}]}>
            <AppText style={styles.infoItemText1}>
              {strings('ordering.orderStatusPending.orderStatus')}
            </AppText>
            <AppText>{basicOrderDetail?.status?.toUpperCase()}</AppText>
          </View>
          <View style={[styles.orderDetailItem, {marginTop: 5}]}>
            <AppText style={styles.infoItemText1}>
              {strings('ordering.orderStatusPending.paymentMethode')}
            </AppText>
            <AppText>
              {basicOrderDetail?.type == 'offline'
                ? basicOrderDetail?.payment?.payment_method?.type?.toUpperCase()
                : basicOrderDetail?.cash_on_delivery == true
                ? 'CASH ON DELIVERY'
                : basicOrderDetail?.payment?.from_coin
                ? 'COIN'
                : basicOrderDetail?.payment?.payment_method?.type?.toUpperCase()}
            </AppText>
          </View>
          {(basicOrderDetail?.status === 'declined' ||
            basicOrderDetail?.status === 'cancelled') &&
            basicOrderDetail?.payment_refund_key?.length > 0 &&
            ['merchant', 'manager'].includes(this.props?.user?.type) && (
              // basicOrderDetail?.payment_method == 'razorpay' &&
              <View style={[styles.orderDetailItem, {marginTop: 5}]}>
                <AppText style={styles.infoItemText1}>
                  {strings('ordering.orderStatusPending.refundKey')}
                </AppText>
                <AppText style={{width: '60%', textAlign: 'right'}}>
                  {basicOrderDetail?.payment_refund_key}
                </AppText>
              </View>
            )}
          {(basicOrderDetail?.status == 'declined' ||
            basicOrderDetail?.status === 'cancelled') &&
            basicOrderDetail?.status_message?.length > 0 && (
              <View style={[styles.orderDetailItem, {marginTop: 5}]}>
                <AppText style={styles.infoItemText1}>
                  {strings('ordering.orderStatusPending.message')}
                </AppText>
                <AppText
                  style={{width: '60%', textAlign: 'right'}}
                  numberOfLines={2}
                >
                  {basicOrderDetail?.status_message}
                </AppText>
              </View>
            )}
          {basicOrderDetail?.type == 'dinein' && (
            <View style={[styles.orderDetailItem, {marginTop: 5}]}>
              <AppText style={styles.infoItemText1}>
                {strings('ordering.orderHistoryDetailScreen.tableNo')}
              </AppText>
              <AppText>{basicOrderDetail?.table_number}</AppText>
            </View>
          )}
        </View>
      </View>
    );
  };

  renderDeliveryLocation = () => {
    const {config} = this.props;

    let addressArea = '';
    let addressSecond = '';
    if (config?.businessLocation?.street) {
      addressArea += config?.businessLocation?.street + ', ';
    }
    if (config?.businessLocation?.area) {
      addressArea += config?.businessLocation?.area + ', ';
    }
    if (config?.businessLocation?.city) {
      addressSecond += config?.businessLocation?.city + ', ';
    }
    if (config?.businessLocation?.state) {
      addressSecond += config?.businessLocation?.state + ', ';
    }
    if (config?.businessLocation?.country) {
      addressSecond += config?.businessLocation?.country;
    }
    if (config?.businessLocation?.pincode) {
      addressSecond += ' - ' + config?.businessLocation?.pincode;
    }

    return (
      <View style={styles.infoList}>
        {/* Heading */}
        <AppText style={styles.info}>
          {strings('ordering.orderStatusPending.pickUpLocation')}
        </AppText>
        {/* border Line */}
        <View
          style={{
            height: 1,
            backgroundColor: 'gray',
            width: '100%',
          }}
        />
        <View style={[styles.orderDetailContainer, {flexDirection: 'column'}]}>
          <AppText style={{textTransform: 'capitalize'}}>{addressArea}</AppText>
          <AppText style={{textTransform: 'capitalize'}}>
            {addressSecond}
          </AppText>
        </View>
      </View>
    );
  };

  renderCustomerInfo = () => {
    const {basicOrderDetail} = this.state;

    return (
      <View style={styles.infoList}>
        {/* Heading */}
        <AppText style={styles.info}>
          {strings('ordering.orderStatusPending.customerDetails')}
        </AppText>
        {/* border Line */}
        <View
          style={{
            height: 1,
            backgroundColor: 'gray',
            width: '100%',
          }}
        />

        {/*  customer Info */}
        <View style={styles.orderDetailContainer}>
          <View style={[styles.orderDetailItem, {marginTop: 0}]}>
            <AppText style={styles.infoItemText1}>
              {strings('ordering.orderStatusPending.name')}
            </AppText>
            <AppText>
              {basicOrderDetail?.user?.name
                ? basicOrderDetail?.user?.name
                : basicOrderDetail?.customer?.name}
            </AppText>
          </View>
          <View style={[styles.orderDetailItem, {marginTop: 5}]}>
            <AppText style={styles.infoItemText1}>
              {strings('ordering.orderStatusPending.mobile')}
            </AppText>
            <AppText>
              {basicOrderDetail?.user?.phone
                ? basicOrderDetail?.user?.phone
                : basicOrderDetail?.customer?.phone}
            </AppText>
          </View>
        </View>
      </View>
    );
  };

  getLocationAsync = async () => {
    const {coordinates} = this.state;
    let {status} = await Location.requestForegroundPermissionsAsync();
    if (status !== 'granted') {
      this.setState({locationPermission: false});
      this.props.openAlert(
        strings('ordering.orderStatusPending.permisiondenied'),
      );
      return;
    }
    this.setState({isLocationLoading: true});
    let location = await Location.getCurrentPositionAsync({});
    let array = coordinates;
    array[0] = {
      ...array[0],
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
    };
    this.setState({
      locationPermission: true,
      coordinates: array,
      isLocationLoading: false,
    });

    console.log('location', location);
  };

  _handleMapRegionChange = (coordinate) => {
    let array = this.state.coordinates;
    array[0] = {
      ...array[0],
      coordinate,
    };
    this.setState({
      coordinates: array,
    });
  };

  renderDeliveryAddress = () => {
    const {
      basicOrderDetail,
      locationPermission,
      coordinates,
      isLocationLoading,
    } = this.state;

    return (
      <View style={styles.infoList}>
        <AppText style={styles.info}>
          {strings('ordering.orderStatusPending.deliveryAddress')}
        </AppText>
        <View
          style={{
            height: 1,
            backgroundColor: 'gray',
            width: '100%',
          }}
        />
        <View style={[styles.orderDetailContainer, {flexDirection: 'column'}]}>
          <AppText>
            {basicOrderDetail?.address?.street},{' '}
            {basicOrderDetail?.address?.area},{' '}
          </AppText>
          <AppText>
            {basicOrderDetail?.address?.city},{' '}
            {basicOrderDetail?.address?.state},{' '}
            {basicOrderDetail?.address?.country}, {' - '}
            {basicOrderDetail?.address?.pincode}
          </AppText>
          {isLocationLoading ? (
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                height: screenHeight / 4,
              }}
            >
              <ActivityIndicator size="large" color={Colors.theme_color} />
            </View>
          ) : (
            Platform.OS !== 'web' &&
            ['merchant', 'manager', 'deliveryboy'].includes(
              this.props?.user?.type,
            ) &&
            basicOrderDetail?.delivery_method == 'self' && (
              <View style={styles.mainMapContainer}>
                {locationPermission == true && coordinates ? (
                  <MapView
                    style={styles.map}
                    provider={MapView.PROVIDER_GOOGLE}
                    showsUserLocation={true}
                    zoomEnabled={true}
                    zoomControlEnabled={true}
                    zoomTapEnabled={true}
                    initialRegion={{
                      latitude: coordinates[0].latitude,
                      longitude: coordinates[0].longitude,
                      latitudeDelta: 0.0922,
                      longitudeDelta: 0.0421,
                    }}
                    onRegionChange={this._handleMapRegionChange}
                  >
                    {coordinates.map((coordinate, index) => (
                      <MapView.Marker
                        key={`coordinate_${index}`}
                        coordinate={coordinate}
                      />
                    ))}
                    {this.state.coordinates.length >= 2 && (
                      <MapViewDirections
                        lineDashPattern={[0]}
                        origin={coordinates[0]}
                        destination={coordinates[1]}
                        apikey={GOOGLE_API_KEY} // insert your API Key here
                        strokeWidth={5}
                        strokeColor="#111111"
                      />
                    )}
                  </MapView>
                ) : (
                  locationPermission == false &&
                  locationPermission != null && (
                    <View
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginVertical: setValue(30),
                      }}
                    >
                      <AppText
                        style={{
                          width: screenWidth / 1.5,
                          textAlign: 'center',
                          color: Colors.wallet_card_red,
                        }}
                      >
                        {strings(
                          'ordering.orderStatusPending.locationPermison',
                        )}
                      </AppText>
                      <TouchableOpacity
                        style={styles.LocationRequestButton}
                        onPress={() => this.getLocationAsync()}
                      >
                        <AppText style={{color: Colors.white}}>
                          {strings(
                            'ordering.orderStatusPending.requestLocation',
                          )}
                        </AppText>
                      </TouchableOpacity>
                    </View>
                  )
                )}
              </View>
            )
          )}
        </View>
      </View>
    );
  };

  renderSpecialInstruction = () => {
    const {basicOrderDetail} = this.state;

    return (
      <View style={styles.infoList}>
        <AppText style={styles.info}>{'Special Instruction'}</AppText>
        <View
          style={{
            height: 1,
            backgroundColor: 'gray',
            width: '100%',
          }}
        />
        <View style={styles.infoItemStyle}>
          <AppText>{basicOrderDetail?.special_instructions}</AppText>
        </View>
      </View>
    );
  };

  renderQrCode = () => {
    const {basicOrderDetail} = this.state;
    return (
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          padding: setValue(10),
        }}
      >
        <QRCode
          size={Platform.OS == 'web' ? 350 : screenWidth / 2}
          value={basicOrderDetail?.qr_code}
        />
      </View>
    );
  };

  renderCancellation = () => {
    const {basicOrderDetail} = this.state;

    return (
      <View style={styles.infoList}>
        <AppText style={styles.info}>
          {strings('ordering.orderHistoryDetailScreen.cancellationRequest')}
        </AppText>
        <View
          style={{
            height: 1,
            backgroundColor: 'gray',
            width: '100%',
          }}
        />
        <View style={styles.orderDetailContainer}>
          <View style={styles.orderDetailItem}>
            <AppText style={styles.infoItemText1}>
              {' '}
              {strings('ordering.orderHistoryDetailScreen.status')}
            </AppText>
            <AppText style={{textTransform: 'capitalize'}}>
              {basicOrderDetail?.cancellation?.status}
            </AppText>
          </View>
          <View style={styles.orderDetailItem}>
            <AppText style={styles.infoItemText1}>
              {strings('ordering.orderHistoryDetailScreen.message')}
            </AppText>
            <AppText
              style={{
                textTransform: 'capitalize',
                textAlign: 'right',
                width: '60%',
              }}
            >
              {basicOrderDetail?.cancellation?.message}
            </AppText>
          </View>
        </View>
      </View>
    );
  };

  renderAppliedOfferCard = () => {
    const {basicOrderDetail} = this.state;

    return (
      <View style={styles.infoList}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <AppText style={styles.info}>
            {strings('ordering.orderStatusPending.appliedPromotion')}
          </AppText>
          <AppText style={[styles.info, {fontWeight: '700'}]}>
            {'- ' +
              this.currencySymbol +
              ' ' +
              parseFloat(
                parseFloat(basicOrderDetail?.order_total) +
                  parseFloat(basicOrderDetail?.delivery_charge) -
                  parseFloat(basicOrderDetail?.total),
              ).toFixed(2)}
          </AppText>
        </View>
        <View
          style={{
            height: 1,
            backgroundColor: 'gray',
            width: '100%',
          }}
        />
        <View style={[styles.orderDetailContainer, {flexDirection: 'column'}]}>
          <AppText>
            <Text style={{fontWeight: '700'}}>
              {basicOrderDetail?.promotion?.coupon_code}
            </Text>
            {'  ' +
              strings('ordering.orderStatusPending.codeapply') +
              parseFloat(basicOrderDetail?.promotion?.percentage_of) +
              '% ' +
              strings('ordering.orderStatusPending.upto') +
              this.currencySymbol +
              ' ' +
              parseFloat(basicOrderDetail?.promotion?.discount_upto_price)}
          </AppText>
        </View>
      </View>
    );
  };

  renderSteps = () => {
    const {config} = this.props;
    console.log('this.props', config?.type);
    let doneAtStepIndex = 0;
    if (this.state.status === 'PAID') {
      doneAtStepIndex = 1;
    } else if (this.state.status === 'ACCEPTED') {
      doneAtStepIndex = 2;
    } else if (this.state.status === 'PREPARING') {
      doneAtStepIndex = 3;
    } else if (
      this.state.status === 'READY' ||
      this.state.status === 'SHIPPING'
    ) {
      doneAtStepIndex = 4;
    } else if (this.state.status === 'COMPLETED') {
      doneAtStepIndex = 5;
    }
    return (
      <View style={styles.orderStatusStepConatiner}>
        <View style={styles.separator} />
        {!this.state?.basicOrderDetail?.cash_on_delivery && (
          <View style={{alignItems: 'center', justifyContent: 'center'}}>
            <View
              style={doneAtStepIndex >= 1 ? styles.doneStep : styles.undoneStep}
            >
              <Image
                style={styles.stepIconForPaid}
                source={require('../../../assets/images/Ordering/paid.png')}
              />
            </View>
            <Text style={styles.textStep}>
              {strings('ordering.orderStatusPending.paid')}
            </Text>
          </View>
        )}

        <View style={{alignItems: 'center', justifyContent: 'center'}}>
          <View
            style={doneAtStepIndex >= 2 ? styles.doneStep : styles.undoneStep}
          >
            <Image
              style={styles.stepIcon}
              source={require('../../../assets/images/Ordering/order_status_image_accepted.png')}
            />
          </View>
          <Text style={styles.textStep}>
            {strings('ordering.orderStatusPending.accepted')}
          </Text>
        </View>
        {config?.type == 'restaurants' && (
          <View style={{alignItems: 'center', justifyContent: 'center'}}>
            <View
              style={doneAtStepIndex >= 2 ? styles.doneStep : styles.undoneStep}
            >
              <Image
                style={styles.stepIcon}
                source={require('../../../assets/images/Ordering/order_status_image_preparing.png')}
              />
            </View>
            <Text style={styles.textStep}>
              {strings('ordering.orderStatusPending.preparing')}
            </Text>
          </View>
        )}
        {this.state.basicOrderDetail?.type &&
          this.state.basicOrderDetail?.type == 'pickup' && (
            <View style={{alignItems: 'center', justifyContent: 'center'}}>
              <View
                style={
                  doneAtStepIndex >= 4 ? styles.doneStep : styles.undoneStep
                }
              >
                {config?.type == 'retail' ? (
                  <Image
                    style={styles.stepIcon}
                    source={require('../../../assets/images/Ordering/truck.png')}
                  />
                ) : (
                  <Image
                    style={styles.stepIcon}
                    source={require('../../../assets/images/Ordering/order_status_image_ready.png')}
                  />
                )}
              </View>
              <Text style={styles.textStep}>
                {strings('ordering.orderStatusPending.ready')}
              </Text>
            </View>
          )}
        {this.state.basicOrderDetail?.type &&
          this.state.basicOrderDetail?.type == 'delivery' && (
            <View style={{alignItems: 'center', justifyContent: 'center'}}>
              <View
                style={
                  doneAtStepIndex >= 4 ? styles.doneStep : styles.undoneStep
                }
              >
                <Icon name="shipping" color={Colors.white} size={10} />
              </View>
              <Text style={styles.textStep}>
                {strings('ordering.orderStatusPending.shipping')}
              </Text>
            </View>
          )}

        <View style={{alignItems: 'center', justifyContent: 'center'}}>
          <View
            style={doneAtStepIndex >= 5 ? styles.doneStep : styles.undoneStep}
          >
            {config?.type == 'retail' ? (
              <Image
                style={styles.stepIcon}
                source={require('../../../assets/images/Ordering/order_status_image_deliverd.png')}
              />
            ) : (
              <Image
                style={styles.stepIcon}
                source={require('../../../assets/images/Ordering/order_status_image_served.png')}
              />
            )}
          </View>
          <Text style={styles.textStep}>
            {this.state.basicOrderDetail?.type == 'delivery'
              ? strings('ordering.orderStatusPending.delivered')
              : this.state.basicOrderDetail?.type == 'dinein'
              ? strings('ordering.orderStatusPending.served')
              : strings('ordering.orderStatusPending.completed')}
          </Text>
        </View>
      </View>
    );
  };

  renderShippingDetail = () => {
    const {basicOrderDetail} = this.state;

    return (
      <View style={styles.infoList}>
        <AppText style={styles.info}>
          {strings('ordering.orderStatusPending.shippingDetails')}
        </AppText>
        <View
          style={{
            height: 1,
            backgroundColor: 'gray',
            width: '100%',
          }}
        />
        <View style={styles.orderDetailContainer}>
          <View style={styles.orderDetailItem}>
            <AppText style={styles.infoItemText1}>
              {strings('ordering.orderStatusPending.courierName') + ': '}
            </AppText>
            <AppText style={{textTransform: 'capitalize'}}>
              {basicOrderDetail?.delivery_information?.courier_name}
            </AppText>
          </View>
          <View style={styles.orderDetailItem}>
            <AppText style={styles.infoItemText1}>
              {strings('ordering.orderStatusPending.aWBNumber') + ': '}
            </AppText>
            <AppText style={{textTransform: 'capitalize'}}>
              {basicOrderDetail?.delivery_information?.awb_number}
            </AppText>
          </View>
        </View>
      </View>
    );
  };

  renderSelfShippingDetail = () => {
    const {basicOrderDetail} = this.state;

    return (
      <View style={styles.infoList}>
        <AppText style={styles.info}>
          {strings('ordering.orderHistoryDetailScreen.deliveryInfo')}
        </AppText>
        <View
          style={{
            height: 1,
            backgroundColor: 'gray',
            width: '100%',
          }}
        />
        <View style={styles.orderDetailContainer}>
          <View style={styles.orderDetailItem}>
            <AppText style={styles.infoItemText1}>
              {strings('ordering.orderHistoryDetailScreen.deliveryPartner')}
            </AppText>
            <AppText style={{textTransform: 'capitalize'}}>
              {basicOrderDetail?.delivery_information?.delivery_partner}
            </AppText>
          </View>
          <View style={styles.orderDetailItem}>
            <AppText style={styles.infoItemText1}>
              {strings('ordering.orderHistoryDetailScreen.trackingId')}
            </AppText>
            <AppText style={{textTransform: 'capitalize'}}>
              {basicOrderDetail?.delivery_information?.tracking_id}
            </AppText>
          </View>
        </View>
      </View>
    );
  };

  scanBarcode = (data) => {
    // selfTrackingId
    console.log('data', data);
    this.setState({
      showShippingModal: true,
      selfTrackingId: data?.trackingId,
    });
  };

  render = () => {
    // const {item} = this.props.route.params;
    const {user, config, loading} = this.props;
    const {
      basicOrderDetail,
      showModal,
      statusMessage,
      showShippingModal,
      orderStatusHistory,
      errorMessage,
    } = this.state;
    return (
      <View style={styles.container}>
        <MainHeader
          title={
            basicOrderDetail?.type
              ? strings('ordering.orderHistoryDetailScreen.title') +
                ' - ' +
                basicOrderDetail?.type.toUpperCase()
              : strings('ordering.orderHistoryDetailScreen.title')
          }
          leftIcon="back"
          leftPress={() => goBack(this.props.navigation)}
          rightIcon={'print'}
          rightPress={() => {
            // this.print()
            // this.downloadOrderInvoice();
            Linking.openURL(
              axios.defaults.baseURL +
                '/order/invoice/' +
                this.state.basicOrderDetail?.id,
            );
          }}
        />

        {!loading && (
          <MainContainer>
            <View style={{marginBottom: 50}}>
              {basicOrderDetail?.status !== 'declined' &&
                basicOrderDetail?.status !== 'cancelled' &&
                basicOrderDetail?.type != 'offline' && (
                  <View style={[styles.container2, {padding: 20}]}>
                    {this.renderSteps()}
                  </View>
                )}
              {user.type == 'client' && basicOrderDetail?.type == 'pickup' && (
                <View style={styles.container2}>{this.renderQrCode()}</View>
              )}

              {basicOrderDetail?.cancellation && (
                <View style={styles.container2}>
                  {this.renderCancellation()}
                </View>
              )}
              {['merchant', 'manager'].includes(this.props?.user?.type) &&
                basicOrderDetail?.cancellation?.status == 'requested' && (
                  <View
                    style={[
                      styles.actionButtonContainer,
                      {flexDirection: 'row'},
                    ]}
                  >
                    <TouchableOpacity
                      style={[
                        styles.actionButton,
                        styles.acceptButton,
                        {marginRight: 5},
                      ]}
                      onPress={() =>
                        this.updateCancellationOrder(
                          basicOrderDetail?.cancellation?.id,
                          'accepted',
                          null,
                        )
                      }
                    >
                      <Text
                        style={{fontFamily: 'Roboto-Bold', color: Colors.white}}
                      >
                        {strings('ordering.orderStatusPending.accept')}
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.actionButton,
                        styles.deniedButton,
                        {marginLeft: 5},
                      ]}
                      onPress={() => {
                        this.setState({
                          showModal: true,
                          declineCancellation: true,
                        });
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: 'Roboto-Bold',
                          color: Colors.wallet_card_red,
                        }}
                      >
                        {strings('ordering.orderStatusPending.decline')}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}

              {/* <View> */}
              {basicOrderDetail?.type == 'delivery' && (
                <View style={styles.container2}>
                  {this.renderDeliveryAddress()}
                </View>
              )}

              {basicOrderDetail?.type == 'delivery' &&
                basicOrderDetail?.delivery_method == 'nimbuspost' &&
                basicOrderDetail?.status == 'shipping' && (
                  <View style={styles.container2}>
                    {this.renderShippingDetail()}
                  </View>
                )}
              <View style={styles.container2}>{this.renderOrderInfo()}</View>
              {basicOrderDetail?.delivery_method == 'self' &&
                basicOrderDetail?.delivery_information && (
                  <View style={styles.container2}>
                    {this.renderSelfShippingDetail()}
                  </View>
                )}

              {basicOrderDetail?.type == 'pickup' && (
                <View style={styles.container2}>
                  {this.renderDeliveryLocation()}
                </View>
              )}

              {['merchant', 'manager'].includes(this.props?.user?.type) &&
                typeof basicOrderDetail?.user == 'object' && (
                  <View style={styles.container2}>
                    {this.renderCustomerInfo()}
                  </View>
                )}

              {basicOrderDetail?.special_instructions != '' &&
                basicOrderDetail?.special_instructions != null && (
                  <View style={styles.container2}>
                    {this.renderSpecialInstruction()}
                  </View>
                )}

              <View style={styles.container2}>{this.renderOrder()}</View>
              {basicOrderDetail?.promotion && (
                <View style={styles.container2}>
                  {this.renderAppliedOfferCard()}
                </View>
              )}
              {/* {(ENV == 'local' || ENV == 'development') && (
                <View style={{marginHorizontal: setValue(5)}}>
                  <TouchableOpacity
                    onPress={() => this.print()}
                    style={[styles.actionButton, styles.deniedButton]}
                  >
                    <AppText style={{fontFamily: 'Roboto-Bold'}}>
                      {strings('ordering.orderHistoryDetailScreen.print')}
                    </AppText>
                  </TouchableOpacity>
                </View>
              )} */}

              {/* order accept or decline */}
              {['merchant', 'manager'].includes(this.props?.user?.type) &&
                (basicOrderDetail?.status == 'paid' ||
                  (basicOrderDetail?.status == 'unpaid' &&
                    basicOrderDetail?.cash_on_delivery)) && (
                  <View
                    style={[
                      styles.actionButtonContainer,
                      {flexDirection: 'row', marginBottom: 20},
                    ]}
                  >
                    <TouchableOpacity
                      style={[
                        styles.actionButton,
                        styles.deniedButton,
                        {marginRight: 5},
                      ]}
                      onPress={() => {
                        this.setState({showModal: true});
                        console.log('clicked', this.state.showModal);
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: 'Roboto-Bold',
                          color: Colors.wallet_card_red,
                        }}
                      >
                        {strings('ordering.orderStatusPending.decline')}
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.actionButton,
                        styles.acceptButton,
                        {marginLeft: 5},
                      ]}
                      onPress={() =>
                        this.onUpdateStatus(basicOrderDetail?.id, 'accepted')
                      }
                    >
                      <Text
                        style={{fontFamily: 'Roboto-Bold', color: Colors.white}}
                      >
                        {strings('ordering.orderStatusPending.accept')}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}

              {/* pickup, order accepted ready */}
              {['merchant', 'manager'].includes(this.props?.user?.type) &&
                basicOrderDetail?.status == 'accepted' &&
                basicOrderDetail?.type == 'pickup' && (
                  <View style={styles.actionButtonContainer}>
                    <TouchableOpacity
                      style={[styles.actionButton, styles.acceptButton]}
                      onPress={() =>
                        this.onUpdateStatus(basicOrderDetail?.id, 'ready')
                      }
                    >
                      <Text
                        style={{fontFamily: 'Roboto-Bold', color: Colors.white}}
                      >
                        {strings('ordering.orderStatusPending.isready')}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}

              {/* pickup order ready to completed */}
              {['merchant', 'manager'].includes(this.props?.user?.type) &&
                basicOrderDetail?.status == 'ready' &&
                basicOrderDetail?.type == 'pickup' && (
                  <View style={styles.actionButtonContainer}>
                    <TouchableOpacity
                      style={[styles.actionButton, styles.acceptButton]}
                      onPress={() =>
                        this.onUpdateStatus(basicOrderDetail?.id, 'completed')
                      }
                    >
                      <Text
                        style={{fontFamily: 'Roboto-Bold', color: Colors.white}}
                      >
                        {strings('ordering.orderStatusPending.completed')}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}

              {/* dining order accepted order completed */}
              {['merchant', 'manager'].includes(this.props?.user?.type) &&
                basicOrderDetail?.type == 'dinein' &&
                basicOrderDetail?.status == 'accepted' && (
                  <View style={styles.actionButtonContainer}>
                    <TouchableOpacity
                      style={[styles.actionButton, styles.acceptButton]}
                      onPress={() =>
                        this.onUpdateStatus(basicOrderDetail?.id, 'completed')
                      }
                    >
                      <Text
                        style={{fontFamily: 'Roboto-Bold', color: Colors.white}}
                      >
                        {strings('ordering.orderStatusPending.served')}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}

              {/* delivery order accepted order shipping */}
              {['merchant', 'manager'].includes(this.props?.user?.type) &&
                basicOrderDetail?.status == 'accepted' &&
                basicOrderDetail?.type == 'delivery' && (
                  <View style={styles.actionButtonContainer}>
                    <TouchableOpacity
                      style={[styles.actionButton, styles.acceptButton]}
                      onPress={() =>
                        this.setState({
                          showShippingModal: true,
                        })
                      }
                    >
                      <Text
                        style={{fontFamily: 'Roboto-Bold', color: Colors.white}}
                      >
                        {strings('ordering.orderStatusPending.startShipping')}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}

              {/* delivery shipping order completed and details */}
              {['merchant', 'manager'].includes(this.props?.user?.type) &&
                basicOrderDetail?.status == 'shipping' && (
                  <View style={styles.actionButtonContainer}>
                    {basicOrderDetail?.delivery_method == 'nimbuspost' && (
                      <TouchableOpacity
                        style={[styles.actionButton, styles.deniedButton]}
                        onPress={() => {
                          this.props.navigation.navigate('nimbus-delivery', {
                            orderId: basicOrderDetail.id,
                          });
                        }}
                      >
                        <Text style={{fontFamily: 'Roboto-Bold'}}>
                          {strings('ordering.orderStatusPending.showStatus')}
                        </Text>
                      </TouchableOpacity>
                    )}

                    {basicOrderDetail?.delivery_method == 'shiprocket' && (
                      <TouchableOpacity
                        style={[styles.actionButton, styles.deniedButton]}
                        onPress={() => {
                          this.props.navigation.navigate(
                            'ship-rocket-delivery',
                            {
                              orderId: basicOrderDetail.id,
                            },
                          );
                        }}
                      >
                        <Text style={{fontFamily: 'Roboto-Bold'}}>
                          {strings(
                            'ordering.orderStatusPending.showStatusDelivery',
                          )}
                        </Text>
                      </TouchableOpacity>
                    )}

                    <TouchableOpacity
                      style={[styles.actionButton, styles.acceptButton]}
                      onPress={() =>
                        this.onUpdateStatus(basicOrderDetail?.id, 'completed')
                      }
                    >
                      <Text
                        style={{fontFamily: 'Roboto-Bold', color: Colors.white}}
                      >
                        {strings('ordering.orderStatusPending.delivered')}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}

              {/* delivery boy completed */}
              {['deliveryboy'].includes(this.props?.user?.type) &&
                basicOrderDetail?.status == 'shipping' && (
                  <View style={styles.actionButtonContainer}>
                    <TouchableOpacity
                      style={[styles.actionButton, styles.acceptButton]}
                      onPress={() =>
                        // this.onUpdateStatus(basicOrderDetail?.id, 'completed')
                        this.UpdateStatusDelivered(basicOrderDetail?.id)
                      }
                    >
                      <Text
                        style={{fontFamily: 'Roboto-Bold', color: Colors.white}}
                      >
                        {strings('ordering.orderStatusPending.delivered')}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}

              {/* order status changes history */}
              {orderStatusHistory?.length > 0 &&
                ['merchant', 'manager'].includes(this.props?.user?.type) && (
                  <View style={styles.orderStatusHistoryContainer}>
                    <View>
                      <View>
                        <AppText style={styles.orderHistoryTitle}>
                          {strings(
                            'ordering.orderStatusPending.orderUpdateHistory',
                          )}
                        </AppText>
                        <View style={styles.basicBorder} />
                        {orderStatusHistory.map((o, i) => (
                          <AppText
                            style={styles.orderStatusHistoryItem}
                            key={i}
                          >
                            {strings(
                              'ordering.orderStatusPending.orderUpdateHistoryby',
                            )}
                            <AppText style={styles.orderStatus}>
                              {' '}
                              {o?.user_name?.toUpperCase()}
                            </AppText>
                            {strings('ordering.orderStatusPending.from')}

                            <AppText style={styles.orderStatus}>
                              {' ' + o?.from_status?.toUpperCase()}
                            </AppText>
                            {strings('ordering.orderStatusPending.to')}

                            <AppText style={styles.orderStatus}>
                              {' ' + o?.to_status?.toUpperCase()}
                            </AppText>
                          </AppText>
                        ))}
                      </View>
                    </View>
                  </View>
                )}
            </View>
            {/* delivery partner modal */}
            <DeliveryPartnerModal
              orderID={basicOrderDetail?.id}
              isModalVisible={showShippingModal}
              navigation={this.props.navigation}
              scanBarcode={() =>
                this.props.navigation.navigate('scan-delivery-partner', {
                  scanBarcode: this.scanBarcode,
                })
              }
              trackingId={this.state.selfTrackingId}
              updateOrder={(data) => {
                this.setState({
                  showShippingModal: false,
                });
                this.onUpdateStatus(
                  basicOrderDetail?.id,
                  'shipping',
                  'self',
                  data,
                );
              }}
              onClose={() => {
                this.setState({
                  showShippingModal: false,
                });
                this.fetchOrderDetail();
              }}
              onlyClose={() => {
                this.setState({
                  showShippingModal: false,
                });
              }}
            />

            {/* reason modal */}
            <Modal
              visible={showModal}
              transparent
              onRequestClose={() => this.setState({showModal: false})}
            >
              <TouchableOpacity
                onPress={() =>
                  this.setState({
                    showModal: false,
                    statusMessage: '',
                    errorMessage: '',
                  })
                }
                style={{flex: 1}}
              >
                <View style={styles.modalContainer}>
                  <TouchableWithoutFeedback>
                    <View style={styles.modalContent}>
                      <View style={styles.textInputContainer}>
                        <AppText style={styles.modalTitle} required>
                          {strings('ordering.orderStatusPending.reason')}
                        </AppText>
                        <TextInput
                          style={styles.infoText}
                          multiline={true}
                          numberOfLines={3}
                          underlineColorAndroid="transparent"
                          autoCapitalize="none"
                          autoCorrect={false}
                          placeholder={strings(
                            'ordering.orderStatusPending.reasonMessage',
                          )}
                          placeholderTextColor={Colors.color30}
                          returnKeyType="done"
                          value={statusMessage}
                          onChangeText={(text) =>
                            this.setState({statusMessage: text})
                          }
                        />
                        {errorMessage && !statusMessage ? (
                          <AppText
                            style={{
                              color: Colors.wallet_card_red,
                              paddingBottom: 5,
                            }}
                          >
                            {errorMessage}
                          </AppText>
                        ) : null}
                      </View>
                      <View>
                        <TouchableOpacity
                          style={[styles.actionButton, styles.declineBtn]}
                          onPress={() => {
                            if (!statusMessage) {
                              this.setState({
                                errorMessage: strings(
                                  'ordering.orderStatusPending.reasonMessagerequired',
                                ),
                              });
                            } else {
                              if (this.state.declineCancellation) {
                                this.updateCancellationOrder(
                                  basicOrderDetail?.cancellation?.id,
                                  'declined',
                                  this.state?.statusMessage,
                                );
                                this.setState({
                                  showModal: false,
                                  statusMessage: '',
                                  declineCancellation: false,
                                });
                              } else {
                                this.onUpdateStatus(
                                  basicOrderDetail?.id,
                                  'declined',
                                );
                              }
                              this.setState({
                                showModal: false,
                                statusMessage: '',
                              });
                            }
                          }}
                        >
                          <AppText
                            style={{
                              fontFamily: 'Roboto-Bold',
                              color: Colors.white,
                            }}
                          >
                            {strings('ordering.orderStatusPending.declined')}
                          </AppText>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </TouchableWithoutFeedback>
                </View>
              </TouchableOpacity>
            </Modal>
          </MainContainer>
        )}
      </View>
    );
  };
}
export default connect(
  (state) => ({
    user: state.auth.user,
    loading: state.common.loading,
    config: state.config,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setLoading: CommonActions.setLoading,
        openAlert: (content) =>
          CommonActions.setAlert({visible: true, content: content}),
      },
      dispatch,
    ),
)(OrderHistoryDetailScreen);
