import {View, TextInput, Text, Platform, Keyboard} from 'react-native';
import React, {useState, useEffect} from 'react';
import {MainHeader, MainContainer, AppText, LongButton} from '../../components';
import styles from './styles';
import {Colors} from '../../constants';
import {useDispatch, useSelector} from 'react-redux';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {
  getAppConfig,
  updateSocialMediaLinks,
} from '../../redux/reduxsauceActions/configActions';

const SocialMediaLinksScreen = (props) => {
  const {config} = useSelector((state) => state);
  const [socialLinks, setSocialLinks] = useState({
    facebook: config?.social_link?.facebook || '',
    instagram: config?.social_link?.instagram || '',
    twitter: config?.social_link?.twitter || '',
    playStore: config?.social_link?.playStore || '',
    appStore: config?.social_link?.appStore || '',
  });

  // useEffect(() => {
  //   dispatch(getAppConfig());
  // }, []);

  const dispatch = useDispatch();
  const openAlert = (
    content,
    leftButton,
    leftPress,
    rightButton,
    rightPress,
  ) => {
    dispatch(
      CommonActions.setAlert({
        visible: true,
        content,
        leftButton,
        leftPress,
        rightButton,
        rightPress,
      }),
    );
  };

  const onSubmit = () => {
    Keyboard.dismiss();
    console.log(socialLinks);
    dispatch(
      updateSocialMediaLinks(socialLinks, async (action, data) => {
        if (action == 'success') {
          props.navigation.goBack();
        }
      }),
    );
  };
  return (
    <View style={styles.container}>
      <MainHeader
        title={'Social Media Links'}
        leftPress={() => props.navigation.goBack()}
        leftIcon={'back'}
      />
      <MainContainer useView>
        <View style={styles.textInputContainer}>
          <AppText style={styles.inputLabel}>{'Facebook'}</AppText>
          <TextInput
            underlineColorAndroid="transparent"
            autoCorrect={false}
            placeholder={'e.g. https://www.facebook.com/'}
            placeholderTextColor={Colors.color30}
            returnKeyType="done"
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={socialLinks.facebook}
            onChangeText={(text) =>
              setSocialLinks({...socialLinks, facebook: text})
            }
          />
        </View>
        <View style={styles.textInputContainer}>
          <AppText style={styles.inputLabel}>{'Instagram'}</AppText>
          <TextInput
            underlineColorAndroid="transparent"
            autoCorrect={false}
            placeholder={'e.g. https://www.instagram.com/'}
            placeholderTextColor={Colors.color30}
            returnKeyType="done"
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={socialLinks.instagram}
            onChangeText={(text) =>
              setSocialLinks({...socialLinks, instagram: text})
            }
          />
        </View>
        <View style={styles.textInputContainer}>
          <AppText style={styles.inputLabel}>{'Twitter'}</AppText>
          <TextInput
            underlineColorAndroid="transparent"
            autoCorrect={false}
            placeholder={'e.g. https://www.twitter.com/'}
            placeholderTextColor={Colors.color30}
            returnKeyType="done"
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={socialLinks.twitter}
            onChangeText={(text) =>
              setSocialLinks({...socialLinks, twitter: text})
            }
          />
        </View>
        <View style={styles.textInputContainer}>
          <AppText style={styles.inputLabel}>{'Android App Link'}</AppText>
          <TextInput
            underlineColorAndroid="transparent"
            autoCorrect={false}
            placeholder={'e.g. https://play.google.com/store/apps/...'}
            placeholderTextColor={Colors.color30}
            returnKeyType="done"
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={socialLinks.playStore}
            onChangeText={(text) =>
              setSocialLinks({...socialLinks, playStore: text})
            }
          />
        </View>
        <View style={styles.textInputContainer}>
          <AppText style={styles.inputLabel}>{'IOS App Link'}</AppText>
          <TextInput
            underlineColorAndroid="transparent"
            autoCorrect={false}
            placeholder={'e.g. https://apps.apple.com/in/app/happy-eats/id1611294930'}
            placeholderTextColor={Colors.color30}
            returnKeyType="done"
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={socialLinks.appStore}
            onChangeText={(text) =>
              setSocialLinks({...socialLinks, appStore: text})
            }
          />
        </View>
        {Platform.OS == 'web' && (
          <LongButton text={'Save'} onPress={onSubmit} />
        )}
      </MainContainer>
      {Platform.OS != 'web' && <LongButton text={'Save'} onPress={onSubmit} />}
    </View>
  );
};
export default SocialMediaLinksScreen;
