import {StyleSheet} from 'react-native';

import {Colors} from '../../../constants';
import { setValue,} from '../../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
  backgroundColor: Colors.white,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  webContainer: {
    overflow: 'visible',
    height: '100vh',
  },
  TabBarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    marginVertical: setValue(15),
    backgroundColor: Colors.white,
    borderRadius: 5,
    ...shadow,
  },
  TabBarHeader: {
    fontFamily: 'Roboto-Bold',
    fontSize: 15,
  },
  TabBarDescText: {
    color: Colors.black40,
    fontSize: 13,
  },
});
