import {Platform, StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {screenWidth, setValue, setXAxisValue, setYAxisValue} from '../../utils';

let TotalWidth =
  screenWidth > 1023 ? 992 : screenWidth - 2 * setXAxisValue(15) - 2;

export default StyleSheet.create({
  modal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.overlay,
  },
  modalContainer: {
    backgroundColor: Colors.white,
    padding: setValue(5),
    marginHorizontal: setValue(15),
    borderRadius: 5,
    width: '90%',
    maxWidth: setValue(360),
    alignSelf: 'center',
  },
  modalDataContainer: {
    margin: setValue(10),
  },
  modalTitle: {
    paddingBottom: setValue(10),
    fontSize: setValue(18),
    fontFamily: 'Roboto-Bold',
    textAlign: 'center',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 5,
  },
  optionGroupTitle: {
    // marginTop: 5,
    fontSize: setXAxisValue(16),
    color: '#CCCCCC',
  },
  optionGroupDivider: {
    height: 1.5,
    backgroundColor: 'rgba(0,0,0,0.2)',
    flex: 1,
    marginStart: setXAxisValue(10),
  },
  optionGroupDesc: {
    fontSize: setXAxisValue(11),
    color: 'red',
    marginTop: setYAxisValue(5),
    marginBottom: setYAxisValue(10),
  },
  optionItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  optionItemCheckBoxEmpty: {
    width: setValue(24),
    height: setValue(24),
  },
  optionItemText: {
    flex: 1,
    color: '#4D4D4D',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: setXAxisValue(5),
  },
  optionItemSoldOut: {
    color: 'red',
    fontSize: setXAxisValue(9),
  },
  optionItemPrice: {
    color: '#B1B1B1',
  },
  addQuantityButton: {
    flex: 1,
    margin: setValue(3),
    borderWidth: 2,
    borderRadius: 5,
    borderColor: Colors.theme_color,
    backgroundColor: Colors.theme_color,
    padding: 5,
    paddingVertical: 9,
    justifyContent: 'center',
    alignItems: 'center',
  },
  variants: {
    padding: 5,
    paddingHorizontal: 12,
    borderWidth: 2,
    borderColor: '#ccc',
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
    marginRight: 8,
  },
  selectedVariants: {
    padding: 5,
    paddingHorizontal: 12,
    borderWidth: 2,
    backgroundColor: Colors.theme_color,
    borderRadius: 8,
    borderColor: Colors.theme_color,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
    marginRight: 8,
  },
  disabledButton: {
    backgroundColor: Colors.theme_color,
    opacity: 0.5,
  },
});
