import React, {useRef, useState, useEffect} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Animated,
  Button,
  TouchableOpacity,
  Dimensions,
} from 'react-native';
import {AntDesign, Entypo} from '@expo/vector-icons';
import {Colors} from '../../constants';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {useSelector, useDispatch} from 'react-redux';

const Toast = () => {
  const dispatch = useDispatch();
  const {showToast} = useSelector((state) => state.common);
  const windowHeight = Dimensions.get('window').height;
  const [status, setStatus] = useState('success');
  const popAnim = useRef(new Animated.Value(windowHeight * 1)).current;
  const successColor = '#9dcff1';
  const successHeader = 'Success!';
  const [successMessage, setSuccessMessage] = useState('');
  const failColor = Colors.wallet_card_orange;
  const failHeader = 'Failed!';
  const [failMessage, setFailMessage] = useState('');

  const popIn = () => {
    Animated.timing(popAnim, {
      toValue: windowHeight * -0.15 * 1,
      duration: 300,
      useNativeDriver: true,
    }).start(popOut());
  };

  const popOut = () => {
    setTimeout(() => {
      Animated.timing(popAnim, {
        toValue: windowHeight * 1,
        duration: 300,
        useNativeDriver: true,
      }).start();
      setSuccessMessage('');
      setFailMessage('');
    }, 3000);
  };

  const instantPopOut = () => {
    Animated.timing(popAnim, {
      toValue: windowHeight * 1,
      duration: 150,
      useNativeDriver: true,
    }).start();
  };
  useEffect(() => {
    showToast.visible && setSuccessMessage(showToast.content);
    showToast.visible && setFailMessage(showToast.content);
    showToast.visible && popIn();
    showToast.visible &&
      dispatch(CommonActions.setShowToast({visible: false, content: ''}));
  }, [showToast]);

  return (
    <View style={{height: 0}}>
      <Animated.View
        style={[
          styles.toastContainer,
          {
            transform: [{translateY: popAnim}],
          },
        ]}
      >
        <View style={styles.toastRow}>
          <AntDesign
            name={status === 'success' ? 'checkcircleo' : 'closecircleo'}
            size={24}
            color={status === 'success' ? successColor : failColor}
          />
          <View style={styles.toastText}>
            <Text
              style={{fontWeight: 'bold', color: Colors.color50, fontSize: 15}}
            >
              {status === 'success' ? successHeader : failHeader}
            </Text>
            <Text style={{color: Colors.color30, fontSize: 12}}>
              {status === 'success' ? successMessage : failMessage}
            </Text>
          </View>
          <TouchableOpacity onPress={instantPopOut}>
            <Entypo name="cross" size={24} color={Colors.color50} />
          </TouchableOpacity>
        </View>
      </Animated.View>

      {/* <Button
        title="Success Message"
        onPress={() => {
          setStatus('success');
          popIn();
        }}
        style={{marginTop: 30}}
      ></Button>

      <Button
        title="Fail Message"
        onPress={() => {
          setStatus('fail');
          popIn();
        }}
        style={{marginTop: 30}}
      ></Button> */}
    </View>
  );
};
export default Toast;
const styles = StyleSheet.create({
  toastContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  toastRow: {
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 2,
    height: 60,
    backgroundColor: 'rgba(0,0,0,0.7)',
    width: '70%',
    maxWidth: 300,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  toastText: {
    width: '70%',
    padding: 2,
  },
});
