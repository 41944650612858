import React, {useEffect, useState} from 'react';
import {
  Image,
  Keyboard,
  Linking,
  Platform,
  TextInput,
  TouchableOpacity,
  View,
  ScrollView,
  ActivityIndicator,
} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {
  AppText,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
  DropDownInput,
} from '../../../components';
import {Colors} from '../../../constants';
import {strings} from '../../../i18n';
import {goBack, setValue} from '../../../utils';
import styles from './styles';
import * as ImagePicker from 'expo-image-picker';
import {connectActionSheet} from '@expo/react-native-action-sheet';
import axios from 'axios';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {useDispatch, useSelector} from 'react-redux';

const AddNewAccommodation = (props) => {
  const item = props?.route?.params?.item;

  const dispatch = useDispatch();
  const config = useSelector((state) => state?.config);

  const [accommodationDetail, setAccommodationDetail] = useState({
    name: item?.name ? item?.name : '',
    description: item?.description ? item?.description : '',
    price_per_night: item?.price_per_night ? item?.price_per_night : null,
    type: item?.type ? item?.type : '',
    qty_per_day: item?.qty_per_day ? JSON.stringify(item?.qty_per_day) : null,
  });
  const [images, setImages] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageLoadingIndex, setImageLoadingIndex] = useState(null);

  console.log('images--->>', images);

  const onSave = async () => {
    Keyboard.dismiss();
    dispatch(CommonActions.setLoading(true));
    if (accommodationDetail.name.trim() == '') {
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('editAccommodation.alert.nameAlert'),
        }),
      );
      return;
    }
    if (accommodationDetail.price_per_night <= 0) {
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('editAccommodation.alert.priceAlert'),
        }),
      );
      return;
    }
    if (accommodationDetail.type == '') {
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('editAccommodation.alert.typeAlert'),
        }),
      );
      return;
    }
    if (accommodationDetail.qty_per_day == null) {
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('editAccommodation.alert.qtyAlert'),
        }),
      );
      return;
    }
    let body = new FormData();
    body.append('name', accommodationDetail?.name.trim());
    accommodationDetail?.description.trim().length > 0 &&
      body.append('description', accommodationDetail?.description.trim());
    accommodationDetail?.price_per_night.length > 0 &&
      body.append('price_per_night', accommodationDetail?.price_per_night);
    accommodationDetail?.type.trim().length > 0 &&
      body.append('type', accommodationDetail?.type.trim());
    body.append('qty_per_day', accommodationDetail?.qty_per_day);

    const header = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    if (item?.id) {
      await axios
        .patch('/hospitality/accommodation/update/' + item?.id, body, header)
        .then((response) => {
          console.log('response update blog', response);
          dispatch(CommonActions.setLoading(false));
          props.navigation.replace('hospitality');
        })
        .catch((error) => {
          console.log('error update blog --', error?.response);
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: error?.response?.message,
            }),
          );
          dispatch(CommonActions.setLoading(false));
        });
    } else {
      await axios
        .post('/hospitality/accommodation/create', body, header)
        .then((response) => {
          console.log('response of create accommodation', response);
          dispatch(CommonActions.setLoading(false));
          props.navigation.replace('hospitality');
          saveNewAccommodationImages(response?.data?.id);
        })
        .catch((error) => {
          console.log('error create accommodation', error.response);
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: error?.response?.message,
            }),
          );
          dispatch(CommonActions.setLoading(false));
        });
    }
  };

  const dataURLtoFile = (dataurl) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], 'image1.jpg', {type: mime});
  };

  const saveNewAccommodationImages = (id) => {
    dispatch(CommonActions.setLoading(true));

    Promise.all(
      images.map(async (o, index) => {
        console.log('image map o===>>', o);
        let blob = await (await fetch(o.photo)).blob();
        let body = new FormData();
        body.append('accommodation', id);
        Platform.OS == 'web'
          ? body.append('image', dataURLtoFile(o.photo))
          : body.append('image', {
              uri: o.photo,
              type: blob.type,
              name: blob.data.name,
            });

        console.log('body', body);

        return axios.post('/hospitality/accommodation/media/create', body);
      }),
    )
      .then((response) => {
        console.log('response of accommodation media create-->', response);
        dispatch(CommonActions.setLoading(false));
        goBack(navigation);
      })
      .catch((error) => {
        console.log('error of accommodation media create-->', error);
        props.openAlert(error?.response?.message);
        dispatch(CommonActions.setLoading(false));
      });
  };

  const UpdateOldProductImages = async (id, url) => {
    let accommodationId = props.route?.params?.item?.id;
    let blob = await (await fetch(url)).blob();

    if (id) {
      let array = [...images];
      let index = array.findIndex((o) => o.id == id);

      if (index >= 0) {
        setImageLoading(true);
        setImageLoadingIndex(index);
      }

      let body = new FormData();
      body.append('accommodation', accommodationId);
      Platform.OS == 'web'
        ? body.append('image', dataURLtoFile(url))
        : body.append('image', {
            uri: url,
            type: blob.type,
            name: blob.data.name,
          });

      console.log('body-->', body);

      axios
        .put('/hospitality/accommodation/media/update/' + id, body)
        .then((response) => {
          console.log(response.data);
          array[index] = {
            ...array[index],
            ...response.data,
          };
          setImages(array);
          setImageLoading(false);
          setImageLoadingIndex(null);
        })
        .catch((error) => {
          console.log('error -->', error?.response);
          props.openAlert(error?.response?.message);
          props.setLoading(false);
          setImageLoading(false);
          setImageLoadingIndex(null);
        });
    } else {
      let body = new FormData();
      body.append('accommodation', accommodationId);

      Platform.OS == 'web'
        ? body.append('image', dataURLtoFile(url))
        : body.append('image', {
            uri: url,
            type: blob.type,
            name: blob.data.name,
          });
      console.log('body-->', body);
      axios
        .post('/hospitality/accommodation/media/create', body)
        .then((response) => {
          console.log(response);
          let array = [...images];
          array.push(response.data);
          setImages(array);
        })
        .catch((error) => {
          console.log('error -->', error?.response);
          props.openAlert(error?.response?.message);
          props.setLoading(false);
        });
    }
  };
  const deleteImage = (id) => {
    let array = [...images];
    let index = array.findIndex((o) => o.id == id);
    setImageLoading(true);
    setImageLoadingIndex(index);
    axios
      .delete('/hospitality/accommodation/media/deleted/' + id)
      .then((response) => {
        console.log('response delete image', response);
        array.splice(index, 1);
        setImages(array);
        setImageLoading(false);
        setImageLoadingIndex(null);
      })
      .catch((error) => {
        console.log('error delete image', error?.response?.data);
        props.openAlert(error?.response?.data?.detail);
        setImageLoading(false);
        setImageLoadingIndex(null);
      });
  };

  const openActionSheet = (index) => {
    console.log('index', index);
    const options = [
      strings('actionSheet.takePhoto'),
      strings('actionSheet.choosePhoto'),
      strings('actionSheet.removePhoto'),
      strings('actionSheet.cancel'),
    ];
    const cancelButtonIndex = 3;

    props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) openCamera(index);
        if (buttonIndex === 1) openImagePicker(index);
        if (buttonIndex === 2) removePhoto(index);
      },
    );
  };
  const removePhoto = (index) => {
    let array = [...images];

    if (!index && index != 0) {
      return null;
    } else {
      if (props.route?.params?.item) {
        let id = array[index]?.id;
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: 'Are you sure you want to remove this photo?',
            leftButton: strings('common.yes'),
            rightButton: strings('common.no'),
            leftPress: () => {
              deleteImage(id);
            },
            rightPress: () => {
              null;
            },
          }),
        );
      } else {
        array.splice(index, 1);
      }
    }

    setImages(array);
  };

  const openCamera = async (index) => {
    await ImagePicker.requestCameraPermissionsAsync();

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: [4, 3],

      quality: 0.8,
      base64: true,
    });

    if (result.uri) {
      let array = [...images];

      // if function have not any index of image
      if (!index && index != 0) {
        // if function have index and also product is old then call api
        if (props.route?.params?.item) {
          UpdateOldProductImages(null, result.uri);

          // if function have index but product is new
        } else {
          let obj = new Object({
            image: result.uri,
          });
          array.push(obj);
        }

        // if function have any  image index
      } else {
        // for old product
        if (props.route?.params?.item) {
          let id = array[index]?.id;
          UpdateOldProductImages(id, result.uri);

          // for new product
        } else {
          array[index] = {
            ...array[index],
            image: result.uri,
          };
        }
      }
      setImages(array);
    }
  };

  const openImagePicker = async (index) => {
    await ImagePicker.requestMediaLibraryPermissionsAsync();

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      aspect: [4, 3],

      quality: 0.8,
      base64: true,
    });

    console.log('result====>>', result);

    if (result.uri) {
      let array = [...images];

      // if function have not any index of image
      if (!index && index != 0) {
        // if function have index and also product is old then call api
        if (props.route?.params?.item) {
          UpdateOldProductImages(null, result.uri);

          // if function have index but product is new
        } else {
          let obj = new Object({
            image: result.uri,
          });
          array.push(obj);
        }

        // if function have any  image index
      } else {
        // for old product
        if (props.route?.params?.item) {
          let id = array[index]?.id;
          UpdateOldProductImages(id, result.uri);

          // for new product
        } else {
          array[index] = {
            ...array[index],
            image: result.uri,
          };
        }
      }
      setImages(array);
    }
  };

  useEffect(() => {
    item?.images?.length > 0 && setImages(item?.images);
  }, [item]);

  return (
    <View style={styles.container}>
      <MainHeader
        title={item?.id ? 'Edit Accommodation Detail' : 'Add New Accommodation'}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer>
        <KeyboardAwareScrollView>
          <>
            <View style={styles.productStrengthContainer}>
              {images.length > 0 ? (
                <ScrollView
                  showsHorizontalScrollIndicator={false}
                  horizontal
                  centerContent={true}
                >
                  {images.map((o, index) =>
                    imageLoading && imageLoadingIndex == index ? (
                      <TouchableOpacity
                        style={[
                          styles.imageContainer,
                          {
                            width: 200,
                            aspectRatio: 4 / 3,
                          },
                        ]}
                        onPress={() => openActionSheet(index)}
                        key={index}
                      >
                        <ActivityIndicator
                          size="small"
                          color={Colors.theme_color}
                        />
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity
                        style={[
                          styles.imageContainer,
                          {
                            width: 200,
                            aspectRatio: 4 / 3,
                          },
                        ]}
                        onPress={() => openActionSheet(index)}
                        key={index}
                      >
                        <View>
                          <Image
                            source={{uri: o.image}}
                            style={[
                              styles.image,
                              {
                                width: 200,
                                aspectRatio: 4 / 3,
                              },
                            ]}
                          />
                          <TouchableOpacity
                            style={styles.cameraIcon}
                            onPress={() => openActionSheet(index)}
                          >
                            <Icon name="camera" color={Colors.black20} />
                          </TouchableOpacity>
                        </View>
                      </TouchableOpacity>
                    ),
                  )}
                  {images.length < 8 && (
                    <TouchableOpacity
                      style={[
                        styles.imageContainer,
                        {
                          width: 200,
                          aspectRatio: 4 / 3,
                        },
                      ]}
                      onPress={() => openActionSheet(undefined)}
                    >
                      <Icon name="add" size={16} color={Colors.black60} />
                    </TouchableOpacity>
                  )}
                </ScrollView>
              ) : (
                <TouchableOpacity
                  style={[
                    styles.imageContainer,
                    {
                      width: 200,
                      aspectRatio: 4 / 3,
                    },
                  ]}
                  onPress={() => openActionSheet(undefined)}
                >
                  <Icon name="add" size={16} color={Colors.black60} />
                </TouchableOpacity>
              )}
            </View>

            <View style={styles.textInputContainer}>
              <AppText style={styles.title} required>
                {strings('editAccommodation.name.name')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={strings('editAccommodation.placehoder.name')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={styles.textInput}
                value={accommodationDetail?.name}
                onChangeText={(text) =>
                  setAccommodationDetail({
                    ...accommodationDetail,
                    name: text,
                  })
                }
              />
            </View>

            <View style={styles.textInputContainer}>
              <AppText style={styles.title}>
                {strings('addNewBlog.title.description')}
              </AppText>
              <TextInput
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={strings(
                  'editAccommodation.placehoder.description',
                )}
                placeholderTextColor={Colors.color30}
                numberOfLines={3}
                multiline={true}
                returnKeyType="done"
                style={styles.multiLineTextInput}
                value={accommodationDetail?.description}
                onChangeText={(text) =>
                  setAccommodationDetail({
                    ...accommodationDetail,
                    description: text,
                  })
                }
              />
            </View>

            <View style={styles.textInputContainer}>
              <AppText style={styles.title} required>
                {strings('editAccommodation.name.price')}
              </AppText>
              <TextInput
                keyboardType='numeric'
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={strings('editAccommodation.placehoder.price')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={styles.textInput}
                value={accommodationDetail?.price_per_night}
                onChangeText={(text) =>
                  setAccommodationDetail({
                    ...accommodationDetail,
                    price_per_night: text,
                  })
                }
              />
            </View>

            <View style={styles.textInputContainer}>
              <AppText style={styles.title} required>
                {strings('editAccommodation.name.type')}
              </AppText>
              <DropDownInput
                data={[
                  {value: 'room', label: 'Room'},
                  {value: 'villas', label: 'Villas'},
                  {value: 'suite', label: 'Suite'},
                ]}
                value={accommodationDetail?.type}
                inputStyle={[
                  styles.textInput,
                  {
                    paddingHorizontal: 10,
                    marginTop: 10,
                  },
                ]}
                onChangeText={(text) => {
                  setAccommodationDetail({...accommodationDetail, type: text});
                }}
              />
            </View>

            <View style={styles.textInputContainer}>
              <AppText style={styles.title} required>
                {strings('editAccommodation.name.qty')}
              </AppText>
              <TextInput
                keyboardType='numeric'
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                placeholder={strings('editAccommodation.placehoder.qty')}
                placeholderTextColor={Colors.color30}
                returnKeyType="done"
                style={styles.textInput}
                value={accommodationDetail?.qty_per_day}
                onChangeText={(text) => {
                  setAccommodationDetail({
                    ...accommodationDetail,
                    qty_per_day: text,
                  });
                }}
              />
            </View>
          </>

          {Platform.OS == 'web' && (
            <LongButton
              onPress={() => onSave()}
              loading={false}
              text={
                item?.id
                  ? strings('addNewGallery.edit')
                  : strings('editProductCategory.button.save')
              }
              style={{
                marginTop: setValue(20),
                marginBottom: setValue(45),
              }}
            />
          )}
        </KeyboardAwareScrollView>
      </MainContainer>

      {Platform.OS !== 'web' && (
        <LongButton
          onPress={() => onSave()}
          loading={false}
          text={
            item?.id
              ? strings('addNewGallery.edit')
              : strings('editProductCategory.button.save')
          }
          style={{borderRadius: 0, width: '100%'}}
        />
      )}
    </View>
  );
};
const ConnectedAddNewAccommodation = connectActionSheet(AddNewAccommodation);

export default ConnectedAddNewAccommodation;
