import React, {useEffect, useState} from 'react';
import {
  Image,
  Platform,
  TextInput,
  View,
  TouchableOpacity,
  FlatList,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {
  AppText,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
  ProgressiveImage,
  ZoomImage,
  SwipeRow,
  NoRecordFound,
} from '../../components';
import styles from './styles';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import axios from 'axios';
import {FontAwesome, MaterialIcons} from '@expo/vector-icons';
import {goBack, setValue} from '../../utils';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import {connectActionSheet} from '@expo/react-native-action-sheet';
import * as ImagePicker from 'expo-image-picker';

const RatingReview = (props) => {
  const [rattingList, setRattingList] = useState([]);
  const [showReviewSection, setShowReviewSection] = useState(false);
  const [visibleImage, setVisibleImage] = useState(false);
  const [rattingField, setRattingField] = useState({
    ratting: '',
    title: '',
    message: '',
    photo: null,
  });

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const getProductReview = async () => {
    await axios
      .get('/products/comment/list/' + props?.route?.params?.id)
      .then((response) => {
        console.log('response-->', response);
        setRattingList(response?.data);
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        console.log('response-->', response);
        dispatch(CommonActions.setLoading(false));
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.message || 'Something want wrong!',
          }),
        );
      });
  };

  useEffect(() => {
    dispatch(CommonActions.setLoading(true));
    getProductReview();
  }, []);

  const openActionSheet = () => {
    const options = [
      strings('actionSheet.takePhoto'),
      strings('actionSheet.choosePhoto'),
      strings('actionSheet.removePhoto'),
      strings('actionSheet.cancel'),
    ];
    const cancelButtonIndex = 3;

    props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) openCamera();
        if (buttonIndex === 1) openImagePicker();
        if (buttonIndex === 2) removePhoto();
      },
    );
  };

  const removePhoto = () => {
    setRattingField({
      ...rattingField,
      photo: null,
    });
  };

  const openCamera = async () => {
    await ImagePicker.requestCameraPermissionsAsync();

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      setRattingField({
        ...rattingField,
        photo: result.uri,
      });
    }
    if (!result.cancelled) {
    }
  };

  const openImagePicker = async () => {
    await ImagePicker.requestMediaLibraryPermissionsAsync();

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      setRattingField({
        ...rattingField,
        photo: result.uri,
      });
    }
    if (!result.cancelled) {
    }
  };

  const dataURLtoFile = (dataurl) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], 'image1.jpg', {type: mime});
  };

  const submitReview = async () => {
    if (!rattingField?.ratting) {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('rattingAndReview.alert.review'),
        }),
      );
      return;
    }
    if (!rattingField?.title) {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('rattingAndReview.alert.title'),
        }),
      );
      return;
    }
    if (!rattingField?.message) {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('rattingAndReview.alert.message'),
        }),
      );
      return;
    }

    let body = new FormData();

    body.append('ratting', parseFloat(rattingField?.ratting));
    body.append('title', rattingField?.title);
    body.append('message', rattingField?.message);
    body.append('product', props?.route?.params?.id);

    if (rattingField?.photo) {
      let blob = await (await fetch(rattingField?.photo)).blob();
      Platform.OS == 'web'
        ? body.append('photo', dataURLtoFile(rattingField?.photo))
        : body.append('photo', {
            uri: rattingField?.photo,
            type: blob.type,
            name: blob.data.name,
          });
    }

    dispatch(CommonActions.setLoading(true));
    axios
      .post('/products/comment/comment/create', body)
      .then((response) => {
        console.log('response-->', response);
        getProductReview();
        dispatch(CommonActions.setLoading(false));
        setRattingField({
          ratting: '',
          title: '',
          message: '',
          photo: null,
        });
        setShowReviewSection(false);
      })
      .catch((error) => {
        console.log('error-->', error);
        dispatch(CommonActions.setLoading(false));
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.message || 'Something want wrong!',
          }),
        );
      });
  };

  const updateReview = async () => {
    if (!rattingField?.ratting) {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('rattingAndReview.alert.ratting'),
        }),
      );
      return;
    }
    if (!rattingField?.title) {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('rattingAndReview.alert.title'),
        }),
      );
      return;
    }
    if (!rattingField?.message) {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('rattingAndReview.alert.message'),
        }),
      );
      return;
    }

    let body = new FormData();

    body.append('ratting', parseFloat(rattingField?.ratting));
    body.append('title', rattingField?.title);
    body.append('message', rattingField?.message);
    body.append('product', props?.route?.params?.id);

    if (rattingField?.photo) {
      let blob = await (await fetch(rattingField?.photo)).blob();
      Platform.OS == 'web'
        ? body.append('photo', dataURLtoFile(rattingField?.photo))
        : body.append('photo', {
            uri: rattingField?.photo,
            type: blob.type,
            name: blob.data.name,
          });
    }

    dispatch(CommonActions.setLoading(true));
    axios
      .put('/products/comment/update/' + rattingField?.id, body)
      .then((response) => {
        console.log('response-->', response);
        getProductReview();
        dispatch(CommonActions.setLoading(false));
        setRattingField({
          ratting: '',
          title: '',
          message: '',
          photo: null,
        });
        setShowReviewSection(false);
      })
      .catch((error) => {
        console.log('error-->', error);
        dispatch(CommonActions.setLoading(false));
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.message || 'Something want wrong!',
          }),
        );
      });
  };

  const deleteReview = async (id) => {
    dispatch(CommonActions.setLoading(true));
    axios
      .delete('/products/comment/delete/' + id)
      .then((response) => {
        console.log('response-->', response);
        getProductReview();
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        console.log('error-->', error);
        dispatch(CommonActions.setLoading(false));
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.message || 'Something want wrong!',
          }),
        );
      });
  };

  return (
    <View
      style={
        Platform.OS == 'web'
          ? {overflow: 'visible', height: '100vh'}
          : styles.container
      }
    >
      <MainHeader
        title={strings('rattingAndReview.title')}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer useView>
        {rattingList?.length == 0 ? (
          <NoRecordFound
            title={'Ratting and Review'}
            message={'No data found'}
          />
        ) : (
          <FlatList
            data={rattingList}
            showsVerticalScrollIndicator={false}
            keyExtractor={(item, index) => index}
            renderItem={({item, index}) => {
              let ratting = parseFloat(item.ratting);
              let filled = Math.floor(ratting || 0);
              let reviewArray = [];

              if (filled == 0) {
                for (let i = 0; i < 5; i++) {
                  reviewArray.push('star-o');
                }
              }
              if (filled == 5) {
                for (let i = 0; i < 5; i++) {
                  reviewArray.push('star');
                }
              }

              if (filled > 0 && filled < 5) {
                if (ratting > filled) {
                  for (let i = 0; i < filled; i++) {
                    reviewArray.push('star');
                  }
                  reviewArray.push('star-half-empty');
                } else {
                  for (let i = 0; i < filled; i++) {
                    reviewArray.push('star');
                  }
                }
                if (reviewArray.length < 5) {
                  for (let i = reviewArray.length; i < 5; i++) {
                    reviewArray.push('star-o');
                  }
                }
              }

              return (
                // <SwipeRow
                //   editable={false}
                //   rightOpenValue={-80}
                //   onPressDelete={() => {}}
                // >
                <CommentComp
                  item={item}
                  index={index}
                  reviewArray={reviewArray}
                  visibleImage={visibleImage}
                  setVisibleImage={(value) => {
                    setVisibleImage(value);
                  }}
                />
                // </SwipeRow>
              );
            }}
          />
        )}
      </MainContainer>
    </View>
  );
};

const CommentComp = (props) => {
  const {reviewArray, item, index, visibleImage, setVisibleImage} = props;

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  return (
    <View key={index} style={styles.card}>
      <View style={styles.userDetailContainer}>
        <View>
          <ProgressiveImage
            thumbnailSource={
              item?.user?.photo
                ? {uri: item?.user?.photo}
                : require('../../../assets/images/General/profile.png')
            }
            source={
              item?.user?.photo
                ? {uri: item?.user?.photo}
                : require('../../../assets/images/General/profile.png')
            }
            style={styles.image}
          />
        </View>
        <View>
          <AppText
            style={{marginBottom: 5, textTransform: 'capitalize'}}
            numberOfLines={1}
          >
            {item?.user?.name}
          </AppText>
          <View style={{flexDirection: 'row'}}>
            {reviewArray.map((o, index) => (
              <FontAwesome
                name={o}
                key={index}
                size={24}
                color="#ffbe3b"
                style={{marginRight: 5}}
              />
            ))}
          </View>
        </View>
      </View>
      <View>
        <AppText style={styles.ratingTitle}>{item?.title}</AppText>
        <AppText style={styles.ratingMessage}>{item?.message}</AppText>
      </View>
      {item?.photo && (
        <TouchableOpacity
          style={{marginTop: 10}}
          onPress={() => setVisibleImage(true)}
        >
          <ProgressiveImage
            thumbnailSource={{uri: item?.photo}}
            source={{uri: item?.photo}}
            style={{
              width: setValue(100),
              height: setValue(100),
              borderRadius: setValue(5),
            }}
            resizeMode="contain"
          />
        </TouchableOpacity>
      )}

      <ZoomImage
        imageUrls={item?.photo?.length > 0 && [{url: item?.photo}]}
        visible={visibleImage}
        handleClose={() => {
          setVisibleImage(false);
        }}
      />
    </View>
  );
};

const ConnectedEditNotification = connectActionSheet(RatingReview);
export default ConnectedEditNotification;
