import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {
  FlatList,
  TouchableOpacity,
  View,
  Linking,
  TextInput,
  Platform,
  ActivityIndicator,
} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';

import {
  AppText,
  DatePicker,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
  NoRecordFound,
  MerchantOrderItem,
} from '../../components';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import {
  goBack,
  isDateValid,
  screenHeight,
  screenWidth,
  setValue,
} from '../../utils';
import styles from './styles';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import moment from 'moment';
import {get} from 'lodash';

const ReportScreen = (props) => {
  // local states
  const [isDateVisible, setIsDateVisible] = useState(false);
  const [isDateVisibleTo, setIsDateVisibleTo] = useState(false);
  const [reportDateFrom, setReportDateFrom] = useState('');
  const [reportDateTo, setReportDateTo] = useState('');
  const [reportDateType, setReportDateType] = useState('from');
  const [reportList, setReportList] = useState([]);
  const [fromDate, setFromDate] = useState({dd: '', mm: '', yy: ''});
  const [toDate, setToDate] = useState({dd: '', mm: '', yy: ''});

  // redux state and dispatch mathod
  const common = useSelector((state) => state.common);
  const config = useSelector((state) => state.config);
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);
  const limit = 8;
  const [count, setCount] = useState(0);
  const currency_symbol = get(config, 'currencyCode', '₹');

  const getReportList = async (text) => {
    if (reportList.length == 0) {
      dispatch(CommonActions.setLoading(true));
    }
    let url = '/order/merchant/list';

    if (reportDateFrom != '' && Platform.OS != 'web') {
      if (url.includes('?')) {
        url += '&start_created_at=' + reportDateFrom;
      } else {
        url += '?start_created_at=' + reportDateFrom;
      }
    }
    if (Platform.OS == 'web' && isDateValid(reportDateFrom)) {
      if (url.includes('?')) {
        url += '&start_created_at=' + reportDateFrom;
      } else {
        url += '?start_created_at=' + reportDateFrom;
      }
    }
    if (Platform.OS == 'web' && isDateValid(reportDateTo)) {
      if (url.includes('?')) {
        url += '&end_created_at=' + reportDateTo;
      } else {
        url += '?end_created_at=' + reportDateTo;
      }
    }
    if (reportDateTo != '' && Platform.OS != 'web') {
      if (url.includes('?')) {
        url += '&end_created_at=' + reportDateTo;
      } else {
        url += '?end_created_at=' + reportDateTo;
      }
    }
    if (limit && limit != '') {
      if (url.includes('?')) {
        url += '&limit=' + limit + '&offset=' + offset;
      } else {
        url += '?limit=' + limit + '&offset=' + offset;
      }
    }

    await axios
      .get(url)
      .then((response) => {
        console.log('called ---------->', response?.data);
        dispatch(CommonActions.setLoading(false));
        setCount(response?.data?.count || 0);
        setReportList([...reportList, ...response?.data?.results]);
      })
      .catch((error) => {
        dispatch(CommonActions.setLoading(false));
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.message,
          }),
        );
      });
  };

  useEffect(() => {
    getReportList();
  }, []);

  let objectToCSVRow = function (dataObject) {
    delete dataObject.business;
    delete dataObject.deleted;
    delete dataObject.promotion;
    delete dataObject.delivery_information;
    delete dataObject.special_instructions;
    delete dataObject.status_message;
    delete dataObject.user;
    delete dataObject.payment_refund_key;
    let addres = dataObject.address;
    let newAddres = null;
    if (addres?.type) {
      newAddres = addres?.type + ' : ';

      if (addres?.street) {
        newAddres += addres?.street;
      }
      if (addres?.area) {
        newAddres += ' ' + addres?.area;
      }
      if (addres?.city) {
        newAddres += ' ' + addres?.city;
      }
      if (addres?.state) {
        newAddres += ' ' + addres?.state;
      }
      if (addres?.country) {
        newAddres += ' ' + addres?.country;
      }
      if (addres?.pincode) {
        newAddres += '-' + addres?.pincode;
      }
    }
    dataObject.address = newAddres;
    let dataArray = new Array();
    for (let o in dataObject) {
      let innerValue = dataObject[o] === null ? '' : dataObject[o].toString();
      let result = innerValue.replace(/"/g, '""');
      dataArray.push('' + result + '');
    }
    return dataArray.join(', ') + '\r\n';
  };

  let exportToCSV = function (arrayOfObjects) {
    if (arrayOfObjects.length == 0) {
      return;
    }

    let csvContent = 'data:text/csv;charset=utf-8,';

    // headers
    let newList = [];
    let allKeys = Object.keys(arrayOfObjects[0]);
    for (let i = 0; i < allKeys.length; i++) {
      const element = allKeys[i];
      if (
        ![
          'business',
          'deleted',
          'offer',
          'delivery_information',
          'special_instructions',
          'status_message',
          'user',
          'payment_refund_key',
        ].includes(element)
      ) {
        newList.push(element);
      }
    }

    csvContent += objectToCSVRow(newList);

    arrayOfObjects.forEach(function (item) {
      csvContent += objectToCSVRow(item);
    });

    let encodedUri = encodeURI(csvContent);
    Linking.openURL(encodedUri);
  };
  const countTotalIncome = () => {
    let totalIncome = 0;

    reportList.map((o) => {
      totalIncome += parseFloat(o.total);
    });

    return totalIncome;
  };
  useEffect(() => {
    getReportList();
  }, [offset]);

  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('reports.title')}
        leftIcon={'back'}
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer useView>
        {/* ------------------------ Report From Date------------------ */}
        <View style={styles.filterByDateContainer}>
          <AppText style={{fontFamily: 'Roboto-Bold'}}>
            {strings('reports.date.from')}
          </AppText>
          {isDateVisible && !(reportDateFrom == '' || !reportDateFrom) ? (
            <View
              style={{flexDirection: 'row', justifyContent: 'space-between'}}
            >
              <AppText style={styles.date}>
                {(reportDateFrom &&
                  moment(reportDateFrom).format('yyyy-MM-DD')) ||
                  strings('reports.date.filterDate')}
              </AppText>
              <TouchableOpacity
                onPress={() => {
                  if (reportDateFrom != '') {
                    setIsDateVisible(false);
                    setReportDateFrom('');
                  } else {
                    setIsDateVisible(true);
                  }
                }}
              >
                <Icon
                  name={reportDateFrom == '' ? 'cross' : 'calendar'}
                  size={18}
                  color={Colors.black20}
                />
              </TouchableOpacity>
            </View>
          ) : (
            <DatePicker
              title={
                <AppText style={styles.date}>
                  {(reportDateFrom &&
                    moment(reportDateFrom).format('yyyy-MM-DD')) ||
                    strings('reports.date.filterDate')}
                </AppText>
              }
              showCross={reportDateFrom != ''}
              visible={isDateVisible}
              maxDate={new Date()}
              onClose={() => {
                setIsDateVisible(false);
              }}
              onDayPress={(date) => {
                setReportDateFrom(date?.dateString);
                setReportDateTo('');
                setIsDateVisible(false);
              }}
              onCancelPress={() => {
                setIsDateVisible(false);
              }}
              navigation={props.navigation}
              onSetDataForWeb={setReportDateFrom}
              onCalenderPress={() => {
                if (reportDateFrom != '') {
                  setIsDateVisible(false);
                  setReportDateFrom('');
                } else {
                  setIsDateVisible(true);
                }
              }}
            />
          )}
        </View>

        {/* ------------------------ Report to Date------------------ */}
        <View style={styles.filterByDateContainer}>
          <AppText style={{fontFamily: 'Roboto-Bold'}}>
            {strings('reports.date.to')}
          </AppText>
          {isDateVisibleTo && !(reportDateTo == '' || !reportDateTo) ? (
            <View
              style={{flexDirection: 'row', justifyContent: 'space-between'}}
            >
              <AppText style={styles.date}>
                {(reportDateTo && moment(reportDateTo).format('yyyy-MM-DD')) ||
                  strings('reports.date.filterDate')}
              </AppText>
              <TouchableOpacity
                onPress={() => {
                  if (reportDateTo != '') {
                    setIsDateVisibleTo(false);
                    setReportDateTo('');
                  } else {
                    setIsDateVisibleTo(true);
                  }
                }}
              >
                <Icon
                  name={reportDateTo.toString() ? 'cross' : 'calendar'}
                  size={18}
                  color={Colors.black20}
                />
              </TouchableOpacity>
            </View>
          ) : (
            <DatePicker
              title={
                <AppText style={styles.date}>
                  {(reportDateTo &&
                    moment(reportDateTo).format('yyyy-MM-DD')) ||
                    strings('reports.date.filterDate')}
                </AppText>
              }
              visible={isDateVisibleTo}
              minDate={
                reportDateFrom &&
                reportDateFrom != '' &&
                new Date(reportDateFrom)
              }
              maxDate={new Date()}
              onClose={() => {
                setIsDateVisibleTo(false);
              }}
              onDayPress={(date) => {
                setIsDateVisibleTo(false);
                setReportDateTo(date?.dateString);
              }}
              showCross={reportDateTo != ''}
              navigation={props.navigation}
              onCancelPress={() => {
                setIsDateVisibleTo(false);
              }}
              onSetDataForWeb={setReportDateTo}
              onCalenderPress={() => {
                if (reportDateTo != '') {
                  setIsDateVisibleTo(false);
                  setReportDateTo('');
                } else {
                  setIsDateVisibleTo(true);
                }
              }}
            />
          )}
        </View>

        {/*get report button  */}
        <LongButton
          text={strings('reports.button.getReports')}
          onPress={async () => {
            setReportList([]);
            if (offset !== 0) {
              setOffset(0);
            } else {
              getReportList();
            }
          }}
        />

        {/* Total income container */}
        {false && (
          <View style={styles.totalIncomeContainer}>
            <AppText style={{fontFamily: 'Roboto-Bold'}}>
              {strings('reports.totalIncome')}:
            </AppText>
            <AppText>{currency_symbol + ' ' + countTotalIncome()}</AppText>
          </View>
        )}

        {common?.loading ? null : reportList.length == 0 ? (
          <NoRecordFound
            title={strings('reports.noRecord.title')}
            message={strings('reports.noRecord.message')}
          />
        ) : (
          <View style={{paddingBottom: setValue(20), flex: 1}}>
            <FlatList
              showsVerticalScrollIndicator={false}
              style={{paddingBottom: setValue(5)}}
              data={reportList}
              onEndReachedThreshold={0.5}
              ListFooterComponent={
                <View style={styles.loadingContainer}>
                  {count > reportList.length && (
                    <ActivityIndicator color={Colors.theme_color} size={25} />
                  )}
                </View>
              }
              onEndReached={() => {
                console.log('callled ------>', offset + limit);
                setOffset(offset + limit);
              }}
              renderItem={({item}) => (
                <MerchantOrderItem
                  item={item}
                  navigation={props.navigation}
                  from="report"
                />
              )}
            />
          </View>
        )}
        {reportList?.length > 0 && Platform.OS == 'web' && (
          <LongButton
            onPress={() => exportToCSV(reportList)}
            style={{bottom: screenWidth < 576 ? 25 : 10}}
            text={strings('reports.exportCSV')}
          />
        )}
      </MainContainer>
      {false && Platform.OS != 'web' && (
        <LongButton
          onPress={() => exportToCSV(reportList)}
          // style={{borderRadius: 0}}
          text={strings('reports.exportCSV')}
        />
      )}
    </View>
  );
};

export default ReportScreen;
