import axios from 'axios';
import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import {
  AppText,
  MainHeader,
  LongButton,
  Icon,
  MainContainer,
} from '../../../components';
import {goBack} from '../../../utils';
import styles from './styles';
import {strings} from '../../../i18n';

const AccommodationSettingList = (props) => {
  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('accommodationSetting.title')}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer useView>
        <TouchableOpacity
          style={styles.settingView}
          onPress={() => {
            props?.navigation.navigate('experience-list');
          }}
        >
          <AppText style={styles.title}>
            {strings('accommodationSetting.card.experience')}
          </AppText>
          <Icon name="forward" size={20} />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.settingView}
          onPress={() => {
            props?.navigation.navigate('features-list');
          }}
        >
          <AppText style={styles.title}>
            {strings('accommodationSetting.card.features')}
          </AppText>
          <Icon name="forward" size={20} />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.settingView}
          onPress={() => {
            props?.navigation.navigate('rate-code-list');
          }}
        >
          <AppText style={styles.title}>
            {"Rate code"}
          </AppText>
          <Icon name="forward" size={20} />
        </TouchableOpacity>
      </MainContainer>
    </View>
  );
};
export default AccommodationSettingList;
