import {Camera} from 'expo-camera';
import {
  View,
  Platform,
  TouchableOpacity,
  TextInput,
  Text,
  Image,
  Modal,
} from 'react-native';
import React, {useEffect, useRef, useState} from 'react';
import {
  AppText,
  Icon,
  SelectProductVeriantsModal,
  LongButton,
  SwipeRow,
  MainContainer,
  MainHeader,
  DropDownInput,
} from '../../../components';
import {goBack, isPhoneNumberValid, setValue} from '../../../utils';
import styles from './styles';
import {strings} from '../../../i18n';
import {Colors} from '../../../constants';
import axios from 'axios';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {useDispatch, useSelector} from 'react-redux';
import {getProducts} from '../../../redux/reduxsauceActions/productActions';
import {get, set} from 'lodash';
import PhoneInput from '../../../lib/react-native-phone-input';

const EditInvoice = (props) => {
  const [selectedCustomerDetail, setSelectedCustomerDetail] = useState(null);
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [varientProduct, setVarientProduct] = useState(null);
  const [isCustomerModalVisible, setIsCustomerModalVisible] = useState(false);

  const products = useSelector((state) => state.products?.products);
  const config = useSelector((state) => state.config);
  const countryCode = useSelector((state) => state.common.countryCode);
  let currencySymbol = get(config, 'currencySymbol', '₹');
  let phoneInput = useRef();

  const iconColor = Colors.white;

  const dispatch = useDispatch();
  const [customerDetail, setCustomerDetail] = useState({
    customer_name: '',
    customer_email: '',
    customer_phone: '+91',
    customer_gender: 'Male',
  });
  const [fieldRequire, setFieldRequire] = useState(false);
  useEffect(() => {
    dispatch(getProducts());
  }, []);
  const [isUser, setIsUser] = useState(false);

  useEffect(() => {
    const barcode = props.route.params?.barcode;
    if (barcode) {
      const selectedItem = products.find((a) => a.barcode == barcode);
      if (!selectedItem) {
        return openAlert('This product does not exists');
      }
      if (selectedItem?.variants?.length > 0) {
        setVarientProduct(selectedItem);
        setIsModalVisible(true);
      } else {
        selectedProducts({products: [selectedItem]});
      }
    }
  }, [props.route.params]);

  const openAlert = (
    content,
    leftButton,
    leftPress,
    rightButton,
    rightPress,
  ) => {
    dispatch(
      CommonActions.setAlert({
        visible: true,
        content,
        leftButton,
        leftPress,
        rightButton,
        rightPress,
      }),
    );
  };

  const checkCameraPermission = async () => {
    const {status} = await Camera.requestCameraPermissionsAsync();

    if (status === 'granted') {
      props.navigation.navigate('scan-product-barcode', {
        navigate: 'add-offline-order',
      });
    } else {
      openAlert(strings('common.permision'));
    }
  };

  const selectedCustomer = async (data) => {
    setSelectedCustomerDetail(data?.detail);
    setIsUser(data.isUser ? true : false);
  };

  const selectedProducts = (data) => {
    if (selectedProductList?.length > 0) {
      data?.products?.map((i) => {
        if (
          selectedProductList.find((o) =>
            i?.selectedVariants && i?.selectedVariants?.length > 0
              ? o.id == i.id &&
                JSON.stringify(i?.selectedVariants) ==
                  JSON.stringify(o?.selectedVariants)
              : o.id == i.id,
          )
        ) {
          onAddQuantity(i?.id, i?.selectedVariants);
        } else {
          setSelectedProductList([...selectedProductList, {...i, qty: 1}]);
        }
      });
    } else {
      let array = data?.products;
      array = array?.map(
        (o) =>
          (o = {
            ...o,
            qty: parseInt(o.qty || 0) + 1,
          }),
      );
      console.log('first array', array);
      setSelectedProductList([...array]);
    }
  };

  const onAddQuantity = (id, verients) => {
    let array = [...selectedProductList];
    const findIndex = verients
      ? array.findIndex(
          (o) =>
            o.id == id &&
            JSON.stringify(verients) == JSON.stringify(o?.selectedVariants),
        )
      : array.findIndex((o) => o.id == id);

    if (
      !array[findIndex]?.always_available_stock &&
      array[findIndex]?.qty >= array[findIndex]?.stock_qty
    ) {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: "You can't add more quantity.",
        }),
      );
      return;
    }
    array[findIndex] = {
      ...array[findIndex],
      qty: array[findIndex]?.qty + 1,
    };
    console.log('array', array);
    setSelectedProductList(array);
  };
  const onSaveModalData = () => {
    if (!selectedCustomerDetail?.id) {
      if (customerDetail?.customer_name == '') {
        setFieldRequire(true);
        setTimeout(() => {
          setFieldRequire(false);
        }, 3000);
        return;
      }
    }

    setSelectedCustomerDetail({
      name: customerDetail.customer_name || '',
      email: customerDetail.customer_email || '',
      phone: isPhoneNumberValid(customerDetail.customer_phone)
        ? customerDetail.customer_phone
        : '-',
      gender: customerDetail.customer_gender || 'Male',
      tax: customerDetail.customer_tax || '0',
    });
    setIsCustomerModalVisible(false);
  };
  const onRemoveQuantity = (id, verients) => {
    let array = [...selectedProductList];
    const findIndex = verients
      ? array.findIndex(
          (o) =>
            o.id == id &&
            JSON.stringify(verients) == JSON.stringify(o?.selectedVariants),
        )
      : array.findIndex((o) => o.id == id);

    if (array[findIndex]?.qty == 1) {
      array = array.filter((o) => o.id != id);
      console.log('newArray', array);
      setSelectedProductList(array);

      return;
    }
    array[findIndex] = {
      ...array[findIndex],
      qty: array[findIndex]?.qty - 1,
    };
    console.log('array', array);
    setSelectedProductList(array);
  };

  const getTotalAmount = () => {
    let totalAmount = 0;
    if (selectedProductList?.length > 0) {
      totalAmount = selectedProductList
        .map((o) => Number(parseInt(o?.final_price || o?.price) * o?.qty))
        .reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0,
        );
      console.log('totalAmount', totalAmount);
    }
    return totalAmount.toFixed(2);
  };

  let total = getTotalAmount();

  const onCreateOrder = async () => {
    console.log('selectedCustomerDetail', !selectedCustomerDetail);
    if (!selectedCustomerDetail) {
      if (customerDetail?.customer_name == '') {
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: strings('editInvoice.alert.nameAlert'),
          }),
        );
        return;
      }
    }

    if (selectedProductList?.length == 0) {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('editInvoice.alert.productAlert'),
        }),
      );
      return;
    }
    try {
      dispatch(CommonActions.setLoading(true));

      let products = selectedProductList?.map(
        (o) =>
          new Object({
            product: o.id,
            qty: parseInt(o?.qty),
            price: parseFloat(o?.price),
            selected_variants: o?.selectedVariants ? o.selectedVariants : null,
            final_price: o?.final_price || o?.price,
          }),
      );

      let body = {
        total: getTotalAmount(),
        order_total: getTotalAmount(),
        business: config.businessId,
        products,
        payment: 'cash',
      };
      if (selectedCustomerDetail?.id && isUser) {
        body = {
          ...body,
          user:
            isUser && selectedCustomerDetail
              ? selectedCustomerDetail?.id
              : null,
        };
      } else if (selectedCustomerDetail?.id) {
        body = {
          ...body,
          customer: selectedCustomerDetail ? selectedCustomerDetail?.id : null,
        };
      } else {
        body = {
          ...body,
          customer: {
            name: customerDetail?.customer_name,
            email: customerDetail?.customer_email,
            phone: isPhoneNumberValid(customerDetail.customer_phone)
              ? customerDetail.customer_phone
              : '-',
            gender: customerDetail?.customer_gender.toLowerCase(),
          },
        };
      }
      const response = await axios.post('/invoice/create', body);
      console.log('response', response);
      dispatch(CommonActions.setLoading(false));
      setCustomerDetail({
        customer_name: '',
        customer_email: '',
        customer_phone: '+91',
      });
      setSelectedCustomerDetail(null);
      setSelectedProductList([]);
      setVarientProduct(null);
      props.navigation.replace('offline-order-detail', {
        id: response?.data?.id,
      });
    } catch (error) {
      console.log('error', error.response);
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message || 'Something went wrong :(',
        }),
      );
    }
  };

  const renderCartItem = (item, index) => {
    return (
      <SwipeRow
        leftOpenValue={70}
        rightOpenValue={-70}
        onPressDelete={() => {
          let newProductList = [...selectedProductList];
          const findIndex = item?.selectedVariants
            ? newProductList.findIndex(
                (o) =>
                  o.id == item?.id &&
                  JSON.stringify(item?.selectedVariants) ==
                    JSON.stringify(o?.selectedVariants),
              )
            : newProductList.findIndex((o) => o.id == item?.id);

          newProductList.splice(findIndex, 1);
          setSelectedProductList(newProductList);
        }}
        key={index}
        item={item}
      >
        <View style={styles.cartContainer}>
          <Image source={{uri: item?.images[0]?.photo}} style={styles.image} />
          <View style={styles.cartItem}>
            <View style={{flexDirection: 'row'}}>
              <Text style={styles.itemName}>
                {item?.name + ' '}
                {item.selectedVariants &&
                  item.selectedVariants?.length != 0 && (
                    <Text style={styles.variantsText}>
                      {'(' +
                        item?.selectedVariants?.map((o) => o.value).join(', ') +
                        ')'}
                    </Text>
                  )}
              </Text>
            </View>
            <Text style={{paddingVertical: 5}}>
              {currencySymbol + ' '}
              {item?.final_price || item?.price} {'* '}
              {parseInt(item?.qty)}
              {` = ` +
                ((item?.final_price || item?.price) * item?.qty).toFixed(2)}
            </Text>
            <View style={styles.buttonContainer}>
              <TouchableOpacity
                style={styles.addRemoveQtyButton}
                onPress={() => {
                  onAddQuantity(item.id, item?.selectedVariants);
                }}
              >
                <Icon name="add" color={iconColor} />
              </TouchableOpacity>
              <Text style={styles.itemQtyText}>{Number(item.qty)}</Text>
              <TouchableOpacity
                style={styles.addRemoveQtyButton}
                onPress={() => {
                  onRemoveQuantity(item.id, item?.selectedVariants);
                }}
              >
                <Icon name="minus" color={iconColor} />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </SwipeRow>
    );
  };

  return (
    <View style={styles.container}>
      <SelectProductVeriantsModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        item={varientProduct}
        addToProduct={(item) => {
          selectedProducts({products: [item]});
          setIsModalVisible(false);
        }}
      />
      <Modal
        visible={isCustomerModalVisible}
        onClose={() => setIsCustomerModalVisible(false)}
        onRequestClose={() => setIsCustomerModalVisible(false)}
        onDismiss={() => setIsCustomerModalVisible(false)}
        transparent
      >
        <View style={styles.modalOverlay}>
          <View style={styles.modalContainer}>
            <AppText style={styles.modalHeader}>
              {strings('editInvoice.createCustomerForm.title')}
            </AppText>
            <View style={styles.cardContent}>
              <View style={styles.inputStyleContainer}>
                <AppText style={styles.label} required>
                  {strings('editInvoice.customerDetailCard.name')}
                </AppText>
                <TextInput
                  style={styles.inputStyle}
                  value={customerDetail?.customer_name}
                  onChangeText={(text) => {
                    setCustomerDetail({
                      ...customerDetail,
                      customer_name: text,
                    });
                  }}
                />
              </View>
              <View style={styles.inputStyleContainer}>
                <AppText style={styles.label}>
                  {strings('profileScreen.gender')}
                </AppText>
                <DropDownInput
                  inputStyle={[
                    styles.inputStyle,
                    {
                      marginTop: 5,
                    },
                  ]}
                  data={['Male', 'Female']}
                  value={customerDetail?.customer_gender}
                  onChangeText={(text) => {
                    setCustomerDetail({
                      ...customerDetail,
                      customer_gender: text,
                    });
                  }}
                />
              </View>
              <View style={styles.inputStyleContainer}>
                <AppText style={styles.label}>
                  {strings('editInvoice.customerDetailCard.email')}
                </AppText>
                <TextInput
                  style={styles.inputStyle}
                  value={customerDetail?.customer_email}
                  onChangeText={(text) => {
                    setCustomerDetail({
                      ...customerDetail,
                      customer_email: text,
                    });
                  }}
                />
              </View>
              <View style={styles.inputStyleContainer}>
                <AppText style={styles.label}>
                  {strings('editInvoice.customerDetailCard.mobileNo')}
                </AppText>
                <PhoneInput
                  ref={(input) => (phoneInput = input)}
                  initialCountry={countryCode}
                  style={[styles.inputStyle, {marginTop: 5}]}
                  value={customerDetail?.customer_phone}
                  onSelectCountry={() => {
                    setCustomerDetail({
                      ...customerDetail,
                      customer_phone: `+${phoneInput.getCountryCode()}`,
                    });
                  }}
                  inputStyle={{
                    height: setValue(50),
                    justifyContent: 'center',
                  }}
                  textProps={{
                    underlineColorAndroid: 'transparent',
                    autoCapitalize: 'none',
                    autoCorrect: false,
                    keyboardType: 'phone-pad',
                    returnKeyType: 'done',
                    value: customerDetail?.customer_phone,
                    onChangeText: (text) =>
                      setCustomerDetail({
                        ...customerDetail,
                        customer_phone: text,
                      }),
                  }}
                />
              </View>
              <View style={styles.inputStyleContainer}>
                <AppText style={styles.label}>
                  {strings('editInvoice.customerDetailCard.tax')}
                </AppText>
                <TextInput
                  style={styles.inputStyle}
                  value={customerDetail?.customer_tax}
                  keyboardType={'numeric'}
                  placeholder={'0'}
                  placeholderTextColor={Colors.black40}
                  onChangeText={(text) => {
                    setCustomerDetail({
                      ...customerDetail,
                      customer_tax: text,
                    });
                  }}
                />
              </View>
              {fieldRequire && (
                <View style={styles.modalRequireMessage}>
                  <AppText
                    style={{
                      color: Colors.wallet_card_red,
                      fonstSize: 12,
                      textAlign: 'center',
                    }}
                  >
                    Please enter name.
                  </AppText>
                </View>
              )}
              <View style={styles.modalButtonContainer}>
                <TouchableOpacity
                  onPress={onSaveModalData}
                  style={[styles.modalButton, {marginRight: 7}]}
                >
                  <AppText style={styles.modalButtonText}>SAVE</AppText>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setIsCustomerModalVisible(false);
                  }}
                  style={[styles.modalButtonCancel, {marginLeft: 7}]}
                >
                  <AppText
                    style={[
                      styles.modalButtonText,
                      {color: Colors.theme_color},
                    ]}
                  >
                    {'CANCEL'}
                  </AppText>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Modal>
      <MainHeader
        title={strings('editInvoice.title')}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer>
        <View style={{flex: 1}}>
          <View style={[styles.buttonContainerForProduct, {marginBottom: 5}]}>
            <TouchableOpacity
              onPress={() =>
                props.navigation.navigate('select-customer', {
                  selectedCustomer: selectedCustomer,
                  selectedCustomerDetail: selectedCustomerDetail,
                })
              }
              style={[
                styles.basicCard,
                {
                  flex: 1,
                  marginRight: setValue(7),
                },
              ]}
            >
              <AppText style={styles.addProduct}>
                {strings('editInvoice.customerButton.selectCustomer')}
              </AppText>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setIsCustomerModalVisible(true)}
              style={[
                styles.basicCard,
                {
                  flex: 1,
                  marginLeft: setValue(7),
                },
              ]}
            >
              <AppText style={styles.addProduct}>
                {strings('editInvoice.customerButton.newCustomer')}
              </AppText>
            </TouchableOpacity>
          </View>
          {
            <View style={styles.basicCard}>
              <View style={styles.headerItems}>
                <AppText style={styles.headerText}>
                  {strings('editInvoice.customerDetailCard.title')}
                </AppText>
                <TouchableOpacity
                  style={styles.headerBtn}
                  onPress={() => {
                    setCustomerDetail({
                      customer_name: '',
                      customer_email: '',
                      customer_phone: '+91',
                    });
                    selectedCustomerDetail
                      ? setSelectedCustomerDetail(null)
                      : null;
                  }}
                >
                  {selectedCustomerDetail && (
                    <AppText style={{color: Colors.wallet_card_red}}>
                      Clear
                    </AppText>
                  )}
                </TouchableOpacity>
              </View>
              <View style={styles.border} />
              <View style={styles.cardContent}>
                <View style={styles.customerField}>
                  <AppText style={styles.fieldLabel}>
                    {strings('editInvoice.customerDetailCard.name')}
                  </AppText>
                  {selectedCustomerDetail?.first_name ? (
                    <AppText>
                      {selectedCustomerDetail?.first_name +
                        ' ' +
                        selectedCustomerDetail?.last_name}
                    </AppText>
                  ) : (
                    <AppText>
                      {selectedCustomerDetail?.name || 'Unknown'}
                    </AppText>
                  )}
                </View>
                <View style={styles.customerField}>
                  <AppText style={styles.fieldLabel}>
                    {strings('editInvoice.customerDetailCard.email')}
                  </AppText>
                  <AppText>{selectedCustomerDetail?.email || '-'}</AppText>
                </View>
                <View style={styles.customerField}>
                  <AppText style={styles.fieldLabel}>
                    {strings('editInvoice.customerDetailCard.mobileNo')}
                  </AppText>
                  <AppText>{selectedCustomerDetail?.phone || '-'}</AppText>
                </View>
                <View style={styles.customerField}>
                  <AppText style={styles.fieldLabel}>
                    {strings('editInvoice.customerDetailCard.tax')}
                  </AppText>
                  <AppText>{selectedCustomerDetail?.tax || '-'}</AppText>
                </View>
              </View>
            </View>
          }

          <View style={styles.buttonContainerForProduct}>
            <TouchableOpacity
              onPress={() =>
                props.navigation.navigate('select-products', {
                  selectedProducts: selectedProducts,
                })
              }
              style={[
                styles.basicCard,
                {
                  flex: 1,
                  marginRight: setValue(7),
                },
              ]}
            >
              <AppText style={styles.addProduct}>
                {strings('editInvoice.customerButton.searchProducts')}
              </AppText>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                checkCameraPermission();
              }}
              style={[
                styles.basicCard,
                {
                  flex: 1,
                  marginLeft: setValue(7),
                },
              ]}
            >
              <AppText style={styles.addProduct}>
                {strings('editInvoice.customerButton.scanQR')}
              </AppText>
            </TouchableOpacity>
          </View>
          <View style={{marginBottom: setValue(7)}}>
            {selectedProductList?.length > 0 &&
              selectedProductList.map((item, index) => {
                return renderCartItem(item, index);
              })}
          </View>
        </View>
        {Platform.OS == 'web' && (
          <>
            <View style={styles.basicCard}>
              <View style={{padding: 15, flexDirection: 'row'}}>
                <AppText style={{flex: 1, fontFamily: 'Roboto-Bold'}}>
                  {strings('editInvoice.total')}
                </AppText>
                <AppText style={{fontFamily: 'Roboto-Bold'}}>
                  {config?.currencySymbol || '₹ ' + getTotalAmount()}
                </AppText>
              </View>
            </View>
            <LongButton
              text={strings('settingScreen.clientSetting.save')}
              style={{marginTop: setValue(10), marginBottom: setValue(45)}}
              onPress={onCreateOrder}
            />
          </>
        )}
      </MainContainer>
      {Platform.OS !== 'web' && (
        <View>
          <View style={{padding: 15, flexDirection: 'row'}}>
            <AppText style={{flex: 1, fontFamily: 'Roboto-Bold'}}>
              Total
            </AppText>
            <AppText style={{fontFamily: 'Roboto-Bold'}}>
              {config?.currencySymbol || '₹ ' + getTotalAmount()}
            </AppText>
          </View>
          <LongButton
            text={strings('settingScreen.clientSetting.save')}
            style={{
              width: '100%',
              borderRadius: 0,
            }}
            onPress={onCreateOrder}
          />
        </View>
      )}
    </View>
  );
};

export default EditInvoice;
