import {Platform, StyleSheet} from 'react-native';

import {Colors} from '../../../constants';
import {
  screenWidth,
  setValue,
  setXAxisValue,
  setYAxisValue,
} from '../../../utils';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  webContainer: {
    overflow: 'visible',
    height: '100vh',
  },
  orderSettingContainer: {
    flex: 1,
  },
  textInputContainer: {
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 5,
    borderRadius: 5,
    marginBottom: setYAxisValue(10),
  },
  inputLabel: {
    fontFamily: 'Roboto-Bold',
    marginLeft: setYAxisValue(10),
    fontSize: setXAxisValue(15),
    color: Colors.black60,
    marginTop: setYAxisValue(5),
  },
  textInput: {
    height: setValue(50),
    color: Colors.black60,
    paddingHorizontal: setXAxisValue(10),
  },
  textInputWeb: {
    height: setValue(50),
    padding: setValue(10),
    paddingHorizontal: setXAxisValue(10),
  },
  deliveryRist: {
    marginBottom: setYAxisValue(10),
    fontFamily: 'Roboto-Bold',
    color: Platform.OS == 'web' ? Colors.black : Colors.white,
    paddingHorizontal: setXAxisValue(2),
    fontSize: setValue(17),
  },
  settingView: {
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 3,
    backgroundColor: Colors.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: setYAxisValue(20),
    paddingHorizontal: setXAxisValue(10),
    borderRadius: 8,
    alignItems: 'center',
    marginBottom: setYAxisValue(10),
  },
  title: {
    fontFamily:"Roboto-Regular",
    fontWeight:"500",
    fontSize: setXAxisValue(14),
    color: Colors.black60,
  },
});
