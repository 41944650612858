import React, {useEffect, useState} from 'react';
import {
  Image,
  Platform,
  Linking,
  Text,
  View,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
} from 'react-native';
import {Colors} from '../../constants';
import {
  DropDownInput,
  Icon,
  MainContainer,
  MainHeader,
  OrderItem,
} from '../../components';
import {goBack, setValue} from '../../utils';
import styles from './styles';
import {useDispatch, useSelector} from 'react-redux';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import axios from 'axios';
import moment from 'moment';
import {NoRecordFound} from '../../components';
import {strings} from '../../i18n';

export default function CustomerDetail(props) {
  const {customerID, memberType} = props.route?.params;
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [assignRollType, setAssignRollType] = useState('');
  const auth = useSelector((state) => state.auth);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingForOder, setLoadingForOder] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [offset, setOffset] = useState(0);
  const limit = 10;
  const dispatch = useDispatch();

  const getCustomerDetail = () => {
    setLoading(true);
    axios
      .get('/user/details/' + customerID)
      .then((response) => {
        console.log(("response user detail", response?.data?.data));
        setUserData(response?.data?.data);
        setAssignRollType(response?.data?.data?.type);
        setLoading(false);
      })
      .catch((error) => {
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.message || 'Something went worg :(',
          }),
        );
        setLoading(false);
      });
  };

  const getCustomerOrderList = async (offsets = offset) => {
    const url = `/order/user/${customerID}/list?offset=${orderList.length}&limit=${limit}`;
    await axios
      .get(url)
      .then((response) => {
        console.log('respoise ======', response);
        setTotalCount(response?.data?.count);
        setOrderList(orderList.concat(response?.data?.results));
        setLoadingForOder(false);
      })
      .catch((error) => {
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.message || 'Something went worg :(',
          }),
        );
        setLoadingForOder(false);
      });
  };
  const appConfig = useSelector((state) => state.config);
  useEffect(() => {
    getCustomerDetail();
    getCustomerOrderList();
  }, [props.route?.params]);

  const onSeeOrderDetail = (item) => {
    props.navigation.navigate('order-history-detail', {
      id: item.id, // TODO_CHANGE
    });
  };
  const onSeeOrderHistory = (item) => {
    props.navigation.navigate('order-history-detail', {
      id: item.id, // TODO_CHANGE
    });
  };
  const renderFooterLoading = () => {
    return orderList.length == totalCount ? null : (
      <View style={{alignItems: 'center', justifyContent: 'center'}}>
        <ActivityIndicator color={Colors.theme_color} size={20} />
      </View>
    );
  };
  return (
    <View style={styles.container}>
      <MainHeader
        title={
          memberType == 'Staff Members'
            ? strings('customersDetail.memberTitle')
            : strings('customersDetail.title')
        }
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      {loading ? (
        <MainContainer useView>
          <View style={styles.loadingContainer}>
            <ActivityIndicator color={Colors.theme_color} size={'large'} />
          </View>
        </MainContainer>
      ) : (
        <MainContainer useView>
          <View style={styles.customerProfileCard}>
            <View style={styles.imageContainer}>
              <Image
                style={styles.image}
                source={
                  userData?.photo && userData?.photo !== null
                    ? {uri: userData?.photo}
                    : require('../../../assets/images/General/profile.png')
                }
              />
            </View>
            <View style={styles.customerDetailsContainer}>
              <Text style={styles.customerName}>
                {userData?.first_name + ' ' + userData?.last_name}
              </Text>
              <Text style={styles.customerInfo}>{userData?.email}</Text>
              <Text style={styles.customerInfo}>{userData?.phone}</Text>
              {/* <View style={styles.customerDetailsContainer}> */}
              <Text style={styles.customerAbout}>{`Joined Date : ${moment(
                userData?.date_joined,
              ).format('DD MMM YYYY')} `}</Text>
              {/* </View> */}
            </View>
          </View>
          <View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {assignRollType !== 'merchant' && (
                <TouchableOpacity style={[styles.callButton, {marginRight: 5}]}>
                  <DropDownInput
                    inputStyle={{
                      marginVertical: 7,
                      marginHorizontal: 10,
                      width: setValue(140),
                    }}
                    iconStyle={{marginTop: 2}}
                    customBackgroundStyle={styles.drpDownBackground}
                    iconColor={Colors.black60}
                    data={[
                      {
                        label: strings('customersDetail.dropDown.client'),
                        value: 'client',
                      },
                      {
                        label: strings('customersDetail.dropDown.manager'),
                        value: 'manager',
                      },
                      {
                        label: strings('customersDetail.dropDown.boy'),
                        value: 'deliveryboy',
                      },
                    ]}
                    value={assignRollType}
                    onChangeText={async (itemValue) => {
                      // console.log('itemValue', itemValue);
                      setAssignRollType(itemValue);
                      dispatch(CommonActions.setLoading(true));
                      try {
                        const response = await axios.patch(
                          `/user/update/role/${customerID}`,
                          {type: itemValue},
                        );
                        console.log('response', response);
                        dispatch(CommonActions.setLoading(false));
                      } catch (error) {
                        dispatch(CommonActions.setLoading(false));
                        console.log('error', error?.response?.message);
                        dispatch(
                          CommonActions.setAlert({
                            visible: true,
                            content: error?.response?.message,
                          }),
                        );
                      }
                    }}
                  />
                </TouchableOpacity>
              )}
              <TouchableOpacity
                style={[styles.callButton, styles.callButtonOutline]}
                onPress={() =>
                  Platform.OS == 'android'
                    ? Linking.openURL(`tel:${userData?.phone}`)
                    : Platform.OS == 'ios'
                    ? Linking.openURL(`tel://${userData?.phone}`)
                    : null
                }
              >
                <Text style={[styles.callButtonText, {color: Colors.black}]}>
                  <Icon name="phone" />
                </Text>
                <Text style={[styles.callButtonText, {color: Colors.black}]}>
                  CALL
                </Text>
              </TouchableOpacity>
            </View>
          </View>
          {loadingForOder ? (
            <View
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ActivityIndicator color={Colors.theme_color} size={45} />
            </View>
          ) : orderList.length == 0 ? (
            <NoRecordFound
              title={strings('customersDetail.noRecord.title')}
              message={strings('customersDetail.noRecord.message')}
              hideImage={true}
            />
          ) : (
            <View style={{flex: 1, marginTop: 10}}>
              <FlatList
                data={orderList}
                renderItem={({item, index}) => (
                  <OrderItem
                    key={index}
                    item={item}
                    tab={0}
                    navigation={props.navigation}
                    onSeeOrderHistory={onSeeOrderHistory}
                    onSeeOrderDetail={onSeeOrderDetail}
                  />
                )}
                keyExtractor={({item, index}) => index}
                onEndReached={() => {
                  if (orderList.length < 7) {
                    return;
                  }
                  orderList.length > limit - 1 &&
                    totalCount != orderList.length &&
                    getCustomerOrderList(offset);
                }}
                onEndReachedThreshold={0.5}
                ListFooterComponent={renderFooterLoading}
              />
            </View>
          )}
          {/* <View style={styles.customerMoreContainer}>
            <View style={styles.customerMore}>
              <Text style={styles.moreText}>20</Text>
              <Text style={styles.moreSubText}>ORDERS</Text>
            </View>
            <View style={styles.customerMore}>
              <Text style={styles.moreText}>15k</Text>
              <Text style={styles.moreSubText}>FOLLOWERS</Text>
            </View>
            <View style={styles.customerMore}>
              <Text style={styles.moreText}>265</Text>
              <Text style={styles.moreSubText}>FOLLOWING</Text>
            </View>
          </View> */}
        </MainContainer>
      )}
    </View>
  );
}
