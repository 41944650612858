import {Platform, StyleSheet} from 'react-native';

import {Colors, Layout} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black60,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 5,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  itemName: {
    marginLeft: setXAxisValue(30),
    color: Colors.primary_color,
    marginBottom: setYAxisValue(10),
  },
  image: {
    width: '100%',
    height: undefined,
    aspectRatio: 3 / 2,
    borderRadius: setValue(10),
    backgroundColor: 'white',
  },
  descriptionText: {
    color: '#5D5D5D',
    fontSize: 12,
    lineHeight: 17,
    paddingVertical: 2,
  },
  sectionContainer: {
    flexDirection: 'row',
    backgroundColor: 'white',
    borderRadius: setValue(10),
    marginTop: setYAxisValue(15),
    paddingVertical: setYAxisValue(15),
    paddingHorizontal: setXAxisValue(15),
    elevation: 3,
    ...shadow,
    // marginBottom: 20,
  },
  otherSectionContainer: {
    backgroundColor: Colors.white,
    borderRadius: setValue(10),
    marginTop: setYAxisValue(14),
    paddingVertical: setYAxisValue(15),
    marginBottom: 10,
    paddingHorizontal: setXAxisValue(15),
    ...shadow,
  },
  summaryDescContainer: {
    flex: 1,
    marginTop: 15,
    backgroundColor: Colors.white,
    padding: 15,
    borderRadius: 10,
    marginBottom: 10,
    ...shadow,
  },
  discountText: {
    paddingTop: setValue(3),
    color: Colors.color30,
    fontSize: setValue(14),
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  summaryPrice: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  summaryPriceText: {
    fontSize: setXAxisValue(20),
    fontFamily: 'Roboto-Bold',
    marginBottom: setValue(5),
  },
  optionItemSoldOut: {
    color: 'red',
    fontSize: setXAxisValue(9),
  },
  specificationContainer: {
    flexDirection: 'row',
    paddingVertical: 5,
    width: '100%',
  },
  specificationKeys: {
    fontFamily: 'Roboto-Bold',
    width: '35%',
  },
  specificationValues: {
    width: '65%',
  },
  pointStyle: {
    borderRadius: 100,
    borderWidth: setValue(3),
    borderColor: 'black',
    alignSelf: 'center',
  },
});
