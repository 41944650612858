import {StyleSheet} from 'react-native';

import {Colors} from '../../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../../utils';

const wrapper = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 8,
  padding: setValue(5),
  backgroundColor: Colors.white,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  imageContainer: {
    width: setValue(80),
    height: setValue(80),
    borderRadius: setValue(80) / 2,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 8,
    backgroundColor: Colors.white_gray,
  },
  image: {
    width: setValue(80),
    height: setValue(80),
    borderRadius: setValue(80) / 2,
  },
  profileStrengthContainer: {
    ...wrapper,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    borderTopRightRadius: setValue(90) / 2,
    borderBottomRightRadius: setValue(90) / 2,
    marginBottom: setYAxisValue(20),
  },
  profileStrengthBody: {
    marginLeft: setXAxisValue(10),
  },
  profileStrengthText: {
    fontSize: setXAxisValue(14),
    color: Colors.color30,
    //
    marginVertical: setYAxisValue(5),
  },
  percentText: {
    fontSize: setXAxisValue(35),
    color: Colors.theme_color,
    // fontFamily: ''
  },
  basicInfoContainer: {
    ...wrapper,
    borderRadius: 8,
    padding: setValue(15),
  },
  infoView: {
    flexDirection: 'row',
  },
  infoTitle: {
    width: '40%',
    fontSize: setXAxisValue(12),
    color: Colors.color30,
    paddingBottom: setYAxisValue(20),
  },
  infoText: {
    width: '60%',
    fontSize: setXAxisValue(12),
    //
    paddingBottom: setYAxisValue(20),
  },
  infoBody: {
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  thirdPartyContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  thirdPartyText: {
    fontSize: setXAxisValue(12),
    color: Colors.color30,
    marginVertical: setYAxisValue(10),
  },
  connectedText: {
    fontSize: setXAxisValue(12),
    //
    color: Colors.facebook,
    marginLeft: setYAxisValue(15),
  },
  ANAContainer: {
    marginTop: setYAxisValue(20),
  },
  hiddenButton: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    padding: setValue(20),
    marginTop: setYAxisValue(5),
  },
  standaloneRowFront: {
    ...wrapper,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 8,
    padding: setValue(15),
    marginTop: setYAxisValue(5),
    flexDirection: 'row',
  },
  addIcon: {
    width: setValue(28),
    height: setValue(28),
    borderRadius: setValue(28) / 2,
    backgroundColor: Colors.theme_color,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerAddress: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginVertical: setYAxisValue(15),
  },
  addressHeader: {
    fontSize: setXAxisValue(14),
    //
    color: Colors.color30,
  },
  title: {
    fontSize: setXAxisValue(14),
    //
    marginBottom: setYAxisValue(5),
  },
  content: {
    fontSize: setXAxisValue(14),
    color: Colors.black40,
  },
  appleContainer: {
    width: setXAxisValue(30),
    height: setXAxisValue(30),
    backgroundColor: Colors.color30,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    marginLeft: setYAxisValue(10),
  },
});
