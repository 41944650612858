import {useIsFocused} from '@react-navigation/native';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {
  FlatList,
  Linking,
  Platform,
  TouchableOpacity,
  View,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {
  AppText,
  CheckBox,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../../components';
import {Colors} from '../../../constants';
import {goBack, setValue} from '../../../utils';
import styles from './styles';
import {AntDesign} from '@expo/vector-icons';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import { getAppConfig } from '../../../redux/reduxsauceActions/configActions';

export default function SelectDefaultTemplate(props) {
  const isFocused = useIsFocused();
  const common = useSelector((state) => state?.common);
  const config = useSelector((state) => state?.config);
  const dispatch = useDispatch();

  const [invoiceList, serInvoiceList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const getInvoicesList = async () => {
    dispatch(CommonActions.setLoading(true));
    await axios
      .get('/lookup/list/template/' + config?.businessId)
      .then((response) => {
        console.log('response template list ->', response.data);
        serInvoiceList([...response.data]);
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        console.log('error invoice Template List', error?.response);
        dispatch(CommonActions.setLoading(false));
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.message || 'Something went wrong!',
          }),
        );
      });
  };

  useEffect(() => {
    config?.invoice?.defaultTemplate && setSelectedTemplate(config?.invoice?.defaultTemplate);
    getInvoicesList();
  }, []);

  const onSave = async() => {
    if (
      !selectedTemplate ||
      selectedTemplate == null ||
      selectedTemplate == undefined
    ) {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: 'Please select any template!',
        }),
      );
      return;
    }
    dispatch(CommonActions.setLoading(true));

    try {
      const response = await axios.patch(
        `/business/update/business-setting/${config?.businessId}`,
        {
          invoice: {
            ...config?.invoice,
            defaultTemplate: selectedTemplate,
          },
        },
      );
      console.log("change invoice setting -->", response);
      dispatch(getAppConfig());
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: "Invoice configuration saved successfully",
        }),
      );
      goBack(props.navigation);
    } catch (error) {
      dispatch(CommonActions.setLoading(false));
      console.log('error', error);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message || 'Something went wrong :(',
        }),
      );
    }
  };

  return (
    <View style={styles.container}>
      <MainHeader
        title={'Select Default Template'}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer useView={Platform.OS === 'web' ? false : true}>
        <FlatList
          data={invoiceList}
          showsVerticalScrollIndicator={false}
          keyExtractor={(item, index) => index}
          renderItem={({item, index}) => {
            return (
              <View style={styles.settingView} key={index}>
                <View style={{flexDirection: 'row', alignItems: 'center', width:"80%"}}>
                  <View style={{marginRight: 10}}>
                    <CheckBox
                      checked={selectedTemplate == item.id ? true : false}
                      onChange={(value) => {
                        value && setSelectedTemplate(item.id);
                        !value && setSelectedTemplate(null);
                      }}
                    />
                  </View>
                  <AppText style={{}}>{item.name}</AppText>
                </View>
                <TouchableOpacity
                  onPress={() =>
                    Linking.openURL(
                      axios.defaults.baseURL +
                        '/lookup/template/preview/' +
                        config?.businessId +
                        '/' +
                        item.id,
                    )
                  }
                  style={{width:"10%"}}
                >
                  <AntDesign name="eye" size={24} color={Colors.color30} />
                </TouchableOpacity>
              </View>
            );
          }}
        />

        {Platform.OS == 'web' && (
          <LongButton
            text={'Save'}
            style={{marginTop: setValue(10), marginBottom: setValue(45)}}
            onPress={() => {
              console.log('call');
              onSave()
            }}
          />
        )}
      </MainContainer>
      {Platform.OS != 'web' && (
        <LongButton
          text={'Save'}
          style={{
            width: '100%',
            borderRadius: 0,
          }}
          onPress={() => {
            console.log('call');
            onSave()
          }}
        />
      )}
    </View>
  );
}
