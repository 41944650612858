import React, {useState, useEffect} from 'react';
import {
  FlatList,
  Image,
  Platform,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import {
  AppText,
  CheckBox,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../components';
import {strings} from '../../i18n';
import {goBack, setValue, setYAxisValue} from '../../utils';
import styles from './styles';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {Colors} from '../../constants';
import {useIsFocused} from '@react-navigation/native';
import {get} from 'lodash';

const NimbusOrderCreate = (props) => {
  const {partnerId, orderId} = props?.route.params;

  const [details, setDetails] = useState({
    weight: '',
    length: '',
    breadth: '',
    height: '',
    pickup_location: null,
    courier: null,
  });
  const [pickupLocationList, setPickupLocationList] = useState([]);
  const [courierAvailable, setCourierAvailable] = useState(false);
  const [courierCompList, setCourierCompList] = useState([]);

  const config = useSelector((state) => state.config);
  let currencySymbol = get(config, 'currencySymbol', '₹');
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const getPickupLocations = () => {
    dispatch(CommonActions.setLoading(true));
    axios
      .get('/delivery/pickup/location/list')
      .then((response) => {
        console.log('pickup locations response \n', response);
        setPickupLocationList(response?.data);
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        console.log('pickup locations error \n', error?.response);
        dispatch(CommonActions.setLoading(false));
      });
  };

  const checkInputField = () => {
    if (details?.weight.trim() == '' || parseFloat(details?.weight) == 0) {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: 'Weight is required!',
        }),
      );
      return;
    }
    if (details?.length.trim() == '' || parseFloat(details?.length) == 0) {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: 'Length is required!',
        }),
      );
      return;
    }
    if (details?.breadth.trim() == '' || parseFloat(details?.breadth) == 0) {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: 'Breadth is required!',
        }),
      );
      return;
    }
    if (details?.height.trim() == '' || parseFloat(details?.height) == 0) {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: 'Height is required!',
        }),
      );
      return;
    }
    if (
      details?.pickup_location == null ||
      details?.pickup_location == undefined
    ) {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: 'Please select any pickup location!',
        }),
      );
      return;
    }
    if (courierAvailable) {
      if (details?.courier == null || details?.courier == undefined) {
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: 'Please select any courier partner!',
          }),
        );
        return;
      }
    }
    courierAvailable ? createOrder() : checkAvailabilities();
  };
  const createOrder = () => {
    console.log('call create order');
    dispatch(CommonActions.setLoading(true));
    axios
      .post('/delivery/create/order/', {
        order: orderId,
        delivery_partner: partnerId,
        pickup_location: details.pickup_location,
        courier: details?.courier,
        weight: parseFloat(details.weight),
        length: parseFloat(details.length),
        breadth: parseFloat(details.breadth),
        height: parseFloat(details.height),
      })
      .then((response) => {
        console.log('create order response \n', response);
        if (response?.data?.status == true) {
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: "Order created on nimbus post.",
            }),
          );
          props.navigation.goBack();
        } else {
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: response?.data?.message || 'Something want wrong!',
            }),
          );
        }
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        console.log('create order error \n', error?.response);
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.data?.message || 'Something want wrong!',
          }),
        );
        dispatch(CommonActions.setLoading(false));
      });
  };

  const checkAvailabilities = () => {
    dispatch(CommonActions.setLoading(true));
    axios
      .post('/delivery/courier/serviceability/', {
        order: orderId,
        delivery_partner: partnerId,
        pickup_location: details.pickup_location,
        weight: parseFloat(details.weight),
      })
      .then((response) => {
        console.log('check availability response \n', response);
        if (response?.data?.status == true) {
          setCourierAvailable(true);
          setCourierCompList(response?.data?.data);
        } else {
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: response?.data?.message || 'Something want wrong!',
            }),
          );
        }
        dispatch(CommonActions.setLoading(false));
      })
      .catch((error) => {
        console.log('check availability error \n', error?.response);
        dispatch(CommonActions.setLoading(false));
      });
  };

  useEffect(() => {
    partnerId && orderId && isFocused && getPickupLocations();
  }, [isFocused]);

  return (
    <View style={{flex: 1}}>
      <MainHeader
        title={strings('nimbusDetailScreen.pageTitle')}
        leftIcon={'back'}
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer>
        <View>
          <Image
            source={require('../../../assets/images/General/nimbuspost.jpg')}
            style={styles.imageLogo}
            resizeMode="center"
          />
        </View>
        <View style={styles.textInputContainer}>
          <AppText style={styles.inputLabel} required>
            {strings('shipRocketDeliveryScreen.label.weight')}
          </AppText>
          <TextInput
            underlineColorAndroid="transparent"
            autoCorrect={false}
            editable={courierAvailable ? false : true}
            returnKeyType="done"
            keyboardType={'number-pad'}
            placeholder={strings('shipRocketDeliveryScreen.placeholder.weight')}
            placeholderTextColor={Colors.color30}
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={details?.weight}
            onChangeText={(text) =>
              setDetails({
                ...details,
                weight: text,
              })
            }
          />
        </View>
        <View style={styles.textInputContainer}>
          <AppText style={styles.inputLabel} required>
            {strings('shipRocketDeliveryScreen.label.courierLength')}
          </AppText>
          <TextInput
            underlineColorAndroid="transparent"
            autoCorrect={false}
            editable={courierAvailable ? false : true}
            keyboardType={'number-pad'}
            returnKeyType="done"
            placeholder={strings(
              'shipRocketDeliveryScreen.placeholder.courierLength',
            )}
            placeholderTextColor={Colors.color30}
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={details?.length}
            onChangeText={(text) => setDetails({...details, length: text})}
          />
        </View>

        <View style={styles.textInputContainer}>
          <AppText style={styles.inputLabel} required>
            {strings('shipRocketDeliveryScreen.label.breadth')}
          </AppText>
          <TextInput
            underlineColorAndroid="transparent"
            autoCorrect={false}
            editable={courierAvailable ? false : true}
            keyboardType={'number-pad'}
            returnKeyType="done"
            placeholder={strings(
              'shipRocketDeliveryScreen.placeholder.breadth',
            )}
            placeholderTextColor={Colors.color30}
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={details?.breadth}
            onChangeText={(text) => setDetails({...details, breadth: text})}
          />
        </View>

        <View style={styles.textInputContainer}>
          <AppText style={styles.inputLabel} required>
            {strings('shipRocketDeliveryScreen.label.height')}
          </AppText>
          <TextInput
            underlineColorAndroid="transparent"
            autoCorrect={false}
            editable={courierAvailable ? false : true}
            keyboardType={'number-pad'}
            placeholder={strings('shipRocketDeliveryScreen.placeholder.height')}
            placeholderTextColor={Colors.color30}
            returnKeyType="done"
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={details?.height}
            onChangeText={(text) => setDetails({...details, height: text})}
          />
        </View>

        <View style={styles.addressContainer}>
          <AppText style={styles.addressContainerLabel} required>
            {'Select Pickup Location'}
          </AppText>
          <FlatList
            data={pickupLocationList}
            keyExtractor={(item, index) => String(index)}
            nestedScrollEnabled={true}
            renderItem={({item, index}) => (
              <TouchableOpacity
                style={styles.address}
                key={index}
                onPress={() => {
                  setDetails({
                    ...details,
                    pickup_location: item?.id,
                  });
                }}
                disabled={courierAvailable ? true : false}
              >
                <View style={styles.radioContainer}>
                  <AppText style={styles.radio}>
                    <CheckBox
                      disabled={courierAvailable ? true : false}
                      checked={details?.pickup_location === item?.id}
                      onChange={(checked) => {
                        setDetails({
                          ...details,
                          pickup_location: item?.id,
                        });
                      }}
                    />
                  </AppText>
                </View>
                <View style={{marginHorizontal: 10}}>
                  <View>
                    <AppText style={styles.addressType}>
                      {item?.type?.charAt(0).toUpperCase() +
                        item?.type?.slice(1)}
                    </AppText>
                  </View>
                  <View>
                    <Text style={styles.location}>
                      {item?.street}, {item?.area}
                    </Text>
                  </View>
                  <View>
                    <Text style={styles.location}>
                      {item?.city}, {item?.state}, {item?.country} -{' '}
                      {item?.pincode}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            )}
            ListFooterComponent={
              <View style={{marginTop: setValue(5)}}>
                <TouchableOpacity
                  style={styles.addressBtn}
                  onPress={() => {
                    props.navigation.navigate('edit-pickup-location');
                  }}
                >
                  <AppText style={styles.addressBtnText}>
                    {strings('placeOrderScreen.button.addNewAddress')}
                  </AppText>
                </TouchableOpacity>
              </View>
            }
          />
        </View>

        {courierAvailable && courierCompList.length > 0 && (
          <View style={styles.addressContainer}>
            <AppText style={styles.addressContainerLabel} required>
              {'Select Courier Partner'}
            </AppText>

            <FlatList
              data={courierCompList.sort(
                (a, b) => a.total_charges - b.total_charges,
              )}
              keyExtractor={(item, index) => index.toString()}
              nestedScrollEnabled={true}
              renderItem={({item, index}) => {
                return (
                  <View style={styles.itemContainer} key={index}>
                    <View style={{width: '75%'}}>
                      <Text style={styles.CompanyName} numberOfLines={1}>
                        {item?.name}
                      </Text>
                      <Text
                        numberOfLines={1}
                        style={{fontFamily: 'Roboto-Regular'}}
                      >
                        {'Min weight' + ' : ' + item?.min_weight + ' gm'}
                      </Text>
                      <Text
                        numberOfLines={1}
                        style={{fontFamily: 'Roboto-Regular'}}
                      >
                        {strings('shipRocketDeliveryScreen.charges') +
                          ' : ' +
                          currencySymbol +
                          ' ' +
                          item?.total_charges}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: '20%',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                      }}
                    >
                      <CheckBox
                        checked={item.id === details?.courier}
                        onChange={(checked) => {
                          setDetails({
                            ...details,
                            courier: item?.id,
                          });
                        }}
                      />
                    </View>
                  </View>
                );
              }}
            />
          </View>
        )}

        {Platform.OS == 'web' && (
          <LongButton
            text={courierAvailable ? 'Order Now' : 'Check Serviceability'}
            style={{marginTop: setValue(20), marginBottom: setValue(45)}}
            onPress={() => checkInputField()}
          />
        )}
      </MainContainer>
      {Platform.OS != 'web' && (
        <LongButton
          text={courierAvailable ? 'Order Now' : 'Check Serviceability'}
          style={{width: '100%', borderRadius: 0}}
          onPress={() => checkInputField()}
        />
      )}
    </View>
  );
};

export default NimbusOrderCreate;
