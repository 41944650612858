import axios from 'axios';
import React, {useState, useRef, useEffect} from 'react';
import {View, TextInput, ActivityIndicator} from 'react-native';
import {useDispatch} from 'react-redux';

import {
  AppText,
  LongButton,
  MainContainer,
  MainHeader,
  Icon,
} from '../../components';
import {strings} from '../../i18n';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {verifyOTP} from '../../redux/reduxsauceActions/authActions';
import {goBack, setYAxisValue} from '../../utils';
import styles from './styles';

const VerifyOtpScreen = props => {
  const otp1 = useRef();
  const otp2 = useRef();
  const otp3 = useRef();
  const otp4 = useRef();
  const otp5 = useRef();
  const otp6 = useRef();

  const dispatch = useDispatch();
  const [otp, setOtp] = useState({});
  const [userOTP, setUserOTP] = useState('');

  const {navigation} = props;
  const [loading, setLoading] = useState(false);

  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(0);

  const onChangeOtp = (text, val, perv, next) => {
    var temperoryOtp = otp;
    if (perv && (text == '' || !text)) {
      perv.current.focus();
    }
    if (next && !(text == '' || !text)) {
      next.current.focus();
    }
    temperoryOtp[val] = text[0];
    setOtp(temperoryOtp);
    if (!next && !(text == '' || !text)) {
      var fullOtp = '';
      Object.values(otp).forEach(a => (fullOtp += a));
      setUserOTP(fullOtp);
      // onSubmitData(fullOtp);
    }
  };

  const onOpenAlert = data =>
    dispatch(
      CommonActions.setAlert({
        visible: true,
        content: data,
      }),
    );

  const onResendOtp = async () => {
    dispatch(CommonActions.setLoading(true));
    const body = {
      business: props?.route?.params?.user?.business,
      username:
        props?.route?.params?.user?.email != ''
          ? props?.route?.params?.user?.email
          : props?.route?.params?.user?.phone,
    };
    await axios
      .post('/user/resend-verification-otp', body)
      .then(response => {
        onOpenAlert(response?.data?.message);
        dispatch(CommonActions.setLoading(false));
      })
      .catch(error => {
        onOpenAlert(error?.response?.message);
        dispatch(CommonActions.setLoading(false));
      });
      setMinutes(2)
  };

  const onSubmitData = async () => {
    const {user} = props.route.params;
    if (userOTP && userOTP.length == 6) {
      dispatch(verifyOTP({otp: userOTP, id: user?.id},navigation));
    } else {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: 'Invalid OTP, Please check your OTP',
        }),
      );
    }
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <View style={styles.container}>
      <MainHeader
        title={'Verify OTP'}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer showLogo>
        <View style={styles.informationContainer}>
          <View style={styles.textInputContainer}>
            <View style={{flexDirection: 'row'}}>
              <TextInput
                selectTextOnFocus
                editable={!loading}
                keyboardType={'numeric'}
                maxLength={1}
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                // autoCorrect={false}
                style={styles.textInput}
                returnKeyType="done"
                onChangeText={text => {
                  onChangeOtp(text, 0, undefined, otp2);
                }}
                ref={otp1}
              />
              <TextInput
                selectTextOnFocus
                editable={!loading}
                keyboardType={'numeric'}
                maxLength={1}
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                // autoCorrect={false}
                style={styles.textInput}
                returnKeyType="done"
                onChangeText={text => {
                  onChangeOtp(text, 1, otp1, otp3);
                }}
                ref={otp2}
              />
              <TextInput
                selectTextOnFocus
                editable={!loading}
                keyboardType={'numeric'}
                maxLength={1}
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                // autoCorrect={false}
                style={styles.textInput}
                returnKeyType="done"
                onChangeText={text => {
                  onChangeOtp(text, 2, otp2, otp4);
                }}
                ref={otp3}
              />
              <TextInput
                selectTextOnFocus
                keyboardType={'numeric'}
                editable={!loading}
                maxLength={1}
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                // autoCorrect={false}
                style={styles.textInput}
                returnKeyType="done"
                onChangeText={text => {
                  onChangeOtp(text, 3, otp3, otp5);
                }}
                ref={otp4}
              />
              <TextInput
                selectTextOnFocus
                editable={!loading}
                keyboardType={'numeric'}
                maxLength={1}
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                // autoCorrect={false}
                style={styles.textInput}
                returnKeyType="done"
                onChangeText={text => {
                  onChangeOtp(text, 4, otp4, otp6);
                }}
                ref={otp5}
              />
              <TextInput
                selectTextOnFocus
                editable={!loading}
                keyboardType={'numeric'}
                maxLength={1}
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                // autoCorrect={false}
                style={styles.textInput}
                returnKeyType="done"
                onChangeText={text => {
                  onChangeOtp(text, 5, otp5);
                }}
                ref={otp6}
              />
            </View>
          </View>
          <LongButton
            style={{marginTop: setYAxisValue(15)}}
            disabled={loading}
            text={
              loading ? (
                <ActivityIndicator size={20} color={'#ffffff'} />
              ) : (
                'Verify OTP'
              )
            }
            onPress={onSubmitData}
          />
          <View style={styles.dividerContainer}>
            <View style={styles.divider} />
            <AppText style={styles.orText}>{strings('loginScreen.or')}</AppText>
            <View style={styles.divider} />
          </View>
          <LongButton
            disabled={minutes == 0 && seconds == 0 ? false : true}
            text={
              minutes == 0 && seconds == 0
                ? `Resend OTP`
                : `${minutes}: ${seconds < 10 ? `0${seconds}` : seconds}`
            }
            onPress={onResendOtp}
          />
        </View>
      </MainContainer>
    </View>
  );
};

export default VerifyOtpScreen;
