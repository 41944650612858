import {get} from 'lodash';
import React, {useEffect, useState} from 'react';
import {
  View,
  TextInput,
  FlatList,
  ActivityIndicator,
  TouchableOpacity,
  Platform,
} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';
import {
  AppText,
  MainContainer,
  MainHeader,
  Icon,
  NoRecordFound,
  LongButton,
  SelectDateItem,
} from '../../components';
import styles from './styles';
import {strings} from '../../i18n';
import {goBack, setValue} from '../../utils';
import {getMenu, getMenuDates} from '../../redux/reduxsauceActions/menuActions';
import MenuItem from '../../components/MenuItem';
import Constants from 'expo-constants';
import {Colors} from '../../constants';
import moment from 'moment';
import CommonActions from '../../redux/reduxsauce/commonRedux';

const MenuScreen = (props) => {
  const {menu, menuSearchLoading, menuDates} = useSelector(
    (state) => state.menu,
  );
  const {user} = useSelector((state) => state.auth);
  const isLogin = useSelector((state) => get(state.auth.user, 'id', false));
  const dispatch = useDispatch();
  const editMenuItem = {
    id: null,
    name: 'add',
    onPress: () => props.navigation.navigate('edit-menu'),
  };
  const [selelctedItem, setSelelctedItem] = useState([]);
  const [menuList, setMenuList] = useState([...menu, editMenuItem]);
  const [selectedItem, setSelectedItem] = useState('Show All');
  const [searchText, setSearchText] = useState(null);
  const {common, config} = useSelector((state) => state);

  useEffect(() => {
    setSelectedItem('Show All');
    dispatch(getMenu());
    dispatch(getMenuDates());
  }, [props.navigation]);

  useEffect(() => {
    setMenuList([...menu, editMenuItem]);
  }, [menu]);

  const onCheckItem = (item) => {
    let newValue = [];
    if (item.checked) {
      item.checked = false;
      setSelelctedItem([...selelctedItem.filter((b) => b.id != item.id)]);
    } else {
      item.checked = true;
      setSelelctedItem([...selelctedItem, item]);
    }
    menuList.forEach((o) => {
      let newItem = o.id == item.id ? item : o;
      newValue.push(newItem);
    });
    setMenuList([...newValue]);
  };

  const renderSearchBar = () => {
    let timer;
    return (
      <View style={styles.searchBarContainer}>
        <TextInput
          underlineColorAndroid="transparent"
          placeholder={strings('menuListScreen.placeOrderScreen.searchHint')}
          placeholderTextColor="gray"
          autoCorrect={false}
          style={styles.searchBarText}
          enablesReturnKeyAutomatically
          returnKeyType="search"
          onChangeText={(text) => {
            dispatch(
              getMenu(
                text,
                selectedItem == 'Show All'
                  ? false
                  : selectedItem
                  ? moment(selectedItem).format('YYYY-MM-DD')
                  : false,
              ),
            );
            clearTimeout(timer);
            timer = setTimeout(() => {}, 600);
          }}
          defaultValue={searchText}
        />
        <View style={styles.searchBarIcon}>
          {searchText?.length === 0 ? (
            <Icon name="search" size={18} color="gray" />
          ) : (
            <TouchableOpacity onPress={() => setSearchText(null)}>
              <Icon name="cross" size={18} color="gray" />
            </TouchableOpacity>
          )}
        </View>
      </View>
    );
  };
  const renderDateList = () => {
    return (
      <View style={{flexDirection: 'row', marginBottom: setValue(15)}}>
        <FlatList
          contentContainerStyle={{flexGrow: 0}}
          horizontal
          scrollEnabled={true}
          keyExtractor={(item, index) => index}
          data={menuDates}
          renderItem={({item, index}) => {
            return (
              <View style={{height: 30}}>
                <SelectDateItem
                  item={item}
                  index={index}
                  OnPressDate={() => {
                    if (selectedItem == item) {
                      setSelectedItem('Show All');
                      dispatch(getMenu());
                      return;
                    } else {
                      setSelectedItem(item);
                    }
                    dispatch(
                      getMenu(
                        searchText,
                        item == 'Show All'
                          ? false
                          : item
                          ? moment(item).format('YYYY-MM-DD')
                          : false,
                      ),
                    );
                  }}
                  selectedItem={selectedItem}
                />
              </View>
            );
          }}
          showsHorizontalScrollIndicator={false}
          extraData={selectedItem}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <MainHeader
        leftIcon={'back'}
        leftPress={() => goBack(props.navigation)}
        title={strings('menuListScreen.title')}
      />
      <MainContainer useView>
        {false && renderSearchBar()}
        {!common.loading && (
          <View style={{flex: 1}}>
            {renderDateList()}
            {menuSearchLoading ? (
              <View style={styles.serachContainer}>
                <ActivityIndicator size={45} color={Colors.theme_color} />
              </View>
            ) : menu.length == 0 ? (
              <View style={{flex: 1}}>
                {isLogin && ['merchant', 'manager'].includes(user.type) && (
                  <View>
                    <TouchableOpacity
                      style={styles.addMenuButton}
                      onPress={editMenuItem.onPress}
                    >
                      <AppText style={styles.addMenuButtonText}>
                        <Icon name={editMenuItem.name} size={16} />
                      </AppText>
                    </TouchableOpacity>
                  </View>
                )}
                <NoRecordFound title={strings('menuListScreen.noRecord.title')} message={strings('menuListScreen.noRecord.message')} />
              </View>
            ) : (
              <FlatList
                data={menuList}
                showsVerticalScrollIndicator={false}
                keyExtractor={({item}, index) => index}
                renderItem={({item, index}) => {
                  if (index == menuList.length - 1) {
                    if (
                      isLogin &&
                      ['merchant', 'manager'].includes(user.type)
                    ) {
                      return (
                        <View>
                          <TouchableOpacity
                            style={styles.addMenuButton}
                            onPress={item.onPress}
                          >
                            <AppText style={styles.addMenuButtonText}>
                              <Icon name={item.name} size={16} />
                            </AppText>
                          </TouchableOpacity>
                        </View>
                      );
                    }
                    return;
                  }
                  return (
                    <MenuItem
                      item={item}
                      onPress={() =>
                        props.navigation.navigate('menu-detail', {item})
                      }
                      onCheckItem={() => onCheckItem(item)}
                      key={index}
                    />
                  );
                }}
              />
            )}
          </View>
        )}
        {Platform.OS == 'web' && <LongButton text={strings('menuListScreen.button.title')} onPress={null} />}
      </MainContainer>
      {Platform.OS != 'web' && <LongButton text={strings('menuListScreen.button.title')} onPress={null} />}
    </View>
  );
};

export default MenuScreen;
