import axios from 'axios';
import moment from 'moment';
import React from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import {FlatList, TouchableOpacity, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {
  AppText,
  DatePicker,
  LongButton,
  MainContainer,
  MainHeader,
  ProductCategoryItem,
  ProgressiveImage,
  SubscriptionItem,
} from '../../components';
import {strings} from '../../i18n';
import {goBack, isDateValid} from '../../utils';
import styles from './styles';
import CommonActions from '../../redux/reduxsauce/commonRedux';

const SubscriptionDelivery = (props) => {
  const limit = 10;
  const [isDateVisible, setIsDateVisible] = useState(false);
  const [searchDate, setSearchDate] = useState(moment().format('YYYY-MM-DD'));
  const [offset, setOffset] = useState(0);
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [subscriptionList, setSubscriptionList] = useState([]);

  const common = useSelector((state) => state?.common);
  const dispatch = useDispatch();

  const getSubscribedProducts = async () => {
    try {
      dispatch(CommonActions.setLoading(true));
      let response = await axios.get('/subcription/subscribed/product/list');
      console.log('response subscribed product list');
      console.log(response.data);
      setProductList(response?.data);
      dispatch(CommonActions.setLoading(false));
    } catch (error) {
      console.log('error on get subscribed products');
      console.log(error?.response);
      dispatch(CommonActions.setLoading(false));
    }
  };

  const getSubscriptionList = async () => {
    try {
      let url = '/subcription/subscribed/by_product';
      if (searchDate && isDateValid(searchDate)) {
        url.includes('?')
          ? (url += '&date=' + searchDate)
          : (url += '?date=' + searchDate);
      }
      if (selectedProduct && selectedProduct != null) {
        url.includes('?')
          ? (url += '&product_id=' + selectedProduct)
          : (url += '?product_id=' + selectedProduct);
      }

      dispatch(CommonActions.setLoading(true));
      let response = await axios.get(url);
      console.log('subscription list --');
      console.log(response.data);
      setSubscriptionList(response?.data);
      dispatch(CommonActions.setLoading(false));
    } catch (error) {
      console.log('error on get subscribed items');
      console.log(error?.response);
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response.message || 'Something went wrong!',
        }),
      );
    }
  };

  useEffect(() => {
    getSubscribedProducts();
  }, []);

  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('subscription-delivery-list.title')}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer useView>
        {/* ---- date ---- */}
        <View style={styles.filterByDateContainer}>
          <AppText style={styles.titleFilter}>
            {strings('transactionHistoryScreen.filterByDate')}
          </AppText>
          <DatePicker
            title={
              <AppText style={styles.date}>
                {searchDate ||
                  searchDate.dateString ||
                  strings('transactionHistoryScreen.noFilter')}
              </AppText>
            }
            visible={isDateVisible}
            onClose={() => {
              setIsDateVisible(false);
              setSearchDate('');
            }}
            onCancelPress={() => {
              setIsDateVisible(false);
              setSearchDate('');
            }}
            onDayPress={(date) => {
              setIsDateVisible(false);
              setSearchDate(date?.dateString);
            }}
            onSetDataForClass={(text) => {
              setIsDateVisible(false);
              setSearchDate(text);
            }}
            onCalenderPress={() => {
              if (searchDate) {
                setIsDateVisible(false);
                setSearchDate('');
              } else {
                setIsDateVisible(true);
              }
            }}
            navigation={props.navigation}
          />
        </View>

        {/* ---- product list ----  */}
        {productList?.length > 0 && (
          <View style={{flexDirection: 'row', marginBottom: 15}}>
            <FlatList
              contentContainerStyle={{flexGrow: 0}}
              horizontal
              data={productList}
              initialNumToRender={5}
              maxToRenderPerBatch={3}
              windowSize={3}
              extraData={0}
              scrollEnabled={true}
              removeClippedSubviews={true}
              showsHorizontalScrollIndicator={false}
              keyExtractor={(item, index) => index}
              renderItem={({item, index}) => {
                return (
                  <View>
                    <ProductItem
                      item={item}
                      index={index}
                      OnPressCategory={() => {
                        setSelectedProduct(item.id);
                      }}
                      selectedItem={selectedProduct}
                    />
                  </View>
                );
              }}
            />
          </View>
        )}

        {/* ---- get List button ---- */}
        <LongButton
          text={strings('subscription-delivery-list.getList')}
          textStyle={{fontFamily: 'Roboto-Bold'}}
          style={{marginBottom: 20}}
          onPress={() => getSubscriptionList()}
        />

        {/* ---- subscription list section ---- */}
        <View style={{flex: 1}}>
          <FlatList
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{flexGrow: 0}}
            initialNumToRender={5}
            maxToRenderPerBatch={3}
            windowSize={3}
            extraData={0}
            scrollEnabled={true}
            removeClippedSubviews={true}
            showsHorizontalScrollIndicator={false}
            data={subscriptionList}
            keyExtractor={(item, index) => index}
            renderItem={({item, index}) => (
              <SubscriptionItem item={item} navigation={props.navigation} />
            )}
            ListFooterComponent={() => <View style={{height: 50}} />}
          />
        </View>
      </MainContainer>
    </View>
  );
};

const ProductItem = (props) => {
  const {item, selectedItem} = props;
  return (
    <TouchableOpacity
      onPress={() => {
        props.OnPressCategory();
      }}
      key={item.id}
    >
      <View
        style={{
          marginRight: 8,
          width: 75,
          alignItems: 'center',
        }}
      >
        <View
          style={[
            styles.categoryButtonIconContainer,
            selectedItem === item.id ? styles.selected : null,
          ]}
        >
          <View style={styles.categoryImages}>
            <ProgressiveImage
              thumbnailSource={
                item?.product_images?.length > 0
                  ? {uri: item?.product_images[0].photo}
                  : require('../../../assets/images/General/no-img-found.jpg')
              }
              source={
                item?.product_images?.length > 0
                  ? {uri: item?.product_images[0].photo}
                  : require('../../../assets/images/General/no-img-found.jpg')
              }
              style={styles.image}
              resizeMode="cover"
            />
          </View>
        </View>
        <AppText style={styles.categoryText} numberOfLines={2}>
          {item.name}
        </AppText>
      </View>
    </TouchableOpacity>
  );
};

export default SubscriptionDelivery;
