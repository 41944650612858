import React, {useState, useEffect} from 'react';
import {
  Keyboard,
  Platform,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import {
  AppText,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../../../components';
import {Colors} from '../../../../constants';
import {strings} from '../../../../i18n';
import {goBack, setValue} from '../../../../utils';
import styles from './styles';
import axios from 'axios';
import CommonActions from '../../../../redux/reduxsauce/commonRedux';
import {useDispatch, useSelector} from 'react-redux';
import {get} from 'lodash';

const EditRateCode = (props) => {
  let currencySymbol = get(config, 'currencySymbol', '₹');
  const dispatch = useDispatch();
  const config = useSelector((state) => state?.config);
  const item = props?.route?.params?.item;

  const [name, setName] = useState(item?.name ? item?.name : '');
  const [plusDayPrice, setPlusDayPrice] = useState({
    monday: item?.plus_day_price?.monday
      ? item?.plus_day_price?.monday.toString()
      : '0',
    tuesday: item?.plus_day_price?.tuesday
      ? item?.plus_day_price?.tuesday.toString()
      : '0',
    wednesday: item?.plus_day_price?.wednesday
      ? item?.plus_day_price?.wednesday.toString()
      : '0',
    thursday: item?.plus_day_price?.thursday
      ? item?.plus_day_price?.thursday.toString()
      : '0',
    friday: item?.plus_day_price?.friday
      ? item?.plus_day_price?.friday.toString()
      : '0',
    saturday: item?.plus_day_price?.saturday
      ? item?.plus_day_price?.saturday.toString()
      : '0',
    sunday: item?.plus_day_price?.sunday
      ? item?.plus_day_price?.sunday.toString()
      : '0',
  });
  const [bookedRatioPercentageArray, setBookedRatioPercentageArray] = useState(
    [],
  );
  const [bookedRatioPercentage, setBookedRatioPercentage] = useState();

  const AddVariantFields = () => {
    setBookedRatioPercentageArray([
      ...bookedRatioPercentageArray,
      {percentage: '', value: ''},
    ]);
  };

  const RemoveRowFields = (index) => {
    let arr = [...bookedRatioPercentageArray];
    arr.splice(index, 1);
    setBookedRatioPercentageArray([...arr]);
  };

  const onSpecificBookedRatioChange = (text, type, index) => {
    let fullArray = [...bookedRatioPercentageArray];

    if (type == 'percentage') {
      fullArray[index] = {
        ...fullArray[index],
        percentage: text,
      };
      setBookedRatioPercentageArray(fullArray);
    } else if (type == 'value') {
      fullArray[index] = {
        ...fullArray[index],
        value: text,
      };
      setBookedRatioPercentageArray(fullArray);
    }
  };

  useEffect(() => {
    if (item?.id) {
      let keyObj = Object.entries(item?.booked_ratio_percentage).map(
        (keyName) => ({
          percentage: keyName[0],
          value: keyName[1].toString(),
        }),
      );
      setBookedRatioPercentageArray([...keyObj]);
    }
  }, [item?.id]);

  useEffect(() => {
    const obj = bookedRatioPercentageArray.reduce(
      (acc, cur) => ({...acc, [cur.percentage]: Number(cur.value)}),
      {},
    );
    setBookedRatioPercentage(obj);
  }, [bookedRatioPercentageArray]);

  const onSave = async () => {
    Keyboard.dismiss();
    dispatch(CommonActions.setLoading(true));
    if (name.trim() == '') {
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: 'Name is required',
        }),
      );
      return;
    }

    let plusDayDetail = {
      monday: Number(plusDayPrice?.monday),
      tuesday: Number(plusDayPrice?.tuesday),
      wednesday: Number(plusDayPrice?.wednesday),
      thursday: Number(plusDayPrice?.thursday),
      friday: Number(plusDayPrice?.friday),
      saturday: Number(plusDayPrice?.saturday),
      sunday: Number(plusDayPrice?.sunday),
    };
    let body = {
      plus_day_price: plusDayDetail,
      name: name,
      booked_ratio_percentage: bookedRatioPercentage,
    };
    console.log('body', body);
    if (item?.id) {
      await axios
        .patch('/hospitality/rate_code/update/' + item?.id, body)
        .then((response) => {
          console.log('response update rate code', response);
          dispatch(CommonActions.setLoading(false));
          props.navigation.replace('rate-code-list');
        })
        .catch((error) => {
          console.log('error update rate code', error?.response);
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: error?.response?.message,
            }),
          );
          dispatch(CommonActions.setLoading(false));
        });
    } else {
      await axios
        .post('/hospitality/rate_code/create', body)
        .then((response) => {
          console.log('response of create rate code', response);
          dispatch(CommonActions.setLoading(false));
          props.navigation.replace('rate-code-list');
        })
        .catch((error) => {
          console.log('error create rate code', error.response);
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: error?.response?.message,
            }),
          );
          dispatch(CommonActions.setLoading(false));
        });
    }
  };

  return (
    <View style={styles.container}>
      <MainHeader
        title={'Add Rate Code'}
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer>
        {/* <KeyboardAwareScrollView> */}
        <>
          <AppText style={[styles.headerTitle, {color: Colors.white}]}>
            {'Plus day price'}
          </AppText>
          {Object.keys(plusDayPrice).map((keyName, i) => {
            return (
              <View style={styles.plusDayInputContainer} key={i}>
                <AppText style={styles.title}>{keyName}</AppText>
                <AppText style={styles.sign}>{':'}</AppText>
                <TextInput
                  underlineColorAndroid="transparent"
                  autoCapitalize="none"
                  autoCorrect={false}
                  placeholder={`Add price for ${keyName}`}
                  placeholderTextColor={Colors.color30}
                  returnKeyType="done"
                  style={styles.plusDayInput}
                  value={plusDayPrice[keyName]}
                  onChangeText={(text) =>
                    setPlusDayPrice({
                      ...plusDayPrice,
                      [keyName]: text,
                    })
                  }
                />
              </View>
            );
          })}

          <View style={styles.textInputContainer}>
            <AppText style={styles.title} required>
              {strings('editExperience.name.name')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCapitalize="none"
              autoCorrect={false}
              placeholder={strings('editExperience.placehoder.name')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={styles.textInput}
              value={name}
              onChangeText={(text) => setName(text)}
            />
          </View>

          <View>
            <AppText style={[styles.headerTitle, {color: Colors.black20}]}>
              {'Booked Ratio Percentage'}
            </AppText>
          </View>
          {bookedRatioPercentageArray &&
            bookedRatioPercentageArray.map((item, index) => (
              <View
                key={index}
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                }}
              >
                <View style={styles.ratioInputContainer} key={index}>
                  <TextInput
                    underlineColorAndroid={Colors.color30}
                    autoCapitalize="none"
                    autoCorrect={false}
                    placeholder={'Percentage'}
                    placeholderTextColor={Colors.color30}
                    returnKeyType="done"
                    style={styles.plusDayInput}
                    value={item?.percentage}
                    onChangeText={(text) => {
                      onSpecificBookedRatioChange(text, 'percentage', index);
                    }}
                  />
                  <AppText style={styles.sign}>{'%'}</AppText>
                  <AppText style={styles.sign}>{'+'}</AppText>
                  <TextInput
                    underlineColorAndroid={Colors.color30}
                    autoCapitalize="none"
                    autoCorrect={false}
                    placeholder={'Value'}
                    placeholderTextColor={Colors.color30}
                    returnKeyType="done"
                    style={styles.plusDayInput}
                    value={item?.value}
                    onChangeText={(text) => {
                      onSpecificBookedRatioChange(text, 'value', index);
                    }}
                  />
                  <AppText style={styles.sign}>{currencySymbol}</AppText>
                </View>
                <View style={styles.deleteButtonContainer}>
                  <TouchableOpacity
                    style={styles.deleteButton}
                    onPress={() => RemoveRowFields(index)}
                  >
                    <Icon name="dustBinSmaller" color="white" />
                  </TouchableOpacity>
                </View>
              </View>
            ))}
          <View
            style={[styles.addRomoveContainer, {marginBottom: setValue(10)}]}
          >
            <TouchableOpacity
              style={[styles.addRomoveButton, {marginLeft: 5}]}
              onPress={() => AddVariantFields()}
            >
              <AppText style={styles.addRomoveButtonText}>
                {'Add Booked Ratio Percentages'}
              </AppText>
            </TouchableOpacity>
          </View>
        </>

        {Platform.OS == 'web' && (
          <LongButton
            onPress={() => onSave()}
            loading={false}
            text={
              item?.id
                ? strings('addNewGallery.edit')
                : strings('editProductCategory.button.save')
            }
            style={{
              marginTop: setValue(20),
              marginBottom: setValue(45),
            }}
          />
        )}
        {/* </KeyboardAwareScrollView> */}
      </MainContainer>

      {Platform.OS !== 'web' && (
        <LongButton
          onPress={() => onSave()}
          loading={false}
          text={
            item?.id
              ? strings('addNewGallery.edit')
              : strings('editProductCategory.button.save')
          }
          style={{borderRadius: 0, width: '100%'}}
        />
      )}
    </View>
  );
};

export default EditRateCode;
