import React, {useState} from 'react';
import {Platform, TextInput, TouchableOpacity, View} from 'react-native';
import {useDispatch} from 'react-redux';
import {
  AppText,
  DropDownInput,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../../components';
import {Colors} from '../../../constants';
import {strings} from '../../../i18n';
import {goBack, setValue} from '../../../utils';
import styles from './styles';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import axios from 'axios';

export default function EditDeliveryPartner(props) {
  const item = props?.route?.params?.item;
  const [detail, setDetail] = useState({
    type: item?.type ? item?.type : 'shiprocket',
    username: item?.username ? item?.username : '',
    password: item?.password ? item?.password : '',
  });
  const [isSecure, setIsSecure] = useState(true);
  const dispatch = useDispatch();

  const saveDeliveryPartner = () => {
    if (
      detail.type == '' ||
      detail?.type == null ||
      detail?.type == undefined
    ) {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: 'Please select any delivery partner type',
        }),
      );
      return;
    }
    if (
      detail.username.trim() == '' ||
      detail?.username == null ||
      detail?.username == undefined
    ) {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: 'Please enter valid username',
        }),
      );
      return;
    }
    if (
      detail.password.trim() == '' ||
      detail?.password == null ||
      detail?.password == undefined
    ) {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: 'Please enter valid password',
        }),
      );
      return;
    }

    dispatch(CommonActions.setLoading(true));

    if (item?.id) {
      axios
        .patch('/delivery/deliverypartnerconfig/update/' + item?.id, detail)
        .then((response) => {
          console.log('update delivery partner config --', response);
          dispatch(CommonActions.setLoading(false));
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: 'Delivery Partner updated successfully',
            }),
          );
          goBack(props?.navigation);
        })
        .catch((error) => {
          console.log('error update delivery partner--', error);
          dispatch(CommonActions.setLoading(false));
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: error?.response?.message || 'Something went wrong!',
            }),
          );
        });
    } else {
      axios
        .post('/delivery/deliverypartnerconfig/create', detail)
        .then((response) => {
          console.log('create delivery partner config --', response);
          dispatch(CommonActions.setLoading(false));
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: 'Delivery Partner saved successfully',
            }),
          );
          goBack(props?.navigation);
        })
        .catch((error) => {
          console.log('error create delivery partner--', error);
          dispatch(CommonActions.setLoading(false));
          dispatch(
            CommonActions.setAlert({
              visible: true,
              content: error?.response?.message || 'Something went wrong!',
            }),
          );
        });
    }
  };

  return (
    <View style={{flex: 1}}>
      <MainHeader
        title={'Edit Delivery Partner'}
        leftIcon="back"
        leftPress={() => goBack(props?.navigation)}
      />
      <MainContainer useView>
        <>
          <View style={styles.textInputContainer}>
            <AppText style={styles.title}>
              {strings('addNewAddressScreen.title.type')}
            </AppText>
            <DropDownInput
              inputStyle={{
                marginVertical: 10,
                marginHorizontal: 10,
              }}
              disabled={item?.id ? true : false}
              data={[
                {value: 'shiprocket', label: 'ShipRocket'},
                {value: 'nimbuspost', label: 'NimbusPost'},
              ]}
              value={detail?.type}
              onChangeText={(text) => {
                setDetail({
                  ...detail,
                  type: text,
                });
              }}
            />
          </View>

          <View style={styles.textInputContainer}>
            <AppText style={styles.title}>{'Username'}</AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCapitalize="none"
              autoCorrect={false}
              placeholder={'Enter username'}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={styles.textInput}
              value={detail?.username}
              onChangeText={(text) => {
                setDetail({
                  ...detail,
                  username: text,
                });
              }}
            />
          </View>
          <View style={styles.textInputContainer}>
            <AppText style={styles.title}>{'Password'}</AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCapitalize="none"
              autoCorrect={false}
              placeholder={'Enter password'}
              placeholderTextColor={Colors.color30}
              secureTextEntry={isSecure}
              style={styles.textInput}
              returnKeyType="done"
              value={detail?.password}
              onChangeText={(text) =>
                setDetail({
                  ...detail,
                  password: text,
                })
              }
            />
            <TouchableOpacity
              onPress={() => setIsSecure(!isSecure)}
              style={styles.inputRightSide}
            >
              <Icon
                name={isSecure ? 'revealPwd' : 'revealPwd-no'}
                size={20}
                color={Colors.color30}
              />
            </TouchableOpacity>
          </View>
        </>

        {Platform.OS === 'web' && (
          <LongButton
            text={'Save'}
            style={{
              marginTop: setValue(20),
              marginBottom: setValue(45),
            }}
            onPress={saveDeliveryPartner}
          />
        )}
      </MainContainer>

      {Platform.OS !== 'web' && (
        <LongButton
          text={'Save'}
          style={{
            width: '100%',
            borderRadius: 0,
          }}
          onPress={saveDeliveryPartner}
        />
      )}
    </View>
  );
}
