import { Platform, StyleSheet } from "react-native";
import { Colors } from "../../../constants";
import { screenHeight, setXAxisValue, setYAxisValue } from "../../../utils";

const shadow = {
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 3,
    backgroundColor: Colors.white,
  };

export default StyleSheet.create({
    container:
    Platform.OS !="web" ?{
      flex: 1,
    }:{
      overflow: 'scroll',
      height: '100vh',
    },
    
    settingView: {
      ...shadow,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingVertical: setYAxisValue(20),
      paddingHorizontal: setXAxisValue(10),
      borderRadius: 8,
      alignItems: 'center',
      marginBottom: setYAxisValue(10),
    },
})