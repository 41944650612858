import { Component } from 'react';
import { unmountComponentAtNode, unstable_renderSubtreeIntoContainer } from 'react-dom';

class Portal extends Component {
  componentDidMount() {
    this.renderPortal(this.props.children);
  }

  UNSAFE_componentWillReceiveProps({ children }) {
    this.renderPortal(children);
  }

  componentWillUnmount() {
    if (!this.node) return;

    unmountComponentAtNode(this.node);
    document.body.removeChild(this.node);

    this.portal = null;
    this.node = null;
  }

  renderPortal(children) {
    if (!this.node) {
      this.node = document.createElement('div');
      document.body.appendChild(this.node);
    }

    this.portal = unstable_renderSubtreeIntoContainer(this, children, this.node);
  }

  render() {
    return null;
  }
}

export default Portal;
