import {StyleSheet} from 'react-native';

import {Colors, Layout} from '../../constants';
import {setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 8,
  backgroundColor: Colors.white,
};

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.theme_color,
  },
  body: {
    marginTop: setYAxisValue(5),
    flex: 1,
  },
  textInputContainer: {
    backgroundColor: Colors.white,
    borderRadius: 5,
    padding: 10,
    margin: 5,
    marginTop: 10,
    ...shadow,
  },
  createTermsAndPolicyButton: {
    borderRadius: 5,
    ...shadow,
    padding: 10,
    marginHorizontal: 5,
    marginVertical: 6,
  },
  textInput: {
    textAlignVertical: 'top',
    borderRadius: 5,
    maxHeight: 150,
  },
  buttonContainer: {
    margin: 5,
  },
  uploadButton: {
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
    backgroundColor: Colors.white,
    ...shadow,
  },
  settingView: {
    ...shadow,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: setYAxisValue(20),
    paddingHorizontal: setXAxisValue(10),
    borderRadius: 8,
    alignItems: 'center',
  },
  webView: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    marginBottom: setYAxisValue(2),
  },
  webViewContainer: {
    marginTop: setYAxisValue(10),
    marginHorizontal: 5,
    padding: 10,
    borderRadius: 5,
    backgroundColor: Colors.white,
    // height: Layout.window.height * 0.7,
    flex: 1,
  },
});
