import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {View, Platform, TouchableOpacity} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import CommonAction from '../../redux/reduxsauce/commonRedux';
import {
  AppConfigCheckbox,
  AppText,
  DropDownInput,
  LongButton,
  MainContainer,
  MainHeader,
  Icon,
} from '../../components';
import {strings} from '../../i18n';
import {goBack, screenHeight, setValue} from '../../utils';
import styles from './styles';
import {getAppConfig} from '../../redux/reduxsauceActions/configActions';

const ProductSetting = (props) => {
  const imageRatio = ['1 : 1', '2 : 3', '3 : 2', '3 : 4', '4 : 3'];

  const [productConfig, setProductConfig] = useState({
    showCategory: true,
    showSubCategory: true,
    showBrand: true,
    showVariants: true,
    hidePrice: false,
    showReview: false,
    aspectRatio: imageRatio[0],
    availableForSubscription: false,
  });

  const config = useSelector((state) => state?.config);
  const dispatch = useDispatch();

  useEffect(() => {
    const setting = config;

    setProductConfig({
      ...productConfig,
      showCategory: setting?.product?.showCategory,
      showSubCategory: setting?.product?.showSubCategory,
      showBrand: setting?.product?.showBrand,
      showVariants: setting?.product?.showVariants,
      hidePrice: setting?.product?.hidePrice || false,
      showReview: setting?.product?.showReview || false,
      aspectRatio: setting?.product?.aspectRatio
        ? arrayToStr(setting?.product?.aspectRatio)
        : imageRatio[0],
      availableForSubscription:
        setting?.product?.availableForSubscription || false,
    });
  }, []);

  const strToArray = (str) => {
    let arr = str.split(':').map((o) => parseInt(o));
    console.log(arr);
    return arr;
  };

  const arrayToStr = (arr) => {
    let arr2 = arr?.map((o) => o.toString());
    let str = arr2.join(' : ');
    return str;
  };

  const saveProductConfig = async () => {
    dispatch(CommonAction.setLoading(true));
    try {
      const response = await axios.put(
        // `/business/update/setting/${config?.businessId}`,
        `/business/update/business-setting/${config?.businessId}`,
        {
          product: {
            showCategory: productConfig?.showCategory,
            hidePrice: productConfig?.hidePrice,
            showReview: productConfig?.showReview,
            showSubCategory: productConfig?.showSubCategory,
            showBrand: productConfig?.showBrand,
            showVariants: productConfig?.showVariants,
            aspectRatio: strToArray(productConfig?.aspectRatio),
            availableForSubscription: productConfig?.availableForSubscription,
          },
        },
      );
      console.log('response', response);
      dispatch(getAppConfig());
      dispatch(CommonAction.setLoading(false));
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: strings('productSetting.alert.success'),
        }),
      );
      goBack(props?.navigation);
    } catch (error) {
      dispatch(CommonAction.setLoading(false));
      console.log('error', error.response);
      dispatch(
        CommonAction.setAlert({
          visible: true,
          content: error?.response?.message || 'Something went wrong :(',
        }),
      );
    }
  };

  return (
    <View
      style={Platform.OS === 'web' ? styles.webContainer : styles.container}
    >
      <MainHeader
        title={strings('productSetting.title')}
        leftIcon="back"
        leftPress={() => goBack(props?.navigation)}
      />
      <MainContainer>
        <View style={Platform.OS != 'web' ? {minHeight: screenHeight / 2} : {}}>

          <TouchableOpacity
            style={styles.settingView}
            onPress={() => {
              props?.navigation.navigate('variant-types');
            }}
          >
            <AppText style={styles.title}>{'Products Variant'}</AppText>
            <Icon name="forward" size={20} />
          </TouchableOpacity>
          
          {/* show category */}
          <AppConfigCheckbox
            icon={'category'}
            title={strings('productSetting.productCategory')}
            desc={strings('productSetting.productCategoryDescription')}
            checked={productConfig?.showCategory}
            onChangeCheck={(checked) => {
              setProductConfig({
                ...productConfig,
                showCategory: checked,
              });
            }}
          />

          {/* show sub category */}
          <AppConfigCheckbox
            icon={'category'}
            title={strings('productSetting.category')}
            desc={strings('productSetting.categoryDescription')}
            checked={productConfig?.showSubCategory}
            onChangeCheck={(checked) => {
              setProductConfig({
                ...productConfig,
                showSubCategory: checked,
              });
            }}
          />

          {/* show brand */}
          {(config?.ENV == 'local' || config?.ENV == 'development') && (
            <AppConfigCheckbox
              icon={'category'}
              title={strings('productSetting.brand')}
              desc={strings('productSetting.brandDescription')}
              checked={productConfig?.showBrand}
              onChangeCheck={(checked) => {
                setProductConfig({
                  ...productConfig,
                  showBrand: checked,
                });
              }}
            />
          )}

          {/* show variants */}
          <AppConfigCheckbox
            icon={'category'}
            title={strings('productSetting.variants')}
            desc={strings('productSetting.variantsDescription')}
            checked={productConfig?.showVariants}
            onChangeCheck={(checked) => {
              setProductConfig({
                ...productConfig,
                showVariants: checked,
              });
            }}
          />

          {/* available for subscription */}
          {(config?.domain == 'demo-retail.whitelabelapp.in' ||
            config?.domain == 'demo-restaurants.whitelabelapp.in') && (
            <AppConfigCheckbox
              icon={'subscription'}
              title={strings('productSetting.subscription')}
              desc={strings('productSetting.subscriptionDetail')}
              checked={productConfig?.availableForSubscription}
              onChangeCheck={(checked) => {
                setProductConfig({
                  ...productConfig,
                  availableForSubscription: checked,
                });
              }}
            />
          )}

          {/* hide price */}
          {(config?.ENV == 'local' || config?.ENV == 'development') && (
            <AppConfigCheckbox
              icon={'category'}
              title={strings('productSetting.hidePrice')}
              desc={strings('productSetting.hidePriceDescription')}
              checked={productConfig?.hidePrice}
              onChangeCheck={(checked) => {
                setProductConfig({
                  ...productConfig,
                  hidePrice: checked,
                });
              }}
            />
          )}

          {/* show review */}
          <AppConfigCheckbox
            icon={'filledStar'}
            title={strings('productSetting.review')}
            desc={strings('productSetting.reviewDescription')}
            checked={productConfig?.showReview}
            onChangeCheck={(checked) => {
              setProductConfig({
                ...productConfig,
                showReview: checked,
              });
            }}
          />

          {/* {Image Size} */}
          <View style={styles.imageContainer}>
            <AppText style={styles.text}>
              {strings('productSetting.imageSize') +
                ' (' +
                strings('productSetting.aspectRatio') +
                ')'}
            </AppText>
            <DropDownInput
              data={imageRatio.map((o) => o)}
              value={productConfig?.aspectRatio}
              inputStyle={{
                paddingHorizontal: 15,
                marginLeft: Platform.OS == 'web' ? 5 : 0,
                marginVertical: 10,
              }}
              onChangeText={(text) => {
                setProductConfig({
                  ...productConfig,
                  aspectRatio: text,
                });
              }}
            />
          </View>
        </View>
        {Platform.OS == 'web' && (
          <LongButton
            text={strings('productSetting.save')}
            style={{marginTop: setValue(10), marginBottom: setValue(45)}}
            onPress={saveProductConfig}
          />
        )}
      </MainContainer>
      {Platform.OS !== 'web' && (
        <LongButton
          text={strings('productSetting.save')}
          style={{
            width: '100%',
            borderRadius: 0,
          }}
          onPress={saveProductConfig}
        />
      )}
    </View>
  );
};

export default ProductSetting;
