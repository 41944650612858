import axios from 'axios';
import React, {Component} from 'react';
import {MaterialCommunityIcons} from '@expo/vector-icons';
import PhoneInput from '../../lib/react-native-phone-input';
import {
  TouchableOpacity,
  View,
  Text,
  Keyboard,
  TextInput,
  ActivityIndicator,
} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
  AppText,
  LongButton,
  MainContainer,
  MainHeader,
  Icon,
} from '../../components';
import {Colors} from '../../constants';
import {strings} from '../../i18n';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {
  goBack,
  isEmail,
  isPhoneNumberValid,
  setValue,
  isusername,
  setYAxisValue,
} from '../../utils';
import styles from './styles';

class ForgotPasswordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '+91',
      email: '',
      loading: false,
      selectedRadio: 0,
      businessId: '',
    };
  }

  onResetPassword = async () => {
    const {phone, email, selectedRadio, businessId} = this.state;
    const {openAlert, navigation} = this.props;

    Keyboard.dismiss();
    if (selectedRadio == 0) {
      if (!email || !(email && isEmail(email))) {
        return openAlert(strings('registerScreen.validate.email'));
      }
    } else {
      if (!phone || !(phone && isPhoneNumberValid(phone))) {
        return openAlert(strings('registerScreen.validate.phone'));
      }
    }
    if (!businessId && businessId == '') {
      return openAlert('Please enter BussinessId');
    }

    this.setState({loading: true});
    axios
      .post('/user/merchant/resend-verification-otp', {
        username: this.state.email != '' ? this.state.email : this.state.phone,
        business: parseInt(this.state.businessId),
      })
      .then((response) => {
        this.props.openAlert(response?.data?.message);
        this.setState({loading: false});
        navigation.navigate('reset-password', {
          username:
            this.state.email != '' ? this.state.email : this.state.phone,
          business: parseInt(this.state.businessId),
        });
      })
      .catch((error) => {
        this.props.openAlert(error?.response?.message);
        this.setState({loading: false});
      });
  };
  render() {
    const {navigation, countryCode} = this.props;
    const {email, selectedRadio, phone, businessId} = this.state;
    return (
      <View style={styles.container}>
        <MainHeader
          title={strings('forgotPasswordScreen.forgotPassword')}
          leftIcon="back"
          leftPress={() => goBack(navigation)}
        />
        <MainContainer showLogo>
          <View style={styles.forgetPasswordContainer}>
            <View style={styles.RadioGroup}>
              <TouchableOpacity
                style={
                  selectedRadio == 0
                    ? styles.selectedSelectionCard
                    : styles.selectionCard
                }
                onPress={() =>
                  this.setState({selectedRadio: 0, phone: '+91', password: ''})
                }
              >
                <MaterialCommunityIcons
                  name="email"
                  size={20}
                  color={selectedRadio == 0 ? Colors.white : Colors.black20}
                />
                <AppText
                  style={
                    selectedRadio == 0
                      ? styles.selectedSelectionText
                      : styles.selectionText
                  }
                >
                  Email
                </AppText>
              </TouchableOpacity>
              <TouchableOpacity
                style={
                  selectedRadio == 1
                    ? styles.selectedSelectionCard
                    : styles.selectionCard
                }
                onPress={() =>
                  this.setState({selectedRadio: 1, password: '', email: ''})
                }
              >
                <Icon
                  name="phone"
                  size={16}
                  color={selectedRadio == 1 ? Colors.white : Colors.black20}
                />
                <AppText
                  style={
                    selectedRadio == 1
                      ? styles.selectedSelectionText
                      : styles.selectionText
                  }
                >
                  Phone
                </AppText>
              </TouchableOpacity>
            </View>
            <View style={styles.textInputContainer}>
              <TextInput
                underlineColorAndroid="transparent"
                autoCapitalize="none"
                autoCorrect={false}
                keyboardType={'number-pad'}
                placeholder={'Enter Your business ID'}
                placeholderTextColor={Colors.color30}
                style={styles.textInput}
                returnKeyType="done"
                value={businessId}
                onChangeText={(text) => this.setState({businessId: text})}
              />
            </View>
            {selectedRadio == 1 ? (
              <PhoneInput
                ref={(input) => {
                  this.phoneInput = input;
                }}
                initialCountry={countryCode}
                style={styles.textInput}
                value={phone}
                onSelectCountry={() => {
                  this.setState({
                    phone: `+${this.phoneInput.getCountryCode()}`,
                  });
                }}
                inputStyle={{
                  height: setValue(50),
                  justifyContent: 'center',
                }}
                textProps={{
                  underlineColorAndroid: 'transparent',
                  autoCapitalize: 'none',
                  autoCorrect: false,
                  placeholder: strings('registerScreen.placeHolder.phone'),
                  placeholderTextColor: Colors.color30,
                  keyboardType: 'phone-pad',
                  returnKeyType: 'done',
                  value: phone,
                  onChangeText: (text) => this.setState({phone: text}),
                  onSubmitEditing: () => this.passwordInput?.focus(),
                }}
              />
            ) : (
              <View style={styles.textInputContainer}>
                <TextInput
                  ref={(input) => {
                    this.passwordInput = input;
                  }}
                  underlineColorAndroid="transparent"
                  autoCapitalize="none"
                  autoCorrect={false}
                  placeholder={strings('loginScreen.placeHolder.email')}
                  placeholderTextColor={Colors.color30}
                  secureTextEntry={false}
                  style={styles.textInput}
                  returnKeyType="done"
                  value={email}
                  onChangeText={(text) => this.setState({email: text})}
                />
                <TouchableOpacity style={styles.inputRightSide}>
                  <Icon name="email" size={30} color={Colors.color30} />
                </TouchableOpacity>
              </View>
            )}
            <LongButton
              style={{marginTop: setYAxisValue(15)}}
              disabled={this.state.loading}
              text={
                this.state.loading ? (
                  <ActivityIndicator size={20} color={'#ffffff'} />
                ) : (
                  strings('forgotPasswordScreen.sendOTP')
                )
              }
              onPress={() => {
                this.onResetPassword();
              }}
            />
          </View>
        </MainContainer>
      </View>
    );
  }
}

export default connect(
  (state) => ({
    countryCode: state.common.countryCode,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        openAlert: (content) =>
          CommonActions.setAlert({visible: true, content}),
        errorAlert: (content) =>
          CommonActions.setAlert({visible: true, content}),
      },
      dispatch,
    ),
)(ForgotPasswordScreen);
