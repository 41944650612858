import {Appearance, Platform, StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

const colorScheme = Appearance.getColorScheme();
const backgroundColor = colorScheme == 'dark' ? Colors.black : Colors.white;
const textColor =
  colorScheme == 'dark' ? 'rgba(215, 219, 221,0.8)' : Colors.black;
const borderColor =
  colorScheme == 'dark' ? 'rgba(128, 139, 150 ,0.3)' : Colors.border;
export default StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    paddingVertical: setYAxisValue(10),
    backgroundColor: backgroundColor,
  },
  iconColor: {
    color: textColor,
  },
  imageView: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: setValue(80),
    height: setValue(80),
    borderRadius: setValue(80) / 2,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    backgroundColor: Colors.white_gray,
  },
  image: {
    width: setValue(80),
    height: setValue(80),
    borderRadius: setValue(80) / 2,
  },
  drawerItem: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderBottomColor: borderColor,
    borderBottomWidth: StyleSheet.hairlineWidth,
    width: '100%',
    paddingVertical: setYAxisValue(15),
  },
  drawerBottom: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: setXAxisValue(10),
    marginBottom: Platform.OS === 'web' ? setYAxisValue(10) : 0,
  },
  envText: {
    fontSize: setXAxisValue(9),
  },
  powerByText: {
    fontSize: setXAxisValue(8),
    color: textColor,
  },
  versionText: {
    fontSize: setXAxisValue(9),
    marginTop: setYAxisValue(5),
    color: textColor,
  },
  powerImage: {
    width: setValue(18),
    height: setValue(18),
  },
  powerView: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: textColor,
    marginTop: setYAxisValue(5),
  },
  drawerBottomEnv: {
    fontSize: setXAxisValue(9),
    fontWeight: 'bold',
    color: Colors.theme_color,
    backgroundColor: colorScheme == 'dark' ? Colors.black : 'yellow',
    marginBottom: 3,
    textTransform: 'capitalize',
  },
  tooltip: {
    padding: setValue(10),
    textAlign: 'center',
  },
});
