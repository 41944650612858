import axios from 'axios';
import {connectActionSheet} from '@expo/react-native-action-sheet';
import React, {useState, useEffect} from 'react';
import {
  Alert,
  Image,
  Platform,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import {
  AppText,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
} from '../../../components';
import {goBack, setValue} from '../../../utils';
import styles from './styles';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Colors} from '../../../constants';
import {strings} from '../../../i18n';
import * as ImagePicker from 'expo-image-picker';
import {
  addNotificationInList,
  updateNotificationInList,
} from '../../../redux/reduxsauceActions/notificationActions';

const EditNotification = (props) => {
  const {
    config,
    setLoading,
    updateNotificationInList,
    openAlert,
    addNotificationInList,
    route,
  } = props;

  let initialState = {
    title: route?.params?.title || '',
    description: route?.params?.description || '',
  };
  const [images, setImages] = useState(
    route?.params?.photo ? {uri: route?.params?.photo} : null,
  );
  const [notificationData, setNotificationData] = useState(initialState);

  const openActionSheet = () => {
    const options = [
      strings('actionSheet.takePhoto'),
      strings('actionSheet.choosePhoto'),
      strings('actionSheet.removePhoto'),
      strings('actionSheet.cancel'),
    ];
    const cancelButtonIndex = 3;

    props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) openCamera();
        if (buttonIndex === 1) openImagePicker();
        if (buttonIndex === 2) removePhoto();
      },
    );
  };
  const removePhoto = () => {
    setImages(null);
  };

  const openCamera = async () => {
    await ImagePicker.requestCameraPermissionsAsync();

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      setImages(result);
    }
    if (!result.cancelled) {
    }
  };

  const openImagePicker = async () => {
    await ImagePicker.requestMediaLibraryPermissionsAsync();

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      setImages(result);
    }
    if (!result.cancelled) {
    }
  };

  const dataURLtoFile = (dataurl) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], 'image1.jpg', {type: mime});
  };

  const onSave = async () => {
    console.log('data -->', notificationData);

    if (notificationData.title == '') {
      openAlert(strings('createNotificationScreen.alert.title'));
      return;
    }

    let body = new FormData();
    body.append('business', config.businessId);
    body.append('title', notificationData.title);
    body.append('description', notificationData.description);
    if (images?.uri && images?.uri?.slice(0, 4) != 'http') {
      if (Platform.OS == 'web') {
        body.append('photo', dataURLtoFile(images?.uri));
      } else {
        let blob = await (await fetch(images?.uri)).blob();
        body.append('photo', {
          uri: images.uri,
          type: blob.type,
          name: blob.data.name,
        });
      }
    } else if (!images?.uri) {
      body.append('photo', '');
    }

    if (route?.params?.id) {
      updateNotificationInList(body, route?.params?.id);
    } else {
      addNotificationInList(body);
    }
    goBack(navigation);
  };

  return (
    <View style={styles.container}>
      <MainHeader
        title={
          route?.params?.id
            ? strings('createNotificationScreen.title.edit')
            : strings('createNotificationScreen.title.add')
        }
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer>
        {/* notification Image */}
        <TouchableOpacity
          style={styles.imageContainer}
          onPress={openActionSheet}
        >
          {!images ? (
            <Icon name="add" size={16} color={Colors.black60} />
          ) : (
            <View>
              <Image source={{uri: images.uri}} style={styles.image} />
              <TouchableOpacity
                style={styles.cameraIcon}
                onPress={openActionSheet}
              >
                <Icon name="camera" color={Colors.black20} />
              </TouchableOpacity>
            </View>
          )}
        </TouchableOpacity>

        {/* Input fields */}
        <View>
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('createNotificationScreen.label.title')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              // autoCapitalize="words"
              autoCorrect={false}
              placeholder={strings(
                'createNotificationScreen.placeholder.title',
              )}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
              value={notificationData.title}
              onChangeText={(text) =>
                setNotificationData({...notificationData, title: text})
              }
            />
          </View>
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('createNotificationScreen.label.descripation')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              autoCorrect={false}
              multiline={true}
              numberOfLines={3}
              placeholder={strings(
                'createNotificationScreen.placeholder.descripation',
              )}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web'
                  ? styles.textInputWeb
                  : styles.multiLineTextInput
              }
              value={notificationData.description}
              onChangeText={(text) =>
                setNotificationData({...notificationData, description: text})
              }
            />
          </View>
        </View>
        {Platform.OS == 'web' && (
          <LongButton
            text={strings('createNotificationScreen.button.save')}
            style={{
              width: '100%',
              marginTop: setValue(10),
              marginBottom: setValue(45),
            }}
            onPress={onSave}
          />
        )}
      </MainContainer>

      {Platform.OS !== 'web' && (
        <LongButton
          text={strings('createNotificationScreen.button.save')}
          style={{
            width: '100%',
            borderRadius: 0,
          }}
          onPress={onSave}
        />
      )}
    </View>
  );
};

const ConnectedEditNotification = connectActionSheet(EditNotification);

export default connect(
  (state) => ({
    user: state.auth.user,
    config: state.config,
    notificationList: state.notification,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        addNotificationInList,
        updateNotificationInList,
        setLoading: CommonActions.setLoading,
        openAlert: (content) =>
          CommonActions.setAlert({visible: true, content: content}),
      },
      dispatch,
    ),
)(ConnectedEditNotification);
