import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {TouchableOpacity, Image, View} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {AppText, MainContainer, MainHeader} from '../../../components';
import {strings} from '../../../i18n';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {deleteNotification} from '../../../redux/reduxsauceActions/notificationActions';
import {dateTimeFormat, getParam, goBack, setValue} from '../../../utils';
import styles from './styles';

const NotificationDetailScreen = (props) => {
  const [notificationData, setNotificationData] = useState({});
  const [images, setImages] = useState(null);
  const {navigation, route, notificationList} = props;

  // useEffect(() => {
  //   console.log('route ------------->',route);
  //   // setImages(route?.params?.data?.photo);
  //   setNotificationData(route?.params?.data);
  // },[]);

  useEffect(() => {
    let updatedData = notificationList.find(
      (a, index) => a.id == route?.params?.data.id,
    );
    setImages({uri: updatedData?.photo});
    setNotificationData(updatedData);
  }, [notificationList]);

  return (
    <View style={styles.container}>
      <MainHeader
        title={notificationData?.title}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
        rightIcon={'edit'}
        rightPress={() =>
          props?.navigation?.navigate('edit-notifications', notificationData)
        }
      />
      <MainContainer useView>
        <View style={styles.notificationContainer}>
          <View>
            <View
              style={{
                borderRadius: setValue(10),
                overflow: 'hidden',
                marginVertical: setValue(10),
              }}
            >
              <Image
                style={{width: '100%', height: 200}}
                source={
                  images?.uri
                    ? {uri: images?.uri}
                    : require('../../../../assets/images/General/no-img-found.jpg')
                }
              />
            </View>
          </View>

          <View>
            {/* <AppText style={styles.title}>{notificationData?.title}</AppText> */}
            <AppText style={styles.description}>
              {strings('common.description')}
            </AppText>
            <AppText style={styles.description}>
              {notificationData?.description}
            </AppText>
            <AppText style={styles.date}>
              {dateTimeFormat(new Date(notificationData?.created_at))}
            </AppText>
          </View>
          {notificationData?.to_user && (
            <View style={styles.itemContainer}>
              <TouchableOpacity
                style={styles.button}
                onPress={() => {
                  props.openAlert(
                    strings('notificationScreen.deleteMessage'),
                    strings('settingScreen.confirm'),
                    async () => {
                      await props.deleteNotification(notificationData?.id);
                      goBack(navigation);
                    },
                    strings('settingScreen.cancel'),
                    null,
                  );
                }}
              >
                <AppText style={styles.okText}>
                  {strings('common.deleteMessage')}
                </AppText>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </MainContainer>
    </View>
  );
};

export default connect(
  (state) => ({
    notificationList: state.notification,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        deleteNotification,
        openAlert: (content, leftButton, leftPress, rightButton, rightPress) =>
          CommonActions.setAlert({
            visible: true,
            content,
            leftButton,
            leftPress,
            rightButton,
            rightPress,
          }),
      },
      dispatch,
    ),
)(NotificationDetailScreen);
