import {Platform, StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 8,
  borderRadius: 5,
  backgroundColor: Colors.white,
};
export default StyleSheet.create({
  orderStatusStepContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 400,
    alignSelf: 'center',
    marginTop: Platform.OS == 'web' ? 15 : 0,
  },
  separator: {
    height: setXAxisValue(2),
    width: '90%',
    position: 'absolute',
    backgroundColor: '#4D4D4D',
    top: setXAxisValue(25),
    left: setXAxisValue(5),
  },
  doneStep: {
    width: setXAxisValue(50),
    height: setXAxisValue(50),
    backgroundColor: '#8CC63F',
    borderRadius: setXAxisValue(10),
    justifyContent: 'center',
    alignItems: 'center',
  },
  declinedStep: {
    backgroundColor: '#c64141',
  },
  undoneStep: {
    width: setXAxisValue(50),
    height: setXAxisValue(50),
    backgroundColor: '#4D4D4D',
    borderRadius: setXAxisValue(10),
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepIcon: {
    width: setXAxisValue(24),
    height: setXAxisValue(24),
  },
  stepIconForPaid: {
    width: setXAxisValue(40),
    height: setXAxisValue(40),
  },
  processIcon: {
    width: setXAxisValue(30),
    height: setXAxisValue(30),
  },
  cardContainer: {
    marginVertical: setYAxisValue(15),
    marginHorizontal: setXAxisValue(5),
    ...shadow,
  },
  detailCard: {
    backgroundColor: 'white',
    borderRadius: 5,
    padding: setValue(10),
  },
  cardTitle: {
    fontSize: setValue(16),
    paddingBottom: setValue(10),
  },
  cardTitleLine: {
    height: 1,
    backgroundColor: 'gray',
    marginHorizontal: setValue(-10),
  },
  detailItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: setYAxisValue(5),
  },
  detailLabel: {
    marginRight: 5,
    width: '40%',
  },
  detailValue: {
    width: '58%',
    textAlign: 'right',
  },
  orderDetailItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: setYAxisValue(5),
  },
  orderTotalLine: {
    paddingTop: 5,
    borderTopWidth: 0.8,
    borderTopColor: Colors.color30,
    marginHorizontal: setValue(-10),
    marginTop: setValue(10),
  },
  itemName: {
    fontSize: setValue(14),
  },
  itemDetail2Container: {
    width: '50%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  itemQuantityText: {
    textAlign: 'center',
    width: '35%',
    paddingHorizontal: 2,
  },
  priceText: {
    width: '100%',
    textAlign: 'right',
    paddingHorizontal: 2,
  },
  btn: {
    width: '49%',
    paddingVertical: 12,
    alignItems: 'center',
    justifyContent: 'center',
    ...shadow,
  },
  declineBtnText: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
    color: Colors.wallet_card_red,
  },
  acceptBtn: {
    backgroundColor: Colors.theme_color,
  },
  acceptBtnText: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
    color: Colors.white,
  },
  productVarientsText: {
    fontSize: 12,
    textAlignVertical: 'bottom',
    color: 'gray',
  },
});
