import {View, FlatList, TouchableOpacity, TextInput} from 'react-native';
import React, {useEffect, useState} from 'react';
import {
  CustomerItem,
  Icon,
  MainContainer,
  MainHeader,
  NoRecordFound,
  SegmentControl,
} from '../../../components';
import {goBack, setValue} from '../../../utils';
import styles from './styles';
import {strings} from '../../../i18n';
import axios from 'axios';
import {Colors} from '../../../constants';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {useDispatch} from 'react-redux';

const SelectCustomerFromList = (props) => {
  const {selectedCustomerDetail} = props.route.params;

  const [selectedCustomer, setSelectedCustomer] = useState(
    selectedCustomerDetail?.id,
  );
  const [process, setProcess] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selected, setSelected] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchCustomerList();
  }, [searchText, selected]);

  let cancelToken = axios.CancelToken.source();

  const fetchCustomerList = async () => {
    dispatch(CommonActions.setLoading(true));
    try {
      // if (searchText) {
      //   url += '?search=' + searchText;
      // } else {
      let url = '';
      if (selected == 0) {
        url = '/user/business/list?type=client';
      } else {
        url = '/customers/list';
      }
      if (searchText) {
        url += '?search=' + searchText;
      } else {
        url;
      }
      // }
      if (cancelToken) {
        cancelToken.cancel();
        cancelToken = axios.CancelToken.source();
      }
      const response = await axios.get(url, {cancelToken: cancelToken.token});
      console.log('response ---------->', response);
      let data = response.data;
      let neList = [];
      data.forEach((a) => {
        let detail = a?.id == selectedCustomer ? {...a, checked: true} : a;
        neList.push(detail);
      });
      setCustomerList([...neList]);
      dispatch(CommonActions.setLoading(false));
    } catch (error) {
      console.log('error', error);
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message || 'Something went wrong :(',
        }),
      );
    }
  };

  const onCheckCustomerItem = (item) => {
    // debounce condition for navigation
    if (process) return;
    setProcess(true);
    let selectedID = null;
    const array = customerList.map((o, i) => {
      if (o.id !== item.id)
        return {
          ...o,
          checked: false,
        };
      if (o.id === item.id) {
        const item = {
          ...o,
          checked: true,
        };
        if (item.checked) {
          setSelectedCustomer(item.id);
          selectedID = item.id;
          return item;
        } else {
          setSelectedCustomer('');
          return item;
        }
      }
      return o;
    });
    setCustomerList(array);
    onSelectCustomerFromList(selectedID);
  };

  const onSelectCustomerFromList = (data = selectedCustomer) => {
    if (!data || data == '') {
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: strings('editInvoice.alert.customerSelect'),
        }),
      );
      return;
    }
    props.route?.params?.selectedCustomer({
      detail: customerList.find((a) => a.id == data),
      isUser: selected == 0 ? true : false,
    });
    props.navigation.goBack();
  };
  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('editInvoice.customerButton.selectCustomer')}
        leftIcon={'back'}
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer useView>
        <View style={styles.textInputContainer}>
          <TextInput
            underlineColorAndroid="transparent"
            autoCapitalize="none"
            autoCorrect={false}
            placeholder={strings('transactionHistoryScreen.search')}
            placeholderTextColor={Colors.color30}
            style={styles.textInput}
            returnKeyType="done"
            value={searchText}
            onChangeText={(text) => {
              setSearchText(text);
              fetchCustomerList();
            }}
          />

          {searchText.length === 0 ? (
            <TouchableOpacity style={styles.inputRightSide} disabled>
              <Icon name="search" size={20} color={Colors.black20} />
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              style={styles.inputRightSide}
              onPress={() => setSearchText('')}
            >
              <Icon name="cross" size={20} color="gray" />
            </TouchableOpacity>
          )}
        </View>
        <SegmentControl
          values={['Online', 'Offline']}
          selectedIndex={selected}
          onChange={(index) => {
            if (typeof cancelToken != typeof undefined) {
              cancelToken.cancel(
                strings('coinTransactionList.alert.cancelToken'),
              );
            }
            setSelected(index);
          }}
        />

        {customerList.length == 0 ? (
          <NoRecordFound title={'Customers'} message={'No customer found'} />
        ) : (
          <FlatList
            data={customerList}
            keyExtractor={(item) => item.id}
            renderItem={({item, index}) => {
              return (
                <CustomerItem
                  item={item}
                  onPressCustomer={() => onCheckCustomerItem(item)}
                />
              );
            }}
          />
        )}
      </MainContainer>
    </View>
  );
};

export default SelectCustomerFromList;
