import Constants from 'expo-constants';
import {StyleSheet} from 'react-native';

import {Colors, Layout} from '../../constants';
import {screenWidth, setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 8,
};

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  currency: {
    fontSize: setXAxisValue(16),
    marginRight: setXAxisValue(5),
    paddingTop: setYAxisValue(2),
  },
  cardContainer: {
    ...shadow,
    flexDirection: 'row',
    marginBottom: setValue(15),
    borderRadius: 12,
    backgroundColor:Colors.white,
  },
  infoContainer: {
    width: '75%',
    flexDirection: 'row',
    paddingHorizontal: setXAxisValue(15),
    paddingVertical: setYAxisValue(20),
    alignItems: 'center',
  },
  avatar: {
    width: setXAxisValue(70),
    height: setXAxisValue(70),
    borderRadius: setXAxisValue(70) / 2,
  },
  qrContainer: {
    width: '25%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.white,
    paddingHorizontal: setXAxisValue(15),
    paddingVertical: setYAxisValue(20),
    borderRadius: 12,
  },
  infoContent: {
    marginLeft: setXAxisValue(15),
    width: '65%',
  },
  name: {
    fontSize: setXAxisValue(16),
    color: Colors.black20,
    fontWeight:"700",
    textTransform: 'capitalize',
  },
  phone: {
    fontSize: setXAxisValue(16),
    color: Colors.black20,
    marginVertical: setYAxisValue(7),
    letterSpacing: setXAxisValue(2),
  },
  email: {
    fontSize: setXAxisValue(12),
    color: Colors.black20,
    marginVertical: setYAxisValue(7),
  },
  date: {
    fontSize: setXAxisValue(11),
    color: Colors.black20,
  },
  voucherContainer: {
    ...shadow,
    backgroundColor: Colors.white,
    borderRadius: 8,
    marginBottom: setYAxisValue(15),
    width: '100%',
  },
  coinCardContainer:{
    backgroundColor:Colors.white,
    borderRadius:5,
    marginBottom:15,
    ...shadow
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: Colors.white,
    borderRadius: 8,
    alignItems: 'center',
    padding: setValue(20),
    paddingVertical: setValue(15),
  },
  numberContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  number: {
    fontSize: setXAxisValue(20),
    marginRight: setXAxisValue(10),
  },
  historyContainer: {
    ...shadow,
    backgroundColor: Colors.white,
    borderRadius: 8,
    width: '100%',
    marginBottom: setYAxisValue(20),
  },
  overlay: {
    flex: 1,
    backgroundColor: Colors.overlay,
    alignItems: 'center',
  },
  modalContainer: {
    borderRadius: 8,
    backgroundColor: Colors.white,
    width: Layout.window.width * 0.9,
    paddingVertical: setYAxisValue(20),
    paddingHorizontal: setXAxisValue(20),
    marginTop: Constants.statusBarHeight + setYAxisValue(100),
  },
  modalInfoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalName: {
    fontSize: setXAxisValue(16),
    color: Colors.white,
    alignSelf: 'center',
    marginLeft: setXAxisValue(10),
  },
  modalPhone: {
    fontSize: setXAxisValue(20),
    color: Colors.white,
    marginVertical: setYAxisValue(15),
    letterSpacing: setXAxisValue(5),
    alignSelf: 'center',
  },
  modalDate: {
    fontSize: setXAxisValue(10),
    color: Colors.white,
    alignSelf: 'center',
  },
  dividerView: {
    height: '100%',
    width: 1,
    backgroundColor: Colors.border,
  },
  tierContainer: {
    ...shadow,
    backgroundColor: Colors.white,
    borderRadius: 8,
    marginBottom: setYAxisValue(15),
    flexDirection: 'row',
  },
  tierTitleText: {
    fontSize: setXAxisValue(12),
    //
    marginTop: setYAxisValue(15),
    color: '#8c8c8c',
  },
  tierContentText: {
    fontSize: setXAxisValue(22),
    //
    marginTop: setYAxisValue(5),
    marginBottom: setYAxisValue(10),
  },
  tierDetailText: {
    fontSize: setXAxisValue(11),
    marginBottom: setYAxisValue(15),
    color: '#8c8c8c',
    paddingHorizontal: setXAxisValue(20),
    paddingVertical: setYAxisValue(5),
  },
  buttonGoToList: {
    borderRadius: 15,
    borderColor: '#8c8c8c',
    borderWidth: 1,
  },
  historyTitleBackground: {
    ...shadow,
    backgroundColor: Colors.theme_color,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 25,
    width: 200,
    height: 40,
    marginTop: -20,
    marginBottom: 13,
  },
  historyTitle: {
    color: Colors.white,
    alignContent: 'center',
    // fontFamily: ''
  },
  tooltip: {
    padding: setValue(10),
    textAlign: 'center',
  },
});
