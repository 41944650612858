import { StyleSheet } from 'react-native';

import { Colors, Layout } from '../../constants';
import { setYAxisValue } from '../../utils';

export default StyleSheet.create({
  container: {
    flex: 1
  },
  body: {
    marginTop: setYAxisValue(20)
  },
  webView: {
    height: Layout.window.height * 0.8,
    borderRadius: 8,
    marginBottom: setYAxisValue(20)
  },
  webViewContainer: {
    marginTop: setYAxisValue(20),
    height: Layout.window.height * 0.8,
    backgroundColor: Colors.white,
    borderRadius: 8
  }
});
