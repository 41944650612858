import {Appearance, Platform, StyleSheet} from 'react-native';

import {Colors, Layout} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';
// const colorScheme = Appearance.getColorScheme();
// const backgroundColor = colorScheme == 'dark' ? Colors.black : Colors.white;

const shadow = {
  shadowColor: Colors.black60,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 5,
};

export default StyleSheet.create({
  categoryButtonIconContainer: {
    backgroundColor: 'white',
    borderRadius: setValue(10),
    width: setValue(60),
    height: setValue(60),
    marginRight: setXAxisValue(5),
    alignItems: 'center',
    justifyContent: 'center',
    ...shadow,
  },
  categoryImages: {
    width: setValue(50),
    height: setValue(50),
  },
  categoryButtonText: {
    fontSize: setXAxisValue(12),
    textAlign: 'center',
    color: Colors.black60,
    marginVertical: setYAxisValue(5),
    marginBottom: setYAxisValue(8),
    // marginLeft: setYAxisValue(8),
    width: 60,
  },
  selected: {
    borderColor: Colors.black,
    borderWidth: setValue(4),
  },
  image: {
    flex: 1,
    width: undefined,
    height: undefined,
    overflow: 'hidden',
    borderRadius: 150 / 2,
  },
  categoryImage: {
    width: setValue(60),
    height: setValue(60),
  },
});
