import {StyleSheet} from 'react-native';

import {Colors, Layout} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

const wrapper = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 3,
  elevation: 3,
  marginHorizontal: setXAxisValue(5),
  backgroundColor: Colors.white,
};

export default StyleSheet.create({
  true: {
    ...wrapper,
    alignItems: 'center',
    borderRadius: 8,
    paddingHorizontal: setXAxisValue(10),
    paddingVertical: setYAxisValue(15),
    flexDirection: 'row',
    backgroundColor: Colors.primary_color,
    marginBottom:setValue(12),
    marginHorizontal:0,
  },
  title: {
    fontSize: setXAxisValue(14),
    fontFamily: 'Roboto-Bold',
    textAlign: 'left',
  },
  content: {
    fontSize: setXAxisValue(12),
    marginVertical: setYAxisValue(5),
  },
  date: {
    fontSize: setXAxisValue(12),
  },
});
