import React, {useEffect} from 'react';
import {View, FlatList, Platform} from 'react-native';
import {
  MainHeader,
  MainContainer,
  AddressItem,
  LongButton,
} from '../../../components';
import {goBack, setValue} from '../../../utils';
import styles from './styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {strings} from '../../../i18n';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import {
  pickupLocationList,
  deletePickupLocation,
} from '../../../redux/reduxsauceActions/authActions';
import {SwipeRow} from '../../../components';
import NoRecordFound from '../../../components/NoRecordFound';
import {useIsFocused} from '@react-navigation/native';

function PickupLocationScreen(props) {
  const isFocused = useIsFocused();

  useEffect(() => {
    props.pickupLocationList();
  }, [isFocused]);

  return (
    <View style={Platform.OS == 'web' ? styles.webContainer : styles.container}>
      <MainHeader
        title={'Pickup Location'} // after we add in language json file
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer useView={Platform.OS === 'web' ? false : true}>
        {props.pickupLocation?.length > 0 || !props.pickupLocation ? (
          <FlatList
            data={props.pickupLocation}
            showsVerticalScrollIndicator={false}
            keyExtractor={(item, index) => index}
            renderItem={({item, index}) => (
              <SwipeRow
                editable={false}
                leftOpenValue={70}
                rightOpenValue={-100}
                onPressEdit={() =>
                  props.navigation.navigate('edit-pickup-location', {
                    item: item,
                    edit: true,
                  })
                }
                onPressDelete={() =>
                  props.openAlert(
                    'Are you sure delete Pickup Location?',
                    strings('settingScreen.confirm'),
                    async () => {
                      props.deletePickupLocation(item.id);
                    },
                    strings('settingScreen.cancel'),
                    null,
                  )
                }
              >
                <AddressItem isVerified item={item} key={index} />
              </SwipeRow>
            )}
          />
        ) : (
          <NoRecordFound title="Pickup Locationes" />
        )}
        {/* if platform is web then edit PickupLocation button with flat list  */}
        {Platform.OS == 'web' && (
          <LongButton
            text={'Add Pickup Location'}
            style={{marginTop: setValue(10), marginBottom: setValue(45)}}
            onPress={() => props.navigation.navigate('edit-pickup-location')}
          />
        )}
      </MainContainer>

      {/* if Platform os other then web then button is end of flex */}
      {Platform.OS != 'web' && (
        <LongButton
          text={'Add Pickup Location'}
          style={{
            width: '100%',
            borderRadius: 0,
          }}
          onPress={() => props.navigation.navigate('edit-pickup-location')}
        />
      )}
    </View>
  );
}

export default connect(
  (state) => {
    const {user: profile, pickupLocation} = state.auth;
    return {
      appConfig: state.config,
      profile,
      pickupLocation,
    };
  },
  (dispatch) =>
    bindActionCreators(
      {
        pickupLocationList,
        deletePickupLocation,
        openAlert: (content, leftButton, leftPress, rightButton, rightPress) =>
          CommonActions.setAlert({
            visible: true,
            content,
            leftButton,
            leftPress,
            rightButton,
            rightPress,
          }),
      },
      dispatch,
    ),
)(PickupLocationScreen);
