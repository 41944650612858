import {StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {
  screenHeight,
  screenWidth,
  setValue,
  setXAxisValue,
  setYAxisValue,
} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 5,
};

export default StyleSheet.create({
  sliderItemContainer: {
    backgroundColor: Colors.white,
    marginTop: 10,
    borderRadius: 5,
    ...shadow,
  },
  imageContainer: {
    // padding: 5,
  },
  sliderImage: {
    aspectRatio: 4 / 2,
    width: '100%',
    borderRadius: 5,
  },
  deleteBtn: {
    backgroundColor: 'rgba(255, 0, 0,0.8)',
    position: 'absolute',
    zIndex: 10000,
    right: 20,
    top: 20,
    borderRadius: 3,
    elevation: 5,
  },
  deleteIcon: {
    padding: 5,
  },
});
