import {connectActionSheet} from '@expo/react-native-action-sheet';
import Constants from 'expo-constants';
import * as ImagePicker from 'expo-image-picker';
import get from 'lodash.get';
import moment from 'moment';
import React, {Component} from 'react';
import PhoneInput from '../../../lib/react-native-phone-input';
import {
  ActivityIndicator,
  Image,
  Keyboard,
  Platform,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import {SwipeRow} from 'react-native-swipe-list-view';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
  AppText,
  DatePicker,
  DropDownInput,
  Icon,
  MainContainer,
  MainHeader,
  SegmentControl,
} from '../../../components';
import {Colors, Images} from '../../../constants';
import {strings} from '../../../i18n';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import AuthActions from '../../../redux/reduxsauce/authRedux';
import {
  editProfile,
  removeProfilePicture,
  uploadProfilePicture,
} from '../../../redux/reduxsauceActions/profileActions';
import {dataURLtoFile, goBack, setValue} from '../../../utils';
import styles from './styles';
import axios from 'axios';

class EditProfileScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // gender: props.profile.gender
      //   ? capitalizeFirstLetter(props.profile.gender)
      //   : '',
      isDateVisible: false,
      date: {
        dateString: props.profile.dateOfBirth,
      },
      imageLoading: false,
      image: props?.profile?.photo,
      name: props.profile.first_name,
      nickname: props.profile.nickname,
      lastName: props.profile.last_name,
      tab: 0,
      phone: props.profile.phone,
      email: props.profile.email,
    };
  }

  onSave = () => {
    Keyboard.dismiss();

    const {name, nickname, lastName, gender} = this.state;
    const {navigation} = this.props;
    this.props.editProfile(
      {
        name,
        lastName,
        // gender: gender.toLowerCase(),
      },
      navigation,
    );
  };

  renderAnniversaryAndAddress = () => (
    <View style={styles.ANAContainer}>
      <SegmentControl
        values={[
          strings('profileScreen.segment.address'),
          strings('profileScreen.segment.anniversary'),
        ]}
        selectedIndex={this.state.tab}
        onChange={(index) => this.setState({tab: index})}
      />
      {this.state.tab === 0 && this.renderAddress()}
      {this.state.tab === 1 && this.renderAnniversary()}
    </View>
  );

  renderAddress = () => (
    <View>
      <View style={styles.headerAddress}>
        <AppText style={styles.addressHeader}>
          {strings('profileScreen.listAddress')}
        </AppText>
        {this.props.profile.addresses.length < 2 && (
          <TouchableOpacity
            style={styles.addIcon}
            onPress={() => this.props.navigation.navigate('AddNewAddress')}
          >
            <Icon name="plus" color={Colors.white} />
          </TouchableOpacity>
        )}
      </View>
      <View>
        {this.props.profile.addresses.map((i) => (
          <SwipeRow key={i.id} disableRightSwipe rightOpenValue={-70}>
            <TouchableOpacity
              style={styles.hiddenButton}
              // onPress={() => this.props.deleteAddress(i.id)}
            >
              <Icon name="dustBinSmaller" color="black" />
            </TouchableOpacity>
            <TouchableWithoutFeedback
              onPress={() =>
                this.props.navigation.navigate('edit-address', {id: i.id})
              }
            >
              <View style={styles.standaloneRowFront}>
                <View>
                  <AppText style={styles.title}>{i.name}</AppText>
                  <AppText
                    style={styles.content}
                  >{`${i.street1}, ${i.street2}, ${i.postCode}, ${i.cityTown}, ${i.countryCode}`}</AppText>
                </View>
                <Icon name="edit" color="black" size={16} />
              </View>
            </TouchableWithoutFeedback>
          </SwipeRow>
        ))}
      </View>
    </View>
  );

  openActionSheet = () => {
    const options = [
      strings('actionSheet.takePhoto'),
      strings('actionSheet.choosePhoto'),
      strings('actionSheet.removePhoto'),
      strings('actionSheet.cancel'),
    ];
    const cancelButtonIndex = 3;

    this.props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) this.openCamera();
        if (buttonIndex === 1) this.openImagePicker();
        if (buttonIndex === 2) this.removePhoto();
      },
    );
  };

  removePhoto = async () => {
    const {profile} = this.props;
    this.setState({
      imageLoading: true,
    });
    let res = await this.props.removeProfilePicture();
    if (res) {
      this.setState({
        imageLoading: false,
        image: '',
      });
    }
  };

  openCamera = async () => {
    const {profile} = this.props;
    await ImagePicker.requestCameraPermissionsAsync();

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
      base64: true,
    });

    if (!result.cancelled) {
      this.setState({
        imageLoading: true,
      });
      let blob = await (await fetch(result?.uri)).blob();
      let res = await this.props.uploadProfilePicture(
        Platform.OS != 'web'
          ? {
              uri: result.uri,
              type: blob.type,
              name: blob.data.name,
            }
          : await dataURLtoFile(result.uri),
      );
      this.setState({
        imageLoading: false,
        image: res,
      });
    }
  };

  openImagePicker = async () => {
    const {profile} = this.props;
    await ImagePicker.requestMediaLibraryPermissionsAsync();

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
      base64: true,
    });

    if (!result.cancelled) {
      this.setState({
        imageLoading: true,
      });
      let blob = await (await fetch(result?.uri)).blob();
      let res = await this.props.uploadProfilePicture(
        Platform.OS != 'web'
          ? {
              uri: result.uri,
              type: blob.type,
              name: blob.data.name,
            }
          : await dataURLtoFile(result.uri),
      );
      this.setState({
        imageLoading: false,
        image: res,
      });
    }
  };

  renderProfileStrength = () => {
    const {image, imageLoading} = this.state;

    console.log('image loading', imageLoading);
    return (
      <View style={styles.profileStrengthContainer}>
        <TouchableOpacity onPress={this.openActionSheet}>
          <View style={styles.imageContainer}>
            <Image
              source={
                image
                  ? {
                      uri: image,
                    }
                  : Images.profile
              }
              style={styles.image}
            />
          </View>
        </TouchableOpacity>
        {imageLoading && (
          <View
            style={{
              position: 'absolute',
              zIndex: 1000,
              top: '40%',
            }}
          >
            <ActivityIndicator size="small" color={Colors.theme_color} />
          </View>
        )}
      </View>
    );
  };

  renderBasicInfo = () => {
    const {appConfig} = this.props;
    const minAge = get(appConfig, 'memberMinAge', 18);
    const maxYear = moment().year() - Number(minAge);
    const socialAccTypes =
      this.props.profile?.social_profiles.length > 0 &&
      this.props.profile.socialAccounts.map((i) => i.accType);

    return (
      <View style={styles.basicInfoContainer}>
        <View style={styles.infoBody}>
          <View style={styles.textInputContainer}>
            <AppText style={styles.infoTitle}>
              {strings('profileScreen.firstName')}
            </AppText>
            <TextInput
              style={styles.infoText}
              underlineColorAndroid="transparent"
              autoCapitalize="none"
              autoCorrect={false}
              placeholder={strings('profileScreen.firstName')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              value={this.state.name}
              onChangeText={(text) => this.setState({name: text})}
            />
          </View>
          <View style={styles.textInputContainer}>
            <AppText style={styles.infoTitle}>
              {strings('profileScreen.lastName')}
            </AppText>
            <TextInput
              style={styles.infoText}
              underlineColorAndroid="transparent"
              autoCapitalize="none"
              autoCorrect={false}
              placeholder={strings('profileScreen.lastName')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              value={this.state.lastName}
              onChangeText={(text) => this.setState({lastName: text})}
            />
          </View>
          {/* <View style={styles.textInputContainer}>
            <AppText style={styles.infoTitle}>
              {strings('profileScreen.gender')}
            </AppText>
            <View style={{width: '60%'}}>
              <DropDownInput
                inputStyle={{
                  paddingHorizontal: 10,
                  marginVertical: Platform.OS == 'web' ? 5 : 8,
                }}
                data={[
                  strings('registerScreen.gender.male'),
                  strings('registerScreen.gender.female'),
                ]}
                value={this.state.gender}
                onChangeText={(text) => this.setState({gender: text})}
              />
            </View>
          </View> */}
          <View style={styles.textInputContainer}>
            <AppText style={styles.infoTitle}>
              {strings('profileScreen.dob')}
            </AppText>
            <TouchableOpacity
              style={styles.dateContainer}
              onPress={() => this.setState({isDateVisible: true})}
            >
              <AppText style={styles.infoText}>
                {this.state.date && this.state.date.dateString}
              </AppText>
              <Icon name="calendarSmall" color={Colors.black60} />
            </TouchableOpacity>
          </View>
          <View style={styles.textInputContainer}>
            <AppText style={styles.infoTitle}>
              {strings('profileScreen.phone')}
            </AppText>
            <TouchableOpacity
              style={styles.dateContainer}
              onPress={() => this.props.navigation.navigate('update-phone')}
            >
              <AppText style={styles.infoTextDisable}>
                {this.props.profile?.phone}
              </AppText>
              <Icon size={15} name="refresh" color={Colors.black60} />
            </TouchableOpacity>
          </View>
          <View style={styles.textInputContainer}>
            <AppText style={styles.infoTitle}>
              {strings('profileScreen.email')}
            </AppText>
            <TouchableOpacity
              style={styles.dateContainer}
              onPress={() => this.props.navigation.navigate('update-email')}
            >
              <AppText style={styles.infoTextDisable}>
                {this.props.profile?.email}
              </AppText>
              <Icon size={15} name="refresh" color={Colors.black60} />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  };

  renderButton = () => (
    <View style={styles.buttonContainer}>
      <TouchableOpacity
        style={
          Platform.OS === 'web' ? styles.buttonButtonWeb : styles.buttonButton
        }
        onPress={this.onSave}
      >
        <AppText style={styles.buttonText}>
          {strings('editProfileScreen.save')}
        </AppText>
      </TouchableOpacity>
    </View>
  );

  setupPhoneInput = () => {
    this.setState({
      phone: `+91`,
    });
  };

  renderEditableField = () => {
    const {appConfig} = this.props;
    const minAge = get(appConfig, 'memberMinAge', 18);
    const maxYear = moment().year() - Number(minAge);
    const {name, nickname, lastName, gender, date, phone, email} = this.state;

    return (
      <View>
        <View style={styles.textInputContainer}>
          <Text style={styles.inputLabel}>
            {strings('profileScreen.firstName')}
          </Text>
          <TextInput
            underlineColorAndroid="transparent"
            autoCapitalize="words"
            autoCorrect={false}
            placeholder={strings('profileScreen.firstName')}
            placeholderTextColor={Colors.color30}
            returnKeyType="done"
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={name}
            onChangeText={(text) => this.setState({name: text})}
          />
        </View>

        <View style={styles.textInputContainer}>
          <Text style={styles.inputLabel}>
            {strings('profileScreen.lastName')}
          </Text>
          <TextInput
            ref={(input) => {
              this.lastName = input;
            }}
            underlineColorAndroid="transparent"
            autoCapitalize="words"
            autoCorrect={false}
            placeholder={strings('profileScreen.lastName')}
            placeholderTextColor={Colors.color30}
            returnKeyType="done"
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={lastName}
            onChangeText={(text) => this.setState({lastName: text})}
          />
        </View>

        {/* <View style={styles.textInputContainer}>
          <Text style={styles.inputLabel}>
            {strings('profileScreen.gender')}
          </Text>
          <DropDownInput
            inputStyle={{
              paddingHorizontal: 10,
              marginVertical: Platform.OS == 'web' ? 5 : 8,
            }}
            data={[
              strings('registerScreen.gender.male'),
              strings('registerScreen.gender.female'),
            ]}
            value={gender}
            onChangeText={(text) => this.setState({gender: text})}
          />
        </View> */}

        <View style={styles.textInputContainer}>
          <Text style={styles.inputLabel}>
            {strings('profileScreen.phone')}
          </Text>
          <PhoneInput
            ref={(input) => {
              this.phoneInput = input;
            }}
            style={styles.textInput}
            value={phone}
            disabled
            onSelectCountry={() => {
              this.setState({
                phone: `+91`,
              });
            }}
            inputStyle={{
              height: setValue(50),
              justifyContent: 'center',
            }}
            textProps={{
              underlineColorAndroid: 'transparent',
              autoCapitalize: 'none',
              autoCorrect: false,
              placeholder: strings('profileScreen.phone'),
              placeholderTextColor: Colors.color30,
              keyboardType: 'phone-pad',
              returnKeyType: 'done',
              value: phone,
            }}
          />
        </View>

        <View style={styles.textInputContainer}>
          <Text style={styles.inputLabel}>
            {strings('profileScreen.email')}
          </Text>
          <TextInput
            ref={(input) => {
              this.emailInput = input;
            }}
            editable={false}
            underlineColorAndroid="transparent"
            autoCapitalize="none"
            autoCorrect={false}
            placeholder={strings('profileScreen.email')}
            placeholderTextColor={Colors.color30}
            keyboardType="email-address"
            returnKeyType="done"
            style={
              Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
            }
            value={email}
          />
        </View>
      </View>
    );
  };

  render() {
    const {navigation} = this.props;
    return (
      <View style={styles.container}>
        <MainHeader
          // title={strings('editProfileScreen.profileEditing')}
          title={strings('profileScreen.profileSettings')}
          leftIcon="back"
          leftPress={() => goBack(navigation)}
        />
        <MainContainer>
          {this.renderProfileStrength()}
          {this.renderEditableField()}
          {Platform.OS == 'web' && (
            <View style={styles.buttonContainerWeb}>
              <TouchableOpacity
                style={
                  Platform.OS === 'web'
                    ? styles.buttonButtonWeb
                    : styles.buttonButton
                }
                onPress={this.onSave}
              >
                <AppText style={styles.buttonText}>
                  {strings('editProfileScreen.save')}
                </AppText>
              </TouchableOpacity>
            </View>
          )}
        </MainContainer>
        {Platform.OS !== 'web' && this.renderButton()}
      </View>
    );
  }
}

const ConnectedEditProfileScreen = connectActionSheet(EditProfileScreen);

export default connect(
  (state) => {
    const {
      user: profile,
      // verify: {lastVerify, afterMs},
    } = state.auth;
    return {
      appConfig: state.config,
      profile,
      // isVerify: lastVerify ? moment().valueOf() <= lastVerify + afterMs : false,
    };
  },
  (dispatch) =>
    bindActionCreators(
      {
        uploadProfilePicture,
        removeProfilePicture,
        editProfile,
        setUser: AuthActions.setUser,
        setLoading: CommonActions.setLoading,
      },
      dispatch,
    ),
)(ConnectedEditProfileScreen);
