import {StyleSheet} from 'react-native';
import {Colors} from '../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 5,
};
export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.theme_color,
  },
  loadingView: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textInputContainer: {
    height: setValue(60),
  },
  textInput: {
    height: setValue(50),
    flex: 1,
    paddingHorizontal: setXAxisValue(15),
    marginBottom: setYAxisValue(10),
    fontSize: setXAxisValue(12),
    //
    ...shadow,
  },
  searchBarContainer: {
    flexDirection: 'row',
    // marginTop: setYAxisValue(20),
    height: setValue(50),
    backgroundColor: 'white',
    borderRadius: setValue(8),
    marginBottom: setYAxisValue(10),
    ...shadow,
  },
  searchBarText: {
    color: 'black',
    marginHorizontal: setXAxisValue(20),
    flex: 1,
  },
  searchBarIcon: {
    marginRight: setXAxisValue(10),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  listContainerWeb: {
    height: '80vh',
  },
  listContainer: {
    flex: 1,
  },
  qrCodeScanner: {
    ...shadow,
    width: setValue(60),
    height: setValue(60),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.theme_color,
    borderRadius: setValue(60),

    position: 'absolute',
    zIndex: 10000,
    right: setXAxisValue(10),
    bottom: setYAxisValue(10),
  },
});
