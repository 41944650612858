import React from 'react';
import {View} from 'react-native';
import {Colors} from './../../constants';
import {AppText} from '../../components';
import Icon from '../Icon';
import styles from './styles';
import {setValue} from '../../utils';

const AddressItem = ({item, key, isVerified}) => {
  return (
    <View style={styles.addressCard} key={key}>
      <View style={styles.addressContainer}>
        <AppText style={{fontWeight: 'bold'}}>
          {item?.type?.charAt(0).toUpperCase() + item?.type?.slice(1)}
        </AppText>
        <AppText style={{opacity: 0.7}} numberOfLines={1}>
          {item?.street + ', ' + item?.area + ','}
        </AppText>
        <AppText numberOfLines={1} style={{opacity: 0.7}}>
          {item?.city +
            ', ' +
            item?.state +
            ', ' +
            item?.country +
            ' - ' +
            item?.pincode}
        </AppText>
      </View>
      {isVerified && (
        <View
          style={{justifyContent: 'center', paddingHorizontal: setValue(15)}}
        >
          <Icon
            color={item?.verified ? Colors.green : Colors.dark_yellow}
            name={item?.verified ? 'verified' : 'unverified'}
          />
        </View>
      )}
    </View>
  );
};

export default AddressItem;
