import {StyleSheet, Text, View} from 'react-native';
import React from 'react';
import {Colors} from '../../constants';

const shadow = {
  shadowColor: Colors.black60,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 5,
};

export default StyleSheet.create({
  homeSettingCardContainer: {
    flexDirection: 'row',
    backgroundColor: Colors.white,
    borderRadius: 5,
    ...shadow,
  },
  homeSettingCardContainerWeb: {
    flexDirection: 'row',
    backgroundColor: Colors.white,
    borderRadius: 5,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 5,
  },
  homeSettingCardTitle: {
    color: Colors.black60,
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
  },
});
