import React, {useState, useEffect, useRef} from 'react';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {
  Image,
  Modal,
  FlatList,
  Platform,
  Linking,
  TextInput,
  View,
  TouchableOpacity,
  ActivityIndicator,
  KeyboardAvoidingView,
  Keyboard,
} from 'react-native';
import {Colors} from '../../constants';
import {Icon, MainContainer, MainHeader, AppText} from '../../components';
import {goBack, screenHeight, setValue} from '../../utils';
import styles from './styles';
import moment from 'moment';
import {dateFormat} from '../../utils';
import * as ImagePicker from 'expo-image-picker';
import {strings} from '../../i18n';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';

const LiveSupport = (props) => {
  // const [images, setImages] = useState(null);
  const [chatList, setChatList] = useState([]);
  const [message, setMessage] = useState('');
  const [sendLoading, setSendLoading] = useState(false);

  const user = {
    userId: props?.route?.params?.chatId,
    image: props?.route?.params?.photo
      ? {uri: props?.route?.params?.photo}
      : require('../../../assets/images/General/profile.png'),
    name:
      (props?.route?.params?.type == 'order'
        ? 'ORD00' + props?.route?.params?.id
        : 'PRD00' + props?.route?.params?.id) || 'Live Support',
    status: '24×7 Customer Support',
  };
  // const [lists, setLists] = useState([]);
  const dispatch = useDispatch();
  const config = useSelector((state) => state?.config);
  const textInput = useRef();
  const flatlistRef = useRef();
  const [fatchData, setFatchData] = useState(false);
  const userInfo = useSelector((state) => state.auth.user);

  useEffect(() => {
    setTimeout(() => {
      flatlistRef?.current?.scrollToEnd({animating: true});
    }, 500);
  }, [chatList, fatchData]);

  useEffect(() => {
    getChatData();
    setTimeout(() => {
      flatlistRef?.current?.scrollToEnd({animating: true});
    }, 500);
  }, []);

  const getChatData = async () => {
    dispatch(CommonActions.setLoading(true));
    try {
      const response = await axios.get(
        `/support/chat/list/${props?.route?.params?.chatId}`,
      );
      onFilterList(response.data);
      dispatch(CommonActions.setLoading(false));
    } catch (error) {
      console.log('error -----------', error);
      dispatch(CommonActions.setLoading(false));
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message || 'Something went wrong :(',
        }),
      );
    }
  };
  
  const HeaderTitle = () => {
    return (
      <View style={styles.headerContainer}>
        <View>
          <Image
            style={styles.headerImage}
            source={
              user?.image
                ? user?.image
                : require('../../../assets/images/General/no-img-found.jpg')
            }
          />
        </View>
        <View
          style={{
            justifyContent: 'center',
            // paddingVertical: setValue(2),
            paddingHorizontal: setValue(5),
          }}
        >
          <AppText style={styles.headerTitle}>{user?.name}</AppText>
          {/* <AppText style={styles.headerStatus}>{user?.status}</AppText> */}
        </View>
      </View>
    );
  };
  const onSendMessage = (type, images) => {
    Keyboard.dismiss();
    setSendLoading(true);
    let callThis = images ? true : false;
    if (message != '' || message) {
      callThis = true;
    }
    if (!callThis) {
      setSendLoading(false);
      return;
    }
    let messageData = {};
    (messageData.id = new Date().getTime()),
      (messageData.message = type == 'message' ? message : null),
      (messageData.type = type),
      (messageData.from_merchant = ['merchant', 'manager'].includes(
        userInfo?.type,
      )
        ? true
        : false),
      (messageData.description = messageData?.message),
      (messageData.created_at = new Date()),
      (messageData.support = props?.route?.params?.chatId);

    if (type == 'Image') {
      messageData.image = images;
    } else {
      messageData.image = null;
    }
    setMessage('');
    sendMessageOnServer(messageData);
  };
  const openAlert = (
    content,
    leftButton,
    leftPress,
    rightButton,
    rightPress,
  ) => {
    dispatch(
      CommonActions.setAlert({
        visible: true,
        content,
        leftButton,
        leftPress,
        rightButton,
        rightPress,
      }),
    );
  };
  const sendMessageOnServer = async (body) => {
    try {
      const response = await axios.post('/support/chat/create', body);
      const chatListData = chatList;
      let isExists = chatListData.find((a) => a.date == dateFormat(new Date()));
      let newObject = {};

      if (isExists) {
        newObject.date = dateFormat(new Date());
        let newList = isExists.list;
        newList.push(body);
        newObject.list = newList;
        let existIndex = chatListData.findIndex(
          (a) => a.date == dateFormat(new Date()),
        );
        chatListData[existIndex] = newObject;
        setChatList([...chatListData]);
      } else {
        newObject.date = dateFormat(new Date());
        newObject.list = [body];
        setChatList([...chatList, newObject]);
      }
      flatlistRef?.current?.scrollToEnd({animating: true});
      textInput.current.focus();
      setSendLoading(false);
    } catch (error) {
      setSendLoading(false);
      dispatch(
        CommonActions.setAlert({
          visible: true,
          content: error?.response?.message || 'Something went wrong :(',
        }),
      );
    }
  };

  const onFilterList = async (lists) => {
    let filterList = [];
    lists.forEach((a, b) => {
      if (
        filterList.find(
          (item) => item?.date == dateFormat(new Date(a.created_at)),
        )
      )
        return;
      let chatObject = {};
      chatObject.date = dateFormat(new Date(a.created_at));
      chatObject.list = lists.filter(
        (chat) =>
          dateFormat(new Date(chat.created_at)) ==
          dateFormat(new Date(a.created_at)),
      );
      filterList.push(chatObject);
      console.log('chatObject  ------------------>', chatObject);
    });
    await setChatList([...filterList]);
    setFatchData(true);
  };

  const openCamera = async () => {
    await ImagePicker.requestCameraPermissionsAsync();

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      onSendMessage('Image', result);
    }
    if (!result.cancelled) {
    }
  };
  const checkType = (data) => {
    if (data) {
      return 'merchant';
    } else {
      return 'client';
    }
  };
  const openImagePicker = async () => {
    await ImagePicker.requestMediaLibraryPermissionsAsync();

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      onSendMessage('Image', result);
    }
    if (!result.cancelled) {
    }
  };
  const onDeleteChat = async () => {
    dispatch(CommonActions.setLoading(true));
    await axios
      .delete('/support/delete/' + props?.route?.params?.chatId)
      .then((response) => {
        dispatch(CommonActions.setLoading(false));
        props.navigation.navigate('active-chat-list', {
          deleteId: props?.route?.params?.chatId,
        });
      })
      .catch((error) => {
        console.log('error?.response --------------->', error?.response);
        dispatch(CommonActions.setLoading(false));
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.message || 'Something went wrong :(',
          }),
        );
      });
  };
  return (
    <View style={{flex: 1, backgroundColor: Colors.white_gray}}>
      <MainHeader
        title={<HeaderTitle />}
        leftIcon="back"
        leftPress={() => {
          goBack(props.navigation);
        }}
        rightIcon={
          ['merchant', 'manager'].includes(userInfo?.type) && 'dustBinSmaller'
        }
        rightPress={() => {
          openAlert(
            strings('liveSupport.askDelete'),
            strings('common.yes'),
            async () => onDeleteChat(),
            strings('common.no'),
            null,
          );
        }}
        rightOtherIcon={'info'}
        rightOtherPress={() =>
          props?.route?.params?.type == 'order'
            ? props.navigation.navigate('order-history-detail', {
                id: props?.route?.params?.id,
              })
            : props.navigation.navigate('productDetail', {
                id: props?.route?.params?.id,
              })
        }
      />
      <KeyboardAvoidingView style={styles.mainContainer} behavior="height">
        {/* <View > */}
        {/* <MainContainer useView> */}
        <View style={styles.container}>
          {chatList?.length == 0 ? (
            <View style={styles.chatContainer}>
              {/* <Image
                style={{
                  margin: setValue(20),
                  width: setValue(200),
                  height: setValue(200),
                }}
                source={require('../../../assets/images/demo/logo96.png')}
              />
              <AppText
                style={{
                  textAlign: 'center',
                  fontSize: setValue(20),
                  opacity: 0.5,
                  alignSelf: 'center',
                  color: 'blue',
                }}
              >
                {`Welcome to the ${config?.name}.\n Please send your support and issues`}
              </AppText> */}
            </View>
          ) : (
            <FlatList
              style={{
                overflowY: 'scroll',
                maxHeight:
                  Platform.OS != 'web' ? null : screenHeight - setValue(80),
              }}
              data={chatList}
              ref={flatlistRef}
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              keyExtractor={(item, index) => index}
              renderItem={({item, index}) => (
                <View key={index}>
                  <View style={styles.chatContainer}>
                    <AppText style={styles.date}>{item?.date}</AppText>
                  </View>
                  {item?.list?.map((msg) => (
                    <View
                      key={msg?.id}
                      style={
                        Platform.OS != 'web'
                          ? styles.chatContainer
                          : {alignItems: 'center'}
                      }
                    >
                      <View
                        style={[
                          styles.chatItem,
                          checkType(!msg?.from_merchant) != userInfo.type
                            ? styles.sender
                            : styles.reciver,
                        ]}
                      >
                        <AppText
                          style={[
                            styles.boubleCorner,
                            checkType(!msg?.from_merchant) != userInfo.type
                              ? styles.boubleCornerRight
                              : styles.boubleCornerLeft,
                          ]}
                        ></AppText>
                        {/* {msg?.type != 'Image' ? ( */}
                        <AppText>{msg?.description}</AppText>
                        {/* ) : (
                        <Image
                          style={{
                            borderRadius: setValue(10),
                            width: '100%',
                            minWidth:
                              Platform.OS == 'web' ? setValue(250) : null,
                            minHeight: setValue(250),
                          }}
                          source={{uri: msg?.image?.uri}}
                        />
                      )} */}
                        <AppText style={styles.timeAndTick}>
                          {moment(msg?.created_at).format('hh:mm A')}
                          {/* {item?.userId != user?.userId ? null : !item?.read ? (
                      <Icon size={10} name="tick" />
                    ) : (
                      <Icon size={10} name="tick" />
                    )} */}
                        </AppText>
                      </View>
                    </View>
                  ))}
                </View>
              )}
            />
          )}
        </View>
        {/* </MainContainer> */}
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'flex-end',
            padding: setValue(5),
          }}
        >
          <View style={styles.textInputContainer}>
            {/* <TouchableOpacity style={styles.icons}>
            <Icon color={Colors.black40} name="emoji" />
          </TouchableOpacity> */}
            <View style={styles.textInput}>
              <TextInput
                ref={textInput}
                value={message}
                // multiline={true}
                onSubmitEditing={() => {
                  onSendMessage('message');
                }}
                placeholder={strings('liveSupport.messageType')}
                onChangeText={(text) => setMessage(text)}
              />
            </View>
            <TouchableOpacity style={styles.icons} onPress={openImagePicker}>
              <Icon color={Colors.black40} name="clip" />
            </TouchableOpacity>
            <TouchableOpacity style={styles.icons} onPress={openCamera}>
              <Icon color={Colors.black40} name="camera" />
            </TouchableOpacity>
          </View>
          <TouchableOpacity
            onPress={() => {
              onSendMessage('message');
            }}
            style={styles.sendButton}
          >
            {sendLoading ? (
              <ActivityIndicator color={Colors.white} size={setValue(20)} />
            ) : (
              <Icon color={Colors.white} name="send" size={12} />
            )}
          </TouchableOpacity>
        </View>
        {/* </View> */}
      </KeyboardAvoidingView>
    </View>
  );
};
export default LiveSupport;
