import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {
  TextInput,
  View,
  FlatList,
  TouchableOpacity,
  Platform,
  ActivityIndicator,
} from 'react-native';
import {Colors} from '../../constants';
import {FontAwesome5} from '@expo/vector-icons';
import {
  AppText,
  Icon,
  DatePicker,
  MainContainer,
  CoinItem,
  MainHeader,
  NoRecordFound,
  SegmentControl,
} from '../../components';
import {goBack, isDateValid} from '../../utils';
import styles from './styles';
import {useDispatch, useSelector} from 'react-redux';
import CommonActions from '../../redux/reduxsauce/commonRedux';
// import SelectPickupTimeModal from '../../components/SelectPickupTimeModal';
import moment from 'moment';
import {strings} from '../../i18n';

const CoinDetailScreen = (props) => {
  let cancelToken = axios.CancelToken.source();
  const {navigation, route} = props;
  const [coinData, setCoinData] = useState([]);
  const dispatch = useDispatch();
  const {common} = useSelector((state) => state);
  const [searchText, setSearchText] = useState('');
  const [isDateVisible, setIsDateVisible] = useState(false);
  const [date, setDate] = useState('');
  const [selectType, setSelectType] = useState([
    strings('coinTransactionList.sagmentData.credit'),
    strings('coinTransactionList.sagmentData.debit'),
  ]);
  const [type, setType] = useState(
    strings('coinTransactionList.sagmentData.credit'),
  );
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const limit = 6;

  useEffect(() => {
    setCoinData([]);
    setLoading(true);
    setOffset(0);
    getCoinDataList();
  }, [searchText, date, type]);

  useEffect(() => {
    dispatch(CommonActions.setLoading(true));
    getCoinDataList();
  }, []);

  const footerLoading = () => {
    return (
      <View
        style={{justifyContent: 'center', padding: 10, alignItems: 'center'}}
      >
        <ActivityIndicator size={20} color={Colors.theme_color} />
      </View>
    );
  };
  const getCoinDataList = async (
    search = searchText,
    createAt,
    types = type.toLowerCase(),
  ) => {
    const url =
      date && date != '' && search && search != ''
        ? '/coin/list/?limit=' +
          limit +
          '&offset=' +
          parseInt(offset * limit) +
          '&type=' +
          types.toLowerCase() +
          '&created_at=' +
          (createAt || date) +
          '&search=' +
          search
        : date && date != ''
        ? '/coin/list/?limit=' +
          limit +
          '&offset=' +
          parseInt(offset * limit) +
          '&type=' +
          types.toLowerCase() +
          '&created_at=' +
          (createAt || date)
        : search
        ? '/coin/list/?limit=' +
          limit +
          '&offset=' +
          parseInt(offset * limit) +
          '&type=' +
          types.toLowerCase() +
          '&search=' +
          search
        : '/coin/list/?limit=' +
          limit +
          '&offset=' +
          parseInt(offset * limit) +
          '&type=' +
          types.toLowerCase();

    await axios
      .get(url)
      .then((response) => {
        console.log('response -------------------------->', response);
        dispatch(CommonActions.setLoading(false));
        offset == 0
          ? setCoinData(response?.data?.results)
          : setCoinData(coinData.concat(response?.data?.results));
        setLoading(false);
        setOffset(offset + 1);
      })
      .catch((error) => {
        dispatch(CommonActions.setLoading(false));
        setLoading(false);
        dispatch(
          CommonActions.setAlert({
            visible: true,
            content: error?.response?.message,
          }),
        );
        console.log(error?.response);
      });
  };
  return (
    <View style={styles.container}>
      <MainHeader
        title={strings('coinTransactionList.title')}
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer useView>
        <View style={styles.textInputContainer}>
          <TextInput
            underlineColorAndroid="transparent"
            autoCapitalize="none"
            autoCorrect={false}
            placeholder={strings('coinTransactionList.placehoder.search')}
            placeholderTextColor={Colors.color30}
            style={styles.textInput}
            returnKeyType="done"
            value={searchText}
            onChangeText={(text) => {
              setOffset(0);
              setSearchText(text);
            }}
          />
          {searchText.length === 0 ? (
            <TouchableOpacity style={styles.inputRightSide}>
              <Icon name="search" size={22} color={Colors.black20} />
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              style={styles.inputRightSide}
              onPress={() => setSearchText('')}
            >
              <Icon name="cross" size={22} color="gray" />
            </TouchableOpacity>
          )}
        </View>
        <View style={styles.itemContainer}>
          <AppText style={styles.inputLabel}>
            {strings('coinTransactionList.label.filterByDate')}
          </AppText>
          <DatePicker
            title={
              <AppText style={styles.date}>
                {(date && moment(date).format('yyyy-MM-DD')) ||
                  strings('coinTransactionList.value.selectDate')}
              </AppText>
            }
            visible={isDateVisible}
            maxDate={new Date()}
            onClose={() => {
              setIsDateVisible(false);
            }}
            onDayPress={(date) => {
              setIsDateVisible(false);
              setOffset(0);
              setCoinData([]);
              setOffset(offset + 1);
              setDate(date?.dateString);
            }}
            onCancelPress={() => {
              setIsDateVisible(false);
            }}
            navigation={props.navigation}
            onSetDataForClass={(text) => {
              if (isDateValid(text)) {
                setOffset(0);
                setDate(text);
              }
            }}
            onCalenderPress={() => {
              if (date != '') {
                setOffset(0);
                setCoinData([]);
                setDate('');
                setIsDateVisible(false);
              } else {
                setIsDateVisible(true);
              }
            }}
          />
        </View>
        <View style={{marginTop: 10}}>
          <SegmentControl
            values={selectType}
            selectedIndex={selectType.findIndex((o) => o == type)}
            onChange={async (index) => {
              setCoinData([]);
              setOffset(0);
              if (cancelToken) {
                cancelToken.cancel(
                  strings('coinTransactionList.alert.cancelToken'),
                );
                cancelToken = await axios.CancelToken.source();
              }
              setOffset(0);
              setType(selectType[index]);
              getCoinDataList(searchText, date, selectType[index]);
            }}
          />
        </View>
        {!common.loading && (
          <View style={{flex: 1}}>
            {loading ? (
              <View style={styles.loadingContainer}>
                <ActivityIndicator color={Colors.theme_color} size={45} />
              </View>
            ) : coinData.length > 0 ? (
              <FlatList
                data={coinData}
                keyExtractor={({item}, index) => index}
                onEndReached={() => {
                  setOffset(offset + 1);
                  getCoinDataList();
                }}
                keyboardShouldPersistTaps="always"
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                ListFooterComponent={footerLoading}
                renderItem={({item, index}) => (
                  <CoinItem navigation={navigation} item={item} />
                )}
              />
            ) : (
              <NoRecordFound
                title={strings('coinTransactionList.noReacordFound.title')}
                message={strings('coinTransactionList.noReacordFound.message')}
              />
            )}
          </View>
        )}
      </MainContainer>
    </View>
  );
};
export default CoinDetailScreen;
