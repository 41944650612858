import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import get from 'lodash.get';
import React, {Component} from 'react';
import {
  Image,
  SafeAreaView,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {strings} from '../../i18n';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import AppText from '../AppText';
import DrawerMenuItem from './DrawerMenuItem';
import styles from './styles';
const ENV = Constants.manifest.extra.ENV;

class DrawerMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatarGuide: false,
    };
  }

  // componentDidMount() {
  //   this.handleCheckGuideAvailable();
  // }

  // handleCheckGuideAvailable = async () => {
  //   const guideScreen = await AsyncStorage.getItem('guideScreen');
  //   if (guideScreen !== 'no') {
  //     setTimeout(() => {
  //       this.setState({avatarGuide: true});
  //     }, 1200);
  //   }
  // };

  navigate = (screen) => {
    this.props.navigator.navigate(screen);
    setTimeout(() => this.props.toggleMenu(false), 200);
  };

  render() {
    const {navigator, isLogin, profile, user, appConfig} = this.props;
    const currentVersion = Constants.manifest.version.toString();
    return navigator ? (
      <SafeAreaView style={styles.container}>
        <View>
          {/* <Tooltip
            isVisible={this.state.avatarGuide}
            content={<AppText style={styles.tooltip}>{strings('guide.profileDetail')}</AppText>}
            placement="bottom"
            onClose={() => this.setState({ avatarGuide: false })}
          >
            <TouchableOpacity
              style={styles.imageView}
              onPress={() => {
                if (this.props.profile && !this.state.avatarGuide) this.navigate('edit-profile');
              }}
              disabled={!profile}
            >
              <Image source={profile && profile.imageURI ? { uri: `${APIUtils.ApiUrl}/${profile.imageURI}` } : Images.profile} style={styles.image} />
            </TouchableOpacity>
          </Tooltip> */}
          {!isLogin && (
            <DrawerMenuItem
              name="login"
              title={strings('menu.login')}
              onPress={() => this.navigate('login')}
            />
          )}
          <DrawerMenuItem
            name="home"
            title={strings('menu.home')}
            onPress={() => this.navigate('home')}
          />

          {!appConfig?.theme?.showTabBar && (
            <DrawerMenuItem
              name="products"
              title={strings('menu.products')}
              onPress={() => this.navigate('products')}
            />
          )}
          {!appConfig?.theme?.showTabBar && (
            <DrawerMenuItem
              name="promotion"
              title={strings('menu.promotion')}
              onPress={() => this.navigate('offer')}
            />
          )}

          {/* {isLogin && (
            <DrawerMenuItem
              name="qrCode"
              title={strings('menu.myQR')}
              onPress={() => this.navigate('my-qr')}
            />
          )} */}

          {isLogin && (
            <DrawerMenuItem
              name="setting"
              title={strings('menu.settings')}
              onPress={() => this.navigate('setting')}
            />
          )}
          <DrawerMenuItem
            name="terms"
            title={strings('menu.termPolicy')}
            onPress={() => this.navigate('term-policy')}
          />
          {['merchant', 'manager'].includes(user?.type) && (
            <DrawerMenuItem
              name="contactSupport"
              title={'Support'}
              onPress={() => this.navigate('active-chat-list')}
            />
          )}
          <DrawerMenuItem
            name="contactUs"
            title={strings('menu.contactUs')}
            onPress={() => this.navigate('contact-us')}
          />
        </View>
        <TouchableWithoutFeedback>
          <View style={styles.drawerBottom}>
            <View style={styles.powerView}>
              <AppText style={[styles.drawerBottomEnv]}>
                {appConfig?.ENV != undefined
                  ? ' ' + appConfig?.ENV + ' '
                  : ' local '}
              </AppText>
            </View>
            <View style={styles.powerView}>
              <AppText style={[styles.powerByText]}>
                {strings('menu.poweredBy1')}
              </AppText>
            </View>
            <AppText style={styles.powerByText}>
              {strings('menu.poweredBy2')}
            </AppText>
            <AppText
              style={styles.versionText}
            >{`v ${currentVersion}`}</AppText>
          </View>
        </TouchableWithoutFeedback>
      </SafeAreaView>
    ) : null;
  }
}

export default connect(
  (state) => ({
    appConfig: state.config,
    isLogin: get(state.auth.user, 'id', false),
    profile: state.auth.profile,
    user: state.auth.user,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        toggleMenu: CommonActions.setMenu,
      },
      dispatch,
    ),
)(DrawerMenu);
