import React, {useState} from 'react';
import {TouchableOpacity, View} from 'react-native';
import {Colors} from '../../constants';
import {AppText, Icon} from '../../components';
import CommonAction from '../../redux/reduxsauce/commonRedux';
import styles from './styles';
import DeliveryRistInputModal from '../DeliveryRistInputModal';
import {useDispatch} from 'react-redux';
import {strings} from '../../i18n';

function DeliveryRistComp(props) {
  const {type, list, title} = props;
  const dispatch = useDispatch();

  const [modalVisible, setModalVisible] = useState(false);

  return (
    <View style={styles.container}>
      <AppText style={[styles.inputLabel, styles.titleText]}>{title}</AppText>
      <View style={styles.listContainer}>
        {['add', ...list].map((o, index) => {
          if (o == 'add') {
            return (
              <TouchableOpacity
                style={styles.orderPlus}
                key={index}
                onPress={() => {
                  setModalVisible(true);
                }}
              >
                <Icon name="plus" color={Colors.black40} />
              </TouchableOpacity>
            );
          }
          return (
            <View style={styles.orderList} key={index}>
              <AppText style={{fontSize: 16}}>{o}</AppText>
              <TouchableOpacity
                onPress={() => {
                  dispatch(
                    CommonAction.setAlert({
                      visible: true,
                      content: 'Are you sure you want to delete this pincode?',
                      leftButton: strings('common.yes'),
                      rightButton: strings('common.no'),
                      leftPress: () => {
                        let arr = [...list];
                        arr.splice(index - 1, 1);
                        props.updateList(arr);
                      },
                      rightPress: () => {
                        null;
                      },
                    }),
                  );
                }}
                style={{paddingHorizontal: 5}}
              >
                <Icon name="cross" size={15} color={Colors.black60} />
              </TouchableOpacity>
            </View>
          );
        })}
      </View>

      {/* modal */}
      <DeliveryRistInputModal
        isVisible={modalVisible}
        deliveryRistType={type}
        onClose={() => {
          setModalVisible(!modalVisible);
        }}
        onSave={(value) => {
          let arr = [...list];
          arr = [...arr, value];
          props.updateList(arr);
          setModalVisible(!modalVisible);
        }}
      />
    </View>
  );
}
export default DeliveryRistComp;
