import {Appearance, Platform, StyleSheet} from 'react-native';
import {Colors} from '../../../constants';
import {setValue, setXAxisValue, setYAxisValue} from '../../../utils';
// const colorScheme = Appearance.getColorScheme();
export default StyleSheet.create({
  container: {
    flex: 1,
  },
  searchBarContainer: {
    flexDirection: 'row',
    backgroundColor: 'white',
    borderRadius: setValue(8),
    paddingHorizontal: setXAxisValue(15),
    paddingVertical: setYAxisValue(15),
    marginBottom: setYAxisValue(8),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchBarText: {
    flex: 1,
    marginHorizontal: setXAxisValue(16),
    fontSize: setXAxisValue(14),
  },
  companySearchImage: {
    height: 80,
    width: 80,
    marginHorizontal: setXAxisValue(10),
  },
  addPromotion: {
    width: '100%',
    borderWidth: 2,
    borderColor: Platform.OS == 'web' ? Colors.theme_color : Colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    paddingVertical: setYAxisValue(10),
    borderRadius: 5,
    marginBottom: setValue(15),
  },
  addPromotionText: {
    color: Platform.OS == 'web' ? Colors.theme_color : Colors.white,
  },
});
