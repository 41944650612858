import {View, Text} from 'react-native';
import React, {useEffect, useState} from 'react';
import {MainContainer, MainHeader} from '../../components';
import {useSelector} from 'react-redux';
import {Colors} from '../../constants';
import Constants from 'expo-constants';
import {goBack} from '../../utils';
import axios from 'axios';

const textColor = Constants.manifest.extra.dark ? Colors.white : Colors.black20;

const PayStripeScreen = (props) => {
  let elements;
  const config = useSelector((state) => state.config);
  const stripe = Stripe("pk_test_51LXLl7SFUHuCoB5LgQ0rlDHkArwha5Le9KyRpY28ZaHGLWOG1WjdhehywPXDc97mIecT141jkTMgk0dm7D8jbvdY00j9VjYKKA");
  const [loading, setLoading] = useState(false);

  const {payment, navigationScreen} = props.route.params;

  useEffect(() => {
    async function paymentNow() {
      try {
        const appearance = {
          theme: 'stripe',
        };
        elements = stripe.elements({
          appearance,
          clientSecret: payment.payment_client_key,
        });
        const paymentElement = elements.create('payment');
        paymentElement.mount('#stripe-payment-element');
      } catch (error) {
        console.log('error', error);
      }
    }
    paymentNow();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    stripe
      .confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.origin}`,
        },
        redirect: 'if_required',
      })
      .then(async (result) => {
        console.log('result --->', result);
        if (result.paymentIntent) {
          const response = await axios.get(
            `/business/stripe/callback/` + payment.id,
          );
          console.log('response->', response);
          props.navigation.replace(navigationScreen);
        }
        setLoading(false);
      })
      .catch((error) => {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === 'card_error' || error.type === 'validation_error') {
          //   setShowMessage(error.message);
          alert(error.message);
        } else {
          alert('An unexpected error occured.');
          //   setShowMessage('An unexpected error occured.');
        }
        setLoading(false);
      });
  };

  return (
    <View style={{display: 'flex'}}>
      <MainHeader
        title="Stripe Payment"
        leftIcon="back"
        leftPress={() => goBack(props.navigation)}
      />
      <MainContainer useView>
        <View>
          <form id="stripe-payment-form" onSubmit={handleSubmit}>
            <div id="stripe-payment-element"></div>
            <button
              id="stripe-submit"
              style={{
                backgroundColor: Colors.theme_color,
                borderRadius: 5,
                borderWidth: 0,
              }}
            >
              {loading ? (
                <div
                  className="stripe-spinner hidden"
                  id="stripe-spinner"
                ></div>
              ) : (
                <span
                  id="stripe-button-text"
                  style={{margin: 10, color: textColor}}
                >
                  Pay now
                </span>
              )}
            </button>
            <div id="stripe-payment-message" className="stripe-hidden"></div>
          </form>
        </View>
      </MainContainer>
    </View>
  );
};

export default PayStripeScreen;
