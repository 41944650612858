import React, {useState} from 'react';
import {TouchableOpacity, Image, View, Text} from 'react-native';
import {strings} from '../../i18n';
import AppText from '../AppText';
import styles from './styles';
import Icon from '../Icon';
import {Colors} from '../../constants';
import {setValue} from '../../utils';
import {Fontisto} from '@expo/vector-icons';
import {useSelector} from 'react-redux';
import {get} from 'lodash';

export default function ProductItemList(props) {
  const {item} = props;
  const [imageHeight, setImageHeight] = useState(0);

  const config = useSelector((state) => state.config);
  let currencySymbol = get(config, 'currencySymbol', '₹');
  let categoryImage = null;

  return (
    <TouchableOpacity
      onPress={
        item.outOfStock ? null : () => props.onPressProductItem()
        //   props.navigation.navigate('product-detail', {
        //     addItem: item,
        //   })
      }
      style={styles.productContainer}
      keyExtractor={item.id}
    >
      <View
        style={{
          height: '100%',
          width: '40%',
          justifyContent: 'center',
        }}
      >
        {!item.always_available_stock && item.stock_qty == 0 && (
          <View style={styles.outOfStock}>
            <Text
              style={{
                color: Colors.white,
                fontWeight: 'bold',
                fontSize: setValue(12),
              }}
            >
              {'OUT OF STOCK'}
            </Text>
          </View>
        )}
        <Image
          source={
            item?.images[0]?.photo
              ? {uri: item?.images[0]?.photo}
              : require('../../../assets/images/General/no-img-found.jpg')
          }
          onLayout={(event) => {
            const {height} = event.nativeEvent.layout;
            setImageHeight(height);
          }}
          style={[
            styles.productImage,
            {
              opacity:
                !item.always_available_stock && item.stock_qty == 0 ? 0.5 : 1,
            },
          ]}
        />
      </View>
      <View style={{marginLeft: 5, paddingHorizontal: 10, width: '60%'}}>
        <AppText style={styles.productName} numberOfLines={1}>
          {item.name}
        </AppText>
        <AppText
          style={[
            styles.productPrice,
            {color: Colors.color30, fontSize: setValue(13)},
          ]}
          numberOfLines={2}
        >
          {item.description}
        </AppText>
        <View
          style={{
            flexDirection: 'row',
            position: 'absolute',
            bottom: 0,
            paddingHorizontal: setValue(10),
            alignItems: 'center',
          }}
        >
          <AppText style={styles.productPrice}>
            {`${currencySymbol} ${item.price}  `}
            {item.discount && item.discount != 0 && (
              <AppText style={styles.discountText}>
                {`${currencySymbol} ${
                  parseInt(item.price) +
                  (parseInt(item.price) * parseInt(item.discount)) / 100
                }`}
              </AppText>
            )}
          </AppText>
        </View>
      </View>

      {config.type == 'restaurants' &&
        (item.food_type == 'veg' ? (
          <View style={[styles.FoodType, {borderColor: Colors.green}]}>
            <Fontisto name="record" size={10} color={Colors.green} />
          </View>
        ) : (
          item.food_type == 'non-veg' && (
            <View style={[styles.FoodType, {borderColor: 'red'}]}>
              <Fontisto
                name="record"
                size={10}
                color={Colors.wallet_card_red}
              />
            </View>
          )
        ))}
      {/* {item.stock_qty == 0 && (
        <TouchableOpacity
          onPress={() => props.onPressPlusIcon()}
          style={styles.productAddButton}
        >
          <Icon name="add" color={Colors.black20} />
        </TouchableOpacity>
      )} */}
    </TouchableOpacity>
  );
}
