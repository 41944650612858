import {Platform, StyleSheet} from 'react-native';

import {Colors} from '../../constants';

import {
  setValue,
  setXAxisValue,
  setYAxisValue,
  screenHeight,
} from '../../utils';

const shadow = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.3,
  shadowRadius: 5,
  elevation: 5,
};

export default StyleSheet.create({
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: Colors.overlay,
    justifyContent: 'center',
  },
  container: {
    marginHorizontal: setXAxisValue(15),
    backgroundColor: 'white',
    padding: setValue(15),
    borderRadius: setValue(10),
    maxWidth: 350,
    alignSelf: 'center',
    maxHeight: Platform.OS != 'web' ? screenHeight - setValue(300) : null,
  },
  textTitle: {
    color: 'gray',
    fontSize: setXAxisValue(12),
    marginVertical: setYAxisValue(10),
  },
  textContentUnselected: {
    fontWeight: 'bold',
    marginBottom: setYAxisValue(10),
    marginRight: setXAxisValue(15),
    padding: setValue(10),
    color: Colors.black20,
  },
  button2: {
    width: '48%',
    //height:'30%',
    backgroundColor: Colors.theme_color,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    //paddingVertical: setYAxisValue(3)
  },
  textContentSelected: {
    fontWeight: 'bold',
    marginBottom: setYAxisValue(10),
    marginRight: setXAxisValue(15),
    color: Colors.theme_color,
    padding: setValue(10),
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingTop: setYAxisValue(10),
  },
  button: {
    padding: setValue(15),
    fontSize: setXAxisValue(14),
    //
    color: Colors.white,
  },

  disbaleHourContainer: {
    padding: 5,
    backgroundColor: '#ddd',
    margin: 8,
    borderRadius: 2,
    ...shadow,
  },
  selectedHourContainer: {
    padding: 5,
    backgroundColor: Colors.white,
    margin: 8,
    borderRadius: 2,
    ...shadow,
  },
  hourContainer: {
    padding: 5,
    backgroundColor: Colors.theme_color,
    margin: 8,
    borderRadius: 2,
    ...shadow,
  },
  hourText: {
    padding: 5,
    color: Colors.white,
    textAlign: 'center',
  },
  selectedHourText: {
    padding: 5,
    color: Colors.theme_color,
    textAlign: 'center',
  },
});
