import axios from 'axios';
import get from 'lodash.get';
import React, {Component} from 'react';
import {
  Platform,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
  AppText,
  Icon,
  MainContainer,
  MainHeader,
  Modal,
} from '../../components';
import {Colors} from '../../constants';
import {strings, switchLanguage} from '../../i18n';
import AuthActions from '../../redux/reduxsauce/authRedux';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import {logout} from '../../redux/reduxsauceActions/authActions';
import {goBack, setValue} from '../../utils';
import styles from './styles';
import Constants from 'expo-constants';
import ConfigActions from '../../redux/reduxsauce/configRedux';
import * as Notifications from 'expo-notifications';

const ENV = Constants.manifest.extra.ENV;

const registerForPushNotificationsAsync = async () => {
  let token;
  if (Constants.isDevice) {
    const {status: existingStatus} = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const {status} = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus == 'denied') {
      return;
    }
    if (finalStatus !== 'granted') {
      alert(strings('homeScreen.pushToken') + finalStatus);
      return;
    }
    // {experienceId: '@spyhunteritsolution/whitelabelapp',}
    token = await Notifications.getExpoPushTokenAsync()
      .then((response) => {
        console.log('====>', response);
        return response.data;
      })
      .catch((error) => {
        console.log('error ===>', error);
      });

    return token;
  }
};

class SettingScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      guideScreenActive: true,
      isModalLanguageVisible: false,
      showWebUpdate: false,
      latestVersion: '0.0.0',
      isModalEnvVisible: false,
      expoToken: '',
    };
  }

  componentDidMount() {
    // this.unsubscribe = this.props.navigation.addListener('blur', () => {
    //   AsyncStorage.setItem(
    //     'guideScreen',
    //     this.state.guideScreenActive ? 'yes' : 'no',
    //   );
    // });
    this.getAppUpdate();
    this.props.isLogin &&
      Platform.OS != 'web' &&
      registerForPushNotificationsAsync().then((token) => {
        console.log('token -----', token);
        this.setState({
          expoToken: token,
        });
      });
  }

  getAppUpdate = async () => {
    const currentVersion = Constants.manifest.version;
    let splitCurrentVersion = currentVersion?.split('.');
    let {version} = this.props.config;
    // let version = '1.4.7';
    let newVersion = version?.split('.');
    console.log('version ->', version, currentVersion);
    if (
      version &&
      currentVersion &&
      (parseInt(splitCurrentVersion[0]) < parseInt(newVersion[0]) ||
        parseInt(splitCurrentVersion[1]) < parseInt(newVersion[1]))
    ) {
      this.setState({
        showWebUpdate: true,
        latestVersion: version,
      });
    }
  };

  componentWillUnmount() {
    // this.unsubscribe && this.unsubscribe();
  }
  onDeleteAccount = () => {
    this.props.setLoading(true);
    axios
      .get('/user/delete/account/' + this.props.user?.id)
      .then((response) => {
        this.props.openAlert(response?.data?.message);
        this.props.setLoading(false);
        goBack(this.props.navigation);
        console.log('deleted ------>', response);
      })
      .catch((error) => {
        this.props.setLoading(false);
        this.props.openAlert(error?.response?.message);
      });
  };

  onLogout = () => {
    console.log('token -->', this.state.expoToken);
    this.props.logout(this.props.navigation, this.state.expoToken);
  };

  renderLanguagePopup = () => {
    return (
      <Modal
        visible={this.state.isModalLanguageVisible}
        transparent
        onRequestClose={() => this.setState({isModalLanguageVisible: false})}
      >
        <TouchableWithoutFeedback
          onPress={() => this.setState({isModalLanguageVisible: false})}
        >
          <View style={styles.overlay}>
            <View style={styles.informationContainer}>
              <TouchableOpacity
                onPress={() => {
                  this.setState({isModalLanguageVisible: false});
                  this.props.setLanguage('en');
                  switchLanguage('en', this);
                }}
              >
                <View style={styles.tickView}>
                  <AppText
                    style={[
                      styles.envItem,
                      {
                        fontWeight: this.props.lang === 'en' ? 'bold' : '400',
                      },
                    ]}
                  >
                    English
                  </AppText>
                  {this.props.lang === 'en' && (
                    <Icon
                      style={styles.tick}
                      size={20}
                      name="tick"
                      color={Colors.wallet_card_red}
                    />
                  )}
                </View>
              </TouchableOpacity>
              <View style={styles.divider} />
              <TouchableOpacity
                onPress={() => {
                  this.setState({isModalLanguageVisible: false});
                  this.props.setLanguage('hindi');
                  switchLanguage('hindi', this);
                }}
              >
                <View style={styles.tickView}>
                  <AppText
                    style={[
                      styles.envItem,
                      {
                        fontWeight:
                          this.props.lang === 'hindi' ? 'bold' : '400',
                      },
                    ]}
                  >
                    हिंदी
                  </AppText>
                  {this.props.lang === 'hindi' && (
                    <Icon
                      style={styles.tick}
                      size={20}
                      name="tick"
                      color={Colors.wallet_card_red}
                    />
                  )}
                </View>
              </TouchableOpacity>
              <View style={styles.divider} />
              <TouchableOpacity
                onPress={() => {
                  this.setState({isModalLanguageVisible: false});
                  this.props.setLanguage('ml');
                  switchLanguage('ml', this);
                }}
              >
                <View style={styles.tickView}>
                  <AppText
                    style={[
                      styles.envItem,
                      {
                        fontWeight: this.props.lang === 'ml' ? 'bold' : '400',
                      },
                    ]}
                  >
                    ગુજરાતી
                  </AppText>
                  {this.props.lang === 'ml' && (
                    <Icon
                      style={styles.tick}
                      size={20}
                      name="tick"
                      color={Colors.wallet_card_red}
                    />
                  )}
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    );
  };

  changeEnvironment = (env) => {
    this.props.setAppConfiguration({
      ...this.props?.config,
      ENV: env,
    });
    this.props.logout();
    if (env === 'development') {
      axios.defaults.baseURL = 'https://wa.dev.spyhunteritsolution.in';
    }
    if (env === 'production') {
      axios.defaults.baseURL = 'https://api.whitelabelapp.in';
    }
  };

  renderEnvironmentPopup = () => {
    return (
      <Modal
        visible={this.state.isModalEnvVisible}
        transparent
        onRequestClose={() => this.setState({isModalEnvVisible: false})}
      >
        <TouchableWithoutFeedback
          onPress={() => this.setState({isModalEnvVisible: false})}
        >
          <View style={styles.overlay}>
            <View style={styles.informationContainer}>
              <TouchableOpacity
                onPress={() => {
                  this.changeEnvironment('development');
                  this.setState({isModalEnvVisible: false});
                }}
              >
                <View style={styles.tickView}>
                  <AppText
                    style={[
                      styles.envItem,
                      {
                        fontWeight:
                          this.props.config?.ENV === 'development'
                            ? 'bold'
                            : '400',
                      },
                    ]}
                  >
                    {strings('settingScreen.development')}
                  </AppText>
                  {this.props.config?.ENV === 'development' && (
                    <Icon
                      style={styles.tick}
                      size={20}
                      name="tick"
                      color={Colors.wallet_card_red}
                    />
                  )}
                </View>
              </TouchableOpacity>
              <View style={styles.divider} />
              <TouchableOpacity
                onPress={() => {
                  this.changeEnvironment('production');
                  this.setState({isModalEnvVisible: false});
                }}
              >
                <View style={styles.tickView}>
                  <AppText
                    style={[
                      styles.envItem,
                      {
                        fontWeight:
                          this.props.config?.ENV === 'production'
                            ? 'bold'
                            : '400',
                      },
                    ]}
                  >
                    {strings('settingScreen.production')}
                  </AppText>
                  {this.props.config?.ENV === 'production' && (
                    <Icon
                      style={styles.tick}
                      size={20}
                      name="tick"
                      color={Colors.wallet_card_red}
                    />
                  )}
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    );
  };

  render() {
    const {navigation, openAlert, isLogin, user, config} = this.props;
    const {showWebUpdate, latestVersion} = this.state;

    const array = [
      {
        title: strings('settingScreen.language'),
        visible: isLogin ? true : false,
        onPress: () => this.setState({isModalLanguageVisible: true}),
      },
      {
        title: strings('settingScreen.changePassword'),
        visible: isLogin ? true : false,
        onPress: () => navigation.navigate('change-password'),
      },
      {
        title: strings('settingScreen.notificationSettings'),
        visible: isLogin ? true : false,
        onPress: () => navigation.navigate('notification-settings'),
      },
      {
        title: 'ENV - ' + config.ENV,
        visible: isLogin && user?.is_tester ? true : false,
        onPress: () => this.setState({isModalEnvVisible: true}),
      },
      // business configurations
      {
        title: strings('settingScreen.businessDetails'),
        visible:
          isLogin && ['merchant', 'manager'].includes(user.type) ? true : false,
        onPress: () => navigation.navigate('business-details'),
      },
      {
        title: strings('settingScreen.clientApp'),
        visible:
          isLogin && ['merchant', 'manager'].includes(user.type) ? true : false,
        onPress: () => navigation.navigate('client-setting'),
      },
      {
        title: strings('settingScreen.orderConfiguaration'),
        visible:
          isLogin && ['merchant', 'manager'].includes(user.type) ? true : false,
        appType: ['retail', 'restaurants', 'services'],
        onPress: () => navigation.navigate('order-setting'),
      },
      {
        title: strings('settingScreen.subscriptionConfiguration'),
        visible:
          isLogin && ['merchant', 'manager'].includes(user.type) ? true : false,
        appType: ['retail', 'restaurants', 'services'],
        onPress: () => navigation.navigate('subscription-setting'),
      },
      {
        title: strings('settingScreen.productsConfiguration'),
        visible:
          isLogin && ['merchant', 'manager'].includes(user.type) ? true : false,
        appType: ['retail', 'restaurants'],
        onPress: () => navigation.navigate('product-setting'),
      },
      {
        title: strings('settingScreen.serviceConfiguration'),
        visible:
          isLogin && ['merchant', 'manager'].includes(user.type) ? true : false,
        appType: [ 'services'],
        onPress: () => navigation.navigate('service-setting'),
      },
      {
        title:
          config.type == 'retail'
            ? strings('settingScreen.shoptime')
            : strings('settingScreen.restauranttime'),
        visible:
          isLogin && ['merchant', 'manager'].includes(user.type) ? true : false,
        appType: ['retail', 'restaurants'],
        onPress: () => navigation.navigate('shop-time-setting'),
      },
      {
        title: strings('settingScreen.currencyConfiguaration'),
        visible:
          isLogin && ['merchant', 'manager'].includes(user.type) ? true : false,
        onPress: () => navigation.navigate('currency-setting'),
      },
      {
        title: strings('settingScreen.paymentConfiguration'),
        visible: isLogin && user.type == 'merchant' ? true : false,
        onPress: () => navigation.navigate('payment-configuration-list'),
      },
      {
        title: strings('settingScreen.deliveryPartnerConfig'),
        visible:
          isLogin && ['merchant', 'manager'].includes(user.type) ? true : false,
        appType: ['retail', 'restaurants', 'services'],
        onPress: () => navigation.navigate('delivery-partners'),
      },
      {
        title: strings('settingScreen.pickupLocationConfig'),
        visible:
          isLogin && ['merchant', 'manager'].includes(user.type) ? true : false,
        appType: ['retail', 'restaurants', 'services'],
        onPress: () => navigation.navigate('pickup-location-list'),
      },
      {
        title: strings('settingScreen.tableConfiguration'),
        visible:
          config?.type == 'restaurants' &&
          isLogin &&
          ['merchant', 'manager'].includes(user.type)
            ? true
            : false,
        appType: ['restaurants'],
        onPress: () => navigation.navigate('table-setting'),
      },
      {
        title: strings('settingScreen.franchise'),
        visible:
          (config?.domain == 'demo-retail.whitelabelapp.in' ||
            config?.domain == 'demo-restaurants.whitelabelapp.in') &&
          isLogin &&
          ['merchant'].includes(user.type)
            ? true
            : false,
        appType: ['restaurants'],
        onPress: () => navigation.navigate('franchise'),
      },
      {
        title: strings('settingScreen.emailConfiguration'),
        visible: isLogin && ['merchant'].includes(user.type) ? true : false,
        onPress: () => navigation.navigate('email-configuration'),
      },
      {
        title: 'Donation Configuration',
        visible:
          isLogin && ['merchant', 'manager'].includes(user.type) ? true : false,
        appType: ['ngo'],
        onPress: () => navigation.navigate('donation-configuration'),
      },
      {
        title: 'Referral Configuration',
        visible:
          isLogin && ['merchant', 'manager'].includes(user.type) ? true : false,
        onPress: () => navigation.navigate('referral-setting'),
      },
      {
        title: 'Redeem Coin Configuration',
        visible:
          isLogin && ['merchant', 'manager'].includes(user.type) ? true : false,
        onPress: () => navigation.navigate('redeem-coin-setting'),
      },

      // static details
      {
        title: strings('menu.contactUs'),
        visible: isLogin ? true : false,
        onPress: () => navigation.navigate('contact-us'),
      },
      {
        title: strings('menu.aboutUs'),
        visible: isLogin ? true : false,
        onPress: () => navigation.navigate('about-us'),
      },
      {
        title: 'Social Media Links',
        visible: isLogin ? true : false,
        onPress: () => navigation.navigate('social-media-link'),
      },
      {
        title: strings('registerScreen.termsAndConditions'),
        visible: isLogin ? true : false,
        onPress: () => navigation.navigate('term-policy'),
      },
      {
        title: strings('shippingPolicyScreen.title'),
        visible: isLogin ? true : false,
        appType: ['retail', 'restaurants', 'services'],
        onPress: () => navigation.navigate('shipping-policy'),
      },
      {
        title: strings('refundPolicyScreen.title'),
        visible: isLogin ? true : false,
        appType: ['retail', 'restaurants', 'services' ,'hospitality'],
        onPress: () => navigation.navigate('refund-policy'),
      },
    ];

    return (
      <View style={styles.container}>
        <MainHeader
          title={strings('settingScreen.settings')}
          leftIcon="back"
          leftPress={() => goBack(navigation)}
        />
        <MainContainer>
          <View style={styles.body}>
            {/* {isLogin && (
              <View style={styles.settingSwitchView}>
                <AppText style={styles.title}>
                  {strings('settingScreen.guideScreen')}
                </AppText>
                <Switch
                  onTintColor={Colors.theme_color}
                  activeThumbColor={Colors.white}
                  value={this.state.guideScreenActive}
                  onValueChange={value =>
                    this.setState({guideScreenActive: value})
                  }
                />
              </View>
            )} */}
            {/* {isLogin && (
              <TouchableOpacity style={styles.settingView} onPress={() => navigation.navigate('PinSetting')}>
                <AppText style={styles.title}>{strings('settingScreen.pinSettings')}</AppText>
                <Icon name="forward" size={20} />
              </TouchableOpacity>
            )} */}

            {array.map((o, i) => {
              console.log('setting screen ==>', this.props.config.type);
              if (
                o.visible &&
                (o.appType == undefined ||
                  o.appType.includes(this.props.config.type))
              ) {
                return (
                  <TouchableOpacity
                    style={styles.settingView}
                    onPress={o.onPress}
                    key={i}
                  >
                    <AppText style={styles.title}>{o.title}</AppText>
                    <Icon name="forward" size={20} />
                  </TouchableOpacity>
                );
              } else {
                return null;
              }
            })}

            {Platform.OS == 'web' &&
              isLogin &&
              showWebUpdate &&
              ['merchant', 'manager'].includes(user.type) && (
                <TouchableOpacity
                  style={styles.settingView}
                  onPress={() => {
                    this.props.setLoading(true);
                    fetch('/setup?domain=' + config.domain, {keepalive: true})
                      .then((response) => {
                        return response.json();
                      })
                      .then((res) => {
                        console.log('response', res);
                        alert(strings('settingScreen.websiteUpdated'));
                        window.location.reload('/');
                        this.props.setLoading(false);
                      })
                      .catch((error) => {
                        console.log('error->', error);
                        this.props.openAlert(strings('settingScreen.tryagine'));
                        this.props.setLoading(false);
                      });
                  }}
                >
                  <AppText style={styles.title}>
                    {strings('settingScreen.newversion')}
                  </AppText>
                  <AppText style={styles.title}>{latestVersion}</AppText>
                </TouchableOpacity>
              )}

            {isLogin && (
              <TouchableOpacity
                style={styles.logoutView}
                onPress={() =>
                  openAlert(
                    strings('settingScreen.logoutMessage'),
                    strings('settingScreen.confirm'),
                    this.onLogout,
                    strings('settingScreen.cancel'),
                    null,
                  )
                }
              >
                <AppText style={styles.title}>
                  {strings('settingScreen.logout')}
                </AppText>
              </TouchableOpacity>
            )}
          </View>
        </MainContainer>
        {this.renderLanguagePopup()}
        {this.renderEnvironmentPopup()}
      </View>
    );
  }
}

export default connect(
  (state) => ({
    isLogin: get(state.auth.user, 'id', false),
    user: state.auth.user,
    lang: state.auth.lang,
    config: state.config,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        openAlert: (content, leftButton, leftPress, rightButton, rightPress) =>
          CommonActions.setAlert({
            visible: true,
            content,
            leftButton,
            leftPress,
            rightButton,
            rightPress,
          }),
        logout,
        setAppConfiguration: (value) =>
          ConfigActions.setAppConfiguration(value),
        setLoading: (values) => CommonActions.setLoading(values),
        setLanguage: AuthActions.setLanguage,
      },
      dispatch,
    ),
)(SettingScreen);
