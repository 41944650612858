import {Platform, StyleSheet} from 'react-native';

import {Colors} from '../../constants';
import {setValue} from '../../utils';

const textColor =  Colors.white;

export default StyleSheet.create({
  selectDateButtonIconContainer: {
    flexDirection: 'row',
    borderRadius: setValue(9),
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectDateItem: {
    flexDirection: 'row',
    backgroundColor: Colors.white,
    marginRight: 10,
    height: '100%',
    borderRadius: 15,
    borderWidth: Platform.OS == 'web' ? 1 : 0,
    borderColor: Colors.theme_color,
  },
  selectDateImages: {
    justifyContent: 'center',
  },
  selectDateButtonText: {
    color: Colors.black60,
    textAlignVertical: 'center',
    fontSize: 13,
    paddingHorizontal: 10,
    alignSelf: 'center',
  },
  selected: {
    flexDirection: 'row',
    backgroundColor: Colors.theme_color,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: Colors.white,
  },
  image: {
    width: setValue(30),
    height: setValue(30),
    borderRadius: 5,
  },
  selectDateImage: {},
  selectedselectDateButtonText: {
    color: Colors.white,
    textAlignVertical: 'center',
    fontSize: 13,
    justifyContent: 'center',
    paddingHorizontal: 10,
    alignSelf: 'center',
  },
  selectedselectDateButtonTextWeb: {
    color: Colors.white,
    textAlignVertical: 'center',
    justifyContent: 'center',
    fontSize: 13,
    paddingHorizontal: 10,
  },
  selectDateButtonTextWeb: {
    color: Colors.black60,
    textAlignVertical: 'center',
    fontSize: 13,
    paddingHorizontal: 10,
  },
  selectedselectDateTextWebContainer: {
    justifyContent: 'center',
  },
  selectDateTextWebContainer: {
    justifyContent: 'center',
  },
});
