import React, {useEffect, useState} from 'react';
import {
  FlatList,
  Image,
  Keyboard,
  Platform,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import {
  AppText,
  CategoryItem,
  Icon,
  LongButton,
  MainContainer,
  MainHeader,
  NoRecordFound,
} from '../../../components';
import {Colors} from '../../../constants';
import {goBack} from '../../../utils';
import styles from './styles';
import {connectActionSheet} from '@expo/react-native-action-sheet';
import {connect, useDispatch} from 'react-redux';
import {strings} from '../../../i18n';
import * as ImagePicker from 'expo-image-picker';
import {bindActionCreators} from 'redux';
import axios from 'axios';
import CommonActions from '../../../redux/reduxsauce/commonRedux';
import ProductActions from '../../../redux/reduxsauce/productRedux';
import {useSelector} from 'react-redux';

const EditProductCategory = (props) => {
  const config = useSelector((state) => state.config);
  const user = useSelector((state) => state.auth.user);
  console.log('config', config);
  const dispatch = useDispatch();
  const [image, setImage] = useState(
    props?.route?.params?.photo ? {uri: props?.route?.params?.photo} : null,
  );
  const [categoryDetail, setCategoryDetail] = useState({
    name: props?.route?.params?.name || '',
    des: props?.route?.params?.description || '',
  });
  const [subCategoryList, setSubCategoryList] = useState([]);

  const openActionSheet = () => {
    const options = [
      strings('actionSheet.takePhoto'),
      strings('actionSheet.choosePhoto'),
      strings('actionSheet.removePhoto'),
      strings('actionSheet.cancel'),
    ];
    const cancelButtonIndex = 3;

    props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) openCamera();
        if (buttonIndex === 1) openImagePicker();
        if (buttonIndex === 2) removePhoto();
      },
    );
  };

  const removePhoto = () => {
    // props.removeProfilePicture();
    setImage(null);
  };

  const openCamera = async () => {
    await ImagePicker.requestCameraPermissionsAsync();

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      setImage(result);
    }

    if (!result.cancelled) {
      // changeProfilePicture(result);
    }
  };

  const openImagePicker = async () => {
    await ImagePicker.requestMediaLibraryPermissionsAsync();

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
      base64: true,
    });
    if (result.uri) {
      setImage(result);
    }

    if (!result.cancelled) {
      // changeProfilePicture(result);
    }
  };

  const dataURLtoFile = (dataurl) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], 'image1.jpg', {type: mime});
  };

  const onSave = async () => {
    props.setLoading(true);
    Keyboard.dismiss();

    const {user, config, categories} = props;

    if (categoryDetail.name == '') {
      props.setLoading(false);
      props.setAlert({
        visible: true,
        content: strings('editProductCategory.required.name'),
      });
      return;
    }

    let body = new FormData();
    body.append('name', categoryDetail?.name);
    body.append('description', categoryDetail?.des);
    body.append('business', config.businessId);

    if (image?.uri && image?.uri?.slice(0, 4) != 'http') {
      if (Platform.OS == 'web') {
        body.append('photo', dataURLtoFile(image?.uri));
      } else {
        let blob = await (await fetch(image?.uri)).blob();
        body.append('photo', {
          uri: image.uri,
          type: blob.type,
          name: blob.data.name,
        });
      }
    } else if (!image?.uri) {
      body.append('photo', '');
    }

    const header = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    console.log('body', body);
    if (props.route?.params?.id) {
      await axios
        .put(
          'products/product-category/update/' + props.route?.params?.id,
          body,
          header,
        )
        .then((response) => {
          setCategoryDetail({
            ...categoryDetail,
            name: '',
            des: '',
          });
          setImage(null);
          let newList = [];
          categories?.forEach((element) => {
            element?.id == props.route?.params?.id
              ? (element = response?.data)
              : null;
            newList.push(element);
          });
          props.setCategories({
            categories: [...newList],
          });
          props.setLoading(false);
          goBack(navigation);
        })
        .catch((error) => {
          props.setAlert({
            visible: true,
            content: error?.response?.message,
          });
          props.setLoading(false);
        });
    } else {
      await axios
        .post('/products/product-category/create', body, header)
        .then((response) => {
          console.log('response', response);
          setCategoryDetail({
            ...categoryDetail,
            name: '',
            des: '',
          });
          setImage(null);
          props.setCategories({
            categories: [...categories, response.data],
          });
          props.setLoading(false);
          goBack(navigation);
        })
        .catch((error) => {
          console.log('error', error.response);
          props.setAlert({
            visible: true,
            content: error?.response?.message,
          });
          props.setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchSubCategory();
  }, []);

  useEffect(() => {
    const unsubscribe = props.navigation.addListener('focus', () => {
      fetchSubCategory();
    });
    return unsubscribe;
  }, [props.navigation]);

  const fetchSubCategory = async () => {
    if (!props.route.params?.id) {
      return;
    }
    dispatch(CommonActions.setLoading(true));
    try {
      const response = await axios.get(
        `/products/product-sub-category/list/${config.businessId}/${props.route.params?.id}`,
      );
      console.log('response', response);
      dispatch(CommonActions.setLoading(false));
      let array = [];
      if (['merchant', 'manager'].includes(user.type)) {
        array = [{static: true}, ...response.data];
      } else {
        array = [...response.data];
      }
      // console.log('array--------', array);
      setSubCategoryList(array);
    } catch (error) {
      console.log('error', error);
      dispatch(CommonActions.setLoading(false));
    }
  };

  const openAlert = (content, leftButton, leftPress, rightButton, rightPress) =>
    CommonActions.setAlert({
      visible: true,
      content,
      leftButton,
      leftPress,
      rightButton,
      rightPress,
    });

  return (
    <View style={styles.container}>
      <MainHeader
        // title={strings('EditProductScreen.profileEditing')}
        title={
          props?.route?.params
            ? strings('editProductCategory.title.edit')
            : strings('editProductCategory.title.add')
        }
        leftIcon="back"
        leftPress={() => goBack(navigation)}
      />
      <MainContainer>
        <View style={styles.profileStrengthContainer}>
          <TouchableOpacity
            style={styles.imageContainer}
            onPress={openActionSheet}
          >
            {!image ? (
              <Icon name="add" size={16} color={Colors.black60} />
            ) : (
              <View>
                <Image source={{uri: image.uri}} style={styles.image} />
                <TouchableOpacity
                  style={styles.cameraIcon}
                  onPress={openActionSheet}
                >
                  <Icon name="camera" color={Colors.black20} />
                </TouchableOpacity>
              </View>
            )}
          </TouchableOpacity>
        </View>

        <View>
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel} required>
              {strings('editProductCategory.label.name')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              // autoCapitalize="words"
              autoCorrect={false}
              placeholder={strings('editProductCategory.placeholder.name')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={
                Platform.OS == 'web' ? styles.textInputWeb : styles.textInput
              }
              value={categoryDetail.name}
              onChangeText={(text) =>
                setCategoryDetail({...categoryDetail, name: text})
              }
            />
          </View>
          <View style={styles.textInputContainer}>
            <AppText style={styles.inputLabel}>
              {strings('editProductCategory.label.des')}
            </AppText>
            <TextInput
              underlineColorAndroid="transparent"
              // autoCapitalize="words"
              autoCorrect={false}
              multiline={true}
              numberOfLines={3}
              placeholder={strings('editProductCategory.placeholder.des')}
              placeholderTextColor={Colors.color30}
              returnKeyType="done"
              style={styles.multiLineTextInput}
              value={categoryDetail.des}
              onChangeText={(text) =>
                setCategoryDetail({...categoryDetail, des: text})
              }
            />
          </View>
        </View>

        {config?.product?.showSubCategory && props.route.params?.id && (
          <View style={styles.subCategoryConatiner}>
            <AppText style={styles.subCatogoryTitle}>
              {' '}
              {strings('editProductCategory.label.subCategory')}
            </AppText>
            {subCategoryList?.length > 0 ? (
              <FlatList
                data={subCategoryList}
                showsVerticalScrollIndicator={false}
                keyExtractor={(item, index) => index}
                renderItem={({item, index, isActive}) => {
                  if (item?.static) {
                    return (
                      <TouchableOpacity
                        key={index}
                        style={styles.staticItemContainer}
                        onPress={() =>
                          props.navigation.navigate('edit-sub-category', {
                            edit: false,
                            category: props.route.params?.id,
                          })
                        }
                      >
                        <AppText style={styles.iconContainer}>
                          <Icon name="add" size={18} style={styles.iconStyle} />
                        </AppText>
                      </TouchableOpacity>
                    );
                  }
                  return (
                    <CategoryItem
                      key={index}
                      item={item}
                      isActive={isActive}
                      onEditPress={() =>
                        props.navigation.navigate('edit-sub-category', {
                          edit: true,
                          ...item,
                        })
                      }
                      onDeletePress={() => {
                        dispatch(
                          openAlert(
                            strings('editProductCategory.askDelete'),
                            strings('settingScreen.confirm'),
                            async () => {
                              dispatch(CommonActions.setLoading(true));
                              await axios
                                .delete(
                                  '/products/product-sub-category/delete/' +
                                    item.id,
                                )
                                .then((response) => {
                                  // setCategories(item.id);
                                  fetchSubCategory();
                                  dispatch(CommonActions.setLoading(false));
                                })
                                .catch((error) => {
                                  console.log(
                                    'error -------------->',
                                    error?.response,
                                  );
                                  dispatch(openAlert(error?.response?.message));
                                  dispatch(CommonActions.setLoading(false));
                                });
                            },
                            strings('settingScreen.cancel'),
                            null,
                          ),
                        );
                      }}
                    />
                  );
                }}
                ListFooterComponent={() => <View style={{height: 50}}></View>}
              />
            ) : (
              <NoRecordFound
                title={strings('editProductCategory.norecord.title')}
                message={strings('editProductCategory.norecord.message')}
              />
            )}
          </View>
        )}
        {Platform.OS == 'web' && (
          <LongButton
            text={'Save'}
            onPress={onSave}
            style={{marginTop: 20, marginBottom: 50}}
          />
        )}
      </MainContainer>
      {Platform.OS !== 'web' && (
        <LongButton
          text={'Save'}
          style={{borderRadius: 0, width: '100%'}}
          onPress={onSave}
        />
      )}
    </View>
  );
};

const ConnectedEditProduct = connectActionSheet(EditProductCategory);

export default connect(
  (state) => ({
    categories: state.products?.categories,
    user: state.auth?.user,
    config: state.config,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setLoading: CommonActions.setLoading,
        setAlert: CommonActions.setAlert,
        setCategories: ProductActions.getCategories,
      },
      dispatch,
    ),
)(ConnectedEditProduct);
