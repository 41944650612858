import React, {Component} from 'react';
import {SafeAreaView, TouchableOpacity, View} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {Icon, WebView} from '../../components';
import {Colors} from '../../constants';
import CommonActions from '../../redux/reduxsauce/commonRedux';
import styles from './styles';

class AlertWebView extends Component {
  leftPress = () => {
    const {alertWebView, closeAlert} = this.props;
    if (alertWebView.leftPress) {
      closeAlert();
      alertWebView.leftPress();
    } else {
      closeAlert();
    }
  };

  rightPress = () => {
    const {alertWebView, closeAlert} = this.props;
    if (alertWebView.rightPress) {
      closeAlert();
      alertWebView.rightPress();
    } else {
      closeAlert();
    }
  };

  render() {
    const {alertWebView} = this.props;

    return alertWebView && alertWebView.content && alertWebView.visible ? (
      <View style={styles.container}>
        <SafeAreaView style={styles.crossIcon}>
          <TouchableOpacity onPress={this.rightPress}>
            <Icon name="cross" size={22} color={Colors.white} />
          </TouchableOpacity>
        </SafeAreaView>
        <View style={styles.webViewContainer}>
          {/* <WebView
            originWhitelist={['*']}
            allowsFullscreenVideo
            allowsInlineMediaPlayback
            mediaPlaybackRequiresUserAction
            style={styles.webView}
            startInLoadingState
            androidHardwareAccelerationDisabled
          /> */}
        </View>
      </View>
    ) : null;
  }
}

export default connect(
  state => ({
    alertWebView: state.common.alertWebView,
  }),
  dispatch =>
    bindActionCreators(
      {
        closeAlert: () => CommonActions.setAlertWebView({visible: false}),
      },
      dispatch,
    ),
)(AlertWebView);
